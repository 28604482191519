import React from 'react';

const CannotSeeAllEmployees = () => (
    <div>
        <div className="sectionQuestion">
            Why can't I see all of my employees in the drop-down list when I am trying to set up a
            user account?
        </div>
        <div className="sectionAnswer">
            If an employee is missing from the drop-down list, it means they're "hidden" in your
            QuickBooks Company file. You can change the status of an employee in QuickBooks by
            selecting them from the Employees list in QuickBooks and unchecking the "Inactive"
            option.
        </div>
    </div>
);

export default CannotSeeAllEmployees;
