import React from 'react';

const BeforeYouBegin = () => (
    <div>
        <div className="sectionQuestion">
            What do I need to do before my employees begin entering time?
        </div>
        <div className="sectionAnswer">
            Once you've signed up, you're up and running 3 easy steps: <br />
            <ol>
                <li>Synchronize your QuickBooks company data with Minute7</li>
                <li>Update and customize your company account</li>
                <li>
                    Add users and manage their permissions. We'll email them with login instructions
                </li>
            </ol>
        </div>
    </div>
);

export default BeforeYouBegin;
