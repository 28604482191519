import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import Modal from 'react-modal';
import HelpSection from './components/HelpSection.js';
import { HelpLinks } from './components/HelpLinks.js';
import { showAlert } from '../../actions/Actions';
import scrollTo from '../../utils/scrollTo';

import '../../css/Help.css';

// Our tests don't like this line
try {
    Modal.setAppElement('#root');
} catch {}

class Help extends Component {
    constructor(props) {
        super(props);
        const { hash } = props.location;

        this.state = {
            isGettingStartedOpen: hash === '#getting-started' || hash === '',
            isAccountAdministrationOpen: hash === '#account-administration',
            isSyncingWithQBDOpen: hash === '#syncing-with-qbd',
            isSyncingWithQBOOpen: hash === '#syncing-with-qbo',
            isSyncingWithQBQuestions: hash === '#syncing-with-qb-questions',
            isCommonWebConnectorOpen: hash === '#common-web-connector-errors',
            isPeopleAndPermissionsOpen: hash === '#people-and-permissions',
            isWorkingWithTimesheetsOpen: hash === '#working-with-timesheets',
            isWorkingWithExpensesOpen: hash === '#working-with-expenses',
            isPayrollOpen: hash === '#payroll',
            isSettingUpPayrollItemsOpen: hash === '#setting-up-payroll-items',
            isMobileMinute7Open: hash === '#mobile-minute7',
            isCustomizingMinute7Open: hash === '#customizing-minute7',
            isUsingQuickBooksWithMinute7Open: hash === '#using-quickbooks-with-minute7',
            isFAQAboutNewSiteOpen: hash === '#faq-new-site',

            areAllExpanded: false,
            isModalOpen: false,
            modalTitle: '',
            modalContent: null,
            modalContentClass: '',
        };
    }

    componentDidMount() {
        const { state = {}, hash } = this.props.location;
        const { error } = state;

        if (error) {
            this.props.showAlert(error, 'negative');
        }

        if (hash && hash !== '') {
            // cut off hash sign
            const anchor = hash.substr(1);

            const el = document.getElementById(`section-${anchor}`);
            if (el) {
                scrollTo({
                    top: el.offsetTop,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        } else {
            scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    toggleAll = openOrClose => {
        this.setState({
            isGettingStartedOpen: openOrClose,
            isAccountAdministrationOpen: openOrClose,
            isSyncingWithQBDOpen: openOrClose,
            isSyncingWithQBOOpen: openOrClose,
            isSyncingWithQBQuestions: openOrClose,
            isCommonWebConnectorOpen: openOrClose,
            isPeopleAndPermissionsOpen: openOrClose,
            isWorkingWithTimesheetsOpen: openOrClose,
            isWorkingWithExpensesOpen: openOrClose,
            isPayrollOpen: openOrClose,
            isSettingUpPayrollItemsOpen: openOrClose,
            isMobileMinute7Open: openOrClose,
            isFAQAboutNewSiteOpen: openOrClose,
            areAllExpanded: openOrClose,
        });
    };

    expandAll = () => {
        this.toggleAll(true);
    };

    closeAll = () => {
        this.toggleAll(false);
    };

    closeModal = () => this.setState({ isModalOpen: false, modalContentClass: '' });

    openModalSection = (modalContent, modalTitle, modalContentClass = '') =>
        this.setState({ modalContent, modalTitle, modalContentClass, isModalOpen: true });

    renderSections = () => {
        const sections = Object.keys(HelpLinks);
        let renderedSections = [];
        sections.forEach(sectionKey => {
            const section = HelpLinks[sectionKey];
            renderedSections.push(
                <HelpSection
                    key={sectionKey}
                    history={this.props.history}
                    label={section.label}
                    hash={section.hash}
                    isOpen={this.state[section.isExpandedKey]}
                    toggle={() => {
                        let tempState = this.state;
                        tempState[section.isExpandedKey] = !tempState[section.isExpandedKey];
                        this.setState(tempState);
                    }}
                    links={section.links}
                    openModalSection={this.openModalSection}
                />
            );
        });
        return renderedSections;
    };

    render() {
        return (
            <div className="application">
                <div className="applicationBody">
                    <div className="bodyHeader">
                        <h2>Help and FAQ</h2>
                        <div className="headerActions">
                            <button
                                className="tertiaryButton"
                                onClick={() =>
                                    this.state.areAllExpanded ? this.closeAll() : this.expandAll()
                                }
                            >
                                {this.state.areAllExpanded ? 'Collapse All' : 'Expand All'}
                            </button>
                        </div>
                    </div>

                    <div className="cardContainer cardContent">{this.renderSections()}</div>

                    <Modal
                        closeTimeoutMS={400}
                        isOpen={this.state.isModalOpen}
                        onRequestClose={this.closeModal}
                        contentLabel="HelpInfo"
                        className="modalContainer helpModal"
                    >
                        <div className="modalHeader">
                            <h2>{this.state.modalTitle}</h2>
                            <div className="modalOptions">
                                <Icon
                                    className="modalIcon"
                                    size={32}
                                    icon={closeIcon}
                                    onClick={this.closeModal}
                                />
                            </div>
                        </div>
                        <div className="modalContent helpModalContent">
                            {this.state.modalContent}
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    showAlert,
};

export default connect(
    null,
    mapDispatchToProps
)(Help);
