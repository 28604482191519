import React from 'react';

const ConnectionNotFound = () => (
    <div>
        <div className="sectionQuestion">
            When I sync, I get the error message "The record of the Minute7 connection in QuickBooks
            Online could not be found. Please subscribe your Minute7 account to QuickBooks Online
            again." How do I subscribe again?
        </div>
        <div className="sectionAnswer">
            When you are logged in to your Minute7 account, you can re-subscribe your Minute7
            account to QuickBooks Online by going to https://www.minute7.com/qboe and following the
            instructions for the first-time setup above.
            <br /> <br />
            Make sure that you choose to turn off the "login security" option in QuickBooks Online
            when you are subscribing again, as Minute7 already handles all user and syncing security
        </div>
    </div>
);

export default ConnectionNotFound;
