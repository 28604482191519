import _ from 'lodash';
import uuid from 'uuid';
import jssha from 'jssha';
import moment from 'moment';
import { isDateInRange, isValidDate } from './DateUtils.js';
import { timeFields, weekHashFields, toParamsObject } from '../config/Fields.js';
import { NO_EMPLOYEE_ASSOCIATION_STRING, MIDNIGHT } from '../config/Constants.js';

export const isEmptyDuration = duration =>
    _.isString(duration) && duration.toString().trim().length === 0;

export const isZeroDuration = duration => durationStringToDuration(duration).asMilliseconds() === 0;

export const durationStringToDuration = str => {
    if (_.isString(str) && str.indexOf(':') !== -1) {
        const [hours, minutes] = str.split(':');
        return moment.duration({ hours, minutes });
    }

    return moment.duration({ hours: str });
};
/**
 * Validates data for a time entry given state
 * EmployeeId required
 * Type required
 * Date Required
 * Note: Expects week entries to be deconstructed into single time entry
 * @param {Object} entry entry composed like { TimeEntry[key]: value, }
 * @param {function} showAlert function to show globalAlert
 */
export const isDataValid = (entry, showAlert = null, showToast = null, noIntegration = false) => {
    const canShowAlert = typeof showAlert === 'function';
    const canShowToast = typeof showToast === 'function';

    for (let key in entry) {
        const isValueEmpty = entry[key] === null || entry[key] === '';
        if (key === 'employee_id') {
            if (parseInt(entry[key]) <= 0) {
                canShowAlert && showAlert(NO_EMPLOYEE_ASSOCIATION_STRING, 'negative');
                return false;
            } else if (isValueEmpty) {
                canShowToast && showToast('Please select an employee', 'warning');
                return false;
            }
        }
        if (
            key === 'inventory_item_id' &&
            !noIntegration &&
            (entry[key] === '0' || isValueEmpty || entry[key] === 0)
        ) {
            canShowToast && showToast('Please select a service for time entry.', 'warning');
            return false;
        }

        if (key === 'date' && isValueEmpty && !isValidDate(entry[key])) {
            canShowToast &&
                showToast('Please provide a valid date in format: YYYY-MM-DD', 'warning');
            return false;
        }

        if (key === 'date' && !isDateInRange(entry[key])) {
            canShowToast &&
                showToast('Date should be in range of 5 years', 'warning');
            return false;
        }

        if (key === 'type' && isValueEmpty) {
            canShowAlert && showAlert('Type not specified. Please contact support', 'negative');
            return false;
        }
    }

    if (entry.entryType === 'day') {
        if (isEmptyDuration(entry.duration)) {
            canShowToast && showToast('Please enter in a duration', 'warning');
            return false;
        }
    } else if (entry.entryType === 'week') {
        if (Object.values(entry.durations).every(isEmptyDuration)) {
            canShowToast && showToast('Please enter in a duration for at least one of the days', 'warning');
            return false;
        }
    }

    return true;
};

/**
 * Given an accountUser and employees, figure the appropriate employeeId to select
 * @param {*} accountUserStore
 * @param {*} employees
 * @todo test case
 */
export const figureEmployeeId = (accountUserStore, employees) => {
    if (parseInt(accountUserStore.employee_id, 10) !== 0) {
        let employeeId = accountUserStore.employee_id;
        if (accountUserStore.type === 'Vendor') {
            employeeId += '_v';
        }
        return employeeId;
    } else {
        let canViewOtherEmployees =
            accountUserStore.view_others_time === 'Yes' ||
            accountUserStore.manage_users === 'Yes' ||
            accountUserStore.manage_account === 'Yes';
        if (canViewOtherEmployees && employees.length > 0) {
            return employees[0].id;
        } else {
            return '0';
        }
    }
};

/**
 * Returns the default, initial activeFilters
 * @todo test case
 * @param {Boolean} canViewOthersTime whether the current AccountUser can view others' time
 */
export const getDefaultFiltersForCurrentUser = canViewOthersTime => {
    const activeFilters = ['date', 'search', 'customer', 'approved', 'inventoryItem'];
    if (canViewOthersTime) {
        activeFilters.push('employee');
    }
    return activeFilters;
};

/**
 * Constructs a paramsObject for timesheet/view
 *
 * this.state.searchNameIds is structured like { id: 'DisplayName' }
 */
export const constructParamsObject = (state, accountUser, urlVariables = null) => {
    const {
        activeFilters,
        searchEmployeeIds,
        searchCustomerIds,
        searchInventoryItemIds,
        searchQbClassIds,
        searchPayrollItemIds,
        approvedFilter,
        lockedFilter,
        exportedFilter,
        billableFilter,
        taxableFilter,
        currentPage,
        startDate,
        endDate,
    } = state;

    const paramsObject = {
        current_page: currentPage,
    };

    // Employee Filter
    if (activeFilters.includes('employee') && searchEmployeeIds.length > 0) {
        paramsObject.employee_id = _.compact(searchEmployeeIds).join(',');
    } else if (
        accountUser.view_others_time === 'Yes' ||
        accountUser.view_others_time === 'Filtered' ||
        accountUser.manage_account === 'Yes' ||
        accountUser.manage_users === 'Yes'
    ) {
        paramsObject.employee_id = 'All';
    } else {
        paramsObject.employee_id = accountUser.employee_id;
        if (accountUser.type === 'Vendor') {
            paramsObject.employee_id += '_v';
        }

        // AccountUser not associated with any employee, set to -1;
        if (paramsObject.employee_id === '0') {
            paramsObject.employee_id = -1;
        }
    }

    // Date Filter
    if (activeFilters.includes('date')) {
        paramsObject.filter_by_date = true;
        paramsObject.search_start_date = moment(startDate).format('Y-MM-DD');
        paramsObject.search_end_date = moment(endDate).format('Y-MM-DD');
    }

    if (activeFilters.includes('customer') && searchCustomerIds.length > 0) {
        paramsObject.customer_ids = _.compact(searchCustomerIds).join(',');
    }

    if (activeFilters.includes('inventoryItem') && searchInventoryItemIds.length > 0) {
        paramsObject.inventory_item_ids = _.compact(searchInventoryItemIds).join(',');
    }

    if (activeFilters.includes('qbClass') && searchQbClassIds.length > 0) {
        paramsObject.qb_class_ids = _.compact(searchQbClassIds).join(',');
    }

    if (activeFilters.includes('payrollItem') && searchPayrollItemIds.length > 0) {
        paramsObject.payroll_item_ids = _.compact(searchPayrollItemIds).join(',');
    }

    if (activeFilters.includes('billable') && toParamsObject[billableFilter] !== '') {
        paramsObject.billable = toParamsObject[billableFilter];
    }

    if (activeFilters.includes('taxable') && toParamsObject[taxableFilter] !== '') {
        paramsObject.taxable = toParamsObject[taxableFilter];
    }

    if (activeFilters.includes('approved') && toParamsObject[approvedFilter] !== '') {
        paramsObject.approved = toParamsObject[approvedFilter];
    }

    if (activeFilters.includes('locked') && toParamsObject[lockedFilter] !== '') {
        paramsObject.locked = toParamsObject[lockedFilter];
    }

    if (activeFilters.includes('exported') && toParamsObject[exportedFilter] !== '') {
        paramsObject.exported = toParamsObject[exportedFilter];
    }

    if (typeof urlVariables === 'object') {
        for (let key in urlVariables) {
            if (key === 'entry_ids') {
                // Set filter by date to false to ensure we pull entry ids
                paramsObject['filter_by_date'] = 0;
                paramsObject[key] = urlVariables[key];
            } else {
                paramsObject[key] = urlVariables[key];
            }
        }
    }

    return paramsObject;
};

export function isWeekEntry(entry) {
    return 'durations' in entry;
}

function addTimeEntryToWeek(weekEntries, weekHash, timeEntry) {
    const day = moment(timeEntry.date).format('ddd');
    weekEntries[weekHash].account_user_id = timeEntry.account_user_id;
    weekEntries[weekHash].durations[day] = timeEntry.duration;
    weekEntries[weekHash].dates[day] = timeEntry.date;
    weekEntries[weekHash].grouped_time_ids[day].push(timeEntry.id);
    weekEntries[weekHash].week_hash = weekHash;
    weekHashFields.forEach(field => {
        weekEntries[weekHash][field] = timeEntry[field];
    });
}

/*
    Creates a new week entry slot for an array of weekEntries
*/
function newWeekEntry() {
    return {
        uuid: uuid.v4(),
        account_user_id: '',
        type: '',
        billable: '',
        approved: '',
        employee_id: '',
        customer_id: '',
        inventory_item_id: '',
        qb_class_id: '',
        payroll_item_id: '',
        description: '',
        exported: '',
        durations: {
            Mon: '',
            Tue: '',
            Wed: '',
            Thu: '',
            Fri: '',
            Sat: '',
            Sun: '',
        },
        dates: {
            Mon: '',
            Tue: '',
            Wed: '',
            Thu: '',
            Fri: '',
            Sat: '',
            Sun: '',
        },
        grouped_time_ids: {
            Mon: [],
            Tue: [],
            Wed: [],
            Thu: [],
            Fri: [],
            Sat: [],
            Sun: [],
        },
        entriesInGroupById: {},
    };
}

/*
    Creates a week_hash for a time entry incase we don't have one stored
 */
export function createWeekHash(timeEntry) {
    const shaObj = new jssha('SHA-512', 'TEXT');
    timeEntry = 'TimeEntry' in timeEntry ? timeEntry.TimeEntry : timeEntry;
    const weekHash = weekHashFields.map(field => timeEntry[field]).join('');
    shaObj.update(weekHash);
    return shaObj.getHash('HEX');
}

/*
    Group entries by week_hashes
 */
export function groupByWeekHash(entries) {
    const weekEntries = {};
    entries.forEach(timeEntry => {
        const day = moment(timeEntry.date).format('ddd');
        let hash = timeEntry.week_hash;

        if (hash === null) {
            hash = createWeekHash(timeEntry);
            timeEntry.week_hash = hash;
        }

        if (hash in weekEntries) {
            // Duration slot is open
            if (
                weekEntries[hash].durations[day] === '0:00' ||
                weekEntries[hash].durations[day] === ''
            ) {
                addTimeEntryToWeek(weekEntries, hash, timeEntry);
                weekEntries[hash].entriesInGroupById[timeEntry.id] = timeEntry;
            }
            // Duration slot is occupied
            else {
                // Duration is added to the previous duration that occupied this slot
                const originalDuration = parseFloat(weekEntries[hash].durations[day]);
                const newDuration = originalDuration + parseFloat(timeEntry.duration);

                addTimeEntryToWeek(weekEntries, hash, {
                    ...timeEntry,
                    duration: newDuration.toFixed(2),
                });
                weekEntries[hash].entriesInGroupById[timeEntry.id] = timeEntry;
            }
        }
        // Entry with week_hash hasn't been added yet
        else {
            weekEntries[hash] = newWeekEntry();
            addTimeEntryToWeek(weekEntries, hash, timeEntry);
            weekEntries[hash].entriesInGroupById[timeEntry.id] = timeEntry;
        }
    });

    return Object.values(weekEntries);
}

const embeddedEmployeeNameSort = entry => {
    return entry.Employee
        ? (
            entry.Employee.last_name ||
            entry.Employee.first_name ||
            entry.Employee.name
        ).toLowerCase()
        : '';
};

/* Sorts an array of entries by employee name alphabetically */
export const sortByEmployee = entries => {
    return _.sortBy(entries, embeddedEmployeeNameSort);
};

export const sortByEmployeeAndDate = entries => {
    const dateSort = entry => -moment(entry.date).valueOf();

    return _.sortBy(entries, [dateSort, embeddedEmployeeNameSort]);
};

export const sortByEmployeeAndCreatedDate = entries => {
    const dateSort = entry => {
        const timestamps = Object.values(entry.entriesInGroupById).map(e =>
            moment(e.created).valueOf()
        );
        return -Math.min(...timestamps);
    };

    return _.sortBy(entries, [embeddedEmployeeNameSort, dateSort]);
};

export const idsForWeeklyEntry = (entry, day) => {
    const ids =
        typeof day === 'undefined'
            ? Object.values(entry.grouped_time_ids)
            : entry.grouped_time_ids[day];
    return _.compact(_.flatten(ids));
};

/**
 * Figure which employee to select by default
 *  - If associated with an employee/vendor - select that employee
 *  - Else use all (emptyObject)
 * @param {*} accountUser accountUser fields
 * @return {object}
 * @todo test case
 */
export const figureDefaultSelectedEmployees = accountUser => {
    const employeeIds = [];
    if (parseInt(accountUser.employee_id) !== 0) {
        const employeeId =
            accountUser.type === 'Vendor'
                ? accountUser.employee_id + '_v'
                : accountUser.employee_id;
        employeeIds.push(employeeId);
    }
    return employeeIds;
};

/**
 * Returns values depending on props.mode
 * @param {*} props
 */
export const mapValuesToEntry = props => {
    if (props.mode === 'edit' || props.mode === 'duplicate' || props.isDuplicate) {
        return {
            id: props.editId,
            qbClassId: props.editQbClassId,
            employeeId: props.editEmployeeId,
            inventoryItemId: props.editInventoryItemId,
            customerId: props.editCustomerId,
            payrollItemId: props.editPayrollItemId,
            durations: props.editDurations,
            approved: props.editApproved,
            billable: props.editBillable,
            duration: props.editDuration,
            description: props.editDescription,
            date: props.editDate,
            dates: props.editDates,
            entryType: props.editEntryType,
            groupedTimeIds: props.editGroupedTimeIds,
            startOfWeek: props.editStartOfWeek,
            startTime: props.editStartTime,
            endTime: props.editEndTime,
            type: props.editType,
        };
    } else {
        return props;
    }
};

/*
 * If the entry is a day entry, returns the entry's date.
 * If the entry is a week entry, returns the first specified date for the entry.
 * Used to construct the week range an entry falls in.
 */

export const referenceDateForEntry = entry => {
    let referenceDate = moment(entry.date || new Date());
    if (isWeekEntry(entry)) {
        const anyDate = Object.values(entry.dates).find(d => d.length !== 0);
        if (anyDate) {
            referenceDate = moment(anyDate);
        }
    }

    return referenceDate.toDate();
};

export const constructEditTimesheetStore = entry => {
    const weekEntry = isWeekEntry(entry);
    const employeeId = entry.type === 'Vendor' ? entry.employee_id + '_v' : entry.employee_id;

    return {
        editHoursOffDuty: entry.hours_off_duty,
        editCustomerId: entry.customer_id,
        editEmployeeId: employeeId,
        editInventoryItemId: entry.inventory_item_id,
        editPayrollItemId: entry.payroll_item_id,
        editQbClassId: entry.qb_class_id,
        editDate: 'date' in entry ? entry.date : moment().format(),
        editType: 'Employee',
        editEntryType: weekEntry ? 'week' : 'day',
        editId: entry.id,
        editDates: entry.dates,
        editDescription: entry.description,
        editDuration: entry.duration || '0',
        editBillable: entry.billable,
        editTaxable: entry.taxable,
        editApproved: entry.approved,
        editExported: entry.exported,
        editDurations: entry.durations,
        editOriginalDurations: _.cloneDeep(entry.durations),
        editEntriesInGroupById: entry.entriesInGroupById,
        editGroupedTimeIds: entry.grouped_time_ids,
        editStartTime: 'start_time' in entry ? entry.start_time : MIDNIGHT,
        editEndTime: 'end_time' in entry ? entry.end_time : MIDNIGHT,
    };
};

export const formDataForEntry = entry => {
    const data = {};
    const { entryType } = entry;

    _.forEach(_.pick(entry, timeFields), (value, key) => {
        switch (key) {
            case 'employee_id': {
                data['TimeEntry[employee_id]'] = entry[key].replace('_v', '');
                break;
            }
            case 'start_time':
            case 'end_time': {
                if (entryType === 'week') {
                    break;
                }

                const startTime = moment(entry.start_time, 'HH:mm');
                const endTime = moment(entry.end_time, 'HH:mm');

                if (startTime.isValid() && endTime.isValid()) {
                    data[`TimeEntry[start_time]`] = startTime.format('HH:mm');
                    data[`TimeEntry[end_time]`] = endTime.format('HH:mm');
                }
                break;
            }
            case 'date': {
                if (entry.date instanceof Date) {
                    data[`TimeEntry[${key}]`] = moment(entry.date).format('Y-MM-DD');
                } else if (typeof entry.date === 'string') {
                    data[`TimeEntry[${key}]`] = entry[key].split('T')[0];
                }
                break;
            }
            default: {
                data[`TimeEntry[${key}]`] = entry[key];
                break;
            }
        }
    });
    data['TimeEntry[duration_overrides_start_end_time]'] = 1;

    return data;
};

export const entryIncludesCurrentDate = entry => {
    if ('entriesInGroupById' in entry) {
        return Object.values(entry.entriesInGroupById).some(entryIncludesCurrentDate);
    }
    return moment(entry.date).isSame(moment(), 'day');
};

export const entryIsInCurrentWeek = entry => {
    if ('entriesInGroupById' in entry) {
        return Object.values(entry.entriesInGroupById).some(entryIsInCurrentWeek);
    }
    return entry.is_in_current_week;
};
