import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { isCompanyNameValid } from '../../../utils/CommonFunctions.js';
import { Row, Col } from 'react-bootstrap';
import { showToast } from '../../../actions/Actions.js';
import { customerFields } from '../../../config/Fields.js';
import 'react-phone-input-2/lib/style.css';
import Select from '../../components/Select.js';
import Spinner from '../../components/Spinner.js';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';
import { updateCustomer } from '../../../actions/CustomerActions';
import { selectCustomer } from '../../../actions/Actions.js';
import _ from 'lodash';
import ToggleOptionsSecondV from '../../components/ToggleOptionsSecondV';
import ToggleSettingListV2 from '../../components/ToggleSettingListV2.js';

class EditCustomerForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState({ selectedView: 'customer' ,firstSearchFilter: '',
        secondSearchFilter: '',
        firstSearchFilterSecondList: '',
        secondSearchFilterSecondList: '', });
    }

    componentWillReceiveProps(props) {}

    defaultState = data => {
        const { customer, accountId, customers,items, serviceItems,inventoryItems } = this.props;
        const items_list = customer.item_ids ? customer.item_ids.split(',') : [];
        const default_billrate_data = new Map();
        if (items_list.length > 0) {
            items_list.forEach(item => {
                default_billrate_data.set(item.key, 0);
            });
        }
        let service_bill_rates = customer.service_items_bill_rates != "{}" ? new Map(Object.entries(customer.service_items_bill_rates)): default_billrate_data;
        let inventory_bill_rates = customer.inventory_items_bill_rates != "{}" ? new Map(Object.entries(customer.inventory_items_bill_rates)) : default_billrate_data;
        const parent_customer = customer
            ? customers.find(e => e.id === customer.parent_id + '')
            : null;

        return {
            inventory_items_bill_rates:inventory_bill_rates ,
            service_items_bill_rates: service_bill_rates,
            cNameInvalidMsg: '',
            account_id: accountId,
            parent_customer: parent_customer ? parent_customer : null,
            parent_hidden: customer.parent_hidden,
            parent_id: parent_customer ? parent_customer.id : null,
            id: customer ? customer.id : '0',
            name: customer ? customer.name : '',
            company_name: customer ? customer.company_name : '',
            spinner_loader: false,
            hidden: customer ? this.hiddenValue(customer.hidden) : false,
            item_ids: items_list,
            items: items,
            item_type: "Service",
            serviceItems: serviceItems,
            inventoryItems: inventoryItems,
        };
    };

    constructCustomer = () => {
        const data = {};
        customerFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else if (field === 'name') {
                    const { customers } = this.props;
                    const parentCustomer =
                        this.state.parent_id !== ''
                            ? customers.find(e => e.id === `${this.state.parent_id}`)
                            : false;

                    data[field] = parentCustomer
                        ? `${parentCustomer.name}:${this.state.company_name}`
                        : this.state.company_name;
                }  else if (field === 'item_bill_rates') {
                    const { item_bill_rates } = this.state;
                    data[field] = JSON.stringify(Object.fromEntries(item_bill_rates));
                }      
                else if (field === 'service_items_bill_rates') {
                    const { service_items_bill_rates } = this.state;
                    data[field] = JSON.stringify(Object.fromEntries(service_items_bill_rates));
                }
                else if (field === 'inventory_items_bill_rates') {
                    const { inventory_items_bill_rates } = this.state;
                    data[field] = JSON.stringify(Object.fromEntries(inventory_items_bill_rates));
                }
                else if (field.includes('item_ids')) {
                    data[field] = this.state[field].join(',');
                }
                 else {
                    data[field] = this.state[field];
                }
            }
        });
        return data;
    };

    checkRequiredFields = () => {
        if (this.state.company_name === '') {
            this.props.showToast('Please fill out Customer Name', 'warning');
            return false;
        }
        return true;
    };

    hiddenValue = employeeHiddenValue => {
        if (employeeHiddenValue === 'No') {
            employeeHiddenValue = false;
        } else if (employeeHiddenValue === 'Yes') {
            employeeHiddenValue = true;
        }
        return employeeHiddenValue;
    };

    saveCustomer = event => {
        event.preventDefault();
        if (this.state.cNameInvalidMsg !== '') {
            this.props.showToast('Please Fix the form error before submit', 'warning');
        } else if (this.state.hidden == false && this.state.parent_hidden === true) {
            this.props.showToast(
                'To activite this customer please activate its parent first.',
                'warning'
            );
        } else {
            if (!this.checkRequiredFields()) {
                return;
            }
            this.setState({ spinner_loader: true });
            const data = this.constructCustomer();
            this.props.updateCustomer(data, newCustomer => {
                if (newCustomer === false) {
                    this.setState({ spinner_loader: false });
                    return;
                } else {
                    this.props.showToast(`Customer ${newCustomer.name} updated`, 'positive');
                    this.props.history.push('/resource_settings/customers');
                }
            });
        }
    };

    handleParentCustomerOnChange = newParentCustomer => {
        this.setState({ parent_id: newParentCustomer });
    };

    setFields = fields => {
        this.setState({ ...fields });
    };

    handleOnChanges = {
        handleComapnyNameOnChange: newName => {
            this.setState({ company_name: newName.target.value });
        },
    };

    render() {
        const { company_name, parent_id, hidden, spinner_loader, firstSearchFilter,
            secondSearchFilter, inventory_items_bill_rates, service_items_bill_rates, items, item_ids, item_type, serviceItems, inventoryItems  } = this.state;

        const { customers, canManageItemBillrate, show_items_association_for_customers } = this.props;
        return (
            <div className="applicationWrapper">
                <div className="application">
                    <div className="ftContainer cardContainer">
                        <form className="ftForm" onSubmit={this.saveCustomer}>
                            <Row className="ftRowAlightCenter">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Edit Customer
                                </div>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                Customer Name{' '}
                                            </div>
                                            <div className="required">*</div>
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.cNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="company_name"
                                                value={company_name}
                                                onChange={
                                                    this.handleOnChanges.handleComapnyNameOnChange
                                                }
                                                onBlur={
                                                    company_name.length !== 0
                                                        ? () =>

                                                        isCompanyNameValid(company_name)
                                                                ? this.setFields({
                                                                    cNameInvalidMsg: '',
                                                                })
                                                                : this.setFields({
                                                                    cNameInvalidMsg:
                                                                        'Customer name contains only  1 to 50 alphabets.',
                                                                })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.cNameInvalidMsg === '' &&
                                                !company_name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.cNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                Parent Customer
                                            </div>
                                        </Row>
                                        <div>
                                            <Select
                                                options={customers}
                                                withSearch
                                                labelWhenNothingSelected="No Parent Customer Selected"
                                                value={parent_id}
                                                onChange={this.handleParentCustomerOnChange}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '15px', marginTop: '15px' }}>
                                    <div className="ftLabelAndInput">
                                        <div>
                                            <input
                                                name="hidden_checkbox"
                                                type="checkbox"
                                                className="checkbox"
                                                checked={hidden}
                                                onChange={() =>
                                                    this.setFields({
                                                        hidden: !hidden,
                                                    })
                                                }
                                            />
                                            <label htmlFor="hidden_checkbox">
                                                Mark customer as inactive
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {canManageItemBillrate && show_items_association_for_customers && (
                                 <ToggleSettingListV2
                                 title={[
                                     <span style={{ fontWeight: 'bold' }}>
                                         Select Item Type{' '}
                                     </span>,
                                     
                                 ]}
                                 value={item_type}
                                 options={[
                                     { label: 'Service Item', value: 'Service' },
                                     { label: 'Inventory Item', value: 'Inventory' },
                                 ]}
                                 valueForOption={option => option.value}
                                 labelForOption={option => option.label}
                                 onChange={value => {
                                     this.setState({
                                         item_type: value,
                                     });
                                    
                                 }}
                             />
                            )}
                            {canManageItemBillrate && show_items_association_for_customers && (
                         
                            <Row className="ftrow">
                                <div className="ftLabel largeBodyText greyText"  
                                            style={{ fontWeight: 'bold' , paddingTop: '2%', paddingBottom:'2%' }}>
                                    {' '}
                                    Set bill rates for {item_type} Items{' '}
                                </div>
                            </Row>
                            )}
                            {item_type === 'Service' && canManageItemBillrate && show_items_association_for_customers && (<ToggleOptionsSecondV
                                listOptions={serviceItems}
                                firstSearchFilter={firstSearchFilter}
                                secondSearchFilter={secondSearchFilter}
                                valueForListOptionMap={ service_items_bill_rates}
                                listValue={item_ids}
                                options={['Yes', 'No']}
                                onListChange={values => {
                                    this.setState({ item_ids: values });
                                    let list = new Map(Array.from(service_items_bill_rates, ([key,value]) => [key, value]).filter(obj => values.includes(obj[0].toString())));
                                    this.setState({service_items_bill_rates: list})
                                }}
                                onValueChange={values => {
                                    this.setState({  service_items_bill_rates: values });
                                }}
                                />)}
                              {item_type === 'Inventory' && canManageItemBillrate && show_items_association_for_customers && (<ToggleOptionsSecondV
                                listOptions={inventoryItems}
                                firstSearchFilter={firstSearchFilter}
                                secondSearchFilter={secondSearchFilter}
                                valueForListOptionMap={inventory_items_bill_rates}
                                listValue={item_ids}
                                options={['Yes', 'No']}
                                onListChange={values => {
                                    this.setState({ item_ids: values });
                                    let list = new Map(Array.from(inventory_items_bill_rates, ([key,value]) => [key, value]).filter(obj => values.includes(obj[0].toString())));
                                    this.setState({inventory_items_bill_rates: list})
                                }}
                                onValueChange={values => {
                                    this.setState({ inventory_items_bill_rates: values });
                                }}
                                />)}
                            <Row>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button type="submit" className="primaryButton ftButton">
                                            Save
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button
                                            className="secondaryButton ftButton"
                                            onClick={() =>
                                                this.props.history.push(
                                                    '/resource_settings/customers'
                                                )
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Spinner loading={spinner_loader} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function getTwoLevelCustomerList(customers, customer) {
    let updatedList = [];
    customers.map(customer => {
        if (customer.name.split(':').length < 4) {
            updatedList.push(customer);
        }
    });
    if (customer.parent_name !== '' && customer.parent_hidden) {
        updatedList.push({ name: customer.parent_name, id: customer.parent_id });
    }
    return updatedList;
}

function mapStateToProps(state) {
    const {
        appStore: { selectedCustomerId },
    } = state;

    const customer = selectedCustomerId;

    return {
        canManageItemBillrate: state.accountUserStore.manage_item_billrates === 'Yes',
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        isSaving: state.accountUserStore.isSaving,
        customers: getTwoLevelCustomerList(state.customerStore.customers, customer),
        customer,
        serviceItems: state.businessResourcesStore.serviceTypeItems,
        inventoryItems: state.businessResourcesStore.inventoryTypeItems,
        show_items_association_for_customers: state.accountStore.show_items_association_for_customers,
    };
}

const mapDispatchToProps = {
    showToast,
    updateCustomer,
    selectCustomer,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCustomerForm);
