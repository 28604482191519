export const login = ({ email, password }) => ({
    type: 'LOGIN',
    email,
    password,
});

export const loginSuccess = payload => ({
    type: 'LOGIN_SUCCESS',
    payload,
});

export const loginError = error => ({
    type: 'LOGIN_ERROR',
    error,
});

export const loginFromSession = ({ sessionId, sessionName, email }) => ({
    type: 'LOGIN_FROM_SESSION',
    email,
    sessionId,
    sessionName,
});

export const loginFromSessionSuccess = payload => ({
    type: 'LOGIN_FROM_SESSION_SUCCESS',
    payload,
});

export const loginFromSessionError = error => ({
    type: 'LOGIN_FROM_SESSION_ERROR',
    error,
});

export const refreshAccount = () => ({
    type: 'REFRESH_ACCOUNT',
});

export const refreshAccountSuccess = () => ({
    type: 'REFRESH_ACCOUNT_SUCCESS',
});

export const refreshAccountError = error => ({
    type: 'REFRESH_ACCOUNT_ERROR',
    error,
});

export const refreshAccountUsers = () => ({
    type: 'REFRESH_ACCOUNT_USERS',
});

export const refreshAccountUsersSuccess = () => ({
    type: 'REFRESH_ACCOUNT_USERS_SUCCESS',
});

export const refreshAccountUsersError = error => ({
    type: 'REFRESH_ACCOUNT_USERS_ERROR',
    error,
});

export const setSession = payload => ({
    type: 'SET_SESSION',
    payload,
});

export const logout = sessionParams => ({ type: 'LOGOUT', sessionParams });
export const logoutSuccess = () => ({ type: 'LOGOUT_SUCCESS' });
export const logoutError = error => ({ type: 'LOGOUT_ERROR', error });

export const showAlert = (message, type) => ({
    type: 'SHOW_ALERT',
    payload: {
        globalAlertType: type,
        globalAlertMessage: message,
    },
});

export const showToast = (message, kind) => ({
    type: 'SHOW_TOAST',
    message,
    kind,
});

export const hideAlert = () => ({ type: 'HIDE_ALERT' });

export const setActivityLog = payload => ({
    type: 'SET_ACTIVITY_LOG',
    payload,
});

export const selectAccountUser = accountUserId => ({
    type: 'SELECT_ACCOUNT_USER',
    payload: accountUserId,
});

export const selectEmployee = employeeId => ({
    type: 'SELECT_EMPLOYEE',
    payload: employeeId,
});

export const selectCustomer = customerId => ({
    type: 'SELECT_CUSTOMER',
    payload: customerId,
});

export const selectAccount = accountId => ({
    type: 'SELECT_ACCOUNT',
    payload: accountId,
});

export const selectInventoryItem = itemId => ({
    type: 'SELECT_INVENTORY_ITEM',
    payload: itemId,
});

export const selectPayrollItem = itemId => ({
    type: 'SELECT_PAYROLL_ITEM',
    payload: itemId,
});

export const selectVendor = vendorId => ({
    type: 'SELECT_VENDOR',
    payload: vendorId,
});

export const selectPermissionsGroup = permissionsGroupId => ({
    type: 'SELECT_PERMISSIONS_GROUP',
    payload: permissionsGroupId,
});
