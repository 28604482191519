import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import Select from '../../components/Select.js';
import className from 'classnames';
import SaveLoading from '../../components/SaveLoading.js';
import styles from '../../../css/free_trial/MobileFreeTrial.module.css';
import { Row, Col } from 'react-bootstrap';
import {
    isCompanyNameValid,
    isNameValid,
    isPasswordsValid,
    isValidPhone,
    isValidEmail,
} from '../../../utils/CommonFunctions.js';

const qbTypes = [
    { label: 'Quickbooks Online', value: 'Online' },
    { label: 'Quickbooks Desktop', value: 'Desktop' },
    { label: 'No Integration', value: 'None' },
];

export default class MobileFreeTrial extends Component {
    state = {
        fNameInvalidMsg: '',
        lNameInvalidMsg: '',
        emailInvalidMsg: '',
        phoneInvalidMsg: '',
        passwordlInvalidMsg: '',
        cNameInvalidMsg: '',
    };
    setFields = fields => {
        this.setState({ ...fields });
    };
    redirect = to => {
        this.props.history.push(to);
    };
    render() {
        // const { promo_code, handlePromoCodeOnChange, verifyPromoCode } = this.props;
        const { first_name, last_name, email, phone, name, password, confirmPassword } = this.props;

        return (
            <div>
                <div className={className('className', styles.mobileFreeTrialContainer)}>
                    {/* <div className={className('className', styles.freeTrialBanner)}>
                        <div className={className('className', styles.getStarted)}>
                            Get started now for FREE!
                        </div>
                        <div className={className('className', styles.simplifyYourTime)}>
                            Simplify your time management today. No credit card required. Cancel
                            anytime.
                        </div>
                    </div> */}
                    <Row>
                        <div className={className('className', styles.logoContainer)}>
                            <img
                                style={{ width: 120, height: 35 }}
                                srcSet="/img/sales/logo@2x-dark.png "
                                src="/img/sales/logo@2x-dark.png"
                                alt="Minute7"
                            />
                        </div>
                    </Row>
                    <Row className="ftRowAlightCenter">
                        <div className="ftSubHeader">
                            Simplify your time and expense management today.
                        </div>
                    </Row>
                    <Row className="ftRowAlightCenter">
                        <div className="ftSubHeader2 ">
                            No credit card required. Cancel anytime.
                        </div>
                    </Row>
                    <form
                        onSubmit={this.props.createAccount}
                        className={className('className', styles.freeTrialInputContainer)}
                    >
                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                <Row className={className('className', styles.mobileRow)}>
                                    <div className="ftLabel2 largeBodyText greyText">
                                        First Name
                                    </div>
                                    <div className={className('className', styles.required)}>*</div>
                                </Row>
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Input
                                    className={
                                        this.state.fNameInvalidMsg === '' ? '' : 'ausInvalidInput'
                                    }
                                    name="first_name"
                                    value={this.props.first_name}
                                    onChange={this.props.handleFirstNameOnChange}
                                    onBlur={
                                        this.props.first_name.length !== 0
                                            ? () =>
                                                  isNameValid(this.props.first_name)
                                                      ? this.setFields({ fNameInvalidMsg: '' })
                                                      : this.setFields({
                                                            fNameInvalidMsg:
                                                                'First name contains only  1 to 50 alphabets.',
                                                        })
                                            : ''
                                    }
                                />
                                <div className="ftInputError">
                                    {!this.state.fNameInvalidMsg === '' &&
                                    !this.props.first_name === '' ? (
                                        <label />
                                    ) : (
                                        <label className="error-message">
                                            {this.state.fNameInvalidMsg}
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                <Row className={className('className', styles.mobileRow)}>
                                    <div className="ftLabel2 largeBodyText greyText">Last Name</div>
                                    <div className={className('className', styles.required)}>*</div>
                                </Row>
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Input
                                    className={
                                        this.state.lNameInvalidMsg === '' ? '' : 'ausInvalidInput'
                                    }
                                    name="last_name"
                                    value={last_name}
                                    onChange={this.props.handleLastNameOnChange}
                                    onBlur={
                                        last_name.length !== 0
                                            ? () =>
                                                  isNameValid(last_name)
                                                      ? this.setFields({ lNameInvalidMsg: '' })
                                                      : this.setFields({
                                                            lNameInvalidMsg:
                                                                'Last name contains only  1 to 50 alphabets.',
                                                        })
                                            : ''
                                    }
                                />
                                <div className="ftInputError">
                                    {!this.state.lNameInvalidMsg === '' && !last_name === '' ? (
                                        <label />
                                    ) : (
                                        <label className="error-message">
                                            {this.state.lNameInvalidMsg}
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                <Row className={className('className', styles.mobileRow)}>
                                    <div className="ftLabel2 largeBodyText greyText">Email</div>
                                    <div className={className('className', styles.required)}>*</div>
                                </Row>
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Input
                                    className={
                                        this.state.emailInvalidMsg === '' ? '' : 'ausInvalidInput'
                                    }
                                    name="email"
                                    placeholder="example@minute7.com"
                                    value={email}
                                    onChange={this.props.handleEmailOnChange}
                                    onBlur={
                                        email.length !== 0
                                            ? () =>
                                                  isValidEmail(email)
                                                      ? this.setFields({ emailInvalidMsg: '' })
                                                      : this.setFields({
                                                            emailInvalidMsg:
                                                                'Email must be between to 4 to 40 characters (e.g. example@minute7.com)',
                                                        })
                                            : ''
                                    }
                                />
                                <div className="ftInputError">
                                    {!this.state.emailInvalidMsg === '' &&
                                    !this.props.email === '' ? (
                                        <label />
                                    ) : (
                                        <label className="error-message">
                                            {this.state.emailInvalidMsg}
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                <Row className={className('className', styles.mobileRow)}>
                                    <div className="ftLabel2 largeBodyText greyText">
                                        Phone Number
                                    </div>
                                    <div className={className('className', styles.required)}>*</div>
                                </Row>
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                {/* <Input
                                    name="phone_number"
                                    placeholder="###-###-####"
                                    value={this.props.phone}
                                    onChange={this.props.handlePhoneOnChange}
                                /> */}

                                <PhoneInput
                                    className={
                                        this.state.phoneInvalidMsg === '' ? '' : 'ausInvalidInput'
                                    }
                                    name="phone_number"
                                    placeholder="###-###-####"
                                    containerClass="phone-input"
                                    country={'us'}
                                    value={phone}
                                    onChange={this.props.handlePhoneOnChange}
                                    onBlur={
                                        phone.length !== 0
                                            ? () =>
                                                  isValidPhone(phone)
                                                      ? this.setFields({ phoneInvalidMsg: '' })
                                                      : this.setFields({
                                                            phoneInvalidMsg:
                                                                'Enter a valid phone number (e.g. 012-345-6789)',
                                                        })
                                            : ''
                                    }
                                />
                                <div className="ftInputError">
                                    {!this.state.phoneInvalidMsg === '' && !phone === '' ? (
                                        <label />
                                    ) : (
                                        <label className="error-message">
                                            {this.state.phoneInvalidMsg}
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                <Row className={className('className', styles.mobileRow)}>
                                    <div className="ftLabel2 largeBodyText greyText">Password</div>
                                    <div className={className('className', styles.required)}>*</div>
                                </Row>
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Input
                                    name="password"
                                    type="password"
                                    value={this.props.password}
                                    onChange={this.props.handlePasswordOnChange}
                                />
                            </div>
                        </div>

                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                <Row className={className('className', styles.mobileRow)}>
                                    <div className="ftLabel2 largeBodyText greyText">
                                        Confirm Password
                                    </div>
                                    <div className={className('className', styles.required)}>*</div>
                                </Row>
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Input
                                    className={
                                        this.state.passwordlInvalidMsg === ''
                                            ? ''
                                            : 'ausInvalidInput'
                                    }
                                    name="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={this.props.handleConfirmPasswordOnChange}
                                    onBlur={
                                        confirmPassword.length !== 0
                                            ? () =>
                                                  isPasswordsValid(password, confirmPassword)
                                                      ? this.setFields({ passwordlInvalidMsg: '' })
                                                      : this.setFields({
                                                            passwordlInvalidMsg:
                                                                'Make sure your password minimum length is 6 and must match to the confirm password',
                                                        })
                                            : ''
                                    }
                                />
                                <div className="ftInputError">
                                    {!this.state.passwordlInvalidMsg === '' &&
                                    !confirmPassword === '' ? (
                                        <label />
                                    ) : (
                                        <label className="error-message">
                                            {this.state.passwordlInvalidMsg}
                                        </label>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                <Row className={className('className', styles.mobileRow)}>
                                    <div className="ftLabel2 largeBodyText greyText">
                                        {' '}
                                        Integration Type
                                    </div>
                                    <div className="required">*</div>
                                </Row>
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Select
                                    options={qbTypes}
                                    valueForOption={option => option.value}
                                    labelForOption={option => option.label}
                                    value={this.props.qb_type}
                                    onChange={this.props.handleQbTypeOnChange}
                                />
                            </div>
                        </div>

                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                <Row className={className('className', styles.mobileRow)}>
                                    <div className="ftLabel2 largeBodyText greyText">
                                        Company Name
                                    </div>
                                    <div className={className('className', styles.required)}>*</div>
                                </Row>
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Input
                                    className={
                                        this.state.cNameInvalidMsg !== '' && name !== ''
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    name="company_name"
                                    value={name}
                                    onChange={this.props.handleNameOnChange}
                                    onBlur={
                                        name.length !== 0
                                            ? () =>
                                                  isCompanyNameValid(name)
                                                      ? this.setFields({ cNameInvalidMsg: '' })
                                                      : this.setFields({
                                                            cNameInvalidMsg:
                                                                "Only Alphanumeric and some Special  Symbols (' - & . , _) allowed between 3 to 100 characters",
                                                        })
                                            : ''
                                    }
                                />
                                <div className="ftInputError">
                                    {this.state.cNameInvalidMsg !== '' || name === '' ? (
                                        <label className="error-message">
                                            {this.state.cNameInvalidMsg}
                                        </label>
                                    ) : (
                                        <label />
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* 
                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                Promo Code
                                <div className="requiredLabel">Optional</div>
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Input
                                    name="promo_code"
                                    value={promo_code}
                                    onChange={handlePromoCodeOnChange}
                                    onBlur={verifyPromoCode}
                                />
                            </div>
                        </div> */}
                        {/* 
                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                Company Size
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Input
                                    name="company_size"
                                    value={this.props.size}
                                    onChange={this.props.handleSizeOnChange}
                                />
                            </div>
                        </div>

                        <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                Industry
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <Input
                                    name="industry"
                                    value={this.props.industry}
                                    onChange={this.props.handleIndustryOnChange}
                                />
                            </div>
                        </div> */}

                        {/* <div className={className('className', styles.inputAndLabelContainer)}>
                            <div className={className('className', styles.labelContainer)}>
                                &nbsp;
                            </div>
                            <div className={className('className', styles.inputContainer)}>
                                <div className="recaptcha">
                                    <ReCAPTCHA
                                        ref={this.props.recaptchaRef}
                                        sitekey={this.props.recaptchaKey}
                                        onChange={this.props.handleCaptchaOnChange}
                                        />
                                </div>
                            </div>
                        </div> */}
                        <Row className="ftrow, ftRowAlightCenter">
                            <div className={className('className', styles.signUp)}>
                                {!this.props.loading ? (
                                    <button type="submit" className="primaryButton ftButton">
                                        Start Your Free Trial
                                    </button>
                                ) : (
                                    <SaveLoading />
                                )}
                            </div>
                        </Row>
                        <Row className="ftrow, ftRowAlightCenter">
                            <div className="ftLabel2 largeBodyText greyText">Need </div>
                            <div className="fbLinkRow" onClick={() => this.redirect('/contact')}>
                                Assistance?
                            </div>
                        </Row>
                    </form>
                </div>
            </div>
        );
    }
}
