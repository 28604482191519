import React from 'react';

const LimitServiceItems = () => (
    <div>
        <div className="sectionQuestion">
            Can I limit which of my "Service Items" a user can see?
        </div>
        <div className="sectionAnswer">
            Yes. For any Minute7 user who does not have permission to Manage Users, limiting which
            Service Items the user can see works just like limiting which Customers the user can
            see. In order to specify which Service Items a user can see, follow these steps:
            <ol>
                <li>
                    Set the option to <b>"Limit this user to certain service items"</b> to{' '}
                    <b>"Yes"</b>.
                </li>
                <li>
                    In the multiple-select box that opens up,{' '}
                    <b>select the Service Items you want this user to see</b>. Click on each
                    customer you want to select to move it to the right hand side to include it or
                    left hand side to exclude it.
                </li>
                <li>
                    <b>Click "Save changes"</b> to save the changes you have made, and make sure
                    that the user whose settings you have just altered{' '}
                    <b>logs out of any open Minute7 sessions</b>
                    and <b>logs back in</b> before entering time again.
                </li>
            </ol>
        </div>
    </div>
);

export default LimitServiceItems;
