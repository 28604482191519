export const INITIAL_STATE = {
    allItems: [],
};

export const itemReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'RECEIVE_BUSINESS_RESOURCES': {
            if ('allItems' in action.payload) {
                const newState = { ...state };
                ['allItems'].forEach(k => {
                    if (k in action.payload) {
                        newState[k] = action.payload[k];
                    }
                });
                return newState;
            } else {
                return state;
            }
        }
        case 'SET_ALL_ITEMS':
            return {
                ...state,
                allItems: action.payload,
            };
        case 'ADD_ITEM':
            return {
                ...state,
                isSaving: true,
            };
        case 'ADD_ITEM_SUCCESS':
            return {
                ...state,
                isSaving: false,
            };
        case 'ADD_ITEM_ERROR':
            return {
                ...state,
                isSaving: false,
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default itemReducer;
