import React from 'react';

const QbClassesTime = () => (
    <div>
        <div className="sectionQuestion">
            How can I assign QuickBooks classes to my time entries?
        </div>
        <div className="sectionAnswer">
            If you use classes in QuickBooks, Minute7 will automatically upload your list of classes
            when you sync. If you would like to allow your employees and vendors to assign
            QuickBooks classes to the time entries they create, you can go to your account settings
            page and change the option that says{' '}
            <b>Allow users to assign QuickBooks classes to time entries</b> to <b>'Yes'</b>.
            <br /> <br />
            Setting this option to 'Yes' will cause a new drop-down list with your company's class
            choices to appear in the time entry form on your timesheets. If a time entry has a class
            assigned to it, the class name will appear in the third row of the "Customer:Job,
            Service, Class" field.
            <br /> <br />
            The classes assigned to time entries are exported to QuickBooks, and can be viewed and
            edited from within QuickBooks after export.
        </div>
    </div>
);

export default QbClassesTime;
