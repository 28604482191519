import React, { Component } from 'react';
import { Input } from 'reactstrap';
import className from 'classnames';
import SaveLoading from '../../components/SaveLoading.js';
import styles from '../../../css/login/MobileLogin.module.css';

export default class MobileLogin extends Component {
    render() {
        return (
            <div className={className(className, styles.mobileLogin)}>
                <div className={className(className, styles.mobileAppAd)}>
                    <div className={className(className, styles.appStoreButtonContainer)}>
                        <div className={className(className, styles.mobileAppText)}>
                            Minute7 has native mobile apps for Android and iOS.
                        </div>
                        <div className={className(className, styles.mobileAppText)}>
                            Please download your respective mobile app for a better experience here:
                        </div>
                        <div>
                            <a
                                href="https://itunes.apple.com/us/app/minute7/id812540141?ls=1&mt=8"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="appstoreButton"
                                    src="./appstore_btn.png"
                                    alt="Download from the App Store"
                                />
                            </a>
                        </div>
                        <br />
                        <div>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.minute7.mobile"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className="appstoreButton"
                                    src="./google_play_store_btn.png"
                                    alt="Download from the Play Store"
                                />
                            </a>
                        </div>
                    </div>
                    <div className={className(className, styles.mobileAppText)}>
                        If you would like to access Minute7 via a mobile browser, you can login
                        below:
                    </div>
                </div>
                <div
                    className={className(className, styles.mobileLoginContainer) + ' cardContainer'}
                >
                    <div className="lLabelAndInput">
                        <div className="lLabel">Email</div>
                        <Input
                            id="emailInput"
                            onChange={newEmail => this.props.emailOnChange(newEmail)}
                            className="loginInput"
                            type="text"
                        />
                    </div>
                    <div className="lLabelAndInput">
                        <div className="lLabel">Password</div>
                        <Input
                            id="passwordInput"
                            onChange={newPassword => this.props.passwordOnChange(newPassword)}
                            className="loginInput"
                            type="password"
                        />
                    </div>
                    <div className="lButtonContainer">
                        <button
                            className="lResetPasswordButton secondaryButton"
                            onClick={() => this.props.history.push('/password_reset')}
                        >
                            Reset Your Password
                        </button>
                        <div>
                            {!this.props.loading ? (
                                <button
                                    id="loginButton"
                                    className="lButton primaryButton"
                                    onClick={this.props.login}
                                >
                                    Login
                                </button>
                            ) : (
                                <div className="lButton">
                                    <SaveLoading />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
