/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const TrackingCreditCardExpenses = () => (
    <div>
        <div className="sectionQuestion">
            Expense tracking is much easier with Minute 7 as it allows users to track both
            reimbursable, and credit card expenses.
        </div>
        <div className="sectionQuestion">
            <b>Credit Card Expenses:</b>
        </div>
        <div className="sectionAnswer">
            Credit card expenses are those expenses purchases have been made by a company issued
            credit card. Since these expenses have already paid for, your company does not want to
            generate a bill for them in your QuickBooks company file. Therefore, once approved by
            the manager, all credit card expense entries are synced as “Paid” in QuickBooks.
        </div>
        <div className="sectionQuestion">
            <b>Example</b>
        </div>
        <div className="sectionAnswer">
            A vendor purchased $500 in office supplies on a company issued credit card, in this
            situation, the vendor would need to select a payment account from which an expense was
            paid as shown in the screenshot below:
            <img
                src="help_images/expense_entries/credit_card_expense_entry.png"
                className="w-100 m-2"
            />
            Therefore, a credit card expense entry in Minute 7 will look like:
            <img
                src="help_images/expense_entries/credit_card_expense_entry_2.png"
                className="w-100 m-2"
            />
            Once this record is approved and synced by the manager; the expense will be displayed
            with type “CC charge” and status “Paid” in QuickBooks.
        </div>
        <div className="sectionQuestion">
            Difference Between Credit Card and Reimbursible Expense Entries:
        </div>
        <div className="sectionAnswer">
            You can see the difference in QuickBooks Desktop by noticing the different status and
            type of two expense entries when synced with QuickBooks below:
            <img
                src="help_images/expense_entries/credit_card_expense_entry_desktop.png"
                className="w-100 m-2"
            />
        </div>
    </div>
);

export default TrackingCreditCardExpenses;
