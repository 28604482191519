export const setCanViewEmployees = payload => ({
    type: 'SET_CAN_VIEW_EMPLOYEES',
    payload,
});

export const setCanEditEmployees = payload => ({
    type: 'SET_CAN_EDIT_EMPLOYEES',
    payload,
});

export const setAllEmployees = payload => ({
    type: 'SET_ALL_EMPLOYEES',
    payload,
});

export const refreshEmployees = () => ({
    type: 'REFRESH_EMPLOYEES',
});

export const refreshEmployeesSuccess = () => ({
    type: 'REFRESH_EMPLOYEES_SUCCESS',
});

export const addEmployee = (employee, callback) => ({
    type: 'ADD_EMPLOYEE',
    employee,
    callback,
});

export const addEmployeeSuccess = employee => ({
    type: 'ADD_EMPLOYEE_SUCCESS',
    employee,
});

export const addEmployeeError = error => ({
    type: 'ADD_EMPLOYEE_ERROR',
    error,
});

export const updateEmployee = (employee, callback) => ({
    type: 'UPDATE_EMPLOYEE',
    employee,
    callback,
});

export const updateEmployeeSuccess = employee => ({
    type: 'UPDATE_EMPLOYEE_SUCCESS',
    employee,
});

export const updateEmployeeError = error => ({
    type: 'UPDATE_EMPLOYEE_ERROR',
    error,
});

export const hideEmployee = employeeId => ({
    type: 'DEACTIVATE_EMPLOYEE',
    employeeId,
});

export const unhideEmployee = employeeId => ({
    type: 'ACTIVATE_EMPLOYEE',
    employeeId,
});

export const unhideEmployeeError = error => ({
    type: 'ACTIVATE_EMPLOYEE_ERROR',
    error,
});

export const hideEmployeeError = error => ({
    type: 'DEACTIVATE_EMPLOYEE_ERROR',
    error,
});
