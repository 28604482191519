import { createQueryString, postRequestWithFormData } from '../utils/HelperFunctions.js';
import GoogleAnalytics from '../utils/GoogleAnalytics.js';

/**
 * Headers are set by back-end to force download. Won't redirect main window
 */

export const getTimePdf = paramsObject => {
    GoogleAnalytics.report('Time PDF');
    const url = createQueryString(paramsObject, 'reports/time_pdf');
    window.location = url;
};

export const getTimeExcel = paramsObject => {
    GoogleAnalytics.report('Time Excel');
    const url = createQueryString(paramsObject, 'reports/time_xlsx');
    window.location = url;
};

export const getTimeCsv = paramsObject => {
    GoogleAnalytics.report('Time CSV');
    const url = createQueryString(paramsObject, 'reports/time_csv');
    window.location = url;
};

export const getExpensePdf = paramsObject => {
    GoogleAnalytics.report('Expense PDF');
    const url = createQueryString(paramsObject, 'reports/expenses_pdf');
    window.location = url;
};

export const getExpenseExcel = paramsObject => {
    GoogleAnalytics.report('Expense Excel');
    const url = createQueryString(paramsObject, 'reports/expenses_xlsx');
    window.location = url;
};

export const getExpenseCsv = paramsObject => {
    GoogleAnalytics.report('Expense CSV');
    const url = createQueryString(paramsObject, 'reports/expenses_csv');
    window.location = url;
};

export const getExpenseAttachments = paramsObject => {
    GoogleAnalytics.report('Expense Attachments');
    const url = createQueryString(paramsObject, 'reports/download_expense_attachments');
    window.location = url;
};

export const segmentTime = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'reports/segment_time', paramsObject).then(
        res => res.SegmentedTime
    );
};

export const segmentExpenses = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'reports/segment_expenses', paramsObject).then(
        res => res.SegmentedExpenses
    );
};
