import React from 'react';
import Icon from 'react-icons-kit';
import { ic_radio_button_checked as radioChecked } from 'react-icons-kit/md/ic_radio_button_checked';
import { ic_radio_button_unchecked as radioUnchecked } from 'react-icons-kit/md/ic_radio_button_unchecked';

const RadioInput = ({ onClick, checked, label }) => (
    <label className="radioLabel mr-left-15" onClick={onClick}>
        <Icon size={18} className="radioIcon" icon={checked ? radioChecked : radioUnchecked} />
        {label}
    </label>
);

export default RadioInput;
