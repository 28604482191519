import React, { Component } from 'react';
import $ from 'jquery';
import scrollTo from '../../utils/scrollTo';
import '../../css/SecondaryHeaderBar.css';

const landingPageLocations = ['home', 'features', 'about', 'testimonials', 'pricing'];

export default class SecondaryHeaderBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSection: '',
            isListOpen: false,
        };
    }

    componentDidMount() {
        // Call to ensure we highlight the correct scrolled section
        this.figureActiveSection();
        if (
            'location' in this.props.history &&
            'state' in this.props.history.location &&
            this.props.history.location.state &&
            'scrollTo' in this.props.history.location.state
        ) {
            this.scrollTo(this.props.history.location.state.scrollTo);
        }
        window.addEventListener('scroll', this.animateHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.animateHeader);
    }

    figureActiveSection = () => {
        try {
            let homeScroll = $('#home').offset().top;
            let featureScroll = $('#features').offset().top;
            let aboutScroll = $('#about').offset().top;
            let testimoniesScroll = $('#testimonials').offset().top;
            let pricingScroll = $('#pricing').offset().top;
            let scrollY = window.scrollY;
            if (scrollY >= homeScroll - 150 && scrollY <= homeScroll + 150) {
                this.setState({ activeSection: 'home' });
            } else if (scrollY >= featureScroll - 150 && scrollY <= featureScroll + 150) {
                this.setState({ activeSection: 'features' });
            } else if (scrollY >= aboutScroll - 150 && scrollY <= aboutScroll + 150) {
                this.setState({ activeSection: 'about' });
            } else if (scrollY >= testimoniesScroll - 150 && scrollY <= testimoniesScroll + 150) {
                this.setState({ activeSection: 'testimonials' });
            } else if (scrollY >= pricingScroll - 150 && scrollY <= pricingScroll + 150) {
                this.setState({ activeSection: 'pricing' });
            }
        } catch (error) {
            this.setState({ activeSection: '' });
        }
    };

    scrollTo = section => {
        const pathname = this.props.history.location.pathname.replace('/', '');
        if (landingPageLocations.includes(section) && !landingPageLocations.includes(pathname)) {
            this.redirect('/home', section);
        }

        if (this.props.fromHome && landingPageLocations.includes(section)) {
            let scrollY = $(`#${section}`).offset().top;

            scrollTo({
                top: scrollY - 40,
                left: 0,
                behavior: 'smooth',
            });

            this.setState({
                activeSection: section,
                isListOpen: false,
            });
        }
    };

    redirect = (to, scrollTo) => {
        this.setState({
            activeSection: to.replace('/', ''),
            isListOpen: false,
        });
        this.props.history.push(to, { scrollTo: scrollTo });
    };

    animateHeader = () => {
        const yOffset = window.pageYOffset;
        // @TODO not working?
        if (this.props.history.location.pathname === 'home') {
            if (yOffset === 0) {
                $('#homeHeaderBar').css({
                    'background-color': 'transparent',
                    'padding-top': '20px',
                });
            } else {
                $('#homeHeaderBar').css({
                    'background-color': 'black',
                    'padding-top': '0px',
                });
            }
        }
    };

    render() {
        const pathname = this.props.history.location.pathname;
        const { activeSection } = this.state;

        return (
            <div
                className={this.props.fromHome ? 'landingPage' : 'homeHeaderBar'}
                id="homeHeaderBar"
            >
                <div className="homeLogoContainer">
                    <img
                        srcSet="/img/sales/logo@2x.png 2x, /img/sales/logo.png 1x"
                        src="/img/sales/logo.png"
                        alt="Minute7"
                    />
                </div>
                <div className="homeLinksContainer">
                    <div
                        className={'homeLink' + (activeSection === 'home' ? ' homeActiveLink' : '')}
                        onClick={() => this.scrollTo('home')}
                    >
                        Home
                    </div>
                    <div
                        className={
                            'homeLink' + (activeSection === 'features' ? ' homeActiveLink' : '')
                        }
                        onClick={() => this.scrollTo('features')}
                    >
                        Features
                    </div>
                    <div
                        className={
                            'homeLink' + (activeSection === 'about' ? ' homeActiveLink' : '')
                        }
                        onClick={() => this.scrollTo('about')}
                    >
                        About
                    </div>
                    <div
                        className={
                            'homeLink' + (activeSection === 'testimonials' ? ' homeActiveLink' : '')
                        }
                        onClick={() => this.scrollTo('testimonials')}
                    >
                        Testimonies
                    </div>
                    <div
                        className={
                            'homeLink' + (activeSection === 'pricing' ? ' homeActiveLink' : '')
                        }
                        onClick={() => this.scrollTo('pricing')}
                    >
                        Pricing
                    </div>
                    <div
                        className={'homeLink' + (pathname === '/contact' ? ' homeActiveLink' : '')}
                        onClick={() => this.redirect('/contact')}
                    >
                        Contact Us
                    </div>
                    {/* <div
                        className={
                            'homeLink' + (pathname === '/quick_tour' ? ' homeActiveLink' : '')
                        }
                        onClick={() => this.redirect('/quick_tour')}
                    >
                        Quick Tour
                    </div> */}
                    <div
                        className={
                            'homeLink' +
                            (pathname === '/support' || pathname.includes('/help')
                                ? ' homeActiveLink'
                                : '')
                        }
                        onClick={() => this.redirect('/support')}
                    >
                        Support
                    </div>
                    <div
                        className={'homeLink' + (pathname === '/login' ? ' homeActiveLink' : '')}
                        onClick={() => this.redirect('/login')}
                    >
                        Sign In
                    </div>
                    <button
                        className="homeFreeTrial primaryButton"
                        color="success"
                        onClick={() => this.redirect('/free_trial')}
                    >
                        Free Trial
                    </button>
                </div>
            </div>
        );
    }
}
