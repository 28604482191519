import _ from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ReactQuoraPixel from 'react-quora-pixel';
import { addEmployee } from '../../actions/EmployeeActions';
import {  updateUser } from '../../actions/AccountUserActions';
import { createAccount } from '../../requests/AccountRequests.js';
import { verifyPromoCode } from '../../requests/AccountRequests.js';
import {
    isNameValid,
    isValidEmail,
    isCompanyNameValid,
    isValidPhone,
    isPasswordsValid,
} from '../../utils/CommonFunctions.js';
import { login, showToast } from '../../actions/Actions.js';
import MobileFreeTrial from './components/MobileFreeTrial.js';
import DefaultFreeTrial from './components/DefaultFreeTrial.js';
import scrollTo from '../../utils/scrollTo';
import '../../css/free_trial/FreeTrial.css';
import MaintenancePlaceholder from '../login/components/MaintenancePlaceholder.js';

// const recaptchaRef = React.createRef();
// const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

class FreeTrial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // AccountUser
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',

            // Account
            size: '',
            qb_type: '',
            name: '',
            industry: '',
            loading: false,
            redirectToHome: false,
            referral: '',
            promo_code: '',

            // Captcha
            // captcha: '',
            payroll_item_id: '',
            payroll_item_ids: [],
            hidden: false,
        };
    }

    componentWillReceiveProps(props) {
        // resources have now loaded, proceed with login
        if (props.isLoggedIn && props.resourcesLoaded && !this.props.resourcesLoaded) {
            this.setState({
                redirectToHome: true,
            });
        }
    }

    componentDidMount() {
        scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        window.rewardful('ready', () => {
            if (window.Rewardful.referral) {
                this.setState({ referral: window.Rewardful.referral });
            }
        });
    }

    /**
     * Cleaner passing as a prop
     */
    handleOnChanges = {
        handleFirstNameOnChange: newName => {
            this.setState({ first_name: newName.target.value });
        },
        handleLastNameOnChange: newName => {
            this.setState({ last_name: newName.target.value });
        },
        handleEmailOnChange: newEmail => {
            this.setState({ email: newEmail.target.value });
        },
        handlePhoneOnChange: newPhone => {
            this.setState({ phone: newPhone });
        },
        handlePasswordOnChange: newPassword => {
            this.setState({ password: newPassword.target.value });
        },
        handleConfirmPasswordOnChange: newPassword => {
            this.setState({ confirmPassword: newPassword.target.value });
        },
        handleSizeOnChange: newSize => {
            if (isNaN(newSize.target.value)) {
                this.setState({ size: this.state.size });
            } else {
                this.setState({ size: newSize.target.value });
            }
        },
        handleQbTypeOnChange: newQbType => {
            this.setState({ qb_type: newQbType }); // Uses listOption - dont use .target.value
        },
        handleNameOnChange: newName => {
            this.setState({ name: newName.target.value });
        },
        handleIndustryOnChange: newIndustry => {
            if (/^[a-zA-Z]*$/.test(newIndustry.target.value)) {
                this.setState({ industry: newIndustry.target.value });
            } else {
                this.setState({ industry: this.state.industry });
            }
        },
        handlePromoCodeOnChange: newPromoCode => {
            this.setState({ promo_code: newPromoCode.target.value });
        },
        // handleCaptchaOnChange: newCaptcha => {
        //     this.setState({ captcha: newCaptcha });
        // },
    };

    requiredFieldsAndCheckFunctions = {
        first_name: () => isNameValid(this.state.first_name),
        last_name: () => isNameValid(this.state.last_name),
        email: () => isValidEmail(this.state.email),
        phone: () => isValidPhone(this.state.phone),
        // captcha: () => this.isCaptchaValid(),
        password: () => isPasswordsValid(this.state.password, this.state.confirmPassword),
        confirmPassword: () => isPasswordsValid(this.state.password, this.state.confirmPassword),
        name: () => isCompanyNameValid(this.state.name),
        qb_type: () => this.state.qb_type !== '',
    };

    isCaptchaValid = () => {
        // TODO: Fix captcha key
        // if (this.state.captcha == null || this.state.captcha.length <= 0) {
        //     this.props.showToast(
        //         'Please confirm that you are not a robot',
        //         'warning'
        //     );
        //     return false;
        // }
        return true;
    };

    checkIfRequiredFieldsAreValid = () => {
        const requiredFields = Object.keys(this.requiredFieldsAndCheckFunctions);
        const notValid = requiredFields.some(field => {
            if (this.state[field] === '' && field !== 'captcha') {
                this.props.showToast('Make sure all required fields are filled out', 'warning');
                return true;
            }
            if (!this.requiredFieldsAndCheckFunctions[field]()) {
                return true;
            }
            return false;
        });
        return !notValid;
    };

    login = () => {
        this.props.login({ email: this.state.email, password: this.state.password });
    };

    constructAccountAndUser = () => {
        const { promo_code } = this.state;

        let data = {};
        data['AccountUser[first_name]'] = this.state.first_name;
        data['AccountUser[last_name]'] = this.state.last_name;
        data['AccountUser[email]'] = this.state.email;
        data['AccountUser[phone]'] = this.state.phone;
        data['AccountUser[password]'] = this.state.password;
        data['Account[company_size]'] = this.state.size;
        data['Account[qb_type]'] = this.state.qb_type;
        data['Account[name]'] = this.state.name;
        data['Account[industry]'] = this.state.industry;
        data['Account[referral]'] = this.state.referral;
        data['Account[promo_code]'] = promo_code;
        // data['Account[captcha]'] = this.state.captcha;
        return data;
    };

    constructEmployee = (res) => {
        const data = {};
        data['account_id'] = res.account_id;
        data['name'] = this.state.first_name + ' ' + this.state.last_name;
        data['first_name'] = this.state.first_name;
        data['last_name'] = this.state.last_name;
        data['email'] = this.state.email;
        data['payroll_item_id'] = '';
        data['payroll_item_ids'] = [];
        data['hidden'] = false;
        return data;
    };

    createAccount = event => {
        event.preventDefault();
        if (!this.checkIfRequiredFieldsAreValid()) {
            return;
        }

        this.setState({ loading: true });

        let data = this.constructAccountAndUser();
        createAccount(data)
            .then(res => {
                ReactQuoraPixel.track('CompleteRegistration');
           
                if(this.state.qb_type ==="None"){
                    let employee =  this.constructEmployee(res);
                    
                    this.props.addEmployee(employee, newEmployee => {
                        if (newEmployee === false) { 
                            this.props.showToast(
                                'Successfully created account! Logging you in now.',
                                'positive'
                            );
                            window.lintrk('track', { conversion_id: 14782610 });
                            setTimeout(() => {
                                this.login();
                            }, 2500);
                            setTimeout(() => {
                                this.props.history.push('/timesheets');;;
                            }, 2500);
                            return;
                        }
                        else{
                            this.props.updateUser(
                                {
                                    id: res.user_id,
                                    employee_id: newEmployee.id,
                                    account_id:  res.account_id
                                },
                                () => {
                                    window.lintrk('track', { conversion_id: 14782610 });
                                    setTimeout(() => {
                                        this.login();
                                    }, 2500);
                                    setTimeout(() => {
                                        this.props.history.push('/timesheets');;;
                                    }, 2500);
                                }
                            );
                        }
                    });
                }else{
                    window.lintrk('track', { conversion_id: 14782610 });
                    setTimeout(() => {
                        this.login();
                    }, 2500);
                }
            })
            .catch(error => {
                this.props.showToast(error.message, 'negative');
                this.setState({ loading: false });
            });
            
    };

    verifyPromoCode = () => {
        const { promo_code } = this.state;

        if (promo_code === '') return;

        this.setState({ loading: true });
        this.requestPromoCodeVerification();
    };

    constructPromoCodeData = () => {
        const { promo_code } = this.state;
        let data = {};
        data['PromoCode[code]'] = promo_code;
        return data;
    };

    requestPromoCodeVerification = () => {
        verifyPromoCode(this.constructPromoCodeData())
            .then(response => {
                this.props.showToast(response.message, 'positive');
            })
            .catch(error => {
                this.props.showToast(error.message, 'negative');
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        const { redirectToHome } = this.state;

        if (redirectToHome === true) {
            return <Redirect to="/" />;
        }
        if (window.location.search === '?bypass_server_maintenance=true') {
            return <MaintenancePlaceholder />;
        } else if (isMobile) {
            return (
                <MobileFreeTrial
                    {...this.state}
                    {...this.handleOnChanges}
                    // recaptchaRef={recaptchaRef}
                    // recaptchaKey={recaptchaKey}
                    history={this.props.history}
                    createAccount={this.createAccount}
                    loading={this.state.loading}
                />
            );
        } else {
            return (
                <DefaultFreeTrial
                    {...this.state}
                    {...this.handleOnChanges}
                    // recaptchaRef={recaptchaRef}
                    // recaptchaKey={recaptchaKey}
                    history={this.props.history}
                    createAccount={this.createAccount}
                    loading={this.state.loading}
                    verifyPromoCode={this.verifyPromoCode}
                />
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: !_.isNil(state.appStore.session_id),
        resourcesLoaded: state.businessResourcesStore.resourcesLoaded,
    };
}

const mapDispatchToProps = {
    showToast,
    login,
    addEmployee,
    updateUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FreeTrial);
