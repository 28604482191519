import React, { Component } from 'react';

export default class Terms extends Component {
    render() {
        return (
            <div className="termsContainer">
                <div className="terms text-center">
                    <h2>Terms of Service</h2>
                    <p>
                        Before you register with Minute7, you must read and agree to this Terms of
                        Service (“TOS”). By using the Minute7 services (the “Service”), you agree to
                        be bound by the TOS and you agree that it is enforceable as if it were a
                        written negotiated agreement signed by Minute7, Inc. (“Minute7”) and you.
                    </p>
                    <p>
                        Minute7 may, in its sole discretion, modify or revise the TOS at any time
                        without notice to you, and you agree to be bound by such modifications or
                        revisions. Unless explicitly stated otherwise, any new features that augment
                        or enhance the current Service shall be subject to the TOS. Continued use of
                        the Service after any such changes shall constitute your consent to such
                        changes. You can review the most current version of the TOS at any time at:{' '}
                        <a href="http://www.minute7.com">http://www.minute7.com</a>
                    </p>

                    <div className="termsBulletsContainer">
                        <h3>Description of Service</h3>
                        <p>
                            Minute7 is a web-based time- adn expense-tracking application from
                            Minute7, Inc. (the “Service”). Your use of the Service is at your sole
                            risk. The service is provided on an AS IS and AS AVAILABLE basis. You
                            understand that your content may be transferred unencrypted over the
                            internet.
                        </p>

                        <h3>Eligibility</h3>
                        <p>
                            You must be at least thirteen (13) years of age to use this Service. You
                            must provide your current, accurate identification, contact, and other
                            information that may be required as part of the registration process
                            and/or continued use of the Service. You are responsible for maintaining
                            the confidentiality of your Service password and account, and are
                            responsible for all activities that occur thereunder. Minute7 reserves
                            the right to refuse service to anyone at any time without notice for any
                            reason.
                        </p>

                        <h3>Proper Use</h3>
                        <p>
                            You agree that you will use the Service in compliance with all
                            applicable local, state, national, and international laws, rules and
                            regulations, including any laws regarding the transmission of technical
                            data exported from your country of residence. You shall not, shall not
                            agree to, and shall not authorize or encourage any third party to: use
                            the Service to upload, transmit or otherwise distribute any content that
                            is unlawful, defamatory, harassing, abusive, fraudulent, obscene,
                            contains viruses, or is otherwise objectionable as reasonably determined
                            by Minute7; use the Service for any fraudulent or inappropriate purpose;
                            resell, duplicate, reproduce or exploit any part of the Service without
                            the express written permission of Minute7; use any robot, spider, other
                            automated device, or manual process to monitor or copy any content from
                            the Service. Violation of any of the foregoing may result in immediate
                            termination of this Agreement, and may subject you to state and federal
                            penalties and other legal consequences.
                        </p>

                        <h3>Payment Terms</h3>
                        <p>
                            Monthly Terms: A valid credit card is required for you to continue using
                            the Service after the 30-day free trial period on a month-to-month
                            basis. The free trial offer entitles new, registered users to a one-time
                            free trial usage of the Services. The service is billed in advance on a
                            monthly basis and is non-refundable. There will be no refunds or credits
                            for partial months of service, upgrade/downgrade accounts, or for months
                            unused with an open account. Yearly Terms: The free trial offer entitles
                            new, registered users to a one-time free trial usage of the Services.
                            Payment via check is required to continue using the Service after the
                            30-day trial period. The service is billed in advance on a yearly basis.
                            Refunds for deleted accounts may be requested for unused months of the
                            service between the date of account removal and the final service date
                            noted on the invoice issued by Minute7. All fees are exclusive of all
                            taxes or duties imposed by governing authorities. You alone are
                            responsible for payment of all such taxes or duties.
                        </p>

                        <h3>Modification to Fees and Services</h3>
                        <p>
                            Minute7 reserves the right to modify, suspend, or discontinue the
                            Service at any time for any reason with or without notice. Minute7
                            reserves the right to change Service fees upon 30 days notice from us.
                            Such notice may be provided at any time by posting the changes to the
                            Minute7 Site (www.minute7.com) or the Service itself.
                        </p>

                        <h3>Cancellation and Termination</h3>
                        <p>
                            You are solely responsible for the proper cancellation of your account.
                            You may Cancel your account any time by clicking on "?" symbol to the
                            left of your name on Minute7 at the top right section of the screen.
                            Then click number 4, "What if I want to deactivate my account?" and
                            click the "Deactivate Account" button. An email or phone request to
                            cancel your account is not considered cancellation. There will be no
                            refund if you cancel the Service before the end of your current, paid-up
                            month, and you will not be charged thereafter. There is no cancellation
                            fee. Cancellation is immediate, and all of your Content will be
                            immediately deleted from the Service upon cancellation. Minute7 may at
                            any time and for any reason terminate the Services, terminate this
                            Agreement, or suspend or terminate your account. In the event of
                            termination, your account will be disabled and you may not be granted
                            access to your account or any files or other content contained in your
                            account although residual copies of information may remain in our
                            system.
                        </p>

                        <h3>Intellectual Property Rights</h3>
                        <p>
                            You acknowledge that Minute7 owns all right, title and interest in and
                            to the Service, including without limitation all intellectual property
                            rights, and such rights are protected by U.S. and international
                            intellectual property laws. You agree that you will not copy, reproduce,
                            alter, modify, or create derivative works from the Service. You also
                            agree that you will not use any robot, spider, other automated device,
                            or manual process to monitor or copy any content from the Service.
                            Minute7 claims no intellectual property rights over the material you
                            provide to the Service.
                        </p>

                        <h3>Warranties and Liability</h3>
                        <p>
                            Minute7 does not warrant that: <br /> (i) The Services will meet your
                            requirements or expectations, <br />
                            (ii) The Service will be timely, uninterrupted or error-free or operate
                            in combination with any other hardware, software, system or data,
                            <br /> (iii) Bugs or errors will be corrected,
                            <br /> (iv) The mathematical calculations performed by the Service is
                            accurate.
                            <br /> The Services are provided to you strictly on an “as is” basis.
                            All conditions, representations and warranties, whether express,
                            implied, statutory or otherwise, including, without limitation, any
                            implied warranty of merchantability, fitness for a particular purpose,
                            or non-infringement of third party rights, are hereby disclaimed to the
                            maximum extent permitted by applicable law. You expressly understand and
                            agree that Minute7 shall not be liable for any direct, indirect,
                            incidental, special, consequential or exemplary damages, including but
                            not limited to, damages for loss of profits, goodwill, use, data or
                            other intangible losses (even if Minute7 has been advised of the
                            possibility of such damages), resulting from your usage of the Service.
                        </p>

                        <h3>Indemnification</h3>
                        <p>
                            You agree to hold harmless and indemnify Minute7, and its affiliates,
                            officers, agents, and employees from and against any third party claim
                            arising from or in any way related to your use of the Service, including
                            any liability or expense arising from all claims, losses, damages
                            (actual and consequential), suits, judgments, litigation costs and
                            attorneys’ fees, of every kind and nature. In such a case, Minute7 will
                            provide you with written notice of such claim, suit or action. The
                            failure of Minute7 to exercise or enforce any right or provision of the
                            TOS shall not constitute a waiver of such right or provision. The TOS
                            constitutes the entire agreement between you and Minute7 and govern your
                            use of the Service, superceding any prior agreements between you and
                            Minute7 (including, but not limited to, any prior versions of the TOS).
                        </p>
                    </div>

                    <p>
                        If you have any questions regarding this Terms of Service or if you wish to
                        discuss the terms and conditions contained herein please contact Minute7 at{' '}
                        <a href="mailto:support@minute7.com">support@minute7.com</a>.
                    </p>
                </div>
            </div>
        );
    }
}
