import React from 'react';

const SetUpQBO = () => (
    <div className="card helpPageContainer">
        <div className="helpPage">
            <div className="sectionQuestion">
                How Do I set up my Minute7 account to sync with QuickBooks Online Edition
            </div>
            <div className="sectionAnswer">
                Once you set your Minute7 account to sync with QuickBooks Online, go to the sync
                page in Minute7 to begin the setup process. QuickBooks Online will walk you through
                the process of signing in, selecting the company you want to sync with, and allowing
                Minute7 to sync with your QuickBooks company file.
                <br /> <br />
                Here are the <b>important</b> settings to use the first time when you subscribe your
                Minute7 account to QuickBooks Online:
                <div className="helpRow">
                    <div className="helpRowColumnOne">
                        <img
                            src="/help_images/syncing_with_qbo/syncing_with_qbo_1.png"
                            className="helpImage"
                            alt="QuickBooks Online Sign In Screen"
                        />
                    </div>
                    <div className="helpRowColumnTwo">
                        Make sure to login using the username and password you normally enter to
                        sign in to view and edit your company file in QuickBooks Online Edition.
                    </div>
                </div>
                <div className="helpRow">
                    <div className="helpRowColumnOne">
                        <img
                            src="/help_images/syncing_with_qbo/syncing_with_qbo_2.png"
                            className="helpImage"
                            alt="Authorize Minute7 to sync with your QuickBooks Online Edition account"
                        />
                    </div>
                    <div className="helpRowColumnTwo">
                        Authorize Minute7 to sync with your QuickBooks Online Edition account.
                    </div>
                </div>
                And then you're good to go! After you sync, you can start entering time and expenses
                immediately
            </div>
        </div>
    </div>
);

export default SetUpQBO;
