import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { isInventoryItemNameValid } from '../../../utils/CommonFunctions.js';
import { Row, Col } from 'react-bootstrap';
import { itemFields } from '../../../config/Fields.js';
import { showToast } from '../../../actions/Actions.js';
import 'react-phone-input-2/lib/style.css';
import Select from '../../components/Select.js';
import Spinner from '../../components/Spinner.js';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';
import { addItem } from '../../../actions/ItemAction.js';
import { selectInventoryItem } from '../../../actions/Actions.js';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import Icon from 'react-icons-kit';
import PropTypes from 'prop-types';
import { gbp } from 'react-icons-kit/fa/gbp';
import { dollar } from 'react-icons-kit/fa/dollar';


const itemTypes = [{ name: 'Inventory', id: 'Inventory' }, { name: 'Service', id: 'Service' }];
class NewItemForm extends Component {


    constructor(props) {
        super(props);
        this.state = this.defaultState({ selectedView: 'item', default_rate: 0 });
    }

    componentWillReceiveProps(props) {}

    defaultState = data => {
        const { accountId } = this.props;

        return {
            iNameInvalidMsg: '',
            name: '',
            item_type: '',
            account_id: accountId,
            spinner_loader: false,
        };
    };

    constructItem = () => {
        const data = {};
        itemFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else {
                    data[field] = this.state[field];
                }
            }
        });
        return data;
    };

    checkRequiredFields = () => {
        const { name, item_type } = this.state;

        if (name === '' || item_type === '') {
            this.props.showToast('Please fill out name and item type', 'warning');
            return false;
        }
        return true;
    };

    saveItem = event => {
        event.preventDefault();
        if (this.state.iNameInvalidMsg !== '') {
            this.props.showToast('Please Fix the form error before submit', 'warning');
        } else {
            if (!this.checkRequiredFields()) {
                return;
            }
            this.setState({ spinner_loader: true });
            const data = this.constructItem();
            this.props.addItem(data, newItem => {
                if (newItem === false) {
                    this.setState({ spinner_loader: false });
                    return;
                } else {
                    this.props.showToast(`New Item ${newItem.name} created`, 'positive');
                    this.setState({
                        spinner_loader: false,
                        name: '',
                        item_type: '',
                        default_rate: 0,
                    });
                }
            });
        }
    };

    handleItemTypeOnChange = newItemType => {
        this.setState({ item_type: newItemType });
    };

    setFields = fields => {
        this.setState({ ...fields });
    };

    handleOnChanges = {
        handleItemName: newName => {
            this.setState({ name: newName.target.value });
        },
        handleAmountChanged: default_rate => {
            let inputValue = default_rate.target.value;
            inputValue = parseFloat(Number(inputValue).toFixed(2));
            default_rate.target.value = inputValue;
            this.setState({ default_rate: default_rate.target.value }); 
        },
    };

    render() {
        const { spinner_loader, name, item_type, default_rate } = this.state;
        const { prefersPoundSign, assosiationCheck } = this.props;
        return (
            <div className="applicationWrapper">
                <div className="application">
                    <div className="ftContainer cardContainer">
                        <form className="ftForm" onSubmit={this.saveItem}>
                            <Row className="ftRowAlightCenter">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Create New Item
                                </div>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                Name
                                            </div>
                                            <div className="required">*</div>
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.iNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="name"
                                                value={name}
                                                onChange={this.handleOnChanges.handleItemName}
                                                onBlur={
                                                    name.length !== 0
                                                        ? () =>
                                                              isInventoryItemNameValid(name)
                                                                  ? this.setFields({
                                                                        iNameInvalidMsg: '',
                                                                    })
                                                                  : this.setFields({
                                                                        iNameInvalidMsg:
                                                                            ' Item name contains only  1 to 50 alphabets.',
                                                                    })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.iNameInvalidMsg === '' &&
                                                !name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.iNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                Item Type
                                            </div>
                                            <div className="required">*</div>
                                        </Row>
                                        <div>
                                            <Select
                                                options={itemTypes}
                                                withSearch
                                                labelWhenNothingSelected="No Item type Selected"
                                                value={item_type}
                                                onChange={this.handleItemTypeOnChange}
                                            />
                                        </div>
                                    </div>
                                </Col>

                                {!assosiationCheck  &&(
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                        Bill Rate
                                        </div>
                                    </Row>

                                    <div>
                                    <InputGroup className="eAmount">
                                        <InputGroupAddon addonType="prepend">
                                            <Icon
                                                className="inputAddonPrependIcon"
                                                size={15}
                                                icon={prefersPoundSign === 'Yes' ? gbp : dollar}
                                            />
                                        </InputGroupAddon>
                                        <Input
                                            className="eAmountInput"
                                            type="number"
                                            id="default_rate"
                                            placeholder="0.00"
                                            onChange={this.handleOnChanges.handleAmountChanged}
                                            value={default_rate}
                                            step = "any"
                                        />
                                    </InputGroup>
                                    </div>
                                </div>
                                </Col>

                                )}
                             
                            </Row>
                            <Row>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button type="submit" className="primaryButton ftButton">
                                            Save
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button
                                            className="secondaryButton ftButton"
                                            onClick={() =>
                                                this.props.history.push('/resource_settings/items')
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Spinner loading={spinner_loader} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        assosiationCheck: state.accountStore.show_items_association_for_customers,
        prefersPoundSign: state.accountStore.prefer_pound_sign,
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        isSaving: state.accountUserStore.isSaving,
    };
}

const mapDispatchToProps = {
    showToast,
    addItem,
    selectInventoryItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewItemForm);
