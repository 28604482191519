import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';

export const getAllItems = async (paramsObject = {}) => {
    const { InventoryItems, meta } = await getData(paramsObject, 'v1/inventoryitems');
    let inventoryItemList = InventoryItems.map(item => {
        return item.InventoryItem;
    });
    return {
        inventoryItemList,
        meta,
    };
};

export const getAllItemsForTable = async (paramsObject = {}) => {
    const { InventoryItems, meta } = await getData(paramsObject, 'v1/inventoryitems/paginated');
    let inventoryItemList = InventoryItems.map(item => {
        return item.InventoryItem;
    });
    return {
        inventoryItemList,
        meta,
    };
};

export const createNewItem = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(data, 'v1/inventory_items', paramsObject);
    return res.InventoryItem;
};

export const updateExistingItem = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        data,
        'v1/inventory_items/' + data['InventoryItem[id]'],
        paramsObject
    );
    return res.InventoryItem;
};

export const activateItem = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/inventory_items/' + id + '/activate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.InventoryItem;
};

export const deactivateItem = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/inventory_items/' + id + '/deactivate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.InventoryItem;
};
