import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { ic_subdirectory_arrow_right as arrow } from 'react-icons-kit/md/ic_subdirectory_arrow_right';
import { circle } from 'react-icons-kit/fa/circle';
import Select from '../../components/Select.js';
import DetailsLoading from '../../components/DetailsLoading.js';
import NoEntriesFound from '../../components/NoEntriesFound.js';
import { fileTextO } from 'react-icons-kit/fa/fileTextO';
import DownloadModal from './DownloadModal';

export default class Segmentation extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState();
    }

    defaultState = () => {
        return {
            csvData: '',
            downloadCSV: false,
            isDownloadModalOpen: false,
            DownloadModalOnConfirm: null,
            DownloadModalTitle: '',
        };
    };
    newSegmentRow = ({ path, type, name, id, icon, sum, index }) => {
        return (
            <tr key={`${path}-${type}-${id}`} className="detailsRow rSegmentTableRow">
                <td className="rSegmentTableCell" style={{ paddingLeft: 25 * index + 5 }}>
                    <div className="rSegmentIconContainer">
                        <Icon size={20} icon={icon} />
                    </div>
                    {name}
                </td>
                <td className="rSegmentTableCell rSegmentTableTotalCell">{sum}</td>
            </tr>
        );
    };

    constructSegments = (segments, index, segmentTable, data, path = '') => {
        data.forEach(datum => {
            segmentTable.push(
                this.newSegmentRow({
                    path,
                    type: segments[index],
                    name: datum.name,
                    id: datum.id,
                    icon: index === 0 ? circle : arrow,
                    sum: datum.sum ? parseFloat(datum.sum, 10).toFixed(2) : datum.sum,
                    index,
                })
            );

            // Further segments
            if (datum.rows) {
                this.constructSegments(
                    segments,
                    index + 1,
                    segmentTable,
                    datum.rows,
                    `${path}-${segments[index]}-${datum.id}`
                );
            }
        });
    };

    /**
     * Sum the sums of only the first segmentation
     */
    totalSegmentation = () => {
        let sum = 0;
        for (let segmentId in this.props.segmentedData) {
            sum += parseFloat(this.props.segmentedData[segmentId].sum);
        }
        return sum.toFixed(2);
    };

    renderSegmentation = () => {
        const {
            firstSegmentation,
            secondSegmentation,
            thirdSegmentation,
            fourthSegmentation,
            segmentedData,
        } = this.props;

        const segments = [
            firstSegmentation,
            secondSegmentation,
            thirdSegmentation,
            fourthSegmentation,
        ];
        const segmentTable = [];
        this.constructSegments(segments, 0, segmentTable, segmentedData);
        return segmentTable;
    };

    downloadCSV = () => {
        this.setState({
            isDownloadModalOpen: true,
            DownloadModalTitle: 'Are you sure you want to Download this data?',
            csvData: this.props.handleCsvTotalDataRequest(),
            downloadCSV: true,
            DownloadModalOnConfirm: () => {
                this.setState({
                    isDownloadModalOpen: false,
                    downloadCSV: false,
                });
            },
        });
    };

    render() {
        const { prefersPoundSign } = this.props;

        return (
            <div className="cardContainer">
                <div className="rSegmentationContainer">
                    <div className="rSegmentationHeader">
                        <div className="rSegmentationDropdowns">
                            <div className="rSegmentationLabel">Segment Data</div>
                            <div className="rSegmentationDropdownContainer">
                                <Select
                                    options={this.props.currentSegmentationList}
                                    labelWhenNothingSelected="No Option Selected"
                                    value={this.props.firstSegmentation}
                                    onChange={this.props.handleFirstSegmentationChanged}
                                />
                            </div>
                            {this.props.firstSegmentation !== '1' && (
                                <div className="rSegmentationDropdownContainer">
                                    <Select
                                        options={this.props.currentSegmentationList}
                                        value={this.props.secondSegmentation}
                                        onChange={this.props.handleSecondSegmentationChanged}
                                    />
                                </div>
                            )}
                            {this.props.secondSegmentation !== 'no_segment' &&
                                this.props.firstSegmentation !== 'no_segment' && (
                                    <div className="rSegmentationDropdownContainer">
                                        <Select
                                            options={this.props.currentSegmentationList}
                                            value={this.props.thirdSegmentation}
                                            onChange={this.props.handleThirdSegmentationChanged}
                                        />
                                    </div>
                                )}
                            {this.props.thirdSegmentation !== 'no_segment' &&
                                this.props.secondSegmentation !== 'no_segment' &&
                                this.props.firstSegmentation !== 'no_segment' && (
                                    <div className="rSegmentationDropdownContainer">
                                        <Select
                                            options={this.props.currentSegmentationList}
                                            value={this.props.fourthSegmentation}
                                            onChange={this.props.handleFourthSegmentationChanged}
                                        />
                                    </div>
                                )}
                        </div>
                        <div className="rExportButtonContainer">
                            <button
                                className="secondaryButton rExportButton"
                                onClick={this.downloadCSV}
                                data-tip={
                                    'Export Total ' +
                                    (this.props.currentView === 'timesheets' ? 'Time' : 'Expense') +
                                    ' in a CSV File'
                                }
                            >
                                <div className="rExportButtonIcon">
                                    <Icon
                                        className="rExportButtonIcon"
                                        icon={fileTextO}
                                        size={20}
                                    />
                                </div>
                                <div className="rExportButtonTitle">Export CSV</div>
                            </button>
                        </div>
                    </div>
                    <div className="rSegmentationDropdowns dataTableHeader">
                        <div className="rSegmentationTotal">
                            {this.props.currentView === 'timesheets' ? (
                                <span>{this.totalSegmentation()} Hours</span>
                            ) : (
                                <span>
                                    {prefersPoundSign === 'Yes' ? '£' : '$'}{' '}
                                    {this.totalSegmentation()}
                                </span>
                            )}
                        </div>
                    </div>
                    {!this.props.loading ? (
                        Object.keys(this.props.segmentedData).length > 0 ? (
                            <table className="rSegmentationTable dataTable">
                                <tbody>{this.renderSegmentation()}</tbody>
                            </table>
                        ) : (
                            <NoEntriesFound
                                type={
                                    this.props.currentView === 'timesheets'
                                        ? 'Timesheets'
                                        : 'Expenses'
                                }
                            />
                        )
                    ) : (
                        <div className="rReportWrapper">
                            <DetailsLoading
                                standalone
                                description={
                                    this.props.currentView === 'timesheets'
                                        ? 'Loading Timesheets Report'
                                        : 'Loading Expenses Report'
                                }
                            />
                        </div>
                    )}
                </div>
                {this.state.downloadCSV ? (
                    <div className="applicationBody">
                        <DownloadModal
                            isOpen={this.state.isDownloadModalOpen}
                            onConfirm={this.state.DownloadModalOnConfirm}
                            onRequestClose={() => this.setState({ isDownloadModalOpen: false })}
                            title={this.state.DownloadModalTitle}
                            data={JSON.parse(this.state.csvData.data)}
                            filename={this.state.csvData.filename}
                        />
                    </div>
                ) : null}
            </div>
        );
    }
}
