import React, { useState, useMemo } from 'react';
import Icon from 'react-icons-kit';
import { plusCircle } from 'react-icons-kit/fa/plusCircle';

const MAX_ROWS = 10;

const EmployeeRow = ({ id, onClick, name, addActionTip }) => (
    <div className="asUnassociatedEntity" onClick={() => onClick(id)} data-tip={addActionTip}>
        <div className="asUnassociatedEntityName">{name}</div>
        <div className="asCreateAccountUserIcon">
            <Icon className="tableIcon" icon={plusCircle} size={22} />
        </div>
    </div>
);

const EmployeeList = ({ title, list, onClick, addActionTip }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const partialList = useMemo(() => (isExpanded ? list : list.slice(0, MAX_ROWS)), [
        list,
        isExpanded,
    ]);

    if (partialList.length === 0) {
        return null;
    }

    const shouldShowExpandToggle = list.length > MAX_ROWS;

    return (
        <div>
            <h3 className="cardHeading">
                {title}
                {shouldShowExpandToggle && (
                    <div className="headingActions">
                        <button
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="tertiaryButton"
                        >
                            Show {isExpanded ? 'Less' : 'More'}
                        </button>
                    </div>
                )}
            </h3>
            <div className="cardContainer asSection">
                {partialList.map(e => (
                    <EmployeeRow
                        id={e.id}
                        key={e.id}
                        name={e.name}
                        onClick={onClick}
                        addActionTip={addActionTip}
                    />
                ))}
                {shouldShowExpandToggle && (
                    <div
                        className="asHiddenResults secondaryButton"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        Show {isExpanded ? 'Less' : 'More'}
                    </div>
                )}
            </div>
        </div>
    );
};

export default EmployeeList;
