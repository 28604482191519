export const INITIAL_STATE = {
    allAccounts: [],
};

export const accountReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'RECEIVE_BUSINESS_RESOURCES': {
            if ('allAccounts' in action.payload) {
                const newState = { ...state };
                ['allAccounts'].forEach(k => {
                    if (k in action.payload) {
                        newState[k] = action.payload[k];
                    }
                });
                return newState;
            } else {
                return state;
            }
        }
        case 'SET_ALL_ACCOUNTS':
            return {
                ...state,
                allAccounts: action.payload,
            };
        case 'ADD_ACCOUNT':
            return {
                ...state,
                isSaving: true,
            };
        case 'ADD_ACCOUNT_SUCCESS':
            return {
                ...state,
                isSaving: false,
            };
        case 'ADD_ACCOUNT_ERROR':
            return {
                ...state,
                isSaving: false,
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default accountReducer;
