import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    updateAccountUserSettings,
    createAccountUser,
    sendWelcomeEmail,
    deleteAccountUser,
    sendResetUserEmail,
} from '../requests/AccountUserRequests';
import { getCanViewEmployees } from '../requests/EmployeeRequests';
import { formDataForUser } from '../utils/AccountUserUtils';
import * as uiActions from '../actions/Actions';
import * as accountUserActions from '../actions/AccountUserActions';
import * as employeeActions from '../actions/EmployeeActions';

export function* updateUser({ user, callback }) {
    try {
        const data = formDataForUser(user);
        const updatedUser = yield call(updateAccountUserSettings, data);
        const updatedEmployees = yield call(getCanViewEmployees);

        yield put(accountUserActions.updateUserSuccess(updatedUser));
        yield put(employeeActions.setCanViewEmployees(updatedEmployees));
        yield put(uiActions.showToast('Settings saved', 'positive'));
        if (callback) {
            callback(); 
        }
    } catch (error) {
        if (callback) {
            callback(false); 
        }
        yield put(accountUserActions.updateUserError(error));
        yield put(
            uiActions.showToast('Couldn’t save account settings. Try again later.', 'negative')
        );
    }
}

export function* addUser({ user, callback }) {
    try {
        const data = formDataForUser(user);
        const newUser = yield call(createAccountUser, data);

        yield put(accountUserActions.addUserSuccess(newUser));
        yield call(sendWelcomeEmail, newUser);// Invoke the callback function
        if (callback) {
            callback(newUser); 
        }else{
            yield put(uiActions.showToast(`User with email ${newUser.email} created`, 'positive'));
        }
    } catch (error) {
        if (callback) {
            callback(false); 
        }
        yield put(accountUserActions.addUserError(error));
        yield put(uiActions.showToast('Couldn’t create new user', 'negative'));
    }
}

export function* deleteUser({ id }) {
    try {
        const params = {
            account_user_id: id,
        };

        const result = yield call(deleteAccountUser, params);
        if (result && result.success) {
            yield put(accountUserActions.deleteUserSuccess(id));
            yield put(uiActions.showToast('User deleted', 'positive'));
        } else {
            const error = new Error('Couldn’t delete user. API returned a non-success response.');
            error.result = result;
            throw error;
        }
    } catch (error) {
        yield put(accountUserActions.deleteUserError(error));
        yield put(uiActions.showToast('Couldn’t delete user', 'negative'));
    }
}

export function* resetUserEmail({ id }) {
    try {
        const params = {
            user_id: id,
        };
        yield call(sendResetUserEmail, params);
    } catch (error) {;
        yield put(uiActions.showToast('Couldn’t update user about email reset', 'negative'));
    }
}

export default function* root() {
    yield all([
        takeLatest(['UPDATE_USER'], updateUser),
        takeLatest(['ADD_USER'], addUser),
        takeLatest(['DELETE_USER'], deleteUser),
        takeLatest(['RESET_USER_EMAIL'],resetUserEmail),
    ]);
}
