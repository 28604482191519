/*
 * Time Entry Fields
 */
export const setStartDate = payload => ({
    type: 'SET_TIMESHEETS_START_DATE',
    payload,
});

export const setEndDate = payload => ({
    type: 'SET_TIMESHEETS_END_DATE',
    payload,
});

export const setCustomerId = payload => ({
    type: 'SET_TIMESHEETS_CUSTOMER_ID',
    payload,
});

export const setEmployeeId = payload => ({
    type: 'SET_TIMESHEETS_EMPLOYEE_ID',
    payload,
});

export const setInventoryItemId = payload => ({
    type: 'SET_TIMESHEETS_INVENTORY_ITEM_ID',
    payload,
});

export const setQbClassId = payload => ({
    type: 'SET_TIMESHEETS_QB_CLASS_ID',
    payload,
});

export const setPayrollItemId = payload => ({
    type: 'SET_TIMESHEETS_PAYROLL_ITEM_ID',
    payload,
});

export const setDate = payload => ({
    type: 'SET_TIMESHEETS_DATE',
    payload,
});

export const setDuration = payload => ({
    type: 'SET_TIMESHEETS_DURATION',
    payload,
});

export const setDurations = payload => ({
    type: 'SET_TIMESHEETS_DURATIONS',
    payload,
});

export const setDescription = payload => ({
    type: 'SET_TIMESHEETS_DESCRIPTION',
    payload,
});

export const setType = payload => ({
    type: 'SET_TIMESHEETS_TIMESHEET_TYPE',
    payload,
});

export const setBillable = payload => ({
    type: 'SET_TIMESHEETS_BILLABLE',
    payload,
});

export const setTaxable = payload => ({
    type: 'SET_TIMESHEETS_TAXABLE',
    payload,
});

export const setStartOfWeek = payload => ({
    type: 'SET_TIMESHEETS_START_OF_WEEK',
    payload,
});

export const setStartTime = payload => ({
    type: 'SET_TIMESHEETS_START_TIME',
    payload,
});

export const setHoursOffDuty = payload => ({
    type: 'SET_TIMESHEETS_HOURS_OFF_DUTY',
    payload,
});

export const setEndTime = payload => ({
    type: 'SET_TIMESHEETS_END_TIME',
    payload,
});

/**
 * Timesheet Filters
 */
export const setSearchText = payload => ({
    type: 'SET_TIMESHEETS_SEARCH_TEXT',
    payload,
});

export const setSearchEmployeeIds = payload => ({
    type: 'SET_TIMESHEETS_SEARCH_EMPLOYEE_IDS',
    payload,
});

export const setSearchCustomerIds = payload => ({
    type: 'SET_TIMESHEETS_SEARCH_CUSTOMER_IDS',
    payload,
});

export const setSearchInventoryItemIds = payload => ({
    type: 'SET_TIMESHEETS_SEARCH_INVENTORY_ITEM_IDS',
    payload,
});

export const setSearchQbClassIds = payload => ({
    type: 'SET_TIMESHEETS_SEARCH_QB_CLASS_IDS',
    payload,
});

export const setSearchPayrollItemIds = payload => ({
    type: 'SET_TIMESHEETS_SEARCH_PAYROLL_ITEM_IDS',
    payload,
});

export const setApprovedFilter = payload => ({
    type: 'SET_TIMESHEETS_APPROVED_FILTER',
    payload,
});


export const setLockedFilter = payload => ({
    type: 'SET_TIMESHEETS_LOCKED_FILTER',
    payload,
});

export const setBillableFilter = payload => ({
    type: 'SET_TIMESHEETS_BILLABLE_FILTER',
    payload,
});

export const setTaxableFilter = payload => ({
    type: 'SET_TIMESHEETS_TAXABLE_FILTER',
    payload,
});

export const setExportedFilter = payload => ({
    type: 'SET_TIMESHEETS_EXPORTED_FILTER',
    payload,
});
export const setCurrentPage = payload => ({
    type: 'SET_TIMESHEETS_CURRENT_PAGE',
    payload,
});

export const setTotalTimeEntries = payload => ({
    type: 'SET_TIMESHEETS_TOTAL_TIME_ENTRIES',
    payload,
});

export const setActiveFilters = payload => ({
    type: 'SET_TIMESHEETS_ACTIVE_FILTERS',
    payload,
});

export const setFilterState = payload => ({
    type: 'SET_TIMESHEETS_FILTER_STATE',
    payload,
});

/**
 * Edit/Duplicate time entry actions
 */
export const setEditTimesheetStore = payload => ({
    type: 'SET_EDIT_TIMESHEET_STORE',
    payload,
});

export const setEditCustomerId = payload => ({
    type: 'SET_TIMESHEETS_EDIT_CUSTOMER_ID',
    payload,
});

export const setEditEmployeeId = payload => ({
    type: 'SET_TIMESHEETS_EDIT_EMPLOYEE_ID',
    payload,
});

export const setEditInventoryItemId = payload => ({
    type: 'SET_TIMESHEETS_EDIT_INVENTORY_ITEM_ID',
    payload,
});

export const setEditQbClassId = payload => ({
    type: 'SET_TIMESHEETS_EDIT_QB_CLASS_ID',
    payload,
});

export const setEditPayrollItemId = payload => ({
    type: 'SET_TIMESHEETS_EDIT_PAYROLL_ITEM_ID',
    payload,
});

export const setEditDate = payload => ({
    type: 'SET_TIMESHEETS_EDIT_DATE',
    payload,
});

export const setEditDuration = payload => ({
    type: 'SET_TIMESHEETS_EDIT_DURATION',
    payload,
});

export const setEditDurations = payload => ({
    type: 'SET_TIMESHEETS_EDIT_DURATIONS',
    payload,
});

export const setEditDescription = payload => ({
    type: 'SET_TIMESHEETS_EDIT_DESCRIPTION',
    payload,
});

export const setEditGroupedTimeIds = payload => ({
    type: 'SET_TIMESHEETS_EDIT_GROUPED_TIME_IDS',
    payload,
});

export const setEditType = payload => ({
    type: 'SET_TIMESHEETS_EDIT_TYPE',
    payload,
});

export const setEditEntryType = payload => ({
    type: 'SET_TIMESHEETS_EDIT_ENTRY_TYPE',
    payload,
});

export const setEditBillable = payload => ({
    type: 'SET_TIMESHEETS_EDIT_BILLABLE',
    payload,
});

export const setEditTaxable = payload => ({
    type: 'SET_TIMESHEETS_EDIT_TAXABLE',
    payload,
});

export const setEditStartOfWeek = payload => ({
    type: 'SET_TIMESHEETS_EDIT_START_OF_WEEK',
    payload,
});

export const setEditStartTime = payload => ({
    type: 'SET_TIMESHEETS_EDIT_START_TIME',
    payload,
});

export const setEditHoursOffDuty = payload => ({
    type: 'SET_TIMESHEETS_EDIT_HOURS_OFF_DUTY',
    payload,
});

export const setEditEndTime = payload => ({
    type: 'SET_TIMESHEETS_EDIT_END_TIME',
    payload,
});

/*
 * Misc
 */
export const selectTimeEntry = (timeEntry, duplicate = false) => ({
    type: 'SELECT_TIME_ENTRY',
    payload: {
        selectedTimeEntry: timeEntry,
        duplicate,
    },
});

export const selectTimeEntryHash = (hash, duplicate = false) => ({
    type: 'SELECT_TIME_ENTRY_HASH',
    payload: {
        selectedTimeEntryHash: hash,
        duplicate,
    },
});

export const setIsSaving = payload => ({
    type: 'SET_TIMESHEETS_IS_SAVING',
    payload,
});

export const setIsNewEntryModalOpen = payload => ({
    type: 'SET_TIMESHEETS_IS_NEW_ENTRY_MODAL_OPEN',
    payload,
});

export const setIsEditEntryModalOpen = payload => ({
    type: 'SET_TIMESHEETS_IS_EDIT_ENTRY_MODAL_OPEN',
    payload,
});

export const setIsNewEntryCollapsed = payload => ({
    type: 'SET_TIMESHEETS_IS_NEW_ENTRY_COLLAPSED',
    payload,
});

export const setSelectedEntryIds = payload => ({
    type: 'SET_TIMESHEETS_SELECTED_ENTRY_IDS',
    payload,
});

export const setLoading = payload => ({
    type: 'SET_TIMESHEETS_LOADING',
    payload,
});

export const resetTimesheetForm = () => ({
    type: 'RESET_TIMESHEETS_FORM',
});

export const setEntryType = payload => ({
    type: 'SET_TIMESHEETS_ENTRY_TYPE',
    payload,
});

export const setGroupingType = payload => ({
    type: 'SET_TIMESHEETS_GROUPING_TYPE',
    payload,
});

export const setDefaultsForNewTimeEntries = defaults => ({
    type: 'SET_DEFAULTS_FOR_NEW_TIME_ENTRIES',
    defaults,
});

/*
 * Get Timesheets
 */

export const requestTimesheets = ({ showLoading, queryParams }) => ({
    type: 'REQUEST_TIMESHEETS',
    showLoading,
    queryParams,
});

export const receiveTimesheets = (timesheets, total) => ({
    type: 'RECEIVE_TIMESHEETS',
    timesheets,
    total,
});

export const requestTimesheetsError = error => ({
    type: 'REQUEST_TIMESHEETS_ERROR',
    error,
});

/* Delete time entries */
export const deleteTimeEntries = ids => ({
    type: 'DELETE_TIME_ENTRIES',
    ids,
});

export const deleteTimeEntriesError = error => ({
    type: 'DELETE_TIME_ENTRIES_ERROR',
    error,
});

export const deleteTimeEntriesSuccess = ids => ({
    type: 'DELETE_TIME_ENTRIES_SUCCESS',
    ids,
});

/* Mark time entries as billable */
export const setTimeEntriesBillable = (ids, billable) => ({
    type: 'SET_TIME_ENTRIES_BILLABLE',
    ids,
    billable,
});

export const setTimeEntriesBillableError = error => ({
    type: 'SET_TIME_ENTRIES_BILLABLE_ERROR',
    error,
});

export const setTimeEntriesBillableSuccess = ids => ({
    type: 'SET_TIME_ENTRIES_BILLABLE_SUCCESS',
    ids,
});

/* Mark time entries as taxable */
export const setTimeEntriesTaxable = (ids, taxable) => ({
    type: 'SET_TIME_ENTRIES_TAXABLE',
    ids,
    taxable,
});

export const setTimeEntriesTaxableError = error => ({
    type: 'SET_TIME_ENTRIES_TAXABLE_ERROR',
    error,
});

export const setTimeEntriesTaxableSuccess = ids => ({
    type: 'SET_TIME_ENTRIES_TAXABLE_SUCCESS',
    ids,
});

/* Mark time entries as approved/pending */
export const setTimeEntriesApproved = (ids, approved) => ({
    type: 'SET_TIME_ENTRIES_APPROVED',
    ids,
    approved,
});

export const setTimeEntriesApprovedError = error => ({
    type: 'SET_TIME_ENTRIES_APPROVED_ERROR',
    error,
});

export const setTimeEntriesApprovedSuccess = ids => ({
    type: 'SET_TIME_ENTRIES_APPROVED_SUCCESS',
    ids,
});

/* Mark time entries as locked */
export const setTimeEntriesLocked = (ids) => ({
    type: 'SET_TIME_ENTRIES_LOCKED',
    ids,
});

export const setTimeEntriesLockedError = error => ({
    type: 'SET_TIME_ENTRIES_LOCKED_ERROR',
    error,
});

export const setTimeEntriesLockedSuccess = ids => ({
    type: 'SET_TIME_ENTRIES_LOCKED_SUCCESS',
    ids,
});

/* Add time entry */
export const addTimeEntry = entry => ({
    type: 'ADD_TIME_ENTRY',
    entry,
});

export const addTimeEntrySuccess = ({ newEntries }) => ({
    type: 'ADD_TIME_ENTRY_SUCCESS',
    newEntries,
});

export const addTimeEntryError = error => ({
    type: 'ADD_TIME_ENTRY_ERROR',
    error,
});

/* Update time entry */
export const updateTimeEntry = entry => ({
    type: 'UPDATE_TIME_ENTRY',
    entry,
});

export const updateTimeEntrySuccess = ({ newEntries, updatedEntries, removedEntryIds }) => ({
    type: 'UPDATE_TIME_ENTRY_SUCCESS',
    newEntries,
    updatedEntries,
    removedEntryIds,
});

export const updateTimeEntryError = error => ({
    type: 'UPDATE_TIME_ENTRY_ERROR',
    error,
});

/* Duplicate time entry */
export const duplicateTimeEntry = entry => ({
    type: 'DUPLICATE_TIME_ENTRY',
    entry,
});

export const duplicateTimeEntrySuccess = ({ newEntries }) => ({
    type: 'DUPLICATE_TIME_ENTRY_SUCCESS',
    newEntries,
});

export const duplicateTimeEntryError = error => ({
    type: 'DUPLICATE_TIME_ENTRY_ERROR',
    error,
});
