import React from 'react';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useTable, usePagination, useSortBy, useExpanded } from 'react-table';
import PropTypes from 'prop-types';
import '../../css/MinuteTable.css';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const MinuteTable = ({
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
    filter,
    type,
    refresh,
    resetTable,
    openToggle,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy },
        toggleAllRowsExpanded,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }, // Pass our hoisted table state
            manualPagination: true,
            manualSortBy: true,
            autoResetPage: false,
            autoResetSortBy: false,
            autoResetExpanded: false, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            pageCount: controlledPageCount,
        },
        useSortBy,
        useExpanded,
        usePagination
    );

    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(
        () => {
            fetchData({ sortBy, pageIndex, pageSize, filter, type, resetTable });
            if (resetTable) {
                gotoPage(0);
            }
            // if(openToggle){
            //     toggleAllRowsExpanded(openToggle);
            // }
        },
        [sortBy, fetchData, pageIndex, pageSize, filter, type, refresh, openToggle]
    );

    // Render the UI for your table
    return (
        <div className="MuiTableContainer-root">
            <table className="MuiTable-root" {...getTableProps()}>
                <thead className="MuiTableHead-root">
                    {headerGroups.map(headerGroup => (
                        <tr
                            className="MuiTableRow-root MuiTableRow-head"
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map(column => (
                                <th
                                    className="MuiTableCell-root MuiTableCell-head"
                                    {...column.getSortByToggleProps()}
                                >
                                    {column.render('Header')}
                                    <span style={{ paddingLeft: '2%' }}>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <ArrowDownwardIcon fontSize="small" />
                                            ) : (
                                                <ArrowUpwardIcon fontSize="small" />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="MuiTableBody-root" {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                className="MuiTableRow-root MuiTableRow-head"
                                {...row.getRowProps()}
                            >
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            className="MuiTableCell-root MuiTableCell-body"
                                            {...cell.getCellProps()}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
            <tfoot className="MuiTableFooter-root">
                <tr className="MuiTableRow-root MuiTableRow-head">
                    {loading ? (
                        // Use our custom loading state to show a loading indicator
                        <td className="MuiTableCell-root MuiTableCell-body" colSpan="10000">
                            Loading...
                        </td>
                    ) : (
                        <td className="MuiTableCell-root MuiTableCell-body">
                            <span class="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit">
                                Rows per page:
                            </span>
                            <div class="MuiInputBase-root MuiTablePagination-input MuiTablePagination-selectRoot">
                                <select
                                    className="MuiSelect-root MuiSelect-select MuiTablePagination-select MuiInputBase-input"
                                    value={pageSize}
                                    onChange={e => {
                                        setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option className="" key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                                <svg
                                    class="MuiSvgIcon-root MuiSelect-icon MuiTablePagination-selectIcon"
                                    focusable="false"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                                >
                                    <path d="M7 10l5 5 5-5z" />
                                </svg>
                            </div>
                            <span
                                className="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit"
                                style={{ marginRight: '5px' }}
                            >
                                Page{' '}
                                <>
                                    {pageIndex + 1} of {pageOptions.length}
                                </>{' '}
                            </span>
                            <IconButton
                                aria-label="first page"
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                <FirstPageIcon />
                            </IconButton>{' '}
                            <IconButton
                                aria-label="previous page"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                <KeyboardArrowLeft />
                            </IconButton>{' '}
                            <IconButton
                                aria-label="next page"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                <KeyboardArrowRight />
                            </IconButton>{' '}
                            <IconButton
                                aria-label="last page"
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                <LastPageIcon />
                            </IconButton>{' '}
                        </td>
                    )}
                </tr>
            </tfoot>
        </div>
    );
};

MinuteTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    setData: PropTypes.func.isRequired,
    skipPageReset: PropTypes.bool.isRequired,
};

export default MinuteTable;
