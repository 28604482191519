import React from 'react';

const LimitCustomerJobs = () => (
    <div>
        <div className="sectionQuestion">Can I limit which of my Customer:Jobs a user can see?</div>
        <div className="sectionAnswer">
            Yes. For any Minute7 user who does not have permission to Manage Users, you may limit
            which Customers the user can see and add time and expense entries for. In order to
            specify which Customers a user can see,
            <ol>
                <li>
                    Set the option to <b>"Limit this user to certain customers"</b> to <b>"Yes"</b>.
                </li>
                <li>
                    In the multiple-select box that opens up,{' '}
                    <b>select the Customers you want this user to see</b>. Click on each customer
                    you want to select to move it to the right hand side to include it or left hand
                    side to exclude it.
                </li>
                <li>
                    <b>Click "Save changes"</b> to save the changes you have made, and make sure
                    that the user whose settings you have just altered{' '}
                    <b>logs out of any open Minute7 sessions</b> and <b>logs back in</b> before
                    entering time again.
                </li>
            </ol>
        </div>
    </div>
);

export default LimitCustomerJobs;
