import _ from 'lodash';
import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { repeat } from 'react-icons-kit/fa/repeat';
import { search } from 'react-icons-kit/fa/search';
import { arrow_left as chevronLeft } from 'react-icons-kit/ikons/arrow_left';
import { arrow_right as chevronRight } from 'react-icons-kit/ikons/arrow_right';

import ReactTooltip from 'react-tooltip';

export default class ToggleOptionss extends Component {
    static defaultProps = {
        permissionsGroupId: '0',
        listOptions: null,
        labelForOption: o => o,
        valueForOption: o => o,
        labelForListOption: o => o.name,
        valueForListOption: o => o.id,
        subtitle: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            firstSearchFilter: '',
            secondSearchFilter: '',
        };
    }

    renderList = (searchValue, list, onChange) => {
        if (_.isNil(list)) {
            return null;
        }

        const { labelForListOption } = this.props;

        return list
            .filter(option =>
                labelForListOption(option)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            )
            .map(option => {
                const label = labelForListOption(option);

                return (
                    <div
                        key={label}
                        className="toggleSettingListItem"
                        onClick={() => onChange(option)}
                        data-tip="Click to move to filtered list"
                    >
                        {label}
                    </div>
                );
            });
    };

    figureCanChange = () => {
        return (
            !('overrideValue' in this.props) ||
            this.props.overrideValue === 'Yes' ||
            parseInt(this.props.permissionsGroupId, 10) === 0
        );
    };

    handleListAdd = options => {
        const { listValue, valueForListOption } = this.props;
        const values = options.map(valueForListOption);

        this.props.onListChange(_.union(listValue, values));
    };

    handleListRemove = options => {
        const { listValue, valueForListOption } = this.props;
        const values = options.map(valueForListOption);

        this.props.onListChange(_.difference(listValue, values));
    };

    handleSelectAll = () => {
        const { listOptions, valueForListOption } = this.props;
        this.props.onListChange(listOptions.map(valueForListOption));
    };

    handleDeselectAll = () => {
        this.props.onListChange([]);
    };

    render() {
        const { listOptions, label, listValue, valueForListOption } = this.props;

        const { firstSearchFilter, secondSearchFilter } = this.state;

        let unselectedListOptions = [];
        let selectedListOptions = [];

        if (listOptions) {
            [selectedListOptions, unselectedListOptions] = _.partition(listOptions, o =>
                listValue.includes(valueForListOption(o))
            );
        }

        return (
            <div className="toggleSettingListContainer">
                <div className="toggleSetting">
                    <div className="toggleSettingLabel">
                        {label && <div className="note">{label}</div>}
                    </div>
                </div>
                <div className="toggleListAndSearchContainer">
                    <div className="toggleSearchContainer">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Icon
                                    className="inputAddonPrependIcon"
                                    size={15}
                                    icon={repeat}
                                    onClick={() =>
                                        this.setState({
                                            firstSearchFilter: '',
                                        })
                                    }
                                    data-tip="Reset Filter"
                                />
                            </InputGroupAddon>
                            <Input
                                value={firstSearchFilter}
                                className="searchBar"
                                onChange={searchText =>
                                    this.setState({
                                        firstSearchFilter: searchText.target.value,
                                    })
                                }
                            />
                            <InputGroupAddon addonType="append">
                                <Icon
                                    className="inputAddonAppendIcon"
                                    size={15}
                                    icon={search}
                                    data-tip="Filter List"
                                />
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="toggleSettingList">
                        {this.renderList(firstSearchFilter, unselectedListOptions, option =>
                            this.handleListAdd([option])
                        )}
                    </div>
                </div>
                <div className="toggleSettingIconContainer">
                    <div>
                        <Icon
                            icon={chevronLeft}
                            size={32}
                            className="tableIcon"
                            data-tip="Deselect All"
                            onClick={() => {
                                if (this.figureCanChange()) {
                                    this.handleDeselectAll();
                                }
                            }}
                        />
                    </div>
                    <div>
                        <Icon
                            icon={chevronRight}
                            size={32}
                            className="tableIcon"
                            data-tip="Select All"
                            onClick={() => {
                                if (this.figureCanChange()) {
                                    this.handleSelectAll();
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="toggleListAndSearchContainer">
                    <div className="toggleSearchContainer">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Icon
                                    className="inputAddonPrependIcon"
                                    size={15}
                                    icon={repeat}
                                    onClick={() =>
                                        this.setState({
                                            secondSearchFilter: '',
                                        })
                                    }
                                    data-tip="Reset Filter"
                                />
                            </InputGroupAddon>
                            <Input
                                value={secondSearchFilter}
                                className="searchBar"
                                onChange={searchText =>
                                    this.setState({
                                        secondSearchFilter: searchText.target.value,
                                    })
                                }
                            />
                            <InputGroupAddon addonType="append">
                                <Icon
                                    className="inputAddonAppendIcon"
                                    size={15}
                                    icon={search}
                                    data-tip="Filter Selected List"
                                />
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="toggleSettingList">
                        {this.renderList(secondSearchFilter, selectedListOptions, option =>
                            this.handleListRemove([option])
                        )}
                    </div>
                </div>
                <ReactTooltip delayShow={500} type="info" multiline={true} />
            </div>
        );
    }
}
