import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    updateExistingAccount,
    createNewAccount,
    getAllAccountsForTable,
    activateAccount,
    deactivateAccount,
} from '../requests/AccountsRequests';
import * as uiActions from '../actions/Actions';
import * as accountActions from '../actions/AccountsActions';
import _ from 'lodash';
import { accountFields } from '../config/Fields';
import * as resourceActions from '../actions/BusinessResourcesActions';

const formDataForAccount = account => {
    const data = {};
    _.forEach(_.pick(account, accountFields), (value, key) => {
        switch (key) {
            case 'account_id': {
                data['QuickbooksAccount[account_id]'] = value;
                break;
            }
            case 'id': {
                data['QuickbooksAccount[id]'] = value;
                break;
            }
            case 'type': {
                data['QuickbooksAccount[type]'] = value;
                break;
            }
            case 'description': {
                data['QuickbooksAccount[description]'] = value;
                break;
            }
            default: {
                data[`QuickbooksAccount[${key}]`] = value;
            }
        }
    });
    return data;
};

export function* updateAccount({ account, callback }) {
    try {
        const data = formDataForAccount(account);
        const updatedAccount = yield call(updateExistingAccount, data);
        yield put(resourceActions.requestBusinessResources());
        callback(updatedAccount);
    } catch (error) {
        console.log(error);
        callback(false);
        yield put(accountActions.updateAccountError(error));
        yield put(
            uiActions.showToast('Couldn’t update this account. Try again later.', 'negative')
        );
    }
}

export function* addAccount({ account, callback }) {
    try {
        const data = formDataForAccount(account);
        const newAccount = yield call(createNewAccount, data);
        yield put(accountActions.addAccountSuccess(newAccount));
        yield put(resourceActions.requestBusinessResources());
        callback(newAccount);
    } catch (error) {
        console.log(error);
        callback(false);
        yield put(accountActions.addAccountError(error));
        yield put(uiActions.showToast('Couldn’t create new account', 'negative'));
    }
}

export function* addAccountList() {
    try {
        const updatedAccounts = yield call(getAllAccountsForTable);
        yield put(accountActions.setAllAccounts(updatedAccounts));
        yield put(uiActions.showToast('New account added', 'positive'));
    } catch (error) {
        console.log(error);
        yield put(accountActions.addAccountListError(error));
        yield put(uiActions.showToast('Couldn’t get new account, please refresh page', 'negative'));
    }
}

export function* unhideAccount({ accountId }) {
    try {
        yield call(activateAccount, accountId);
        yield put(resourceActions.requestBusinessResources());
        yield put(uiActions.showToast('account has been activated', 'positive'));
    } catch (error) {
        yield put(accountActions.unhideAccount(error));
        yield put(
            uiActions.showToast('Couldn’t activate this account. Try again later.', 'negative')
        );
    }
}

export function* hideAccount({ accountId }) {
    try {
        yield call(deactivateAccount, accountId);
        yield put(resourceActions.requestBusinessResources());
        yield put(uiActions.showToast('account has been deactivated', 'positive'));
    } catch (error) {
        yield put(accountActions.hideAccount(error));
        yield put(
            uiActions.showToast('Couldn’t deactivate this account. Try again later.', 'negative')
        );
    }
}

export default function* root() {
    yield all([
        takeLatest(['UPDATE_ACCOUNT'], updateAccount),
        takeLatest(['ADD_ACCOUNT'], addAccount),
        takeLatest(['ADD_ACCOUNT_LIST'], addAccountList),
        takeLatest(['ACTIVATE_QB_ACCOUNT'], unhideAccount),
        takeLatest(['DEACTIVATE_QB_ACCOUNT'], hideAccount),
    ]);
}
