import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import uuid from 'uuid';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';

import { updateAccountSettings, removeCompanyLogo } from '../../../requests/AccountRequests.js';
import { companySettingsFields, timeRoundingIntervals } from '../../../config/Fields.js';
import { COMPANY, KILOMETERS } from '../../../config/Constants';
import {
    kilometersToMiles,
    milesToKilometers,
    isCompanyNameValid,
    zipCodeValid,
    zipErrorMessage,
    companyNameError,
    isStateValid,
    stateErrorMessage,
} from '../../../utils/CommonFunctions';
import FloatingSaveButton from '../../components/FloatingButton.js';
import ToggleSetting from '../../components/ToggleSetting.js';
import Select from '../../components/Select.js';
import MileageCalculatorSetting from '../../components/MileageCalculatorSetting';
import { showAlert, showToast } from '../../../actions/Actions.js';
import { setAccountStore } from '../../../actions/AccountActions.js';
import { getCanViewEmployees, getCanEditEmployees } from '../../../requests/EmployeeRequests.js';
import { setCanViewEmployees, setCanEditEmployees } from '../../../actions/EmployeeActions.js';

import '../../../css/CompanySettings.css';

const supportedUploadFileTypes = ['image/png', 'image/jpg', 'image/gif', 'image/jpeg'];

const supportedUploadFileTypesString = 'png, jpeg, jpg, and gif';

const IMAGES_BASE_URL = process.env.REACT_APP_S3_BUCKET_URL;

class CompanySettings extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState();
    }

    defaultState = () => {
        const { accountStore } = this.props;
        let { default_reimbursement_rate } = accountStore;

        if (accountStore.preferred_distance_units === KILOMETERS) {
            default_reimbursement_rate =
                parseFloat(default_reimbursement_rate) * milesToKilometers(1);
        }

        return {
            name: accountStore.name,
            state: accountStore.state,
            zip: accountStore.zip,
            logo: {
                file: null,
                fileProperties: {
                    name: '',
                    type: '',
                },
                thumbnail: null,
            },
            export_wait_hours: accountStore.export_wait_hours,
            password_reset_days: accountStore.password_reset_days,
            default_rounding_interval: accountStore.default_rounding_interval,
            show_expenses: accountStore.show_expenses,
            show_vendors: accountStore.show_vendors,
            show_qb_classes: accountStore.show_qb_classes,
            show_payroll: accountStore.show_payroll,
            prevent_edit_for_synced_entries: accountStore.prevent_edit_for_synced_entries,
            prevent_edit_for_locked_entries: accountStore.prevent_edit_for_locked_entries,
            approved_by_default: accountStore.approved_by_default,
            show_billable: accountStore.show_billable,
            show_taxable: accountStore.show_taxable,
            billable_by_default: accountStore.billable_by_default,
            taxable_by_default: accountStore.taxable_by_default,
            time_autofill_description: accountStore.time_autofill_description,
            time_prepend_name: accountStore.time_prepend_name,
            expenses_prepend_name: accountStore.expenses_prepend_name,
            show_all_qb_account_types: accountStore.show_all_qb_account_types,
            prefer_pound_sign: accountStore.prefer_pound_sign,
            hasBeenEdited: false,
            logoUploadInputKey: +new Date(),
            billable_type: accountStore.billable_type,
            reimbursement_rate_type: accountStore.reimbursement_rate_type,
            default_reimbursement_rate,
            preferred_distance_units: accountStore.preferred_distance_units,
            preferred_expense_type: accountStore.preferred_expense_type,
            expense_transaction_type: accountStore.expense_transaction_type,
            show_service_items_for_expense: accountStore.show_service_items_for_expense,
            show_items_association_for_customers: accountStore.show_items_association_for_customers,
        };
    };

    removeLogo = () => {
        this.setState({
            logo: {
                file: null,
                fileProperties: {
                    name: '',
                    type: '',
                },
                thumbnail: null,
            },
            logoUploadInputKey: +new Date(),
        });
    };

    constructCompanySettings = () => {
        let data = {};
        const { accountStore } = this.props;
        const { default_reimbursement_rate, preferred_distance_units } = this.state;
        companySettingsFields.forEach(field => {
            if (field === 'logo') {
                data['logo'] = {
                    name: this.state.logo.fileProperties.name,
                    path: this.state.logo.file,
                    type: this.state.logo.fileProperties.type,
                    size: this.state.logo.fileProperties.size,
                };
            } else {
                data[`Account[${field}]`] = this.state[field];
            }
        });
        data[`Account[id]`] = accountStore.id;
        if (preferred_distance_units === KILOMETERS) {
            data['Account[default_reimbursement_rate]'] =
                parseFloat(default_reimbursement_rate) * kilometersToMiles(1);
        }
        return data;
    };

    updateCompanySettings = () => {
        if (isCompanyNameValid(this.state.name)) {
            let data = this.constructCompanySettings();
            updateAccountSettings(data)
                .then(res => {
                    let tempAccountStore = res.Account;
                    tempAccountStore['accountUsers'] = res.AccountUsers.map(
                        ({ AccountUser }) => AccountUser
                    );
                    this.props.setAccountStore(tempAccountStore);

                    // Refresh employees incase show_vendors has changed
                    Promise.all([getCanEditEmployees(), getCanViewEmployees()]).then(res => {
                        this.props.setCanEditEmployees(res[0]);
                        this.props.setCanViewEmployees(res[1]);
                    });

                    this.props.showToast('Settings saved', 'positive');
                    this.setState({
                        hasBeenEdited: false,
                        logoUploadInputKey: +new Date(),
                    });
                    this.props.history.goBack();
                })
                .catch(() => {
                    this.props.showToast('Couldn’t save settings. Try again later.', 'negative');
                });
        } else {
            this.props.showToast('Please fill required fields correctly', 'warning');
        }
    };

    removeLogoFromAccount = () => {
        removeCompanyLogo()
            .then(res => {
                let tempAccountStore = this.props.accountStore;
                tempAccountStore['logo'] = '';
                this.props.setAccountStore(tempAccountStore);
                this.removeLogo();
            })
            .catch(() => {
                this.props.showToast('Couldn’t remove logo. Try again later.', 'negative');
            });
    };

    renderCompanyLogo = () => {
        if (this.state.logo.thumbnail === null) {
            if (this.props.accountStore.logo !== '') {
                let url = `${IMAGES_BASE_URL}/${this.props.accountStore.logo}`;
                return (
                    <div className="logoUploadContainer">
                        <img alt="Logo" className="logoThumbnail" src={url} />
                        <div className="redIcon" onClick={() => this.removeLogoFromAccount()}>
                            <b>DELETE</b>
                        </div>
                    </div>
                );
            } else {
                return <div>No file chosen</div>;
            }
        } else {
            return <div>{this.state.logo.thumbnail}</div>;
        }
    };

    uploadLogo = () => {
        let reader = new FileReader();
        if (!this.uploadedLogo.files[0]) {
            return;
        }

        reader.onload = (file, fileProperties = this.uploadedLogo.files[0]) => {
            let tempLogo = {};
            tempLogo['file'] = file.target.result;
            tempLogo['fileProperties'] = fileProperties;
            tempLogo['thumbnail'] = (
                <div className="logoUploadContainer">
                    <img alt="Logo" className="logoThumbnail" src={file.target.result} />
                    <div className="redIcon" onClick={() => this.removeLogo()}>
                        <b>DELETE</b>
                    </div>
                </div>
            );
            this.setState({
                logo: tempLogo,
                hasBeenEdited: true,
            });
        };

        if (this.isLogoGreaterThanOneMB()) {
            this.props.showToast('Logo attachment must be less than 1MB in size', 'warning');
        } else if (this.isFileFormatNotSupported()) {
            this.props.showToast(
                'Attempted to upload file of type ' +
                this.uploadedLogo.files[0].type +
                '. Supported file types are ' +
                supportedUploadFileTypesString,
                'warning'
            );
        } else {
            reader.readAsDataURL(this.uploadedLogo.files[0]);
        }
    };

    isLogoGreaterThanOneMB = () => {
        return this.uploadedLogo.files[0].size > 1000000 ? true : false;
    };

    isFileFormatNotSupported = () => {
        return supportedUploadFileTypes.includes(this.uploadedLogo.files[0].type) ? false : true;
    };

    handlePreferredDistanceUnitChange = (preferredDistanceUnit, reimbursementRate) => {
        this.setState({
            preferred_distance_units: preferredDistanceUnit,
            default_reimbursement_rate: reimbursementRate,
            hasBeenEdited: true,
        });
    };

    handleReimbursementRateChange = reimbursementRate => {
        this.setState({
            default_reimbursement_rate: reimbursementRate,
            hasBeenEdited: true,
        });
    };

    handleChangeBillableByDefault = value => {
        if (value === 'No') {
            this.setState({
                billable_by_default: value,
                taxable_by_default: value,
                hasBeenEdited: true,
            });
        } else {
            this.setState({
                billable_by_default: value,
                hasBeenEdited: true,
            });
        }
    };

    handleChangeTransactionType = value => {
        this.setState({
            expense_transaction_type: value,
            hasBeenEdited: true,
        });
    };

    render() {
        const { pathname } = this.props.history.location;
        const { accountStore, no_integration } = this.props;
        const {
            logoUploadInputKey,
            reimbursement_rate_type,
            default_reimbursement_rate,
            preferred_distance_units,
            show_taxable,
            show_billable,
            expense_transaction_type,
            prefer_pound_sign,
            name,
            state,
            zip,
        } = this.state;
        const isMileageCalculatorVisible = reimbursement_rate_type === COMPANY;
        const expenseSettingHeader = no_integration ? "Expense Preferences" : "Export Preferences";

        return (
            <div className="applicationWrapper">
                <div className="secondaryNav">
                    <button
                        className={classNames({
                            secondaryNavLink: true,
                            secondaryNavActiveLink: pathname === '/account_settings',
                        })}
                        onClick={() => this.props.history.push('account_settings')}
                    >
                        Account Settings
                    </button>
                    <button
                        className={classNames({
                            secondaryNavLink: true,
                            secondaryNavActiveLink: pathname === '/company_settings',
                        })}
                        onClick={() => this.props.history.push('company_settings')}
                    >
                        Company Settings
                    </button>
                    {no_integration  && (
                        <button
                            className={classNames({
                                secondaryNavLink: true,
                                secondaryNavActiveLink: pathname === '/resource_settings',
                            })}
                            onClick={() => this.props.history.push('resource_settings')}
                        >
                            Resource Settings
                        </button>
                    )}
                </div>

                <div className="applicationBody">
                    <FloatingSaveButton
                        buttonText={'Save'}
                        hasBeenEdited={this.state.hasBeenEdited}
                        onSave={this.updateCompanySettings}
                    />

                    <div className="bodyHeader">
                        <h2>{accountStore.name}</h2>
                    </div>

                    <h3 className="cardHeading">General Settings</h3>
                    <div className="cardContainer settingsSection">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                Company Name
                                <div className="settingsRequiredLabel">Required</div>
                            </div>
                            <div className="settingsInput">
                                <Input
                                    className={isCompanyNameValid(name) ? '' : 'ausInvalidInput'}
                                    value={name}
                                    onBlur={() => {
                                        if (name === '') {
                                            this.setState({
                                                name: accountStore.name,
                                            });
                                        }
                                    }}
                                    onChange={newName =>
                                        this.setState({
                                            name: newName.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {!isCompanyNameValid(name) && (
                                <div className="ausInvalidInputTip">
                                    {companyNameError(name)}
                                    Only Alphanumeric and some Special Symbols (' - & . , _) allowed
                                    between 3 to 100 characters
                                </div>
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">State</div>
                            <div className="settingsInput">
                                <Input
                                    className={
                                        state === '' || state === null
                                            ? ''
                                            : isStateValid(state)
                                                ? ''
                                                : 'ausInvalidInput'
                                    }
                                    value={state}
                                    onBlur={() => {
                                        if (state === '') {
                                            this.setState({
                                                state: accountStore.state,
                                            });
                                        }
                                    }}
                                    onChange={newState =>
                                        this.setState({
                                            state: newState.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {state === '' || state === null
                                ? ''
                                : !isStateValid(state) && (
                                    <div className="ausInvalidInputTip">
                                        {stateErrorMessage(state)}
                                        State name contains only 1 to 50 alphabets.
                                    </div>
                                )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Zip Code</div>
                            <div className="settingsInput">
                                <Input
                                    className={
                                        zip === '' ? '' : zipCodeValid(zip) ? '' : 'ausInvalidInput'
                                    }
                                    value={zip}
                                    onBlur={() => {
                                        if (zip === '') {
                                            this.setState({
                                                zip: accountStore.zip,
                                            });
                                        }
                                    }}
                                    onChange={newZipCode =>
                                        this.setState({
                                            zip: newZipCode.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {zip === ''
                                ? ''
                                : !zipCodeValid(zip) && (
                                    <div className="ausInvalidInputTip">
                                        {zipErrorMessage(zip)}
                                        Zip code contains only 5 to 10 - and Alphanumeric.
                                    </div>
                                )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                {no_integration 
                                    ? 'Integration Type'
                                    : 'QuickBooks Edition'}
                            </div>
                            <div className="settingsInput csQbType">{accountStore.qb_type}</div>
                            <div className="settingsTip">
                                {no_integration  && (
                                    <span>
                                        If you need to change your Integration, please lock and
                                        expense entries, then
                                    </span>
                                )}

                                {!no_integration  && (
                                    <span>
                                        If you need to change your QuickBooks Edition, please sync
                                        and expense entries, then
                                    </span>
                                )}

                                <button
                                    className="tertiaryButton"
                                    onClick={() => this.props.history.push('contact_us')}
                                >
                                    &nbsp;contact support
                                </button>
                            </div>
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                Logo
                                <div className="settingsAdditionalInfo">jpg, jpeg, png, or gif</div>
                            </div>
                            <div className="settingsInput">
                                <button
                                    className="secondaryButton"
                                    onClick={() => document.getElementById('logoUpload').click()}
                                >
                                    Upload File
                                </button>
                                <input
                                    hidden
                                    key={logoUploadInputKey}
                                    ref={ref => (this.uploadedLogo = ref)}
                                    id="logoUpload"
                                    type="file"
                                    onChange={() => this.uploadLogo()}
                                />
                            </div>
                            {this.renderCompanyLogo()}
                        </div>

                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                Default Timer Rounding Interval
                            </div>
                            <div className="settingsInput">
                                <Select
                                    value={
                                        _.invert(timeRoundingIntervals)[
                                        this.state.default_rounding_interval
                                        ]
                                    }
                                    options={Object.keys(timeRoundingIntervals)}
                                    labelForOption={option => option}
                                    valueForOption={option => option}
                                    onChange={value =>
                                        this.setState({
                                            default_rounding_interval: timeRoundingIntervals[value],
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="settingsSection">
                            <ToggleSetting
                                title="Default Mileage Type"
                                value={reimbursement_rate_type}
                                options={[
                                    { label: 'Company Rate', value: 'company' },
                                    { label: 'Individual Rate', value: 'individual' },
                                ]}
                                valueForOption={option => option.value}
                                labelForOption={option => option.label}
                                onChange={value =>
                                    this.setState({
                                        reimbursement_rate_type: value,
                                        hasBeenEdited: true,
                                    })
                                }
                            />
                            {isMileageCalculatorVisible && (
                                <MileageCalculatorSetting
                                    distanceUnit={preferred_distance_units}
                                    reimbursementRate={default_reimbursement_rate}
                                    onDistanceUnitChange={this.handlePreferredDistanceUnitChange}
                                    onReimbursementRateChange={this.handleReimbursementRateChange}
                                    isHeadingVisible={false}
                                />
                            )}
                        </div>
                    </div>
                    <h3 className="cardHeading">Preferences</h3>
                    <div className="cardContainer settingsSection ">
                        {!no_integration && (
                                <ToggleSetting
                                    title="Invoice Billable Type"
                                    value={this.state.billable_type}
                                    options={[
                                        { label: 'Employee/Vendor', value: 'employee' },
                                        { label: 'Service', value: 'service' },
                                    ]}
                                    valueForOption={option => option.value}
                                    labelForOption={option => option.label}
                                    onChange={value =>
                                        this.setState({
                                            billable_type: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                        )}
                        <ToggleSetting
                            title="Associate expenses with"
                            value={this.state.preferred_expense_type}
                            options={[
                                { label: 'Item', value: 'item' },
                                { label: 'Account', value: 'account' },
                            ]}
                            valueForOption={option => option.value}
                            labelForOption={option => option.label}
                            onChange={value =>
                                this.setState({
                                    preferred_expense_type: value,
                                    hasBeenEdited: true,
                                })
                            }
                        />
                        {accountStore.quickbooks_type !== 'Online' && (
                            <ToggleSetting
                                title="Allow expenses to associate with services"
                                value={this.state.show_service_items_for_expense}
                                options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                ]}
                                valueForOption={option => option.value}
                                labelForOption={option => option.label}
                                onChange={value => {
                                    this.setState({
                                        show_service_items_for_expense: value,
                                        hasBeenEdited: true,
                                    });
                                }}
                            />
                        )}
                        {no_integration && (
                            <ToggleSetting
                                title="Allow items to associate with customers"
                                value={this.state.show_items_association_for_customers}
                                options={[
                                    { label: 'Yes', value: true },
                                    { label: 'No', value: false },
                                ]}
                                valueForOption={option => option.value}
                                labelForOption={option => option.label}
                                onChange={value => {
                                    this.setState({
                                        show_items_association_for_customers: value,
                                        hasBeenEdited: true,
                                    });
                                }}
                            />
                        )}
                        <ToggleSetting
                            title={
                                no_integration 
                                    ? 'Track expense entries as:'
                                    : 'Export expense entries to QuickBooks as:'
                            }
                            value={expense_transaction_type}
                            options={[
                                { label: 'Reimbursable', value: 'bill' },
                                { label: 'Company Credit Card', value: 'purchase' },
                                { label: 'Both', value: 'bill_and_purchase' },
                            ]}
                            valueForOption={option => option.value}
                            labelForOption={option => option.label}
                            onChange={value => this.handleChangeTransactionType(value)}
                        />
                        <div className="cardContent">
                            <div className="csTextAndInput ">
                                Require users to reset passwords every
                                <div className="csInputWithinText">
                                    <Input
                                        id="passwordResetDaysInput"
                                        style={{ textAlign: 'center' }}
                                        type="text"
                                        onBlur={() => {
                                            if (this.state.password_reset_days === '') {
                                                this.setState({
                                                    password_reset_days: 0,
                                                });
                                            }
                                        }}
                                        value={this.state.password_reset_days}
                                        onChange={newResetDays =>
                                            this.setState({
                                                password_reset_days: newResetDays.target.value,
                                                hasBeenEdited: true,
                                            })
                                        }
                                    />
                                </div>
                                days
                            </div>
                            <div className="note ">
                                Set to <b>zero</b> to disable.
                            </div>
                        </div>

                    </div>

                    {!no_integration  && (
                        <>
                            <h3 className="cardHeading">Sync Preferences</h3>
                            <div className="cardContainer cardContent settingsSection">
                                <div className="csTextAndInput">
                                    Wait
                                    <div className="csInputWithinText">
                                        <Input
                                            style={{ textAlign: 'center' }}
                                            type="text"
                                            value={this.state.export_wait_hours}
                                            onBlur={() => {
                                                if (this.state.export_wait_hours === '') {
                                                    this.setState({
                                                        export_wait_hours: 0,
                                                    });
                                                }
                                            }}
                                            onChange={newWait =>
                                                this.setState({
                                                    export_wait_hours: newWait.target.value,
                                                    hasBeenEdited: true,
                                                })
                                            }
                                        />
                                    </div>
                                    hours after a time or expense entry is edited before exporting
                                    it to QuickBooks.
                                </div>
                                <div className="note">
                                    Minute7 will not export time and expenses updated within the
                                    last {accountStore.export_wait_hours} hours
                                    <br />
                                    This helps prevent situations where a time or expense is
                                    exported to QuickBooks before it is finalized. Recommended Sync
                                    Delay is <b>8 hours</b>.
                                </div>
                            </div>
                        </>
                    )}


                    <h3 className="cardHeading">Enabled Features</h3>
                    <div className="cardContainer settingsSection">
                        {
                            <ToggleSetting
                                title={[
                                    <span key={uuid.v4()}>
                                        Enable expense tracking for Vendors (
                                    </span>,
                                    <span
                                        key={uuid.v4()}
                                        className="tertiaryButton"
                                        onClick={() => this.props.history.push('/help')}
                                    >
                                        Read More
                                    </span>,
                                    <span key={uuid.v4()}>)</span>,
                                ]}
                                value={this.state.show_expenses}
                                options={['Yes', 'No']}
                                onChange={value =>
                                    this.setState({
                                        show_expenses: value,
                                        hasBeenEdited: true,
                                    })
                                }
                            />
                        }
                        {
                            <ToggleSetting
                                title="Enable time entries for Vendors"
                                value={this.state.show_vendors}
                                options={['Yes', 'No']}
                                onChange={value =>
                                    this.setState({
                                        show_vendors: value,
                                        hasBeenEdited: true,
                                    })
                                }
                            />
                        }
                        {!no_integration && (
                            <ToggleSetting
                                title="Allow users to assign QuickBooks classes to entries"
                                value={this.state.show_qb_classes}
                                options={['Yes', 'No']}
                                onChange={value =>
                                    this.setState({
                                        show_qb_classes: value,
                                        hasBeenEdited: true,
                                    })
                                }
                            />
                        )}
                        {
                            <ToggleSetting
                                title="Show payroll items for time entries"
                                value={this.state.show_payroll}
                                options={['Yes', 'No']}
                                onChange={value =>
                                    this.setState({
                                        show_payroll: value,
                                        hasBeenEdited: true,
                                    })
                                }
                            />
                        }
                    </div>

                    <h3 className="cardHeading">Time and Expense Preferences</h3>
                    <div className="cardContainer settingsSection">
                        <div>
                            {!no_integration  &&(
                                <ToggleSetting
                                    title={'Prevent users from editing time and expense entries that have been synced'
                                    }
                                    value={this.state.prevent_edit_for_synced_entries}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            prevent_edit_for_synced_entries: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            )}
                            {no_integration  && (
                                <ToggleSetting
                                    title={'Prevent users from editing time and expense entries that have been locked'}
                                    value={this.state.prevent_edit_for_locked_entries}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            prevent_edit_for_locked_entries: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            )

                            }
                            {
                                <ToggleSetting
                                    title="Mark time and expense entries 'Approved' by default"
                                    value={this.state.approved_by_default}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            approved_by_default: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            }
                            {
                                <ToggleSetting
                                    title="Mark time and expense entries 'Billable' by default"
                                    value={this.state.billable_by_default}
                                    options={['Yes', 'No']}
                                    onChange={value => this.handleChangeBillableByDefault(value)}
                                />
                            }
                            {accountStore.qb_type === 'Online' &&
                                this.state.billable_by_default === 'Yes' && (
                                    <ToggleSetting
                                        title="Mark time entries 'Taxable' by default"
                                        value={this.state.taxable_by_default}
                                        options={['Yes', 'No']}
                                        onChange={value =>
                                            this.setState({
                                                taxable_by_default: value,
                                                hasBeenEdited: true,
                                            })
                                        }
                                    />
                                )}
                            {
                                <ToggleSetting
                                    title="Allow users to set the billable status of time and expense entries"
                                    value={show_billable}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            show_taxable: value === 'No' ? 'No' : show_taxable,
                                            show_billable: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            }
                            {accountStore.qb_type === 'Online' && (
                                <ToggleSetting
                                    title="Allow users to set the taxable status of time"
                                    subtitle="(This feature requires that you also have 'Allow users to set the billable status of time entries' feature set to 'Yes')"
                                    value={show_taxable}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            show_billable: value === 'Yes' ? 'Yes' : show_billable,
                                            show_taxable: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            )}
                            {!no_integration  && (
                                <ToggleSetting
                                    title="Automatically fill in time entry description (when blank) with QuickBooks Service Item description"
                                    value={this.state.time_autofill_description}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            time_autofill_description: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            )}
                            {!no_integration  && (
                                <ToggleSetting
                                    title="Prefix employee/vendor name to each time entry description"
                                    value={this.state.time_prepend_name}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            time_prepend_name: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            )}
                            {!no_integration  && (
                                <ToggleSetting
                                    title="Prefix vendor name to each expense entry description"
                                    value={this.state.expenses_prepend_name}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            expenses_prepend_name: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            )}
                            {!no_integration && (
                                <ToggleSetting
                                    title="Show all QuickBooks accounts when entering expenses"
                                    value={this.state.show_all_qb_account_types}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            show_all_qb_account_types: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            )}
                            {
                                <ToggleSetting
                                    title="Show pound sign instead of dollar sign for expenses"
                                    value={prefer_pound_sign}
                                    options={['Yes', 'No']}
                                    onChange={value =>
                                        this.setState({
                                            prefer_pound_sign: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const no_integration = state.accountStore.qb_type === 'None' ? true : false;
    return {
        accountStore: state.accountStore,
        employees: state.businessResourcesStore.employees,
        vendors: state.businessResourcesStore.vendors,
        no_integration: no_integration
    };
}

const mapDispatchToProps = {
    showAlert,
    showToast,
    setAccountStore,
    setCanViewEmployees,
    setCanEditEmployees,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompanySettings);
