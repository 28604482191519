import React from 'react';

const UploadCompanyLogo = () => (
    <div>
        <div className="sectionQuestion">Uploading Your Company's Logo</div>
        <div className="sectionAnswer">
            Managers can upload logos on their account settings page. File Requirements: The logo
            must be a JPEG, PNG, or GIF file at a resolution of 72 pixels per inch (ppi). We
            recommend sizing the image to 250 pixels wide by 100 pixels tall on a white or
            transparent background.
        </div>
    </div>
);

export default UploadCompanyLogo;
