export const receiveCustomers = payload => ({
    type: 'RECEIVE_CUSTOMERS',
    payload,
});

export const receiveInventoryItems = payload => ({
    type: 'RECEIVE_INVENTORY_ITEMS',
    payload,
});

export const receiveQbAccounts = payload => ({
    type: 'RECEIVE_QB_ACCOUNTS',
    payload,
});

export const receiveQbClasses = payload => ({
    type: 'RECEIVE_QB_CLASSES',
    payload,
});

export const setExpenseGroupings = payload => ({
    type: 'SET_EXPENSE_GROUPINGS',
    payload,
});

export const setPermissionsGroups = payload => ({
    type: 'SET_PERMISSIONS_GROUPS',
    payload,
});

export const requestExpenseGroupingsForVendor = vendorId => ({
    type: 'REQUEST_EXPENSE_GROUPINGS_FOR_VENDOR',
    vendorId,
});

export const requestExpenseGroupingsForVendorError = error => ({
    type: 'REQUEST_EXPENSE_GROUPINGS_FOR_VENDOR_ERROR',
    error,
});

export const receiveExpenseGroupingsForVendor = ({ vendorId, expenseGroupings }) => ({
    type: 'RECEIVE_EXPENSE_GROUPINGS_FOR_VENDOR',
    vendorId,
    expenseGroupings,
});

export const addExpenseGrouping = (expenseGrouping, callback) => ({
    type: 'ADD_EXPENSE_GROUPING',
    expenseGrouping,
    callback,
});

export const addExpenseGroupingSuccess = expenseGrouping => ({
    type: 'ADD_EXPENSE_GROUPING_SUCCESS',
    expenseGrouping,
});

export const addExpenseGroupingError = error => ({
    type: 'ADD_EXPENSE_GROUPING_ERROR',
    error,
});

export const updateExpenseGrouping = (expenseGrouping, callback) => ({
    type: 'UPDATE_EXPENSE_GROUPING',
    expenseGrouping,
    callback,
});

export const updateExpenseGroupingSuccess = expenseGrouping => ({
    type: 'UPDATE_EXPENSE_GROUPING_SUCCESS',
    expenseGrouping,
});

export const updateExpenseGroupingError = error => ({
    type: 'UPDATE_EXPENSE_GROUPING_ERROR',
    error,
});

export const requestPayrollItemsForEmployee = employeeId => ({
    type: 'REQUEST_PAYROLL_ITEMS_FOR_EMPLOYEE',
    employeeId,
});

export const requestPayrollItemsForEmployeeError = error => ({
    type: 'REQUEST_PAYROLL_ITEMS_FOR_EMPLOYEE_ERROR',
    error,
});

export const receivePayrollItemsForEmployee = ({ employeeId, payrollItems }) => ({
    type: 'RECEIVE_PAYROLL_ITEMS_FOR_EMPLOYEE',
    employeeId,
    payrollItems,
});

export const requestBusinessResources = payload => ({
    type: 'REQUEST_BUSINESS_RESOURCES',
    payload,
});

export const requestBusinessResourcesError = error => ({
    type: 'REQUEST_BUSINESS_RESOURCES_ERROR',
    error,
});

export const receiveBusinessResources = (payload, options = { replace: false }) => ({
    type: 'RECEIVE_BUSINESS_RESOURCES',
    payload,
    options: { replace: false, ...options },
});

export const resourcesLoaded = () => ({
    type: 'RESOURCES_LOADED',
});

export const addPermissionsGroup = permissionsGroup => ({
    type: 'ADD_PERMISSIONS_GROUP',
    permissionsGroup,
});

export const addPermissionsGroupSuccess = permissionsGroups => ({
    type: 'ADD_PERMISSIONS_GROUP_SUCCESS',
    permissionsGroups,
});

export const addPermissionsGroupError = error => ({
    type: 'ADD_PERMISSIONS_GROUP_ERROR',
    error,
});

export const updatePermissionsGroup = permissionsGroup => ({
    type: 'UPDATE_PERMISSIONS_GROUP',
    permissionsGroup,
});

export const updatePermissionsGroupSuccess = permissionsGroup => ({
    type: 'UPDATE_PERMISSIONS_GROUP_SUCCESS',
    permissionsGroup,
});

export const updatePermissionsGroupError = error => ({
    type: 'UPDATE_PERMISSIONS_GROUP_ERROR',
    error,
});

export const deletePermissionsGroup = payload => ({
    type: 'DELETE_PERMISSIONS_GROUP',
    payload,
});

export const deletePermissionsGroupSuccess = payload => ({
    type: 'DELETE_PERMISSIONS_GROUP_SUCCESS',
    payload,
});
