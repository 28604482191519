import React from 'react';

const Minute7Secure = () => (
    <div>
        <div className="sectionQuestion">Is my information secure and confidential?</div>
        <div className="sectionAnswer">
            Yes. We take security and privacy very seriously. We encrypt all your billing
            information and all data transmission is conducted through SSL, just like a bank.
        </div>
    </div>
);

export default Minute7Secure;
