import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import moment from 'moment';
import classNames from 'classnames';
import { PATTERNS } from '../../../config/Patterns';
import { durationStringToDuration } from '../../../utils/TimeEntryUtils';
import { calculateDurationFromStartAndEndTimes } from '../../../utils/DateUtils';

const FORMAT = 'HH:mm';

const StartAndEndTimeCalculator = forwardRef(
    (
        {
            startTime,
            endTime,
            isOpen,
            offHours,
            duration,
            calculateDuration,
            updateHoursOffProps,
            updateStartTimeProps,
            updateEndTimeProps,
        },
        ref
    ) => {
        let startMoment = moment(startTime);
        startMoment = startMoment.isValid() ? startMoment : moment();

        let endMoment = moment(endTime);
        endMoment = endMoment.isValid() ? endMoment : moment();

        const [start, setStart] = useState(startMoment.format(FORMAT));
        const [end, setEnd] = useState(moment(endMoment).format(FORMAT));
        const [startIsValid, setStartIsValid] = useState(true);
        const [hoursOffDuty, setHoursOffDuty] = useState(offHours);
        const [endIsValid, setEndIsValid] = useState(true);

        useEffect(
            () => {
                setHoursOffDuty(offHours);
            },
            [offHours]
        );

        useEffect(
            () => {
                setEnd(moment(endMoment).format(FORMAT));
            },
            [moment(endMoment).format(FORMAT)]
        );

        useEffect(
            () => {
                setStart(startMoment.format(FORMAT));
            },
            [startMoment.format(FORMAT)]
        );

        const calculateDurationByValues = event => {
            if (startIsValid && endIsValid) calculateDuration({ start, end, hoursOffDuty });
        };

        const validateStartTime = event => {
            const value = event.target.value;
            setStart(value);
            const time = moment(value.trim(), FORMAT);
            if (time.isValid()) {
                setStartIsValid(true);
                updateStartTimeProps(time.format(FORMAT));
            } else {
                setStartIsValid(false);
            }
        };

        const validateEndTime = event => {
            const value = event.target.value;
            setEnd(value);
            const time = moment(value.trim(), FORMAT);
            if (time.isValid()) {
                setEndIsValid(true);
                updateEndTimeProps(time.format(FORMAT));
            } else {
                setEndIsValid(false);
            }
        };

        const removeExtraValues = event => {
            let hoursOffDutyValue = removeAlphaValues(event.target.value);
            hoursOffDutyValue = removeInvalidMinutes(hoursOffDutyValue);
            setHoursOffDuty(hoursOffDutyValue);
            updateHoursOffProps(hoursOffDutyValue);
        };

        function removeInvalidMinutes(hoursOfDutyValue) {
            if (isInvalidDuration(duration, hoursOfDutyValue))
                return hoursOfDutyValue.substr(0, hoursOfDutyValue.length - 1);
            else if (isInvalidMinuteFormat(hoursOfDutyValue))
                return hoursOfDutyValue.substr(0, hoursOfDutyValue.length - 1);
            return hoursOfDutyValue;
        }

        function removeAlphaValues(hoursOfDutyValue) {
            return hoursOfDutyValue.replace(/[^0-9.:]/gi, '');
        }

        function isInvalidMinuteFormat(hoursOfDutyValue) {
            return !PATTERNS.durationFormat.test(hoursOfDutyValue);
        }

        function isInvalidDuration(duration, offHours) {
            const durationAsMilli = durationStringToDuration(duration).asMilliseconds();
            const hoursOffAsMilli = durationStringToDuration(offHours).asMilliseconds();
            const startAndEndTimeDifference = calculateDurationFromStartAndEndTimes(start, end);
            const differentAsMilli = durationStringToDuration(
                startAndEndTimeDifference
            ).asMilliseconds();
            return isBlank(startAndEndTimeDifference)
                ? invalidValue(duration) || isBlank(duration) || durationAsMilli < hoursOffAsMilli
                : invalidValue(duration) || differentAsMilli < hoursOffAsMilli;
        }

        function isBlank(value) {
            return value === '' || value === ' ' || value === '0:00';
        }

        function invalidValue(value) {
            return (
                value === ':' ||
                value === '0:0' ||
                value === ':0' ||
                value === ':00' ||
                value === '0:' ||
                value === '00:00'
            );
        }

        return (
            <div className="startAndEndTimeCalculator" hidden={!isOpen} ref={ref}>
                <h3 className="formGroupHeading">Start And End Time Calculator</h3>
                <div className="startAndEndTimeCalculatorInputContainer">
                    <div
                        className={classNames({
                            stackedLabelAndInput: true,
                            invalid: !startIsValid,
                        })}
                    >
                        <label className="inputLabel">Start Time</label>
                        <Input type="time" value={start} onChange={validateStartTime} />
                    </div>
                    <div
                        className={classNames({
                            stackedLabelAndInput: true,
                            invalid: !endIsValid,
                        })}
                    >
                        <label className="inputLabel">End Time</label>
                        <Input type="time" value={end} onChange={validateEndTime} />
                    </div>
                </div>
                <div className="stackedLabelAndInput fieldGroup">
                    <label className="inputLabel">Hours Off Duty</label>
                    <Input
                        id="hoursOfDuty"
                        value={hoursOffDuty === '0:00' ? '' : hoursOffDuty}
                        placeholder="0:00"
                        onChange={removeExtraValues}
                    />
                </div>
                <button className="secondaryButton" onClick={calculateDurationByValues}>
                    Calculate
                </button>
            </div>
        );
    }
);

StartAndEndTimeCalculator.propTypes = {
    startTime: PropTypes.object.isRequired,
    onStartTimeChanged: PropTypes.func.isRequired,
    endTime: PropTypes.object.isRequired,
    onEndTimeChanged: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onHoursOffDutyChanged: PropTypes.func.isRequired,
    hoursOffDuty: PropTypes.string,
};

export default StartAndEndTimeCalculator;
