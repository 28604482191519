import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { showToast } from '../../../actions/Actions.js';
import 'react-phone-input-2/lib/style.css';
import Spinner from '../../components/Spinner.js';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';
import { addAccount } from '../../../actions/AccountsActions.js';
import { selectAccount } from '../../../actions/Actions.js';
import ToggleSettingList from '../../components/ToggleSettingList.js';

class NewAccountTypeChoiceForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState({ selectedView: 'accounts' });
    }

    componentWillReceiveProps(props) {}

    defaultState = data => {
        return {
            accountSettings: '',
            spinner_loader: false,
        };
    };

    handleFormChange = event => {
        event.preventDefault();
        const { accountSettings } = this.state;
        if (accountSettings === '') {
            this.props.showToast('Please select account type', 'error');
            return;
        }
        if (accountSettings === 'general') {
            this.props.history.push('create_new_account');
        } else {
            this.props.history.push('create_new_payment_account');
        }
    };

    handleFormCancel = () => {
        this.props.history.push('/resource_settings/accounts');
    };

    render() {
        const { spinner_loader, accountSettings, accountCreation } = this.state;
        return (
            <div className="applicationWrapper">
                <div className="application">
                    <div className="ftContainer cardContainer">
                        <form className="ftForm" onSubmit={this.handleFormChange}>
                            <Row className="ftRowAlightCenter">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Create New Account
                                </div>
                            </Row>
                            {!accountCreation && (
                                <Row className="ftrow">
                                    <Col sm={12} style={{ marginBottom: '5px' }}>
                                        <div className="ftLabelAndInput">
                                            <ToggleSettingList
                                                title={[
                                                    <span style={{ fontWeight: 'bold' }}>
                                                        Please select the account type you want to create{' '}
                                                    </span>,
                                                ]}
                                                value={accountSettings}
                                                options={[
                                                    { label: 'General Account', value: 'general' , message: 'General Accounts help you break down your expenses into “categories” so it’s easier to  manage your company related expenses.'},
                                                    { label: 'Payment Account', value: 'payment', message: 'Payment Accounts allow you to record expense tied to credit cards, bank accounts or digital wallets.' },
                                                ]}
                                                valueForOption={option => option.value}
                                                labelForOption={option => option.label}
                                                infoMessageForOption={option => option.message}
                                                enableInfoTip={true}
                                                onChange={value => {
                                                    this.setState({
                                                        accountSettings: value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button type="submit" className="primaryButton ftButton">
                                            Next
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button
                                            type="button"
                                            className="secondaryButton ftButton"
                                            onClick={this.handleFormCancel}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Spinner loading={spinner_loader} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        isSaving: state.accountUserStore.isSaving,
    };
}

const mapDispatchToProps = {
    showToast,
    addAccount,
    selectAccount,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewAccountTypeChoiceForm);
