import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { sendWelcomeEmail } from '../requests/AccountUserRequests';
import { updateExistingVendor, createNewVendor, getAllVendors, deactivateVendor, activateVendor, getCanEditVendors } from '../requests/VendorRequests';
import * as uiActions from '../actions/Actions';
import * as vendorActions from '../actions/VendorActions';
import * as businessResouceActions from '../actions/BusinessResourcesActions';
import _ from 'lodash';
import { employeeFields } from '../config/Fields';

/**
 * Returns a Vendor object for api calls
 * @param {Object} user holds all accountUser settings
 */
const formDataForVendor = vendor => {
    const data = {};
    _.forEach(_.pick(vendor, employeeFields), (value, key) => {
        switch (key) {
            case 'vendor_id': {
                if (String(value).includes('_v')) {
                    data['Vendor[vendor_id]'] = value.replace('_v', '');
                    data['Vendor[type]'] = 'Vendor';
                } else {
                    data['Vendor[Vendor_id]'] = value;
                    data['Vendor[type]'] = 'Vendor';
                }
                break;
            }
            case 'password': {
                if (value.toString().replace(/\s+/, '').length > 0) {
                    data['Vendor[password]'] = value;
                }
                break;
            }
            case 'type': {
                break;
            }
            default: {
                data[`Vendor[${key}]`] = value;
            }
        }
    });
    return data;
};

export function* updateVendor({ vendor, callback }) {
    try {
        const data = formDataForVendor(vendor);
        const updatedVendor = yield call(updateExistingVendor, data);
        const updatedVendors = yield call(getAllVendors);

        yield put(vendorActions.updateVendorSuccess(updatedVendor));
        yield put(vendorActions.setAllVendors(updatedVendors));
        yield put(businessResouceActions.requestBusinessResources());
        const action = yield take(['REFRESH_VENDORS_SUCCESS']);
        if (action.type === 'REFRESH_VENDORS_SUCCESS') {
            callback(updatedVendor); // Invoke the callback function
        }
    } catch (error) {
        callback(false);
        console.log(error);
        yield put(vendorActions.updateVendorError(error));
        yield put(uiActions.showToast('Couldn’t update this vendor. Try again later.', 'negative'));
    }
}

export function* addVendor({ vendor, callback }) {
    try {
        const data = formDataForVendor(vendor);
        const newVendor = yield call(createNewVendor, data);
        const updatedVendors = yield call(getAllVendors);

        yield put(vendorActions.addVendorSuccess(newVendor));
        yield put(vendorActions.setAllVendors(updatedVendors));
        yield put(businessResouceActions.requestBusinessResources());
        yield call(sendWelcomeEmail, newVendor);
        const action = yield take(['REFRESH_VENDORS_SUCCESS']);
        if (action.type === 'REFRESH_VENDORS_SUCCESS') {
            callback(newVendor); // Invoke the callback function
        }
    } catch (error) {
        callback(false);
        console.log(error);
        yield put(vendorActions.addVendorError(error));
        yield put(uiActions.showToast(error.message, 'negative'));
    }
}

export function* unhideVendor({ vendorId }) {
    try {
        yield call(activateVendor, vendorId);
        const updatedVendors = yield call(getAllVendors);

        yield put(vendorActions.setAllVendors(updatedVendors));
        yield put(businessResouceActions.requestBusinessResources());
        yield put(uiActions.showToast('Vendor has been activated', 'positive'));
    } catch (error) {
        yield put(vendorActions.unhideVendorError(error));
        yield put(
            uiActions.showToast('Couldn’t activate this vendor. Try again later.', 'negative')
        );
    }
}

export function* hideVendor({ vendorId }) {
    try {
        yield call(deactivateVendor, vendorId);
        const updatedVendors = yield call(getAllVendors);

        yield put(vendorActions.setAllVendors(updatedVendors));
        yield put(businessResouceActions.requestBusinessResources());
        yield put(uiActions.showToast('Vendor has been deactivated', 'positive'));
    } catch (error) {
        yield put(vendorActions.hideVendorError(error));
        yield put(
            uiActions.showToast('Couldn’t deactivate this vendor. Try again later.', 'negative')
        );
    }
}

export function* refreshVendors() {
    try {
        const updatedVendors = yield call(getCanEditVendors);
        yield put(vendorActions.setCanEditVendors(updatedVendors));
        yield put(vendorActions.refreshVendorsSuccess());
    } catch (error) {
        yield put(
            uiActions.showToast(
                'Couldn’t load data from our servers. Try reloading the page.',
                'negative'
            )
        );
    }
}

export default function* root() {
    yield all([takeLatest(['UPDATE_VENDOR'], updateVendor), takeLatest(['ADD_VENDOR'], addVendor),
    takeLatest(['DEACTIVATE_VENDOR'], hideVendor),
    takeLatest(['ACTIVATE_VENDOR'], unhideVendor),
    takeLatest(['REFRESH_VENDORS'], refreshVendors),]);
}
