import React from 'react';

const EnableTimeTracking = () => (
    <div>
        <div className="sectionQuestion">
            Figure 1 Figure 2 Figure 2 QuickBooks Web Connector gives me the error "QuickBooks could
            not add time and expense entries because your QuickBooks company file does not have time
            tracking enabled".
        </div>
        <div className="sectionAnswer">
            If you have received this error message, QuickBooks is having trouble adding the time
            entries from Minute7 because your QuickBooks company file is not setup to allow time
            tracking. You can set your QuickBooks company file to <b>enable time tracking</b> by
            doing the following:
            <ol>
                <li>Open your QuickBooks company file in QuickBooks.</li>
                <li>
                    In the top menubar, go to <b>"Edit"</b>, then <b>"Preferences..."</b> The
                    QuickBooks preference window will open up.
                </li>
                <li>
                    In the left-hand column, choose <b>"Time Tracking".</b>
                </li>
                <li>
                    Select the <b>"Company Preferences"</b> tab.
                </li>
                <li>
                    For the option "Do You Track Time?", select <b>"Yes"</b>
                </li>
                <li>
                    Click <b>"OK"</b> to close the Preferences window and save your changes.
                </li>
                <li>
                    Close and reopen QuickBooks and the Web Connector (if it is open). You should
                    now be able to sync time entries with Minute7.
                </li>
            </ol>
        </div>
    </div>
);

export default EnableTimeTracking;
