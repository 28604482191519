import _ from 'lodash';
import React, { useRef, memo } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { caretDown } from 'react-icons-kit/fa/caretDown';
import { tick as tickIcon } from 'react-icons-kit/ikons/tick';
import { plus as plusIcon } from 'react-icons-kit/ikons/plus';
import classNames from 'classnames';

import RadioInput from './RadioInput';
import { groupingOptions as GROUPING_OPTIONS } from '../../config/Fields';
import useDismissableByClickOutside from '../../hooks/useDismissableByClickOutside';

import styles from '../../css/MoreOptionsDropdown.module.css';

// this is a combination of all filters considered permanent
// on both the expenses and the timesheets view
const PERMANENT_FILTERS = ['date', 'search', 'customer', 'approved', 'inventoryItem', 'employee'];

const FilterBubble = ({ isActive, label, id, onClick }) => (
    <button
        onClick={() => onClick(id)}
        className={classNames({
            [styles.filterBubble]: true,
            [styles.activeFilterBubble]: isActive,
        })}
    >
        <Icon className={styles.filterBubbleIcon} size={16} icon={isActive ? tickIcon : plusIcon} />
        {label}
    </button>
);

const MoreOptionsDropdown = ({
    groupingType,
    onGroupingTypeChanged,
    activeFilters,
    availableFilters,
    onFilterPressed,
}) => {
    const containerRef = useRef(null);
    const [isOpen, setIsOpen] = useDismissableByClickOutside(containerRef);

    const handleTogglePressed = () => {
        setIsOpen(true);
    };

    // available - permanently displayed
    const filters = availableFilters.filter(
        ({ filter: { id } }) => !_.includes(PERMANENT_FILTERS, id)
    );

    const showGroupingTypes = !_.isNil(groupingType);

    return (
        <div className={styles.container} ref={containerRef}>
            <button
                onClick={handleTogglePressed}
                className={classNames('secondaryButton', styles.buttonToggle)}
            >
                More
                <Icon className={styles.buttonToggleIcon} size={15} icon={caretDown} />
            </button>

            <div className={styles.dropdownContainer} hidden={!isOpen}>
                <div className={styles.section}>
                    <h3 className={classNames(styles.sectionHeading, 'formGroupHeading')}>
                        More Filters
                    </h3>
                    <div className={styles.filters}>
                        {filters.map(({ filter }) => (
                            <FilterBubble
                                isActive={_.includes(activeFilters, filter.id)}
                                label={filter.name}
                                key={filter.id}
                                id={filter.id}
                                onClick={onFilterPressed}
                            />
                        ))}
                    </div>
                </div>

                {showGroupingTypes && (
                    <div className={styles.section}>
                        <h3 className={classNames(styles.sectionHeading, 'formGroupHeading')}>
                            Grouping Options
                        </h3>
                        <div className="radioGroup">
                            <RadioInput
                                onClick={() => onGroupingTypeChanged('week')}
                                checked={groupingType === 'week'}
                                label={GROUPING_OPTIONS.week}
                            />
                            <RadioInput
                                onClick={() => onGroupingTypeChanged('individual_entries')}
                                checked={groupingType === 'individual_entries'}
                                label={GROUPING_OPTIONS.individual_entries}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

MoreOptionsDropdown.propTypes = {
    activeFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
    availableFilters: PropTypes.array.isRequired,
    groupingType: PropTypes.oneOf(Object.keys(GROUPING_OPTIONS)),
    onGroupingTypeChanged: PropTypes.func,
    onFilterPressed: PropTypes.func.isRequired,
};

export default memo(MoreOptionsDropdown);
