import React, { Component } from 'react';
import { passwordReset } from '../../requests/AccountUserRequests.js';
import * as commonFunctions from '../../utils/CommonFunctions.js';
import { Input } from 'reactstrap';
import DocumentTitle from 'react-document-title';
import '../../css/login/Login.css';
import { connect } from 'react-redux';
import { showAlert } from '../../actions/Actions.js';

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
    }

    passwordReset = () => {
        if (commonFunctions.isValidEmail(this.state.email)) {
            const paramsObject = {
                email: this.state.email,
            };
            passwordReset(paramsObject)
                .then(res => {
                    if (res === '' || ('success' in res && res.success)) {
                        this.props.showAlert(
                            'We’ve emailed you a new password. Be sure to update it once you’ve logged in.',
                            'positive'
                        );
                        this.props.history.push('/login');
                    } else {
                        this.props.showAlert(
                            'We couldn’t find an account associated with the email address you provided.',
                            'negative'
                        );
                    }
                })
                .catch(error => {
                    this.props.showAlert(
                        'We were unable to reset your password at this time',
                        'negative'
                    );
                });
        } else {
            this.props.showAlert('Please provide a valid email', 'warning');
        }
    };

    render() {
        return (
            <div className="lContainer">
                <DocumentTitle title="Password Reset" />
                <div className="lSignInText">Reset Your Password</div>
                <div className="lInputContainer cardContainer">
                    <div className="lPasswordResetInstruction">
                        Enter an email associated with an account and we'll send you a new password
                    </div>
                    <div className="lLabelAndInput">
                        <div className="lLabel">Email</div>
                        <Input
                            onChange={newEmail =>
                                this.setState({
                                    email: newEmail.target.value,
                                    invalidEmail: false,
                                })
                            }
                            type="text"
                        />
                    </div>
                    <div className="lButtonContainer">
                        <button
                            className="lResetPasswordLogin secondaryButton"
                            onClick={() => this.props.history.push('/login')}
                        >
                            Login
                        </button>
                        <button className="primaryButton" onClick={this.passwordReset}>
                            Reset Password
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
    { showAlert }
)(PasswordReset);
