import React from 'react';

const ResetPassword = () => (
    <div>
        <div className="sectionQuestion">
            I am having trouble getting into my account. How can I reset my password?
        </div>
        <div className="sectionAnswer">
            The first thing to check is that you are using the same email address that you used to
            create your Minute7 account. If you are sure you have the right email address, here are
            the steps to reset your password:
            <ol>
                <li>Go to www.minute7.com</li>
                <li>Click "Sign In" in the upper right corner of the page</li>
                <li>
                    Click on the &nbsp;
                    <span
                        onClick={() => (window.location.href = '/password_reset')}
                        className="secondaryButton"
                    >
                        Reset Your Password
                    </span>
                    &nbsp; to the left of the "Sign In Button" as shown below:
                    <div className="listImageContainer">
                        <img src="./help_images/ResetPassword.png" alt="Sign In Form" />
                    </div>
                </li>
                <li>
                    Enter in your email address and you will receive a new password by email within
                    a few minutes
                </li>
            </ol>
        </div>
    </div>
);

export default ResetPassword;
