import { all, fork } from 'redux-saga/effects';
import AccountSaga from './AccountSaga';
import AccountUserSaga from './AccountUserSaga';
import TimesheetsSaga from './TimesheetsSaga';
import ExpensesSaga from './ExpensesSaga';
import BusinessResourcesSaga from './BusinessResourcesSaga';
import TimerSaga from './TimerSaga';
import ReportsSaga from './ReportsSaga';
import UISaga from './UISaga';
import EmployeeSaga from './EmployeesSaga';
import VendorSaga from './VendorsSaga';
import CustomerSaga from './CustomersSaga';
import PayrollItemSaga from './PayrollItemSaga';
import ItemSaga from './ItemSaga';
import AccountsSaga from './AccountsSaga';
import ReportFilterSaga from './ReportFilterSaga'
export default function* root() {
    yield all([
        fork(AccountSaga),
        fork(AccountUserSaga),
        fork(TimesheetsSaga),
        fork(ExpensesSaga),
        fork(BusinessResourcesSaga),
        fork(TimerSaga),
        fork(ReportsSaga),
        fork(UISaga),
        fork(EmployeeSaga),
        fork(VendorSaga),
        fork(CustomerSaga),
        fork(PayrollItemSaga),
        fork(ItemSaga),
        fork(AccountsSaga),
        fork(ReportFilterSaga),
    ]);
}
