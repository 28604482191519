import { updateEntryInArray } from '../utils/CommonFunctions';

export const INITIAL_STATE = {
    id: null,
    name: '',
    accountUsers: [],
    billing_first_name: '',
    billing_last_name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    cc_number: '',
    cc_type: '',
    security_code: '',
    expiration_month: '',
    expiration_year: '',
    default_rounding_interval: '',
    logo: null,
    prevent_edit_for_synced_entries: null,
    prevent_edit_for_locked_entries: null,
    expense_transaction_type: null,
    approved_by_default: null,
    show_vendors: null,
    show_expenses: null,
    show_qb_classes: null,
    show_payroll: null,
    show_all_qb__types: null,
    show_billable: null,
    show_taxable: null,
    taxable_by_default: null,
    billable_by_default: null,
    time_prepend_name: null,
    expenses_prepend_name: null,
    time_autofill_description: null,
    active: null,
    reactivated_count: null,
    paid_through: null,
    referred_by: null,
    promo_code_id: null,
    trial_length: null,
    trial_reminder_sent: null,
    export_wait_hours: null,
    last_sync: null,
    sync_entries: null,
    auto_sync: null,
    qb_type: null,
    qb_owner_id: null,
    qb_file_id: null,
    notes: null,
    demo_completed: null,
    sale_priority: null,
    company_size: null,
    industry: '',
    test_: null,
    password_reset_days: null,
    first_sync: null,
    last_active: null,
    first_paid: null,
    last_paid: null,
    total_revenue: null,
    converted_from_trial: null,
    created: null,
    billable_type: '',
    is_deactive_expired_trial_account: null,
    reimbursement_rate_type: '',
    default_reimbursement_rate: null,
    preferred_distance_units: '',
    preferred_expense_type: '',
    show_service_items_for_expense: null,
    prefer_pound_sign: 'No',
};

export const accountReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_ACCOUNT_STORE':
            let accountStore = action.payload;
            if ('Account' in action.payload) {
                accountStore = action.payload['Account'];
            }
            if ('AccountUsers' in action.payload) {
                accountStore['accountUsers'] = action.payload['AccountUsers'].map(
                    ({ AccountUser }) => AccountUser
                );
                delete accountStore['AccountUsers'];
            }
            return {
                ...state,
                ...accountStore,
            };
        case 'UPDATE_USER_SUCCESS': {
            return {
                ...state,
                accountUsers: updateEntryInArray(state.accountUsers, action.user),
            };
        }
        case 'ADD_USER_SUCCESS': {
            return {
                ...state,
                accountUsers: [...state.accountUsers, action.user],
            };
        }
        case 'DELETE_USER_SUCCESS': {
            return {
                ...state,
                accountUsers: state.accountUsers.filter(u => u.id !== action.id),
            };
        }
        case 'ACCOUNT_TOGGLE_AUTO_SYNCING_SUCCESS': {
            return {
                ...state,
                auto_sync: action.isEnabled ? 'Yes' : 'No',
            };
        }
        case 'ACCOUNT_TOGGLE_SYNCING_SUCCESS': {
            const auto_sync = action.isEnabled ? state.auto_sync : 'No';

            return {
                ...state,
                sync_entries: action.isEnabled ? 'Yes' : 'No',
                auto_sync: auto_sync,
            };
        }
        case 'REACTIVATE_ACCOUNT_SUCCESS': {
            return {
                ...state,
                ...action.account,
            };
        }
        case 'DEACTIVATE_ACCOUNT_SUCCESS': {
            return {
                ...state,
                ...action.account,
            };
        }
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default accountReducer;
