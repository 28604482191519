import _ from 'lodash';
import { all, call, put, fork, cancel, select, take, takeLatest } from 'redux-saga/effects';
import { segmentExpenses, segmentTime } from '../requests/ReportRequests';
import {
    figureNameOrDescriptionFromKeyWithId,
    properDisplayNameFromIdName,
} from '../utils/CommonFunctions.js';
import * as reportActions from '../actions/ReportActions';
import * as uiActions from '../actions/Actions';

const entityForIdAndType = (id, type, state) => {
    const {
        businessResourcesStore: resources,
        employeeStore: { canViewEmployees },
        vendorStore: { canViewVendors },
    } = state;

    if (id === '0') {
        return {
            id,
            name: `No ${properDisplayNameFromIdName(type)} Selected`,
        };
    }

    switch (type) {
        case 'employee_id': {
            const employee = canViewEmployees.find(employee => employee.id === id);
            if (!employee) return {};

            return {
                id: employee.id,
                first_name: employee.first_name,
                last_name: employee.last_name,
                name: employee.name,
            };
        }
        // Note: This code seems not in use
        case 'vendor_id': {
            const vendor = canViewVendors.find(vendor => vendor.id === id);
            if (!vendor) return {};

            return {
                id: vendor.id,
                name: vendor.name,
            };
        }
        default: {
            const name = figureNameOrDescriptionFromKeyWithId(type, id, resources);
            return {
                id,
                name,
            };
        }
    }
};

const denormalizeSegmentData = (state, data, segments) => {
    const rows = [];
    const segment = segments.shift();

    _.forOwn(data, (entry, id) => {
        if (id !== 'sum' && id !== 'label') {
            const entity = entityForIdAndType(id, segment, state);
            if (segments.length > 0) {
                entity.rows = denormalizeSegmentData(state, entry, [...segments]);
            }
            entity.sum = entry.sum;
            if (!entity.name) {
                entity.name = entry.label;

                // infer first_name and last_name by splitting the name
                // required for sorting properly
                if (segment === 'employee_id' && entry.label) {
                    const [firstName, ...lastName] = entry.label.split(' ');
                    entity.first_name = firstName;
                    entity.last_name = lastName.join(' ');
                }
            }
            rows.push(entity);
        }
    });

    return _.sortBy(rows, e => e.last_name || e.first_name || e.name);
};

export function* requestSegmentedTime({ params }) {
    try {
        const result = yield call(segmentTime, params);
        yield put(reportActions.receiveSegmentedTime(result));
    } catch (error) {
        yield put(reportActions.requestSegmentedTimeError(error));
    }
}

export function* requestSegmentedExpenses({ params }) {
    try {
        const result = yield call(segmentExpenses, params);
        yield put(reportActions.receiveSegmentedExpenses(result));
    } catch (error) {
        yield put(reportActions.requestSegmentedExpensesError(error));
    }
}

export function* requestReport({ type, params, onSuccess, onError }) {
    try {
        const task = yield fork(
            type === 'REQUEST_SEGMENTED_TIME' ? requestSegmentedTime : requestSegmentedExpenses,
            { params }
        );

        const action = yield take([
            'REQUEST_SEGMENTED_TIME_ERROR',
            'REQUEST_SEGMENTED_EXPENSES_ERROR',
            'RECEIVE_SEGMENTED_TIME',
            'RECEIVE_SEGMENTED_EXPENSES',
            'CANCEL_REPORT_REQUESTS',
        ]);

        if (action.type === 'CANCEL_REPORT_REQUESTS') {
            yield cancel(task);
            return;
        }

        if (
            action.type === 'REQUEST_SEGMENTED_TIME_ERROR' ||
            action.type === 'REQUEST_SEGMENTED_EXPENSES_ERROR'
        ) {
            yield put(uiActions.showToast('Couldn’t load report', 'negative'));
            if (typeof onError === 'function') {
                yield call(onError, action.error);
            }
            return;
        }

        if (typeof onSuccess === 'function') {
            const denormalized = yield select(
                denormalizeSegmentData,
                action.type === 'RECEIVE_SEGMENTED_TIME'
                    ? action.segmentedTime
                    : action.segmentedExpenses,
                params.segments.filter(s => s !== 'no_segment')
            );
            yield call(onSuccess, denormalized);
        }
    } catch (error) {
        console.log('Error in report request flow:', error);
    }
}

export default function* root() {
    yield all([
        takeLatest(['REQUEST_SEGMENTED_TIME', 'REQUEST_SEGMENTED_EXPENSES'], requestReport),
    ]);
}
