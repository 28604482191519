/** Holds all date related logic */
import moment from 'moment';
import { paddingZero } from './CommonFunctions.js';
import { PATTERNS } from '../config/Patterns.js';

export const WEEKDAYS = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

/**
 * Add days days to date, also handles month wrapping
 */
const dateByAddingDaysToDate = function (originalDate, days) {
    var date = new Date(originalDate.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

/**
 * Month here is 1-indexed (January is 1, February is 2, etc). This is
 * because we're using 0 as the day so that it returns the last day
 * of the last month
 * @param {*} year
 * @param {*} month
 */
export const daysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
};

/**
 * Given a date, return an a.m. / p.m. formatted string of the time
 * @param {Date} date
 */
export const formatAMPM = date => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // hour 0 should be 12
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
};

/**
 * Given a yearAndMonth string figure the first and last day of the month
 * @param {string} year format YYYY
 * @param {string} month format MM
 * @return {object} {
 *  first: YYYY-MM-01
 *  last: YYYY-MM-(last day in month)
 * }
 */
export const firstAndLastDayOfMonth = (year, month) => {
    // Month - 1 as months are zero indexed
    let firstDay = new Date(year, parseInt(month) - 1);
    firstDay.setDate(1);
    const beginningOfMonth = dateToMySqlFormat(firstDay);
    let lastDay = daysInMonth(year, month);
    const endOfMonth = `${year}-${paddingZero(month)}-${paddingZero(lastDay)}`;
    return {
        first: beginningOfMonth,
        last: endOfMonth,
    };
};

export const firstAndLastDayOfYear = year => {
    return {
        first: `${year}-01-01`,
        last: `${year}-12-31`,
    };
};

/**
 * Given a string, test whether it is in the correct format of YYYY-MM-DD
 * @param {string} date
 */
export const isValidDate = date => {
    return PATTERNS.date.test(date);
};

export const isDateInRange = date => {
    return Math.abs(new Date(date) - new Date()) / (1000 * 60 * 60 * 24 * 365) < 5 ? true : false;
};

export const dateToMySqlFormat = date => {
    let day = date.getDate();
    let year = date.getFullYear();
    let month = parseInt(date.getMonth()) + 1;
    return `${year}-${paddingZero(month)}-${paddingZero(day)}`;
};

/**
 Week offsets are currently stored like:
    Monday: 0,
    Tuesday: -6,
    Wednesday: -5,
    Thursday: -4,
    Friday: -3,
    Saturday: -2,
    Sunday: -1,

    this will return the proper offset given how we store offsets with mon - sun being 0, 6
*/
export const offsetFromMonday = weekStartOffset => {
    const offset = parseInt(weekStartOffset, 10);
    if (offset === 0) {
        return 0;
    }
    return 7 - (Math.abs(offset) % 7);
};

/*
 * Returns the start of the week that a reference date is in,
 * respecting the weekOffset for the user.
 */

export const startOfWeek = (date, weekOffset = 0) => {
    // always a Monday, according to ISO 8601
    const referenceDate = moment(date);
    const startOfWeek = moment(referenceDate).startOf('isoWeek');
    const offset = offsetFromMonday(weekOffset);
    const startDate = moment(startOfWeek).add(offset, 'd');
    if (startDate.isAfter(referenceDate, 'd')) {
        startDate.add(-1, 'w');
    }
    return startDate.toDate();
};

/**
 * Given a date, figure start and end date of week based on weekOffset
 * @param {Date} date reference date
 * @param {string} weekOffset account User's start_week_offset
 */
export const expandDateToWeekRange = (date, weekOffset = 0) => {
    const startDate = startOfWeek(date, weekOffset);
    const endDate = moment(startDate).add(6, 'd');

    return {
        startDate,
        endDate: endDate.toDate(),
    };
};

/**
 * Given an array of entries, determine min/max date of the set
 * @param {array} entries
 * @return {object} minAndMaxDate { min: Date(), max: Date() }
 */
export const getMinAndMaxDateOfEntries = entries => {
    if (entries.length === 0) {
        return {};
    }

    let minDate = entries[0].date;
    let maxDate = entries[0].date;

    entries.forEach(entry => {
        const currentDate = entry.date;
        if (currentDate > maxDate) {
            maxDate = currentDate;
        }
        if (currentDate < minDate) {
            minDate = currentDate;
        }
    });

    let endDate = new Date(maxDate);
    endDate = dateByAddingDaysToDate(endDate, 1); // Add a day to ensure we encapsulate all timezones

    return {
        max: endDate,
        min: new Date(minDate),
    };
};

export const getMonday = date => {
    let tempDate = new Date(date);
    let day = tempDate.getDay();
    let diff = tempDate.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(tempDate.setDate(diff));
};

export const getPreviousMonday = date => {
    let tempDate = new Date(date);
    let day = tempDate.getDay();
    let diff = tempDate.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(tempDate.setDate(diff - 7));
};

export const getSunday = date => {
    let tempDate = new Date(date);
    let day = tempDate.getDay();
    let diff = tempDate.getDate() - day;
    return new Date(tempDate.setDate(diff));
};

export const getNextSunday = date => {
    let tempDate = new Date(date);
    let day = tempDate.getDay();
    let diff = tempDate.getDate() - day + 7;
    return new Date(tempDate.setDate(diff));
};

/**
 *
 * @param {Duration} duration a moment.duration() object
 * @param {*} minuteInterval interval like 1, 5, 6, 10, 15
 * @return {Duration} rounded duration
 */
export const roundDurationToMinuteInterval = (duration, minuteInterval) => {
    const interval = parseInt(minuteInterval, 10);
    const rounded = Math.ceil(duration.asMinutes() / interval) * interval;
    return moment.duration(rounded, 'minutes');
};

/**
 *
 * @param {Date} startDate DateTime to base endTime from
 * @param {*} duration elapsed time in ms
 * @param {*} minuteInterval minute interval to round to
 */
export const computeEndTime = ({ startDate, duration: durationMs, minuteInterval }) => {
    const start = moment(startDate);
    const duration = roundDurationToMinuteInterval(moment.duration(durationMs), minuteInterval);
    const end = start.add(duration);
    return end.format('HH:mm:ss');
};

export const selectPreviousMonth = () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth(), 0);
    return {
        firstDay,
        lastDay,
    };
};

export const selectThisMonth = () => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return {
        firstDay,
        lastDay,
    };
};

/**
 * Given start and end time, calculate the time worked
 * @param {moment} startTime
 * @param {moment} endTime
 */
export const calculateDurationFromStartAndEndTimes = (startTime, endTime) => {
    return moment
        .utc(moment(endTime, 'HH:mm:ss').diff(moment(startTime, 'HH:mm:ss')))
        .format('H:mm');
};
