import React from 'react';

const ColorScheme = () => (
    <div className="helpModal">
        <div className="sectionQuestion">Why is the color scheme different?</div>
        <div className="sectionAnswer">
            As part of the redesign we have created one standard color scheme for all users. If you
            previously had a custom color scheme (blue, green, or orange) it will now be
            standardized to our new color scheme.
        </div>
    </div>
);

export default ColorScheme;
