import { postRequestWithFormData, getData } from '../utils/HelperFunctions.js';

export const createReportFilter = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(data, 'v1/report_filters', paramsObject);
    return res.ReportFilter;
};


export const refreshReportFilter = async (paramsObject = {}) => {
    return await getData(paramsObject, 'v1/report_filters').then(res => res);;
};

export const deleteReportFilter = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/report_filters/' + id + '/delete_report',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res;
};

export const updateReportFilter = async (id, data, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        data,
        'v1/report_filters/' + id + '/update_report_filter',
        paramsObject
    );
    return res;
};
