import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Icon from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import '../../css/ConfirmationModal.css';

// Our tests don't like this line
try {
    Modal.setAppElement('#root');
} catch {}

export default class ConfirmationModal extends Component {
    render() {
        const {
            isOpen,
            onRequestClose,
            onConfirm,
            title,
            message,
            confirmActionLabel,
            confirmActionIsDestructive,
        } = this.props;

        return (
            <Modal
                closeTimeoutMS={400}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel="Confirmation Modal"
                shouldCloseOnOverlayClick={true}
                className="modalContainer"
            >
                <div>
                    <div className="modalHeader modalHeaderWithoutTitle">
                        <div className="modalOptions">
                            <Icon
                                className="modalIcon"
                                size={24}
                                icon={closeIcon}
                                onClick={onRequestClose}
                            />
                        </div>
                    </div>
                    <div className="modalContent">
                        <div className="confirmationTitle mb-3">{title}</div>
                        {message && <div className="confirmationMessage">{message}</div>}
                        <div className="modalActions">
                            <button className="secondaryButton" onClick={onRequestClose}>
                                Cancel
                            </button>
                            <button
                                className={
                                    confirmActionIsDestructive
                                        ? 'destructiveButton'
                                        : 'primaryButton'
                                }
                                onClick={onConfirm}
                            >
                                {confirmActionLabel}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

ConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    confirmActionLabel: PropTypes.string,
    confirmActionIsDestructive: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
    confirmActionLabel: 'Delete',
    confirmActionIsDestructive: true,
};
