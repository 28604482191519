import moment from 'moment';

export const CONSTANTS = {
    bookDemo: 'https://calendly.com/minute7/30min',
};

export const NO_EMPLOYEE_AND_VENDOR_ASSOCIATION_STRING = `Your Minute7 user account does not currently
    have access to enter time or expenses. Please contact your account manager and ask them
    to go to the "Account" tab in Minute7 and edit your user account settings by
    choosing a QuickBooks Vendor and/or Employee to associate with your user account.`;

export const NO_EMPLOYEE_ASSOCIATION_STRING = `Your Minute7 user account does not currently
    have access to enter time. Please contact your account manager and ask them
    to go to the "Account" tab in Minute7 and edit your user account settings by
    choosing a QuickBooks Employee or Vendor to associate with your user account.`;

export const NO_VENDOR_ASSOCIATION_STRING = `Your Minute7 user account does not currently
    have access to enter expenses. Please contact your account manager and ask them
    to go to the 'Account' tab in Minute7 and edit your user account settings by
    choosing a QuickBooks Vendor to associate with your user account.`;

export const MIDNIGHT = moment()
    .hour(0)
    .minute(0)
    .format('HH:mm:ss');

// Preferred Distance Unists
export const MILES = 'miles';
export const KILOMETERS = 'kilometers';

// Reimbursement Rate types
export const COMPANY = 'company';
export const INDIVIDUAL = 'individual';
