import _ from 'lodash';
import React, { Component } from 'react';
import {
    employeeWithId,
    isNameValid,
    isValidEmail,
    isValidPhone,
    milesToKilometers,
    nameErrorMessage,
    emailErrorMessage,
} from '../../utils/CommonFunctions.js';
import { Input } from 'reactstrap';
import Icon from 'react-icons-kit';
import { ic_error_outline as errorIcon } from 'react-icons-kit/md/ic_error_outline';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import ReactTooltip from 'react-tooltip';
import { informationCircled as info } from 'react-icons-kit/ionicons/informationCircled';
import FloatingSaveButton from '../components/FloatingButton.js';
import Select from '../components/Select.js';
import {
    syncReminder,
    weekday_offsets,
    weekday_offsets_to_save,
    remindMe,
    minimumHours,
    minimumHoursToSave,
    groupingOptionsForSelect,
} from '../../config/Fields.js';
import { INDIVIDUAL, MILES, KILOMETERS } from '../../config/Constants';
import {
    showToast,
    selectEmployee,
    selectVendor,
    selectPermissionsGroup,
} from '../../actions/Actions.js';
import { updateUser } from '../../actions/AccountUserActions';
import { offsetFromMonday } from '../../utils/DateUtils.js';
import PayrollSettings from './components/PayrollSettings.js';
import MileageCalculatorSetting from '../components/MileageCalculatorSetting';
import * as accountSelectors from '../../selectors/AccountSelectors';
import '../../css/AccountUserSettings.css';

class AccountUserSettings extends Component {
    constructor(props) {
        super(props);

        if (this.props.user) {
            this.state = this.stateForUser(this.props.user);
        }
    }

    componentWillReceiveProps(props) {
        if (props.user !== this.props.user && props.user && !props.isSaving) {
            this.setState(this.stateForUser(props.user));
        }

        if (props.isSaving !== this.props.isSaving && !props.isSaving) {
            if (props.user.id === props.currentUser.id) {
                this.resetState();
            } else {
                this.props.history.goBack();
            }
        }
    }

    resetState = () => {
        this.setState({
            hasBeenEdited: false,
            newPassword: '',
            confirmPassword: '',
        });
    };

    stateForUser = user => {
        const { defaultReimbursementRate, no_integration } = this.props;
        const employee_id = user.type === 'Vendor' ? user.employee_id + '_v' : user.employee_id;
        const employee = employeeWithId(this.props.canViewEmployees, employee_id);
        const preferred_distance_units = _.get(user, 'preferred_distance_units', MILES);
        let reimbursement_rate = _.get(user, 'reimbursement_rate');
        if (_.isNil(reimbursement_rate)) reimbursement_rate = defaultReimbursementRate;
        if (preferred_distance_units === KILOMETERS) {
            reimbursement_rate = parseFloat(reimbursement_rate) * milesToKilometers(1);
        }

        return {
            employee_id,
            expense_vendor_id: _.get(user, 'expense_vendor_id', null),
            email: _.get(user, 'email', ''),
            first_name: _.get(user, 'first_name', ''),
            middle_name: _.get(user, 'middle_name', ''),
            last_name: _.get(user, 'last_name', ''),
            phone: _.get(user, 'phone', ''),
            week_start_offset: parseInt(_.get(user, 'week_start_offset', 0), 10),
            timesheet_grouping: _.get(user, 'timesheet_grouping', 'week'),
            preferred_distance_units,
            reimbursement_rate,
            time_entry_reminder_frequency: _.get(user, 'time_entry_reminder_frequency', null),
            time_entry_reminder_minimum_hours: _.get(
                user,
                'time_entry_reminder_minimum_hours',
                null
            ),
            export_reminder_interval: _.get(user, 'export_reminder_interval', null),
            type: _.get(user, 'type', null),
            newPassword: '',
            confirmPassword: '',
            default_payroll_item_id: employee ? employee.default_payroll_item_id : null,
            hasBeenEdited: false,
            no_integration: no_integration,
            disable_assosiated_fields:
                (employee_id !== '0' || _.get(user, 'expense_vendor_id', '0') !== '0') &&
                no_integration
                    ? true
                    : false,
        };
    };

    checkRequiredFields = () => {
        const { email, first_name, last_name, phone } = this.state;

        if (
            !isValidEmail(email) ||
            !isNameValid(first_name) ||
            !isNameValid(last_name) ||
            !isValidPhone(phone)
        ) {
            this.props.showToast('Make sure all fields are filled out correctly', 'warning');
            return false;
        }
        return true;
    };

    updateAccountUserSettings = () => {
        const { email, first_name, last_name, phone } = this.state;

        if (
            this.doPasswordsMatch() &&
            isValidEmail(email) &&
            isNameValid(first_name) &&
            isNameValid(last_name) &&
            isValidPhone(phone)
        ) {
            this.props.updateUser({
                ...this.props.user,
                ...this.state,
                password: this.state.newPassword,
            });
        } else {
            this.props.showToast('Make sure all fields are filled out correctly', 'warning');
        }
    };

    doPasswordsMatch = () => {
        return this.state.newPassword === this.state.confirmPassword;
    };

    handlePayrollItemIdChanged = id => {
        this.setState({
            default_payroll_item_id: id,
            hasBeenEdited: true,
        });
    };

    handlePreferredDistanceUnitChange = (preferredDistanceUnit, reimbursementRate) => {
        this.setState({
            preferred_distance_units: preferredDistanceUnit,
            reimbursement_rate: reimbursementRate,
            hasBeenEdited: true,
        });
    };

    handleReimbursementRateChange = reimbursementRate => {
        this.setState({
            reimbursement_rate: reimbursementRate,
            hasBeenEdited: true,
        });
    };

    handleCopyToken = () => {
        const { showToast, token } = this.props;
        navigator.clipboard.writeText(token);
        showToast('Token Copied!', 'positive');
    };

    handleEditEmployeePressed = employee_id => {
        this.props.selectEmployee(employee_id);
        this.props.history.push('/edit_employee');
       
    };

    render() {
        const { user } = this.props;

        if (!user) {
            return (
                <div className="infoPage">
                    <Icon size={72} icon={errorIcon} className="errorIcon" />

                    <h1>We can’t find the user you're looking for.</h1>
                    <p>You can find a list of all users on the Settings tab.</p>
                </div>
            );
        }

        const {
            hasBeenEdited,
            employee_id,
            expense_vendor_id,
            email,
            middle_name,
            week_start_offset,
            time_entry_reminder_frequency,
            time_entry_reminder_minimum_hours,
            phone,
            type,
            export_reminder_interval,
            default_payroll_item_id,
            first_name,
            last_name,
            timesheet_grouping,
            preferred_distance_units,
            reimbursement_rate,
            no_integration,
            disable_assosiated_fields,
        } = this.state;

        const {
            payrollItems,
            canViewEmployees,
            canViewActiveEmployees,
            canViewActiveVendors,
            canAlterQBSettings,
            canManageUsers,
            canManageApi,
            qbType,
            shouldShowPayroll,
            history,
            isMileageCalculatorVisible,
            canManageAccount,
            currentUser,
            isManager,
        } = this.props;

        const fullName = [first_name, last_name].join(' ');

        const URL = process.env.REACT_APP_URL;

        return (
            <div className="applicationBody">
                <DocumentTitle title={`Account Settings for ${fullName}`} />
                <div className="bodyHeader">
                    <h2>{`${fullName}'s Settings`}</h2>
                    {'fromAccountSettings' in this.props && (
                        <button
                            className="tertiaryButton"
                            onClick={() => history.push('/account_settings')}
                        >
                            Back to Account Settings
                        </button>
                    )}
                </div>
                <FloatingSaveButton
                    buttonText={'Save'}
                    hasBeenEdited={hasBeenEdited}
                    onSave={this.updateAccountUserSettings}
                />
                {canAlterQBSettings && !no_integration && (
                    <div>
                        <h3 className="cardHeading">QuickBooks Settings</h3>
                        <div className="cardContainer ausSectionContainer">
                            <div className="settingsLabelAndInputContainer">
                                <div className="settingsInputLabel">
                                    QuickBooks Employee or Vendor for Time Tracking
                                </div>
                                <div className="settingsInput">
                                    <Select
                                        withSearch
                                        withClearOption
                                        clearOptionLabel="No Employee Selected"
                                        options={canViewActiveEmployees}
                                        labelWhenNothingSelected="No Employee Selected"
                                        value={employee_id}
                                        onChange={id =>
                                            this.setState({
                                                employee_id: id,
                                                hasBeenEdited: true,
                                            })
                                        }
                                    />
                                </div>
                                <div className="settingsTip">
                                    Simplifies time tracking by automatically selecting this
                                    employee for time entries
                                </div>
                            </div>
                            <div className="settingsLabelAndInputContainer">
                                <div className="settingsInputLabel">
                                    QuickBooks Vendor for Expense Tracking
                                </div>
                                <div className="settingsInput">
                                    <Select
                                        withSearch
                                        withClearOption
                                        clearOptionLabel="No Vendor Selected"
                                        options={canViewActiveVendors}
                                        labelWhenNothingSelected="No Vendor Selected"
                                        value={expense_vendor_id}
                                        onChange={id =>
                                            this.setState({
                                                expense_vendor_id: id,
                                                hasBeenEdited: true,
                                            })
                                        }
                                    />
                                </div>
                                <div className="settingsTip">
                                    Required for mobile expense tracking. For web-based expense
                                    tracking, required if permissions for this account limit it to
                                    editing only its own expenses
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    <h3 className="cardHeading">Change Password</h3>
                    <div className="cardContainer ausSectionContainer">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">New Password</div>
                            <div className="settingsInput">
                                <Input
                                    className={this.doPasswordsMatch() ? '' : 'ausInvalidInput'}
                                    type="password"
                                    autoComplete="new-password"
                                    onChange={newPassword =>
                                        this.setState({
                                            newPassword: newPassword.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {!this.doPasswordsMatch() && (
                                <div className="ausInvalidInputTip">Passwords Do Not Match</div>
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Confirm Password</div>
                            <div className="settingsInput">
                                <Input
                                    className={this.doPasswordsMatch() ? '' : 'ausInvalidInput'}
                                    type="password"
                                    onChange={confirmPassword =>
                                        this.setState({
                                            confirmPassword: confirmPassword.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 className="cardHeading" style={{ marginRight: '10px', width: '7%' }}>
                            Contact Info
                        </h3>
                        {disable_assosiated_fields && (
                            <div>
                                <ReactTooltip
                                    className="tooltip-override"
                                    data-for="first_name"
                                    type="info"
                                    multiline={true}
                                    data-html={true}
                                />
                                <Icon
                                    className="blueIcon"
                                    size={16}
                                    data-tip="If you wish to update the first name, last name, or email of this user, please update the corresponding employee details in the resource section"
                                    icon={info}
                                    id="first_name"
                                />
                            </div>
                        )}
                    </div>
                    <div className="cardContainer ausSectionContainer">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                Email
                                <div className="settingsRequiredLabel">Required</div>
                            </div>
                            <div className="settingsInput">
                                <Input
                                    disabled={disable_assosiated_fields && !isManager}
                                    className={isValidEmail(email) ? '' : 'ausInvalidInput'}
                                    value={email}
                                    onChange={newEmail =>
                                        this.setState({
                                            email: newEmail.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {!isValidEmail(email) && (
                                <div className="ausInvalidInputTip">
                                    {emailErrorMessage(email)} Email must be between to 4 to 50 e.g
                                    (example@minute7.com)
                                </div>
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                First Name
                                <div className="settingsRequiredLabel">Required</div>
                            </div>
                            <div className="settingsInput">
                                <Input
                                    disabled={disable_assosiated_fields && !isManager}
                                    className={isNameValid(first_name) ? '' : 'ausInvalidInput'}
                                    value={first_name}
                                    onChange={newFirstName =>
                                        this.setState({
                                            first_name: newFirstName.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {!isNameValid(first_name) && (
                                <div className="ausInvalidInputTip">
                                    {nameErrorMessage(first_name)}
                                    You can only use alphabets and (- . ') between 1 to 50.
                                </div>
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Middle Name</div>
                            <div className="settingsInput">
                                <Input
                                    disabled={disable_assosiated_fields && !isManager}
                                    className={
                                        middle_name !== '' &&
                                        middle_name !== null &&
                                        !isNameValid(middle_name)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={middle_name}
                                    onChange={newMiddleName =>
                                        this.setState({
                                            middle_name: newMiddleName.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {middle_name !== '' &&
                            middle_name !== null &&
                            !isNameValid(middle_name) ? (
                                <lable className="error-message">
                                    {nameErrorMessage(middle_name)}
                                    You can only use alphabets and (- . ') between 1 to 50.
                                </lable>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                Last Name
                                <div className="settingsRequiredLabel">Required</div>
                            </div>
                            <div className="settingsInput">
                                <Input
                                    disabled={disable_assosiated_fields  && !isManager}
                                    className={isNameValid(last_name) ? '' : 'ausInvalidInput'}
                                    value={last_name}
                                    onChange={newLastName =>
                                        this.setState({
                                            last_name: newLastName.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {!isNameValid(last_name) && (
                                <div className="ausInvalidInputTip">
                                    {nameErrorMessage(last_name)}
                                    You can only use alphabets and (- . ') between 1 to 50.
                                </div>
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                Phone
                                <div className="settingsRequiredLabel">Required</div>
                            </div>
                            <div className="settingsInput">
                                <Input
                                    className={isValidPhone(phone) ? '' : 'ausInvalidInput'}
                                    value={phone}
                                    onChange={newPhone =>
                                        this.setState({
                                            phone: newPhone.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {!isValidPhone(phone) && (
                                <div className="ausInvalidInputTip">
                                    Enter a valid phone number (e.g. 012-345-6789)
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {(qbType === 'Desktop' || qbType === 'None') && employee_id !== '0' && (
                    <div>
                        <h3 className="cardHeading">Payroll Settings</h3>
                        <div className="cardContainer ausSectionContainer">
                            <PayrollSettings
                                history={history}
                                shouldShowPayroll={shouldShowPayroll}
                                canEditPayroll={user.edit_payroll === 'Yes'}
                                canManageAccount={user.manage_account === 'Yes'}
                                type={type}
                                selectedEmployeeId={employee_id}
                                employees={canViewEmployees}
                                payrollItems={payrollItems}
                                payrollItemId={default_payroll_item_id}
                                qbType={qbType}
                                payrollItemIdChanged={this.handlePayrollItemIdChanged}
                                no_integration={no_integration}
                                onEmployeeSetting={this.handleEditEmployeePressed}
                            />
                        </div>
                    </div>
                )}

                <div>
                    <h3 className="cardHeading">Display Preferences</h3>
                    <div className="cardContainer ausSectionContainer">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Start Week On</div>
                            <div className="settingsInput">
                                <Select
                                    value={
                                        Object.keys(weekday_offsets)[
                                            offsetFromMonday(week_start_offset)
                                        ]
                                    }
                                    options={Object.keys(weekday_offsets)}
                                    labelForOption={option => option}
                                    valueForOption={option => option}
                                    onChange={value =>
                                        this.setState({
                                            week_start_offset: weekday_offsets_to_save[value],
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            <div className="settingsTip">For Timesheets and Expense views</div>
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Default Time Entry Grouping</div>
                            <div className="settingsInput">
                                <Select
                                    value={timesheet_grouping}
                                    options={groupingOptionsForSelect}
                                    onChange={value =>
                                        this.setState({
                                            timesheet_grouping: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            <div className="settingsTip">
                                You can toggle between grouping options without changing the default
                                by using the “More” menu above the time entries table.
                            </div>
                        </div>
                    </div>
                </div>

                {canManageUsers && isMileageCalculatorVisible && (
                    <MileageCalculatorSetting
                        distanceUnit={preferred_distance_units}
                        reimbursementRate={reimbursement_rate}
                        onDistanceUnitChange={this.handlePreferredDistanceUnitChange}
                        onReimbursementRateChange={this.handleReimbursementRateChange}
                    />
                )}

                <div>
                    <h3 className="cardHeading">Reminders</h3>
                    <p>
                        Note: reminders apply to all weekdays. If your company has specific
                        holidays, you may simply disregard the email reminder.
                    </p>
                    <div className="cardContainer ausSectionContainer">
                        {employee_id !== '0' && (
                            <div className="ausReminderContainer">
                                <div className="ausReminderText">Remind Me</div>
                                <div className="ausReminderDropdownContainer">
                                    <Select
                                        value={time_entry_reminder_frequency}
                                        options={remindMe}
                                        labelForOption={option => option}
                                        valueForOption={option => option}
                                        onChange={value =>
                                            this.setState({
                                                time_entry_reminder_frequency: value,
                                                hasBeenEdited: true,
                                            })
                                        }
                                    />
                                </div>
                                <div className="ausReminderText">if I have not entered</div>
                                <div className="ausReminderDropdownContainer">
                                    <Select
                                        value={
                                            Object.keys(minimumHoursToSave)[
                                                time_entry_reminder_minimum_hours
                                            ]
                                        }
                                        labelForOption={option => option}
                                        valueForOption={option => option}
                                        options={minimumHours}
                                        onChange={value =>
                                            this.setState({
                                                time_entry_reminder_minimum_hours:
                                                    minimumHoursToSave[value],
                                                hasBeenEdited: true,
                                            })
                                        }
                                    />
                                </div>
                                <div className="ausReminderText">on any given weekday</div>
                            </div>
                        )}
                        {!no_integration && (
                        <div className="ausReminderContainer ausExportReminder">
                            <div className="ausReminderText">Remind me to sync with QuickBooks</div>
                            <div className="ausReminderDropdownContainer">
                                <Select
                                    value={export_reminder_interval}
                                    options={syncReminder}
                                    labelForOption={option => option}
                                    valueForOption={option => option}
                                    onChange={value =>
                                        this.setState({
                                            export_reminder_interval: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            <div className="ausReminderText">after the last sync</div>
                        </div>
                        )}
                    </div>
                </div>
                {canManageApi && currentUser.id === user.id && (
                    <div>
                        <h3 className="cardHeading">Public API</h3>
                        {!canManageAccount && (
                            <p>Note: Admin has allowed you to access API to pull data.</p>
                        )}
                        <div className="cardContainer ausSectionContainer">
                            <div className="settingsLabelAndInputContainer">
                                <div className="settingsInputLabel">
                                    Copy this token to authenticate API
                                </div>
                                <div className="settingsInput">
                                    <button
                                        className="copyTokenButton primaryButton"
                                        onClick={this.handleCopyToken}
                                    >
                                        Copy Token
                                    </button>
                                </div>
                                <div className="settingsTip">
                                    Find API documentation{' '}
                                    <a href={`${URL}/about/api-guide`}>here</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const canAlterQBSettings =
        state.accountUserStore.manage_users === 'Yes' ||
        state.accountUserStore.manage_account === 'Yes';

    const canManageUsers =
        state.accountUserStore.manage_users === 'Yes' ||
        state.accountUserStore.manage_account === 'Yes';

    const canManageApi =
        state.accountUserStore.manage_api === 'Yes' ||
        state.accountUserStore.manage_account === 'Yes';

    const canManageAccount = state.accountUserStore.manage_account === 'Yes';

    const users = state.accountStore.accountUsers;
    const { match } = ownProps;

    let user;

    if (match && match.params && match.params.id) {
        user = users.find(u => u.id === match.params.id);
    } else {
        user = state.accountUserStore;
    }

    const canViewActiveEmployees = accountSelectors.selectCanViewActiveEmployees(state);
    const canViewActiveVendors = accountSelectors.selectCanViewActiveVendors(state);
    const isMileageCalculatorVisible = state.accountStore.reimbursement_rate_type === INDIVIDUAL;
    const no_integration = state.accountStore.qb_type === 'None' ? true : false;
    const isManager = user.manage_account === 'Yes' && no_integration === false;
    return {
        token: state.appStore.session_id,
        currentUser: state.accountUserStore,
        canViewEmployees: state.employeeStore.canViewEmployees,
        canViewActiveEmployees,
        canViewActiveVendors,
        canAlterQBSettings,
        canManageUsers,
        canManageApi,
        canManageAccount,
        user,
        payrollItems: state.businessResourcesStore.payrollItems,
        defaultReimbursementRate: state.accountStore.default_reimbursement_rate,
        shouldShowPayroll: state.accountStore.show_payroll === 'Yes',
        qbType: state.accountStore.qb_type,
        isSaving: state.accountUserStore.isSaving,
        isMileageCalculatorVisible,
        no_integration,
        isManager,
    };
}

const mapDispatchToProps = {
    showToast,
    selectEmployee,
    selectPermissionsGroup,
    selectVendor,
    updateUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountUserSettings);
