import React, { Component } from 'react';

export default class Privacy extends Component {
  render() {
    return(
      <div className="termsContainer">
        <div className="terms text-center">
          <h2>Privacy Policy</h2>
          <p>
            Minute7 is committed to protect the privacy of our customers. This Privacy Policy outlines what information
            we collect and how we may use it on www.Minute7.com.
          </p>
          <p>
            Maintaining the privacy of your information is of paramount importance to us as it helps foster confidence,
            goodwill and stronger relationships with you, our customers. If, at any time, you have questions or concerns
            about our privacy practices, please feel free contact us at <a href="mailto:support@minute7.com">support@minute7.com</a>.
          </p>

          <p>
            When you register with Minute7, we need to ask for information such as your name, company name, email address.
            Minute7 uses the collected information for the following general purposes: to create your account, provide you
            with email communications in regards to our service, to send you newsletters, service improvements, and research.
            If you wish to upgrade your account this may be done within the application. This privacy policy does not cover
            any personal or sensitive information collected within the application.
          </p>

          <div className="termsBulletsContainer">
            <h3>Newsletters</h3>
            <p>
              As part of the service that we offer you will receive periodic newsletters. We will use your name and email address
              to send the newsletter to you. If you no longer wish to receive our newsletters you may unsubscribe by following
              the unsubscribe link located within each email communication.
            </p>

            <p>
              Minute7 will not make any of the collected information available to anyone. We will never sell, rent, exchange
              or share your personal information with third parties other than as disclosed within this privacy policy.
              Minute7 will need to share the collected information in order to respond to investigation, court orders, legal
              process, or to investigate, prevent or take action regarding illegal activities, suspected fraud, or situations
              involving potential threats to the physical safety of any person, violations of Minute7’s terms of use, or as
              otherwise required by law.
              Minute7 reserves the right to disclose your personally identifiable information as required by law and when
              we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court
              order, or legal process served on our Web site.
              Minute7 will send a welcoming email to verify your new account. We will only email account holders for billing
              purposes. We will occasionally send out Minute7 service related announcements.
              Minute7 uses a technology called “cookies” to store session information. A cookie is a small amount of data,
              which often includes an anonymous unique identifier, that is sent to your browser from a web site’s computers
              and stored on your computer’s hard drive. Minute7 may set and access Minute7 cookies on your computer, cookies
              are required to use the Minute7 service. We do not link the information we store in cookies to any personally
              identifiable information you submit while on our site.
              We use both session ID cookies and persistent cookies. We use session cookies to make it easier for you to
              navigate our site. A session ID cookie expires when you close your browser. A persistent cookie remains on your
              hard drive for an extended period of time. You can remove persistent cookies by following directions provided in your
              Internet browser’s “help” file.
            </p>

            <h3>Third Party Cookies</h3>
            <p>
              The use of cookies by third parties is not covered by our privacy statement. We do not have access or control over
              these cookies. These third parties use session ID cookies to make it easier for you to navigate our site.
            </p>

            <p>
              Minute7 uses third party vendors and hosting partners to provide the necessary hardware, software, networking,
              storage, and related technology required to run Minute7. Although Minute7 owns the code, databases, and all rights to the
              Minute7 application, you retain all rights to your data.
              Minute7 may periodically update this Privacy Policy. We will notify you about significant changes in the way we treat
              personal information by sending a notice to the primary email address specified in your Minute7 account or by
              placing a prominent notice on our site.
              If you have any question about this Privacy Policy, please contact us at support@minute7.com.
            </p>

            <h3>Updating or Correcting Personally Identifiable Information</h3>
            <p>
              If your personally identifiable information changes, or if you no longer desire our service,
              you may correct or update it by emailing our Customer Support at support@minute7.com or by contacting
              us by telephone or postal mail at the contact information listed below.
            </p>

            <h3>Customer Testimonials</h3>
            <p>
              We post customer testimonials on our web site which may contain personally identifiable information.
              We do obtain the customer’s consent via email prior to posting the testimonial to post their name along
              with their testimonial.
            </p>

            <h3>Blog</h3>
            <p>
              If you use the blog on this Web site, you should be aware that any personally identifiable information
              you submit there can be read, collected, or used by other users of these blogs, and could be used to
              send you unsolicited messages. We are not responsible for the personally identifiable information you
              choose to submit in these blogs.
            </p>

            <h3>Security</h3>
            <p>
              We follow generally accepted industry standards to protect the personally identifiable information
              submitted to us, both during transmission and once we receive it. No method of transmission over the Internet,
              or method of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially
              acceptable means to protect your personal information, we cannot guarantee its absolute security.
            </p>

            <p>If you have any questions about security on our Web site, you can email us at support@minute7.com.</p>

            <h3>Changes in this Privacy Statement</h3>
            <p>
              If we decide to change our privacy policy, we will post those changes to this privacy statement, the home page,
              and other places we deem appropriate so that you are aware of what information we collect, how we use it, and
              under what circumstances, if any, we disclose it.
            </p>
          </div>

          <p>
            We reserve the right to modify this privacy statement at any time, so please review it frequently. If we make material
            changes to this policy, we will notify you here, by email, or by means of a notice on our home page.
          </p>

          <p>
            Minute7 may be contacted at the following address:
            Minute7
            5149 Catalon Ave
            Woodland Hills, CA 91364
          </p>

          <p>
            By telephone:
            (858) 736-9366
            By Email: <a href="mailto:support@minute7.com">support@minute7.com</a>
          </p>
        </div>
      </div>
    )
  }
}
