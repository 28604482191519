import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';
import GoogleAnalytics from '../utils/GoogleAnalytics.js';

const unwrapEntry = ({
    TimeEntry,
    Customer,
    InventoryItem,
    PayrollItem,
    QbClass,
    Employee,
    Vendor,
}) => ({
    ...TimeEntry,
    Customer,
    InventoryItem,
    PayrollItem,
    QbClass,
    Employee,
    Vendor,
});

export const getTimesheets = (paramsObject = {}) => {
    return getData(paramsObject, 'timesheets/view').then(({ TimeEntries, meta }) => {
        const entries = TimeEntries.map(unwrapEntry);
        return {
            entries,
            meta,
        };
    });
};

export const addTimeEntry = (data, paramsObject = {}) => {
    GoogleAnalytics.timeEntry('Add');
    return postRequestWithFormData(data, 'timesheets/web_add', paramsObject).then(unwrapEntry);
};

export const editTimeEntry = (data, paramsObject = {}) => {
    GoogleAnalytics.timeEntry('Edit');
    return postRequestWithFormData(data, 'timesheets/edit', paramsObject).then(unwrapEntry);
};

export const deleteTimeEntry = (paramsObject = {}) => {
    GoogleAnalytics.timeEntry('Delete');
    return postRequestWithFormData({}, 'timesheets/delete', paramsObject, {'Content-Type': 'multipart/form-data'});
};

export const setApproved = (data, paramsObject = {}) => {
    let action = data['approved_status'] === 'Yes' ? 'Approved' : 'Disapproved';
    GoogleAnalytics.timeEntry(action);
    return postRequestWithFormData(data, 'timesheets/set_approved', paramsObject).then(
        res => res.TimeEntry
    );
};

export const setLocked = (data, paramsObject = {}) => {
    let action = 'Locked';
    GoogleAnalytics.timeEntry(action);
    return postRequestWithFormData(data, 'timesheets/set_locked', paramsObject).then(
        res => res.TimeEntry
    );
};

export const setBillable = (data, paramsObject = {}) => {
    let action = data['billable_status'] === 'Yes' ? 'Marked Billable' : 'Marked Unbillable';
    GoogleAnalytics.timeEntry(action);
    return postRequestWithFormData(data, 'timesheets/set_billable', paramsObject).then(
        res => res.TimeEntry
    );
};

export const setTaxable = (data, paramsObject = {}) => {
    let action = data['taxable_status'] === 'Yes' ? 'Marked Taxable' : 'Marked Untaxable';
    GoogleAnalytics.timeEntry(action);
    return postRequestWithFormData(data, 'timesheets/mark_taxable', paramsObject).then(
        res => res.TimeEntry
    );
};
