import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Icon from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import NewTimeEntryContainer from './NewTimeEntryContainer';

// Our tests don't like this line
try {
    Modal.setAppElement('#root');
} catch {}

const NewTimeEntryModal = props => {
    const { isOpen, onRequestClose } = props;

    return (
        <Modal
            closeTimeoutMS={400}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Add Timesheet"
            className="modalContainer tsTimeEntryModal"
        >
            <div className="modalHeader">
                <h2>Add Timesheet</h2>
                <div className="modalOptions">
                    <Icon
                        className="modalIcon"
                        size={24}
                        icon={closeIcon}
                        onClick={onRequestClose}
                    />
                </div>
            </div>
            <div className="modalContent">
                <NewTimeEntryContainer
                    hideEntryTypeToggle={true}
                    onCancelPressed={onRequestClose}
                    {...props}
                />
            </div>
        </Modal>
    );
};

NewTimeEntryModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    ...NewTimeEntryContainer.propTypes,
};

export default NewTimeEntryModal;
