export const INITIAL_STATE = {
    email: null,
    password: null,

    isLoggingIn: false,
    session_id: null,
    session_name: null,

    activityLogStore: [],
    expenseEntriesStore: [],

    selectedPermissionsGroupId: null,
    selectedAccountUserId: null,
    selectedEmployeeId: null,
    selectedVendorId: null,
    selectedCustomerId: null,
    selectedInventoryItemId: null,
    selectedAccountId: null,
    globalAlertMessage: '',
    globalAlertType: 'positive',
    isGlobalAlertVisible: false,
};

export const appReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOGIN': {
            return {
                ...state,
                isLoggingIn: true,
            };
        }
        case 'LOGIN_SUCCESS':
            const loginInfo = action.payload.logininfo;
            return {
                ...state,
                email: action.payload.email,
                password: null,
                session_id: loginInfo.session.session_id,
                session_name: loginInfo.session.session_name,
                isGlobalAlertVisible: false,
                isLoggingIn: false,
            };
        case 'LOGIN_ERROR': {
            return {
                ...state,
                isLoggingIn: false,
            };
        }
        case 'LOGIN_FROM_SESSION': {
            return {
                ...state,
                isLoggingIn: true,
                email: action.email,
                session_id: action.sessionId,
                session_name: action.sessionName,
            };
        }
        case 'LOGIN_FROM_SESSION_SUCCESS': {
            return {
                ...state,
                isLoggingIn: false,
            };
        }
        case 'LOGIN_FROM_SESSION_ERROR': {
            return {
                ...state,
                isLoggingIn: false,
            };
        }
        case 'SET_SESSION':
            return {
                ...state,
                ...action.payload,
            };
        case 'LOGOUT':
            return {
                ...INITIAL_STATE,
            };
        case 'SHOW_ALERT':
            return {
                ...state,
                isGlobalAlertVisible: true,
                globalAlertMessage: action.payload.globalAlertMessage,
                globalAlertType: action.payload.globalAlertType,
            };
        case 'HIDE_ALERT':
            return {
                ...state,
                isGlobalAlertVisible: false,
                globalAlertMessage: '',
                globalAlertType: 'positive',
            };
        case 'SET_EXPENSE_ENTRIES':
            return {
                ...state,
                expenseEntriesStore: action.payload,
            };
        case 'SET_ACTIVITY_LOG':
            return {
                ...state,
                activityLogStore: action.payload,
            };
        case 'SELECT_ACCOUNT_USER':
            return {
                ...state,
                selectedAccountUserId: action.payload,
            };
        case 'SELECT_EMPLOYEE':
            return {
                ...state,
                selectedEmployeeId: action.payload,
            };
        case 'SELECT_CUSTOMER':
            return {
                ...state,
                selectedCustomerId: action.payload,
            };
        case 'SELECT_INVENTORY_ITEM':
            return {
                ...state,
                selectedInventoryItemId: action.payload,
            };
        case 'SELECT_PAYROLL_ITEM':
            return {
                ...state,
                selectedPayrollItemId: action.payload,
            };
        case 'SELECT_ACCOUNT':
            return {
                ...state,
                selectedAccountId: action.payload,
            };
        case 'SELECT_VENDOR':
            return {
                ...state,
                selectedVendorId: action.payload,
            };
        case 'SELECT_PERMISSIONS_GROUP':
            return {
                ...state,
                selectedPermissionsGroupId: action.payload,
            };
        default:
            return state;
    }
};

export default appReducer;
