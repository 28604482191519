import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import {
    getEntityWithId,
    figureUnknownEntities,
    groupByWeek,
    searchEntries,
    sumWeeklyEntryDurations,
} from '../utils/CommonFunctions';
import {
    groupByWeekHash,
    sortByEmployeeAndDate,
    sortByEmployeeAndCreatedDate,
} from '../utils/TimeEntryUtils';
import { getMinAndMaxDateOfEntries, expandDateToWeekRange } from '../utils/DateUtils';
import {
    canUserEditTimeEntry,
    canUserApproveTimeEntry,
    canUserLockTimeEntry,
} from '../utils/PermissionUtils';
import { TIMESHEET_FILTERS } from '../config/Fields.js';
import {
    selectWeekOffset,
    selectQbType,
    selectAccount,
    selectAccountUsers,
    selectCurrentUser,
    selectPreventEditForSyncedEntries,
    prevent_edit_for_locked_entries,
    selectCanViewEmployeesById,
} from './AccountSelectors';

export const selectBusinessResources = ({ businessResourcesStore }) => businessResourcesStore;
export const selectAllEntries = ({ timesheetsStore }) => timesheetsStore.entries;
export const selectSearchKeyword = ({ timesheetsStore }) => timesheetsStore.searchText;
export const selectStartDate = ({ timesheetsStore }) => timesheetsStore.startDate;
export const selectEndDate = ({ timesheetsStore }) => timesheetsStore.endDate;

export const filterByKeyword = (entries, keyword) => {
    return searchEntries(keyword, entries);
};

export const getSortedWeekIds = entriesByWeek => {
    const timestamps = Object.keys(entriesByWeek);
    timestamps.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
    return timestamps;
};

export const calculateWeeklyTotals = entriesByWeek => {
    return _.mapValues(entriesByWeek, entries => sumWeeklyEntryDurations(entries));
};

export const calculateWeeklyGrandTotal = weeklyTotals => {
    const grandTotal = {
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0,
        Sun: 0,
        sum: 0,
    };

    _.forEach(weeklyTotals, totals => {
        _.forOwn(totals, (value, key) => {
            grandTotal[key] += value;
        });
    });

    return grandTotal;
};

export const selectAllDenormalizedEntries = createSelector(
    [
        selectAllEntries,
        selectBusinessResources,
        selectCanViewEmployeesById,
        selectQbType,
        selectAccountUsers,
        selectCurrentUser,
        selectPreventEditForSyncedEntries,
        prevent_edit_for_locked_entries,
    ],
    (
        entries,
        resources,
        canViewEmployeesById,
        qbType,
        accountUsers,
        currentUser,
        preventEditForSyncedEntries,
        prevent_edit_for_locked_entries
    ) => {
        return entries.map(entry => {
            if (_.isNil(_.get(entry, 'Customer.id'))) {
                entry.Customer = resources.customersById[entry.customer_id];
            }

            if (_.isNil(_.get(entry, 'InventoryItem.id'))) {
                entry.InventoryItem = resources.inventoryItemsById[entry.inventory_item_id];
            }

            if (_.isNil(_.get(entry, 'QbClass.id'))) {
                entry.QbClass = resources.qbClassesById[entry.qb_class_id];
            }

            if (_.isNil(_.get(entry, 'PayrollItem.id'))) {
                entry.PayrollItem = getEntityWithId(
                    'PayrollItem',
                    entry.payroll_item_id,
                    resources
                );
            }

            if (_.isNil(_.get(entry, 'AccountUser.id'))) {
                entry.AccountUser = (accountUsers || []).find(
                    ({ id }) => id === entry.account_user_id
                );
            }

            if (entry.type === 'Employee' && _.isNil(_.get(entry, 'Employee.id'))) {
                entry.Employee = canViewEmployeesById[entry.employee_id];
            }

            if (entry.type === 'Vendor') {
                if (!_.isNil(_.get(entry, 'Vendor.id'))) {
                    entry.Employee = entry.Vendor;
                } else {
                    entry.Employee = canViewEmployeesById[`${entry.employee_id}_v`];
                }
            }

            entry.unknown_entities = figureUnknownEntities(
                {
                    employee: entry.Employee,
                    customer: entry.Customer,
                    inventoryItem: entry.InventoryItem,
                    qbClass: entry.QbClass,
                    payrollItem: entry.PayrollItem,
                },
                qbType
            );

            entry.can_be_edited_by_current_user = canUserEditTimeEntry({
                user: currentUser,
                entry,
                preventEditForSyncedEntries,
                prevent_edit_for_locked_entries,
                qbType,
            });

            entry.can_be_approved_by_current_user = canUserApproveTimeEntry({
                user: currentUser,
                entry,
                preventEditForSyncedEntries,
            });

            entry.can_user_lock_entry = canUserLockTimeEntry({
                user: currentUser,
                entry,
                prevent_edit_for_locked_entries,
            });

            const weekStartOffset = _.defaultTo(_.get(currentUser, 'week_start_offset', 0), 0);
            const date = moment(entry.date, 'Y-MM-DD');
            const range = expandDateToWeekRange(new Date(), weekStartOffset);
            entry.is_in_current_week = date.isBetween(range.startDate, range.endDate, 'day', '[]');

            return entry;
        });
    }
);

export const selectAllowedTimesheetFilters = createSelector(
    [selectCurrentUser, selectAccount],
    (currentUser, account) => {
        const canViewOthersTime = currentUser.view_others_time === 'Yes';
        const shouldShowQbClasses = account.show_qb_classes === 'Yes';
        const shouldShowPayrollItems = account.show_payroll === 'Yes';
        const shouldShowBillable = account.show_billable === 'Yes';
        const shouldShowTaxable = account.show_taxable === 'Yes';
        const noIntegration = account.qb_type === 'None';
        return TIMESHEET_FILTERS.filter(({ filter: { id } }) => {
            if (id === 'employee' && !canViewOthersTime) {
                return false;
            }

            if ((id === 'qbClass' && !shouldShowQbClasses) || (id === 'qbClass' && noIntegration)) {
                return false;
            }

            if (id === 'payrollItem' && !shouldShowPayrollItems) {
                return false;
            }

            if (id === 'billable' && !shouldShowBillable) {
                return false;
            }

            if (id === 'taxable' && !shouldShowTaxable) {
                return false;
            }

            if (id === 'exported' && noIntegration) {
                return false;
            }
            
            if (id === 'locked' && !noIntegration) {
                return false;
            }
            return true;
        });
    }
);

export const selectFilteredEntries = createSelector(
    [selectAllDenormalizedEntries, selectSearchKeyword],
    filterByKeyword
);

export const selectGroupedByWeek = createSelector(
    [selectFilteredEntries, selectWeekOffset],
    groupByWeek
);

export const selectGroupedByWeekAndSorted = createSelector(
    [selectGroupedByWeek],
    entriesByWeek => {
        return _.mapValues(entriesByWeek, sortByEmployeeAndDate);
    }
);

export const selectGroupedByWeekAndWeekHash = createSelector(
    [selectGroupedByWeek],
    entriesByWeek => {
        return _.mapValues(entriesByWeek, groupByWeekHash);
    }
);

export const selectGroupedByWeekAndWeekHashAndEmployee = createSelector(
    [selectGroupedByWeekAndWeekHash],
    entriesByWeek => {
        return _.mapValues(entriesByWeek, sortByEmployeeAndCreatedDate);
    }
);

export const selectSortedWeekIds = createSelector(
    [selectGroupedByWeek],
    getSortedWeekIds
);

export const selectWeeklyTotals = createSelector(
    [selectGroupedByWeekAndWeekHash],
    calculateWeeklyTotals
);

export const selectWeeklyGrandTotal = createSelector(
    [selectWeeklyTotals],
    calculateWeeklyGrandTotal
);

export const selectEntriesAvailableForEdit = createSelector(
    [selectGroupedByWeekAndWeekHash],
    entriesByWeek => {
        const entriesAvailableForEdit = [];
        _.forOwn(entriesByWeek, entries => {
            _.forOwn(entries, entry => {
                const canEdit = entry.can_be_edited_by_current_user;
                const canApprove = entry.can_be_approved_by_current_user;

                if (canEdit || canApprove) {
                    entriesAvailableForEdit.push(entry);
                }
            });
        });
        return entriesAvailableForEdit;
    }
);

export const selectEntriesDateRange = createSelector(
    [selectAllEntries],
    getMinAndMaxDateOfEntries
);

export const selectDateFilterOptions = createSelector(
    [selectStartDate, selectEndDate],
    (startDate, endDate) => {
        return {
            startDate: _.isNil(startDate) ? startDate : new Date(startDate),
            endDate: _.isNil(endDate) ? endDate : new Date(endDate),
        };
    }
);
