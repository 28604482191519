import React, { Component } from 'react';
import { Input } from 'reactstrap';
// import Icon from 'react-icons-kit';
// import { lock } from 'react-icons-kit/fa/lock';
// import { ban } from 'react-icons-kit/fa/ban';
// import { clockO } from 'react-icons-kit/fa/clockO';
import SaveLoading from '../../components/SaveLoading.js';
import PhoneInput from 'react-phone-input-2';
import { Row, Col } from 'react-bootstrap';
import Select from '../../components/Select.js';
import {
    isCompanyNameValid,
    isNameValid,
    isPasswordsValid,
    isValidPhone,
    isValidEmail,
} from '../../../utils/CommonFunctions.js';

const qbTypes = [
    { label: 'Quickbooks Online', value: 'Online' },
    { label: 'Quickbooks Desktop', value: 'Desktop' },
    { label: 'No Integration', value: 'None' },
];

export default class DefaultFreeTrial extends Component {
    redirect = to => {
        this.props.history.push(to);
    };
    onSubmit = () => {
        const recaptchaValue = this.props.recaptchaRef.current.getValue();
        this.props.onSubmit(recaptchaValue);
    };

    state = {
        fNameInvalidMsg: '',
        lNameInvalidMsg: '',
        emailInvalidMsg: '',
        phoneInvalidMsg: '',
        passwordlInvalidMsg: '',
        cNameInvalidMsg: '',
    };
    
    setFields = fields => {
        this.setState({ ...fields });
    };

    render() {
        //const { promo_code, handlePromoCodeOnChange, verifyPromoCode } = this.props;
        const {
            first_name,
            last_name,
            email,
            phone,
            name,
            password,
            confirmPassword,
            size,
            industry,
        } = this.props;

        return (
            <div className="application">
                {/* <div className="ftHeaderText">
                    <div className="ftGetStarted">Get started now for FREE!</div>

                </div> */}
                <div className="ftContainer cardContainer">
                    <form className="ftForm" onSubmit={this.props.createAccount}>
                        <Row>
                            <div className="logoContainer">
                                <img
                                    style={{ width: 120, height: 35 }}
                                    srcSet="/img/sales/logo@2x-dark.png "
                                    src="/img/sales/logo@2x-dark.png"
                                    alt="Minute7"
                                />
                            </div>
                        </Row>
                        <Row className="ftRowAlightCenter">
                            <div className="ftSubHeader">
                                Simplify your time and expense management today.
                            </div>
                        </Row>
                        <Row className="ftRowAlightCenter">
                            <div className="ftSubHeader2 ">
                                No credit card required. Cancel anytime.
                            </div>
                        </Row>
                        <Row className="ftrow">
                            <Col sm={6} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                            {' '}
                                            First Name{' '}
                                        </div>
                                        <div className="required">*</div>
                                    </Row>

                                    <div>
                                        <Input
                                            className={
                                                this.state.fNameInvalidMsg === ''
                                                    ? ''
                                                    : 'ausInvalidInput'
                                            }
                                            name="first_name"
                                            value={first_name}
                                            onChange={this.props.handleFirstNameOnChange}
                                            onBlur={
                                                this.props.first_name.length !== 0
                                                    ? () =>
                                                          isNameValid(this.props.first_name)
                                                              ? this.setFields({
                                                                    fNameInvalidMsg: '',
                                                                })
                                                              : this.setFields({
                                                                    fNameInvalidMsg:
                                                                        'First name contains only  1 to 50 alphabets.',
                                                                })
                                                    : ''
                                            }
                                        />
                                        <div className="ftInputError">
                                            {!this.state.fNameInvalidMsg === '' &&
                                            !first_name === '' ? (
                                                <label />
                                            ) : (
                                                <label className="error-message">
                                                    {this.state.fNameInvalidMsg}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                            {' '}
                                            Last Name{' '}
                                        </div>
                                        <div className="required">*</div>
                                    </Row>
                                    <div>
                                        <Input
                                            className={
                                                this.state.lNameInvalidMsg === ''
                                                    ? ''
                                                    : 'ausInvalidInput'
                                            }
                                            name="last_name"
                                            value={last_name}
                                            onChange={this.props.handleLastNameOnChange}
                                            onBlur={
                                                last_name.length !== 0
                                                    ? () =>
                                                          isNameValid(last_name)
                                                              ? this.setFields({
                                                                    lNameInvalidMsg: '',
                                                                })
                                                              : this.setFields({
                                                                    lNameInvalidMsg:
                                                                        'Last name contains only  1 to 50 alphabets.',
                                                                })
                                                    : ''
                                            }
                                        />
                                        <div className="ftInputError">
                                            {!this.state.lNameInvalidMsg === '' &&
                                            !last_name === '' ? (
                                                <label />
                                            ) : (
                                                <label className="error-message">
                                                    {this.state.lNameInvalidMsg}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="ftrow">
                            <Col sm={12} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText"> Email</div>
                                        <div className="required">*</div>
                                    </Row>

                                    <div>
                                        <Input
                                            className={
                                                this.state.emailInvalidMsg === ''
                                                    ? ''
                                                    : 'ausInvalidInput'
                                            }
                                            name="email"
                                            value={email}
                                            onChange={this.props.handleEmailOnChange}
                                            onBlur={
                                                email.length !== 0
                                                    ? () =>
                                                          isValidEmail(email)
                                                              ? this.setFields({
                                                                    emailInvalidMsg: '',
                                                                })
                                                              : this.setFields({
                                                                    emailInvalidMsg:
                                                                        'Email must be between to 4 to 40 characters (e.g. example@minute7.com)',
                                                                })
                                                    : ''
                                            }
                                        />
                                        <div className="ftInputError">
                                            {!this.state.emailInvalidMsg === '' &&
                                            !this.props.email === '' ? (
                                                <label />
                                            ) : (
                                                <label className="error-message">
                                                    {this.state.emailInvalidMsg}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="ftrow">
                            <Col sm={6} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                            {' '}
                                            Company Name
                                        </div>
                                        <div className="required">*</div>
                                    </Row>
                                    <div>
                                        <Input
                                            className={
                                                this.state.cNameInvalidMsg !== '' && name !== ''
                                                    ? 'ausInvalidInput'
                                                    : ''
                                            }
                                            name="company_name"
                                            value={name}
                                            onChange={this.props.handleNameOnChange}
                                            onBlur={
                                                name.length !== 0
                                                    ? () =>
                                                          isCompanyNameValid(name)
                                                              ? this.setFields({
                                                                    cNameInvalidMsg: '',
                                                                })
                                                              : this.setFields({
                                                                    cNameInvalidMsg:
                                                                        "Only Alphanumeric and some Special  Symbols (' - & . , _) allowed between 3 to 100 characters",
                                                                })
                                                    : ''
                                            }
                                        />
                                        <div className="ftInputError">
                                            {this.state.cNameInvalidMsg !== '' || name === '' ? (
                                                <label className="error-message">
                                                    {this.state.cNameInvalidMsg}
                                                </label>
                                            ) : (
                                                <label />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                            {' '}
                                            Integration Type
                                        </div>
                                        <div className="required">*</div>
                                    </Row>
                                    <div className="ftDropdown">
                                        <Select
                                            options={qbTypes}
                                            valueForOption={option => option.value}
                                            labelForOption={option => option.label}
                                            value={this.props.qb_type}
                                            onChange={this.props.handleQbTypeOnChange}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="ftrow">
                            <Col sm={12} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                            {' '}
                                            Phone Number
                                        </div>
                                        <div className="required">*</div>
                                    </Row>

                                    <div>
                                        <PhoneInput
                                            className={
                                                this.state.phoneInvalidMsg === ''
                                                    ? ''
                                                    : 'ausInvalidInput'
                                            }
                                            name="phone_number"
                                            placeholder="###-###-####"
                                            containerClass="phone-input"
                                            country={'us'}
                                            value={phone}
                                            onChange={this.props.handlePhoneOnChange}
                                            onBlur={
                                                phone.length !== 0
                                                    ? () =>
                                                          isValidPhone(phone)
                                                              ? this.setFields({
                                                                    phoneInvalidMsg: '',
                                                                })
                                                              : this.setFields({
                                                                    phoneInvalidMsg:
                                                                        'Enter a valid phone number (e.g. 012-345-6789)',
                                                                })
                                                    : ''
                                            }
                                        />
                                        <div className="ftInputError">
                                            {!this.state.phoneInvalidMsg === '' && !phone === '' ? (
                                                <label />
                                            ) : (
                                                <label className="error-message">
                                                    {this.state.phoneInvalidMsg}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="ftrow">
                            <Col sm={6} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                            {' '}
                                            Password
                                        </div>
                                        <div className="required">*</div>
                                    </Row>
                                    <div>
                                        <Input
                                            className={
                                                this.state.passwordlInvalidMsg === ''
                                                    ? ''
                                                    : 'ausInvalidInput'
                                            }
                                            name="password"
                                            type="password"
                                            value={password}
                                            onChange={this.props.handlePasswordOnChange}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col sm={6} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                            {' '}
                                            Confirm Password
                                        </div>
                                        <div className="required">*</div>
                                    </Row>
                                    <div>
                                        <Input
                                            className={
                                                this.state.passwordlInvalidMsg === ''
                                                    ? ''
                                                    : 'ausInvalidInput'
                                            }
                                            name="confirmPassword"
                                            type="password"
                                            value={confirmPassword}
                                            onChange={this.props.handleConfirmPasswordOnChange}
                                            onBlur={
                                                confirmPassword.length !== 0
                                                    ? () =>
                                                          isPasswordsValid(
                                                              password,
                                                              confirmPassword
                                                          )
                                                              ? this.setFields({
                                                                    passwordlInvalidMsg: '',
                                                                })
                                                              : this.setFields({
                                                                    passwordlInvalidMsg:
                                                                        'Make sure your password minimum length is 6 and must match to the confirm password',
                                                                })
                                                    : ''
                                            }
                                        />
                                        <div className="ftInputError">
                                            {!this.state.passwordlInvalidMsg === '' &&
                                            !confirmPassword === '' ? (
                                                <label />
                                            ) : (
                                                <label className="error-message">
                                                    {this.state.passwordlInvalidMsg}
                                                </label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <div className="ftButtonContainer">
                                {!this.props.loading ? (
                                    <button type="submit" className="primaryButton ftButton">
                                        Start Your Free Trial
                                    </button>
                                ) : (
                                    <SaveLoading />
                                )}
                            </div>
                        </Row>
                        <Row className="ftrow, ftRowAlightCenter">
                            <div className="ftLabel2 largeBodyText greyText">Need </div>
                            <div className="fbLinkRow" onClick={() => this.redirect('/contact')}>
                                Assistance?
                            </div>
                        </Row>
                    </form>
                    {/* <div className="ftRightContainer">
                        <div className="ftQuestions">Have any questions? (858) 736-9366</div>
                        <div className="ftQuote">
                            "I've been using Minute7 for 5 years. I would never consider anything
                            else. It is hands down the best time and expense tracking software"
                            <div className="ftQuotedPerson">CONNIE FARINO - WESTSIDE FINANCIAL</div>
                            "Minute7 has really simplified my payroll and reimbursements. Super easy
                            integration with QuickBooks!"
                            <div className="ftQuotedPerson">
                                JON BOWMAN - CONCORD CUSTOM CLEANERS
                            </div>
                        </div>
                        <div className="ftIconAndInfoContainer">
                            <Icon className="ftIcon" size={68} icon={lock} />
                            <div>
                                <div className="ftInfo">No Setup Fees, No Credit Card Required</div>
                                <div>
                                    There are no sign up, setup, software, hardware, download, or
                                    update costs
                                </div>
                            </div>
                        </div>
                        <div className="ftIconAndInfoContainer">
                            <Icon className="ftIcon" size={68} icon={ban} />
                            <div>
                                <div className="ftInfo">Safe and Secure</div>
                                <div>
                                    We use the same SSL encryption technology as major banks to keep
                                    your private data completely safe
                                </div>
                            </div>
                        </div>
                        <div className="ftIconAndInfoContainer">
                            <Icon className="ftIcon" size={68} icon={clockO} />
                            <div>
                                <div className="ftInfo">No Setup Fees, No Credit Card Required</div>
                                <div>
                                    Just sync with QuickBooks&trade;, create users, and start saving
                                    time
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}
