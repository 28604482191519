import React from 'react';

const RestartingTimers = () => (
    <div className="helpModal">
        <div className="sectionQuestion">How do I restart a timer in the new site?</div>
        <div className="sectionAnswer">
            <p>
                To restart a timer for an existing timesheet you need to make sure you are grouping
                entries individually in Minute7. Here are the steps:
            </p>
            <ol>
                <li>Go to the Timesheets tab in Minute7</li>
                <li>
                    Scroll down to the bottom of the page and the right hand side click the “More”
                    button.
                </li>
                <li>Under “Grouping Options” select “Show Individual Entries”.</li>
                <li>
                    Now next to any entry that was created using a timer you will see a button that
                    says “Continue Timer”. Click the button to restart the timer.{' '}
                </li>
            </ol>
        </div>
    </div>
);

export default RestartingTimers;
