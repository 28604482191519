import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { isInventoryItemNameValid } from '../../../utils/CommonFunctions.js';
import { Row, Col } from 'react-bootstrap';
import { accountFields } from '../../../config/Fields.js';
import { showToast } from '../../../actions/Actions.js';
import 'react-phone-input-2/lib/style.css';
import Select from '../../components/Select.js';
import Spinner from '../../components/Spinner.js';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';
import { updateAccount } from '../../../actions/AccountsActions.js';
import { selectAccount } from '../../../actions/Actions.js';

const accountTypes = [
    { name: 'Assets', id: 'Assets' },
    { name: 'Liabilities', id: 'Liabilities' },
    { name: 'Income', id: 'Income' },
    { name: 'Expenses', id: 'Expenses' },
];

const paymentAccountTypes = [
    { name: 'Credit Card', id: 'Credit Card' },
    { name: 'Bank', id: 'Bank' },
];

class EditAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState({ selectedView: 'accounts' });
    }

    componentWillReceiveProps(props) {}

    defaultState = data => {
        const { accountId, account } = this.props;
        let accountSettings = ['Credit Card', 'Bank'].includes(account.account_type)
            ? 'payment'
            : 'general';
        return {
            iNameInvalidMsg: '',
            id: account.id || '',
            name: account.name || '',
            account_type: account.account_type || '',
            account_id: accountId,
            spinner_loader: false,
            hidden: account.hidden === 'Yes' || false,
            accountSettings: accountSettings,
        };
    };

    constructAccount = () => {
        const data = {};
        accountFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else {
                    data[field] = this.state[field];
                }
            }
        });
        return data;
    };

    checkRequiredFields = () => {
        const { name, account_type } = this.state;

        if (name === '' || account_type === '') {
            this.props.showToast('Please fill out name, account type ', 'warning');
            return false;
        }
        return true;
    };

    saveAccount = event => {
        event.preventDefault();
        if (this.state.iNameInvalidMsg !== '') {
            this.props.showToast('Please Fix the form error before submit', 'warning');
        } else {
            if (!this.checkRequiredFields()) {
                return;
            }
            this.setState({ spinner_loader: true });
            const data = this.constructAccount();
            this.props.updateAccount(data, newAccount => {
                if (newAccount === false) {
                    this.setState({ spinner_loader: false });
                    return;
                } else {
                    this.props.showToast(`Account ${newAccount.name} has been updated`, 'positive');
                    this.props.history.push('/resource_settings/accounts');
                }
            });
        }
    };

    handleAccountTypeOnChange = newAccountType => {
        this.setState({ account_type: newAccountType });
    };

    setFields = fields => {
        this.setState({ ...fields });
    };

    handleOnChanges = {
        handleAccountName: newName => {
            this.setState({ name: newName.target.value });
        },
    };

    render() {
        const { spinner_loader, name, account_type, hidden, accountSettings } = this.state;
        return (
            <div className="applicationWrapper">
                <div className="application">
                    <div className="ftContainer cardContainer">
                        <form className="ftForm" onSubmit={this.saveAccount}>
                            <Row className="ftRowAlightCenter">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Edit Account
                                </div>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {accountSettings === 'general'
                                                    ? 'Account Name'
                                                    : 'Payment Account Name'}
                                            </div>
                                            <div className="required">*</div>
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.iNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="name"
                                                value={name}
                                                onChange={this.handleOnChanges.handleAccountName}
                                                onBlur={
                                                    name.length !== 0
                                                        ? () =>
                                                              isInventoryItemNameValid(name)
                                                                  ? this.setFields({
                                                                        iNameInvalidMsg: '',
                                                                    })
                                                                  : this.setFields({
                                                                        iNameInvalidMsg:
                                                                            'Account name contains only  1 to 50 alphabets.',
                                                                    })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.iNameInvalidMsg === '' &&
                                                !name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.iNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {accountSettings === 'general'
                                                    ? 'Account Type'
                                                    : 'Payment Account Type'}
                                            </div>
                                            <div className="required">*</div>
                                        </Row>
                                        <div>
                                            <Select
                                                options={
                                                    accountSettings === 'general'
                                                        ? accountTypes
                                                        : paymentAccountTypes
                                                }
                                                withSearch
                                                labelWhenNothingSelected="No Account Selected"
                                                value={account_type}
                                                onChange={this.handleAccountTypeOnChange}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '15px', marginTop: '15px' }}>
                                    <div className="ftLabelAndInput">
                                        <div>
                                            <input
                                                name="hidden_checkbox"
                                                type="checkbox"
                                                className="checkbox"
                                                checked={hidden}
                                                onChange={() =>
                                                    this.setFields({
                                                        hidden: !hidden,
                                                    })
                                                }
                                            />
                                            <label htmlFor="hidden_checkbox">
                                                Mark Account as inactive
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button type="submit" className="primaryButton ftButton">
                                            Save
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button
                                            className="secondaryButton ftButton"
                                            onClick={() =>
                                                this.props.history.push(
                                                    '/resource_settings/accounts'
                                                )
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Spinner loading={spinner_loader} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        appStore: { selectedAccountId },
    } = state;

    const account = selectedAccountId;
    return {
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        isSaving: state.accountUserStore.isSaving,
        account,
    };
}

const mapDispatchToProps = {
    showToast,
    updateAccount,
    selectAccount,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAccountForm);
