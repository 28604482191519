import _ from 'lodash';

// Order of timeFields and expenseFields affect exports
export const timeFields = [
    // id will only be taken into account for edit calls of day entries
    'id',
    'account_id',
    'account_user_id',
    'approved',
    'billable',
    'customer_id',
    'date',
    'description',
    'employee_id',
    'exported',
    'inventory_item_id',
    'payroll_item_id',
    'project',
    'qb_class_id',
    'start_time',
    'end_time',
    'hours_off_duty',
    'duration',
    'type',
    'taxable',
    'duplicate_time_entry_id',
    'inventory_item_id_changed',
    'payroll_item_id_changed',
    'old_inventory_item_id',
    'default_rate'

];

export const allowedAccountTypes = [
    'Expense',
    'OtherExpense',
    'OtherCurrentAsset',
    'OtherCurrentLiability',
    'FixedAsset',
    'CostOfGoodsSold',
    'OtherAsset',
    'Expenses',
    'Income',
    'Assets',
    'Liabilities',
];

export const paymentAccountTypes = ['Bank', 'CreditCard', 'Credit'];

export const searchableFields = [
    'Customer.name',
    'Employee.name',
    'InventoryItem.name',
    'PayrollItem.name',
    'QbClass.name',
    'Vendor.name',
    'description',
    'duration',
];

export const weekHashFields = [
    'approved',
    'billable',
    'taxable',
    'customer_id',
    'description',
    'employee_id',
    'exported',
    'locked',
    'inventory_item_id',
    'payroll_item_id',
    'qb_class_id',
    'type',
    // calculated during denormalization
    'unknown_entities',
    'can_be_edited_by_current_user',
    'can_be_approved_by_current_user',
    'can_user_lock_entry',
    // associations (in denormalized form)
    'AccountUser',
    'Customer',
    'Employee',
    'Vendor',
    'PayrollItem',
    'InventoryItem',
    'QbClass',
];
export const expenseFields = [
    'id',
    'account_id',
    'account_user_id',
    'amount',
    'approved',
    'billable',
    'customer_id',
    'date',
    'description',
    'expense_grouping_id',
    'exported',
    'miles',
    'expenseable_id',
    'qb_class_id',
    'reimbursement_rate',
    'vendor_id',
    'attachments',
    'deletedAttachments',
    'payment_account_id',
    'locked'
];

export const employeeFields = [
    'id',
    'name',
    'account_id',
    'first_name',
    'middle_name',
    'last_name',
    'pay_rate',
    'email',
    'payroll_item_id',
    'payroll_item_ids',
    'password',
    'hidden',
    'reference_number',
    'use_time_for_paychecks',
    'payroll_bill_rates',
];

export const itemFields = [
    'id',
    'name',
    'account_id',
    'item_type',
    'description',
    'quantity',
    'hidden',
    'default_rate'
];

export const accountFields = ['id', 'name', 'account_id', 'account_type', 'hidden'];

export const accountUserFields = [
    'id',
    'account_id',
    'first_name',
    'middle_name',
    'last_name',
    'email',
    'password',
    'phone',
    'week_start_offset',
    'timesheet_grouping',
    'preferred_distance_units',
    'reimbursement_rate',
    'show_most_recent',
    'employee_id',
    'type',
    'expense_vendor_id',
    'default_payroll_item_id',

    'time_entry_reminder_frequency',
    'time_entry_reminder_minimum_hours',
    'export_reminder_interval',

    'manage_account',
    'manage_users',
    'manage_api',
    'export_time',
    'view_reports',
    'clock_in_only',
    'edit_payroll',
    'manage_payroll_payrates',
    'manage_item_billrates',
    'permission_group_id',
    'edit_own_time',
    'view_others_time',
    'filter_view_employee_ids',
    'edit_others_time',
    'filter_edit_employee_ids',
    'approve_others_time',
    'filter_approve_employee_ids',
    'edit_own_expenses',
    'view_others_expenses',
    'filter_view_expense_vendor_ids',
    'edit_others_expenses',
    'filter_edit_expense_vendor_ids',
    'approve_others_expenses',
    'filter_approve_expense_vendor_ids',
    'filter_customer_list',
    'filter_customer_ids',
    'filter_inventory_item_list',
    'filter_inventory_item_ids',
    'filter_qb_account_list',
    'filter_qb_account_ids',
    'filter_qb_class_list',
    'filter_qb_class_ids',
    'approve_others_expenses_override_group',
    'approve_others_time_override_group',
    'edit_others_expenses_override_group',
    'edit_others_time_override_group',
    'edit_own_expenses_override_group',
    'edit_own_time_override_group',
    'edit_payroll_override_group',
    'export_time_override_group',
    'filter_approve_employee_ids_override_group',
    'filter_approve_expense_vendor_ids_override_group',
    'filter_customer_ids_override_group',
    'filter_customer_list_override_group',
    'filter_edit_employee_ids_override_group',
    'filter_edit_expense_vendor_ids_override_group',
    'filter_inventory_item_ids_override_group',
    'filter_inventory_item_list_override_group',
    'filter_qb_account_ids_override_group',
    'filter_qb_account_list_override_group',
    'filter_qb_class_ids_override_group',
    'filter_qb_class_list_override_group',
    'filter_view_employee_ids_override_group',
    'filter_view_expense_vendor_ids_override_group',
    'manage_account_override_group',
    'manage_expenses_override_group',
    'manage_time_override_group',
    'manage_users_override_group',
    'manage_api_override_group',
    'manage_users_override',
    'restrictions_override_group',
    'view_others_expenses_override_group',
    'view_others_time_override_group',
    'view_reports_override_group',
    'send_invite_sms',
    'use_time_for_paychecks',
    'manage_payroll_payrates_override_group',
    'manage_item_billrates_override_group',
];
export const approvedFilter = [
    { id: 'all', name: 'Approved or Not' },
    { id: 'approved', name: 'Approved' },
    { id: 'pending', name: 'Pending' },
];

export const lockedFilter = [
    { id: 'all', name: 'Locked or Not' },
    { id: 'locked', name: 'Locked' },
    { id: 'unlocked', name: 'Unlocked' },
];


export const billableFilter = [
    { id: 'all', name: 'Billable or Not' },
    { id: 'billable', name: 'Billable' },
    { id: 'not_billable', name: 'Not Billable' },
];

export const taxableFilter = [
    { id: 'all', name: 'Taxable or Not' },
    { id: 'taxable', name: 'Taxable' },
    { id: 'not_taxable', name: 'Not Taxable' },
];

export const exportedFilter = [
    { id: 'all', name: 'Synced or Not' },
    { id: 'synced', name: 'Synced' },
    { id: 'unsynced', name: 'Unsynced' },
];

export const expenseTypeFilter = [
    { id: 'all', name: 'Reimbursable or Credit Card' },
    { id: 'reimbursable', name: 'Reimbursable' },
    { id: 'credit_card', name: 'Credit Card' },
];

export const groupingOptions = {
    week: 'Group by week',
    individual_entries: 'Show individual entries',
};

export const emailType = {
    support: 'Support',
    suggestion: 'Suggestion',
};

export const groupingOptionsForSelect = _.map(groupingOptions, (name, id) => ({ name, id }));

export const attachmentFilter = [
    { id: 'all', name: 'With and Without Attachment' },
    { id: 'with_attachment', name: 'With Attachment Only' },
    { id: 'without_attachment', name: 'Without Attachment Only' },
];
export const timeRoundingIntervals = {
    '1 minute': '1',
    '5 minutes': '5',
    '6 minutes': '6',
    '10 minutes': '10',
    '15 minutes': '15',
};
export const toParamsObject = {
    all: '',
    approved: 'Yes',
    pending: 'No',
    billable: 'Yes',
    not_billable: 'No',
    taxable: 'Yes',
    not_taxable: 'No',
    synced: 'Yes',
    unsynced: 'No',
    with_attachment: 'Yes',
    without_attachment: 'No',
    reimbursable: 'Yes',
    credit_card: 'No',
    locked: 'Yes',
    unlocked: 'No'
};
export const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
let today = new Date();
export const years = [
    parseInt(today.getFullYear(), 10),
    parseInt(today.getFullYear(), 10) + 1,
    parseInt(today.getFullYear(), 10) + 2,
    parseInt(today.getFullYear(), 10) + 3,
    parseInt(today.getFullYear(), 10) + 4,
    parseInt(today.getFullYear(), 10) + 5,
    parseInt(today.getFullYear(), 10) + 6,
    parseInt(today.getFullYear(), 10) + 7,
];
export const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const weekday_offsets = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6,
};
export const weekday_offsets_to_save = {
    Monday: 0,
    Tuesday: -6,
    Wednesday: -5,
    Thursday: -4,
    Friday: -3,
    Saturday: -2,
    Sunday: -1,
};
export const remindMe = ['Never', 'Daily', 'Weekly'];
export const minimumHours = [
    'Any Time',
    'At Least 1 Hour',
    'At Least 2 Hour',
    'At Least 3 Hour',
    'At Least 4 Hour',
    'At Least 5 Hour',
    'At Least 6 Hour',
    'At Least 7 Hour',
    'At Least 8 Hour',
];
export const minimumHoursToSave = {
    'Any Time': 0,
    'At Least 1 Hour': 1,
    'At Least 2 Hour': 2,
    'At Least 3 Hour': 3,
    'At Least 4 Hour': 4,
    'At Least 5 Hour': 5,
    'At Least 6 Hour': 6,
    'At Least 7 Hour': 7,
    'At Least 8 Hour': 8,
};

export const payrollItemFields = ['id', 'name', 'account_id', 'wage_type', 'hidden'];

export const customerFields = [
    'id',
    'name',
    'account_id',
    'parent_id',
    'first_name',
    'middle_name',
    'last_name',
    'email',
    'password',
    'hidden',
    'company_name',
    'service_items_bill_rates',
    'inventory_items_bill_rates',
    'item_ids',
    'phone_1',
    'phone_2',
    'fax',
    'billing_address_line_1',
    'billing_address_line_2',
    'billing_address_city',
    'billing_address_zip_code',
    'shipping_address_line_1',
    'shipping_address_line_2',
    'shipping_address_city',
    'shipping_address_state',
    'shipping_address_zip_code',
];

export const syncReminder = ['Never', 'One Week', 'Two Weeks', 'One Month'];
export const reportTimeFields = {
    employee_id: 'Employee',
    customer_id: 'Customer & Job',
    description: 'Description',
    qb_class_id: 'Class',
    inventory_item_id: 'Service',
    payroll_item_id: 'Payroll Item',
    date: 'Date',
    duration: 'Duration',
    end_time: 'End Time',
    start_time: 'Start Time',
    hours_off_duty: 'Hours Off Duty',
    approved: 'Approved',
    billable: 'Billable',
    taxable: 'Taxable',
    exported: 'Exported',
    type: 'Type',
};

export const reportTimeFieldsNonQB = {
    employee_id: 'Employee',
    //employee_number: 'Employee Number',
    customer_id: 'Customer & Job',
    description: 'Description',
    qb_class_id: 'Class',
    inventory_item_id: 'Service',
    payroll_item_id: 'Payroll Item',
    payrate: 'Pay Rate',
    billrate: 'Bill Rate',
    billable_cost: ' Billable Cost',
    date: 'Date',
    duration: 'Duration',
    end_time: 'End Time',
    start_time: 'Start Time',
    hours_off_duty: 'Hours Off Duty',
    approved: 'Approved',
    billable: 'Billable',
    taxable: 'Taxable',
    locked: 'Locked',
    type: 'Type',
};

export const reportTimeFieldsNonPayRollAndBillRollManager = {
    employee_id: 'Employee',
    //employee_number: 'Employee Number',
    customer_id: 'Customer & Job',
    description: 'Description',
    qb_class_id: 'Class',
    inventory_item_id: 'Service',
    payroll_item_id: 'Payroll Item',
    date: 'Date',
    duration: 'Duration',
    end_time: 'End Time',
    start_time: 'Start Time',
    hours_off_duty: 'Hours Off Duty',
    approved: 'Approved',
    billable: 'Billable',
    taxable: 'Taxable',
    locked: 'Locked',
    type: 'Type',
};

export const reportTimeFieldsNonPayRollManager = {
    employee_id: 'Employee',
    //employee_number: 'Employee Number',
    customer_id: 'Customer & Job',
    description: 'Description',
    qb_class_id: 'Class',
    inventory_item_id: 'Service',
    payroll_item_id: 'Payroll Item',
    billrate: 'Bill Rate',
    billable_cost: 'Billable Cost',
    date: 'Date',
    duration: 'Duration',
    end_time: 'End Time',
    start_time: 'Start Time',
    hours_off_duty: 'Hours Off Duty',
    approved: 'Approved',
    billable: 'Billable',
    taxable: 'Taxable',
    locked: 'Locked',
    type: 'Type',
};
export const reportTimeFieldsNonBillRatelManager = {
    employee_id: 'Employee',
    // employee_number: 'Employee Number',
    customer_id: 'Customer & Job',
    description: 'Description',
    qb_class_id: 'Class',
    inventory_item_id: 'Service',
    payrate: 'Pay Rate',
    payroll_item_id: 'Payroll Item',
    date: 'Date',
    duration: 'Duration',
    end_time: 'End Time',
    start_time: 'Start Time',
    hours_off_duty: 'Hours Off Duty',
    approved: 'Approved',
    billable: 'Billable',
    taxable: 'Taxable',
    locked: 'Locked',
    type: 'Type',
};

export const reportExpenseFields = {
    vendor_id: 'Vendor',
    customer_id: 'Customer & Job',
    expenseable_id: 'Account / Item',
    qb_class_id: 'Class',
    expense_grouping_id: 'Bill',
    description: 'Description',
    amount: 'Amount',
    date: 'Date',
    miles: 'Miles',
    reimbursement_rate: 'Reimbursement Rate',
    exported: 'Exported',
    approved: 'Approved',
    billable: 'Billable',
    attachments: 'Attachments',
};
export const reportExpenseFieldsNonQB = {
    vendor_id: 'Vendor',
    customer_id: 'Customer & Job',
    expenseable_id: 'Account / Item',
    qb_class_id: 'Class',
    expense_grouping_id: 'Bill',
    description: 'Description',
    amount: 'Amount',
    date: 'Date',
    miles: 'Miles',
    reimbursement_rate: 'Reimbursement Rate',
    locked: 'Locked',
    approved: 'Approved',
    billable: 'Billable',
    attachments: 'Attachments',
};
export const pdfTimeExportFields = {
    date: 'Date',
    employee_id: 'Employee',
    customer_id: 'Customer',
    inventory_item_id: 'Service',
    duration: 'Hours',
    approved: 'Approved',
};
export const pdfExpenseExportFields = {
    date: 'Date',
    vendor_id: 'Vendor',
    customer_id: 'Customer',
    expense_grouping_id: 'Bill',
    qb_account_id: 'Account',
    amount: 'Amount',
    approved: 'Approved',
};
export const segmentationTimeFields = [
    {
        id: 'no_segment',
        name: "Don't Segment Further",
    },
    {
        id: 'employee_id',
        name: 'Employee/Vendor',
    },
    {
        id: 'customer_id',
        name: 'Customer',
    },
    {
        id: 'inventory_item_id',
        name: 'Service',
    },
    {
        id: 'qb_class_id',
        name: 'Class',
    },
    {
        id: 'payroll_item_id',
        name: 'Payroll Item',
    },
];
export const segmentationTimeFieldsNonPayrollManager = [
    {
        id: 'no_segment',
        name: "Don't Segment Further",
    },
    {
        id: 'employee_id',
        name: 'Employee/Vendor',
    },
    {
        id: 'customer_id',
        name: 'Customer',
    },
    {
        id: 'inventory_item_id',
        name: 'Service',
    },
    {
        id: 'qb_class_id',
        name: 'Class',
    },
];

export const segmentationExpenseFields = [
    {
        id: 'no_segment',
        name: "Don't Segment Further",
    },
    {
        id: 'vendor_id',
        name: 'Vendor',
    },
    {
        id: 'customer_id',
        name: 'Customer',
    },
    {
        id: 'expenseable_id',
        name: 'Account/Item',
    },
    {
        id: 'qb_class_id',
        name: 'Class',
    },
    {
        id: 'expense_grouping_id',
        name: 'Bill',
    },
];
export const supportedUploadFileTypes = [
    'image/png',
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
];
export const byAction = [
    'Add',
    'Edit',
    'Delete',
    'Approve',
    'Disapprove',
    'Billable',
    'Non billable',
    'Sync',
    'Taxable',
    'Non taxable',
];
export const byObjectType = [
    'Account',
    'User',
    'Time Entry',
    'Expense Entry',
    'Expense Grouping',
    'Permission Group',
    'QuickBooks Soap Interaction',
];
export const yearFilter = [
    parseInt(today.getFullYear(), 10),
    parseInt(today.getFullYear(), 10) - 1,
    parseInt(today.getFullYear(), 10) - 2,
    parseInt(today.getFullYear(), 10) - 3,
];
export const activityLogFields = ['account_user_id', 'created', 'message', 'action', 'object_type'];
export const companySettingsFields = [
    'name',
    'state',
    'zip',
    'export_wait_hours',
    'password_reset_days',
    'show_expenses',
    'show_vendors',
    'show_qb_classes',
    'show_payroll',
    'default_rounding_interval',
    'prevent_edit_for_synced_entries',
    'prevent_edit_for_locked_entries',
    'approved_by_default',
    'show_billable',
    'show_taxable',
    'billable_by_default',
    'time_autofill_description',
    'time_prepend_name',
    'expenses_prepend_name',
    'show_all_qb_account_types',
    'logo',
    'billable_type',
    'reimbursement_rate_type',
    'default_reimbursement_rate',
    'preferred_distance_units',
    'preferred_expense_type',
    'taxable_by_default',
    'expense_transaction_type',
    'show_service_items_for_expense',
    'prefer_pound_sign',
    'show_items_association_for_customers',
];
export const permissionsGroupsFields = [
    'id',
    'account_id',
    'name',
    'manage_account',
    'manage_users',
    'manage_api',
    'export_time',
    'view_reports',
    'edit_own_time',
    'view_others_time',
    'edit_others_time',
    'approve_others_time',
    'filter_view_employee_ids',
    'filter_edit_employee_ids',
    'filter_approve_employee_ids',
    'edit_own_expenses',
    'edit_others_expenses',
    'view_others_expenses',
    'approve_others_expenses',
    'filter_view_expense_vendor_ids',
    'filter_edit_expense_vendor_ids',
    'filter_approve_expense_vendor_ids',
    'edit_payroll',
    'filter_customer_list',
    'filter_customer_ids',
    'filter_inventory_item_list',
    'filter_inventory_item_ids',
    'filter_qb_account_list',
    'filter_qb_account_ids',
    'filter_qb_class_list',
    'filter_qb_class_ids',
    'manage_payroll_payrates',
    'manage_item_billrates',
];
export const stateVarToObject = {
    filter_customer_ids: 'customers',
    filter_inventory_item_ids: 'inventoryItems',
    filter_qb_account_ids: 'qbAccounts',
    filter_qb_class_ids: 'qbClasses',
    filter_view_employee_ids: 'employees',
    filter_edit_employee_ids: 'employees',
    filter_approve_employee_ids: 'employees',
    filter_view_expense_vendor_ids: 'vendors',
    filter_edit_expense_vendor_ids: 'vendors',
    filter_approve_expense_vendor_ids: 'vendors',
};

export const TIMESHEET_FILTERS = [
    {
        filter: {
            id: 'date',
            name: 'Date',
        },
    },
    {
        filter: {
            id: 'search',
            name: 'Search',
        },
    },
    {
        filter: {
            id: 'employee',
            name: 'Employee',
        },
    },
    {
        filter: {
            id: 'customer',
            name: 'Customer & Job',
        },
    },
    {
        filter: {
            id: 'inventoryItem',
            name: 'Service',
        },
    },
    {
        filter: {
            id: 'qbClass',
            name: 'Class',
        },
    },
    {
        filter: {
            id: 'payrollItem',
            name: 'Payroll Item',
        },
    },
    {
        filter: {
            id: 'billable',
            name: 'Billable / Not Billable',
        },
    },
    {
        filter: {
            id: 'taxable',
            name: 'Taxable / Not Taxable',
        },
    },
    {
        filter: {
            id: 'approved',
            name: 'Approved / Pending',
        },
    },
    {
        filter: {
            id: 'exported',
            name: 'Synced / Unsynced',
        },
    },
    {
        filter: {
            id: 'locked',
            name: 'Locked / Unlocked',
        },
    },
];

export const EXPENSE_FILTERS = [
    {
        filter: {
            id: 'date',
            name: 'Date',
        },
    },
    {
        filter: {
            id: 'search',
            name: 'Search',
        },
    },
    {
        filter: {
            id: 'vendor',
            name: 'Vendor',
        },
    },
    {
        filter: {
            id: 'customer',
            name: 'Customer & Job',
        },
    },
    {
        filter: {
            id: 'qbAccount',
            name: 'Account',
        },
    },
    {
        filter: {
            id: 'inventoryItem',
            name: 'Item',
        },
    },
    {
        filter: {
            id: 'qbClass',
            name: 'Class',
        },
    },
    {
        filter: {
            id: 'bill',
            name: 'Bill',
        },
    },
    {
        filter: {
            id: 'billable',
            name: 'Billable / Not Billable',
        },
    },
    {
        filter: {
            id: 'approved',
            name: 'Approved / Pending',
        },
    },
    {
        filter: {
            id: 'exported',
            name: 'Synced / Unsynced',
        },
    },
    {
        filter: {
            id: 'locked',
            name: 'Locked / Unlocked',
        },
    },
    {
        filter: {
            id: 'expenseType',
            name: 'Reimbursable / Credit Card',
        },
    },
    {
        filter: {
            id: 'attachment',
            name: 'With / Without Attachments',
        },
    },
];

export const preferredDistanceUnits = [
    {
        id: 'miles',
        name: 'Miles',
    },
    {
        id: 'kilometers',
        name: 'Kilometers',
    },
];

export const reportFilterFileds = [
    'account_id',
    'user_id',
    'name',
    'employees',
    'vendors',
    'accounts',
    'customers',
    'class_list',
    'service',
    'inventory',
    'bill',
    'expense_type',
    'attachment',
    'payroll_items',
    'approved',
    'taxable',
    'billable',
    'locked',
    'synced',
    'report_type',
    'fields'
];
