import React from 'react';

const CouldNotAddError = () => (
    <div>
        <div className="sectionQuestion">
            When I sync, I get the error message "QuickBooks Online could not add time and expense
            entries because your QuickBooks Online account does not have time and expense tracking
            enabled. You may need to upgrade your QuickBooks Online account to use these features."
            How do I upgrade my QuickBooks Online account?
        </div>
        <div className="sectionAnswer">
            Minute7 can sync with any version of QuickBooks Online Edition that has time and expense
            tracking enabled. Currently, QuickBooks Online Basic does not offer support for time
            tracking, and so will not work with Minute7's time tracking features; if your company
            uses QuickBooks Online Basic, you may need to upgrade to QuickBooks Online Plus or
            enable Time Tracking in QuickBooks Online to use Minute7's time tracking features.
            <br /> <br />
            You can enable time tracking in your QuickBooks Online account by signing in to your
            QuickBooks Online account and going to the Employees tab in the top menubar. Click on
            the icon labeled "Time Tracking" to see the ways in which you can add time tracking
            features to your QuickBooks Online account.
        </div>
    </div>
);

export default CouldNotAddError;
