export const setCanViewVendors = payload => ({
    type: 'SET_CAN_VIEW_VENDORS',
    payload,
});

export const setCanEditVendors = payload => ({
    type: 'SET_CAN_EDIT_VENDORS',
    payload,
});

export const setAllVendors = payload => ({
    type: 'SET_ALL_VENDORS',
    payload,
});

export const refreshVendors = () => ({
    type: 'REFRESH_VENDORS',
});

export const refreshVendorsSuccess = () => ({
    type: 'REFRESH_VENDORS_SUCCESS',
});

export const addVendor = (vendor, callback) => ({
    type: 'ADD_VENDOR',
    vendor,
    callback,
});

export const addVendorSuccess = vendor => ({
    type: 'ADD_VENDOR_SUCCESS',
    vendor,
});

export const addVendorError = error => ({
    type: 'ADD_VENDOR_ERROR',
    error,
});

export const updateVendor = (vendor, callback) => ({
    type: 'UPDATE_VENDOR',
    vendor,
    callback,
});

export const updateVendorSuccess = vendor => ({
    type: 'UPDATE_VENDOR_SUCCESS',
    vendor,
});

export const updateVendorError = error => ({
    type: 'UPDATE_VENDOR_ERROR',
    error,
});


export const hideVendor = vendorId => ({
    type: 'DEACTIVATE_VENDOR',
    vendorId,
});

export const unhideVendor = vendorId => ({
    type: 'ACTIVATE_VENDOR',
    vendorId,
});

export const unhideVendorError = error => ({
    type: 'ACTIVATE_VENDOR_ERROR',
    error,
});

export const hideVendorError = error => ({
    type: 'DEACTIVATE_VENDOR_ERROR',
    error,
});