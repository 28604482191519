import { updateEntryInArray } from '../utils/CommonFunctions';

export const INITIAL_STATE = {
    customers: [],
    customersById: {},
    qbAccounts: [],
    qbAccountsById: {},
    qbClasses: [],
    qbClassesById: {},
    expenseGroupings: [],
    expenseGroupingsByVendorId: {},
    inventoryItems: [],
    inventoryItemsById: {},
    payrollItems: [],
    allActivePayrollItems: [],
    serviceTypeItems: [],
    inventoryTypeItems: [],
    payrollItemsByEmployeeId: {},
    permissionsGroups: [],
    resourcesLoaded: false,
    isSavingPermissionsGroup: false,
};

export const businessResourcesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return INITIAL_STATE;
        case 'IMPERSONATE_STORE_CLEAR':
            return INITIAL_STATE;
        case 'SET_EXPENSE_GROUPINGS':
            return {
                ...state,
                expenseGroupings: action.payload,
            };
        case 'SET_PERMISSIONS_GROUPS':
            return {
                ...state,
                permissionsGroups: action.payload,
            };
        case 'RECEIVE_CUSTOMERS': {
            return {
                ...state,
                ...mergeEntities(action.payload, state, 'customers'),
            };
        }
        case 'RECEIVE_INVENTORY_ITEMS': {
            return {
                ...state,
                ...mergeEntities(action.payload, state, 'inventoryItems'),
            };
        }
        case 'RECEIVE_QB_ACCOUNTS': {
            return {
                ...state,
                ...mergeEntities(action.payload, state, 'qbAccounts'),
            };
        }
        case 'RECEIVE_QB_CLASSES': {
            return {
                ...state,
                ...mergeEntities(action.payload, state, 'qbClasses'),
            };
        }
        case 'RECEIVE_BUSINESS_RESOURCES': {
            const shouldReplace = action.options.replace;
            const mergeableResources = ['customers', 'inventoryItems', 'qbAccounts', 'qbClasses'];
            const allowedKeys = Object.keys(INITIAL_STATE);

            const newResources = {};
            for (let entityName in action.payload) {
                if (mergeableResources.includes(entityName)) {
                    Object.assign(
                        newResources,
                        mergeEntities(
                            action.payload[entityName],
                            shouldReplace ? INITIAL_STATE : state,
                            entityName
                        )
                    );
                } else if (allowedKeys.includes(entityName)) {
                    Object.assign(newResources, { [entityName]: action.payload[entityName] });
                }
            }

            return {
                ...state,
                ...newResources,
            };
        }
        case 'RECEIVE_EXPENSE_GROUPINGS_FOR_VENDOR': {
            return {
                ...state,
                expenseGroupingsByVendorId: {
                    ...state.expenseGroupingsByVendorId,
                    [action.vendorId]: action.expenseGroupings,
                },
            };
        }
        case 'RECEIVE_PAYROLL_ITEMS_FOR_EMPLOYEE': {
            return {
                ...state,
                payrollItemsByEmployeeId: {
                    ...state.payrollItemsByEmployeeId,
                    [action.employeeId]: action.payrollItems,
                },
            };
        }
        case 'RESOURCES_LOADED': {
            return {
                ...state,
                resourcesLoaded: true,
            };
        }
        case 'ADD_EXPENSE_GROUPING_SUCCESS': {
            const newEntry = action.expenseGrouping;
            let expenseGroupingsByVendorId = state.expenseGroupingsByVendorId;
            if (newEntry.vendor_id in expenseGroupingsByVendorId) {
                expenseGroupingsByVendorId = {
                    ...expenseGroupingsByVendorId,
                    [newEntry.vendor_id]: [
                        ...expenseGroupingsByVendorId[newEntry.vendor_id],
                        newEntry,
                    ],
                };
            }

            return {
                ...state,
                expenseGroupings: [...state.expenseGroupings, newEntry],
                expenseGroupingsByVendorId,
            };
        }
        case 'UPDATE_EXPENSE_GROUPING_SUCCESS': {
            const updatedEntry = action.expenseGrouping;
            let expenseGroupingsByVendorId = state.expenseGroupingsByVendorId;

            if (updatedEntry.vendor_id in state.expenseGroupingsByVendorId) {
                expenseGroupingsByVendorId = {
                    ...expenseGroupingsByVendorId,
                    [updatedEntry.vendor_id]: updateEntryInArray(
                        expenseGroupingsByVendorId[updatedEntry.vendor_id],
                        updatedEntry
                    ),
                };
            }

            return {
                ...state,
                expenseGroupings: updateEntryInArray(state.expenseGroupings, updatedEntry),
                expenseGroupingsByVendorId,
            };
        }
        case 'UPDATE_PERMISSIONS_GROUP':
        case 'ADD_PERMISSIONS_GROUP': {
            return {
                ...state,
                isSavingPermissionsGroup: true,
            };
        }
        case 'UPDATE_PERMISSIONS_GROUP_SUCCESS': {
            return {
                ...state,
                isSavingPermissionsGroup: false,
                permissionsGroups: updateEntryInArray(
                    state.permissionsGroups,
                    action.permissionsGroup
                ),
            };
        }
        case 'ADD_PERMISSIONS_GROUP_SUCCESS': {
            return {
                ...state,
                isSavingPermissionsGroup: false,
                permissionsGroups: action.permissionsGroups,
            };
        }
        case 'UPDATE_PERMISSIONS_GROUP_ERROR':
        case 'ADD_PERMISSIONS_GROUP_ERROR': {
            return {
                ...state,
                isSavingPermissionsGroup: false,
            };
        }
        case 'DELETE_PERMISSIONS_GROUP_SUCCESS': {
            let permissionsGroups = [...state.permissionsGroups];
            for (let index in permissionsGroups) {
                if (action.payload.permission_group_id === permissionsGroups[index].id) {
                    permissionsGroups.splice(index, 1);
                }
            }
            return {
                ...state,
                permissionsGroups,
            };
        }
        default:
            return state;
    }
};

export function mergeEntities(entities, state, entityName) {
    const ids = [...state[entityName]];
    const byId = entities.reduce(
        (all, entity) => {
            if (entity.id in all) {
                all[entity.id] = { ...all[entity.id], ...entity };
            } else {
                all[entity.id] = { ...entity };
                ids.push(entity.id);
            }
            return all;
        },
        { ...state[`${entityName}ById`] }
    );

    return {
        [entityName]: ids,
        [`${entityName}ById`]: byId,
    };
}

export default businessResourcesReducer;
