export const addReportFilter = (reportFilter, callback) => ({
    type: 'ADD_REPORT_FILTER',
    reportFilter,
    callback,
});

export const addReportFiltersSucess = reportFilter => ({
    type: 'ADD_REPORT_FILTER_SUCCESS',
    reportFilter,
});

export const addReportFiltersFailure = reportFilter => ({
    type: 'ADD_REPORT_FILTER_FAILURE',
    reportFilter,
});

export const refreshReportFilters = (params, callback) => ({
    type: 'REFRESH_REPORT_FILTERS',
    params,
    callback,
});

export const refreshReportFiltersSuccess = () => ({
    type: 'REFRESH_REPORT_FILTERS_SUCCESS',
});

export const setReportFilter = (payload) => ({
    type: 'SET_REPORT_FILTER',
    payload
});

export const deleteReportFilters = (id, callback) => ({
    type: 'DELETE_REPORT_FILTERS',
    id,
    callback,
});

export const updateReportFilters = (id, reportFilter, callback) => ({
    type: 'UPDATE_REPORT_FILTERS',
    id,
    reportFilter,
    callback,
});

