import _ from 'lodash';
import React, { Component , useState } from 'react';
import Icon from 'react-icons-kit';
import { Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { repeat } from 'react-icons-kit/fa/repeat';
import { search } from 'react-icons-kit/fa/search';
import { arrow_left as chevronLeft } from 'react-icons-kit/ikons/arrow_left';
import { arrow_right as chevronRight } from 'react-icons-kit/ikons/arrow_right';
import { cross } from 'react-icons-kit/entypo/cross'
import { dollar } from 'react-icons-kit/fa/dollar';
import ReactTooltip from 'react-tooltip';
import { Row, Col } from 'react-bootstrap';

export default class ToggleOptionsSecondV extends Component {
    static defaultProps = {
        permissionsGroupId: '0',
        listOptions: null,
        labelForOption: o => o,
        valueForOption: o => o,
        labelForListOption: o => o.name,
        valueForListOption: o => o.id,
        subtitle: '',
    };
    
    constructor(props) {
        super(props);
        this.state = {
            firstSearchFilter: '',
            secondSearchFilter: '',
            payRate: 0
        };
    }


    renderList = (searchValue, list, onChange) => {
        if (_.isNil(list)) {
            return null;
        }
        list.sort((a, b) => (a.name > b.name ? 1 : -1));
        const { labelForListOption } = this.props;

        return list
            .filter(option =>
                labelForListOption(option)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            )
            .map(option => {
                const label = labelForListOption(option);

                return (
                    <div>
                        <ReactTooltip delayShow={500} type="info" multiline={true} id='toggleList' />
                          <div
                        key={label}
                        className="toggleSettingListItem"
                        onClick={() => onChange(option)}
                        data-tip="Click to move to filtered list"
                        data-for="toggleList"
                    >
                        {label}
                    </div>
                    </div>
                  
                );
            });
    };

    renderSecondList = (searchValue, list, onChange) => {
        if (_.isNil(list)) {
            return null;
        }
        list.sort((a, b) => (a.name > b.name ? 1 : -1));
        const { labelForListOption, valueForListOptionMap, refresh } = this.props;
        let value;
        return list
            .filter(option =>
                labelForListOption(option)
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            )
       
            .map(option => {
                const label = labelForListOption(option);   
                value = valueForListOptionMap.get(option["id"]);
                const handleInputChange = (event) => {
                    value = event.target.value;
                    let inputValue = event.target.value;
                    // Round the input value
                    inputValue = parseFloat(Number(inputValue).toFixed(2));
                    valueForListOptionMap.set(option['id'], inputValue);
                    event.target.value = inputValue;
                    this.props.onValueChange(valueForListOptionMap);
                  };
                return (
                    <div 
                        className="toggleSettingListItem"
                        style={{ width:"-webkit-fill-available"}}
                    >
                        <ReactTooltip delayShow={100} type="info" multiline={true} id='toggleList2' />
                        <Col sm={5} >
                        <div className='word-wrap-ellipsis' data-tip={label}
                        data-for="toggleList2">{label}</div>
                        </Col>
                        <Col sm={5} >
                        <InputGroup className="eAmount">
                            <InputGroupAddon addonType="prepend">
                                <Icon
                                    className="inputAddonPrependIconV2"
                                    size={12}
                                    icon={dollar}
                                />
                            </InputGroupAddon>
                            <Input
                                className="inputBilling"
                                type="number"
                                id="amount"
                                placeholder="0.00"
                                step="any"
                                onChange={handleInputChange}
                                value={value}

                            />
                        </InputGroup>
                        </Col>
                        <Col sm={1} style={{ marginLeft: '-19px' }} >
                        <Icon style={{ color: '#EF233C' }}
                            icon={cross}
                            size={15}
                            className="tableIcon"
                            data-tip="Cancel"
                            onClick={() => {
                                if (this.figureCanChange()) {
                                    this.handleListRemove([option]);
                                }
                            }}
                        />
                        </Col>
                       
              

                   
                    </div>
                );
            });
            
    };


    figureCanChange = () => {
        return (
            !('overrideValue' in this.props) ||
            this.props.overrideValue === 'Yes' ||
            parseInt(this.props.permissionsGroupId, 10) === 0
        );
    };

    handleListAdd = options => {
        const { listValue, valueForListOption } = this.props;
        const values = options.map(valueForListOption);
        this.props.valueForListOptionMap.set(values[0], '');
        this.props.onListChange(_.union(listValue, values));
    };

    handleListRemove = options => {
        const { listValue, valueForListOption } = this.props;
        const values = options.map(valueForListOption);

        this.props.onListChange(_.difference(listValue, values));
    };

    handleSelectAll = () => {
        const { listOptions, valueForListOption } = this.props;
        this.props.onListChange(listOptions.map(valueForListOption));
    };

    handleDeselectAll = () => {
        this.props.onListChange([]);
    };

    render() {
        const { listOptions, label, listValue, valueForListOption } = this.props;

        const { firstSearchFilter, secondSearchFilter } = this.state;

        let unselectedListOptions = [];
        let selectedListOptions = [];

        if (listOptions) {
            [selectedListOptions, unselectedListOptions] = _.partition(listOptions, o =>
                listValue.includes(valueForListOption(o))
            );
        }

        return (
            <div className="toggleSettingListContainerv2">
                <ReactTooltip delayShow={500} type="info" multiline={true} id='toggle' />
                <div className="toggleListAndSearchContainerV2">
                    <div className="toggleSearchContainer">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Icon
                                    className="inputAddonPrependIcon"
                                    size={15}
                                    icon={repeat}
                                    onClick={() =>
                                        this.setState({
                                            firstSearchFilter: '',
                                        })
                                    }
                                    data-tip="Reset Filter"
                                    data-for="toggle"
                                />
                            </InputGroupAddon>
                            <Input
                                value={firstSearchFilter}
                                className="searchBar"
                                onChange={searchText =>
                                    this.setState({
                                        firstSearchFilter: searchText.target.value,
                                    })
                                }
                            />
                            <InputGroupAddon addonType="append">
                                <Icon
                                    className="inputAddonAppendIcon"
                                    size={15}
                                    icon={search}
                                    data-tip="Filter List"
                                    data-for="toggle"
                                />
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="toggleSettingListV2">
                        {this.renderList(firstSearchFilter, unselectedListOptions, option =>
                            this.handleListAdd([option])
                        )}
                    </div>
                </div>
                <div className="toggleSettingIconContainerV2">
                    <div>
                        <Icon
                            icon={chevronLeft}
                            size={32}
                            className="tableIcon"
                            data-tip="Deselect All"
                            data-for="toggle"
                            onClick={() => {
                                if (this.figureCanChange()) {
                                    this.handleDeselectAll();
                                }
                            }}
                        />
                    </div>
                    <div>
                        <Icon
                            icon={chevronRight}
                            size={32}
                            className="tableIcon"
                            data-tip="Select All"
                            data-for="toggle"
                            onClick={() => {
                                if (this.figureCanChange()) {
                                    this.handleSelectAll();
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="toggleListAndSearchContainerV2">
                    <div className="toggleSearchContainer">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Icon
                                    className="inputAddonPrependIcon"
                                    size={15}
                                    icon={repeat}
                                    onClick={() =>
                                        this.setState({
                                            secondSearchFilter: '',
                                        })
                                    }
                                    data-tip="Reset Filter"
                                    data-for="toggle"
                                />
                            </InputGroupAddon>
                            <Input
                                value={secondSearchFilter}
                                className="searchBar"
                                onChange={searchText =>
                                    this.setState({
                                        secondSearchFilter: searchText.target.value,
                                    })
                                }
                            />
                            <InputGroupAddon addonType="append">
                                <Icon
                                    className="inputAddonAppendIcon"
                                    size={15}
                                    icon={search}
                                    data-tip="Filter Selected List"
                                    data-for="toggle"
                                />
                            </InputGroupAddon>
                        </InputGroup>
                    </div>
                    <div className="toggleSettingListV2">
                        <Row>
                        {this.renderSecondList(secondSearchFilter, selectedListOptions,
                            //     option => this.handleListRemove([option])
                        )}
                        </Row>
                       
                    </div>
                </div>


            </div>
        );
    }
}
