import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { InputGroup, Input, InputGroupAddon } from 'reactstrap';
import Icon from 'react-icons-kit';
import { arrow_left as chevronLeft } from 'react-icons-kit/ikons/arrow_left';
import { arrow_right as chevronRight } from 'react-icons-kit/ikons/arrow_right';
import { ic_date_range as calendarIcon } from 'react-icons-kit/md/ic_date_range';
import classNames from 'classnames';
import moment from 'moment';
import { expandDateToWeekRange } from '../../utils/DateUtils.js';

import '../../css/DateTimePicker.css';

const DATE_FORMATS = ['MM-DD-YY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'MM-DD', 'DD-MM', 'MMM DD'];

class DateTimePicker extends PureComponent {
    constructor(props) {
        super(props);

        const date = this.props.value
            ? moment(this.props.value, DATE_FORMATS).toDate()
            : new Date();
        this.state = {
            isCalendarHidden: true,
            date,
            tempDate: date,
            selectedDate: date,
            isDateFormattedCorrectly: true,
        };
        this.isUserTyping = false;
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
        document.addEventListener('keydown', this.handleKeyPressed, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
        document.removeEventListener('keydown', this.handleKeyPressed, false);
    }

    componentWillReceiveProps(props) {
        const newDate = props.value ? moment(props.value, DATE_FORMATS) : moment();

        if (!newDate.isSame(this.state.date)) {
            this.setState({
                date: newDate.toDate(),
                selectedDate: newDate.toDate(),
                tempDate: newDate.toDate(),
            });
        }
    }

    open = () => {
        this.setState({
            isCalendarHidden: false,
        });
    };

    handleUserTypingDate = value => {
        this.isUserTyping = true;
        this.setState({
            tempDate: value.target.value,
        });
    };

    handleInputOnBlur = () => {
        this.isUserTyping = false;
        // Check to see if we can parse the date to a new date
        const parsedDate = moment(this.state.tempDate, DATE_FORMATS);
        if (parsedDate.isValid()) {
            this.selectDate(parsedDate.toDate());
        } else {
            this.setState({
                tempDate: this.state.selectedDate,
            });
        }
    };

    handleClick = event => {
        if (this.state.isCalendarHidden) {
            return;
        }

        if (this.dateTimePicker === null || this.dateTimePicker.contains(event.target)) {
            return;
        }

        this.setState({
            isCalendarHidden: true,
        });
    };

    handleDateClicked = date => {
        this.selectDate(date);
        this.setState({ isCalendarHidden: true });
    };

    handleKeyPressed = event => {
        if (this.isUserTyping && event.code === 'Enter') {
            if (this.inputRef) {
                this.inputRef.blur();
            }

            this.setState({
                isCalendarHidden: true,
            });
        }

        if (event.code === 'Tab') {
            this.setState({
                isCalendarHidden: true,
            });
        }
    };

    selectDate = date => {
        if (this.props.mode === 'week') {
            const range = expandDateToWeekRange(date, this.props.weekOffset);
            this.props.onChange([moment(range.startDate).format(), moment(range.endDate).format()]);
        } else {
            this.props.onChange(moment(date).format());
        }
        this.setState({
            selectedDate: date,
            tempDate: date,
        });
    };

    previousMonth = () => {
        const date = moment(this.state.date)
            .subtract(1, 'month')
            .toDate();
        this.setState({
            date,
        });
    };

    nextMonth = () => {
        const date = moment(this.state.date)
            .add(1, 'month')
            .toDate();
        this.setState({
            date,
        });
    };

    classNameForDate = date => {
        const { weekOffset, mode } = this.props;
        const { date: referenceDate } = this.state;

        const selectedDate = moment(this.state.selectedDate);
        const cellDate = moment(date);

        const isSelected = selectedDate.isSame(cellDate, 'day');

        const classes = {
            calendarCell: true,
            selectedDayCell: isSelected,
            notThisMonthCell: !cellDate.isSame(referenceDate, 'month'),
        };

        if (mode === 'week') {
            const selectedWeekRange = expandDateToWeekRange(selectedDate.toDate(), weekOffset);
            const weekRange = expandDateToWeekRange(cellDate.toDate(), weekOffset);
            classes.selectedDayCell = cellDate.isBetween(
                selectedWeekRange.startDate,
                selectedWeekRange.endDate,
                'day',
                '[]'
            );
            classes.weekStart = cellDate.isSame(weekRange.startDate, 'day');
            classes.weekMiddle = cellDate.isBetween(
                weekRange.startDate,
                weekRange.endDate,
                'day',
                '()'
            );
            classes.weekEnd = cellDate.isSame(weekRange.endDate, 'day');
        }

        return classNames(classes);
    };

    buildCalendar = () => {
        const { date } = this.state;
        const { weekOffset, mode } = this.props;

        // put together an array of all dates for the currently-selected month
        // TODO: memoize this part, so we don't rebuild the date list on every render
        const firstWeek = expandDateToWeekRange(moment(date).startOf('month'), weekOffset);
        const lastWeek = expandDateToWeekRange(moment(date).endOf('month'), weekOffset);

        const dates = [];
        const startMoment = moment(firstWeek.startDate);

        while (!startMoment.isAfter(lastWeek.endDate, 'day')) {
            dates.push({
                date: startMoment.toDate(),
                formattedDate: startMoment.format('D'),
                className: this.classNameForDate(startMoment.toDate()),
            });
            startMoment.add(1, 'd');
        }

        const rows = _.chunk(dates, 7);

        // to compute week headings, take first row and get only day names
        const formattedWeekdays = rows[0].map(d => moment(d.date).format('dd'));

        return (
            <div>
                <div className="calendarHeader">
                    <Icon
                        onClick={this.previousMonth}
                        className="moveMonthIcon previousMonthIcon"
                        icon={chevronLeft}
                        size={22}
                        data-tip="Previous Month"
                    />
                    <div className="calendarMonth">{moment(date).format('MMMM, Y')}</div>
                    <Icon
                        onClick={this.nextMonth}
                        className="moveMonthIcon nextMonthIcon"
                        icon={chevronRight}
                        size={22}
                        data-tip="Next Month"
                    />
                </div>
                <table
                    className={classNames({
                        calendarTable: true,
                        calendarModeWeek: mode === 'week',
                    })}
                >
                    <tbody>
                        <tr className="calendarDayLabels">
                            {formattedWeekdays.map(day => (
                                <th className="calendarDayLabelCell" key={day}>
                                    {day}
                                </th>
                            ))}
                        </tr>
                        {rows.map((dates, i) => {
                            return (
                                <tr className="calendarWeek" key={`week-${i}`}>
                                    {dates.map(({ date, formattedDate, className }, j) => {
                                        return (
                                            <td
                                                key={`weekday-${i}-${j}`}
                                                className={className}
                                                onClick={this.handleDateClicked.bind(this, date)}
                                            >
                                                {formattedDate}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    handleTodayPressed = () => {
        const today = moment().startOf('day');
        this.selectDate(today.toDate());
        this.setState({
            isCalendarHidden: true,
        });
    };

    render() {
        const { toggleStyle, toggleLabel, className, todayButtonLabel, mode } = this.props;

        return (
            <div
                className={classNames('dateTimePicker', className)}
                ref={c => (this.dateTimePicker = c)}
            >
                {toggleStyle === 'input' && (
                    <InputGroup>
                        <Input
                            type="text"
                            className="dateTimePickerInput"
                            value={
                                this.isUserTyping
                                    ? this.state.tempDate
                                    : moment(this.state.tempDate).format('Y-MM-DD')
                            }
                            onChange={this.handleUserTypingDate}
                            onBlur={this.handleInputOnBlur}
                            onFocus={this.open}
                            innerRef={c => (this.inputRef = c)}
                        />
                        <InputGroupAddon addonType="append">
                            <Icon
                                onClick={this.open}
                                className="inputAddonAppendIcon"
                                size={17}
                                icon={calendarIcon}
                                data-tip={toggleLabel}
                            />
                        </InputGroupAddon>
                    </InputGroup>
                )}
                {toggleStyle === 'icon' && (
                    <Icon
                        onClick={this.open}
                        className="noInputCalendar calendarIcon"
                        size={20}
                        icon={calendarIcon}
                        data-tip={toggleLabel}
                    />
                )}
                {toggleStyle === 'labelAndIcon' && (
                    <button onClick={this.open} className="tertiaryButton">
                        {toggleLabel}
                        <Icon
                            className="calendarIcon iconAfterLabel"
                            size={20}
                            icon={calendarIcon}
                        />
                    </button>
                )}
                <div className="calendarDropdownContainer">
                    <div className="calendarDropdown" hidden={this.state.isCalendarHidden}>
                        <div className="calendar">
                            {this.buildCalendar()}
                            {mode === 'week' && (
                                <div className="calendarWeekOfInputContainer">
                                    <label className="label">Week Of</label>
                                    <Input
                                        type="text"
                                        className="calendarWeekOfInput"
                                        value={
                                            this.isUserTyping
                                                ? this.state.tempDate
                                                : moment(this.state.tempDate).format('Y-MM-DD')
                                        }
                                        onChange={this.handleUserTypingDate}
                                        onBlur={this.handleInputOnBlur}
                                        innerRef={c => (this.inputRef = c)}
                                    />
                                </div>
                            )}
                            <div
                                className="dateTimePickerToday secondaryButton"
                                onClick={this.handleTodayPressed}
                            >
                                {todayButtonLabel}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DateTimePicker.defaultProps = {
    mode: 'day',
    toggleStyle: 'input',
    toggleLabel: 'Open Date Picker',
    todayButtonLabel: 'Today',
    weekOffset: '0',
};

const mapStateToProps = state => ({
    weekOffset: _.get(state.accountUserStore, 'week_start_offset'),
});

export default connect(mapStateToProps)(DateTimePicker);
