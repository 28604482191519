import React from 'react';

const WhatIsAssociatedVendor = () => (
    <div>
        <div className="sectionQuestion">
            What is an "Associated Vendor for expense tracking in QuickBooks"?
        </div>
        <div className="sectionAnswer">
            The "Vendor for expense tracking" drop-down list allows you to select which Vendor the
            current user is associated with for expense tracking. If the user only has permission to
            view and edit his or her own expenses, then the user will only be able to enter and
            track expenses for the Vendor you set as the "Associated Vendor for expense tracking in
            QuickBooks". If the user can view, edit, and approve expenses for multiple Vendors, you
            can select a Vendor as the default Vendor for this user in expense tracking, but the
            user will be able to view and add expenses for the other Vendors as well.
        </div>
    </div>
);

export default WhatIsAssociatedVendor;
