import React from 'react';

const WhichMobileDevices = () => (
    <div className="helpModal">
        <div className="sectionQuestion">
            Which mobile phones support Mobile Minute7? Will it work on my phone?
        </div>
        <div className="sectionAnswer">
            Mobile Minute7 is intended to run on Android and iOS devices.
            <ul>
                <li>iOS devices running iOS 10.0 or later</li>
                <li>Android devices running Android 5.0 or later</li>
            </ul>
        </div>
    </div>
);

export default WhichMobileDevices;
