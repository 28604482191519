import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { parseUrlVariables } from '../../utils/CommonFunctions';
import { loginFromSession } from '../../actions/Actions';
import { SiteLoading } from '../../views/components/SiteLoading';

const ProtectedRoute = ({
    isLoggedIn,
    isLoggingIn,
    resourcesLoaded,
    requiresPermissions,
    currentUser,
    account,
    component: Component,
    render,
    location,
    loginFromSession,
    ...rest
}) => {
    let error;
    let redirect = '/';
    let shouldRender = false;
    const queryParams = parseUrlVariables({ location });

    if (isLoggingIn) {
        return <SiteLoading />;
    }

    if (isLoggedIn) {
        shouldRender = true;

        if (requiresPermissions.length > 0) {
            // we want to fail early in this case, which is why
            // we're checking if a permission check has failed
            const notAllowed = requiresPermissions.some(check => {
                const result = check(currentUser, account);
                if (!result.isAllowed) {
                    error = result.errorWhenNotAllowed;
                    if (result.redirectWhenNotAllowed) {
                        redirect = result.redirectWhenNotAllowed;
                    }
                }
                return !result.isAllowed;
            });

            shouldRender = shouldRender && !notAllowed;
        }
    } else if ('sid' in queryParams && 'sname' in queryParams && 'email' in queryParams) {
        loginFromSession({
            sessionId: queryParams.sid,
            sessionName: queryParams.sname,
            email: queryParams.email,
        });

        return <SiteLoading />;
    } else {
        error = 'You must be logged in to view that page.';
        redirect = '/login';
    }

    return (
        <Route
            {...rest}
            render={props =>
                shouldRender ? (
                    typeof render === 'function' ? (
                        render(props)
                    ) : (
                        <Component {...props} />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: redirect,
                            state: { from: props.location, error },
                        }}
                    />
                )
            }
        />
    );
};

ProtectedRoute.propTypes = {
    requiresPermissions: PropTypes.arrayOf(PropTypes.func),
};

ProtectedRoute.defaultProps = {
    requiresPermissions: [],
};

const mapStateToProps = ({ appStore, accountUserStore: currentUser, accountStore: account }) => {
    return {
        isLoggedIn: !_.isNil(appStore.session_id),
        isLoggingIn: appStore.isLoggingIn,
        currentUser,
        account,
    };
};

const mapDispatchToProps = {
    loginFromSession,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ProtectedRoute)
);
