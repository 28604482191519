import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { isNameValid, isValidEmail } from '../../../utils/CommonFunctions.js';
import { Row, Col } from 'react-bootstrap';
import { accountUserFields, employeeFields } from '../../../config/Fields.js';
import { showToast } from '../../../actions/Actions.js';
import { addVendor } from '../../../actions/VendorActions';
import 'react-phone-input-2/lib/style.css';
import Icon from 'react-icons-kit';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';
import { informationCircled as info } from 'react-icons-kit/ionicons/informationCircled';
import { selectVendor, selectEmployee } from '../../../actions/Actions.js';
import { addUser } from '../../../actions/AccountUserActions';
import ToggleSettingList from '../../components/ToggleSettingList.js';
import Spinner from '../../components/Spinner.js';
import ReactTooltip from 'react-tooltip';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';

class NewVendorForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState();
    }

    componentWillReceiveProps(props) {
        if (props.isSaving !== this.props.isSaving && !props.isSaving) {
            this.props.history.goBack();
        }
    }

    defaultState = () => {
        const { accountId, showVendors } = this.props;

        return {
            fNameInvalidMsg: '',
            mNameInvalidMsg: '',
            lNameInvalidMsg: '',
            emailInvalidMsg: '',
            userSettings: 'default',
            defaultUserCreation: false,
            defaultTimeTracking: false,
            account_id: accountId,
            email: '',
            name: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            type: '',
            hasBeenEdited: false,
            hidden: false,
            save_message: 'Save',
            make_employee_user: false,
            employee_id: '0',
            expense_vendor_id: '0',
            phone: '',
            validation_msg: '',
            week_start_offset: 0,
            timesheet_grouping: 'week',
            time_entry_reminder_frequency: 'Never',
            time_entry_reminder_minimum_hours: 0,
            export_reminder_interval: 'Never',
            permission_group_id: '0',
            approve_others_expenses: 'No',
            approve_others_time: 'No',
            edit_others_expenses: 'No',
            edit_others_time: 'No',
            edit_own_expenses: 'No',
            edit_own_time: 'No',
            edit_payroll: 'No',
            export_time: 'No',
            manage_account: 'No',
            manage_users: 'No',
            manage_api: 'No',
            view_others_expenses: 'No',
            view_others_time: 'No',
            view_reports: 'No',
            clock_in_only: 'No',
            filter_view_employee_ids: [],
            filter_edit_employee_ids: [],
            filter_approve_employee_ids: [],
            filter_view_expense_vendor_ids: [],
            filter_edit_expense_vendor_ids: [],
            filter_approve_expense_vendor_ids: [],
            filter_customer_list: 'No',
            filter_customer_ids: [],
            filter_inventory_item_list: 'No',
            filter_inventory_item_ids: [],
            filter_qb_account_list: 'No',
            filter_qb_account_ids: [],
            filter_qb_class_list: 'No',
            filter_qb_class_ids: [],
            approve_others_expenses_override_group: 'No',
            approve_others_time_override_group: 'No',
            edit_others_expenses_override_group: 'No',
            edit_others_time_override_group: 'No',
            edit_own_expenses_override_group: 'No',
            edit_own_time_override_group: 'No',
            edit_payroll_override_group: 'No',
            manage_payroll_payrates_override_group: 'No',
            manage_item_billrates_override_group: 'No',
            export_time_override_group: 'No',
            filter_approve_employee_ids_override_group: 'No',
            filter_approve_expense_vendor_ids_override_group: 'No',
            filter_customer_ids_override_group: 'No',
            filter_customer_list_override_group: 'No',
            filter_edit_employee_ids_override_group: 'No',
            filter_edit_expense_vendor_ids_override_group: 'No',
            filter_inventory_item_ids_override_group: 'No',
            filter_inventory_item_list_override_group: 'No',
            filter_qb_account_ids_override_group: 'No',
            filter_qb_account_list_override_group: 'No',
            filter_qb_class_ids_override_group: 'No',
            filter_qb_class_list_override_group: 'No',
            filter_view_employee_ids_override_group: 'No',
            filter_view_expense_vendor_ids_override_group: 'No',
            manage_account_override_group: 'No',
            manage_expenses_override_group: 'No',
            manage_time_override_group: 'No',
            manage_users_override_group: 'No',
            manage_api_override_group: 'No',
            restrictions_override_group: 'No',
            view_others_expenses_override_group: 'No',
            view_others_time_override_group: 'No',
            view_reports_override_group: 'No',
            send_invite_sms: false,
            spinner_loader: false,
            show_vendors_timeSheet: showVendors,
        };
    };

    constructVendor = () => {
        const data = {};
        const dataFields = this.state.make_employee_user ? accountUserFields : employeeFields;
        dataFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else if (field === 'email') {
                    data[field] = this.state[field].trim().toLowerCase();
                } else {
                    data[field] = this.state[field];
                }
            }
        });
        return data;
    };

    checkRequiredFields = () => {
        const { first_name, last_name, name, email, defaultUserCreation } = this.state;

        if (defaultUserCreation && !isValidEmail(email.trim())) {
            this.props.showToast('Please enter a valid email address for this user', 'warning');
            return false;
        }
        if (last_name === '' || (defaultUserCreation && first_name === '') || name === '') {
            if (defaultUserCreation) {
                this.props.showToast(
                    'Please fill out both First, Last and Display Name',
                    'warning'
                );
            }
            if (!defaultUserCreation) {
                this.props.showToast('Please fill out Last Name/Merchant Name', 'warning');
            }

            return false;
        }
        return true;
    };

    saveVendor = event => {
        event.preventDefault();
        if (
            this.state.emailInvalidMsg !== '' ||
            this.state.fNameInvalidMsg !== '' ||
            this.state.mNameInvalidMsg !== '' ||
            this.state.lNameInvalidMsg !== ''
        ) {
            this.props.showToast('Please Fix the form error before submit', 'warning');
        } else {
            if (!this.checkRequiredFields()) {
                return;
            }
            this.setState({ spinner_loader: true });
            const data = this.constructVendor();
            this.props.addVendor(data, newVendor => {
                if (newVendor === false) {
                    this.setState({ spinner_loader: false, make_employee_user: false });
                    return;
                }
                if (this.state.defaultUserCreation) {
                    this.setState({
                        expense_vendor_id: newVendor.id.toString(),
                        make_employee_user: true,
                    });
                    if (this.state.defaultTimeTracking && this.props.showVendors === 'Yes') {
                        this.setState({ employee_id: newVendor.id.toString() + '_v' });
                    }
                    const userData = this.constructVendor();
                    if (this.state.userSettings === 'default') {
                        this.props.addUser(userData, newUser => {
                            if (newUser === false) {
                                this.setState({ spinner_loader: false, make_employee_user: false });
                                return;
                            }
                            this.props.showToast(
                                `New Vendor ${newUser.first_name} ${
                                    newUser.last_name
                                } and its user created`,
                                'positive'
                            );
                            this.setState({
                                spinner_loader: false,
                                employee_id: '0',
                                email: '',
                                name: '',
                                first_name: '',
                                middle_name: '',
                                last_name: '',
                                reference_number: '',
                            });
                        });
                    } else {
                        if (this.state.defaultTimeTracking && this.props.showVendors === 'Yes') {
                            this.props.selectEmployee(newVendor.id.toString() + '_v');
                        }
                        this.props.selectVendor(newVendor.id.toString());
                        this.props.history.push('/create_account_user');
                        this.props.showToast(`New Vendor ${newVendor.name} created`, 'positive');
                    }
                } else {
                    this.props.showToast(`New Vendor ${newVendor.name} created`, 'positive');
                    this.setState({
                        spinner_loader: false,
                        employee_id: '0',
                        email: '',
                        name: '',
                        first_name: '',
                        middle_name: '',
                        last_name: '',
                        reference_number: '',
                    });
                }
            });
        }
    };

    setFields = fields => {
        this.setState({ ...fields, hasBeenEdited: true });
    };

    handleDefaultUserCheckOnChange = defaultUserCreation => {
        this.setState({ defaultUserCreation: defaultUserCreation });
        if (defaultUserCreation && this.state.userSettings === 'default') {
            this.setState({ save_message: 'Save + Send Invite' });
        } else {
            this.setState({ save_message: 'Save' });
        }
    };
    handleDefaultTimeTrackingCheckOnChange = defaultTimeTracking => {
        this.setState({ defaultTimeTracking: defaultTimeTracking });
    };

    handleOnChanges = {
        handleFirstNameOnChange: newName => {
            this.setState({ name: `${newName.target.value} ${this.state.last_name}` });
            this.setState({ first_name: newName.target.value });
        },
        handleMiddleNameOnChange: newName => {
            this.setState({ middle_name: newName.target.value });
        },
        handleLastNameOnChange: newName => {
            this.setState({ name: `${this.state.first_name} ${newName.target.value}` });
            this.setState({ last_name: newName.target.value });
        },
        handleEmailOnChange: newEmail => {
            this.setState({ email: newEmail.target.value });
        },
    };
    render() {
        const {
            email,
            first_name,
            middle_name,
            last_name,
            defaultUserCreation,
            defaultTimeTracking,
            userSettings,
            save_message,
            show_vendors_timeSheet,
            spinner_loader,
        } = this.state;

        return (
            <div className="applicationWrapper">
                <div className="application">
                    <div className="ftContainer cardContainer">
                        <form className="ftForm" onSubmit={this.saveVendor}>
                            <Row className="ftRowAlightCenter">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Create New Vendor
                                </div>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                First Name{' '}
                                            </div>
                                            {defaultUserCreation && (
                                                <div className="required">*</div>
                                            )}
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.fNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="first_name"
                                                value={first_name}
                                                onChange={
                                                    this.handleOnChanges.handleFirstNameOnChange
                                                }
                                                onBlur={
                                                    first_name.length !== 0
                                                        ? () =>
                                                              isNameValid(first_name)
                                                                  ? this.setFields({
                                                                        fNameInvalidMsg: '',
                                                                    })
                                                                  : this.setFields({
                                                                        fNameInvalidMsg:
                                                                            'First name contains only  1 to 50 alphabets.',
                                                                    })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.fNameInvalidMsg === '' &&
                                                !first_name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.fNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                Last Name/Merchant Name{' '}
                                            </div>
                                            <div className="required">*</div>
                                        </Row>
                                        <div>
                                            <Input
                                                className={
                                                    this.state.lNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="last_name"
                                                value={last_name}
                                                onChange={
                                                    this.handleOnChanges.handleLastNameOnChange
                                                }
                                                onBlur={
                                                    last_name.length !== 0
                                                        ? () =>
                                                              isNameValid(last_name)
                                                                  ? this.setFields({
                                                                        lNameInvalidMsg: '',
                                                                    })
                                                                  : this.setFields({
                                                                        lNameInvalidMsg:
                                                                            'Last name contains only  1 to 50 alphabets.',
                                                                    })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.lNameInvalidMsg === '' &&
                                                !last_name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.lNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                Middle Name{' '}
                                            </div>
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.mNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="middle_name"
                                                value={middle_name}
                                                onChange={
                                                    this.handleOnChanges.handleMiddleNameOnChange
                                                }
                                                onBlur={
                                                    middle_name.length !== 0
                                                        ? () =>
                                                              isNameValid(middle_name)
                                                                  ? this.setFields({
                                                                        mNameInvalidMsg: '',
                                                                    })
                                                                  : this.setFields({
                                                                        mNameInvalidMsg:
                                                                            'Middle name contains only  1 to 50 alphabets.',
                                                                    })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.mNameInvalidMsg === '' &&
                                                !middle_name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.mNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                Email{' '}
                                            </div>
                                            {defaultUserCreation && (
                                                <div className="required">*</div>
                                            )}
                                        </Row>
                                        <div>
                                            <Input
                                                className={
                                                    this.state.emailInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="email"
                                                value={email}
                                                onChange={this.handleOnChanges.handleEmailOnChange}
                                                onBlur={
                                                    email.length !== 0
                                                        ? () =>
                                                              isValidEmail(email)
                                                                  ? this.setFields({
                                                                        emailInvalidMsg: '',
                                                                    })
                                                                  : this.setFields({
                                                                        emailInvalidMsg:
                                                                            'Email must be between to 4 to 40 characters (e.g. example@minute7.com)',
                                                                    })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.emailInvalidMsg === '' &&
                                                !email === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.emailInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <ReactTooltip
                                        className="tooltip-override"
                                        data-for="UserCreation"
                                        type="info"
                                        multiline={true}
                                        data-html={true}
                                    />
                                    <div className="fFilterCheckboxContainer">
                                        <div className="fFilterByDateCheckbox">
                                            <Icon
                                                className="blueIcon"
                                                size={18}
                                                icon={
                                                    defaultUserCreation
                                                        ? checkboxChecked
                                                        : checkboxUnchecked
                                                }
                                                onClick={() =>
                                                    this.handleDefaultUserCheckOnChange(
                                                        !defaultUserCreation
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            className="fEnableDateRangeText"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            Add as a user
                                        </div>
                                        <Icon
                                            style={{ paddingLeft: '4px' }}
                                            className="blueIcon fEnableDateRangeText"
                                            size={16}
                                            data-tip="Selecting this option will auto create Minute 7 user account for this employee."
                                            icon={info}
                                            id="UserCreation"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {defaultUserCreation && show_vendors_timeSheet === 'Yes' && (
                                <Row className="ftrow">
                                    <Col sm={6} style={{ marginBottom: '5px' }}>
                                        <ReactTooltip
                                            className="tooltip-override"
                                            data-for="UserCreation"
                                            type="info"
                                            multiline={true}
                                            data-html={true}
                                        />
                                        <div className="fFilterCheckboxContainer">
                                            <div className="fFilterByDateCheckbox">
                                                <Icon
                                                    className="blueIcon"
                                                    size={18}
                                                    icon={
                                                        defaultTimeTracking
                                                            ? checkboxChecked
                                                            : checkboxUnchecked
                                                    }
                                                    onClick={() =>
                                                        this.handleDefaultTimeTrackingCheckOnChange(
                                                            !defaultTimeTracking
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="fEnableDateRangeText"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Allow time tracking
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            {defaultUserCreation && (
                                <div className="ftLabelAndInput">
                                    <ReactTooltip
                                        className="tooltip-override"
                                        data-for="permission"
                                        type="info"
                                        multiline={true}
                                        data-html={true}
                                    />
                                    <ToggleSettingList
                                        title={[
                                            <span style={{ fontWeight: 'bold' }}>
                                                Permission Settings{' '}
                                            </span>,
                                            <Icon
                                                className="blueIcon"
                                                size={16}
                                                data-tip="Default permission settings allow users to add and edit their timesheets. Choose customized settings for different permissions."
                                                icon={info}
                                                id="permission"
                                            />,
                                        ]}
                                        value={userSettings}
                                        options={[
                                            { label: 'Default', value: 'default' },
                                            { label: 'Customized', value: 'custom' },
                                        ]}
                                        valueForOption={option => option.value}
                                        labelForOption={option => option.label}
                                        onChange={value => {
                                            this.setState({
                                                userSettings: value,
                                            });
                                            if (value === 'default') {
                                                this.setState({
                                                    save_message: 'Save + Send Invite',
                                                });
                                            } else {
                                                this.setState({ save_message: 'Save' });
                                            }
                                        }}
                                    />
                                </div>
                            )}

                            <Row>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button type="submit" className="primaryButton ftButton">
                                            {save_message}
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button
                                            className="secondaryButton ftButton"
                                            onClick={() =>
                                                this.props.history.push(
                                                    '/resource_settings/vendors'
                                                )
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Spinner loading={spinner_loader} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        accountId: state.accountStore.id,
        showVendors: state.accountStore.show_vendors,
        accountUserStore: state.accountUserStore,
        isSaving: state.accountUserStore.isSaving,
    };
}

const mapDispatchToProps = {
    showToast,
    addVendor,
    addUser,
    selectVendor,
    selectEmployee,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewVendorForm);
