import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import Icon from 'react-icons-kit';
import { arrow_left as chevronLeft } from 'react-icons-kit/ikons/arrow_left';
import { arrow_right as chevronRight } from 'react-icons-kit/ikons/arrow_right';
import { ic_date_range as calendarIcon } from 'react-icons-kit/md/ic_date_range';
import moment from 'moment';
import classNames from 'classnames';
import { selectPreviousMonth, selectThisMonth } from '../../utils/DateUtils.js';

import '../../css/DateTimePicker.css';

const DATE_FORMATS = ['MM-DD-YY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'MM-DD', 'DD-MM', 'MMM DD'];
const CALENDAR_HEIGHT = ['0', '1', '2', '3', '4'];
const WEEKDAYS = ['0', '1', '2', '3', '4', '5', '6'];

export default class DateTimeRangePicker extends PureComponent {
    static propTypes = {
        startDate: PropTypes.instanceOf(Date),
        endDate: PropTypes.instanceOf(Date),
        onRangeChange: PropTypes.func.isRequired,
        onThisWeekPress: PropTypes.func.isRequired,
        todayButtonText: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isCalendarHidden: true,
            currentField: 'startDate',
            date: props.startDate,
            startDateIsValid: true,
            endDateIsValid: true,
            startDateString: moment(props.startDate).format('Y-MM-DD'),
            endDateString: moment(props.endDate).format('Y-MM-DD'),
        };
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
        document.addEventListener('keydown', this.handleKeyPressed, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
        document.removeEventListener('keydown', this.handleKeyPressed, false);
    }

    resetInputFieldsState = (start, end) => {
        this.setState({
            startDateString: moment(start).format('Y-MM-DD'),
            endDateString: moment(end).format('Y-MM-DD'),
            startDateIsValid: true,
            endDateIsValid: true,
        });
    };

    open = () => {
        if (this.state.isCalendarHidden) {
            this.setState(
                {
                    isCalendarHidden: false,
                },
                () => {
                    if (this.startDateInput) {
                        this.startDateInput.focus();
                    }
                }
            );

            this.resetInputFieldsState(this.props.startDate, this.props.endDate);
        }
    };

    selectPreviousMonth = () => {
        const startAndEndDate = selectPreviousMonth();
        this.props.onRangeChange(startAndEndDate.firstDay, startAndEndDate.lastDay);
        this.resetInputFieldsState(startAndEndDate.firstDay, startAndEndDate.lastDay);
    };

    selectThisMonth = () => {
        const startAndEndDate = selectThisMonth();
        this.props.onRangeChange(startAndEndDate.firstDay, startAndEndDate.lastDay);
        this.resetInputFieldsState(startAndEndDate.firstDay, startAndEndDate.lastDay);
    };

    handleClick = clickedItem => {
        if (this.dateTimePicker === null || this.dateTimePicker.contains(clickedItem.target)) {
            return;
        }
        this.setState({
            isCalendarHidden: true,
        });
    };

    handleKeyPressed = event => {
        if (event.key === 'Tab') {
            if (this.state.currentField === 'endDate') {
                this.setState({
                    isCalendarHidden: true,
                });
            }
        }

        if (event.key === 'Escape') {
            this.setState({
                isCalendarHidden: true,
            });
        }
    };

    selectDate = date => {
        if (this.state.currentField === 'startDate') {
            this.props.onRangeChange(date, this.props.endDate);
            this.setState({
                startDateString: moment(date).format('Y-MM-DD'),
                startDateIsValid: true,
                currentField: 'endDate',
            });
        }
        if (this.state.currentField === 'endDate') {
            this.props.onRangeChange(this.props.startDate, date);
            this.setState({
                endDateString: moment(date).format('Y-MM-DD'),
                endDateIsValid: true,
            });
        }
    };

    previousMonth = () => {
        const date = moment(this.state.date)
            .subtract(1, 'month')
            .toDate();
        this.setState({
            date,
        });
    };

    nextMonth = () => {
        const date = moment(this.state.date)
            .add(1, 'month')
            .toDate();
        this.setState({
            date,
        });
    };

    getFirstWeekdayOfThisMonth = () => {
        let year = this.state.date.getFullYear();
        let month = this.state.date.getMonth();
        let day = new Date(year, month, 1).getDay();
        return day;
    };

    getFirstWeekdayOfNextMonth = () => {
        let year = this.state.date.getFullYear();
        let month = this.state.date.getMonth() + 1;
        let day = new Date(year, month, 1).getDay();
        return day;
    };

    getNumberOfDaysInPreviousMonth = () => {
        var numberOfDays = new Date(this.state.date.getFullYear(), this.state.date.getMonth(), 0);
        return numberOfDays.getDate();
    };

    getNumberOfDaysInThisMonth = () => {
        var numberOfDays = new Date(
            this.state.date.getFullYear(),
            this.state.date.getMonth() + 1,
            0
        );
        return numberOfDays.getDate();
    };

    getNumberOfDaysInNextMonth = () => {
        var numberOfDays = new Date(
            this.state.date.getFullYear(),
            this.state.date.getMonth() + 2,
            0
        );
        return numberOfDays.getDate();
    };

    getCellDetails = (heightIndex, weekdayIndex) => {
        let firstDayOfThisMonth = this.getFirstWeekdayOfThisMonth();
        let cellValue = weekdayIndex - firstDayOfThisMonth + 1 + heightIndex * 7;
        let cellDetails = {
            date: new Date(this.state.date.getFullYear(), this.state.date.getMonth(), cellValue),
            cellValue: cellValue,
            month: 'this',
        };
        // First Week - show days of previous month
        if (heightIndex === 0) {
            let numberOfDaysInPreviousMonth = this.getNumberOfDaysInPreviousMonth();
            if (firstDayOfThisMonth > weekdayIndex) {
                cellDetails.cellValue =
                    numberOfDaysInPreviousMonth - (firstDayOfThisMonth - weekdayIndex - 1);
                cellDetails.month = 'previous';
            }
        }
        // Last week - show days of next month
        else if (heightIndex === 4) {
            if (
                weekdayIndex - firstDayOfThisMonth + 1 + heightIndex * 7 >
                this.getNumberOfDaysInThisMonth()
            ) {
                let firstDayOfNextMonth = this.getFirstWeekdayOfNextMonth();
                cellDetails.cellValue = weekdayIndex - firstDayOfNextMonth + 1;
                cellDetails.month = 'next';
            }
        }
        return cellDetails;
    };

    figureCellClass = cellDetails => {
        let classString = 'calendarCell';

        const cellDateTime = cellDetails.date.getTime();
        const startDateTime = this.props.startDate.getTime();
        const endDateTime = this.props.endDate.getTime();

        if (cellDateTime >= startDateTime && cellDateTime <= endDateTime) {
            classString += ' inBetweenStartAndEndDateCell';
        }

        if (cellDateTime === endDateTime) {
            classString += ' customEndDate';
        }

        if (cellDateTime === startDateTime) {
            classString += ' customStartDate';
        }

        classString += cellDetails.month !== 'this' ? ' notThisMonthCell' : '';

        return classString;
    };

    buildCalendar = () => {
        return (
            <div>
                <div className="calendarHeader">
                    <Icon
                        onClick={this.previousMonth}
                        className="moveMonthIcon previousMonthIcon"
                        icon={chevronLeft}
                        size={22}
                        data-tip="Previous Month"
                    />
                    <div className="calendarMonth">{moment(this.state.date).format('MMMM, Y')}</div>
                    <Icon
                        onClick={this.nextMonth}
                        className="moveMonthIcon nextMonthIcon"
                        icon={chevronRight}
                        size={22}
                        data-tip="Next Month"
                    />
                </div>
                <table className="calendarTable">
                    <tbody>
                        <tr className="calendarDayLabels">
                            <th className="calendarDayLabelCell">Su</th>
                            <th className="calendarDayLabelCell">Mo</th>
                            <th className="calendarDayLabelCell">Tu</th>
                            <th className="calendarDayLabelCell">We</th>
                            <th className="calendarDayLabelCell">Th</th>
                            <th className="calendarDayLabelCell">Fr</th>
                            <th className="calendarDayLabelCell">Sa</th>
                        </tr>
                        {CALENDAR_HEIGHT.map((weekNumber, heightIndex) => {
                            return (
                                <tr className="calendarWeek" key={`week-${weekNumber}`}>
                                    {WEEKDAYS.map((day, weekIndex) => {
                                        let cellDetails = this.getCellDetails(
                                            heightIndex,
                                            weekIndex
                                        );
                                        return (
                                            <td
                                                key={`weekday-${weekNumber}-${weekIndex}`}
                                                className={this.figureCellClass(cellDetails)}
                                                onClick={() => this.selectDate(cellDetails.date)}
                                            >
                                                {cellDetails.cellValue}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    handleStartDateChanged = event => {
        const date = moment(event.target.value, DATE_FORMATS);
        this.setState({ startDateString: event.target.value, startDateIsValid: date.isValid() });
        if (date.isValid()) {
            this.handleRangeChanged(date.toDate(), this.props.endDate);
        }
    };

    handleEndDateChanged = event => {
        const date = moment(event.target.value, DATE_FORMATS);
        this.setState({ endDateString: event.target.value, endDateIsValid: date.isValid() });
        if (date.isValid()) {
            this.handleRangeChanged(this.props.startDate, date.toDate());
        }
    };

    handleRangeChanged = _.debounce((start, end) => {
        this.props.onRangeChange(start, end);
    }, 250);

    handleFieldFocus = field => {
        this.setState({ currentField: field });
    };

    render() {
        const { onThisWeekPress } = this.props;

        const {
            currentField,
            startDateString,
            endDateString,
            startDateIsValid,
            endDateIsValid,
        } = this.state;

        return (
            <div
                className={classNames('dateTimePicker', this.props.className)}
                ref={dateTimePicker => (this.dateTimePicker = dateTimePicker)}
            >
                <Icon
                    onClick={this.open}
                    className="noInputCalendar calendarIcon"
                    size={20}
                    icon={calendarIcon}
                    data-tip="Open Date Picker"
                />
                <div className="rangePickerDropdown" hidden={this.state.isCalendarHidden}>
                    <div className="rangeOptions">
                        <div className="rangeTypeCustomContainer">
                            <label className="sectionLabel">Date Range:</label>
                            <div className="dateRangeInputs">
                                <div
                                    className={classNames({
                                        dateRangeInput: true,
                                        currentField: currentField === 'startDate',
                                    })}
                                >
                                    <label>Start Date</label>
                                    <Input
                                        innerRef={c => (this.startDateInput = c)}
                                        type="text"
                                        className={classNames({
                                            startDateInput: true,
                                            selectedCustomRangeInput: currentField === 'startDate',
                                            invalid: !startDateIsValid,
                                        })}
                                        value={startDateString}
                                        onChange={this.handleStartDateChanged}
                                        onFocus={this.handleFieldFocus.bind(this, 'startDate')}
                                    />
                                </div>
                                <div
                                    className={classNames({
                                        dateRangeInput: true,
                                        currentField: currentField === 'endDate',
                                    })}
                                >
                                    <label>End Date</label>
                                    <Input
                                        ref={endDateInput => (this.endDateInput = endDateInput)}
                                        type="text"
                                        className={classNames({
                                            endDateInput: true,
                                            selectedCustomRangeInput: currentField === 'endDate',
                                            invalid: !endDateIsValid,
                                        })}
                                        value={endDateString}
                                        onChange={this.handleEndDateChanged}
                                        onFocus={this.handleFieldFocus.bind(this, 'endDate')}
                                    />
                                </div>
                            </div>
                            <div className="customDateTemplateContainer">
                                <button
                                    onClick={this.selectPreviousMonth}
                                    className="secondaryButton customDateTemplateButton"
                                >
                                    Previous Month
                                </button>
                                &nbsp;&nbsp;
                                <button
                                    onClick={this.selectThisMonth}
                                    className="secondaryButton customDateTemplateButton"
                                >
                                    This Month
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="calendar">
                        {this.buildCalendar()}
                        <div
                            className="dateTimePickerToday secondaryButton"
                            onClick={onThisWeekPress}
                        >
                            {this.props.todayButtonText ? this.props.todayButtonText : 'Today'}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
