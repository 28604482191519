import { createQueryString } from '../utils/HelperFunctions.js';
import GoogleAnalytics from '../utils/GoogleAnalytics.js';

export const login = (email, password) => {
    GoogleAnalytics.login();
    let paramsObject = {
        email: email,
        password: password,
    };
    return fetch(createQueryString({}, 'login'), {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ user: paramsObject }),
    }).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    });
};

export const logout = sessionParams => {
    const params = {
        credentials: 'include',
    };
    return fetch(createQueryString(sessionParams, 'login/logout'), params).then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    });
};
