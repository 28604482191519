import React from 'react';
import { connect } from 'react-redux';
// import { getData } from '../../utils/HelperFunctions.js';s
import { impersonateUser } from '../../actions/AccountActions';

function Impersonate(props) {
    const userId = window.location.search.split('=')[1];
    const isAdmin = props.accountUserStore.is_admin;

    return (
        <div className="application">
            <div className="applicationBody">
                <div className="cardContainer cardContent">
                    {isAdmin && userId && (
                        <button
                            className="contactUsSendButton primaryButton"
                            onClick={() => props.impersonateUser(userId)}
                        >
                            Impersonate User id: {userId}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        accountUserStore: state.accountUserStore,
    };
}

export default connect(
    mapStateToProps,
    {
        impersonateUser,
    }
)(Impersonate);
