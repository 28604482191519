import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    updateExistingItem,
    createNewItem,
    getAllItemsForTable,
    activateItem,
    deactivateItem,
} from '../requests/ItemRequests';
import * as uiActions from '../actions/Actions';
import * as itemActions from '../actions/ItemAction';
import _ from 'lodash';
import { itemFields } from '../config/Fields';
import * as resourceActions from '../actions/BusinessResourcesActions';

const formDataForItem = item => {
    const data = {};
    _.forEach(_.pick(item, itemFields), (value, key) => {
        switch (key) {
            case 'account_id': {
                data['InventoryItem[account_id]'] = value;
                break;
            }
            case 'id': {
                data['InventoryItem[id]'] = value;
                break;
            }
            case 'type': {
                data['InventoryItem[type]'] = value;
                break;
            }
            case 'description': {
                data['InventoryItem[description]'] = value;
                break;
            }
            default: {
                data[`InventoryItem[${key}]`] = value;
            }
        }
    });
    return data;
};

export function* updateItem({ item, callback }) {
    try {
        const data = formDataForItem(item);
        const updatedItem = yield call(updateExistingItem, data);
        yield put(resourceActions.requestBusinessResources());
        callback(updatedItem);
    } catch (error) {
        console.log(error);
        callback(false);
        yield put(itemActions.updateItemError(error));
        yield put(uiActions.showToast('Couldn’t update this item. Try again later.', 'negative'));
    }
}

export function* addItem({ item, callback }) {
    try {
        const data = formDataForItem(item);
        const newItem = yield call(createNewItem, data);
        yield put(itemActions.addItemSuccess(newItem));
        yield put(resourceActions.requestBusinessResources());
        callback(newItem);
    } catch (error) {
        console.log(error);
        callback(false);
        yield put(itemActions.addItemError(error));
        yield put(uiActions.showToast('Couldn’t create new item', 'negative'));
    }
}

export function* addItemList() {
    try {
        const updatedItems = yield call(getAllItemsForTable);
        yield put(itemActions.setAllItems(updatedItems));
        yield put(uiActions.showToast('New item added', 'positive'));
    } catch (error) {
        console.log(error);
        yield put(itemActions.addItemListError(error));
        yield put(uiActions.showToast('Couldn’t get new items, please refresh page', 'negative'));
    }
}

export function* unhideItem({ itemId }) {
    try {
        yield call(activateItem, itemId);
        yield put(resourceActions.requestBusinessResources());
        yield put(uiActions.showToast('item has been activated', 'positive'));
    } catch (error) {
        yield put(itemActions.unhideItem(error));
        yield put(uiActions.showToast('Couldn’t activate this item. Try again later.', 'negative'));
    }
}

export function* hideItem({ itemId }) {
    try {
        yield call(deactivateItem, itemId);
        yield put(resourceActions.requestBusinessResources());
        yield put(uiActions.showToast('item has been deactivated', 'positive'));
    } catch (error) {
        yield put(itemActions.hideItem(error));
        yield put(
            uiActions.showToast('Couldn’t deactivate this item. Try again later.', 'negative')
        );
    }
}

export default function* root() {
    yield all([
        takeLatest(['UPDATE_ITEM'], updateItem),
        takeLatest(['ADD_ITEM'], addItem),
        takeLatest(['ADD_ITEM_LIST'], addItemList),
        takeLatest(['ACTIVATE_ITEM'], unhideItem),
        takeLatest(['DEACTIVATE_ITEM'], hideItem),
    ]);
}
