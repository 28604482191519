import React, { Component } from 'react';
import { Input } from 'reactstrap';
import SaveLoading from '../../components/SaveLoading.js';

export default class DefaultLogin extends Component {
    render() {
        return (
            <div className="full">
                <div className="lContainer">
                    <div className="lSignInText">Sign In</div>
                    <div className="lInputContainer cardContainer">
                        <div className="lLabelAndInput">
                            <div className="lLabel">Email</div>
                            <Input
                                id="emailInput"
                                onChange={newEmail => this.props.emailOnChange(newEmail)}
                                className="loginInput"
                                type="text"
                            />
                        </div>
                        <div className="lLabelAndInput">
                            <div className="lLabel">Password</div>
                            <Input
                                id="passwordInput"
                                onChange={newPassword => this.props.passwordOnChange(newPassword)}
                                className="loginInput"
                                type="password"
                            />
                        </div>
                        <div className="lButtonContainer">
                            <button
                                className="lResetPasswordButton secondaryButton"
                                onClick={() => this.props.history.push('/password_reset')}
                            >
                                Reset Your Password
                            </button>
                            <div>
                                {!this.props.loading ? (
                                    <button
                                        id="loginButton"
                                        className="lButton primaryButton"
                                        onClick={this.props.login}
                                    >
                                        Log In
                                    </button>
                                ) : (
                                    <div className="lButton">
                                        <SaveLoading />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
