import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { caretDown } from 'react-icons-kit/fa/caretDown';
import { caretRight } from 'react-icons-kit/fa/caretRight';
import '../../../css/Help.css';

export default class HelpSection extends Component {
    renderLinks = () => {
        let renderedLinks = [];
        Array.from(this.props.links).forEach((linkObject, index) => {
            renderedLinks.push(
                <button
                    key={linkObject.title}
                    className="hListLink tertiaryButton"
                    onClick={() =>
                        'onClickOverride' in linkObject
                            ? linkObject.onClickOverride(this.props.history)
                            : this.props.openModalSection(linkObject.content, linkObject.modalTitle)
                    }
                >
                    {index + 1 + '. ' + linkObject.title}
                </button>
            );
        });
        return renderedLinks;
    };

    render() {
        return (
            <div className="hSection" id={`section-${this.props.hash}`}>
                <div className="hSectionLabel" onClick={this.props.toggle}>
                    <Icon
                        icon={this.props.isOpen ? caretDown : caretRight}
                        size={18}
                        className="tableIcon"
                    />
                    <div>{this.props.label}</div>
                </div>
                <div hidden={!this.props.isOpen} className="hSectionList">
                    {this.renderLinks()}
                </div>
            </div>
        );
    }
}
