import React from 'react';

const DoNotSeeEmployeesOrCustomers = () => (
    <div>
        <div className="sectionQuestion">I don't see any of my employees or customers</div>
        <div className="sectionAnswer">
            You need to synchronize your QuickBooks company data with Minute7. This will pull over
            entities from your QuickBooks file into Minute7.
        </div>
    </div>
);

export default DoNotSeeEmployeesOrCustomers;
