import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { accountUserFields, employeeFields } from '../../../config/Fields.js';
import { selectEmployee } from '../../../actions/Actions.js';
import { showToast } from '../../../actions/Actions.js';
import { addUser, updateUser, resetUserEmail } from '../../../actions/AccountUserActions';
import { updateEmployee } from '../../../actions/EmployeeActions';
import Select from '../../components/Select.js';
import { isEmployeeNameValid, isValidEmail } from '../../../utils/CommonFunctions.js';
import { Row, Col } from 'react-bootstrap';
import Icon from 'react-icons-kit';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';
import { informationCircled as info } from 'react-icons-kit/ionicons/informationCircled';
import ToggleSettingList from '../../components/ToggleSettingList.js';
import ReactTooltip from 'react-tooltip';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';
import * as accountSelectors from '../../../selectors/AccountSelectors';
import Spinner from '../../components/Spinner.js';
import ToggleSettingListV2 from '../../components/ToggleSettingListV2.js';
import ToggleOptionsSecondV from '../../components/ToggleOptionsSecondV';
class EditEmployeeForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState();
    }

    componentWillUnmount() {
        this.props.selectEmployee(this.props.employee.id);
    }

    checkBoxColor(value) {
        if (value) {
            return 'gray';
        } else {
            return '#008fd7';
        }
    }

    componentWillReceiveProps(props) {
        if (props.isSaving !== this.props.isSaving && !props.isSaving) {
            this.props.history.goBack();
        }
    }

    defaultState = () => {
        const { employee, payrollItems, accountId, user } = this.props;

        // const payroll_item = employee
        //     ? payrollItems.find(e => e.id === employee.payroll_item_ids + '')
        //     : null;
        const payroll_item = employee.payroll_item_ids ? employee.payroll_item_ids.split(',') : [];
        const default_payrate_data = new Map();
        if (payroll_item.length > 0) {
            payroll_item.forEach(item => {
                default_payrate_data.set(item.key, 0);
            });
        }
        let payroll_bill_rates = employee.payroll_bill_rates != "{}" ? new Map(Object.entries(employee.payroll_bill_rates)) : default_payrate_data;
        return {
            firstSearchFilter: '',
            secondSearchFilter: '',
            fNameInvalidMsg: '',
            mNameInvalidMsg: '',
            lNameInvalidMsg: '',
            emailInvalidMsg: '',
            userSettings: 'default',
            defaultUserCreation: user ? true : false,
            account_id: accountId,
            make_employee_user: false,
            id: employee ? employee.id : '0',
            name: employee
                ? employee.name === ''
                    ? `${employee.first_name} ${employee.last_name}`
                    : employee.name
                : '',
            email: employee.email ? employee.email : '',
            first_name: employee.first_name ? employee.first_name : '',
            middle_name: employee.middle_name ? employee.middle_name : '',
            last_name: employee.last_name ? employee.last_name : '',
            pay_rate: employee ? employee.pay_rate : 0,
            reference_number: employee ? employee.reference_number : '',
            hidden: employee ? this.hiddenValue(employee.hidden) : '',
            type: '',
            payroll_item_ids: payroll_item ? payroll_item : [],
            payroll_bill_rates: payroll_bill_rates,
            payroll_items: payrollItems,
            use_time_for_paychecks: 'UseTimeData',
            hasBeenEdited: false,
            save_message: 'Save',
            employee_id: '0',
            expense_vendor_id: '0',
            phone: '',
            week_start_offset: 0,
            timesheet_grouping: 'week',
            time_entry_reminder_frequency: 'Never',
            time_entry_reminder_minimum_hours: 0,
            export_reminder_interval: 'Never',
            permission_group_id: '0',
            approve_others_expenses: 'No',
            approve_others_time: 'No',
            edit_others_expenses: 'No',
            edit_others_time: 'No',
            edit_own_expenses: 'No',
            edit_own_time: 'No',
            edit_payroll: 'No',
            export_time: 'No',
            manage_account: 'No',
            manage_users: 'No',
            manage_payroll_payrates: 'No',
            manage_item_billrates: 'No',
            manage_api: 'No',
            view_others_expenses: 'No',
            view_others_time: 'No',
            view_reports: 'No',
            clock_in_only: 'No',
            filter_view_employee_ids: [],
            filter_edit_employee_ids: [],
            filter_approve_employee_ids: [],
            filter_view_expense_vendor_ids: [],
            filter_edit_expense_vendor_ids: [],
            filter_approve_expense_vendor_ids: [],
            filter_customer_list: 'No',
            filter_customer_ids: [],
            filter_inventory_item_list: 'No',
            filter_inventory_item_ids: [],
            filter_qb_account_list: 'No',
            filter_qb_account_ids: [],
            filter_qb_class_list: 'No',
            filter_qb_class_ids: [],
            approve_others_expenses_override_group: 'No',
            approve_others_time_override_group: 'No',
            edit_others_expenses_override_group: 'No',
            edit_others_time_override_group: 'No',
            edit_own_expenses_override_group: 'No',
            edit_own_time_override_group: 'No',
            edit_payroll_override_group: 'No',
            export_time_override_group: 'No',
            filter_approve_employee_ids_override_group: 'No',
            filter_approve_expense_vendor_ids_override_group: 'No',
            filter_customer_ids_override_group: 'No',
            filter_customer_list_override_group: 'No',
            filter_edit_employee_ids_override_group: 'No',
            filter_edit_expense_vendor_ids_override_group: 'No',
            filter_inventory_item_ids_override_group: 'No',
            filter_inventory_item_list_override_group: 'No',
            filter_qb_account_ids_override_group: 'No',
            filter_qb_account_list_override_group: 'No',
            filter_qb_class_ids_override_group: 'No',
            filter_qb_class_list_override_group: 'No',
            filter_view_employee_ids_override_group: 'No',
            filter_view_expense_vendor_ids_override_group: 'No',
            manage_account_override_group: 'No',
            manage_expenses_override_group: 'No',
            manage_time_override_group: 'No',
            manage_users_override_group: 'No',
            manage_api_override_group: 'No',
            restrictions_override_group: 'No',
            view_others_expenses_override_group: 'No',
            view_others_time_override_group: 'No',
            view_reports_override_group: 'No',
            send_invite_sms: false,
            disable_user_creation: user ? true : false,
            spinner_loader: false,
        };
    };

    hiddenValue = employeeHiddenValue => {
        if (employeeHiddenValue === 'No') {
            employeeHiddenValue = false;
        } else if (employeeHiddenValue === 'Yes') {
            employeeHiddenValue = true;
        }
        return employeeHiddenValue;
    };

    constructEmployee = () => {
        const data = {};
        const dataFields = this.state.make_employee_user ? accountUserFields : employeeFields;
        dataFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else if (field === 'email') {
                    data[field] = this.state[field].trim().toLowerCase();
                } else if (field === 'payroll_bill_rates') {
                    const { payroll_bill_rates } = this.state;
                    data[field] = JSON.stringify(Object.fromEntries(payroll_bill_rates));
                }
                else {
                    data[field] = this.state[field];
                }

            }
            if (field.includes('payroll_item_ids')) {
                data[field] = this.state[field].join(',');
            }
        });
        return data;
    };

    checkRequiredFields = () => {
        if (this.state.email === null) {
            this.props.showToast('Please enter an email address for this employee', 'warning');
            return false;
        } else {
            if (!isValidEmail(this.state.email.trim())) {
                this.props.showToast(
                    'Please enter a valid email address for this employee',
                    'warning'
                );
                return false;
            }
        }
        if (this.state.last_name === '' || this.state.first_name === '' || this.state.name === '') {
            this.props.showToast('Please fill out both First Name and Last Name', 'warning');
            return false;
        }
        // if (this.state.pay_rate < 0 || isNaN(this.state.pay_rate)) {
        //     this.props.showToast('Please enter a valid payrate for this employee', 'warning');
        //     return false;
        // }
        return true;
    };

    saveEditedEmployee = event => {
        event.preventDefault();
        if (
            this.state.emailInvalidMsg !== '' ||
            this.state.fNameInvalidMsg !== '' ||
            this.state.mNameInvalidMsg !== '' ||
            this.state.lNameInvalidMsg !== ''
        ) {
            this.props.showToast('Please Fix the form error before submit', 'warning');
        } else {
            if (!this.checkRequiredFields()) {
                return;
            }
            this.setState({ spinner_loader: true });
            const data = this.constructEmployee();
            this.props.updateEmployee(data, newEmployee => {
                if (newEmployee === false) {
                    this.setState({ spinner_loader: false });
                    return;
                }
                if (this.state.defaultUserCreation && this.state.disable_user_creation === false) {
                    this.setState({
                        employee_id: newEmployee.id.toString(),
                        make_employee_user: true,
                    });
                    const userData = this.constructEmployee();
                    if (this.state.userSettings === 'default') {
                        this.props.addUser(userData, newUser => {
                            if (newUser === false) {
                                this.setState({ spinner_loader: false });
                                return;
                            }
                            this.props.history.push('/resource_settings/employees');
                            this.props.showToast(
                                ` Employee has been upated and its user created`,
                                'positive'
                            );
                        });
                    } else {
                        this.props.selectEmployee(newEmployee.id.toString());
                        this.props.history.push('/create_account_user');
                        this.props.showToast(`Employee has been upated`, 'positive');
                    }
                } else {
                    if (this.state.defaultUserCreation && this.state.disable_user_creation) {
                        this.props.updateUser(
                            {
                                ...this.props.user,
                                first_name: this.state.first_name,
                                last_name: this.state.last_name,
                                middle_name: this.state.middle_name,
                                email: this.state.email,
                            },
                            () => {
                                if (this.props.user.email !== this.state.email) {
                                    this.props.resetUserEmail(this.props.user.id);
                                }
                            }
                        );
                    }

                    this.props.history.push('/resource_settings/employees');
                    this.props.showToast(`Employee has been upated'`, 'positive');
                }
                this.setState({ spinner_loader: false });
            });
        }
    };

    handlePayrollItemOnChange = newPayrollItem => {
        this.setState({ payroll_item_ids: newPayrollItem, hasBeenEdited: true });
    };

    setFields = fields => {
        this.setState({ ...fields, hasBeenEdited: true });
    };

    handleDefaultUserCheckOnChange = defaultUserCreation => {
        if (this.state.disable_user_creation) {
            return;
        }
        this.setState({ defaultUserCreation: defaultUserCreation });
        if (defaultUserCreation && this.state.userSettings === 'default') {
            this.setState({ save_message: 'Save + Send Invite' });
        } else {
            this.setState({ save_message: 'Save' });
        }
    };

    handleOnChanges = {
        handleFirstNameOnChange: newName => {
            this.setState({ name: `${newName.target.value} ${this.state.last_name}` });
            this.setState({ first_name: newName.target.value });
        },
        handleMiddleNameOnChange: newName => {
            this.setState({ middle_name: newName.target.value });
        },
        handleLastNameOnChange: newName => {
            this.setState({ name: `${this.state.first_name} ${newName.target.value}` });
            this.setState({ last_name: newName.target.value });
        },
        handleEmailOnChange: newEmail => {
            this.setState({ email: newEmail.target.value });
        },
        handleNameOnChange: newName => {
            this.setState({ name: newName.target.value });
        },
        handlePayrollItemOnChange: newPayrollItem => {
            this.setState({ payroll_item_ids: newPayrollItem, hasBeenEdited: true });
        },
        handleReferenceNumberOnChange: newReferenceNumber => {
            this.setState({ reference_number: newReferenceNumber.target.value });
        },
    };

    render() {
        const {
            email,
            first_name,
            middle_name,
            last_name,
            reference_number,
            payroll_items,
            payroll_item_ids,
            hidden,
            defaultUserCreation,
            userSettings,
            save_message,
            disable_user_creation,
            spinner_loader,
            payroll_bill_rates,
            firstSearchFilter,
            secondSearchFilter,
        } = this.state;
        const { canManagePayRollItem } = this.props;
        return (
            <div className="applicationWrapper">
                <div>
                    <div className="application">
                        <div className="ftContainer cardContainer">
                            <form className="ftForm" onSubmit={this.saveEditedEmployee}>
                                <Row className="ftRowAlightCenter">
                                    <div
                                        style={{
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            marginBottom: '15px',
                                        }}
                                    >
                                        Edit Employee
                                    </div>
                                </Row>
                                <Row className="ftrow">
                                    <Col sm={6} style={{ marginBottom: '5px' }}>
                                        <div className="ftLabelAndInput">
                                            <Row>
                                                <div className="ftLabel largeBodyText greyText">
                                                    {' '}
                                                    First Name{' '}
                                                </div>
                                                <div className="required">*</div>
                                            </Row>

                                            <div>
                                                <Input
                                                    className={
                                                        this.state.fNameInvalidMsg === ''
                                                            ? ''
                                                            : 'ausInvalidInput'
                                                    }
                                                    name="first_name"
                                                    value={first_name}
                                                    onChange={
                                                        this.handleOnChanges.handleFirstNameOnChange
                                                    }
                                                    onBlur={
                                                        first_name.length !== 0
                                                            ? () =>
                                                                isEmployeeNameValid(first_name)
                                                                    ? this.setFields({
                                                                        fNameInvalidMsg: '',
                                                                    })
                                                                    : this.setFields({
                                                                        fNameInvalidMsg:
                                                                            'First name contains only  1 to 50 alphabets.',
                                                                    })
                                                            : ''
                                                    }
                                                />
                                                <div className="ftInputError">
                                                    {!this.state.fNameInvalidMsg === '' &&
                                                        !first_name === '' ? (
                                                        <label />
                                                    ) : (
                                                        <label className="error-message">
                                                            {this.state.fNameInvalidMsg}
                                                        </label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} style={{ marginBottom: '5px' }}>
                                        <div className="ftLabelAndInput">
                                            <Row>
                                                <div className="ftLabel largeBodyText greyText">
                                                    {' '}
                                                    Last Name{' '}
                                                </div>
                                                <div className="required">*</div>
                                            </Row>
                                            <div>
                                                <Input
                                                    className={
                                                        this.state.lNameInvalidMsg === ''
                                                            ? ''
                                                            : 'ausInvalidInput'
                                                    }
                                                    name="last_name"
                                                    value={last_name}
                                                    onChange={
                                                        this.handleOnChanges.handleLastNameOnChange
                                                    }
                                                    onBlur={
                                                        last_name.length !== 0
                                                            ? () =>
                                                                isEmployeeNameValid(last_name)
                                                                    ? this.setFields({
                                                                        lNameInvalidMsg: '',
                                                                    })
                                                                    : this.setFields({
                                                                        lNameInvalidMsg:
                                                                            'Last name contains only  1 to 50 alphabets.',
                                                                    })
                                                            : ''
                                                    }
                                                />
                                                <div className="ftInputError">
                                                    {!this.state.lNameInvalidMsg === '' &&
                                                        !last_name === '' ? (
                                                        <label />
                                                    ) : (
                                                        <label className="error-message">
                                                            {this.state.lNameInvalidMsg}
                                                        </label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="ftrow">
                                    <Col sm={6} style={{ marginBottom: '5px' }}>
                                        <div className="ftLabelAndInput">
                                            <Row>
                                                <div className="ftLabel largeBodyText greyText">
                                                    {' '}
                                                    Middle Name{' '}
                                                </div>
                                            </Row>

                                            <div>
                                                <Input
                                                    className={
                                                        this.state.mNameInvalidMsg === ''
                                                            ? ''
                                                            : 'ausInvalidInput'
                                                    }
                                                    name="middle_name"
                                                    value={middle_name}
                                                    onChange={
                                                        this.handleOnChanges
                                                            .handleMiddleNameOnChange
                                                    }
                                                    onBlur={
                                                        middle_name.length !== 0
                                                            ? () =>
                                                                isEmployeeNameValid(middle_name)
                                                                    ? this.setFields({
                                                                        mNameInvalidMsg: '',
                                                                    })
                                                                    : this.setFields({
                                                                        mNameInvalidMsg:
                                                                            'Middle name contains only  1 to 50 alphabets.',
                                                                    })
                                                            : ''
                                                    }
                                                />
                                                <div className="ftInputError">
                                                    {!this.state.mNameInvalidMsg === '' &&
                                                        !middle_name === '' ? (
                                                        <label />
                                                    ) : (
                                                        <label className="error-message">
                                                            {this.state.mNameInvalidMsg}
                                                        </label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} style={{ marginBottom: '5px' }}>
                                        <div className="ftLabelAndInput">
                                            <Row>
                                                <div className="ftLabel largeBodyText greyText">
                                                    {' '}
                                                    Email{' '}
                                                </div>
                                                <div className="required">*</div>
                                            </Row>
                                            <div>
                                                <Input
                                                    className={
                                                        this.state.emailInvalidMsg === ''
                                                            ? ''
                                                            : 'ausInvalidInput'
                                                    }
                                                    name="email"
                                                    value={email}
                                                    onChange={
                                                        this.handleOnChanges.handleEmailOnChange
                                                    }
                                                    onBlur={
                                                        email.length !== 0
                                                            ? () =>
                                                                isValidEmail(email)
                                                                    ? this.setFields({
                                                                        emailInvalidMsg: '',
                                                                    })
                                                                    : this.setFields({
                                                                        emailInvalidMsg:
                                                                            'Email must be between to 4 to 40 characters (e.g. example@minute7.com)',
                                                                    })
                                                            : ''
                                                    }
                                                />
                                                <div className="ftInputError">
                                                    {!this.state.emailInvalidMsg === '' &&
                                                        !email === '' ? (
                                                        <label />
                                                    ) : (
                                                        <label className="error-message">
                                                            {this.state.emailInvalidMsg}
                                                        </label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="ftrow">
                                    <Col sm={6} style={{ marginBottom: '5px' }}>
                                        <div className="ftLabelAndInput">
                                            <Row>
                                                <div className="ftLabel largeBodyText greyText">
                                                    {' '}
                                                    Employee Number{' '}
                                                </div>
                                            </Row>
                                            <div>
                                                <Input
                                                    name="reference_number"
                                                    value={reference_number}
                                                    onChange={
                                                        this.handleOnChanges
                                                            .handleReferenceNumberOnChange
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    {/* </Row>

 

                                <Row className="ftrow"> */}
                                    <Col sm={6}>
                                        <ReactTooltip
                                            className="tooltip-override"
                                            data-for="UserCreation"
                                            type="info"
                                            multiline={true}
                                            data-html={true}
                                        />
                                        <div className="fFilterCheckboxContainer" style={{ marginTop: '27px' }}>
                                            <div className="fFilterByDateCheckbox">
                                                <Icon
                                                    style={{
                                                        color: this.checkBoxColor(
                                                            disable_user_creation
                                                        ),
                                                    }}
                                                    size={18}
                                                    icon={
                                                        defaultUserCreation
                                                            ? checkboxChecked
                                                            : checkboxUnchecked
                                                    }
                                                    onClick={() =>
                                                        this.handleDefaultUserCheckOnChange(
                                                            !defaultUserCreation
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div
                                                className="fEnableDateRangeText"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Add as a user
                                            </div>
                                            <Icon
                                                style={{ paddingLeft: '4px' }}
                                                className="blueIcon fEnableDateRangeText"
                                                size={16}
                                                data-tip="Selecting this option will auto create Minute 7 user account for this employee."
                                                icon={info}
                                                id="UserCreation"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {defaultUserCreation && disable_user_creation === false && (
                                    <div className="ftLabelAndInput">
                                        <ReactTooltip
                                            className="tooltip-override"
                                            data-for="permission"
                                            type="info"
                                            multiline={true}
                                            data-html={true}
                                        />
                                        <ToggleSettingListV2
                                            title={[
                                                <span style={{ fontWeight: 'bold' }}>
                                                    Permission Settings{' '}
                                                </span>,
                                                <Icon
                                                    className="blueIcon"
                                                    size={16}
                                                    data-tip="Default permission settings allow users to add and edit their timesheets. Choose customized settings for different permissions."
                                                    icon={info}
                                                    id="permission"
                                                />,
                                            ]}
                                            value={userSettings}
                                            options={[
                                                { label: 'Default', value: 'default' },
                                                { label: 'Customized', value: 'custom' },
                                            ]}
                                            valueForOption={option => option.value}
                                            labelForOption={option => option.label}
                                            onChange={value => {
                                                this.setState({
                                                    userSettings: value,
                                                });
                                                if (value === 'default') {
                                                    this.setState({
                                                        save_message: 'Save + Send Invite',
                                                    });
                                                } else {
                                                    this.setState({ save_message: 'Save' });
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                                {canManagePayRollItem && (
                                    <Row className="ftrow">
                                        <div className="ftLabel largeBodyText greyText"
                                            style={{ fontWeight: 'bold', paddingTop: '2%', paddingBottom: '2%' }}>
                                            {' '}
                                            Payroll Items{' '}
                                        </div>
                                    </Row>)}
                                {canManagePayRollItem && (
                                    <ToggleOptionsSecondV
                                        listOptions={payroll_items}
                                        firstSearchFilter={firstSearchFilter}
                                        secondSearchFilter={secondSearchFilter}
                                        valueForListOptionMap={payroll_bill_rates}
                                        listValue={payroll_item_ids}
                                        options={['Yes', 'No']}
                                        onListChange={values => {
                                            this.setState({ payroll_item_ids: values });
                                        }}
                                        onValueChange={values => {
                                            this.setState({ payroll_bill_rates: values });
                                        }}
                                    />
                                )}
                                <Row className="ftrow">
                                    <Col sm={6} style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <div className="ftLabelAndInput">
                                            <div>
                                                <input
                                                    name="hidden_checkbox"
                                                    type="checkbox"
                                                    className="checkbox"
                                                    checked={hidden}
                                                    onChange={() =>
                                                        this.setFields({
                                                            hidden: !hidden,
                                                        })
                                                    }
                                                />
                                                <label htmlFor="hidden_checkbox">
                                                    Mark employee as inactive
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} style={{ marginBottom: '5px' }}>
                                        <div className="ftButtonContainer">
                                            <button
                                                type="submit"
                                                className="primaryButton ftButton"
                                            >
                                                {save_message}
                                            </button>
                                        </div>
                                    </Col>
                                    <Col sm={6} style={{ marginBottom: '5px' }}>
                                        <div className="ftButtonContainer">
                                            <button
                                                className="secondaryButton ftButton"
                                                onClick={() =>
                                                    this.props.history.push(
                                                        '/resource_settings/employees'
                                                    )
                                                }
                                            >
                                                Resource Settings
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Spinner loading={spinner_loader} />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        appStore: { selectedEmployeeId },
    } = state;
    const users = accountSelectors.selectUsersSorted(state);
    const employee = selectedEmployeeId;
    const user = selectedEmployeeId
        ? users.find(e => e.employee_id === selectedEmployeeId.id)
        : null;
    return {
        canManagePayRollItem: state.accountUserStore.manage_payroll_payrates === 'Yes',
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        employee,
        isSaving: state.accountUserStore.isSaving,
        payrollItems: state.businessResourcesStore.allActivePayrollItems,
        user,
    };
}

const mapDispatchToProps = {
    showToast,
    selectEmployee,
    updateEmployee,
    addUser,
    updateUser,
    resetUserEmail,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditEmployeeForm);
