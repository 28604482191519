export const INITIAL_STATE = { 
    reportFilters: [],
};

export const reportFilterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
       
        
        case 'SET_REPORT_FILTER':
            return {
                ...state,
                reportFilters: action.payload,
            };
        case 'ADD_REPORT_FILTER':
            return {
                ...state,
                isSaving: true,
            };
        case 'ADD_REPORT_FILTER_SUCCESS':
            return {
                ...state,
                isSaving: false,
            };
        case 'ADD_REPORT_FILTER_ERROR':
            return {
                ...state,
                isSaving: false,
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default reportFilterReducer;
