export const requestSegmentedTime = ({ params, onSuccess, onError }) => ({
    type: 'REQUEST_SEGMENTED_TIME',
    params,
    onSuccess,
    onError,
});

export const requestSegmentedTimeError = error => ({
    type: 'REQUEST_SEGMENTED_TIME_ERROR',
    error,
});

export const receiveSegmentedTime = segmentedTime => ({
    type: 'RECEIVE_SEGMENTED_TIME',
    segmentedTime,
});

export const requestSegmentedExpenses = ({ params, onSuccess, onError }) => ({
    type: 'REQUEST_SEGMENTED_EXPENSES',
    params,
    onSuccess,
    onError,
});

export const requestSegmentedExpensesError = error => ({
    type: 'REQUEST_SEGMENTED_EXPENSES_ERROR',
    error,
});

export const receiveSegmentedExpenses = segmentedExpenses => ({
    type: 'RECEIVE_SEGMENTED_EXPENSES',
    segmentedExpenses,
});

export const cancelReportRequests = () => ({
    type: 'CANCEL_REPORT_REQUESTS',
});
