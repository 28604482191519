import React from 'react';

const EditSyncedEntry = () => (
    <div>
        <div className="sectionQuestion">
            How can I delete or edit entries that have been synced with QuickBooks?
        </div>
        <div className="sectionAnswer">
            If you want to delete or edit an entry that has already been synced with QuickBooks, you
            can take the following steps:
            <ol>
                <li>The Account Manager will need to log into your account at Minute7.com</li>
                <li>Once you are logged in click on the Settings tab at the top of the page</li>
                <li>Click on the Company Settings tab below the primary header bar</li>
                <li>Scroll down to "Time and Expense Preferences"</li>
                <li>
                    Change "Prevent users from editing time and expense entries that have been
                    synced" to "No".
                </li>
            </ol>
        </div>
    </div>
);

export default EditSyncedEntry;
