import React from 'react';

const Minute7Cost = () => (
    <div>
        <div className="sectionQuestion">How much does Minute7 cost?</div>
        <div className="sectionAnswer">
            A subscription costs just $8 per user per month. There are no sign up, setup, software,
            hardware, download or update costs. You pay nothing upon sign up
        </div>
        <div className="sectionButton">
            <button
                className="secondaryButton sectionFont"
                onClick={() => (window.location.href = '/free_trial')}
            >
                Start Your Free Trial
            </button>
        </div>
    </div>
);

export default Minute7Cost;
