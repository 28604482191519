import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';

export const getAllPayrollItems = async (paramsObject = {}) => {
    const { PayrollItems, meta } = await getData(paramsObject, 'v1/payrollitems');
    let payrollItemList = PayrollItems.map(item => {
        return item.PayrollItem;
    });
    return {
        payrollItemList,
        meta,
    };
};

export const getAllPayrollItemsForTable = async (paramsObject = {}) => {
    const { PayrollItems, meta } = await getData(paramsObject, 'v1/payrollitems/paginated');
    let payrollItemList = PayrollItems.map(item => {
        return item.PayrollItem;
    });
    return {
        payrollItemList,
        meta,
    };
};

export const createNewPayrollItem = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(data, 'v1/payroll_items', paramsObject);
    return res.PayrollItem;
};

export const updateExistingPayrollItem = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        data,
        'v1/payroll_items/' + data['PayrollItem[id]'],
        paramsObject
    );
    return res.PayrollItem;
};

export const activatePayrollItem = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/payroll_items/' + id + '/activate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.PayrollItem;
};

export const deactivatePayrollItem = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/payroll_items/' + id + '/deactivate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.PayrollItem;
};
