import { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Modal from 'react-modal';
import { showAlert } from './actions/Actions.js';
import { canViewTimesheets, canViewExpenses } from './utils/RoutingUtils';

try {
    Modal.setAppElement('#root');
} catch {}

class App extends Component {
    componentWillMount() {
        const { state = {} } = this.props.location;
        const { error } = state;
        const { currentUser, account, isLoggedIn } = this.props;
        if (isLoggedIn) {
            if (error) {
                this.props.showAlert(error, 'negative');
            }
            if (
                !canViewTimesheets(currentUser).isAllowed &&
                canViewExpenses(currentUser, account).isAllowed
            ) {
                this.props.history.push('/expenses');
                return;
            }

            this.props.history.push('/timesheets');
        } else {
            this.props.history.push('/home');
        }
    }

    render() {
        return null;
    }
}

function mapStateToProps({ businessResourcesStore, accountUserStore, accountStore, appStore }) {
    return {
        isLoggedIn: !_.isNil(appStore.session_id),
        currentUser: accountUserStore,
        account: accountStore,
    };
}

const mapDispatchToProps = {
    showAlert,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
