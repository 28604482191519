import React from 'react';

const QuickBooksVersion = () => (
    <div>
        <div className="sectionQuestion">
            Which versions of QuickBooks&trade; does Minute7 Work With?
        </div>
        <div className="sectionAnswer">
            Minute7 works with QuickBooks Online and QuickBooks Desktop versions from 2006 onward
        </div>
    </div>
);
export default QuickBooksVersion;
