import _ from 'lodash';
import moment from 'moment';
import { paddingZero } from './CommonFunctions.js';
import { roundDurationToMinuteInterval } from './DateUtils.js';
import { durationStringToDuration } from './TimeEntryUtils.js';

const TIME_FORMAT = 'HH:mm';

export const durationToString = (durationMs, options = {}) => {
    const duration = moment.duration(durationMs);
    const sign = duration.asMilliseconds() < 0 ? '-' : '';
    const hours = paddingZero(Math.abs(duration.hours()));
    const minutes = paddingZero(Math.abs(duration.minutes()));
    const seconds = paddingZero(Math.abs(duration.seconds()));
    return options.includeSeconds
        ? `${sign}${hours}:${minutes}:${seconds}`
        : `${sign}${hours}:${minutes}`;
};

export const elapsedTimeWorkingFromChunks = chunks =>
    chunks.reduce((total, { start, end }, i) => {
        // Only add the difference between now and the start time
        // if we're dealing with the very last chunk.
        // If any of the earlier chunks is missing an end, consider that
        // to be a malformed 0-duration chunk and skip over it.
        if (!end) {
            if (i === chunks.length - 1) {
                return total + moment().valueOf() - start;
            }
            console.log('Malformed time chunk in elapsed work time calculation:', chunks[i]);
            return total;
        }

        return total + end - start;
    }, 0);

export const elapsedTimeOffDutyFromChunks = (chunks, options = {}) =>
    chunks.reduce((total, { start, end }, i) => {
        _.defaults(options, { includePresentMoment: false });

        let adjustmentForPresentMoment = 0;
        // if includePresentMoment is set to true and this is the last completed chunk
        // include the difference between now and the end of this chunk
        if (options.includePresentMoment && i === chunks.length - 1 && end) {
            adjustmentForPresentMoment = moment().valueOf() - end;
        }

        if (i === 0) {
            return total + adjustmentForPresentMoment;
        }

        const prev = chunks[i - 1];

        // if the previous entry is malformed, skip over it
        if (!prev.end) {
            console.log('Malformed time chunk in hours off duty calculation:', prev);
            return total + adjustmentForPresentMoment;
        }

        return total + start - prev.end + adjustmentForPresentMoment;
    }, 0);

export const startTimeFromChunks = chunks => {
    const time = chunks.length && chunks[0].start ? moment(chunks[0].start) : moment();
    return time.format(TIME_FORMAT);
};

export const endTimeFromChunks = chunks => {
    const time =
        chunks.length && chunks[chunks.length - 1].end
            ? moment(chunks[chunks.length - 1].end)
            : moment();
    return time.format(TIME_FORMAT);
};

export const constructNewTimeEntry = props => {
    const {
        accountStore,
        accountUserStore,
        timeEntryId,
        approved,
        billable,
        customerId,
        date,
        description,
        roundingInterval,
        employeeId,
        inventoryItemId,
        payrollItemId,
        qbClassId,
        timeChunks,
        hoursOffDuty,
        hoursOffDutyAdjustment,
        taxable,
    } = props;

    const elapsedTimeWorking = elapsedTimeWorkingFromChunks(timeChunks);
    const durationWorking = moment.duration(elapsedTimeWorking);
    const durationOffDutyAdjustment = moment.duration(hoursOffDutyAdjustment, 'hours');
    const durationOffDuty = durationStringToDuration(hoursOffDuty).subtract(
        durationOffDutyAdjustment
    );
    const startTime = startTimeFromChunks(timeChunks);
    const roundedDuration =
        roundingInterval === ''
            ? durationWorking.subtract(durationOffDuty)
            : roundDurationToMinuteInterval(
                  durationWorking.subtract(durationOffDuty),
                  roundingInterval
              );

    const startTimeAsDuration = moment.duration(startTime);
    const endMoment = moment()
        .hours(startTimeAsDuration.hours())
        .minutes(startTimeAsDuration.minutes())
        .add(roundedDuration);

    const endTime = endMoment.format('HH:mm');

    const entry = {
        entryType: 'day', // always true for timer entries
        account_id: accountStore.id,
        account_user_id: accountUserStore.id,
        approved,
        billable: accountStore.show_billable === 'No' ? accountStore.billable_by_default : billable,
        taxable: accountStore.show_taxable === 'No' ? accountStore.taxable_by_default : taxable,
        customer_id: customerId,
        date: moment(date).format('Y-MM-DD'),
        description,
        duration: roundedDuration.asHours(),
        start_time: startTime,
        end_time: endTime,
        hours_off_duty: durationOffDuty.asHours(),
        type: employeeId.includes('_v') ? 'Vendor' : 'Employee',
        employee_id: employeeId.replace('_v', ''),
        inventory_item_id: inventoryItemId,
        payroll_item_id: payrollItemId,
        qb_class_id: qbClassId,
        default_rate: accountStore.qb_type === 'None' ? props.filterdInventoryItems.find(item => item.id === inventoryItemId).default_rate : 0.0,
    };

    if (timeEntryId !== null) {
        entry.id = timeEntryId;
    }

    return entry;
};

export const timerPropsForContinuingEntry = entry => {
    if ('entriesInGroupById' in entry) {
        // this is a week entry, so pick today's entry
        const entries = _.sortBy(Object.values(entry.entriesInGroupById), e =>
            moment(e.date, 'Y-MM-DD').valueOf()
        );

        const todaysEntry = entries.find(e => moment(e.date, 'Y-MM-DD').isSame(moment(), 'day'));
        if (todaysEntry) {
            return timerPropsForContinuingEntry(todaysEntry);
        }

        // if today's entry isn't available, pick the last one in the group chronologically
        return timerPropsForContinuingEntry(entries[entries.length - 1]);
    }

    const date = moment(entry.date, 'Y-MM-DD');
    const employeeId = entry.type === 'Vendor' ? entry.employee_id + '_v' : entry.employee_id;

    let timeChunks;
    let start;
    let end;

    const duration = moment.duration(parseFloat(entry.duration), 'hours');

    if (entry.start_time && entry.start_time !== entry.end_time) {
        start = moment(`${entry.date} ${entry.start_time}`, 'Y-MM-DD HH:mm');
        end = moment(`${entry.date} ${entry.end_time}`, 'Y-MM-DD HH:mm');

        timeChunks = [
            {
                start: start.valueOf(),
                end: start
                    .clone()
                    .add(duration)
                    .valueOf(),
            },
        ];
    } else {
        end = moment();
        start = moment().subtract(duration);

        timeChunks = [{ start: start.valueOf(), end: end.valueOf() }];
    }

    const hoursOffDutyAdjustment = durationStringToDuration(entry.hours_off_duty);

    const props = {
        timeEntryId: entry.id,
        customerId: entry.customer_id,
        date: date.toDate(),
        description: entry.description,
        employeeId,
        billable: entry.billable,
        payrollItemId: entry.payroll_item_id,
        inventoryItemId: entry.inventory_item_id,
        qbClassId: entry.qb_class_id,
        hoursOffDuty: entry.hours_off_duty,
        hoursOffDutyAdjustment: hoursOffDutyAdjustment.asHours(),
        timeChunks,
    };

    return props;
};

export const timerPropsForEntry = entry => ({
    customerId: entry.customer_id,
    date: new Date(),
    description: entry.description,
    employeeId: entry.type === 'Vendor' ? entry.employee_id + '_v' : entry.employee_id,
    billable: entry.billable,
    payrollItemId: entry.payroll_item_id,
    inventoryItemId: entry.inventory_item_id,
    qbClassId: entry.qb_class_id,
    hoursOffDuty: '',
    hoursOffDutyAdjustment: 0,
});
