import React, { Component } from 'react';

export default class MaintenancePlaceholder extends Component {
    render() {
        return (
            <div className="full">
                <div className="lContainer">
                    <div>
                        <h3>Minute7 is down for maintenance today from 4am pst to 6am pst.</h3>
                        <h3>All operations will resume immediately after the maintenance window.</h3>
                    </div>
                </div>
            </div>
        );
    }
}
