import React from 'react';
import className from 'classnames';
import Icon from 'react-icons-kit';
import { paperclip } from 'react-icons-kit/fa/paperclip';
import styles from '../../../css/Home/HomeCircleIcon.module.css';

const HomeCircleIcon = props => (
    <div className={className(className, styles.container)}>
        <div className={className(className, styles.iconContainer)}>
            <Icon
                className={props.icon === paperclip ? className(className, styles.paperclip) : ''}
                icon={props.icon}
                size={props.size}
            />
        </div>
    </div>
);

export default HomeCircleIcon;
