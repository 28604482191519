import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { Row, Col } from 'react-bootstrap';
import { ic_radio_button_checked as radioChecked } from 'react-icons-kit/md/ic_radio_button_checked';
import { ic_radio_button_unchecked as radioUnchecked } from 'react-icons-kit/md/ic_radio_button_unchecked';
import ToggleOptions from './ToggleOptions';
import '../../css/CommonTheme.css';
import classNames from 'classnames';
import { informationCircled as info } from 'react-icons-kit/ionicons/informationCircled';
import ReactTooltip from 'react-tooltip';

export default class ToggleSettingList extends Component {
    static defaultProps = {
        permissionsGroupId: '0',
        firstListOptions: null,
        secondListOptions: null,
        enableInfoTip: false,
        labelForOption: o => o,
        valueForOption: o => o,
        infoMessageForOption: o => o,
        labelForListOption: o => o.name,
        valueForListOption: o => o.id,
        subtitle: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            firstSearchFilter: '',
            secondSearchFilter: '',
            firstSearchFilterSecondList: '',
            secondSearchFilterSecondList: '',
        };
    }

    figureCanChange = () => {
        return (
            !('overrideValue' in this.props) ||
            this.props.overrideValue === 'Yes' ||
            parseInt(this.props.permissionsGroupId, 10) === 0
        );
    };

    render() {
        const {
            value,
            options,
            title,
            subtitle,
            onChange,
            overrideValue,
            permissionsGroupId,
            firstListOptions,
            secondListOptions,
            firstLabel,
            secondLabel,
            listValue,
            overrideOnChange,
            labelForOption,
            valueForOption,
            infoMessageForOption,
            enableInfoTip,
        } = this.props;

        const {
            firstSearchFilter,
            secondSearchFilter,
            firstSearchFilterSecondList,
            secondSearchFilterSecondList,
        } = this.state;

        const shouldShowOverrides =
            permissionsGroupId !== null && parseInt(permissionsGroupId, 10) > 0;

        const values = options.map(valueForOption);
        const labels = options.map(labelForOption);
        const messages = options.map(infoMessageForOption);

        return (
            <div className="">
                <div className="toggleSettingLabel">
                    <Row className="ftrow">
                        <Col sm={12} style={{ marginBottom: '5px' }}>
                            <div >
                                {title}
                                {subtitle !== '' && <div className="note">{subtitle}</div>}
                            </div>
                        </Col>


                    </Row>

                    {labels.map((label, index) => {
                        return (
                            <Row className="ftrow">
                                <Col sm={12} style={{ marginBottom: '5px' }}>
                                    <div
                                        key={label}

                                        onClick={() => {
                                            if (this.figureCanChange()) {
                                                onChange(values[index]);
                                            }
                                        }}
                                    >
                                        <Icon
                                            icon={
                                                values[index] === value ? radioChecked : radioUnchecked
                                            }
                                            size={18}
                                            className="radioIcon"
                                        />
                                        <span className="radioLabel">{label}</span>
                                        {enableInfoTip && (
                                            <>
                                                <ReactTooltip
                                                    className="tooltip-override"
                                                    data-for="UserCreation"
                                                    type="info"
                                                    multiline={true}
                                                    data-html={true}
                                                />
                                                <Icon
                                                    style={{ paddingLeft: '4px' }}
                                                    className="blueIcon fEnableDateRangeText"
                                                    size={16}
                                                    data-tip={messages[index]}
                                                    icon={info}
                                                    id="UserCreation"
                                                />
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        );
                    })}
                </div>

                {firstListOptions !== null && this.figureCanChange() && (
                    <ToggleOptions
                        listOptions={firstListOptions}
                        firstSearchFilter={firstSearchFilter}
                        secondSearchFilter={secondSearchFilter}
                        label={firstLabel}
                        listValue={listValue}
                        options={options}
                        onListChange={this.props.onListChange}
                    />
                )}
                {secondListOptions !== null && this.figureCanChange() && (
                    <ToggleOptions
                        listOptions={secondListOptions}
                        firstSearchFilter={firstSearchFilterSecondList}
                        secondSearchFilter={secondSearchFilterSecondList}
                        label={secondLabel}
                        listValue={listValue}
                        overrideValue={overrideValue}
                        options={options}
                        onListChange={this.props.onListChange}
                    />
                )}
            </div>
        );
    }
}
