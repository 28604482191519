import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';
import { caretDown } from 'react-icons-kit/fa/caretDown';
import { caretRight } from 'react-icons-kit/fa/caretRight';
import moment from 'moment';
import classNames from 'classnames';
import WeekEntryInput from './WeekEntryInput.js';
import Select from '../../components/Select';
import DateTimePicker from '../../components/DateTimePicker.js';
import TwoButtonSwitch from '../../components/TwoButtonSwitch.js';
import SaveLoading from '../../components/SaveLoading.js';
import DescriptionInput from '../../components/DescriptionInput.js';
import { calculateDurationFromStartAndEndTimes } from '../../../utils/DateUtils.js';
import DurationInput from './DurationInput.js';
import { MIDNIGHT } from '../../../config/Constants.js';
import '../../../css/Timesheets.css';
import { PATTERNS } from '../../../config/Patterns.js';
import { durationStringToDuration } from '../../../utils/TimeEntryUtils';
import { durationToString } from '../../../utils/TimerUtils';

export default class NewTimeEntryInlineForm extends Component {
    static propTypes = {
        mode: PropTypes.oneOf(['add', 'edit', 'duplicate']),
        shouldShowBillable: PropTypes.bool.isRequired,
        shouldShowTaxable: PropTypes.bool.isRequired,
        shouldShowVendors: PropTypes.bool,
        shouldShowQbClasses: PropTypes.bool.isRequired,
        shouldShowPayrollItems: PropTypes.bool.isRequired,
        canEditOthersTime: PropTypes.bool.isRequired,
        qbClasses: PropTypes.any.isRequired,
        payrollItems: PropTypes.any.isRequired,
        employees: PropTypes.any.isRequired,
        customers: PropTypes.any.isRequired,
        inventoryItems: PropTypes.any.isRequired,
        onSavePressed: PropTypes.func.isRequired,
        onResetPressed: PropTypes.func,
        entryType: PropTypes.oneOf(['day', 'week']),
        qbClassId: PropTypes.string,
        employeeId: PropTypes.string,
        inventoryItemId: PropTypes.string,
        customerId: PropTypes.string,
        payrollItemId: PropTypes.string,
        durations: PropTypes.object,
        description: PropTypes.string,
        duration: PropTypes.string,
        hoursOffDuty: PropTypes.string,
        billable: PropTypes.string,
        date: PropTypes.string,
        isSaving: PropTypes.bool,
        isCollapsed: PropTypes.bool,
        onCollapsePressed: PropTypes.func,
        startOfWeek: PropTypes.string,
        startTime: PropTypes.string,
        endTime: PropTypes.string,
        editStartTime: PropTypes.string,
        editEndTime: PropTypes.string,
        no_integration: PropTypes.bool,
        history: PropTypes.any,
        filterdInventoryItems: PropTypes.array,
    };

    static defaultProps = {
        isSaving: false,
        mode: 'add',
        entryType: 'day',
        description: '',
        isCollapsed: false,
    };

    isDurationFocused = false;
    initialFocusInput = null;

    constructor(props) {
        super(props);

        this.state = {
            isCollapsed: false,
        };
    }

    componentWillMount() {
        document.addEventListener('keydown', this.handleEnterKeyPressed, false);
    }

    componentDidMount() {
        if (this.initialFocusInput) {
            this.initialFocusInput.focus();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEnterKeyPressed, false);
    }

    handleEnterKeyPressed = event => {
        if (event.code === 'Enter' && this.isDurationFocused) {
            this.props.onSavePressed();
        }
    };

    onDurationFocus = () => {
        this.isDurationFocused = true;
    };

    onDurationBlur = () => {
        this.isDurationFocused = false;
    };

    get shouldShowQbClasses() {
        return this.props.qbClasses.length > 0 && this.props.shouldShowQbClasses;
    }

    get shouldShowPayroll() {
        return this.props.payrollItems.length > 0 && this.props.shouldShowPayrollItems;
    }

    handleEntryTypeChanged = entryType => {
        this.props.onEntryFieldUpdated('entryType', entryType);
    };

    handleTimeFieldChanged = (date, event) => {
        const tempDurations = { ...this.props.durations };

        if (this.checkDurationFormat(event.target.value)) {
            tempDurations[moment(date).format('ddd')] = event.target.value;
        } else {
            tempDurations[moment(date).format('ddd')] = event.target.value.substr(
                0,
                event.target.value.length - 1
            );
        }
        this.props.onEntryFieldUpdated('durations', tempDurations);
    };

    handleDescriptionChanged = event => {
        this.props.onEntryFieldUpdated('description', event.target.value);
    };

    // Reset start/end times
    handleDurationChanged = ({ target }) => {
        if (this.checkDurationFormat(target.value)) {
            this.props.onEntryFieldUpdated('duration', target.value);
        } else {
            this.props.onEntryFieldUpdated(
                'duration',
                target.value.substr(0, target.value.length - 1)
            );
        }
        this.props.onEntryFieldUpdated('startTime', MIDNIGHT);
        this.props.onEntryFieldUpdated('endTime', MIDNIGHT);
        this.props.onEntryFieldUpdated('hoursOffDuty', '0:00');
    };

    checkDurationFormat = value => {
        return PATTERNS.durationFormat.test(value);
    };

    handleDateChanged = newDate => {
        this.props.onEntryFieldUpdated('date', newDate);
    };

    handleBillableToggled = () => {
        const billable = !(this.props.billable === 'Yes');
        this.props.onEntryFieldUpdated('billable', billable ? 'Yes' : 'No');
    };

    handleTaxableToggled = () => {
        const taxable = !(this.props.taxable === 'Yes');
        this.props.onEntryFieldUpdated('taxable', taxable ? 'Yes' : 'No');
    };

    handleEmployeeSelected = id => {
        this.props.onEntryFieldUpdated('employeeId', id);
    };

    handleNewEmployeeRouting = id => {
        this.props.history.push('/create_new_employee');
    };

    handleNewCustomerRouting = id => {
        this.props.history.push('/create_new_customer');
    };

    handleNewInventoryItemRouting = id => {
        this.props.history.push('/create_new_item');
    };

    handleNewPayrollItemRouting = id => {
        this.props.history.push('/create_new_payroll_item');
    };

    handleNewVendorRouting = id => {
        this.props.history.push('/create_new_vendor');
    };

    handleButtonList = (no_integration, shouldShowVendors) => {
        let buttonsList = [];
        if (no_integration) {
            buttonsList.push({
                withButton: true,
                buttonLablel: 'Add New Employee',
                route_path: this.handleNewEmployeeRouting,
            });
            if (shouldShowVendors) {
                buttonsList.push({
                    withButton: true,
                    buttonLablel: 'Add New Vendor',
                    route_path: this.handleNewVendorRouting,
                });
            }
        }
        return buttonsList;
    };

    handleCustomerButtonList = no_integration => {
        let buttonsList = [];
        if (no_integration) {
            buttonsList.push({
                withButton: true,
                buttonLablel: 'Add New Customer',
                route_path: this.handleNewCustomerRouting,
            });
        }
        return buttonsList;
    };

    handleInventoryButtonList = no_integration => {
        let buttonsList = [];
        if (no_integration) {
            buttonsList.push({
                withButton: true,
                buttonLablel: 'Add New Item',
                route_path: this.handleNewInventoryItemRouting,
            });
        }
        return buttonsList;
    };

    handlePayrollButtonList = no_integration => {
        let buttonsList = [];
        if (no_integration) {
            buttonsList.push({
                withButton: true,
                buttonLablel: 'Add New Payroll Item',
                route_path: this.handleNewPayrollItemRouting,
            });
        }
        return buttonsList;
    };

    handleCustomerSelected = id => {
        this.props.onEntryFieldUpdated('customerId', id);
        if(this.props.no_integration){
            this.props.onEntryFieldUpdated('inventoryItemId', "0");
        }
    };
    handleInventoryItemSelected = id => {
        this.props.onEntryFieldUpdated('inventoryItemId', id);
    };

    handlePayrollItemSelected = id => {
        this.props.onEntryFieldUpdated('payrollItemId', id);
    };

    handleQbClassSelected = id => {
        this.props.onEntryFieldUpdated('qbClassId', id);
    };

    handleStartOfWeekChanged = ([startDate, endDate]) => {
        this.props.onStartOfWeekChange(startDate);
    };

    updateHoursOffProps = hoursOffDuty => {
        this.props.onEntryFieldUpdated('hoursOffDuty', hoursOffDuty);
    };

    updateStartTimeProps = startTime => {
        this.props.onEntryFieldUpdated('startTime', startTime);
    };

    updateEndTimeProps = endTime => {
        this.props.onEntryFieldUpdated('endTime', endTime);
    };

    calculateDuration = calculationCriteria => {
        const startTime = calculationCriteria.start;
        const endTime = calculationCriteria.end;
        const offHours = calculationCriteria.hoursOffDuty;
        this.props.onEntryFieldUpdated('startTime', startTime);
        this.props.onEntryFieldUpdated('endTime', endTime);
        this.props.onEntryFieldUpdated('hoursOffDuty', offHours);
        const durationFromStartEndTime = calculateDurationFromStartAndEndTimes(startTime, endTime);
        let durationValue = 0;
        if (this.isBlank(durationFromStartEndTime)) durationValue = this.props.duration;
        else durationValue = durationFromStartEndTime;
        const durationAsMilli = durationStringToDuration(durationValue).asMilliseconds();
        const hoursOffAsMilli = durationStringToDuration(offHours).asMilliseconds();
        durationValue = durationAsMilli - hoursOffAsMilli;
        this.props.onEntryFieldUpdated('duration', durationToString(durationValue));
        this.setState({ isMileageCalculatorOpen: false });
    };

    isBlank(hours) {
        return hours === '' || hours === ' ' || hours === '0:00';
    }

    render() {
        const {
            qbClasses,
            employees,
            customers,
            inventoryItems,
            payrollItems,
            shouldShowBillable,
            shouldShowTaxable,
            shouldShowVendors,
            canEditOthersTime,
            mode,
            entryType,
            qbClassId,
            employeeId,
            inventoryItemId,
            customerId,
            payrollItemId,
            durations,
            billable,
            taxable,
            duration,
            hoursOffDuty,
            startTime,
            endTime,
            description,
            date,
            isSaving,
            onSavePressed,
            onResetPressed,
            onNextWeek,
            onPrevWeek,
            isCollapsed,
            onCollapsePressed,
            startOfWeek,
            no_integration,
            filterdInventoryItems,
        } = this.props;
        const startOfWeekMoment = moment(startOfWeek);
        const startTimeMoment = moment(startTime, 'HH:mm:ss');
        const endTimeMoment = moment(endTime, 'HH:mm:ss');

        const formattedWeekdays = _.range(0, 7).map(name => {
            const date = startOfWeekMoment.toDate();
            const day = startOfWeekMoment.format('ddd');
            const formattedDate = startOfWeekMoment.format('dd M/D');
            startOfWeekMoment.add(1, 'd');

            return {
                day,
                date,
                formattedDate,
            };
        });
        
        return (
            <div className="tsTimeEntrySection collapsiblePanel">
                <button
                    className={classNames({
                        secondaryButton: true,
                        collapsedToggleButton: true,
                        isCollapsed,
                    })}
                    onClick={onCollapsePressed}
                >
                    <Icon className="icon" icon={isCollapsed ? caretRight : caretDown} size={18} />{' '}
                    Quick Add
                </button>

                <div
                    className={classNames({
                        entryFormCard: true,
                        panelContent: true,
                        isCollapsed,
                    })}
                >
                    <div className="entryWrapper">
                        <div className="entryContainer">
                            <div className="tsEntryCustomerDetailsContainer">
                                <h3 className="formGroupHeading">Customer &amp; Job Details</h3>
                                <input
                                    className="initialFocusInput"
                                    ref={initialFocusInput =>
                                        (this.initialFocusInput = initialFocusInput)
                                    }
                                />
                                <div className="fields">
                                    {canEditOthersTime && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Employee</label>
                                            <Select
                                                className="dropdownContainer"
                                                withSearch
                                                options={employees}
                                                value={employeeId}
                                                buttonsList={this.handleButtonList(
                                                    no_integration,
                                                    shouldShowVendors
                                                )}
                                                onChange={this.handleEmployeeSelected}
                                            />
                                        </div>
                                    )}

                                    <div className="stackedLabelAndDropdown fieldGroup">
                                        <label className="dropdownLabel">Customer &amp; Job</label>
                                        <Select
                                            className="dropdownContainer"
                                            options={customers}
                                            withSearch
                                            labelWhenNothingSelected="No Customer Selected"
                                            value={customerId}
                                            buttonsList={this.handleCustomerButtonList(
                                                no_integration
                                            )}
                                            onChange={this.handleCustomerSelected}
                                        />
                                    </div>
                                    {no_integration === false && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Service</label>
                                            <Select
                                                className="dropdownContainer"
                                                withSearch
                                                options={inventoryItems}
                                                labelWhenNothingSelected="No Service Selected"
                                                value={inventoryItemId}
                                                onChange={this.handleInventoryItemSelected}
                                            />
                                        </div>
                                    )}

                                    {no_integration === true && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Item</label>
                                            <Select
                                                className="dropdownContainer"
                                                withSearch
                                                options={filterdInventoryItems}
                                                labelWhenNothingSelected="No Item Selected"
                                                value={inventoryItemId}
                                                buttonsList={this.handleInventoryButtonList(
                                                    no_integration
                                                )}
                                                onChange={this.handleInventoryItemSelected}
                                            />
                                        </div>
                                    )}

                                    {this.shouldShowQbClasses && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Class</label>
                                            <Select
                                                className="dropdownContainer"
                                                withSearch
                                                options={qbClasses}
                                                labelWhenNothingSelected="No Class Selected"
                                                value={qbClassId}
                                                onChange={this.handleQbClassSelected}
                                                withClearOption
                                                clearOptionLabel="No Class Selected"
                                            />
                                        </div>
                                    )}

                                    {this.shouldShowPayroll && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Payroll Item</label>
                                            <Select
                                                className="dropdownContainer"
                                                withClearOption
                                                clearOptionLabel="No Payroll Item"
                                                labelWhenNothingSelected="No Payroll Item Selected"
                                                options={payrollItems}
                                                buttonsList={this.handlePayrollButtonList(
                                                    no_integration
                                                )}
                                                onChange={this.handlePayrollItemSelected}
                                                value={payrollItemId}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="tsEntryTimeContainer">
                                <h3 className="formGroupHeading">
                                    <div className="headingTitle">Time</div>
                                    <div className="headingMiddle">
                                        <TwoButtonSwitch
                                            firstButtonText="Day Entry"
                                            firstButtonValue="day"
                                            secondButtonText="Week Entry"
                                            secondButtonValue="week"
                                            onChange={this.handleEntryTypeChanged}
                                            value={entryType}
                                        />
                                    </div>
                                    <div className="headingActions">
                                        <button
                                            id="clearTimesheetForm"
                                            className="tertiaryButton"
                                            onClick={onResetPressed}
                                        >
                                            Clear Form
                                        </button>
                                        {entryType === 'week' && (
                                            <DateTimePicker
                                                mode="week"
                                                toggleStyle="labelAndIcon"
                                                toggleLabel="Pick Week"
                                                todayButtonLabel="This Week"
                                                className="tsWeekPicker"
                                                value={startOfWeek}
                                                onChange={this.handleStartOfWeekChanged}
                                            />
                                        )}
                                    </div>
                                </h3>
                                {entryType === 'day' && (
                                    <div className="fieldGroupRow">
                                        <div className="stackedLabelAndInput fieldGroup">
                                            <label className="inputLabel">Duration</label>
                                            <DurationInput
                                                onDurationChanged={this.handleDurationChanged}
                                                onDurationBlur={this.onDurationBlur}
                                                onDurationFocus={this.onDurationFocus}
                                                startTime={startTimeMoment}
                                                endTime={endTimeMoment}
                                                duration={duration}
                                                withStartAndEndTime
                                                hoursOffDuty={hoursOffDuty}
                                                calculateDuration={this.calculateDuration}
                                                updateHoursOffProps={this.updateHoursOffProps}
                                                updateStartTimeProps={this.updateStartTimeProps}
                                                updateEndTimeProps={this.updateEndTimeProps}
                                            />
                                        </div>

                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Date</label>
                                            <DateTimePicker
                                                toggleStyle="input"
                                                value={date}
                                                onChange={this.handleDateChanged}
                                            />
                                        </div>
                                    </div>
                                )}

                                {entryType === 'week' && (
                                    <WeekEntryInput
                                        formattedWeekdays={formattedWeekdays}
                                        onDurationBlur={this.onDurationBlur}
                                        onDurationFocus={this.onDurationFocus}
                                        handleTimeFieldChanged={this.handleTimeFieldChanged}
                                        durations={durations}
                                        handlePreviousWeekPressed={onPrevWeek}
                                        handleNextWeekPressed={onNextWeek}
                                    />
                                )}
                                <div className="stackedLabelAndInput fullWidthField fieldGroup">
                                    <label className="inputLabel">Description</label>
                                    <DescriptionInput
                                        value={description ? description : ''}
                                        onChange={this.handleDescriptionChanged}
                                    />
                                </div>

                                <div
                                    className={classNames({
                                        tsBillableAndActions: true,
                                        tsSingleActionOnly: !shouldShowBillable,
                                    })}
                                >
                                    {shouldShowBillable && (
                                        <div className="tsBillableButtonContainer">
                                            <div
                                                className="toggleBillableContainer noselect"
                                                onClick={this.handleBillableToggled}
                                            >
                                                <Icon
                                                    className="toggleBillableIcon"
                                                    size={18}
                                                    icon={
                                                        billable === 'Yes'
                                                            ? checkboxChecked
                                                            : checkboxUnchecked
                                                    }
                                                />
                                                Billable
                                            </div>
                                            {shouldShowTaxable && billable === 'Yes' && (
                                                <div
                                                    className="toggleTaxableContainer noselect"
                                                    onClick={this.handleTaxableToggled}
                                                >
                                                    <Icon
                                                        className="toggleTaxableIcon"
                                                        size={18}
                                                        icon={
                                                            taxable === 'Yes'
                                                                ? checkboxChecked
                                                                : checkboxUnchecked
                                                        }
                                                    />
                                                    Taxable
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {isSaving && (
                                        <div className="entryActions">
                                            <SaveLoading />
                                        </div>
                                    )}
                                    {!isSaving && (
                                        <div className="entryActions">
                                            <button
                                                className="primaryButton"
                                                onClick={onSavePressed}
                                            >
                                                {mode === 'add' || mode === 'duplicate'
                                                    ? 'Add Timesheet'
                                                    : 'Update Timesheet'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
