import React from 'react';
import Icon from 'react-icons-kit';
import { ic_error_outline as errorIcon } from 'react-icons-kit/md/ic_error_outline';

const Error404 = () => (
    <div className="infoPage">
        <Icon size={72} icon={errorIcon} className="errorIcon" />

        <h1>We can’t find the page you're looking for.</h1>
        <p>
            Try taking a look at our <a href="/help">Help</a> section, or contact support.
        </p>
    </div>
);

export default Error404;
