import React from 'react';
import Icon from 'react-icons-kit';
import { ic_error_outline as errorIcon } from 'react-icons-kit/md/ic_error_outline';

const RefreshPage = () => (
    <div className="infoPage">
        <Icon size={72} icon={errorIcon} className="errorIcon" />

        <h1>Something went wrong!</h1>
        <p>Please refresh this page.</p>
    </div>
);

export default RefreshPage;
