import React from 'react';

const ApprovedAndDisapproved = () => (
    <div>
        <div className="sectionQuestion">What are "approved" and "disapproved" time entries?</div>
        <div className="sectionAnswer">
            Approved time entries <b>will</b> be exported with QuickBooks on the next sync. <b />
            Disapproved time entries <b>will not</b> be exported with QuickBooks on the next sync
            <br /> <br />
            An Approved time entry will me marked by a label:{' '}
            <span className="tsStatus tsStatusApproved">Approved for Sync</span> <br />A Disapproved
            time entry will be marked by a label:{' '}
            <span className="tsStatus tsStatusPending">Pending Approval</span>
            <br /> <br />
            Time entries can also be set to be either approved or disapproved by default by going to
            your account settings page and setting the option that says Time entries are marked
            'Approved' by default to either 'Yes' or 'No'.
        </div>
    </div>
);

export default ApprovedAndDisapproved;
