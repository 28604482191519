import React from 'react';

const UpdateBilling = () => (
    <div>
        <div className="sectionQuestion">
            How do I update the Billing Information for our account?
        </div>
        <div className="sectionAnswer">
            Here are the steps for updating the billing information in your account:
            <ol>
                <li>The Account Manager will need to log into your account at Minute7.com</li>
                <li>
                    Once you are logged in click on the Settings tab located in the header bar at
                    the top of the page
                </li>
                <li>
                    Click on the blue "Edit" button to the right of "Billing Information" as shown
                    below:
                    <div className="listImageContainer">
                        <img src="./help_images/UpdateBilling.png" alt="Billing Information" />
                    </div>
                </li>
                <li>
                    Enter your credit card information into the dialog that appears and hit "Update
                    Info"
                </li>
            </ol>
        </div>
    </div>
);

export default UpdateBilling;
