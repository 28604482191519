import React from 'react';

const PayrollWithTime = () => (
    <div>
        <div className="sectionQuestion">
            Can Minute7 associate QuickBooks payroll items with time entries?
        </div>
        <div className="sectionAnswer">
            Yes! You can set your Minute7 account to show and use payroll items on your Account
            Settings page. If your account has "Show payroll items for time entries" set to Yes,
            payroll items can be assigned to Employees who use time data to create paychecks in
            QuickBooks.
            <br /> <br />
            Users with the "Edit Payroll" permission will be able to assign and edit payroll items
            for time entries. If a user has "Edit Payroll" set to No, she will be able to see any
            payroll items assigned to her time entries, but she will not be able to alter the
            payroll items.
        </div>
    </div>
);

export default PayrollWithTime;
