import _ from 'lodash';
import { createSelector } from 'reselect';

const nameSort = item => (item.name || item.description|| '').toLowerCase();

export const selectAllInventoryItemIds = ({ businessResourcesStore }) =>
    businessResourcesStore.inventoryItems;
export const selectAllInventoryItemsById = ({ businessResourcesStore }) =>
    businessResourcesStore.inventoryItemsById;

export const selectAllCustomerIds = ({ businessResourcesStore }) =>
    businessResourcesStore.customers;
export const selectAllCustomersById = ({ businessResourcesStore }) =>
    businessResourcesStore.customersById;

export const selectAllQbAccountIds = ({ businessResourcesStore }) =>
    businessResourcesStore.qbAccounts;
export const selectAllQbAccountsById = ({ businessResourcesStore }) =>
    businessResourcesStore.qbAccountsById;

export const selectAllQbClassIds = ({ businessResourcesStore }) => businessResourcesStore.qbClasses;
export const selectAllQbClassesById = ({ businessResourcesStore }) =>
    businessResourcesStore.qbClassesById;

export const selectInventoryItemIdForCurrentlyEditedTimeEntry = ({ timesheetsStore }) =>
    timesheetsStore.editInventoryItemId;

export const selectShouldFilterInventoryItems = ({ accountUserStore }) =>
    accountUserStore.filter_inventory_item_list === 'Yes' &&
    !_.isEmpty(accountUserStore.filter_inventory_item_ids);

export const selectShouldShowServiceItem = ({ accountStore }) =>
    accountStore.show_service_items_for_expense && accountStore.qb_type !== 'Online';

export const selectAllowedInventoryItemIds = ({ accountUserStore }) =>
    accountUserStore.filter_inventory_item_ids;

export const selectAllInventoryItems = createSelector(
    [selectAllInventoryItemIds, selectAllInventoryItemsById],
    (ids, byId) => _.sortBy(ids.map(id => byId[id]), nameSort)
);

export const selectFilterItems = (items, serviceItem) => {
    return serviceItem ? items : items.filter(item => item.type === 'Inventory');
};

export const selectAllowedInventoryItems = createSelector(
    [
        selectAllInventoryItemIds,
        selectAllInventoryItemsById,
        selectShouldFilterInventoryItems,
        selectAllowedInventoryItemIds,
    ],
    (inventoryItemIds, inventoryItemsById, shouldFilter, allowedIds) => {
        let items;
        if (shouldFilter) {
            const ids = String(allowedIds).split(',');
            items = _.compact(ids.map(id => inventoryItemsById[id]));
        } else {
            items = inventoryItemIds.map(id => inventoryItemsById[id]);
        }

        return _.sortBy(items.filter(item => item.hidden === 'No'), nameSort);
    }
);

export const selectAllowedInventoryItemsOfTypeInventory = createSelector(
    [selectAllowedInventoryItems, selectShouldShowServiceItem],
    (inventoryItems, serviceItem) => {
        return selectFilterItems(inventoryItems, serviceItem);
    }
);

export const selectAllowedInventoryItemsForEditing = createSelector(
    [
        selectAllInventoryItemIds,
        selectAllInventoryItemsById,
        selectShouldFilterInventoryItems,
        selectAllowedInventoryItemIds,
        selectInventoryItemIdForCurrentlyEditedTimeEntry,
    ],
    (
        inventoryItemIds,
        inventoryItemsById,
        shouldFilter,
        allowedIds,
        inventoryItemIdForCurrentlyEditedTimeEntry,
    ) => {
        let items;
        if (shouldFilter) {
            const ids = String(allowedIds).split(',');
            if (
                inventoryItemIdForCurrentlyEditedTimeEntry !== '0' &&
                !ids.includes(inventoryItemIdForCurrentlyEditedTimeEntry)
            ) {
                ids.push(inventoryItemIdForCurrentlyEditedTimeEntry);
            }

            items = _.compact(ids.map(id => inventoryItemsById[id]));
        } else {
            items = inventoryItemIds.map(id => inventoryItemsById[id]);
        }

        return _.sortBy(items.filter(item => item.hidden === 'No'), nameSort);
    }
);

export const selectShouldFilterCustomers = ({ accountUserStore }) =>
    accountUserStore.filter_customer_list === 'Yes' &&
    !_.isEmpty(accountUserStore.filter_customer_ids);

export const selectAllCustomers = createSelector(
    [selectAllCustomerIds, selectAllCustomersById],
    (ids, byId) => _.sortBy(ids.map(id => byId[id]), nameSort)
);

export const selectAllowedCustomerIds = ({ accountUserStore }) =>
    accountUserStore.filter_customer_ids;

export const selectCustomerIdForCurrentlyEditedEntry = ({ timesheetsStore, expensesStore }) => {
    if (
        timesheetsStore.selectedTimeEntry !== null ||
        timesheetsStore.selectedTimeEntryHash !== null
    ) {
        return timesheetsStore.editCustomerId;
    }

    if (expensesStore.selectedExpenseEntry !== null) {
        return expensesStore.editCustomerId;
    }

    return '0';
};

export const selectAllowedCustomers = createSelector(
    [
        selectAllCustomerIds,
        selectAllCustomersById,
        selectShouldFilterCustomers,
        selectAllowedCustomerIds,
    ],
    (customerIds, customersById, shouldFilter, allowedIds) => {
        let customers;
        if (shouldFilter) {
            const ids = String(allowedIds).split(',');
            customers = _.compact(ids.map(id => customersById[id]));
        } else {
            customers = customerIds.map(id => customersById[id]);
        }

        return _.sortBy(customers.filter(customer => customer.hidden === 'No'), nameSort);
    }
);

export const selectAllowedCustomersForEditing = createSelector(
    [
        selectAllCustomerIds,
        selectAllCustomersById,
        selectShouldFilterCustomers,
        selectAllowedCustomerIds,
        selectCustomerIdForCurrentlyEditedEntry,
    ],
    (customerIds, customersById, shouldFilter, allowedIds, customerIdForCurrentlyEditedEntry) => {
        let customers;
        if (shouldFilter) {
            const ids = String(allowedIds).split(',');

            // make sure the customer id of the currently-edited entry is
            // always in the list of allowed customers, so it can show up in the dropdown
            if (
                customerIdForCurrentlyEditedEntry !== '0' &&
                !ids.includes(customerIdForCurrentlyEditedEntry)
            ) {
                ids.push(customerIdForCurrentlyEditedEntry);
            }
            customers = _.compact(ids.map(id => customersById[id]));
        } else {
            customers = customerIds.map(id => customersById[id]);
        }

        return _.sortBy(customers.filter(customer => customer.hidden === 'No'), nameSort);
    }
);

export const selectQbAccountIdForCurrentlyEditedEntry = ({ expensesStore }) => {
    if (expensesStore.selectedExpenseEntry !== null) {
        return expensesStore.editQbAccountId;
    }

    return '0';
};

export const selectShouldFilterQbAccounts = ({ accountUserStore }) =>
    accountUserStore.filter_qb_account_list === 'Yes' &&
    !_.isEmpty(accountUserStore.filter_qb_account_ids);

export const selectAllQbAccounts = createSelector(
    [selectAllQbAccountIds, selectAllQbAccountsById],
    (ids, byId) => _.sortBy(ids.map(id => byId[id]), nameSort)
);

export const selectAllowedQbAccountIds = ({ accountUserStore }) =>
    accountUserStore.filter_qb_account_ids;

export const selectAllowedQbAccounts = createSelector(
    [
        selectAllQbAccountIds,
        selectAllQbAccountsById,
        selectShouldFilterQbAccounts,
        selectAllowedQbAccountIds,
    ],
    (qbAccountIds, qbAccountsById, shouldFilter, allowedIds) => {
        let qbAccounts;
        if (shouldFilter) {
            const ids = String(allowedIds).split(',');
            qbAccounts = _.compact(ids.map(id => qbAccountsById[id]));
        } else {
            qbAccounts = qbAccountIds.map(id => qbAccountsById[id]);
        }

        return _.sortBy(qbAccounts, nameSort);
    }
);

export const selectAllowedQbAccountsForEditing = createSelector(
    [
        selectAllQbAccountIds,
        selectAllQbAccountsById,
        selectShouldFilterQbAccounts,
        selectAllowedQbAccountIds,
        selectQbAccountIdForCurrentlyEditedEntry,
    ],
    (
        qbAccountIds,
        qbAccountsById,
        shouldFilter,
        allowedIds,
        qbAccountIdForCurrentlyEditedEntry
    ) => {
        let qbAccounts;
        if (shouldFilter) {
            const ids = String(allowedIds).split(',');
            if (
                qbAccountIdForCurrentlyEditedEntry !== '0' &&
                !ids.includes(qbAccountIdForCurrentlyEditedEntry)
            ) {
                ids.push(qbAccountIdForCurrentlyEditedEntry);
            }

            qbAccounts = _.compact(ids.map(id => qbAccountsById[id]));
        } else {
            qbAccounts = qbAccountIds.map(id => qbAccountsById[id]);
        }

        return _.sortBy(qbAccounts, nameSort);
    }
);

export const selectQbClassIdForCurrentlyEditedEntry = ({ timesheetsStore, expensesStore }) => {
    if (
        timesheetsStore.selectedTimeEntry !== null ||
        timesheetsStore.selectedTimeEntryHash !== null
    ) {
        return timesheetsStore.editQbClassId;
    }

    if (expensesStore.selectedExpenseEntry !== null) {
        return expensesStore.editQbClassId;
    }

    return '0';
};

export const selectShouldFilterQbClasses = ({ accountUserStore }) =>
    accountUserStore.filter_qb_class_list === 'Yes' &&
    !_.isEmpty(accountUserStore.filter_qb_class_ids);

export const selectAllQbClasses = createSelector(
    [selectAllQbClassIds, selectAllQbClassesById],
    (ids, byId) => _.sortBy(ids.map(id => byId[id]), nameSort)
);

export const selectAllowedQbClassIds = ({ accountUserStore }) =>
    accountUserStore.filter_qb_class_ids;

export const selectAllowedQbClasses = createSelector(
    [
        selectAllQbClassIds,
        selectAllQbClassesById,
        selectShouldFilterQbClasses,
        selectAllowedQbClassIds,
    ],
    (qbClassIds, qbClassesById, shouldFilter, allowedIds) => {
        let qbClasses;
        if (shouldFilter) {
            const ids = String(allowedIds).split(',');
            qbClasses = _.compact(ids.map(id => qbClassesById[id]));
        } else {
            qbClasses = qbClassIds.map(id => qbClassesById[id]);
        }

        return _.sortBy(qbClasses, nameSort);
    }
);

export const selectAllowedQbClassesForEditing = createSelector(
    [
        selectAllQbClassIds,
        selectAllQbClassesById,
        selectShouldFilterQbClasses,
        selectAllowedQbClassIds,
        selectQbClassIdForCurrentlyEditedEntry,
    ],
    (qbClassIds, qbClassesById, shouldFilter, allowedIds, qbClassIdForCurrentlyEditedEntry) => {
        let qbClasses;
        if (shouldFilter) {
            const ids = String(allowedIds).split(',');
            if (
                qbClassIdForCurrentlyEditedEntry !== '0' &&
                !ids.includes(qbClassIdForCurrentlyEditedEntry)
            ) {
                ids.push(qbClassIdForCurrentlyEditedEntry);
            }

            qbClasses = _.compact(ids.map(id => qbClassesById[id]));
        } else {
            qbClasses = qbClassIds.map(id => qbClassesById[id]);
        }

        return _.sortBy(qbClasses, nameSort);
    }
);

export const selectUnsyncedExpenseGroupingsForVendorId = (
    { businessResourcesStore },
    vendorId,
    currentValue,
    transactionType
) =>
    (businessResourcesStore.expenseGroupingsByVendorId[vendorId] || []).filter(e =>
        e.id === currentValue ? true : e.exported === 'No' && e.transaction_type === transactionType
    );
