import React from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import classNames from 'classnames';
import { reactivateAccount } from '../../actions/AccountActions';
import styles from '../../css/ReactivateAccount.module.css';

const MessageAdmin = ({ reactivateAccount, toggleBillingInfoVisibility, accountStore }) => {
  const { suspended } = accountStore;
  const handleReactiveAccount = () => {
    suspended === 'Yes' ? toggleBillingInfoVisibility() : reactivateAccount();
  }

  return (
    <div className="application">
      <DocumentTitle title="Activate Your Account" />

      <div className="applicationBody">
        <div className={classNames('cardContainer', 'cardContent', styles.container)}>
          <h2 className="heading">Reactivate Your Account</h2>
          <div className={styles.details}>
            <p>
              Reactivating your Minute7 account is easy - just click below to confirm
              your reactivation, and your account will be restored, with all data
              intact.
            </p>
            <p>
              Upon reactivation, please make sure all billing and account information
              is updated and accurate.
            </p>
          </div>

          <button className="primaryButton" onClick={handleReactiveAccount}>
            Reactivate Account
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accountStore: state.accountStore,
  };
};

const mapDispatchToProps = {
  reactivateAccount,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageAdmin);
