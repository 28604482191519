import React from 'react';
import DocumentTitle from 'react-document-title';
import classNames from 'classnames';
import styles from '../../css/ReactivateAccount.module.css';

const MessageNonAdmin = () => (
  <div className="application">
    <DocumentTitle title="Minute7 Account is not active" />

    <div className="applicationBody">
      <div className={classNames('cardContainer', 'cardContent', styles.container)}>
        <h2 className="heading">Minute7 Account is not active</h2>
        <div className={styles.details}>
          <p>
            The Minute7 account you are associated with is currently deactivated by the Admin.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default MessageNonAdmin;
