import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import DocumentTitle from 'react-document-title';
import Icon from 'react-icons-kit';
import { ic_error_outline as errorIcon } from 'react-icons-kit/md/ic_error_outline';
import FloatingSaveButton from '../../components/FloatingButton.js';
import Permissions from './Permissions';
import { permissionsGroupsFields } from '../../../config/Fields.js';
import {
    updatePermissionsGroup,
    addPermissionsGroup,
} from '../../../actions/BusinessResourcesActions';
import { isCompanyNameValid, companyNameError } from '../../../utils/CommonFunctions.js';
import * as expensesSelectors from '../../../selectors/ExpensesSelectors';
import { showToast } from '../../../actions/Actions.js';
import * as resourceSelectors from '../../../selectors/BusinessResourcesSelectors';
import * as accountSelectors from '../../../selectors/AccountSelectors';

class PermissionsGroup extends Component {
    constructor(props) {
        super(props);

        this.state = this.stateForPermissionsGroup(this.props.permissionsGroup);
    }

    componentWillReceiveProps(props) {
        if (
            props.permissionsGroup !== this.props.permissionsGroup &&
            props.permissionsGroup &&
            !props.isSaving
        ) {
            this.setState(this.stateForPermissionsGroup(props.permissionsGroup));
        }

        if (props.isSaving !== this.props.isSaving && !props.isSaving) {
            this.props.history.goBack();
        }
    }

    stateForPermissionsGroup = permissions => {
        return {
            account_id: this.props.accountStore.id,
            name: permissions ? permissions.name : '',
            manage_account: permissions ? permissions.manage_account : 'No',
            manage_users: permissions ? permissions.manage_users : 'No',
            manage_api: permissions ? permissions.manage_api : 'No',
            export_time: permissions ? permissions.export_time : 'No',
            view_reports: permissions ? permissions.view_reports : 'No',
            edit_payroll: permissions ? permissions.edit_payroll : 'No',
            manage_payroll_payrates: permissions ? permissions.manage_payroll_payrates : 'No',
            manage_item_billrates: permissions ? permissions.manage_item_billrates: 'No',

            // Time
            edit_own_time: permissions ? permissions.edit_own_time : 'No',

            // VIEW_OTHERS_TIME
            view_others_time: permissions ? permissions.view_others_time : 'No',
            filter_view_employee_ids: permissions
                ? permissions.filter_view_employee_ids.split(',')
                : [],
            // EDIT_OTHERS_TIME
            edit_others_time: permissions ? permissions.edit_others_time : 'No',
            filter_edit_employee_ids: permissions
                ? permissions.filter_edit_employee_ids.split(',')
                : [],
            // APPROVE_OTHERS_TIME
            approve_others_time: permissions ? permissions.approve_others_time : 'No',
            filter_approve_employee_ids: permissions
                ? permissions.filter_approve_employee_ids.split(',')
                : [],
            // Expenses
            edit_own_expenses: permissions ? permissions.edit_own_expenses : 'No',
            // VIEW_OTHERS_EXPENSES
            view_others_expenses: permissions ? permissions.view_others_expenses : 'No',
            filter_view_expense_vendor_ids: permissions
                ? permissions.filter_view_expense_vendor_ids.split(',')
                : [],
            // EDIT_OTHERS_EXPENSES
            edit_others_expenses: permissions ? permissions.edit_others_expenses : 'No',
            filter_edit_expense_vendor_ids: permissions
                ? permissions.filter_edit_expense_vendor_ids.split(',')
                : [],
            // APPROVE_OTHERS_EXPENSES
            approve_others_expenses: permissions ? permissions.approve_others_expenses : 'No',
            filter_approve_expense_vendor_ids: permissions
                ? permissions.filter_approve_expense_vendor_ids.split(',')
                : [],
            // Misc
            // FILTER CUSTOMERS
            filter_customer_list: permissions ? permissions.filter_customer_list : 'No',
            filter_customer_ids: permissions ? permissions.filter_customer_ids.split(',') : [],
            // FILTER INVENTORY_ITEMS
            filter_inventory_item_list: permissions ? permissions.filter_inventory_item_list : 'No',
            filter_inventory_item_ids: permissions
                ? permissions.filter_inventory_item_ids.split(',')
                : [],
            // FILTER QB_ACCOUNTS
            filter_qb_account_list: permissions ? permissions.filter_qb_account_list : 'No',
            filter_qb_account_ids: permissions ? permissions.filter_qb_account_ids.split(',') : [],
            // FILTER QB_CLASSES
            filter_qb_class_list: permissions ? permissions.filter_qb_class_list : 'No',
            filter_qb_class_ids: permissions ? permissions.filter_qb_class_ids.split(',') : [],
            hasBeenEdited: false,
        };
    };

    validate = () => {
        const {
            name,
            filter_view_employee_ids: filterViewEmployeeIds,
            filter_edit_employee_ids: filterEditEmployeeIds,
            filter_approve_employee_ids: filterApproveEmployeeIds,
            filter_view_expense_vendor_ids: filterViewExpenseVendorIds,
            filter_edit_expense_vendor_ids: filterEditExpenseVendorIds,
            filter_approve_expense_vendor_ids: filterApproveExpenseVendorIds,
            filter_customer_ids: filterCustomerIds,
            filter_inventory_item_ids: filterInventoryItemIds,
            filter_qb_account_ids: filterQbAccountIds,
            filter_qb_class_ids: filterQbClassIds,
            manage_users: manageUsers,
            view_others_time: viewOthersTime,
            edit_others_time: editOthersTime,
            approve_others_time: approveOthersTime,
            view_others_expenses: viewOthersExpenses,
            edit_others_expenses: editOthersExpenses,
            approve_others_expenses: approveOthersExpenses,
            filter_customer_list: filterCustomerList,
            filter_inventory_item_list: filterInventoryItemList,
            filter_qb_account_list: filterQbAccountList,
            filter_qb_class_list: filterQbClassList,
        } = this.state;

        const { showToast } = this.props;

        if (name === '') {
            showToast('Please fill out the name of this permissions group', 'warning');
            return false;
        }

        if (manageUsers === 'No') {
            if (viewOthersTime === 'Filtered' && filterViewEmployeeIds.length === 0) {
                showToast(
                    'Please select at least one employee this user can view time entries for.',
                    'warning'
                );
                return false;
            }

            if (editOthersTime === 'Filtered' && filterEditEmployeeIds.length === 0) {
                showToast(
                    'Please select at least one employee this user can edit time entries for.',
                    'warning'
                );
                return false;
            }

            if (approveOthersTime === 'Filtered' && filterApproveEmployeeIds.length === 0) {
                showToast(
                    'Please select at least one employee this user can approve time entries for.',
                    'warning'
                );
                return false;
            }

            if (viewOthersExpenses === 'Filtered' && filterViewExpenseVendorIds.length === 0) {
                showToast(
                    'Please select at least one employee this user can view expense entries for.',
                    'warning'
                );
                return false;
            }

            if (editOthersExpenses === 'Filtered' && filterEditExpenseVendorIds.length === 0) {
                showToast(
                    'Please select at least one employee this user can edit expense entries for.',
                    'warning'
                );
                return false;
            }

            if (
                approveOthersExpenses === 'Filtered' &&
                filterApproveExpenseVendorIds.length === 0
            ) {
                showToast(
                    'Please select at least one employee this user can approve expense entries for.',
                    'warning'
                );
                return false;
            }
        }

        if (filterCustomerList === 'Yes' && filterCustomerIds.length === 0) {
            showToast(
                'Please select at least one customer this user should be restricted to.',
                'warning'
            );
            return false;
        }

        if (filterInventoryItemList === 'Yes' && filterInventoryItemIds.length === 0) {
            showToast(
                'Please select at least one service this user should be restricted to.',
                'warning'
            );
            return false;
        }

        if (filterQbAccountList === 'Yes' && filterQbAccountIds.length === 0) {
            showToast(
                'Please select at least one expense entry account or credit card payment account this user should be restricted to.',
                'warning'
            );
            return false;
        }

        if (filterQbClassList === 'Yes' && filterQbClassIds.length === 0) {
            showToast(
                'Please select at least one QuickBooks class this user should be restricted to.',
                'warning'
            );
            return false;
        }

        return true;
    };

    savePermissionsGroup = () => {
        const isValid = this.validate();
        if (!isValid) {
            return;
        }

        const data = _.pick(
            { ...this.props.permissionsGroup, ...this.state },
            permissionsGroupsFields
        );

        if (this.props.permissionsGroup) {
            this.props.updatePermissionsGroup(data);
        } else {
            this.props.addPermissionsGroup(data);
        }
    };

    setFields = fields => {
        this.setState({ ...fields, hasBeenEdited: true });
    };

    render() {
        const {
            permissionsGroup,
            match,
            customers,
            inventoryItems,
            qbClasses,
            qbAccounts,
            employees,
            vendors,
            allowedQbAccounts,
            allowedQbPaymentAccounts,
        } = this.props;

        const { name } = this.state;

        if (match && match.params && match.params.id && !permissionsGroup) {
            return (
                <div className="infoPage">
                    <Icon size={72} icon={errorIcon} className="errorIcon" />

                    <h1>We can’t find the permissions group you're looking for.</h1>
                    <p>You can find a list of all permissions groups on the Settings tab.</p>
                </div>
            );
        }

        const permissionsProps = {
            manageUsers: this.state.manage_users,
            manageApi: this.state.manage_api,
            customers,
            inventoryItems,
            qbAccounts,
            qbClasses,
            employees,
            vendors,
            allowedQbAccounts,
            allowedQbPaymentAccounts,
            exportTime: this.state.export_time,
            viewReports: this.state.view_reports,
            editPayroll: this.state.edit_payroll,
            viewOthersTime: this.state.view_others_time,
            filterViewEmployeeIds: this.state.filter_view_employee_ids,
            editOthersTime: this.state.edit_others_time,
            filterEditEmployeeIds: this.state.filter_edit_employee_ids,
            approveOthersTime: this.state.approve_others_time,
            filterApproveEmployeeIds: this.state.filter_approve_employee_ids,
            viewOthersExpenses: this.state.view_others_expenses,
            filterViewExpenseVendorIds: this.state.filter_view_expense_vendor_ids,
            editOthersExpenses: this.state.edit_others_expenses,
            filterEditExpenseVendorIds: this.state.filter_edit_expense_vendor_ids,
            approveOthersExpenses: this.state.approve_others_expenses,
            filterApproveExpenseVendorIds: this.state.filter_approve_expense_vendor_ids,
            filterCustomerList: this.state.filter_customer_list,
            filterCustomerIds: this.state.filter_customer_ids,
            filterInventoryItemList: this.state.filter_inventory_item_list,
            filterInventoryItemIds: this.state.filter_inventory_item_ids,
            filterQbAccountList: this.state.filter_qb_account_list,
            filterQbAccountIds: this.state.filter_qb_account_ids,
            filterQbClassList: this.state.filter_qb_class_list,
            filterQbClassIds: this.state.filter_qb_class_ids,
            managePayrollPayrates: this.state.manage_payroll_payrates,
            manageItemBillrates: this.state.manage_item_billrates,

            expenseEntityType: 'Vendors',
            onChange: this.setFields,
            showClockInOnlySetting: false,
            no_integration: this.props.no_integration
        };

        return (
            <div className="applicationBody">
                <DocumentTitle
                    title={
                        permissionsGroup
                            ? `Edit "${permissionsGroup.name}" Permissions Group`
                            : 'Add Permissions Group'
                    }
                />

                <FloatingSaveButton
                    buttonText={'Save'}
                    hasBeenEdited={this.state.hasBeenEdited}
                    onSave={this.savePermissionsGroup}
                />
                <div className="bodyHeader">
                    <h2>
                        {permissionsGroup
                            ? 'Edit ' + permissionsGroup.name
                            : 'Add Permissions Group'}
                    </h2>
                    <div className="headerActions">
                        <button
                            className="tertiaryButton"
                            onClick={() => this.props.history.push('/account_settings')}
                        >
                            Back to Account Settings
                        </button>
                    </div>
                </div>
                <div>
                    <h3 className="cardHeading">Group Name</h3>
                    <div className="cardContainer settingsSection">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Name</div>
                            <div className="settingsInput">
                                <Input
                                    className={
                                        name !== '' && name !== null && !isCompanyNameValid(name)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={name}
                                    onChange={newName =>
                                        this.setState({
                                            name: newName.target.value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            {name !== '' && !isCompanyNameValid(name) ? (
                                <lable className="error-message">
                                    {companyNameError(this.state.name)}
                                    Only Alphanumeric and some Special Symbols (' - & . , _) between
                                    between 3 to 100 characters
                                </lable>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>

                <Permissions {...permissionsProps} />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { match } = ownProps;
    const { permissionsGroups } = state.businessResourcesStore;

    let permissionsGroup;

    if (match && match.params && match.params.id) {
        permissionsGroup = permissionsGroups.find(p => p.id === match.params.id);
    }
    const no_integration = state.accountStore.qb_type === 'None' ? true : false;
    return {
        accountStore: state.accountStore,
        isSaving: state.businessResourcesStore.isSavingPermissionsGroup,
        permissionsGroup,
        customers: resourceSelectors.selectAllCustomers(state),
        inventoryItems: resourceSelectors.selectAllInventoryItems(state),
        qbClasses: resourceSelectors.selectAllQbClasses(state),
        employees: accountSelectors.selectCanEditActiveEmployees(state),
        vendors: accountSelectors.selectCanEditActiveVendors(state),
        allowedQbAccounts: expensesSelectors.selectAllowedQbAccountsForExpenses(state),
        allowedQbPaymentAccounts: expensesSelectors.selectAllowedQbPaymentAccountsForExpenses(
            state
        ),
        no_integration
    };
}

const mapDispatchToProps = {
    showToast,
    updatePermissionsGroup,
    addPermissionsGroup,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PermissionsGroup);
