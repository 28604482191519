export const setAllPayrollItems = payload => ({
    type: 'SET_ALL_PAYROLL_ITEM',
    payload,
});

export const addPayrollItem = (payrollItem, callback) => ({
    type: 'ADD_PAYROLL_ITEM',
    payrollItem,
    callback,
});

export const addPayrollItemList = () => ({
    type: 'ADD_PAYROLL_ITEM_LIST',
});

export const addPayrollItemListError = () => ({
    type: 'ADD_PAYROLL_ITEM_LIST_ERROR',
});

export const addPayrollItemSuccess = payrollItem => ({
    type: 'ADD_PAYROLL_ITEM_SUCCESS',
    payrollItem,
});

export const addPayrollItemError = error => ({
    type: 'ADD_PAYROLL_ITEM_ERROR',
    error,
});

export const updatePayrollItem = (payrollItem, callback) => ({
    type: 'UPDATE_PAYROLL_ITEM',
    payrollItem,
    callback,
});

export const updatePayrollItemSuccess = payrollItem => ({
    type: 'UPDATE_PAYROLL_ITEM_SUCCESS',
    payrollItem,
});

export const updatePayrollItemError = error => ({
    type: 'UPDATE_PAYROLL_ITEM_ERROR',
    error,
});

export const hidePayrollItem = payrollItemId => ({
    type: 'DEACTIVATE_PAYROLL_ITEM',
    payrollItemId,
});

export const unhidePayrollItem = payrollItemId => ({
    type: 'ACTIVATE_PAYROLL_ITEM',
    payrollItemId,
});
