import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as api from '../requests/BusinessResourcesRequests';
import * as resourceActions from '../actions/BusinessResourcesActions';
import * as uiActions from '../actions/Actions';
import * as vendorActions from '../actions/VendorActions';
import { permissionsGroupsFields } from '../config/Fields';

const formDataForExpenseGrouping = entry => ({
    'ExpenseGrouping[account_id]': entry.account_id,
    'ExpenseGrouping[vendor_id]': entry.vendor_id,
    'ExpenseGrouping[id]': entry.id,
    'ExpenseGrouping[description]': entry.description,
    'ExpenseGrouping[payment_account_id]': entry.payment_account_id,
});

const formDataForPermissionsGroup = entry => {
    const data = {};
    _.forEach(_.pick(entry, permissionsGroupsFields), (value, field) => {
        if (field.includes('ids')) {
            data[`PermissionsGroup[${field}]`] = value.join(',');
        } else {
            data[`PermissionsGroup[${field}]`] = value;
        }
    });
    return data;
};

const selectEmployeePayrollItemIds = ({ employeeStore: { canEditEmployees: employees } }, id) => {
    const employee = employees.find(e => e.id === id);
    if (employee) {
        return employee.payroll_item_ids.split(',');
    }
    return [];
};

const selectResourcesLoaded = ({ businessResourcesStore }) =>
    businessResourcesStore.resourcesLoaded;

export function* getExpenseGroupingsForVendor({ vendorId }) {
    try {
        const params = {
            vendor_id: vendorId,
        };

        const expenseGroupings = yield call(api.getExpenseGroupings, params);
        yield put(
            resourceActions.receiveExpenseGroupingsForVendor({
                vendorId,
                expenseGroupings,
            })
        );
    } catch (err) {
        yield put(resourceActions.requestExpenseGroupingsForVendorError(err));
    }
}

export function* addExpenseGrouping({ expenseGrouping, callback }) {
    try {
        const formData = formDataForExpenseGrouping(expenseGrouping);
        const newEntry = yield call(api.addExpenseGrouping, formData);
        yield put(resourceActions.addExpenseGroupingSuccess(newEntry));
        if (typeof callback === 'function') {
            yield call(callback, newEntry);
        }
    } catch (err) {
        yield put(resourceActions.addExpenseGroupingError(err));
    }
}

export function* updateExpenseGrouping({ expenseGrouping, callback }) {
    try {
        const params = { id: expenseGrouping.id };
        const formData = formDataForExpenseGrouping(expenseGrouping);
        const updatedEntry = yield call(api.editExpenseGrouping, formData, params);
        yield put(resourceActions.updateExpenseGroupingSuccess(updatedEntry));
        if (typeof callback === 'function') {
            yield call(callback, updatedEntry);
        }
    } catch (err) {
        yield put(resourceActions.updateExpenseGroupingError(err));
    }
}

export function* getPayrollItemsForEmployee({ employeeId }) {
    try {
        const params = {
            employee_id: employeeId,
        };

        const payrollItems = yield call(api.getPayrollItems, params);
        const allowedIds = yield select(selectEmployeePayrollItemIds, employeeId);

        const allowedPayrollItems = payrollItems.filter(item => allowedIds.includes(item.id));

        yield put(
            resourceActions.receivePayrollItemsForEmployee({
                employeeId,
                payrollItems: allowedPayrollItems,
            })
        );
    } catch (err) {
        yield put(resourceActions.requestPayrollItemsForEmployeeError(err));
    }
}

export function* getBusinessResources() {
    try {
        const resources = yield call(api.getAllResources);
        yield put(resourceActions.receiveBusinessResources(resources, { replace: true }));
        yield put(vendorActions.refreshVendorsSuccess());
        const resourcesLoaded = yield select(selectResourcesLoaded);
        if (!resourcesLoaded) {
            yield put(resourceActions.resourcesLoaded());
        }
    } catch (err) {
        yield put(uiActions.showToast('Couldn’t load data from our servers', 'negative'));

        yield put(resourceActions.requestBusinessResourcesError(err));
    }
}

export function* updatePermissionsGroup({ permissionsGroup }) {
    try {
        const data = formDataForPermissionsGroup(permissionsGroup);
        const updatedPermissionsGroups = yield call(api.savePermissionsGroups, data);
        const updatedPermissionsGroup = updatedPermissionsGroups.find(
            ({ id }) => id === permissionsGroup.id
        );

        if (updatedPermissionsGroup) {
            yield put(resourceActions.updatePermissionsGroupSuccess(updatedPermissionsGroup));
            yield put(uiActions.showToast('Permissions group saved', 'positive'));
        } else {
            throw new Error('Couldn’t find updated permissions group in API response.');
        }
    } catch (error) {
        yield put(resourceActions.updatePermissionsGroupError(error));
        yield put(uiActions.showToast('Couldn’t save permissions group', 'negative'));
    }
}

export function* addPermissionsGroup({ permissionsGroup }) {
    try {
        const data = formDataForPermissionsGroup(permissionsGroup);
        const updatedPermissionsGroups = yield call(api.savePermissionsGroups, data);

        yield put(resourceActions.addPermissionsGroupSuccess(updatedPermissionsGroups));
        yield put(uiActions.showToast('Permissions group saved', 'positive'));
    } catch (error) {
        yield put(resourceActions.addPermissionsGroupError(error));
        yield put(uiActions.showToast('Couldn’t save permissions group', 'negative'));
    }
}

export function* deletePermissionsGroup({ payload }) {
    try {
        yield call(api.deletePermissionsGroups, payload);
        yield put(resourceActions.deletePermissionsGroupSuccess(payload));
        yield put(uiActions.showToast('Permissions group deleted', 'positive'));
    } catch (error) {
        yield put(
            uiActions.showToast('can not delete permission group with active users', 'negative')
        );
    }
}

export default function* root() {
    yield all([
        takeLatest(['REQUEST_EXPENSE_GROUPINGS_FOR_VENDOR'], getExpenseGroupingsForVendor),
        takeLatest(['REQUEST_PAYROLL_ITEMS_FOR_EMPLOYEE'], getPayrollItemsForEmployee),
        takeLatest(['REQUEST_BUSINESS_RESOURCES'], getBusinessResources),
        takeLatest(['ADD_EXPENSE_GROUPING'], addExpenseGrouping),
        takeLatest(['UPDATE_EXPENSE_GROUPING'], updateExpenseGrouping),
        takeLatest(['UPDATE_PERMISSIONS_GROUP'], updatePermissionsGroup),
        takeLatest(['ADD_PERMISSIONS_GROUP'], addPermissionsGroup),
        takeLatest(['DELETE_PERMISSIONS_GROUP'], deletePermissionsGroup),
    ]);
}
