import React from 'react';
import { connect } from 'react-redux';
import MessageNonAdmin from './MessageNonAdmin.js';
import MessageAdmin from './MessageAdmin.js';

const ReactivateAccount = ({ toggleBillingInfoVisibility, accountUserStore }) => {

    const { manage_account } = accountUserStore;

    return manage_account === 'Yes'
        ? <MessageAdmin toggleBillingInfoVisibility={toggleBillingInfoVisibility} />
        : <MessageNonAdmin />
};

const mapStateToProps = (state) => {
    return {
        accountUserStore: state.accountUserStore,
    };
};

export default connect(
    mapStateToProps
)(ReactivateAccount);
