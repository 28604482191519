import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'reactstrap';
import Select from './Select';
import { preferredDistanceUnits } from '../../config/Fields';
import { MILES, KILOMETERS } from '../../config/Constants';
import { milesToKilometers, kilometersToMiles } from '../../utils/CommonFunctions';

const MileageCalculatorSetting = props => {
    const {
        distanceUnit,
        onDistanceUnitChange,
        reimbursementRate,
        onReimbursementRateChange,
        isHeadingVisible,
    } = props;

    const initialState = reimbursementRate
        ? parseFloat(reimbursementRate).toFixed(4)
        : reimbursementRate;
    const [displayReimbursementRate, setDisplayReimbursementRate] = useState(initialState);

    const handleReimbursementRateChange = ({ target }) => {
        setDisplayReimbursementRate(target.value);
        onReimbursementRateChange(target.value);
    };

    return (
        <div>
            {isHeadingVisible && <h3 className="cardHeading">Mileage Calculator</h3>}
            <div className="cardContainer ausSectionContainer">
                <div className="settingsLabelAndInputContainer">
                    <div className="settingsInputLabel">Preferred Unit</div>
                    <div className="settingsInput">
                        <Select
                            value={distanceUnit}
                            options={preferredDistanceUnits}
                            onChange={value => {
                                let rate = null;
                                if (distanceUnit === value) {
                                    rate = reimbursementRate;
                                }
                                if (value === KILOMETERS) {
                                    rate = milesToKilometers(reimbursementRate);
                                }
                                if (value === MILES) {
                                    rate = kilometersToMiles(reimbursementRate);
                                }
                                onDistanceUnitChange(value, rate);
                                setDisplayReimbursementRate(rate.toFixed(4));
                            }}
                        />
                    </div>
                </div>
                <div className="settingsLabelAndInputContainer">
                    <div className="settingsInputLabel">
                        Default Reimbursement Rate per
                        {distanceUnit === MILES ? 'Mile' : 'Km'}
                    </div>
                    <div className="settingsInput">
                        <Input
                            value={displayReimbursementRate}
                            onChange={handleReimbursementRateChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

MileageCalculatorSetting.propTypes = {
    distanceUnit: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    onDistanceUnitChange: PropTypes.func,
    reimbursementRate: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    onReimbursementRateChange: PropTypes.func,
    isHeadingVisible: PropTypes.bool,
};

MileageCalculatorSetting.defaultProps = {
    isHeadingVisible: true,
};

export default MileageCalculatorSetting;
