import React from 'react';
import { employeeWithId, createSelectedListWithIds } from '../../../utils/CommonFunctions.js';
import Select from '../../components/Select';

const PayrollSettings = ({
    history,
    shouldShowPayroll,
    canEditPayroll,
    canManageAccount,
    selectedEmployeeId,
    employees,
    payrollItems,
    type,
    payrollItemId,
    payrollItemIdChanged,
    qbType,
    no_integration,
    onEmployeeSetting,

}) => {
    if (type === 'Vendor') {
        if(qbType === 'None')return <div className="cardContent">Vendors do not use payroll items</div>;
        return <div className="cardContent">Vendors do not use QuickBooks' payroll items</div>;
    }
    
    const employee = employeeWithId(employees, selectedEmployeeId, type);

    if (shouldShowPayroll) {
        if (canEditPayroll) {
            if (employee) {
                const name = [employee.first_name, employee.last_name].join(' ');
                const quickbookText =   name + " uses time data to create paychecks in QuickBooks, but there are no payroll items assigned to this employee. You must add payroll items and rates for " + name + " in QuickBooks before entering time for this employee.";
                const nonQuickbookText =   "There are no payroll items assigned to this employee. You must add payroll items and rates for " + name+ " ";
                if (employee['use_time_for_paychecks'] === 'NotSet' && qbType === 'Desktop') {
                    return (
                        <div className="cardContent">
                            QuickBooks does not know the payroll status of this employee. You must
                            set whether this employee uses time data to create paychecks in
                            QuickBooks before entering time for {name}.
                            <br />
                            <button
                                className="tertiaryButton"
                                onClick={() => {
                                    history.push('/help#setting-up-payroll-items');
                                }}
                            >
                                Read more about how to do this.
                            </button>
                        </div>
                    );
                } else if (
                    employee['use_time_for_paychecks'] === 'UseTimeData' ||
                    qbType === 'None'
                ) {
                    if (
                        (payrollItems.length === 0 ||
                        employee.payroll_item_ids === '' ||
                        employee.payroll_item_ids === null) && no_integration === false
                    ) {
                        return (
                                <div className="cardContent">
                                {quickbookText}
                                <button
                                    className="tertiaryButton"
                                    onClick={() => {
                                        history.push('/help#setting-up-payroll-items');
                                    }}
                                >
                                    Read more about how to do this.
                                </button>
                            </div>
                            
                        );
                    }
                    else if (
                        (payrollItems.length === 0 ||
                        employee.payroll_item_ids === '' ||
                        employee.payroll_item_ids === null  ) && no_integration === true
                    ) {
                        return (
                                <div className="cardContent">
                                {nonQuickbookText}
                                <button
                                    className="tertiaryButton"
                                    onClick={() => onEmployeeSetting(employee)}
                                >
                                    Click here to edit employees' settings.
                                </button>
                            </div>
                            
                        );
                    }
                     else {
                        const arrayOfPayrollItemIds = employee.payroll_item_ids.split(',');
                        let selectedPayrollItems = createSelectedListWithIds(
                            arrayOfPayrollItemIds,
                            payrollItems,
                            'PayrollItem'
                        );
                        return (
                            <div className="settingsLabelAndInputContainer">
                                <div className="settingsInputLabel">Default Payroll Item</div>
                                <div className="settingsInput">
                                    <Select
                                        withSearch
                                        options={selectedPayrollItems}
                                        labelWhenNothingSelected="No Payroll Item Selected"
                                        value={payrollItemId}
                                        onChange={id => payrollItemIdChanged(id)}
                                    />
                                </div>
                            </div>
                        );
                    }
                } else {
                    return (
                        <div className="cardContent">
                            {name} is not set to use time data to create paychecks in QuickBooks.
                            <br />
                            <button
                                className="tertiaryButton"
                                onClick={() => history.push('/help#setting-up-payroll-items')}
                            >
                                Read more about how to set the payroll status of this employee.
                            </button>
                        </div>
                    );
                }
            } else {
                return (
                    <div className="cardContent">
                        Please select an active employee to see payroll options.
                    </div>
                );
            }
        } else {
            return (
                <div className="cardContent">You are not authorized to edit payroll options.</div>
            );
        }
    } else {
        return (
            <div className="cardContent">
                Your Minute7 company account is not set to use QuickBooks payroll items.
                {canManageAccount && (
                    <>
                        {' '}
                        You can set this account to use payroll by going to your&nbsp;
                        <span
                            className="ausAccountSettingsLink"
                            onClick={() => history.push('/company_settings')}
                        >
                            account settings
                        </span>
                        .
                    </>
                )}
            </div>
        );
    }
};

export default PayrollSettings;
