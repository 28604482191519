export const setAccountUserStore = payload => ({
    type: 'SET_ACCOUNT_USER_STORE',
    payload,
});

export const updateUser = (user,callback) => ({
    type: 'UPDATE_USER',
    user,
    callback,
});

export const updateUserSuccess = user => ({
    type: 'UPDATE_USER_SUCCESS',
    user,
});

export const updateUserError = error => ({
    type: 'UPDATE_USER_ERROR',
    error,
});

export const addUser = (user,callback) => ({
    type: 'ADD_USER',
    user,
    callback,
});

export const addUserSuccess = user => ({
    type: 'ADD_USER_SUCCESS',
    user,
});

export const addUserError = error => ({
    type: 'ADD_USER_ERROR',
    error,
});

export const deleteUser = id => ({
    type: 'DELETE_USER',
    id,
});

export const resetUserEmail = id => ({
    type: 'RESET_USER_EMAIL',
    id,
});

export const deleteUserSuccess = id => ({
    type: 'DELETE_USER_SUCCESS',
    id,
});

export const deleteUserError = error => ({
    type: 'DELETE_USER_ERROR',
    error,
});
