import React from 'react';
import '../../css/SiteLoading.css';

export const SiteLoading = () => {
    return (
        <div className="siteLoadingContainer">
            <img src="./minute7-logo@3x.png" alt="Minute7" />
            <div className="siteLoadingIcon" />
        </div>
    );
};
