import React, { Component } from 'react';
import Toast from './Toast';
import styles from './Toast.module.css';

const DEFAULT_DURATION = 5000; // in ms

export default class ToastManager extends Component {
    static idCounter = 0;

    state = {
        messages: [],
    };

    constructor(props) {
        super(props);
        props.notify(this.notify);
    }

    notify = (message, options) => {
        const toast = this.createToastState(message, options);

        this.setState(prev => {
            return {
                ...prev,
                messages: [...prev.messages, toast],
            };
        });
    };

    createToastState = (message, options) => {
        const id = ++ToastManager.idCounter;

        return {
            id,
            message,
            kind: typeof options.kind === 'undefined' ? 'neutral' : options.kind,
            showing: true,
            duration: typeof options.duration === 'undefined' ? DEFAULT_DURATION : options.duration,
            onRequestRemove: () => this.removeToast(String(id)),
        };
    };

    // actually fully remove the toast
    removeToast = id => {
        this.setState(prev => {
            return {
                ...prev,
                messages: prev.messages.filter(toast => toast.id !== id),
            };
        });
    };

    render() {
        const { messages } = this.state;
        return (
            <span className={styles.container}>
                {messages.map(toast => {
                    return <Toast key={toast.id} {...toast} />;
                })}
            </span>
        );
    }
}
