import React from 'react';
import {
    reportTimeFields,
    reportExpenseFields,
    reportTimeFieldsNonQB,
    reportTimeFieldsNonBillRatelManager,
    reportTimeFieldsNonPayRollAndBillRollManager,
    reportTimeFieldsNonPayRollManager,
    reportExpenseFieldsNonQB,
} from '../../../config/Fields.js';
import Icon from 'react-icons-kit';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';

const isKeyInExportFields = (key, currentView, exportTimeFields, exportExpenseFields) => {
    if (currentView === 'timesheets') {
        if (exportTimeFields[key]) {
            return true;
        }
        return false;
    } else if (currentView === 'expenses') {
        if (exportExpenseFields[key]) {
            return true;
        }
        return false;
    }
};

const ReportFields = ({
    toggleExportField,
    currentView,
    exportTimeFields,
    exportExpenseFields,
    preferredDistanceUnits,
    qbType,
    canManagePayroll,
    canManageItemBillrate,
}) => {
    let keys = null;
    let currentObject = null;

    if (currentView === 'timesheets') {
        if (qbType === 'None') {
            if(canManagePayroll && canManageItemBillrate){
                keys = Object.keys(reportTimeFieldsNonQB);
                currentObject = reportTimeFieldsNonQB;
            }
            else if (!canManagePayroll && canManageItemBillrate) {
                keys = Object.keys(reportTimeFieldsNonPayRollManager);
                currentObject = reportTimeFieldsNonPayRollManager;
            }
            else if (canManagePayroll && !canManageItemBillrate) {
                keys = Object.keys(reportTimeFieldsNonBillRatelManager);
                currentObject = reportTimeFieldsNonBillRatelManager;
            }
            else{
                keys = Object.keys(reportTimeFieldsNonPayRollAndBillRollManager);
                currentObject =reportTimeFieldsNonPayRollAndBillRollManager;
            }
        } else {
            keys = Object.keys(reportTimeFields);
            currentObject = reportTimeFields;
        }
    } else if (currentView === 'expenses') {
        if (qbType === 'None') {
            keys = Object.keys(reportExpenseFieldsNonQB);
            currentObject = reportExpenseFieldsNonQB;
        } else {
            keys = Object.keys(reportExpenseFields);
            currentObject = reportExpenseFields;
        }
    }

    keys.sort();

    let exportTable = [];
    for (let i = 0; i < 6; i++) {
        let children = [];
        for (let j = 0; j < 5; j++) {
            if (i * 5 + j < keys.length) {
                const key = keys[i * 5 + j];
                let label = currentObject[key];
                if (key === 'miles' && preferredDistanceUnits === 'kilometers') {
                    label = 'Kilometers';
                }

                children.push(
                    <td key={key} className="rExportFieldsTableCell">
                        <div className="rCheckboxAndLabelContainer">
                            {isKeyInExportFields(
                                key,
                                currentView,
                                exportTimeFields,
                                exportExpenseFields
                            ) ? (
                                <Icon
                                    className="blueIcon"
                                    size={20}
                                    icon={checkboxChecked}
                                    onClick={() => toggleExportField(keys[i * 5 + j])}
                                />
                            ) : (
                                <Icon
                                    className="blueIcon"
                                    size={20}
                                    icon={checkboxUnchecked}
                                    onClick={() => toggleExportField(keys[i * 5 + j])}
                                />
                            )}
                            <div className="rExportFieldLabel">{label}</div>
                        </div>
                    </td>
                );
            }
        }
        children.sort();
        exportTable.push(<tr key={i}>{children}</tr>);
    }
    return exportTable;
};

export default ReportFields;
