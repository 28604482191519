import React, { useRef, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import Message from './Message';
import { useTimeout } from '../../../hooks/useTimeout';

import styles from './Toast.module.css';

const getStyle = () => {
    return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };
};

const Toast = ({ id, message, kind, onRequestRemove, duration = 30000 }) => {
    const container = useRef(null);
    const [timeout, setTimeout] = useState(duration);
    const [localShow, setLocalShow] = useState(true);

    useTimeout(close, timeout);

    const animation = {
        config: { mass: 1, tension: 250, friction: 30 },
        from: {
            opacity: 1,
            height: 0,
            transform: `translateY(0) scale(1)`,
        },
        enter: () => next =>
            next({
                opacity: 1,
                height: container.current.getBoundingClientRect().height,
                transform: `translateY(0) scale(1)`,
            }),
        leave: {
            opacity: 0,
            height: 0,
            transform: `translateY(0 scale(0.95)`,
        },
        onRest,
    };

    const transition = useTransition(localShow, null, animation);
    const style = getStyle();

    function onMouseEnter() {
        setTimeout(null);
    }

    function onMouseLeave() {
        setTimeout(duration);
    }

    function onRest() {
        if (!localShow) {
            onRequestRemove();
        }
    }

    function close() {
        setLocalShow(false);
    }

    function renderMessage() {
        if (typeof message === 'string' || React.isValidElement(message)) {
            return <Message id={id} kind={kind} title={message} onClose={close} />;
        }

        if (typeof message === 'function') {
            return message({
                id,
                onClose: close,
            });
        }

        return null;
    }

    return (
        <React.Fragment>
            {transition.map(
                ({ key, item, props }) =>
                    item && (
                        <animated.div
                            key={key}
                            className={styles.toast}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            style={{
                                opacity: props.opacity,
                                height: props.height,
                                ...style,
                            }}
                        >
                            <animated.div
                                style={{
                                    transform: props.transform,
                                    pointerEvents: 'auto',
                                }}
                                ref={container}
                                className={styles.messageWrapper}
                            >
                                {renderMessage()}
                            </animated.div>
                        </animated.div>
                    )
            )}
        </React.Fragment>
    );
};

export default Toast;
