export const setAllAccounts = payload => ({
    type: 'SET_ALL_ACCOUNT',
    payload,
});

export const addAccount = (account, callback) => ({
    type: 'ADD_ACCOUNT',
    account,
    callback,
});

export const addAccountList = () => ({
    type: 'ADD_ACCOUNT_LIST',
});

export const addAccountListError = () => ({
    type: 'ADD_ACCOUNT_LIST_ERROR',
});

export const addAccountSuccess = account => ({
    type: 'ADD_ACCOUNT_SUCCESS',
    account,
});

export const addAccountError = error => ({
    type: 'ADD_ACCOUNT_ERROR',
    error,
});

export const updateAccount = (account, callback) => ({
    type: 'UPDATE_ACCOUNT',
    account,
    callback,
});

export const updateAccountSuccess = account => ({
    type: 'UPDATE_ACCOUNT_SUCCESS',
    account,
});

export const updateAccountError = error => ({
    type: 'UPDATE_ACCOUNT_ERROR',
    error,
});

export const hideAccount = accountId => ({
    type: 'DEACTIVATE_QB_ACCOUNT',
    accountId,
});

export const unhideAccount = accountId => ({
    type: 'ACTIVATE_QB_ACCOUNT',
    accountId,
});
