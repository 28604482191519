import React, { useEffect } from 'react';
import moment from 'moment';
import packageJson from '../../package.json';

const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);

    return momLatestDateTime.isAfter(momCurrentDateTime);
};

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        useEffect(() => {
            fetch('/meta.json', {
                cache: 'no-store',
            })
                .then(response => response.json())
                .then(meta => {
                    const latestBuildDate = meta.buildDate;
                    const currentBuildDate = packageJson.buildDate;

                    const shouldForceRefresh = buildDateGreaterThan(
                        latestBuildDate,
                        currentBuildDate
                    );

                    if (shouldForceRefresh) refreshCacheAndReload();
                })
                .catch(error => console.error('error', error));
        }, []);

        const refreshCacheAndReload = async () => {
            if (caches) {
                const cacheKeys = await caches.keys();
                await Promise.all(cacheKeys.map(key => caches.delete(key)));
            }
            window.location.replace(window.location.href);
        };

        return <Component {...props} />;
    }

    return ClearCacheComponent;
}

export default withClearCache;
