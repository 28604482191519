import React from 'react';

const NotAssociatedEmployee = () => (
    <div>
        <div className="sectionQuestion">
            What happens if I don't associate a user with an employee?
        </div>
        <div className="sectionAnswer">
            Not all users must be associated with QuickBooks employees or vendors. Usually, a
            Minute7 user is associated with an employee, so that he or she can easily log his or her
            own time. However, you can user Minute7's graded permissions system to set up users in
            many different ways. <br />
            For example, you may want to set up managing users who themselves don't enter time or
            expenses, but review time and expenses for other employees and vendors. In that case,
            you could set up a Minute7 user account with permission to view, edit, and approve all
            time and expenses, but without a directly associated QuickBooks employee or vendor.
        </div>
    </div>
);

export default NotAssociatedEmployee;
