import _ from 'lodash';
import jsSHA from 'jssha';

import { LOGS } from '../config/Logs.js';
import { PATTERNS } from '../config/Patterns.js';
import { appStore as store } from '../stores/store';

let sessionId = null;
let sessionName = null;

const URL = process.env.REACT_APP_URL;
const URL_SUFFIX = process.env.REACT_APP_URL_SUFFIX;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_SECRET = process.env.REACT_APP_API_SECRET;

export const setSession = (newSessionId, newSessionName) => {
    sessionId = newSessionId;
    sessionName = newSessionName;
};
const toDataURL = url =>
    fetch(url)
        .then(response => response.blob())
        .then(
            blob =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(blob);
                })
        );

export function handleImageDownload({ url, name }) {
    toDataURL(url).then(dataUrl => {
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = name;
        a.click();
    });
}
/**
 * Creates a file object with name, filename, from a base64 data string
 * @param {string} dataURL a data*base64 file string of the file to create
 */
function dataURLtoFile(dataURL) {
    let arr = dataURL.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    let file = new Blob([u8arr], { type: mime });
    return file;
}

export function postRequestWithFormData(requestData, route, paramsObject, headers = {}) {
    const formData = new FormData();

    for (let key in requestData) {
        if (typeof requestData[key] === 'undefined') {
            continue;
        }

        if (key === 'new_attachments') {
            requestData[key].forEach(attachment => {
                // See if we need to convert base64 string
                if (typeof attachment.path === 'string' && !attachment.path.includes('https://')) {
                    formData.append(`${key}[]`, dataURLtoFile(attachment.path), attachment.name);
                } else {
                    formData.append(`${key}[]`, attachment.path);
                }
            });
        } else if (key === 'deleted_attachments') {
            requestData[key].forEach(attachment => {
                formData.append(`${key}[]`, JSON.stringify(attachment));
            });
        } else if (key === 'logo') {
            if (requestData[key].path !== null && requestData[key].path !== '') {
                formData.append(
                    `${key}[]`,
                    dataURLtoFile(requestData[key].path),
                    requestData[key].name
                );
            }
        } else if (requestData[key] instanceof Array) {
            requestData[key].forEach(element => {
                formData.append(`${key}[]`, element);
            });
        } else if (requestData[key] instanceof Object) {
            for (let item in requestData[key]) {
                formData.append(`${key}[${item}]`, requestData[key][item]);
            }
        } else {
            if (requestData[key] === null) {
                requestData[key] = '';
            }
            formData.append(key, requestData[key].toString());
        }
    }

    if (sessionId !== null && sessionName !== null) {
        paramsObject[sessionName] = sessionId;
    }

    const params = {
        method: 'POST',
        credentials: 'include',
        body: formData,
        headers: headers,
    };

    return baseRequest(paramsObject, route, params);
}

export function getData(paramsObject, route) {
    if (sessionId !== null && sessionName !== null) {
        paramsObject[sessionName] = sessionId;
    }

    const params = {
        credentials: 'include',
    };
    return baseRequest(paramsObject, route, params);
}

function createQueryParamsString(paramsObject) {
    const strArr = [];
    for (const key in paramsObject) {
        if (paramsObject.hasOwnProperty(key) && typeof key !== 'object') {
            const encodedKey = encodeURI(key).replace(/%20/g, '+');
            const encodedValue = encodeURI(paramsObject[key]).replace(/%20/g, '+');
            strArr.push(`${encodedKey}=${encodedValue}`);
        }
    }
    return strArr.join('&');
}

function encodeURI(str) {
    return encodeURIComponent(str).replace(
        PATTERNS.encodeURIChars,
        c =>
            `%${c
                .charCodeAt(0)
                .toString(16)
                .toUpperCase()}`
    );
}

export function createQueryString(paramsObject, route) {
    let tempParamsObject = JSON.parse(JSON.stringify(paramsObject));
    tempParamsObject.z_ca = Math.floor(Math.random() * 100000);
    tempParamsObject.api_key = API_KEY;
    tempParamsObject = sortParams(tempParamsObject);

    const signature = `${API_SECRET + URL_SUFFIX + route}?${createQueryParamsString(
        tempParamsObject
    )}`;
    const shaObject = new jsSHA('SHA-512', 'TEXT');
    shaObject.update(signature);
    tempParamsObject.signature = shaObject.getHash('HEX');

    if (LOGS.ENABLE_LOGS) {
        console.info(`${URL + URL_SUFFIX + route}?${createQueryParamsString(tempParamsObject)}`);
    }
    return `${URL + URL_SUFFIX + route}?${createQueryParamsString(tempParamsObject)}`;
}

function sortParams(paramsObject) {
    const keys = [];
    for (const i in paramsObject) {
        keys.push(i);
    }
    keys.sort();
    const sortedParams = {};
    for (let i = 0; i < keys.length; i++) {
        sortedParams[keys[i]] = paramsObject[keys[i]];
    }
    return sortedParams;
}

function baseRequest(paramsObject, route, params) {
    const urlWithParams = createQueryString(paramsObject, route);

    if (LOGS.ENABLE_LOGS) {
        console.info(`paramsObject =====> `, paramsObject);
        console.info(`route =====> `, route);
        console.info(`params =====> `, params);
        console.info(`urlWithParams =====> `, urlWithParams);
    }

    return fetch(urlWithParams, params)
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    return response
                        .json()
                        .then(json => {
                            if (json.m7 && json.m7.error) {
                                json.error = json.m7.error;
                            }
                            json.status = response.status;

                            if (json.error) {
                                const error = new Error(json.error);
                                error.response = response;
                                throw error;
                            }

                            if (LOGS.ENABLE_LOGS) {
                                console.info(`Response from route ${route}: `, json);
                            }
                            return json;
                        })
                        .catch(error => {
                            console.info(
                                `ERROR: while parsing response from route ${route}: `,
                                error
                            );
                            error.response = response;
                            throw error;
                        });
                } else {
                    return response.text().then(text => {
                        if (text.match(/^{/)) {
                            // attempt to parse as JSON anyway
                            try {
                                const json = JSON.parse(text);
                                return json;
                            } catch (e) {
                                return text;
                            }
                        }
                        return text;
                    });
                }
            } else if (response.status === 401 || response.status === 403) {
                store.dispatch({ type: 'API_REQUEST_UNAUTHORIZED_ERROR' });
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            } else if (response.status === 400) {
                return response
                    .json()
                    .then(res => {
                        const error = new Error(res.errors || res.error);
                        error.response = response;
                        throw error;
                    })
                    .catch(error => {
                        if (LOGS.ENABLE_LOGS) {
                            console.info(`ERROR: In baseRequest when on route ${route}: `, error);
                        }

                        throw error;
                    });
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .catch(error => {
            if (LOGS.ENABLE_LOGS) {
                console.info(`ERROR: In baseRequest when on route ${route}: `, error);
            }

            throw error;
        });
}

// Extract embedded partial records in responses returned from the API
// For example:
//
// const records = [
//    { id: 1, Customer: { id: 1, name: 'John' }},
//    { id: 2, Customer: { id: 2, name: 'Jane' }},
// ];
//
// const extracted = extractEmbeddedEntities(['Customer'], records);
//
// // extracted is now:
// { Customer: [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }] }

export const extractEmbeddedEntities = (entityNames, records) => {
    const results = entityNames.reduce((all, name) => {
        all[name] = { entities: [], byId: {} };
        return all;
    }, {});

    records.forEach(record => {
        entityNames.forEach(name => {
            if (
                record[name] &&
                !(record[name].id in results[name].byId) &&
                record[name].id !== null
            ) {
                results[name].entities.push(record[name]);
                results[name].byId[record[name].id] = true;
            }
        });
    });

    return _.mapValues(results, 'entities');
};
