import React from 'react';

const errorURL =
    'https://quickbooks.intuit.com/community/Help-Articles/QuickBooks-Web-Connector-Error-QBWC1085-or-Exception-TypeError/m-p/201453';

const QBWC1085 = () => (
    <div>
        <div className="sectionQuestion">
            What should I do if get a QuickBooks Web Connector Error “QBWC1085”?
        </div>
        <div className="sectionAnswer">
            This error occurs when the QWCLOG.TXT file used by the Web Connector program becomes
            damaged or it cannot be accessed.{' '}
            <a href={errorURL}>
                Click here for a help article from Intuit on how to fix this problem
            </a>
            .
        </div>
    </div>
);

export default QBWC1085;
