import _ from 'lodash';
import React, { Component, lazy, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { isIE } from 'react-device-detect';
import moment from 'moment';
import ProtectedRoute from './views/components/ProtectedRoute';
import App from './App.js';
import ScrollToTop from './views/components/ScrollToTop';
import Login from './views/login/Login.js';
import Timesheets from './views/timesheets/Timesheets.js';
import AccountSettings from './views/account_settings/AccountSettings.js';
import ReactivateAccount from './views/account_settings/ReactivateAccount.js';
import DeactivateAccount from './views/account_settings/DeactivateAccount.js';
import PermissionsGroup from './views/account_settings/components/PermissionsGroups.js';
import AccountUserPermissions from './views/account_settings/components/AccountUserPermissions.js';
import CreateAccountUser from './views/account_settings/components/CreateAccountUser.js';
import EditAccountUser from './views/account_settings/components/EditAccountUser.js';

import PaymentHistory from './views/account_settings/components/PaymentHistory.js';
import AccountUserSettings from './views/account_user_settings/AccountUserSettings.js';
import Timer from './views/components/Timer.js';
import Reports from './views/reports/Reports.js';
import PasswordReset from './views/password_reset/PasswordReset.js';
import FreeTrial from './views/free_trial/FreeTrial.js';
import ActivityLog from './views/activity_log/ActivityLog.js';
import CompanySettings from './views/account_settings/components/CompanySettings.js';
import ResourceSettings from './views/account_settings/components/ResourceSettings.js';
import ContactUs from './views/contact_us/ContactUs.js';
import Home from './views/home/Home.js';
import Terms from './views/home/Terms.js';
import Privacy from './views/home/Privacy.js';
import OnlineSync from './views/sync/OnlineSync.js';
import DesktopSync from './views/sync/DesktopSync.js';
import Help from './views/help/Help.js';
import Impersonate from './views/impersonate/Impersonate.js';
import HeaderBar from './views/components/HeaderBar.js';
import FooterBar from './views/components/FooterBar.js';
import GlobalAlert from './views/components/GlobalAlert.js';
import BillingInfoModal from './views/components/BillingInfoModal.js';
import IENotSupportedView from './views/components/IENotSupportedView';
import Error404 from './views/errors/Error404';
import EmailSentSuccess from './views/contact_us/components/EmailSentSuccess';
import { SiteLoading } from './views/components/SiteLoading';
import { requestBusinessResources } from './actions/BusinessResourcesActions.js';
import { showToast, login, refreshAccount } from './actions/Actions.js';
import { setAccountStore } from './actions/AccountActions.js';
import { setAccountUserStore } from './actions/AccountUserActions.js';
import { setCanViewVendors, setCanEditVendors } from './actions/VendorActions.js';
import { setCanViewEmployees, setCanEditEmployees } from './actions/EmployeeActions.js';
import { setDate as setNewTimeEntryDate } from './actions/TimesheetsActions';
import { setDate as setNewTimerEntryDate } from './actions/TimerActions';
import { setDate as setNewExpenseEntryDate } from './actions/ExpensesActions';
import {
    canViewTimesheets,
    canViewExpenses,
    canManageAccount,
    canManageUsers,
    canViewReports,
    canViewActivityLog,
    canExportTime,
} from './utils/RoutingUtils';
import { setAllAccounts } from './actions/AccountsActions.js';
import WebConnector from './views/help/components/pages/syncing_with_qbd/WebConnector.js';
import SetUpQBO from './views/help/components/pages/syncing_with_qbo/SetUpQBO.js';
import HowDoPermissionsWork from './views/help/components/pages/people_and_permissions/HowDoPermissionsWork.js';
import GoogleAnalytics from './utils/GoogleAnalytics.js';
import OpenId from './views/open_id/OpenId.js';
import { bugsnagClient } from './bugsnag';
import history from './history';
import withClearCache from './hocs/withClearCache';

import './css/Router.css';
import './css/CommonTheme.css';
import { ErrorBoundary } from 'react-error-boundary';
import RefreshPage from './views/errors/RefreshPage';
import NewEmployeeForm from './views/employees/components/NewEmployeeForm';
import EditEmployeeForm from './views/employees/components/EditEmployeeForm';
import NewVendorForm from './views/vendors/components/NewVendorForm';
import EditVendorForm from './views/vendors/components/EditVendorForm';
import NewCustomerForm from './views/customers/components/NewCustomerForm';
import EditCustomerForm from './views/customers/components/EditCustomerForm';
import NewPayrollItemForm from './views/payroll_item/components/NewPayrollItemForm';
import EditPayrollItemForm from './views/payroll_item/components/EditPayrollItemForm';
import NewInventoryItemForm from './views/inventory_item/components/NewItemForm';
import EditInventoryItemForm from './views/inventory_item/components/EditItemForm';
import EditAccountForm from './views/accounts/components/EditAccountForm';
import NewAccountTypeChoiceForm from './views/accounts/components/NewAccountTypeChoiceForm';
import GeneralAccountForm from './views/accounts/components/GeneralAccountForm';
import PaymentAccountForm from './views/accounts/components/PaymentAccountForm';
const Expenses = lazy(() => import('./views/expenses/Expenses.js'));

const FIFTEEN_MINUTES = 15 * 60 * 1000; // 15 min

class MainRouter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBillingInfoOpen: false,
        };
        this.refreshInterval = null;
    }

    componentDidMount() {
        this.refreshAccount();
        this.refreshInterval = setInterval(this.refreshAccount, FIFTEEN_MINUTES);
        this.setBugsnagUserFromProps();
        this.props.setNewTimeEntryDate(moment().format());
        this.props.setNewExpenseEntryDate(moment().format());
        if (!this.props.isTimerStarted) {
            this.props.setNewTimerEntryDate(moment().format());
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            this.setBugsnagUserFromProps();
        }
    }

    componentWilUnmount() {
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
    }

    setBugsnagUserFromProps = () => {
        bugsnagClient.user = this.props.isLoggedIn
            ? {
                  id: this.props.currentUser.id,
                  email: this.props.currentUser.email,
                  account_id: this.props.account.id,
              }
            : {};
    };

    login = (email = null, password = null) => {
        // use props if params = null
        if (email === null && password === null) {
            if (this.props.email !== null && this.props.password !== null) {
                email = this.props.email;
                password = this.props.password;
            } else {
                return false;
            }
        }

        this.props.login({ email, password });
    };

    toggleBillingInfoVisibility = () => {
        this.setState({ isBillingInfoOpen: !this.state.isBillingInfoOpen });
    };

    refreshAccount = () => {
        if (!this.props.isLoggedIn) {
            return;
        }

        this.props.refreshAccount();
    };

    render() {
        const { resourcesLoaded, isAccountActive, isLoggedIn } = this.props;

        if (isIE) return <IENotSupportedView />;

        return (
            <Router history={history}>
                <ScrollToTop>
                    <div className="applicationDiv">
                        <Route component={GoogleAnalytics} />
                        <DocumentTitle title="Minute7" />
                        {resourcesLoaded && <Route component={Timer} />}
                        {resourcesLoaded && (
                            <Route
                                render={props => (
                                    <BillingInfoModal
                                        {...props}
                                        isBillingInfoOpen={this.state.isBillingInfoOpen}
                                        requestClose={this.toggleBillingInfoVisibility}
                                    />
                                )}
                            />
                        )}
                        <Route
                            render={props => (
                                <HeaderBar
                                    {...props}
                                    toggleTimerVisibility={this.toggleTimerVisibility}
                                    toggleBillingInfoVisibility={this.toggleBillingInfoVisibility}
                                />
                            )}
                        />
                        <Route component={GlobalAlert} />

                        <Switch>
                            <Route path="/" exact component={App} />
                            <Route path="/open_id" exact component={OpenId} />
                            <Route path="/home" exact render={props => <Home {...props} />} />
                            <Route path="/terms" exact render={props => <Terms {...props} />} />
                            <Route path="/privacy" exact render={props => <Privacy {...props} />} />
                            <Route path="/login" exact component={Login} />
                            <Route
                                path="/password_reset"
                                exact
                                render={props => <PasswordReset {...props} />}
                            />
                            <Route path="/free_trial" exact component={FreeTrial} />
                            {isLoggedIn && !isAccountActive && (
                                <Route
                                    render={props => (
                                        <ReactivateAccount
                                            {...props}
                                            toggleBillingInfoVisibility={
                                                this.toggleBillingInfoVisibility
                                            }
                                        />
                                    )}
                                />
                            )}
                            <ProtectedRoute
                                path="/timesheets"
                                exact
                                requiresPermissions={[canViewTimesheets]}
                                render={props => (
                                    <div className="application">
                                        <Timesheets {...props} openTimer={this.openTimer} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/expenses"
                                exact
                                requiresPermissions={[canViewExpenses]}
                                render={props => (
                                    <div className="application">
                                        <ErrorBoundary FallbackComponent={RefreshPage}>
                                            <Suspense fallback={<SiteLoading />}>
                                                <Expenses {...props} />
                                            </Suspense>
                                        </ErrorBoundary>
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/reports"
                                exact
                                requiresPermissions={[canViewReports]}
                                render={props => (
                                    <div className="application">
                                        <Reports {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/sync"
                                exact
                                requiresPermissions={[canExportTime]}
                                render={props =>
                                    this.props.account.quickbooks_type === 'Desktop' ? (
                                        <div className="application">
                                            <DesktopSync {...props} />
                                        </div>
                                    ) : (
                                        <div className="application">
                                            <OnlineSync {...props} />
                                        </div>
                                    )
                                }
                            />
                            <ProtectedRoute
                                path="/account_settings"
                                exact
                                requiresPermissions={[canManageUsers]}
                                render={props => (
                                    <div className="application">
                                        <AccountSettings
                                            {...props}
                                            toggleBillingInfoVisibility={
                                                this.toggleBillingInfoVisibility
                                            }
                                        />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/deactivate_account"
                                exact
                                requiresPermissions={[canManageAccount]}
                                component={DeactivateAccount}
                            />
                            <ProtectedRoute
                                path="/company_settings"
                                exact
                                requiresPermissions={[canManageAccount]}
                                render={props => (
                                    <div className="application">
                                        <CompanySettings {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/resource_settings"
                                exact
                                requiresPermissions={[canManageUsers]}
                                render={props => (
                                    <div className="application">
                                        <ResourceSettings {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/payment_history"
                                exact
                                requiresPermissions={[canManageAccount]}
                                render={props => (
                                    <div className="application">
                                        <PaymentHistory {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/permissions_group"
                                exact
                                requiresPermissions={[canManageUsers]}
                                render={props => (
                                    <div className="application">
                                        <PermissionsGroup {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/permissions_group/:id"
                                exact
                                requiresPermissions={[canManageUsers]}
                                render={props => (
                                    <div className="application">
                                        <PermissionsGroup {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/account_user_permissions"
                                exact
                                requiresPermissions={[canManageUsers]}
                                render={props => (
                                    <div className="application">
                                        <AccountUserPermissions {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/account_user_permissions/:id"
                                exact
                                requiresPermissions={[canManageUsers]}
                                render={props => (
                                    <div className="application">
                                        <AccountUserPermissions {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/create_account_user"
                                exact
                                requiresPermissions={[canManageUsers]}
                                render={props => (
                                    <div className="application">
                                        <CreateAccountUser {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/edit_account_user"
                                exact
                                requiresPermissions={[canManageUsers]}
                                render={props => (
                                    <div className="application">
                                        <EditAccountUser {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/edit_account_user/:id"
                                exact
                                requiresPermissions={[canManageUsers]}
                                render={props => (
                                    <div className="application">
                                        <EditAccountUser {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/account_user_settings"
                                exact
                                render={props => (
                                    <div className="application">
                                        <AccountUserSettings {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/activity_log"
                                exact
                                requiresPermissions={[canViewActivityLog]}
                                render={props => (
                                    <div className="application">
                                        <ActivityLog {...props} />
                                    </div>
                                )}
                            />
                            <ProtectedRoute
                                path="/contact_us"
                                exact
                                render={props => (
                                    <div className="application">
                                        <ContactUs {...props} />
                                    </div>
                                )}
                            />
                            <Route
                                path="/contact"
                                exact
                                render={props => (
                                    <div className="application">
                                        <ContactUs {...props} />
                                    </div>
                                )}
                            />
                            {this.props.account.quickbooks_type === 'None' && (
                                <>
                                    <ProtectedRoute
                                        path="/resource_settings/:path"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <ResourceSettings {...props} />
                                            </div>
                                        )}
                                    />

                                    <ProtectedRoute
                                        path="/create_new_employee"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <NewEmployeeForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/edit_vendor"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <EditVendorForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/edit_customer"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <EditCustomerForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/create_new_customer"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <NewCustomerForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/edit_payroll_item"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <EditPayrollItemForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/create_new_payroll_item"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <NewPayrollItemForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/edit_item"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <EditInventoryItemForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/create_new_item"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <NewInventoryItemForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/create_new_vendor"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <NewVendorForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/edit_employee"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <EditEmployeeForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/edit_account"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <EditAccountForm {...props} />
                                            </div>
                                        )}
                                    />

                                    <ProtectedRoute
                                        path="/choose_new_account_type"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <NewAccountTypeChoiceForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/create_new_account"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <GeneralAccountForm {...props} />
                                            </div>
                                        )}
                                    />
                                    <ProtectedRoute
                                        path="/create_new_payment_account"
                                        exact
                                        requiresPermissions={[canManageUsers]}
                                        render={props => (
                                            <div className="application">
                                                <PaymentAccountForm {...props} />
                                            </div>
                                        )}
                                    />
                                </>
                            )}
                            <Route path="/help" exact component={Help} />
                            <Route path="/support" exact component={Help} />
                            {/**Help sections too big for a modal */}
                            <Route path="/help/web_connector" exact component={WebConnector} />
                            <Route path="/help/set_up_qbo" exact component={SetUpQBO} />
                            <Route path="/impersonate" exact component={Impersonate} />
                            <Route
                                path="/help/how_do_permissions_work"
                                exact
                                component={HowDoPermissionsWork}
                            />
                            <Route path="/email_sent_success" exact component={EmailSentSuccess} />
                            <Route component={Error404} />
                        </Switch>

                        <Route component={FooterBar} />
                    </div>
                </ScrollToTop>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: !_.isNil(state.appStore.session_id),
        isAccountActive: state.accountStore.active === 'Yes',
        resourcesLoaded: state.businessResourcesStore.resourcesLoaded,
        appStore: state.appStore,
        qbType: state.accountStore.qb_type,
        email: state.appStore.email,
        password: state.appStore.password,
        currentUser: state.accountUserStore,
        account: state.accountStore,
        isTimerStarted: state.timerStore.isTimerStarted,
    };
}

const mapDispatchToProps = {
    setAccountStore,
    setAccountUserStore,
    requestBusinessResources,
    showToast,
    login,
    setCanViewEmployees,
    setCanEditEmployees,
    setCanViewVendors,
    setCanEditVendors,
    refreshAccount,
    setNewTimeEntryDate,
    setNewTimerEntryDate,
    setNewExpenseEntryDate,
    setAllAccounts,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withClearCache(MainRouter));
