import React, { Component } from 'react';
import Modal from 'react-modal';
import { Input } from 'reactstrap';
import { updateAccountSettings, chargeAccount } from '../../requests/AccountRequests.js';
import Icon from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/ikons/close';

import { connect } from 'react-redux';
import { showToast } from '../../actions/Actions.js';
import { setAccountStore, reactivateAccount } from '../../actions/AccountActions.js';

import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import StripeForm from './StripeForm.js';

import {
    isNameValid,
    isAddressValid,
    nameErrorMessage,
    addressErrorMessage,
    isCityValid,
    cityErrorMessage,
} from '../../utils/CommonFunctions';

import '../../css/BillingInfoModal.css';

// Our tests don't like this line
try {
    Modal.setAppElement('#root');
} catch {}

const properDisplayName = {
    address1: 'Address One',
    address2: 'Address Two',
    city: 'City',
    billing_first_name: 'Billing First Name',
    billing_last_name: 'Billing Last Name',
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class BillingInfoModal extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => {
        const { accountStore } = this.props;
        return {
            address1: accountStore.address1,
            address2: accountStore.address2,
            city: accountStore.city,
            billing_first_name: accountStore.billing_first_name,
            billing_last_name: accountStore.billing_last_name,
            is_saving: false,
        };
    };

    isDataValid = () => {
        let stateKeys = Object.keys(this.state);
        stateKeys.forEach(key => {
            if (key !== 'address2') {
                if (this.state[key] === '') {
                    this.props.showToast(
                        'Make sure ' + properDisplayName[key] + ' is filled out correctly'
                    );
                    return false;
                }
            }
        });
        return true;
    };

    constructorBillingInfoData = paymentMethod => {
        if (this.isDataValid()) {
            let data = {};
            let stateKeys = Object.keys(this.state);
            stateKeys.forEach(key => {
                data['Account[' + key + ']'] = this.state[key];
            });
            data['Account[last_four_cc_digits'] = paymentMethod.card.last4;
            data['Account[expiration_month]'] = paymentMethod.card.exp_month;
            data['Account[expiration_year]'] = paymentMethod.card.exp_year;
            data['Account[cc_type]'] = paymentMethod.card.brand;
            data['Account[stripe_payment_method_id'] = paymentMethod.id;
            data['Account[id]'] = this.props.accountStore.id;
            return data;
        }
        return false;
    };

    handleIsSaving = is_billing_info_saving => {
        this.setState({ is_saving: is_billing_info_saving });
    };

    requiredFieldsValid = () => {
        const { billing_first_name, billing_last_name, address1, address2, city } = this.state;

        if (
            isNameValid(billing_first_name) &&
            isNameValid(billing_last_name) &&
            isAddressValid(address1) &&
            isAddressValid(address2) &&
            isCityValid(city)
        ) {
            return true;
        }
        return false;
    };

    updateBillingInfo = (error, paymentMethod) => {
        if (error) {
            this.props.showToast(
                'There was an error saving your credit card information please try again',
                'negative'
            );
            this.handleIsSaving(false);
        } else {
            const { suspended } = this.props.accountStore;
            let data = this.constructorBillingInfoData(paymentMethod);
            data['Account[zip]'] = paymentMethod.billing_details.address.postal_code;
            if (this.requiredFieldsValid) {
                updateAccountSettings(data)
                    .then(res => {
                        this.props.showToast('Billing information saved', 'positive');
                        if (suspended === 'Yes') {
                            this.props.showToast('Charging your account', 'positive');
                            chargeAccount(data)
                                .then(res => {
                                    this.props.setAccountStore(res);
                                    this.props.requestClose();
                                    this.setState(this.getInitialState());
                                    this.props.showToast(
                                        'Sucessfully Charged your card',
                                        'positive'
                                    );
                                })
                                .catch(() => {
                                    this.props.showToast(
                                        'Unable to charge, check your email for details',
                                        'negative'
                                    );
                                    this.handleIsSaving(false);
                                });
                        } else {
                            this.props.setAccountStore(res);
                            this.props.requestClose();
                            this.setState(this.getInitialState());
                        }
                    })
                    .catch(() => {
                        this.props.showToast('Couldn’t save billing information', 'negative');
                        this.handleIsSaving(false);
                    });
            } else {
                this.props.showToast('Please fill all required fields correctly', 'warning');
            }
        }
    };

    render() {
        const { billing_first_name, billing_last_name, address1, address2, city } = this.state;

        return (
            <Modal
                closeTimeoutMS={400}
                isOpen={this.props.isBillingInfoOpen}
                onRequestClose={() => this.props.requestClose()}
                className="modalContainer billingInfoModal"
                contentLabel="BillingInformation"
                shouldCloseOnOverlayClick={true}
            >
                <div>
                    <div className="modalHeader">
                        <h2>Billing Information</h2>
                        <div className="modalOptions">
                            <Icon
                                className="modalIcon"
                                size={24}
                                icon={closeIcon}
                                onClick={this.props.requestClose}
                            />
                        </div>
                    </div>
                    <div className="modalContent biModal">
                        <div className="biRow">
                            <div className="labelAndDropdown">
                                <div className="inputLabel">
                                    Billing First Name
                                    <div className="requiredLabel">Required</div>
                                </div>
                                <Input
                                    className={
                                        billing_first_name !== '' &&
                                        billing_first_name !== null &&
                                        !isNameValid(billing_first_name)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={billing_first_name}
                                    onChange={newName =>
                                        this.setState({
                                            billing_first_name: newName.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="labelAndDropdown">
                                <div className="inputLabel">
                                    Billing Last Name
                                    <div className="requiredLabel">Required</div>
                                </div>
                                <Input
                                    className={
                                        billing_last_name !== '' &&
                                        billing_last_name !== null &&
                                        !isNameValid(billing_last_name)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={billing_last_name}
                                    onChange={newName =>
                                        this.setState({
                                            billing_last_name: newName.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="biRow">
                            <div className="billingInfoError">
                                {billing_first_name !== '' &&
                                billing_first_name !== null &&
                                !isNameValid(billing_first_name) ? (
                                    <lable className="bimInvalidInputTip">
                                        {nameErrorMessage(billing_first_name)}
                                        You can only use alphabets and (- . ') between 1 to 50.
                                    </lable>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="billingInfoError">
                                {billing_last_name !== '' &&
                                billing_last_name !== null &&
                                !isNameValid(billing_last_name) ? (
                                    <lable className="bimInvalidInputTip">
                                        {nameErrorMessage(billing_last_name)}
                                        You can only use alphabets and (- . ') between 1 to 50.
                                    </lable>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <div className="biRow">
                            <div className="labelAndDropdown">
                                <div className="inputLabel">
                                    Address One
                                    <div className="requiredLabel">Required</div>
                                </div>
                                <Input
                                    className={
                                        address1 !== '' &&
                                        address1 !== null &&
                                        !isAddressValid(address1)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={address1}
                                    onChange={newAddress =>
                                        this.setState({
                                            address1: newAddress.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="labelAndDropdown">
                                <div className="inputLabel">Address Two</div>
                                <Input
                                    className={
                                        address2 !== '' &&
                                        address2 !== null &&
                                        !isAddressValid(address2)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={address2}
                                    onChange={newAddress =>
                                        this.setState({
                                            address2: newAddress.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="biRow">
                            <div className="billingInfoError">
                                {address1 !== null &&
                                address1 !== '' &&
                                !isAddressValid(address1) ? (
                                    <div className="bimInvalidInputTip">
                                        {addressErrorMessage(address1)}
                                        Address contains / - , (0-9) and (a-z) 2 to 100 characters.
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="billingInfoError">
                                {address2 !== null &&
                                address2 !== '' &&
                                !isAddressValid(address2) ? (
                                    <div className="bimInvalidInputTip">
                                        {addressErrorMessage(address2)}
                                        Address contains / - , (0-9) and (a-z) 2 to 100 characters.
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        <div className="biRow">
                            <div className="labelAndDropdown">
                                <div className="inputLabel">
                                    City
                                    <div className="requiredLabel">Required</div>
                                </div>
                                <Input
                                    className={
                                        city !== null && city !== '' && !isCityValid(city)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={city}
                                    onChange={newCity =>
                                        this.setState({
                                            city: newCity.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="billingInfoError">
                            {city !== null && city !== '' && !isCityValid(city) ? (
                                <div className="bimInvalidInputTip">
                                    {cityErrorMessage(city)}
                                    Billing city name contains only 1 to 50 alphabets.
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                            {({ elements, stripe }) => (
                                <StripeForm
                                    elements={elements}
                                    stripe={stripe}
                                    onSubmit={this.updateBillingInfo}
                                    isSavingBillingInfo={this.state.is_saving}
                                    setIsBillingInfoSaving={this.handleIsSaving}
                                />
                            )}
                        </ElementsConsumer>
                    </Elements>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        accountStore: state.accountStore,
    };
}

const mapDispatchToProps = {
    showToast,
    setAccountStore,
    reactivateAccount,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BillingInfoModal);
