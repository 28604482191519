import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import Icon from 'react-icons-kit';
import { stopwatch as timerIcon } from 'react-icons-kit/entypo/stopwatch';
import moment from 'moment';
import TimesheetWeekDetails from './components/TimesheetWeekDetails.js';
import NewTimeEntryModal from './components/NewTimeEntryModal.js';
import NewTimeEntryContainer from './components/NewTimeEntryContainer.js';
import NewTimeEntryInlineForm from './components/NewTimeEntryInlineForm.js';
import EditTimeEntryModal from './components/EditTimeEntryModal.js';
import MoreOptionsDropdown from '../components/MoreOptionsDropdown';
import DateFilter from '../components/DateFilter.js';
import DetailsLoading from '../components/DetailsLoading.js';
import SearchField from '../components/SearchField.js';
import Select from '../components/Select.js';
import Pagination from '../components/Pagination';
import ConfirmationModal from '../components/ConfirmationModal';
import GoogleAnalytics from '../../utils/GoogleAnalytics.js';
import { parseUrlVariables } from '../../utils/CommonFunctions.js';
import { expandDateToWeekRange } from '../../utils/DateUtils.js';
import {
    isDataValid,
    getDefaultFiltersForCurrentUser,
    figureDefaultSelectedEmployees,
    idsForWeeklyEntry,
} from '../../utils/TimeEntryUtils.js';
import {
    billableFilter as BILLABLE_FILTER_OPTIONS,
    taxableFilter as TAXABLE_FILTER_OPTIONS,
    exportedFilter as EXPORTED_FILTER_OPTIONS,
    approvedFilter as APPROVED_FILTER_OPTIONS,
    lockedFilter as LOCKED_FILTER_OPTIONS,
} from '../../config/Fields.js';
import { openTimer, continueTimer } from '../../actions/TimerActions.js';
import { showAlert, showToast } from '../../actions/Actions.js';
import * as timesheetsActions from '../../actions/TimesheetsActions.js';
import * as timesheetsSelectors from '../../selectors/TimesheetsSelectors';
import * as accountSelectors from '../../selectors/AccountSelectors';
import * as businessResourcesSelectors from '../../selectors/BusinessResourcesSelectors';
import { figureCanViewOthersTime, figureCanEditOthersTime } from '../../utils/PermissionUtils.js';
import { timerPropsForContinuingEntry, timerPropsForEntry } from '../../utils/TimerUtils.js';

import 'react-widgets/dist/css/react-widgets.css';
import '../../css/Timesheets.css';

// This is set in the API.
const resultsPerPage = 250;
const initialConfirmationModalState = {
    confirmationModalIsOpen: false,
    confirmationModalTitle: '',
    confirmationModalMessage: null,
    confirmationModalConfirmActionLabel: '',
    confirmationModalOnConfirm: null,
    confirmationModalConfirmActionIsDestructive: true,
};

class Timesheets extends Component {
    static propTypes = {
        history: PropTypes.any.isRequired,
        setTimeEntriesBillable: PropTypes.func.isRequired,
        setTimeEntriesTaxable: PropTypes.func.isRequired,
        setTimeEntriesApproved: PropTypes.func.isRequired,
        setTimeEntriesLocked: PropTypes.func.isRequired,
        deleteTimeEntries: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            confirmationModalIsOpen: false,
            confirmationModalTitle: '',
            confirmationModalMessage: null,
            confirmationModalOnConfirm: null,
            confirmationModalConfirmActionIsDestructive: true,
            oldInventoryItemId: '',
            oldPayrollItemId: '',
        };

        this.urlVariables = null;
    }

    componentDidMount() {
        this.urlVariables = parseUrlVariables(this.props.history);
        if (Object.keys(this.urlVariables).length > 0) {
            // Replace current path so we don't run into issues with attempting to change filters;
            this.props.history.replace('/timesheets');
        }
        if (this.props.isLoggedIn) {
            this.setInitialStoreState(this.props);
            this.refreshTimeEntriesStore(true);
        }
    }

    setInitialStoreState = props => {
        const { currentUser } = props;
        const viewOthersTime = figureCanViewOthersTime(currentUser);

        if (props.startDate === null || props.endDate === null) {
            const weekStartOffset = _.defaultTo(_.get(currentUser, 'week_start_offset', 0), 0);
            const startAndEndDate = expandDateToWeekRange(new Date(), weekStartOffset);
            props.setEndDate(startAndEndDate.endDate);
            props.setStartDate(startAndEndDate.startDate);
            props.setStartOfWeek(moment(startAndEndDate.startDate).format('Y-MM-DD'));
        }

        if (props.searchEmployeeIds === null) {
            const searchEmployeeIds = figureDefaultSelectedEmployees(currentUser);
            props.setSearchEmployeeIds(searchEmployeeIds);
        }

        if (props.activeFilters.length === 0 || props.noIntegration) {
            const activeFilters = getDefaultFiltersForCurrentUser(viewOthersTime);
            props.setActiveFilters(activeFilters);
        }
    };

    updateWeek = (date, shouldRefreshTimeEntriesStore = true) => {
        const range = expandDateToWeekRange(date, this.props.currentUser.week_start_offset);
        this.props.setStartDate(range.startDate);
        this.props.setEndDate(range.endDate);
        if (shouldRefreshTimeEntriesStore) {
            this.refreshTimeEntriesStore(true);
        }
    };

    refreshTimeEntriesStore = (showLoading = false) => {
        this.props.requestTimesheets({ showLoading, queryParams: this.urlVariables });
        this.urlVariables = null;
    };

    continueTimer = entry => {
        if (this.props.isTimerStarted) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Timer Is Already Active',
                confirmationModalMessage: `You’ve already started a timer for another entry. Stop that timer and save its time entry before starting a new one.`,
                confirmationModalOnConfirm: () => {
                    this.setState({ ...initialConfirmationModalState });
                    this.props.openTimer();
                },
                confirmationModalConfirmActionLabel: 'View Active Timer',
                confirmationModalConfirmActionIsDestructive: false,
            });
            return;
        }
        const props = timerPropsForContinuingEntry(entry);
        this.props.continueTimer(props);
    };

    startNewTimer = entry => {
        if (this.props.isTimerStarted) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Timer Is Already Active',
                confirmationModalMessage: `You’ve already started a timer for another entry. Stop that timer and save its time entry before starting a new one.`,
                confirmationModalOnConfirm: () => {
                    this.setState({ ...initialConfirmationModalState });
                    this.props.openTimer();
                },
                confirmationModalConfirmActionLabel: 'View Active Timer',
                confirmationModalConfirmActionIsDestructive: false,
            });
            return;
        }
        const props = timerPropsForEntry(entry);
        this.props.openTimer(props);
    };

    handleEditTimeEntryPressed = (timeEntry, entryHash) => {
        this.setState({oldInventoryItemId: timeEntry.inventory_item_id});
        this.setState({oldPayrollItemId: timeEntry.payroll_item_id});
        this.props.setEditTimesheetStore(timeEntry);
        this.props.selectTimeEntryHash(entryHash, false);
        this.props.setIsEditEntryModalOpen(true);
    };

    handleDuplicateTimeEntryPressed = (timeEntry, entryHash) => {
        GoogleAnalytics.timeEntry('Duplicate');
        this.props.setEditTimesheetStore(timeEntry);
        this.props.selectTimeEntryHash(entryHash, true);
        this.props.setIsNewEntryModalOpen(true);
    };

    handleSearchPressed = searchText => {
        this.props.setSearchText(searchText);
    };

    handleSaveEntryPressed = (entry, isEditMode, isDuplicate) => {
        if (!this.isEmployeeActive(entry.employee_id)) return;

        const isValid = isDataValid(
            entry,
            this.props.showAlert,
            this.props.showToast,
            this.props.noIntegration
        );
        if (!isValid) {
            return;
        }

        if (isEditMode) {
            entry['inventory_item_id_changed']="No";
            entry['payroll_item_id_changed']="No";
            if(this.props.noIntegration && this.state.oldInventoryItemId !== entry.inventory_item_id){
                entry['old_inventory_item_id']=this.state.oldInventoryItemId;
                entry['inventory_item_id_changed']='Yes';
            }
            if(this.props.noIntegration && this.state.oldPayrollItemId !== entry.payroll_item_id){
                entry['payroll_item_id_changed']='Yes';
            }

            this.props.updateTimeEntry(entry);
        } else if (isDuplicate) {
            this.props.duplicateTimeEntry(entry);
        } else {
            this.props.addTimeEntry(entry);
        }
    };

    isEmployeeActive = employeeId => {
        const employee = this.props.canViewEmployees.find(employee => employee.id === employeeId);
        if (employee && employee.hidden === 'No') return true;

        this.props.showToast('Cannot create entry with inactive employee', 'warning');
        return false;
    };

    handlePrevWeekPressed = () => {
        const startMoment = moment(this.props.startDate);
        const endMoment = moment(this.props.endDate);
        const diff = endMoment.diff(startMoment, 'days');
        const newStartDate = startMoment.subtract(diff + 1, 'days');
        const newEndDate = endMoment.subtract(diff + 1, 'days');

        this.handleDateFilterRangeChanged(newStartDate.toDate(), newEndDate.toDate());
    };

    handleNextWeekPressed = () => {
        const startMoment = moment(this.props.startDate);
        const endMoment = moment(this.props.endDate);
        const diff = endMoment.diff(startMoment, 'days');
        const newStartDate = startMoment.add(diff + 1, 'days');
        const newEndDate = endMoment.add(diff + 1, 'days');

        this.handleDateFilterRangeChanged(newStartDate.toDate(), newEndDate.toDate());
    };

    handleThisWeekPressed = () => {
        // We need to pass the date like this so the local time doesn't advance us a day
        const today = new Date();
        this.updateWeek(new Date(today.getFullYear(), today.getMonth(), today.getDate()));
    };

    handleEmployeeFilterChanged = ids => {
        this.props.setSearchEmployeeIds(ids);
        this.refreshTimeEntriesStore(true);
    };

    handleClearEmployeeFilterPressed = () => {
        this.props.setSearchEmployeeIds([]);
        this.refreshTimeEntriesStore(true);
    };

    handleCustomerFilterChanged = ids => {
        this.props.setSearchCustomerIds(ids);
        this.refreshTimeEntriesStore(true);
    };

    handleClearCustomerFilterPressed = () => {
        this.props.setSearchCustomerIds([]);
        this.refreshTimeEntriesStore(true);
    };

    handleInventoryItemFilterChanged = ids => {
        this.props.setSearchInventoryItemIds(ids);
        this.refreshTimeEntriesStore(true);
    };

    handleClearInventoryItemFilterPressed = () => {
        this.props.setSearchInventoryItemIds([]);
        this.refreshTimeEntriesStore(true);
    };

    handleQbClassFilterChanged = ids => {
        this.props.setSearchQbClassIds(ids);
        this.refreshTimeEntriesStore(true);
    };

    handleClearQbClassFilterPressed = () => {
        this.props.setSearchQbClassIds([]);
        this.refreshTimeEntriesStore(true);
    };

    handlePayrollItemFilterChanged = ids => {
        this.props.setSearchPayrollItemIds(ids);
        this.refreshTimeEntriesStore(true);
    };

    handleClearPayrollItemFilterPressed = () => {
        this.props.setSearchPayrollItemIds([]);
        this.refreshTimeEntriesStore(true);
    };

    handleBillableFilterChanged = value => {
        this.props.setBillableFilter(value);
        this.refreshTimeEntriesStore(true);
    };

    handleTaxableFilterChanged = value => {
        this.props.setTaxableFilter(value);
        this.refreshTimeEntriesStore(true);
    };

    handleExportedFilterChanged = value => {
        this.props.setExportedFilter(value);
        this.refreshTimeEntriesStore(true);
    };

    handleGroupingTypeChanged = value => {
        this.props.setGroupingType(value);
    };

    handleApprovedFilterChanged = value => {
        this.props.setApprovedFilter(value);
        this.refreshTimeEntriesStore(true);
    };

    handleLockedFilterChanged = value => {
        this.props.setLockedFilter(value);
        this.refreshTimeEntriesStore(true);
    };

    handleAddEntryPressed = () => {
        this.props.setIsNewEntryModalOpen(true);
    };

    handleAddEntryModalRequestedClose = () => {
        this.props.selectTimeEntryHash(null);
        this.props.setIsNewEntryModalOpen(false);
    };

    handleEditEntryModalRequestedClose = () => {
        this.setState({oldInventoryItemId: ""});
        this.props.selectTimeEntryHash(null);
        this.props.setIsEditEntryModalOpen(false);
    };

    handleNextPagePressed = () => {
        const currentPage = this.props.currentPage;
        // + 2 because currentPage is zero indexed and we are checking the next page
        if ((currentPage + 1) * resultsPerPage <= this.props.totalTimeEntries) {
            this.props.setCurrentPage(currentPage + 1);
            this.refreshTimeEntriesStore(true);
        }
    };

    handlePreviousPagePressed = () => {
        const currentPage = this.props.currentPage;
        if (currentPage > 0) {
            this.props.setCurrentPage(currentPage - 1);
            this.refreshTimeEntriesStore(true);
        }
    };

    handleSelectAllPressed = () => {
        const {
            entriesAvailableForEdit,
            groupingType,
            selectedEntryIds,
            setSelectedEntryIds,
            filteredEntries,
        } = this.props;

        const entries = Object.values(entriesAvailableForEdit);
        if (groupingType === 'week') {
            if (selectedEntryIds.length === entries.length) {
                // deselect all
                setSelectedEntryIds([]);
            } else {
                // select all
                const allIds = entries.map(entry => {
                    return 'uuid' in entry ? entry.uuid : entry.id;
                });
                setSelectedEntryIds(allIds);
            }
        } else {
            if (filteredEntries.length === selectedEntryIds.length) {
                setSelectedEntryIds([]);
            } else {
                const allIds = _.flatMap(entries, entry => idsForWeeklyEntry(entry));
                setSelectedEntryIds(allIds);
            }
        }
    };

    handleEntrySelected = id => {
        const index = this.props.selectedEntryIds.indexOf(id);
        if (index !== -1) {
            const tempSelectedIds = [...this.props.selectedEntryIds];
            // @FIX splice could cause issues
            tempSelectedIds.splice(index, 1);
            this.props.setSelectedEntryIds([...tempSelectedIds]);
        } else {
            const tempSelectedIds = [...this.props.selectedEntryIds];
            tempSelectedIds.push(id);
            this.props.setSelectedEntryIds([...tempSelectedIds]);
        }
    };

    handleBulkMarkApprovedPressed = () => {
        const filter = { filter: 'approvable' };
        const ids = this.getSelectedEntryIds(filter);
        const count = this.getSelectedEntries(filter).length;

        if (ids.length > 0) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Are you sure?',
                confirmationModalMessage: `You’re about to mark ${count} ${
                    count === 1 ? 'entry' : 'entries'
                } as approved.`,
                confirmationModalOnConfirm: () => {
                    this.setState({
                        ...initialConfirmationModalState,
                    });
                    this.props.setTimeEntriesApproved(ids, 'Yes');
                },
                confirmationModalConfirmActionLabel: 'Mark as Approved',
                confirmationModalConfirmActionIsDestructive: false,
            });
        } else {
            alert(
                'You don’t have the necessary permissions to mark the selected entries as approved.'
            );
        }
    };

    handleBulkMarkLockedPressed = () => {
        const filter = { filter: 'lockable' };
        const ids = this.getSelectedEntryIds(filter);
        const count = this.getSelectedEntries().length;

        if (ids.length > 0) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Are you sure?',
                confirmationModalMessage: `You’re about to mark ${count} ${
                    count === 1 ? 'entry' : 'entries'
                } as locked.`,
                confirmationModalOnConfirm: () => {
                    this.setState({
                        ...initialConfirmationModalState,
                    });
                    this.props.setTimeEntriesLocked(ids, 'Yes');
                },
                confirmationModalConfirmActionLabel: 'Mark as Locked',
                confirmationModalConfirmActionIsDestructive: false,
            });
        } else {
            alert(
                'You don’t have the necessary permissions to mark the selected entries as locked.'
            );
        }
    };

    handleBulkMarkPendingPressed = () => {
        const filter = { filter: 'approvable' };
        const ids = this.getSelectedEntryIds(filter);
        const count = this.getSelectedEntries().length;

        if (ids.length > 0) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Are you sure?',
                confirmationModalMessage: `You’re about to mark ${count} ${
                    count === 1 ? 'entry' : 'entries'
                } as pending.`,
                confirmationModalOnConfirm: () => {
                    this.setState({
                        ...initialConfirmationModalState,
                    });
                    this.props.setTimeEntriesApproved(ids, 'No');
                },
                confirmationModalConfirmActionLabel: 'Mark as Pending',
                confirmationModalConfirmActionIsDestructive: false,
            });
        } else {
            alert(
                'You don’t have the necessary permissions to mark the selected entries as pending.'
            );
        }
    };

    handleBulkMarkBillablePressed = () => {
        const filter = { filter: 'editable', deleteFlag: true };
        const ids = this.getSelectedEntryIds(filter);
        const count = this.getSelectedEntries(filter).length;

        if (ids.length > 0) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Are you sure?',
                confirmationModalMessage: `You’re about to mark ${count} ${
                    count === 1 ? 'entry' : 'entries'
                } as billable.`,
                confirmationModalOnConfirm: () => {
                    this.setState({
                        ...initialConfirmationModalState,
                    });
                    this.props.setTimeEntriesBillable(ids, 'Yes');
                },
                confirmationModalConfirmActionLabel: 'Mark as Billable',
                confirmationModalConfirmActionIsDestructive: false,
            });
        } else {
            alert(
                'You don’t have the necessary permissions to mark the selected entries as billable.'
            );
        }
    };

    handleBulkMarkTaxablePressed = () => {
        const filter = { filter: 'editable', billable: true };
        const ids = this.getSelectedEntryIds(filter);
        const count = ids.length;
        if (ids.length > 0) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Are you sure?',
                confirmationModalMessage: `You’re about to mark ${count} ${
                    count === 1 ? 'entry' : 'entries'
                } as taxable.`,
                confirmationModalOnConfirm: () => {
                    this.setState({
                        ...initialConfirmationModalState,
                    });
                    this.props.setTimeEntriesTaxable(ids, 'Yes');
                },
                confirmationModalConfirmActionLabel: 'Mark as Taxable',
                confirmationModalConfirmActionIsDestructive: false,
            });
        } else {
            alert(
                'You don’t have the necessary permissions to mark the selected entries as taxable.'
            );
        }
    };

    handleBulkMarkNotBillablePressed = () => {
        const filter = { filter: 'editable', deleteFlag: true };
        const ids = this.getSelectedEntryIds(filter);
        const count = this.getSelectedEntries(filter).length;

        if (ids.length > 0) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Are you sure?',
                confirmationModalMessage: `You’re about to mark ${count} ${
                    count === 1 ? 'entry' : 'entries'
                } as not billable.`,
                confirmationModalOnConfirm: () => {
                    this.setState({
                        ...initialConfirmationModalState,
                    });
                    this.props.setTimeEntriesBillable(ids, 'No');
                },
                confirmationModalConfirmActionLabel: 'Mark as Not Billable',
                confirmationModalConfirmActionIsDestructive: false,
            });
        } else {
            alert(
                'You don’t have the necessary permissions to mark the selected entries as non-billable.'
            );
        }
    };

    handleBulkMarkNotTaxablePressed = () => {
        const filter = { filter: 'editable' };
        const ids = this.getSelectedEntryIds(filter);

        const count = this.getSelectedEntries().length;

        if (ids.length > 0) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Are you sure?',
                confirmationModalMessage: `You’re about to mark ${count} ${
                    count === 1 ? 'entry' : 'entries'
                } as not taxable.`,
                confirmationModalOnConfirm: () => {
                    this.setState({
                        ...initialConfirmationModalState,
                    });
                    this.props.setTimeEntriesTaxable(ids, 'No');
                },
                confirmationModalConfirmActionLabel: 'Mark as Not Taxable',
                confirmationModalConfirmActionIsDestructive: false,
            });
        } else {
            alert(
                'You don’t have the necessary permissions to mark the selected entries as non-taxable.'
            );
        }
    };

    handleBulkDeletePressed = () => {
        const filter = { filter: 'editable', deleteFlag: true };
        const count = this.getSelectedEntries(filter).length;
        const ids = this.getSelectedEntryIds(filter);

        if (ids.length > 0) {
            this.setState({
                confirmationModalIsOpen: true,
                confirmationModalTitle: 'Are you sure?',
                confirmationModalMessage: `You’re about to delete ${count} ${
                    count === 1 ? 'entry' : 'entries'
                }.`,
                confirmationModalOnConfirm: () => {
                    this.setState({
                        ...initialConfirmationModalState,
                    });
                    this.props.deleteTimeEntries(ids);
                },
                confirmationModalConfirmActionLabel: 'Delete',
                confirmationModalConfirmActionIsDestructive: true,
            });
        } else {
            alert('You don’t have the necessary permissions to delete the selected entries.');
        }
    };

    // By default, this function will return entries that are available for editing OR approval
    // Use the `filter: 'editable'` option to limit to editable entries only
    getSelectedEntries = (opts = { filter: 'any', deleteFlag: false }) => {
        const options = _.defaults(opts, { filter: 'any', billable: false });
        const { filter, billable, deleteFlag } = options;
        const { selectedEntryIds, groupingType, weeklyEntriesByWeek, filteredEntries } = this.props;
        const matchesFilter = (entry, isSelected) => {
            if (this.props.noIntegration && entry.locked === 'Yes' && !deleteFlag) {
                return false;
            }
            if (filter === 'editable' && billable) {
                return (
                    isSelected && entry.can_be_edited_by_current_user && entry.billable === 'Yes'
                );
            }
            if (filter === 'editable') {
                return isSelected && entry.can_be_edited_by_current_user;
            }

            if (filter === 'approvable') {
                return isSelected && entry.can_be_approved_by_current_user;
            }

            if (filter === 'lockable') {
                return isSelected && entry.can_user_lock_entry;
            }
            return isSelected;
        };

        let selectedEntries;
        if (groupingType === 'week') {
            const entries = _.flatten(Object.values(weeklyEntriesByWeek).map(Object.values));
            selectedEntries = entries.filter(entry => {
                const isSelected = selectedEntryIds.includes(entry.uuid);
                return matchesFilter(entry, isSelected);
            });
        } else {
            selectedEntries = filteredEntries.filter(entry => {
                const isSelected = selectedEntryIds.includes(entry.id);
                return matchesFilter(entry, isSelected);
            });
        }

        return selectedEntries;
    };

    getSelectedEntryIds = (opts = { filter: 'any' }) => {
        const selectedEntries = this.getSelectedEntries(opts);
        const { groupingType } = this.props;

        if (groupingType === 'week') {
            const ids = _.flatMap(selectedEntries, entry => idsForWeeklyEntry(entry));
            return ids;
        } else {
            return selectedEntries.map(({ id }) => id);
        }
    };

    handleFilterChanged = id => {
        const { activeFilters, setActiveFilters } = this.props;
        let filters = [...activeFilters];
        if (_.includes(activeFilters, id)) {
            filters = _.without(filters, id);
        } else {
            filters.push(id);
        }
        setActiveFilters(filters);
        this.refreshTimeEntriesStore(true);
    };

    handleClearFiltersPressed = () => {
        const range = expandDateToWeekRange(new Date(), this.props.currentUser.week_start_offset);
        const canViewOthersTime = figureCanViewOthersTime(this.props.currentUser);

        this.props.setActiveFilters(getDefaultFiltersForCurrentUser(canViewOthersTime));
        this.props.setStartDate(range.startDate);
        this.props.setEndDate(range.endDate);
        this.props.setSearchText('');
        this.props.setSearchEmployeeIds(figureDefaultSelectedEmployees(this.props.currentUser));
        this.props.setSearchCustomerIds([]);
        this.props.setSearchInventoryItemIds([]);
        this.props.setSearchQbClassIds([]);
        this.props.setSearchPayrollItemIds([]);
        this.props.setApprovedFilter(APPROVED_FILTER_OPTIONS[0]);
        if (this.props.noIntegration) {
            this.props.setLockedFilter(LOCKED_FILTER_OPTIONS[0]);
        }
        this.props.setBillableFilter(BILLABLE_FILTER_OPTIONS[0]);
        this.props.setTaxableFilter(TAXABLE_FILTER_OPTIONS[0]);
        this.props.setExportedFilter(EXPORTED_FILTER_OPTIONS[0]);

        this.refreshTimeEntriesStore(true);
    };

    handleDateFilterRangeChanged = (startDate, endDate) => {
        this.props.setStartDate(startDate);
        this.props.setEndDate(endDate);
        this.refreshTimeEntriesStore(true);
    };

    handleNewEntryCollapsePressed = event => {
        if (event) {
            event.preventDefault();
        }

        this.props.setIsNewEntryCollapsed(!this.props.isNewEntryCollapsed);
    };

    handleConfirmationModalRequestedClose = () => {
        this.setState({
            confirmationModalIsOpen: false,
        });
    };

    render() {
        const {
            isLoggedIn,
            isUserClockInOnly,
            toggleTimerVisibility,
            isTimerStarted,
            isTimerRunning,
            canEditEmployees,
            canViewEmployees,
            startDate,
            endDate,
            searchEmployeeIds,
            searchCustomerIds,
            searchInventoryItemIds,
            searchQbClassIds,
            searchPayrollItemIds,
            approvedFilter,
            billableFilter,
            lockedFilter,
            taxableFilter,
            exportedFilter,
            isSaving,
            loading,
            isNewEntryModalOpen,
            isEditEntryModalOpen,
            activeFilters,
            selectedEntryIds,
            selectedTimeEntryHash,
            entriesAvailableForEdit,
            openTimer,
            totalTimeEntries,
            currentPage,
            isNewEntryCollapsed,
            groupingType,
            weeklyTotals,
            weeklyGrandTotal,
            entriesByWeek,
            weeklyEntriesByWeek,
            sortedWeekIds,
            timerTimeEntryId,
            shouldShowQbClasses,
            shouldShowPayrollItems,
            shouldShowBillable,
            shouldShowTaxable,
            shouldShowVendors,
            canEditOthersTime,
            canEditPayrollItems,
            allowedInventoryItems,
            allowedInventoryItemsForEditing,
            allowedQbClasses,
            allowedQbClassesForEditing,
            allowedCustomers,
            allowedCustomersForEditing,
            payrollItems,
            allowedTimesheetFilters,
            searchText,
            history,
            noIntegration,
        } = this.props;

        const {
            confirmationModalIsOpen,
            confirmationModalTitle,
            confirmationModalMessage,
            confirmationModalOnConfirm,
            confirmationModalConfirmActionLabel,
            confirmationModalConfirmActionIsDestructive,
        } = this.state;

        if (!isLoggedIn) {
            return null;
        }

        const groupedByWeek = groupingType === 'week';

        return (
            <div className="applicationBody">
                <NewTimeEntryModal
                    onSavePressed={this.handleSaveEntryPressed}
                    onRequestClose={this.handleAddEntryModalRequestedClose}
                    isOpen={isNewEntryModalOpen}
                    isTimerStarted={isTimerStarted}
                    isTimerRunning={isTimerRunning}
                    isSaving={isSaving}
                    shouldShowBillable={shouldShowBillable}
                    shouldShowTaxable={shouldShowTaxable}
                    shouldShowQbClasses={shouldShowQbClasses}
                    shouldShowPayrollItems={shouldShowPayrollItems && canEditPayrollItems}
                    canEditEmployees={canEditEmployees}
                    canEditOthersTime={canEditOthersTime}
                    toggleTimerVisibility={toggleTimerVisibility}
                    qbClasses={allowedQbClasses}
                    customers={allowedCustomers}
                    inventoryItems={allowedInventoryItems}
                />

                <EditTimeEntryModal
                    onSavePressed={this.handleSaveEntryPressed}
                    onRequestClose={this.handleEditEntryModalRequestedClose}
                    isTimerStarted={isTimerStarted}
                    isTimerRunning={isTimerRunning}
                    isSaving={isSaving}
                    isOpen={isEditEntryModalOpen}
                    shouldShowBillable={shouldShowBillable}
                    shouldShowTaxable={shouldShowTaxable}
                    shouldShowQbClasses={shouldShowQbClasses}
                    shouldShowPayrollItems={shouldShowPayrollItems && canEditPayrollItems}
                    canEditEmployees={canEditEmployees}
                    canEditOthersTime={canEditOthersTime}
                    toggleTimerVisibility={toggleTimerVisibility}
                    qbClasses={allowedQbClassesForEditing}
                    customers={allowedCustomersForEditing}
                    inventoryItems={allowedInventoryItemsForEditing}
                    mode="edit"
                />

                <ConfirmationModal
                    isOpen={confirmationModalIsOpen}
                    title={confirmationModalTitle}
                    message={confirmationModalMessage}
                    onConfirm={confirmationModalOnConfirm}
                    confirmActionLabel={confirmationModalConfirmActionLabel}
                    confirmActionIsDestructive={confirmationModalConfirmActionIsDestructive}
                    onRequestClose={this.handleConfirmationModalRequestedClose}
                />

                <div className="bodyHeader">
                    <h2>Timesheets</h2>
                    <div className="headerActions">
                        <button
                            onClick={openTimer}
                            className={
                                isUserClockInOnly
                                    ? 'secondaryButton'
                                    : 'secondaryButton tsTimerButton'
                            }
                        >
                            <Icon className="icon" icon={timerIcon} size={18} />{' '}
                            {isTimerStarted ? 'View Timer' : 'Start Timer'}
                        </button>
                    </div>
                </div>

                {!isUserClockInOnly && (
                    <NewTimeEntryContainer
                        onCollapsePressed={this.handleNewEntryCollapsePressed}
                        onSavePressed={this.handleSaveEntryPressed}
                        FormComponent={NewTimeEntryInlineForm}
                        toggleTimerVisibility={toggleTimerVisibility}
                        isTimerStarted={isTimerStarted}
                        isTimerRunning={isTimerRunning}
                        isSaving={isSaving}
                        isCollapsed={isNewEntryCollapsed}
                        qbClasses={allowedQbClasses}
                        customers={allowedCustomers}
                        inventoryItems={allowedInventoryItems}
                        shouldShowBillable={shouldShowBillable}
                        shouldShowTaxable={shouldShowTaxable}
                        shouldShowQbClasses={shouldShowQbClasses}
                        shouldShowVendors={shouldShowVendors}
                        shouldShowPayrollItems={shouldShowPayrollItems && canEditPayrollItems}
                        canEditEmployees={canEditEmployees}
                        canEditOthersTime={canEditOthersTime}
                        mode="add"
                        history={history}
                        isDuplicate={false}
                    />
                )}

                {selectedEntryIds.length === 0 && (
                    <div className="filters">
                        <div className="activeFilters">
                            {activeFilters.includes('date') && (
                                <DateFilter
                                    onPrevWeek={this.handlePrevWeekPressed}
                                    onNextWeek={this.handleNextWeekPressed}
                                    onRangeChange={this.handleDateFilterRangeChanged}
                                    onThisWeekPress={this.handleThisWeekPressed}
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            )}

                            {activeFilters.includes('search') && (
                                <SearchField
                                    placeholder="Search"
                                    value={searchText}
                                    onSearchPressed={this.handleSearchPressed}
                                />
                            )}

                            {activeFilters.includes('employee') && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    withSearch
                                    canSelectMultiple
                                    options={canViewEmployees}
                                    value={searchEmployeeIds}
                                    labelWhenNothingSelected="Employees"
                                    onChange={this.handleEmployeeFilterChanged}
                                    onClear={this.handleClearEmployeeFilterPressed}
                                />
                            )}

                            {activeFilters.includes('customer') && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    withSearch
                                    canSelectMultiple
                                    options={allowedCustomers}
                                    labelWhenNothingSelected="Customers"
                                    value={searchCustomerIds}
                                    onChange={this.handleCustomerFilterChanged}
                                    onClear={this.handleClearCustomerFilterPressed}
                                />
                            )}

                            {activeFilters.includes('inventoryItem') && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    withSearch
                                    canSelectMultiple
                                    options={allowedInventoryItems}
                                    labelWhenNothingSelected="Services"
                                    value={searchInventoryItemIds}
                                    onChange={this.handleInventoryItemFilterChanged}
                                    onClear={this.handleClearInventoryItemFilterPressed}
                                />
                            )}

                            {shouldShowQbClasses && activeFilters.includes('qbClass') && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    withSearch
                                    canSelectMultiple
                                    options={allowedQbClasses}
                                    labelWhenNothingSelected="Classes"
                                    value={searchQbClassIds}
                                    onChange={this.handleQbClassFilterChanged}
                                    onClear={this.handleClearQbClassFilterPressed}
                                />
                            )}

                            {shouldShowPayrollItems && activeFilters.includes('payrollItem') && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    withSearch
                                    canSelectMultiple
                                    options={payrollItems}
                                    labelWhenNothingSelected="Payroll Items"
                                    value={searchPayrollItemIds}
                                    onChange={this.handlePayrollItemFilterChanged}
                                    onClear={this.handleClearPayrollItemFilterPressed}
                                />
                            )}

                            {shouldShowBillable && activeFilters.includes('billable') && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    options={BILLABLE_FILTER_OPTIONS}
                                    value={billableFilter}
                                    defaultValue="all"
                                    onChange={this.handleBillableFilterChanged}
                                />
                            )}

                            {shouldShowTaxable && activeFilters.includes('taxable') && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    options={TAXABLE_FILTER_OPTIONS}
                                    value={taxableFilter}
                                    defaultValue="all"
                                    onChange={this.handleTaxableFilterChanged}
                                />
                            )}

                            {activeFilters.includes('approved') && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    options={APPROVED_FILTER_OPTIONS}
                                    value={approvedFilter}
                                    defaultValue="all"
                                    onChange={this.handleApprovedFilterChanged}
                                />
                            )}

                            {activeFilters.includes('locked') && noIntegration && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    options={LOCKED_FILTER_OPTIONS}
                                    value={lockedFilter}
                                    defaultValue="all"
                                    onChange={this.handleLockedFilterChanged}
                                />
                            )}

                            {activeFilters.includes('exported') && (
                                <Select
                                    toggleStyle="button"
                                    className="multiselectFilter"
                                    options={EXPORTED_FILTER_OPTIONS}
                                    value={exportedFilter}
                                    defaultValue="all"
                                    onChange={this.handleExportedFilterChanged}
                                />
                            )}
                        </div>

                        <div className="moreOptions">
                            <MoreOptionsDropdown
                                activeFilters={activeFilters}
                                availableFilters={allowedTimesheetFilters}
                                groupingType={groupingType}
                                onGroupingTypeChanged={this.handleGroupingTypeChanged}
                                onFilterPressed={this.handleFilterChanged}
                            />
                        </div>
                    </div>
                )}

                {selectedEntryIds.length > 0 && (
                    <div className="bulkActions">
                        <span className="numSelected">
                            <span className="num">{selectedEntryIds.length}</span>{' '}
                            {selectedEntryIds.length === 1 ? 'entry selected' : 'entries selected'}
                        </span>

                        <div className="buttons">
                            {noIntegration && (
                                <button
                                    className="secondaryButton"
                                    onClick={this.handleBulkMarkLockedPressed}
                                >
                                    Mark as Locked
                                </button>
                            )}
                            <button
                                className="secondaryButton"
                                onClick={this.handleBulkMarkApprovedPressed}
                            >
                                Mark as Approved
                            </button>

                            <button
                                className="secondaryButton"
                                onClick={this.handleBulkMarkPendingPressed}
                            >
                                Mark as Pending
                            </button>

                            {shouldShowBillable && (
                                <>
                                    <button
                                        className="secondaryButton"
                                        onClick={this.handleBulkMarkBillablePressed}
                                    >
                                        Mark as Billable
                                    </button>

                                    <button
                                        className="secondaryButton"
                                        onClick={this.handleBulkMarkNotBillablePressed}
                                    >
                                        Mark as Not Billable
                                    </button>
                                </>
                            )}
                            {shouldShowTaxable && (
                                <>
                                    <button
                                        className="secondaryButton"
                                        onClick={this.handleBulkMarkTaxablePressed}
                                    >
                                        Mark as Taxable
                                    </button>

                                    <button
                                        className="secondaryButton"
                                        onClick={this.handleBulkMarkNotTaxablePressed}
                                    >
                                        Mark as Not Taxable
                                    </button>
                                </>
                            )}
                            <button
                                className="destructiveSecondaryButton"
                                onClick={this.handleBulkDeletePressed}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                )}

                <div className="cardContainer">
                    {!loading ? (
                        <TimesheetWeekDetails
                            continueTimer={this.continueTimer}
                            startNewTimer={this.startNewTimer}
                            editTimeEntry={this.handleEditTimeEntryPressed}
                            duplicateTimeEntry={this.handleDuplicateTimeEntryPressed}
                            canEditEmployees={canEditEmployees}
                            groupedByWeek={groupedByWeek}
                            entriesByWeek={entriesByWeek}
                            weeklyEntriesByWeek={weeklyEntriesByWeek}
                            weekIds={sortedWeekIds}
                            weeklyTotals={weeklyTotals}
                            weeklyGrandTotal={weeklyGrandTotal}
                            selectedEntryHash={selectedTimeEntryHash}
                            selectedEntryIds={selectedEntryIds}
                            entriesAvailableForEdit={entriesAvailableForEdit}
                            onEntrySelect={this.handleEntrySelected}
                            onSelectAllPress={this.handleSelectAllPressed}
                            groupingType={groupingType}
                            timerTimeEntryId={timerTimeEntryId}
                            openTimer={openTimer}
                            startDate={startDate}
                            endDate={endDate}
                            shouldShowBillable={shouldShowBillable}
                            shouldShowTaxable={shouldShowTaxable}
                            shouldShowQbClasses={shouldShowQbClasses}
                            shouldShowPayrollItems={shouldShowPayrollItems}
                            noIntegration={noIntegration}
                        />
                    ) : (
                        <DetailsLoading description="Loading Timesheets" />
                    )}
                </div>
                <Pagination
                    total={totalTimeEntries}
                    perPage={resultsPerPage}
                    currentPage={currentPage}
                    onPreviousPressed={this.handlePreviousPagePressed}
                    onNextPressed={this.handleNextPagePressed}
                />
                <ReactTooltip delayShow={500} type="info" multiline={true} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const timesheetsStore = state.timesheetsStore;

    const filteredEntries = timesheetsSelectors.selectFilteredEntries(state);
    const entriesByWeek = timesheetsSelectors.selectGroupedByWeekAndSorted(state);
    const weeklyEntriesByWeek = timesheetsSelectors.selectGroupedByWeekAndWeekHashAndEmployee(
        state
    );
    const sortedWeekIds = timesheetsSelectors.selectSortedWeekIds(state);
    const weeklyTotals = timesheetsSelectors.selectWeeklyTotals(state);
    const weeklyGrandTotal = timesheetsSelectors.selectWeeklyGrandTotal(state);
    const entriesAvailableForEdit = timesheetsSelectors.selectEntriesAvailableForEdit(state);
    const dateFilterOptions = timesheetsSelectors.selectDateFilterOptions(state);
    const canEditEmployees = accountSelectors.selectCanEditActiveEmployeesForEditing(state);
    const canViewEmployees = accountSelectors.selectCanViewActiveEmployees(state);

    const shouldShowQbClasses = _.get(state.accountStore, 'show_qb_classes', 'No') === 'Yes';
    const shouldShowPayrollItems = _.get(state.accountStore, 'show_payroll', 'No') === 'Yes';
    const shouldShowBillable = _.get(state.accountStore, 'show_billable', 'No') === 'Yes';
    const shouldShowTaxable = _.get(state.accountStore, 'show_taxable', 'No') === 'Yes';
    const shouldShowVendors = _.get(state.accountStore, 'show_vendors', 'No') === 'Yes';
    const canEditPayrollItems = _.get(state.accountUserStore, 'edit_payroll', 'No') === 'Yes';
    const canViewOthersTime = figureCanViewOthersTime(state.accountUserStore);
    const canEditOthersTime = figureCanEditOthersTime(state.accountUserStore);
    const noIntegration = state.accountStore.qb_type === 'None';

    const allowedInventoryItems = businessResourcesSelectors
        .selectAllowedInventoryItems(state)
        .filter(item => item.type === 'Service');
    const allowedInventoryItemsForEditing = businessResourcesSelectors
        .selectAllowedInventoryItemsForEditing(state)
        .filter(item => item.type === 'Service');

    const allowedCustomers = businessResourcesSelectors.selectAllowedCustomers(state);
    const allowedCustomersForEditing = businessResourcesSelectors.selectAllowedCustomersForEditing(
        state
    );
    const allowedQbClasses = businessResourcesSelectors.selectAllowedQbClasses(state);
    const allowedQbClassesForEditing = businessResourcesSelectors.selectAllowedQbClassesForEditing(
        state
    );

    const allowedTimesheetFilters = timesheetsSelectors.selectAllowedTimesheetFilters(state);

    const {
        isNewEntryModalOpen,
        isEditEntryModalOpen,
        isSaving,
        loading,
        searchText,
        searchEmployeeIds,
        searchCustomerIds,
        searchInventoryItemIds,
        searchQbClassIds,
        searchPayrollItemIds,
        approvedFilter,
        billableFilter,
        lockedFilter,
        taxableFilter,
        exportedFilter,
        activeFilters,
        selectedEntryIds,
        selectedTimeEntryHash,
        totalTimeEntries,
        currentPage,
        isNewEntryCollapsed,
    } = timesheetsStore;

    const groupingType =
        timesheetsStore.groupingType || state.accountUserStore.timesheet_grouping || 'week';

    return {
        currentUser: state.accountUserStore,
        isTimerRunning: state.timerStore.isTimerRunning,
        isTimerStarted: state.timerStore.isTimerStarted,
        timerTimeEntryId: state.timerStore.timeEntryId,
        isLoggedIn: !_.isNil(state.appStore.session_id),
        isUserClockInOnly: state.accountUserStore.clock_in_only === 'Yes',
        canEditEmployees,
        canViewEmployees,
        canViewOthersTime,
        canEditPayrollItems,
        canEditOthersTime,
        shouldShowQbClasses,
        shouldShowPayrollItems,
        shouldShowBillable,
        shouldShowTaxable,
        shouldShowVendors,
        filteredEntries,
        entriesByWeek,
        weeklyEntriesByWeek,
        sortedWeekIds,
        weeklyTotals,
        weeklyGrandTotal,
        entriesAvailableForEdit,
        ...dateFilterOptions,
        allowedInventoryItems,
        allowedInventoryItemsForEditing,
        allowedCustomers,
        allowedCustomersForEditing,
        allowedQbClasses,
        allowedQbClassesForEditing,
        payrollItems: state.businessResourcesStore.payrollItems,
        isNewEntryModalOpen,
        isEditEntryModalOpen,
        isSaving,
        loading,
        searchEmployeeIds,
        searchCustomerIds,
        searchInventoryItemIds,
        searchQbClassIds,
        searchPayrollItemIds,
        approvedFilter,
        billableFilter,
        lockedFilter,
        taxableFilter,
        exportedFilter,
        activeFilters,
        selectedEntryIds,
        selectedTimeEntryHash,
        totalTimeEntries,
        currentPage,
        isNewEntryCollapsed,
        groupingType,
        allowedTimesheetFilters,
        searchText,
        noIntegration,
    };
}

const mapDispatchToProps = {
    ...timesheetsActions,
    showAlert,
    showToast,
    openTimer,
    continueTimer,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Timesheets);
