const YES = 'Yes';
const NO = 'No';
const FILTERED = 'Filtered';
const SELF = 'Self';

const parseToArray = value => {
    if (typeof value === 'string') return value.split(',');
    return value;
};

export const figureCanViewActivityLog = accountUserStore => {
    return figureIsManagerOverride(accountUserStore);
};

export const figureCanViewExpenses = (accountStore, accountUserStore) => {
    if (accountStore.show_expenses === NO) {
        return false;
    }

    if (figureIsManagerOverride(accountUserStore)) {
        return true;
    }

    if (
        accountUserStore.view_others_expenses !== NO ||
        accountUserStore.edit_others_expenses !== NO
    ) {
        return true;
    }

    return parseInt(accountUserStore.expense_vendor_id, 10) !== 0;
};

export const figureCanViewTimesheets = accountUserStore => {
    const isManagerOverride = figureIsManagerOverride(accountUserStore);
    return isManagerOverride || parseInt(accountUserStore.employee_id, 10) !== 0;
};

export const figureCanViewOthersTime = accountUserStore => {
    const isManagerOverride = figureIsManagerOverride(accountUserStore);
    return isManagerOverride || accountUserStore.view_others_time !== 'No';
};

export const figureCanEditOthersTime = user => {
    const isManagerOverride = figureIsManagerOverride(user);
    return isManagerOverride || user.edit_others_time !== 'No';
};

export const figureCanViewOthersExpenses = accountUserStore => {
    const isManagerOverride = figureIsManagerOverride(accountUserStore);
    return isManagerOverride || accountUserStore.view_others_expenses !== 'No';
};

export const figureCanEditOthersExpenses = accountUserStore => {
    const isManagerOverride = figureIsManagerOverride(accountUserStore);
    return isManagerOverride || accountUserStore.edit_others_expenses !== 'No';
};

export const figureIsManagerOverride = accountUserStore => {
    return accountUserStore.manage_account === YES || accountUserStore.manage_users === YES;
};

export const canUserApproveTimeEntry = ({ user, entry }) => {
    const isExported = entry.exported === 'Yes';
    const isLocked = entry.locked === 'Yes';
    if (isExported) {
        return false;
    }

    if (isLocked) {
        return false;
    }

    if (user.manage_account === 'Yes' || user.manage_users === 'Yes') {
        return true;
    }

    const approveOthersTime = user.approve_others_time;
    const employeeId = entry.type === 'Vendor' ? `${entry.employee_id}_v` : entry.employee_id;
    switch (approveOthersTime) {
        case YES:
            return true;
        case NO:
            return false;
        case FILTERED: {
            return parseToArray(user.filter_approve_employee_ids).includes(employeeId);
        }
        case SELF: {
            return entry.employee_id === user.employee_id;
        }
        default:
            return false;
    }
};

export const canUserLockTimeEntry = ({ user, entry }) => {
    const isLocked = entry.locked === 'Yes';

    if (isLocked) {
        return false;
    }

    if (user.manage_account === 'Yes' || user.manage_users === 'Yes') {
        return true;
    }

    return false;
};

export const canUserEditTimeEntry = ({
    user,
    entry,
    preventEditForSyncedEntries,
    prevent_edit_for_locked_entries,
    qbType,
}) => {
    const editOthersTime = user.edit_others_time;
    const employeeId = entry.type === 'Vendor' ? `${entry.employee_id}_v` : entry.employee_id;
    let isAllowed = false;

    if (user.manage_account === 'Yes' || user.manage_users === 'Yes') {
        isAllowed = true;
    } else {
        switch (editOthersTime) {
            case YES:
                isAllowed = true;
                break;
            case NO:
                isAllowed = entry.employee_id === user.employee_id;
                break;
            case FILTERED: {
                isAllowed = parseToArray(user.filter_edit_employee_ids).includes(employeeId);
                break;
            }
            default:
                break;
        }
    }

    const isExported = entry.exported === 'Yes';
    const isLocked = entry.locked === 'Yes';

    if (qbType === 'None') {
        return isAllowed && (!isLocked || (isLocked && !prevent_edit_for_locked_entries));
    } else {
        return (
            isAllowed &&
            (!isExported ||
                (isExported && !preventEditForSyncedEntries) ||
                (isLocked && !prevent_edit_for_locked_entries))
        );
    }
};

export const canUserApproveExpenseEntry = ({ user, entry }) => {
    const isExported = entry.exported === 'Yes';
    const isLocked = entry.locked === 'Yes';
    if (isExported) {
        return false;
    }

    if (isLocked) {
        return false;
    }

    if (user.manage_account === 'Yes' || user.manage_users === 'Yes') {
        return true;
    }

    const approveOthersExpenses = user.approve_others_expenses;
    switch (approveOthersExpenses) {
        case YES:
            return true;
        case NO:
            return false;
        case FILTERED: {
            let approveFilterList = parseToArray(user.filter_approve_expense_vendor_ids);
            let entryVendorId = entry.vendor_id;
            return approveFilterList.includes(entryVendorId);
        }
        case SELF: {
            return entry.vendor_id === user.expense_vendor_id;
        }
        default:
            return false;
    }
};

export const canUserEditExpenseEntry = ({
    user,
    entry,
    preventEditForSyncedEntries,
    prevent_edit_for_locked_entries,
    qbType,
}) => {
    const editOthersExpenses = user.edit_others_expenses;
    let isAllowed = false;

    if (user.manage_account === 'Yes' || user.manage_users === 'Yes') {
        isAllowed = true;
    } else {
        switch (editOthersExpenses) {
            case YES:
                isAllowed = true;
                break;
            case NO:
                isAllowed = entry.vendor_id === user.expense_vendor_id;
                break;
            case FILTERED: {
                const editFilterList = parseToArray(user.filter_edit_expense_vendor_ids);
                const vendorId = entry.vendor_id;
                isAllowed = editFilterList.includes(vendorId);
                break;
            }
            default:
                break;
        }
    }
    const isExported = entry.exported === 'Yes';
    const isLocked = entry.locked === 'Yes';

    if (qbType === 'None') {
        return isAllowed && (!isLocked || (isLocked && !prevent_edit_for_locked_entries));
    } else {
        return isAllowed && (!isExported || (isExported && !preventEditForSyncedEntries));
    }
};

export const parseAccountUserPermissions = ({ accountId, accountUser }) => {
    const employeeId =
        accountUser.type === 'Vendor' ? accountUser.employee_id + '_v' : accountUser.employee_id;

    return {
        id: accountUser.id,
        email: accountUser.email,
        employee_id: employeeId,
        type: accountUser.type,
        expense_vendor_id: accountUser.expense_vendor_id,
        account_id: accountId,
        manage_account: accountUser ? accountUser.manage_account : 'No',
        manage_users: accountUser ? accountUser.manage_users : 'No',
        manage_api: accountUser ? accountUser.manage_api : 'No',
        export_time: accountUser ? accountUser.export_time : 'No',
        view_reports: accountUser ? accountUser.view_reports : 'No',
        clock_in_only: accountUser ? accountUser.clock_in_only : 'No',
        edit_payroll: accountUser ? accountUser.edit_payroll : 'No',
        manage_payroll_payrates: accountUser ? accountUser.manage_payroll_payrates : 'No',
        manage_item_billrates: accountUser ? accountUser.manage_item_billrates : 'No',
        permission_group_id: accountUser ? accountUser.permission_group_id : '0',
        // Time
        edit_own_time: accountUser ? accountUser.edit_own_time : 'No',
        // VIEW_OTHERS_TIME
        view_others_time: accountUser ? accountUser.view_others_time : 'No',
        filter_view_employee_ids: accountUser
            ? accountUser.filter_view_employee_ids.split(',')
            : [],
        // EDIT_OTHERS_TIME
        edit_others_time: accountUser ? accountUser.edit_others_time : 'No',
        filter_edit_employee_ids: accountUser
            ? accountUser.filter_edit_employee_ids.split(',')
            : [],
        // APPROVE_OTHERS_TIME
        approve_others_time: accountUser ? accountUser.approve_others_time : 'No',
        filter_approve_employee_ids: accountUser
            ? accountUser.filter_approve_employee_ids.split(',')
            : [],
        // Expenses
        edit_own_expenses: accountUser ? accountUser.edit_own_expenses : 'No',
        // VIEW_OTHERS_EXPENSES
        view_others_expenses: accountUser ? accountUser.view_others_expenses : 'No',
        filter_view_expense_vendor_ids: accountUser
            ? accountUser.filter_view_expense_vendor_ids.split(',')
            : [],
        // EDIT_OTHERS_EXPENSES
        edit_others_expenses: accountUser ? accountUser.edit_others_expenses : 'No',
        filter_edit_expense_vendor_ids: accountUser
            ? accountUser.filter_edit_expense_vendor_ids.split(',')
            : [],
        // APPROVE_OTHERS_EXPENSES
        approve_others_expenses: accountUser ? accountUser.approve_others_expenses : 'No',
        filter_approve_expense_vendor_ids: accountUser
            ? accountUser.filter_approve_expense_vendor_ids.split(',')
            : [],
        // Misc
        // FILTER CUSTOMERS
        filter_customer_list: accountUser ? accountUser.filter_customer_list : 'No',
        filter_customer_ids: accountUser ? accountUser.filter_customer_ids.split(',') : [],
        // FILTER INVENTORY_ITEMS
        filter_inventory_item_list: accountUser ? accountUser.filter_inventory_item_list : 'No',
        filter_inventory_item_ids: accountUser
            ? accountUser.filter_inventory_item_ids.split(',')
            : [],
        // FILTER QB_ACCOUNTS
        filter_qb_account_list: accountUser ? accountUser.filter_qb_account_list : 'No',
        filter_qb_account_ids: accountUser ? accountUser.filter_qb_account_ids.split(',') : [],
        // FILTER QB_CLASSES
        filter_qb_class_list: accountUser ? accountUser.filter_qb_class_list : 'No',
        filter_qb_class_ids: accountUser ? accountUser.filter_qb_class_ids.split(',') : [],
        approve_others_expenses_override_group: accountUser.approve_others_expenses_override_group,
        approve_others_time_override_group: accountUser.approve_others_time_override_group,
        edit_others_expenses_override_group: accountUser.edit_others_expenses_override_group,
        edit_others_time_override_group: accountUser.edit_others_time_override_group,
        edit_own_expenses_override_group: accountUser.edit_own_expenses_override_group,
        edit_own_time_override_group: accountUser.edit_own_time_override_group,
        edit_payroll_override_group: accountUser.edit_payroll_override_group,
        manage_payroll_payrates_override_group: accountUser.manage_payroll_payrates_override_group,
        manage_item_billrates_override_group: accountUser.manage_item_billrates_override_group,
        export_time_override_group: accountUser.export_time_override_group,
        filter_approve_employee_ids_override_group:
            accountUser.filter_approve_employee_ids_override_group,
        filter_approve_expense_vendor_ids_override_group:
            accountUser.filter_approve_expense_vendor_ids_override_group,
        filter_customer_ids_override_group: accountUser.filter_customer_ids_override_group,
        filter_customer_list_override_group: accountUser.filter_customer_list_override_group,
        filter_edit_employee_ids_override_group:
            accountUser.filter_edit_employee_ids_override_group,
        filter_edit_expense_vendor_ids_override_group:
            accountUser.filter_edit_expense_vendor_ids_override_group,
        filter_inventory_item_ids_override_group:
            accountUser.filter_inventory_item_ids_override_group,
        filter_inventory_item_list_override_group:
            accountUser.filter_inventory_item_list_override_group,
        filter_qb_account_ids_override_group: accountUser.filter_qb_account_ids_override_group,
        filter_qb_account_list_override_group: accountUser.filter_qb_account_list_override_group,
        filter_qb_class_ids_override_group: accountUser.filter_qb_class_ids_override_group,
        filter_qb_class_list_override_group: accountUser.filter_qb_class_list_override_group,
        filter_view_employee_ids_override_group:
            accountUser.filter_view_employee_ids_override_group,
        filter_view_expense_vendor_ids_override_group:
            accountUser.filter_view_expense_vendor_ids_override_group,
        manage_account_override_group: accountUser.manage_account_override_group,
        manage_expenses_override_group: accountUser.manage_expenses_override_group,
        manage_time_override_group: accountUser.manage_time_override_group,
        manage_users_override_group: accountUser.manage_users_override_group,
        manage_api_override_group: accountUser.manage_api_override_group,
        manage_users_override: accountUser ? accountUser.manage_users_override : 'No',
        restrictions_override_group: accountUser.restrictions_override_group,
        view_others_expenses_override_group: accountUser.view_others_expenses_override_group,
        view_others_time_override_group: accountUser.view_others_time_override_group,
        view_reports_override_group: accountUser.view_reports_override_group,
    };
};

export const parsePermissionsGroupPermissions = ({ permissionsGroup }) => {
    return {
        permission_group_id: permissionsGroup.id,
        approve_others_expenses: permissionsGroup.approve_others_expenses,
        approve_others_time: permissionsGroup.approve_others_time,
        edit_others_expenses: permissionsGroup.edit_others_expenses,
        edit_others_time: permissionsGroup.edit_others_time,
        edit_own_expenses: permissionsGroup.edit_own_expenses,
        edit_own_time: permissionsGroup.edit_own_time,
        edit_payroll: permissionsGroup.edit_payroll,
        export_time: permissionsGroup.export_time,
        manage_account: permissionsGroup.manage_account,
        //manage_users: permissionsGroup.manage_users,
        permission_group_manage_users: permissionsGroup.manage_users,
        manage_payroll_payrates: permissionsGroup.manage_payroll_payrates,
        manage_item_billrates: permissionsGroup.manage_item_billrates,
        manage_api: permissionsGroup.manage_api,
        view_others_expenses: permissionsGroup.view_others_expenses,
        view_others_time: permissionsGroup.view_others_time,
        view_reports: permissionsGroup.view_reports,
        filter_view_employee_ids: permissionsGroup
            ? permissionsGroup.filter_view_employee_ids.split(',')
            : [],
        filter_edit_employee_ids: permissionsGroup
            ? permissionsGroup.filter_edit_employee_ids.split(',')
            : [],
        filter_approve_employee_ids: permissionsGroup
            ? permissionsGroup.filter_approve_employee_ids.split(',')
            : [],
        filter_view_expense_vendor_ids: permissionsGroup
            ? permissionsGroup.filter_view_expense_vendor_ids.split(',')
            : [],
        filter_edit_expense_vendor_ids: permissionsGroup
            ? permissionsGroup.filter_edit_expense_vendor_ids.split(',')
            : [],
        filter_approve_expense_vendor_ids: permissionsGroup
            ? permissionsGroup.filter_approve_expense_vendor_ids.split(',')
            : [],
        filter_customer_list: permissionsGroup ? permissionsGroup.filter_customer_list : 'No',
        filter_customer_ids: permissionsGroup
            ? permissionsGroup.filter_customer_ids.split(',')
            : [],
        filter_inventory_item_list: permissionsGroup
            ? permissionsGroup.filter_inventory_item_list
            : 'No',
        filter_inventory_item_ids: permissionsGroup
            ? permissionsGroup.filter_inventory_item_ids.split(',')
            : [],
        filter_qb_account_list: permissionsGroup ? permissionsGroup.filter_qb_account_list : 'No',
        filter_qb_account_ids: permissionsGroup
            ? permissionsGroup.filter_qb_account_ids.split(',')
            : [],
        filter_qb_class_list: permissionsGroup ? permissionsGroup.filter_qb_class_list : 'No',
        filter_qb_class_ids: permissionsGroup
            ? permissionsGroup.filter_qb_class_ids.split(',')
            : [],
        approve_others_expenses_override_group: 'No',
        approve_others_time_override_group: 'No',
        edit_others_expenses_override_group: 'No',
        edit_others_time_override_group: 'No',
        edit_own_expenses_override_group: 'No',
        edit_own_time_override_group: 'No',
        edit_payroll_override_group: 'No',
        manage_payroll_payrates_override_group: 'No',
        manage_item_billrates_override_group: 'No',
        export_time_override_group: 'No',
        filter_approve_employee_ids_override_group: 'No',
        filter_approve_expense_vendor_ids_override_group: 'No',
        filter_customer_ids_override_group: 'No',
        filter_customer_list_override_group: 'No',
        filter_edit_employee_ids_override_group: 'No',
        filter_edit_expense_vendor_ids_override_group: 'No',
        filter_inventory_item_ids_override_group: 'No',
        filter_inventory_item_list_override_group: 'No',
        filter_qb_account_ids_override_group: 'No',
        filter_qb_account_list_override_group: 'No',
        filter_qb_class_ids_override_group: 'No',
        filter_qb_class_list_override_group: 'No',
        filter_view_employee_ids_override_group: 'No',
        filter_view_expense_vendor_ids_override_group: 'No',
        manage_account_override_group: 'No',
        manage_expenses_override_group: 'No',
        manage_time_override_group: 'No',
        manage_users_override_group: 'No',
        manage_api_override_group: 'No',
        restrictions_override_group: 'No',
        view_others_expenses_override_group: 'No',
        view_others_time_override_group: 'No',
        view_reports_override_group: 'No',
    };
};
