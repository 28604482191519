import React from 'react';

const QBOVersion = () => (
    <div>
        <div className="sectionQuestion">
            What versions of QuickBooks Online Edition will work with Minute7?
        </div>
        <div className="sectionAnswer">
            Minute7 can sync with any version of QuickBooks Online Edition that has{' '}
            <b>time and expense tracking enabled</b>.
            <br /> <br />
            Currently, QuickBooks Online Basic does <b>not</b> offer support for time tracking, and
            so will not work with Minute7's time tracking features; if your company uses QuickBooks
            Online Basic, you may need to upgrade to QuickBooks Online Plus or enable Time Tracking
            in QuickBooks Online to use Minute7's time tracking features.
            <br /> <br />
            For more information about the capabilities of different versions of QuickBooks Online,
            please see Intuit's QuickBooks Online product comparisons.
        </div>
    </div>
);

export default QBOVersion;
