import React, { useMemo } from 'react';
import Icon from 'react-icons-kit';
import { plus } from 'react-icons-kit/ikons/plus';
import MinuteTable from '../../components/MinuteTable';
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit';
import { getAllVendors } from '../../../requests/VendorRequests';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import { refresh } from 'react-icons-kit/ionicons/refresh';
import Modal from 'react-modal';
import '../../../css/Timesheets.css';
import Select from '../../components/Select.js';
try {
    Modal.setAppElement('#root');
} catch { }

const VendorResourcesList = ({
    title,
    onClick,
    addActionTip,
    history,
    createNewLabel,
    createNewPath,
    activateVendor,
    deactivateVendor,
    currentUserId,
}) => {
    const columns = useMemo(
        () => [
            {
                accessor: 'first_name',
                Header: 'First Name',
            },
            {
                accessor: 'last_name',
                Header: 'Last Name',
            },
            {
                accessor: 'email',
                Header: 'Email',
            },
            {
                Header: 'Status',
                id: 'status',
                accessor: () => 'Status',
                Cell: tableProps => (
                    <td className="tsStatusCell">
                        {tableProps.row.original.hidden === 'No' && (
                            <span
                                className={currentUserId === tableProps.row.original.id ? "tsStatus tsStatusDisabled" : "tsStatus tsStatusApproved"}
                                onClick={() => onToggleApproved(tableProps.row.original, currentUserId)}
                            >
                                {isUpdatingStatus ? 'Updating...' : 'Active'}
                            </span>
                        )}
                        {tableProps.row.original.hidden === 'Yes' && (
                            <span
                                className={currentUserId === tableProps.row.original.id ? "tsStatus tsStatusDisabled" : "tsStatus tsStatusPending"}
                                onClick={() => onToggleApproved(tableProps.row.original, currentUserId)}
                            >
                                {isUpdatingStatus ? 'Updating...' : 'Inactive'}
                            </span>
                        )}
                    </td>
                ),
            },
            {
                Header: 'Actions',
                id: 'actions',
                accessor: str => 'Actions',

                Cell: tableProps => (
                    <Icon
                        onClick={() => onClick(tableProps.row.original, currentUserId)}
                        icon={ic_mode_edit}
                        className="tableIcon asAccountUserIcon"
                        size={22}
                        data-tip="Edit Permissions"
                        style={{
                            color: checkBoxColor(
                                currentUserId === tableProps.row.original.id
                            ),
                        }}
                    />
                ),
            },
        ],
        []
    );
    const statusFilters = [
        { name: 'All VENDORS', id: 'all' },
        { name: 'ACTIVE', id: 'active' },
        { name: 'INACTIVE', id: 'inactive' },
    ];
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);
    const sortIdRef = React.useRef(0);
    const [refreshTable, setRefreshTable] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [selectedVendorId, setSelectedVendorId] = React.useState(0);
    const [filter, setFilter] = React.useState('active');
    const [resetTable, setResetTable] = React.useState(false);
    const [isUpdatingStatus, setIsUpdatingStatus] = React.useState(false);

    const fetchData = React.useCallback(({ sortBy, pageSize, pageIndex, filter }) => {
        const fetchId = ++fetchIdRef.current;
        setLoading(true);
        setRefreshTable(false);
        setResetTable(false);
        setTimeout(() => {
            if (fetchId === fetchIdRef.current) {
                let paramsObject = {
                    current_page: pageIndex + 1,
                    per_page: pageSize,
                    filter: filter,
                };
                Promise.all([getAllVendors(paramsObject)]).then(response => {
                    let json = response[0];

                    let vendorsList = json.vendorsList;

                    if (sortBy.length > 0) {
                        const sortId = ++sortIdRef.current;
                        if (sortId === sortIdRef.current) {
                            let sorted = vendorsList;
                            sorted.sort((a, b) => {
                                for (let i = 0; i < sortBy.length; ++i) {
                                    if (a[sortBy[i].id] > b[sortBy[i].id])
                                        return sortBy[i].desc ? -1 : 1;
                                    if (a[sortBy[i].id] < b[sortBy[i].id])
                                        return sortBy[i].desc ? 1 : -1;
                                }
                                return 0;
                            });
                            setData(vendorsList);
                            setPageCount(Math.ceil(json.meta.total / pageSize));
                            setLoading(false);
                        }
                    } else {
                        setData(vendorsList);
                        setPageCount(Math.ceil(json.meta.total / pageSize));
                        setLoading(false);
                    }
                });
            }
        }, 1000);
    }, []);

    function checkBoxColor(value) {
        if (value) {
            return 'gray';
        } else {
            return '#008fd7';
        }
    }
    function handleFilterChanged(value) {
        setFilter(value);
        setResetTable(true);
    }
    function closeModal() {
        setIsModalOpen(false);
    }

    function onToggleApproved(vendor, currentVendorId) {
        if (currentVendorId !== vendor.id) {
            if (vendor.hidden === 'No') {
                setSelectedVendorId(vendor.id);
                setIsModalOpen(true);
            } else {
                setIsUpdatingStatus(true);
                activateVendor(vendor.id);
                setIsUpdatingStatus(false);
                setRefreshTable(true);
            }
        }
    }

    function inactiveUser() {
        setIsUpdatingStatus(true);
        deactivateVendor(selectedVendorId);
        setIsUpdatingStatus(false);
        setRefreshTable(true);
        closeModal();
    }

    return (
        <div>
            <h3 className="cardHeading">
                {title}
                <div className="bodyHeader filters">
                    <div className="" style={{ fontWeight: 'lighter' }}>
                        <Select
                            toggleStyle="button"
                            className="multiselectFilter"
                            options={statusFilters}
                            value={filter}
                            defaultValue="active"
                            onChange={handleFilterChanged}
                        />
                    </div>
                    <div className="">
                        <button
                            className="secondaryButton"
                            style={{ height: '35px' }}
                            onClick={() => history.push(createNewPath)}
                        >
                            <Icon className="icon" icon={plus} size={20} />
                            {createNewLabel}
                        </button>
                    </div>
                    <div className="headerActions">
                        <button
                            className="secondaryButton"
                            style={{ height: '35px' }}
                            onClick={() => setRefreshTable(true)}
                        >
                            <Icon className="icon" icon={refresh} size={20} />
                        </button>
                    </div>
                </div>
            </h3>
            <Modal
                closeTimeoutMS={400}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Inactive user"
                className="modalContainer resourceModal"
            >
                <div className="modalHeader">
                    <h2>Deactivate Vendor</h2>
                    <div className="modalOptions">
                        <Icon
                            className="modalIcon"
                            size={32}
                            icon={closeIcon}
                            onClick={closeModal}
                        />
                    </div>
                </div>
                <div className="modalContent helpModalContent">
                    <div className="sectionAnswer">
                        Are you sure you want to deactivate this vendor?
                    </div>
                    {
                        <div className="sectionAnswer">
                            Note: Deactivating an vendor will also remove any associated user.
                        </div>
                    }
                    <div className="sectionButton">
                        <button className="destructiveButton" onClick={() => inactiveUser()}>
                            Deactivate
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="cardContainer asSection">
                <MinuteTable
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount}
                    filter={filter}
                    refresh={refreshTable}
                    resetTable={resetTable}
                />
            </div>
        </div>
    );
};

export default VendorResourcesList;
