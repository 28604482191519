import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../css/DetailsLoading.css';

const DetailsLoading = ({ description, standalone }) => {
    return (
        <div className={classNames({ detailsLoadingContainer: true, standalone })}>
            <div className="detailsLoadingHeader">
                {description}
            </div>
            <span className="detailsLoadingIcon" />
        </div>
    );
}

DetailsLoading.propTypes = {
    standalone: PropTypes.bool,
    description: PropTypes.string.isRequired,
};

DetailsLoading.defaultProps = {
    standalone: false,
};

export default DetailsLoading;