export const INITIAL_STATE = {
    canViewVendors: [],
    canEditVendors: [],  
    allVendors: [],
};

export const vendorReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'RECEIVE_BUSINESS_RESOURCES': {
            if ('canViewVendors' in action.payload || 'canEditVendors' in action.payload||
            'allVendors' in action.payload) {
                const newState = { ...state };
                ['canViewVendors', 'canEditVendors'].forEach(k => {
                    if (k in action.payload) {
                        newState[k] = action.payload[k];
                    }
                });
                return newState;
            } else {
                return state;
            }
        }
        case 'SET_CAN_VIEW_VENDORS':
            return {
                ...state,
                canViewVendors: action.payload,
            };
        case 'SET_CAN_EDIT_VENDORS':
            return {
                ...state,
                canEditVendors: action.payload,
            };
        case 'SET_ALL_VENDORS':
            return {
                ...state,
                allVendors: action.payload,
            };
        case 'ADD_VENDOR':
            return {
                ...state,
                isSaving: true,
            };
        case 'ADD_VENDOR_SUCCESS':
            return {
                ...state,
                isSaving: false,
            };
        case 'ADD_VENDOR_ERROR':
            return {
                ...state,
                isSaving: false,
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default vendorReducer;
