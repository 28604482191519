import React from 'react';

const SearchTab = () => (
    <div className="helpModal">
        <div className="sectionQuestion">What happened to the Search tab?</div>
        <div className="sectionAnswer">
            <p>
                We have included all of the Search functionality directly in the Timesheets and
                Expense tab, respectively. If you scroll to the top of the Timesheet or Expense
                table, you will see:
            </p>
            <ul>
                <li>a) all of the filters you need to filter through the data</li>
                <li>
                    b) you can still do batch processing (for example, select multiple entries and
                    mark them as approved, not approved, billable, not billable, etc.)
                </li>
            </ul>
        </div>
    </div>
);

export default SearchTab;
