import { useState, useEffect } from 'react';

// used in cases where a click outside a container element needs to close it (useful for dropdowns)
const useDismissableByClickOutside = containerRef => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = event => {
        if (containerRef.current === null || containerRef.current.contains(event.target)) {
            return;
        }
        setIsOpen(false);
    };

    useEffect(
        () => {
            if (isOpen) {
                document.addEventListener('mousedown', handleClick, false);
            }

            return () => {
                document.removeEventListener('mousedown', handleClick, false);
            };
        },
        [isOpen]
    );

    return [isOpen, setIsOpen];
};

export default useDismissableByClickOutside;
