import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import '../../css/Spinner.css';

const override = {
 
    
};

export default class Spinner extends React.Component {


    render() {
        const {
            color,
            loading,
        } = this.props;

        return (
            loading && (<Row >
                <Col sm={12} style={{ marginBottom: '30px' , marginTop: '30px' }}>
                    <div className="spinner-loading">
                        <ClipLoader
                            cssOverride={override}
                            size={50}
                            color={color}
                            loading={loading}
                        />
                    </div>
                </Col>
            </Row>)

        );
    }
}

Spinner.propTypes = {
    color: PropTypes.string,
    loading: PropTypes.string.isRequired,
};

Spinner.defaultProps = {
    color: '#008fd7',
};