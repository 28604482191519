import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { ic_radio_button_checked as radioChecked } from 'react-icons-kit/md/ic_radio_button_checked';
import { ic_radio_button_unchecked as radioUnchecked } from 'react-icons-kit/md/ic_radio_button_unchecked';
import ToggleOptions from './ToggleOptions';

import classNames from 'classnames';

export default class ToggleSetting extends Component {
    static defaultProps = {
        permissionsGroupId: '0',
        firstListOptions: null,
        secondListOptions: null,
        labelForOption: o => o,
        valueForOption: o => o,
        labelForListOption: o => o.name,
        valueForListOption: o => o.id,
        subtitle: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            firstSearchFilter: '',
            secondSearchFilter: '',
            firstSearchFilterSecondList: '',
            secondSearchFilterSecondList: '',
        };
    }

    figureCanChange = () => {
        return (
            !('overrideValue' in this.props) ||
            this.props.overrideValue === 'Yes' ||
            parseInt(this.props.permissionsGroupId, 10) === 0
        );
    };

    render() {
        const {
            value,
            options,
            title,
            subtitle,
            onChange,
            overrideValue,
            permissionsGroupId,
            firstListOptions,
            secondListOptions,
            firstLabel,
            secondLabel,
            listValue,
            overrideOnChange,
            labelForOption,
            valueForOption,
        } = this.props;

        const {
            firstSearchFilter,
            secondSearchFilter,
            firstSearchFilterSecondList,
            secondSearchFilterSecondList,
        } = this.state;

        const shouldShowOverrides =
            permissionsGroupId !== null && parseInt(permissionsGroupId, 10) > 0;

        const values = options.map(valueForOption);
        const labels = options.map(labelForOption);

        return (
            <div className="toggleSettingRow">
                <div className="toggleSetting">
                    <div className="toggleSettingLabel">
                        {title}
                        {subtitle !== '' && <div className="note">{subtitle}</div>}
                    </div>
                    <div className="toggleSettingValuesContainer">
                        {labels.map((label, index) => {
                            return (
                                <div
                                    key={label}
                                    className={classNames({
                                        toggleSettingValue: true,
                                        toggleSettingValueDisabled: !this.figureCanChange(),
                                    })}
                                    onClick={() => {
                                        if (this.figureCanChange()) {
                                            onChange(values[index]);
                                        }
                                    }}
                                >
                                    <Icon
                                        icon={
                                            values[index] === value ? radioChecked : radioUnchecked
                                        }
                                        size={18}
                                        className="radioIcon"
                                    />
                                    <span className="radioLabel">{label}</span>
                                </div>
                            );
                        })}
                    </div>

                    {shouldShowOverrides && (
                        <div className="toggleSettingOverrideContainer">
                            <div className="toggleSettingOverride">
                                <div className="overrideText">Override Group</div>
                                <div
                                    className="overrideToggle"
                                    onClick={() => overrideOnChange('Yes')}
                                >
                                    <Icon
                                        icon={
                                            overrideValue === 'Yes' ? radioChecked : radioUnchecked
                                        }
                                        size={18}
                                        className="radioIcon"
                                    />
                                    <span className="radioLabel">Yes</span>
                                </div>
                                <div
                                    className="overrideToggle"
                                    onClick={() => overrideOnChange('No')}
                                >
                                    <Icon
                                        icon={
                                            overrideValue === 'Yes' ? radioUnchecked : radioChecked
                                        }
                                        size={18}
                                        className="radioIcon"
                                    />
                                    <span className="radioLabel">No</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {firstListOptions !== null && this.figureCanChange() && (
                    <ToggleOptions
                        listOptions={firstListOptions}
                        firstSearchFilter={firstSearchFilter}
                        secondSearchFilter={secondSearchFilter}
                        label={firstLabel}
                        listValue={listValue}
                        options={options}
                        onListChange={this.props.onListChange}
                    />
                )}
                {secondListOptions !== null && this.figureCanChange() && (
                    <ToggleOptions
                        listOptions={secondListOptions}
                        firstSearchFilter={firstSearchFilterSecondList}
                        secondSearchFilter={secondSearchFilterSecondList}
                        label={secondLabel}
                        listValue={listValue}
                        overrideValue={overrideValue}
                        options={options}
                        onListChange={this.props.onListChange}
                    />
                )}
            </div>
        );
    }
}
