import { getData, createQueryString } from '../utils/HelperFunctions.js';
import GoogleAnalytics from '../utils/GoogleAnalytics.js';
import { openPopUpWindow } from '../utils/CommonFunctions.js';

// Set window location to force download file (headers set by endpoint)
export const getQWC = (paramsObject) => {
    let url = createQueryString(paramsObject, 'sync/qwc_download');
    window.location = url;
};

export const onlineSync = (paramsObject = {}) => {
    GoogleAnalytics.QBO('Sync');
    return getData(paramsObject, 'sync/online_sync').then(res => res);
};

export const qbConnect = (paramsObject = {}) => {
    GoogleAnalytics.QBO('Connect');
    let url = createQueryString(paramsObject, 'sync/qb_connect');
    let qbConnectWindow = openPopUpWindow('QuickBooks Online', 800, 700, url);
    // Reload the current sync page so OAuth is tested
    var timer = setInterval(() => {
        if (qbConnectWindow.closed) {
            clearInterval(timer);
            window.location.reload();
        }
    }, 1000);
};

export const isOauthValid = (paramsObject = {}) => {
    return getData(paramsObject, 'sync/oauth_valid').then(res => res);
};

export const qbDisconnect = (paramsObject = {}) => {
    return getData(paramsObject, 'sync/qb_disconnect').then(res => res);
};
