import React from 'react';

const BillableAndUnbillableTime = () => (
    <div>
        <div className="sectionQuestion">How can I enter billable and unbillable time</div>
        <div className="sectionAnswer">
            By default, all time entries in Minute7 are billable and will appear as billable in
            QuickBooks. However, if you want to be able to mark some time entries as not billable,
            or want time entries to be marked unbillable by default, you can go to your account
            settings page and change the option that says{' '}
            <b>Allow users to set the billable status of time entries</b> to <b>'Yes'</b>.
            <br /> <br />
            Billable entries will be marked with a label:{' '}
            <span class="tsBillableTag isBillable">Billable</span>
            <br />
            Unbillable entries will be unlabeled.
            <br /> <br />
            Billable and unbillable time entries are all exported to QuickBooks (with their billable
            status). If you want to prevent the export of certain entries, you can use Minute7's
            capacity to 'Approve' and 'Disapprove' time entries.
        </div>
    </div>
);

export default BillableAndUnbillableTime;
