import React from 'react';

const WhatIsMobileMinute7 = () => (
    <div>
        <div className="sectionQuestion">What is Mobile Minute7? How do I use it?</div>
        <div className="sectionAnswer">
            Mobile Minute7 is a mobile application written specifically for iOS and Android devices.
            <div className="appStoreButtonsContainer">
                <a
                    href="https://itunes.apple.com/us/app/minute7/id812540141?ls=1&mt=8"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="helpAppStoreButton"
                        src="/appstore_btn.png"
                        alt="Apple AppStore"
                    />
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.minute7.mobile"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        className="helpAppStoreButton"
                        src="google_play_store_btn.png"
                        alt="Google Play Store"
                    />
                </a>
            </div>
            <br />
            After downloading and opening, you will be presented with a login screen. Login with
            your Minute7 account. That's it! You're now ready to use Minute7 on the go.
        </div>
    </div>
);

export default WhatIsMobileMinute7;
