import React from 'react';
import Icon from 'react-icons-kit';
import { arrow_left as chevronLeft } from 'react-icons-kit/ikons/arrow_left';
import { arrow_right as chevronRight } from 'react-icons-kit/ikons/arrow_right';
import { Input } from 'reactstrap';
import moment from 'moment';

const WeekEntryInput = ({
    formattedWeekdays,
    onDurationFocus = () => null,
    onDurationBlur = () => null,
    durations,
    handleTimeFieldChanged,
    handlePreviousWeekPressed,
    handleNextWeekPressed,
}) => {
    return (
        <div className="tsWeekEntryContainer">
            <div className="tsWeekChangeButtons">
                <button
                    onClick={handlePreviousWeekPressed}
                    className="tsWeekEntryChangeWeek tertiaryButton tsWeekEntryChangeWeekPrev"
                >
                    <Icon className="icon" icon={chevronLeft} size={20} />
                    Previous Week
                </button>
                <button
                    onClick={handleNextWeekPressed}
                    className="tsWeekEntryChangeWeek tertiaryButton tsWeekEntryChangeWeekNext"
                >
                    Next Week
                    <Icon className="icon" icon={chevronRight} size={20} />
                </button>
            </div>
            <table className="tsEntryWeekFields">
                <tbody>
                    <tr>
                        {formattedWeekdays.map(({ day, formattedDate }) => (
                            <th key={day}>{formattedDate}</th>
                        ))}
                    </tr>
                    <tr>
                        {formattedWeekdays.map(({ day, date }, index) => {
                            const duration = durations[moment(date).format('ddd')];
                            return (
                                <td className="tsWeekEntryInput" key={day}>
                                    <Input
                                        onFocus={onDurationFocus}
                                        onBlur={onDurationBlur}
                                        id={`duration${index}`}
                                        value={duration === '0:00' ? '' : duration}
                                        placeholder="0:00"
                                        autoComplete="off"
                                        onChange={event => handleTimeFieldChanged(date, event)}
                                    />
                                </td>
                            );
                        })}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default WeekEntryInput;
