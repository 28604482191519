import _ from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as api from '../requests/ExpensesRequests';
import * as expensesActions from '../actions/ExpensesActions';
import * as resourceActions from '../actions/BusinessResourcesActions';
import * as uiActions from '../actions/Actions';
import * as accountSelectors from '../selectors/AccountSelectors';
import * as resourceSelectors from '../selectors/BusinessResourcesSelectors';
import { getMinAndMaxDateOfEntries } from '../utils/DateUtils';
import { constructParamsObject } from '../utils/ExpenseEntryUtils.js';
import { extractEmbeddedEntities } from '../utils/HelperFunctions';
import { figureVendorId, formDataForEntry } from '../utils/ExpenseEntryUtils';
import { filterStateMatchesEntry, newFilterStateMatchingEntries } from '../utils/CommonFunctions';
import { COMPANY } from '../config/Constants';

const selectFetchParams = (state, queryParams) => {
    const params = constructParamsObject(state.expensesStore, state.accountUserStore, queryParams);

    return params;
};

const selectFilterState = ({ expensesStore: store }) => ({
    activeFilters: store.activeFilters,
    searchText: store.searchText,
    searchVendorIds: store.searchVendorIds,
    searchCustomerIds: store.searchCustomerIds,
    searchInventoryItemIds: store.searchInventoryItemIds,
    searchQbClassIds: store.searchQbClassIds,
    searchQbAccountIds: store.searchQbAccountIds,
    searchExpenseGroupingIds: store.searchExpenseGroupingIds,
    approvedFilter: store.approvedFilter,
    exportedFilter: store.exportedFilter,
    expenseTypeFilter: store.expenseTypeFilter,
    billableFilter: store.billableFilter,
    attachmentFilter: store.attachmentFilter,
    startDate: store.startDate,
    endDate: store.endDate,
});

const selectDefaultsForNewExpenseEntries = state => {
    const { expensesStore, accountStore, accountUserStore: currentUser } = state;
    const { customerId, qbAccountId, inventoryItemId, vendorId } = expensesStore;

    const canEditVendors = accountSelectors.selectCanEditVendors(state);
    const customers = resourceSelectors.selectAllowedCustomers(state);
    const qbAccounts = resourceSelectors.selectAllowedQbAccounts(state);
    const inventoryItems = resourceSelectors.selectAllowedInventoryItems(state);

    const defaults = {};

    const defaultVendorId = figureVendorId(currentUser, canEditVendors);

    const isBillableByDefault = accountStore.billable_by_default === 'Yes';

    defaults.billable = isBillableByDefault ? 'Yes' : 'No';
    let reimbursementRate = _.get(currentUser, 'reimbursement_rate');
    if (accountStore.reimbursement_rate_type === COMPANY || _.isNil(reimbursementRate)) {
        reimbursementRate = accountStore.default_reimbursement_rate;
    }

    defaults.reimbursementRate = reimbursementRate;

    if (customerId === '0') {
        defaults.customerId = _.get(customers, '[0].id', '0');
    }

    if (qbAccountId === '0') {
        defaults.qbAccountId = _.get(qbAccounts, '[0].id', '0');
    }

    if (inventoryItemId === '0') {
        defaults.inventoryItemId = _.get(inventoryItems, '[0].id', '0');
    }

    if (vendorId === '0' && defaultVendorId) {
        defaults.vendorId = defaultVendorId;
    }

    return defaults;
};

const selectModalState = ({ expensesStore }) => ({
    isNewEntryModalOpen: expensesStore.isNewEntryModalOpen,
    isEditEntryModalOpen: expensesStore.isEditEntryModalOpen,
});

export function* getExpenses({ queryParams }) {
    try {
        const params = yield select(selectFetchParams, queryParams);

        const { entries: expenses, meta } = yield call(api.getExpenses, params);

        // extract embedded entities and call the batched receive action
        // for the new resources
        const entityToStateKey = {
            Customer: 'customers',
            QbAccount: 'qbAccounts',
            InventoryItem: 'inventoryItems',
            QbClass: 'qbClasses',
        };

        const extractedEntities = extractEmbeddedEntities(Object.keys(entityToStateKey), expenses);

        const newResources = {};

        for (let entityName in extractedEntities) {
            const entities = extractedEntities[entityName];
            const stateKey = entityToStateKey[entityName];

            if (entities.length > 0) {
                newResources[stateKey] = entities;
            }
        }

        if (Object.keys(newResources).length > 0) {
            yield put(resourceActions.receiveBusinessResources(newResources));
        }

        yield put(expensesActions.receiveExpenses(expenses, meta.total));
        yield put(expensesActions.setSelectedEntryIds([]));

        if ('entry_ids' in params) {
            const minAndMaxDate = getMinAndMaxDateOfEntries(expenses);

            if ('min' in minAndMaxDate) {
                yield put(expensesActions.setStartDate(minAndMaxDate.min));
            }

            if ('max' in minAndMaxDate) {
                yield put(expensesActions.setEndDate(minAndMaxDate.max));
            }
        }
    } catch (err) {
        yield put(expensesActions.requestExpensesError(err));
        yield put(expensesActions.setSelectedEntryIds([]));
    }
}

export function* deleteExpenseEntries({ ids }) {
    try {
        const requests = ids.map(id => call(api.deleteExpenseEntry, { id }));
        yield all(requests);
        yield put(expensesActions.deleteExpenseEntriesSuccess(ids));
        yield put(expensesActions.selectExpenseEntry(null, { duplicate: false }));
        yield put(expensesActions.requestExpenses({ showLoading: false }));
    } catch (err) {
        yield put(expensesActions.deleteExpenseEntriesError(err));
        console.log(err);
    }
}

export function* setExpenseEntriesBillable({ ids, billable }) {
    try {
        const params = {
            ids: ids.join(','),
            billable_status: billable,
        };

        yield call(api.setBillable, params);
        yield put(expensesActions.setExpenseEntriesBillableSuccess(ids));
        yield put(expensesActions.requestExpenses({ showLoading: false }));
    } catch (err) {
        yield put(expensesActions.setExpenseEntriesBillableError(err));
        console.log(err);
    }
}

export function* setExpenseEntriesApproved({ ids, approved }) {
    try {
        const params = {
            ids: ids.join(','),
            approved_status: approved,
        };

        yield call(api.setApproved, params);
        yield put(expensesActions.setExpenseEntriesApprovedSuccess(ids));
        yield put(expensesActions.requestExpenses({ showLoading: false }));
    } catch (err) {
        yield put(expensesActions.setExpenseEntriesApprovedError(err));
        console.log(err);
    }
}

export function* setExpenseEntriesLocked({ ids }) {
    try {
        const params = {
            ids: ids.join(','),
            locked_status: "Yes",
        };

        yield call(api.setLocked, params);
        yield put(expensesActions.setExpenseEntriesLockedSuccess(ids));
        yield put(expensesActions.requestExpenses({ showLoading: false }));
    } catch (err) {
        yield put(expensesActions.setExpenseEntriesLockedError(err));
        console.log(err);
    }
}


export function* addExpenseEntry({ entry }) {
    try {
        yield put(expensesActions.setIsSaving(true));

        const data = formDataForEntry(entry);
        const newEntry = yield call(api.addExpenseEntry, data);

        yield put(expensesActions.addExpenseEntrySuccess({ newEntries: [newEntry] }));
        yield put(resourceActions.requestExpenseGroupingsForVendor(entry['vendor_id']));
        yield put(resourceActions.requestBusinessResources());
        yield put(expensesActions.selectExpenseEntry(null, { duplicate: false }));
        yield put(expensesActions.setIsSaving(false));

        const modalState = yield select(selectModalState);
        if (modalState.isNewEntryModalOpen) {
            yield put(expensesActions.setIsNewEntryModalOpen(false));
            yield put(expensesActions.resetExpenseForm());
        } else if (modalState.isEditEntryModalOpen) {
            yield put(expensesActions.setIsEditEntryModalOpen(false));
            yield put(expensesActions.resetExpenseForm());
        } else {
            yield put(expensesActions.resetExpenseForm());
        }

        yield put(expensesActions.requestExpenses({ showLoading: false }));
    } catch (err) {
        yield put(expensesActions.setIsSaving(false));
        yield put(expensesActions.addExpenseEntryError(err));
        console.log(err);
    }
}

export function* updateExpenseEntry({ entry }) {
    try {
        yield put(expensesActions.setIsSaving(true));

        const data = formDataForEntry(entry);
        const updatedEntry = yield call(api.editExpenseEntry, data);

        yield put(
            expensesActions.updateExpenseEntrySuccess({
                updatedEntries: [updatedEntry],
            })
        );
        yield put(expensesActions.selectExpenseEntry(null, { duplicate: false }));
        yield put(expensesActions.setIsSaving(false));

        const modalState = yield select(selectModalState);
        if (modalState.isNewEntryModalOpen) {
            yield put(expensesActions.setIsNewEntryModalOpen(false));
            yield put(expensesActions.resetExpenseForm());
        } else if (modalState.isEditEntryModalOpen) {
            yield put(expensesActions.setIsEditEntryModalOpen(false));
            yield put(expensesActions.resetExpenseForm());
        } else {
            yield put(expensesActions.resetExpenseForm());
        }

        yield put(expensesActions.requestExpenses({ showLoading: false }));
    } catch (err) {
        yield put(expensesActions.setIsSaving(false));
        yield put(expensesActions.updateExpenseEntryError(err));
        console.log(err);
    }
}

export function* duplicateExpenseEntry({ entry }) {
    try {
        yield put(expensesActions.setIsSaving(true));

        const data = formDataForEntry(entry);
        const newEntry = yield call(api.addExpenseEntry, data);

        yield put(
            expensesActions.duplicateExpenseEntrySuccess({
                newEntries: [newEntry],
            })
        );
        yield put(expensesActions.selectExpenseEntry(null, { duplicate: false }));
        yield put(expensesActions.setIsSaving(false));

        const modalState = yield select(selectModalState);
        if (modalState.isNewEntryModalOpen) {
            yield put(expensesActions.setIsNewEntryModalOpen(false));
            yield put(expensesActions.resetExpenseForm());
        } else if (modalState.isEditEntryModalOpen) {
            yield put(expensesActions.setIsEditEntryModalOpen(false));
            yield put(expensesActions.resetExpenseForm());
        } else {
            yield put(expensesActions.resetExpenseForm());
        }

        yield put(expensesActions.requestExpenses({ showLoading: false }));
    } catch (err) {
        yield put(expensesActions.setIsSaving(false));
        yield put(expensesActions.duplicateExpenseEntryError(err));
        console.log(err);
    }
}

export function* showSuccessAlertForNewEntry() {
    yield put(uiActions.showToast('Saved', 'positive'));
}

export function* showSuccessAlertForUpdatedEntry() {
    yield put(uiActions.showToast('Saved', 'positive'));
}

export function* showSuccessAlertForDuplicatedEntry() {
    yield put(uiActions.showToast('Saved', 'positive'));
}

export function* showErrorAlertForEntry({ error }) {
    let message;

    if (error && error.message) {
        message = error.message;
    } else {
        message = 'Error saving entry';
    }

    yield put(uiActions.showToast(message, 'negative'));
}

export function* setDefaultsForNewExpenseEntries() {
    const defaults = yield select(selectDefaultsForNewExpenseEntries);
    yield put(expensesActions.setDefaultsForNewExpenseEntries(defaults));
}

export function* ensureFiltersMatchNewAndUpdatedEntries({ newEntries, updatedEntries }) {
    const filterState = yield select(selectFilterState);
    const entries = [...(newEntries || []), ...(updatedEntries || [])];
    if (!entries.every(entry => filterStateMatchesEntry(filterState, entry))) {
        const newFilterState = newFilterStateMatchingEntries(
            filterState,
            updatedEntries || newEntries
        );
        yield put(expensesActions.setFilterState(newFilterState));
    }
}

export default function* root() {
    yield all([
        takeLatest(['REQUEST_EXPENSES'], getExpenses),
        takeLatest(['DELETE_EXPENSE_ENTRIES'], deleteExpenseEntries),
        takeLatest(['SET_EXPENSE_ENTRIES_BILLABLE'], setExpenseEntriesBillable),
        takeLatest(['SET_EXPENSE_ENTRIES_APPROVED'], setExpenseEntriesApproved),
        takeLatest(['SET_EXPENSE_ENTRIES_LOCKED'], setExpenseEntriesLocked),
        takeLatest(['ADD_EXPENSE_ENTRY'], addExpenseEntry),
        takeLatest(['UPDATE_EXPENSE_ENTRY'], updateExpenseEntry),
        takeLatest(['DUPLICATE_EXPENSE_ENTRY'], duplicateExpenseEntry),
        takeLatest(['ADD_EXPENSE_ENTRY_SUCCESS'], showSuccessAlertForNewEntry),
        takeLatest(['UPDATE_EXPENSE_ENTRY_SUCCESS'], showSuccessAlertForUpdatedEntry),
        takeLatest(['DUPLICATE_EXPENSE_ENTRY_SUCCESS'], showSuccessAlertForDuplicatedEntry),
        takeLatest(
            [
                'ADD_EXPENSE_ENTRY_ERROR',
                'UPDATE_EXPENSE_ENTRY_ERROR',
                'DUPLICATE_EXPENSE_ENTRY_ERROR',
            ],
            showErrorAlertForEntry
        ),
        takeLatest(['RESOURCES_LOADED'], setDefaultsForNewExpenseEntries),
        takeLatest(
            [
                'ADD_EXPENSE_ENTRY_SUCCESS',
                'UPDATE_EXPENSE_ENTRY_SUCCESS',
                'DUPLICATE_EXPENSE_ENTRY_SUCCESS',
            ],
            ensureFiltersMatchNewAndUpdatedEntries
        ),
    ]);
}
