import React from 'react';

const AddPayroll = () => (
    <div>
        <div className="sectionQuestion">
            How do I add payroll items and rates for an Employee in QuickBooks?
        </div>
        <div className="sectionAnswer">
            Each Employee in QuickBooks can have payroll items, also known as Earnings Items, and
            wage rates associated with him or her. It is essential to have these payroll items set
            up in QuickBooks in order to add time entries for an Employee who is set to "Use time
            data to create paychecks". You can set up payroll items for an Employee by doing the
            following:
            <ol>
                <li>In QuickBooks, open up the "Edit Employee" window for your employee.</li>
                <li>
                    In the drop-down select at the top of the "Edit Employee" window, choose
                    "Payroll and Compensation Info."
                </li>
                <li>
                    In the Payroll Info tab, click on the first row in the box marked "Earnings".
                    Click on the down arrow that appears in the row to select from your company's
                    available payroll items, or select <b>{'<Add New>'}</b> to add a new payroll
                    item.
                </li>
                <li>
                    Add a rate for this Employee for this payroll item under "Hourly/Annual Rate".
                </li>
                <li>Click "OK" to close and save your changes.</li>
            </ol>
        </div>
    </div>
);

export default AddPayroll;
