import React, { Component } from 'react';
import _ from 'lodash';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import {
    isValidEmail,
    isNameValid,
    nameErrorMessage,
    emailErrorMessage,
} from '../../../utils/CommonFunctions.js';
import FloatingSaveButton from '../../components/FloatingButton.js';
import Permissions from './Permissions';
import Select from '../../components/Select';
import {
    syncReminder,
    weekday_offsets,
    weekday_offsets_to_save,
    remindMe,
    minimumHours,
    minimumHoursToSave,
    accountUserFields,
    groupingOptionsForSelect,
} from '../../../config/Fields.js';
import * as expensesSelectors from '../../../selectors/ExpensesSelectors';
import { offsetFromMonday } from '../../../utils/DateUtils.js';
import { selectEmployee, selectVendor } from '../../../actions/Actions.js';
import { showToast } from '../../../actions/Actions.js';
import { addUser } from '../../../actions/AccountUserActions';
import * as resourceSelectors from '../../../selectors/BusinessResourcesSelectors';
import * as accountSelectors from '../../../selectors/AccountSelectors';
import PhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'libphonenumber-js';
import 'react-phone-input-2/lib/style.css';
import ReactTooltip from 'react-tooltip';
import Icon from 'react-icons-kit';
import { informationCircled as info } from 'react-icons-kit/ionicons/informationCircled';

class CreateAccountUser extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState();
    }

    componentWillReceiveProps(props) {
        if (props.isSaving !== this.props.isSaving && !props.isSaving) {
            this.props.history.push('/account_settings');
        }
    }

    componentWillUnmount() {
        this.props.selectVendor(null);
        this.props.selectEmployee(null);
    }

    defaultState = () => {
        const { vendor, employee, accountId, no_integration } = this.props;
        let edit_enabled = false;
        if (
            !_.isEmpty(employee) &&
            !_.isEmpty(employee.email) &&
            !_.isEmpty(employee.first_name) &&
            !_.isEmpty(employee.last_name)
        ) {
            edit_enabled = true;
        }
        return {
            account_id: accountId,
            employee_id: employee ? employee.id : '0',
            expense_vendor_id: vendor ? vendor.id : '0',
            email: employee
                ? employee.email
                    ? employee.email
                    : ''
                : vendor
                ? vendor.email
                    ? vendor.email
                    : ''
                : '',
            first_name: employee ? employee.first_name : vendor ? vendor.first_name : '',
            middle_name: '',
            last_name: employee ? employee.last_name : vendor ? vendor.last_name : '',
            phone: '',
            type: '',
            validation_msg: '',
            week_start_offset: 0,
            timesheet_grouping: 'week',
            time_entry_reminder_frequency: 'Never',
            time_entry_reminder_minimum_hours: 0,
            export_reminder_interval: 'Never',
            permission_group_id: '0',
            approve_others_expenses: 'No',
            approve_others_time: 'No',
            edit_others_expenses: 'No',
            edit_others_time: 'No',
            edit_own_expenses: 'No',
            edit_own_time: 'No',
            edit_payroll: 'No',
            manage_payroll_payrates: 'No',
            manage_item_billrates: 'No',
            export_time: 'No',
            manage_account: 'No',
            manage_users: 'No',
            permission_group_manage_users: 'No',
            manage_users_override: 'No',
            manage_api: 'No',
            view_others_expenses: 'No',
            view_others_time: 'No',
            view_reports: 'No',
            clock_in_only: 'No',
            filter_view_employee_ids: [],
            filter_edit_employee_ids: [],
            filter_approve_employee_ids: [],
            filter_view_expense_vendor_ids: [],
            filter_edit_expense_vendor_ids: [],
            filter_approve_expense_vendor_ids: [],
            filter_customer_list: 'No',
            filter_customer_ids: [],
            filter_inventory_item_list: 'No',
            filter_inventory_item_ids: [],
            filter_qb_account_list: 'No',
            filter_qb_account_ids: [],
            filter_qb_class_list: 'No',
            filter_qb_class_ids: [],
            approve_others_expenses_override_group: 'No',
            approve_others_time_override_group: 'No',
            edit_others_expenses_override_group: 'No',
            edit_others_time_override_group: 'No',
            edit_own_expenses_override_group: 'No',
            edit_own_time_override_group: 'No',
            edit_payroll_override_group: 'No',
            manage_payroll_payrates_override_group: 'No',
            manage_item_billrates_override_group: 'No',
            export_time_override_group: 'No',
            filter_approve_employee_ids_override_group: 'No',
            filter_approve_expense_vendor_ids_override_group: 'No',
            filter_customer_ids_override_group: 'No',
            filter_customer_list_override_group: 'No',
            filter_edit_employee_ids_override_group: 'No',
            filter_edit_expense_vendor_ids_override_group: 'No',
            filter_inventory_item_ids_override_group: 'No',
            filter_inventory_item_list_override_group: 'No',
            filter_qb_account_ids_override_group: 'No',
            filter_qb_account_list_override_group: 'No',
            filter_qb_class_ids_override_group: 'No',
            filter_qb_class_list_override_group: 'No',
            filter_view_employee_ids_override_group: 'No',
            filter_view_expense_vendor_ids_override_group: 'No',
            manage_account_override_group: 'No',
            manage_expenses_override_group: 'No',
            manage_time_override_group: 'No',
            manage_users_override_group: 'No',
            manage_api_override_group: 'No',
            restrictions_override_group: 'No',
            view_others_expenses_override_group: 'No',
            view_others_time_override_group: 'No',
            view_reports_override_group: 'No',
            hasBeenEdited: edit_enabled,
            send_invite_sms: false,
            no_integration: no_integration,
            disable_assosiated_fields: (employee || vendor) && no_integration ? true : false,
        };
    };

    selectPermissionsGroup = id => {
        if (id === '0') {
            this.setState(this.defaultState());
            return;
        }

        const { permissionsGroups } = this.props;

        const permissions = permissionsGroups.find(group => group.id === id);

        this.setState({
            hasBeenEdited: true,
            permission_group_id: id,
            approve_others_expenses: permissions.approve_others_expenses,
            approve_others_time: permissions.approve_others_time,
            edit_others_expenses: permissions.edit_others_expenses,
            edit_others_time: permissions.edit_others_time,
            edit_own_expenses: permissions.edit_own_expenses,
            edit_own_time: permissions.edit_own_time,
            edit_payroll: permissions.edit_payroll,
            manage_payroll_payrates: permissions.manage_payroll_payrates,
            manage_item_billrates: permissions.manage_item_billrates,
            export_time: permissions.export_time,
            manage_account: permissions.manage_account,
            permission_group_manage_users: permissions.manage_users,
            view_others_expenses: permissions.view_others_expenses,
            view_others_time: permissions.view_others_time,
            view_reports: permissions.view_reports,
            clock_in_only: permissions.clock_in_only || 'No',

            filter_view_employee_ids: permissions
                ? permissions.filter_view_employee_ids.split(',')
                : [],
            filter_edit_employee_ids: permissions
                ? permissions.filter_edit_employee_ids.split(',')
                : [],
            filter_approve_employee_ids: permissions
                ? permissions.filter_approve_employee_ids.split(',')
                : [],
            filter_view_expense_vendor_ids: permissions
                ? permissions.filter_view_expense_vendor_ids.split(',')
                : [],
            filter_edit_expense_vendor_ids: permissions
                ? permissions.filter_edit_expense_vendor_ids.split(',')
                : [],
            filter_approve_expense_vendor_ids: permissions
                ? permissions.filter_approve_expense_vendor_ids.split(',')
                : [],
            filter_customer_list: permissions ? permissions.filter_customer_list : 'No',
            filter_customer_ids: permissions ? permissions.filter_customer_ids.split(',') : [],
            filter_inventory_item_list: permissions ? permissions.filter_inventory_item_list : 'No',
            filter_inventory_item_ids: permissions
                ? permissions.filter_inventory_item_ids.split(',')
                : [],
            filter_qb_account_list: permissions ? permissions.filter_qb_account_list : 'No',
            filter_qb_account_ids: permissions ? permissions.filter_qb_account_ids.split(',') : [],
            filter_qb_class_list: permissions ? permissions.filter_qb_class_list : 'No',
            filter_qb_class_ids: permissions ? permissions.filter_qb_class_ids.split(',') : [],
            approve_others_expenses_override_group: 'No',
            approve_others_time_override_group: 'No',
            edit_others_expenses_override_group: 'No',
            edit_others_time_override_group: 'No',
            edit_own_expenses_override_group: 'No',
            edit_own_time_override_group: 'No',
            edit_payroll_override_group: 'No',
            manage_payroll_payrates_override_group: 'No',
            manage_item_billrates_override_group: 'No',
            export_time_override_group: 'No',
            filter_approve_employee_ids_override_group: 'No',
            filter_approve_expense_vendor_ids_override_group: 'No',
            filter_customer_ids_override_group: 'No',
            filter_customer_list_override_group: 'No',
            filter_edit_employee_ids_override_group: 'No',
            filter_edit_expense_vendor_ids_override_group: 'No',
            filter_inventory_item_ids_override_group: 'No',
            filter_inventory_item_list_override_group: 'No',
            filter_qb_account_ids_override_group: 'No',
            filter_qb_account_list_override_group: 'No',
            filter_qb_class_ids_override_group: 'No',
            filter_qb_class_list_override_group: 'No',
            filter_view_employee_ids_override_group: 'No',
            filter_view_expense_vendor_ids_override_group: 'No',
            manage_account_override_group: 'No',
            manage_expenses_override_group: 'No',
            manage_time_override_group: 'No',
            manage_users_override_group: 'No',
            manage_api_override_group: 'No',
            restrictions_override_group: 'No',
            view_others_expenses_override_group: 'No',
            view_others_time_override_group: 'No',
            view_reports_override_group: 'No',
            
        });
    };

    constructNewAccountUser = () => {
        const data = {};
        accountUserFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else if (field === 'email') {
                    data[field] = this.state[field].trim();
                } else if (field === 'phone') {
                    const { phone } = this.state;
                    data[field] = phone !== '' ? '+' + phone : phone;
                } else {
                    data[field] = this.state[field];
                }
            }
        });
        return data;
    };

    isPhoneNumberValid = () => {
        const { validation_msg, phone, send_invite_sms } = this.state;
        if (validation_msg !== '' && phone !== '') {
            return false;
        }
        if (send_invite_sms && (validation_msg !== '' || phone === '')) {
            return false;
        }

        return true;
    };

    checkRequiredFields = () => {
        const { email, first_name, last_name } = this.state;

        if (
            !isValidEmail(email) ||
            !isNameValid(first_name) ||
            !isNameValid(last_name) ||
            !this.isPhoneNumberValid
        ) {
            this.props.showToast('Make sure all fields are filled out correctly', 'warning');
            return false;
        }
        return true;
    };

    createAccountUser = () => {
        if (!this.checkRequiredFields()) {
            return;
        }

        const data = this.constructNewAccountUser();
        this.props.addUser(data);
    };

    setFields = fields => {
        this.setState({ ...fields, hasBeenEdited: true });
    };

    handlePhoneInput = () => {
        isValidPhoneNumber('+' + this.state.phone)
            ? this.setFields({
                  validation_msg: '',
              })
            : this.setFields({
                  validation_msg: 'Enter a valid phone number (e.g. 012-345-6789)',
              });
    };
    handleEmployeeAssosiation = employee_id => {
        this.setFields({ employee_id: employee_id });
    };

    render() {
        const {
            permissionsGroups,
            customers,
            inventoryItems,
            qbClasses,
            employees,
            vendors,
            allowedQbAccounts,
            allowedQbPaymentAccounts,
        } = this.props;

        const {
            timesheet_grouping,
            phone,
            validation_msg,
            send_invite_sms,
            first_name,
            last_name,
            middle_name,
            email,
            disable_assosiated_fields,
            no_integration,
        } = this.state;

        const permissionsProps = {
            permissionsGroupId: this.state.permission_group_id,
            manageUsers: this.state.permission_group_id !=='0'?this.state.permission_group_manage_users:this.state.manage_users,
            manageApi: this.state.manage_api,
            manageUsersOverrideGroup: this.state.manage_users_override_group,
            manageApiOverrideGroup: this.state.manage_api_override_group,
            customers,
            inventoryItems,
            qbClasses,
            employees,
            vendors,
            allowedQbAccounts,
            allowedQbPaymentAccounts,
            exportTime: this.state.export_time,
            exportTimeOverrideGroup: this.state.export_time_override_group,
            viewReports: this.state.view_reports,
            viewReportsOverrideGroup: this.state.view_reports_override_group,
            clockInOnly: this.state.clock_in_only,
            editPayroll: this.state.edit_payroll,
            managePayrollPayrates: this.state.manage_payroll_payrates,
            manageItemBillrates: this.state.manage_item_billrates,
            editPayrollOverrideGroup: this.state.edit_payroll_override_group,
            managePayrollPayratesOverrideGroup: this.state.manage_payroll_payrates_override_group,
            manageItemBillratesOverrideGroup: this.state.manage_item_billrates_override_group,
            manageTimeOverrideGroup: this.state.manage_time_override_group,
            viewOthersTime: this.state.view_others_time,
            filterViewEmployeeIds: this.state.filter_view_employee_ids,
            editOthersTime: this.state.edit_others_time,
            filterEditEmployeeIds: this.state.filter_edit_employee_ids,
            approveOthersTime: this.state.approve_others_time,
            filterApproveEmployeeIds: this.state.filter_approve_employee_ids,
            manageExpensesOverrideGroup: this.state.manage_expenses_override_group,
            viewOthersExpenses: this.state.view_others_expenses,
            filterViewExpenseVendorIds: this.state.filter_view_expense_vendor_ids,
            editOthersExpenses: this.state.edit_others_expenses,
            filterEditExpenseVendorIds: this.state.filter_edit_expense_vendor_ids,
            approveOthersExpenses: this.state.approve_others_expenses,
            filterApproveExpenseVendorIds: this.state.filter_approve_expense_vendor_ids,
            filterCustomerList: this.state.filter_customer_list,
            filterCustomerIds: this.state.filter_customer_ids,
            filterCustomerIdsOverrideGroup: this.state.filter_customer_ids_override_group,
            filterInventoryItemList: this.state.filter_inventory_item_list,
            filterInventoryItemIds: this.state.filter_inventory_item_ids,
            filterInventoryItemIdsOverrideGroup: this.state
                .filter_inventory_item_ids_override_group,
            filterQbAccountList: this.state.filter_qb_account_list,
            filterQbAccountIds: this.state.filter_qb_account_ids,
            filterQbAccountIdsOverrideGroup: this.state.filter_qb_account_ids_override_group,
            filterQbClassList: this.state.filter_qb_class_list,
            filterQbClassIds: this.state.filter_qb_class_ids,
            filterQbClassIdsOverrideGroup: this.state.filter_qb_class_ids_override_group,
            no_integration: this.state.no_integration,
            expenseEntityType: 'Vendors',
            onChange: this.setFields,
        };

        return (
            <div className="applicationBody">
                <FloatingSaveButton
                    buttonText={'Save + Send Invite'}
                    hasBeenEdited={this.state.hasBeenEdited}
                    onSave={this.createAccountUser}
                />
                <div className="bodyHeader">
                    <h2 ref={ref => (this.scrollToMe = ref)}>Add a New Minute7 User Account</h2>
                    <div className="headerActions">
                        <button
                            className="tertiaryButton"
                            onClick={() => this.props.history.push('/account_settings')}
                        >
                            Back to Account Settings
                        </button>
                    </div>
                </div>
                {!no_integration && (
                    <div>
                        <h3 className="cardHeading">QuickBooks Settings</h3>
                        <div className="cardContainer ausSectionContainer">
                            <div className="settingsLabelAndInputContainer">
                                <div className="settingsInputLabel">
                                    QuickBooks Employee or Vendor for Time Tracking
                                </div>
                                <div className="settingsInput">
                                    <Select
                                        withSearch
                                        withClearOption
                                        clearOptionLabel="No Employee Selected"
                                        options={employees}
                                        labelWhenNothingSelected="No Employee Selected"
                                        value={this.state.employee_id}
                                        onChange={id =>
                                            // this.setFields({
                                            //     employee_id: id,
                                            // })
                                            this.handleEmployeeAssosiation(id)
                                        }
                                    />
                                </div>
                                <div className="settingsTip">
                                    Simplifies time tracking by automatically selecting this
                                    employee for time entries
                                </div>
                            </div>
                            <div className="settingsLabelAndInputContainer">
                                <div className="settingsInputLabel">
                                    QuickBooks Vendor for Expense Tracking
                                </div>
                                <div className="settingsInput">
                                    <Select
                                        withSearch
                                        withClearOption
                                        clearOptionLabel="No Vendor Selected"
                                        options={vendors}
                                        labelWhenNothingSelected="No Vendor Selected"
                                        value={this.state.expense_vendor_id}
                                        onChange={id =>
                                            this.setFields({
                                                expense_vendor_id: id,
                                            })
                                        }
                                    />
                                </div>
                                <div className="settingsTip">
                                    Required for mobile expense tracking. For web-based expense
                                    tracking, required if permissions for this account limit it to
                                    editing only its own expenses
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 className="cardHeading" style={{ marginRight: '10px', width: '7%' }}>
                            Contact Info
                        </h3>
                        {disable_assosiated_fields && (
                            <div>
                                <ReactTooltip
                                    className="tooltip-override"
                                    data-for="first_name"
                                    type="info"
                                    multiline={true}
                                    data-html={true}
                                />
                                <Icon
                                    className="blueIcon"
                                    size={16}
                                    data-tip="If you wish to update the first name, last name, or email of this user, please update the corresponding employee details in the resource section"
                                    icon={info}
                                    id="first_name"
                                />
                            </div>
                        )}
                    </div>
                    <div className="cardContainer ausSectionContainer">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                Email
                                <div className="settingsRequiredLabel">Required</div>
                            </div>
                            <div className="settingsInput">
                                <Input
                                    disabled={disable_assosiated_fields}
                                    className={
                                        email !== '' && !isValidEmail(email)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={email}
                                    type="email"
                                    onChange={newEmail =>
                                        this.setFields({
                                            email: newEmail.target.value,
                                        })
                                    }
                                />
                            </div>
                            {email !== '' && !isValidEmail(email) && (
                                <lable className="ausInvalidInputTip">
                                    {emailErrorMessage(email)}
                                    Email must be between to 4 to 50 characters (e.g.
                                    example@minute7.com)
                                </lable>
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                First Name
                                <div className="settingsRequiredLabel">Required</div>
                            </div>
                            <div className="settingsInput">
                                <Input
                                    disabled={disable_assosiated_fields}
                                    className={
                                        first_name !== '' &&
                                        first_name !== null &&
                                        !isNameValid(first_name)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={first_name}
                                    onChange={newFirstName =>
                                        this.setFields({
                                            first_name: newFirstName.target.value,
                                        })
                                    }
                                />
                            </div>
                            {first_name !== '' &&
                            first_name !== null &&
                            !isNameValid(first_name) ? (
                                <lable className="error-message">
                                    {nameErrorMessage(first_name)}
                                    You can only use alphabets and (- . ') between 1 to 50.
                                </lable>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Middle Name</div>
                            <div className="settingsInput">
                                <Input
                                    disabled={disable_assosiated_fields}
                                    className={
                                        middle_name !== '' &&
                                        middle_name !== null &&
                                        !isNameValid(middle_name)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={this.state.middle_name}
                                    onChange={newMiddleName =>
                                        this.setFields({
                                            middle_name: newMiddleName.target.value,
                                        })
                                    }
                                />
                            </div>
                            {middle_name !== '' &&
                            middle_name !== null &&
                            !isNameValid(middle_name) ? (
                                <lable className="error-message">
                                    {nameErrorMessage(middle_name)}
                                    You can only use alphabets and (- . ') between 1 to 50.
                                </lable>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                Last Name
                                <div className="settingsRequiredLabel">Required</div>
                            </div>
                            <div className="settingsInput">
                                <Input
                                    disabled={disable_assosiated_fields}
                                    className={
                                        last_name !== '' &&
                                        last_name !== null &&
                                        !isNameValid(last_name)
                                            ? 'ausInvalidInput'
                                            : ''
                                    }
                                    value={last_name}
                                    onChange={newLastName =>
                                        this.setFields({
                                            last_name: newLastName.target.value,
                                        })
                                    }
                                />
                            </div>
                            {last_name !== '' && last_name !== null && !isNameValid(last_name) ? (
                                <lable className="error-message">
                                    {nameErrorMessage(last_name)}
                                    You can only use alphabets and (- . ') between 1 to 50.
                                </lable>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Phone</div>
                            <div className="settingsInput">
                                <PhoneInput
                                    containerClass="phone-input"
                                    country={'us'}
                                    value={phone}
                                    onChange={new_phone =>
                                        this.setFields({
                                            phone: new_phone,
                                            validation_msg: '',
                                        })
                                    }
                                    onBlur={this.handlePhoneInput}
                                />
                            </div>
                            {phone === '' || validation_msg === '' ? (
                                <div className="settingsInput">
                                    <input
                                        name="sms_invite"
                                        type="checkbox"
                                        className="checkbox"
                                        checked={send_invite_sms}
                                        onChange={() =>
                                            this.setFields({ send_invite_sms: !send_invite_sms })
                                        }
                                    />
                                    <label htmlFor="sms_invite">
                                        Also send invitation as Text Message
                                    </label>
                                </div>
                            ) : (
                                <label className="error-message">{validation_msg}</label>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className="cardHeading">Display Preferences</h3>
                    <div className="cardContainer ausSectionContainer">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Start Week On</div>
                            <div className="settingsInput">
                                <Select
                                    value={
                                        Object.keys(weekday_offsets)[
                                            offsetFromMonday(this.state.week_start_offset)
                                        ]
                                    }
                                    options={Object.keys(weekday_offsets)}
                                    labelForOption={option => option}
                                    valueForOption={option => option}
                                    onChange={value =>
                                        this.setFields({
                                            week_start_offset: weekday_offsets_to_save[value],
                                        })
                                    }
                                />
                            </div>
                            <div className="ausItemTip">For Timesheets and Expense views</div>
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Default Time Entry Grouping</div>
                            <div className="settingsInput">
                                <Select
                                    value={timesheet_grouping}
                                    options={groupingOptionsForSelect}
                                    onChange={value =>
                                        this.setState({
                                            timesheet_grouping: value,
                                            hasBeenEdited: true,
                                        })
                                    }
                                />
                            </div>
                            <div className="settingsTip">
                                You can toggle between grouping options without changing the default
                                by using the “More” menu above the time entries table.
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className="cardHeading">Reminders</h3>
                    <p>
                        Note: reminders apply to all weekdays. If your company has specific
                        holidays, you may simply disregard the email reminder.
                    </p>

                    <div className="cardContainer ausSectionContainer">
                        <div className="ausReminderContainer">
                            <div className="ausReminderText">Remind Me</div>
                            <div className="ausReminderDropdownContainer">
                                <Select
                                    value={this.state.time_entry_reminder_frequency}
                                    options={remindMe}
                                    labelForOption={option => option}
                                    valueForOption={option => option}
                                    onChange={value =>
                                        this.setFields({
                                            time_entry_reminder_frequency: value,
                                        })
                                    }
                                />
                            </div>
                            <div className="ausReminderText">if I have not entered</div>
                            <div className="ausReminderDropdownContainer">
                                <Select
                                    value={
                                        Object.keys(minimumHoursToSave)[
                                            this.state.time_entry_reminder_minimum_hours
                                        ]
                                    }
                                    options={minimumHours}
                                    labelForOption={option => option}
                                    valueForOption={option => option}
                                    onChange={value =>
                                        this.setFields({
                                            time_entry_reminder_minimum_hours:
                                                minimumHoursToSave[value],
                                        })
                                    }
                                />
                            </div>
                            <div className="ausReminderText">on any given weekday</div>
                        </div>
                        <div className="ausReminderContainer ausExportReminder">
                            <div className="ausReminderText">Remind me to sync with QuickBooks</div>
                            <div className="ausReminderDropdownContainer">
                                <Select
                                    value={this.state.export_reminder_interval}
                                    options={syncReminder}
                                    labelForOption={option => option}
                                    valueForOption={option => option}
                                    onChange={value =>
                                        this.setFields({
                                            export_reminder_interval: value,
                                        })
                                    }
                                />
                            </div>
                            <div className="ausReminderText">after the last sync</div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className="cardHeading">Group</h3>
                    <div className="cardContainer settingsSection">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Permissions Group Name</div>
                            <div className="settingsInput">
                                <Select
                                    options={permissionsGroups}
                                    labelWhenNothingSelected="No Permissions Group Selected"
                                    value={this.state.permission_group_id}
                                    onChange={id => this.selectPermissionsGroup(id)}
                                    withClearOption
                                    clearOptionLabel="No Permissions Group Selected"
                                />
                            </div>
                            <div className="settingsTip">
                                You can add a new permissions group to manage permissions for many
                                users at once
                            </div>
                        </div>
                    </div>
                </div>

                <Permissions {...permissionsProps} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const employees = accountSelectors.selectCanEditActiveEmployeesNoAssosiation(state);
    const vendors = accountSelectors.selectCanEditActiveVendors(state);

    const {
        appStore: { selectedEmployeeId, selectedVendorId },
    } = state;

    const employee = selectedEmployeeId ? employees.find(e => e.id === selectedEmployeeId) : null;
    const vendor = selectedVendorId ? vendors.find(v => v.id === selectedVendorId) : null;
    const no_integration = state.accountStore.qb_type === 'None' ? true : false;
    return {
        expenseTransactionType: state.accountStore.expense_transaction_type,
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        permissionsGroups: state.businessResourcesStore.permissionsGroups,
        customers: resourceSelectors.selectAllCustomers(state),
        inventoryItems: resourceSelectors.selectAllInventoryItems(state),
        qbClasses: resourceSelectors.selectAllQbClasses(state),
        // qbAccounts: resourceSelectors.selectAllQbAccounts(state),
        allowedQbAccounts: expensesSelectors.selectAllowedQbAccountsForExpenses(state),
        allowedQbPaymentAccounts: expensesSelectors.selectAllowedQbPaymentAccountsForExpenses(
            state
        ),
        employees,
        vendors,
        employee,
        vendor,
        isSaving: state.accountUserStore.isSaving,
        no_integration,
    };
}

const mapDispatchToProps = {
    showToast,
    selectEmployee,
    selectVendor,
    addUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateAccountUser);
