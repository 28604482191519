import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { parseUrlVariables } from '../../utils/CommonFunctions.js';
import { login, logout } from '../../actions/Actions.js';
import { SiteLoading } from '../../views/components/SiteLoading.js';
import GoogleAnalytics from '../../utils/GoogleAnalytics.js';

class OpenId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToHome: false,
        };
    }

    componentWillMount() {
        const search = this.props.history.location.search;
        if (search !== '') {
            let parsedVariables = parseUrlVariables(this.props.history);
            if ('email' in parsedVariables && 'password' in parsedVariables) {
                if ('new_account' in parsedVariables) {
                    if (parsedVariables.new_account === 'true') {
                        GoogleAnalytics.openId('New Account');
                    } else if (parsedVariables.new_account === 'false') {
                        GoogleAnalytics.openId('Login');
                    }
                }

                this.props.login({
                    email: parsedVariables.email,
                    password: parsedVariables.password,
                });
            }
        } else {
            this.props.history.push('/home');
        }
    }

    componentWillReceiveProps(props) {
        // resources have now loaded, proceed with login
        if (props.isLoggedIn && props.resourcesLoaded && !this.props.resourcesLoaded) {
            this.setState({
                redirectToHome: true,
            });
        }
    }

    render() {
        const { redirectToHome } = this.state;

        if (redirectToHome === true) {
            return <Redirect to="/" />;
        }

        return (
            <div className="openIdContainer">
                <SiteLoading />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: !_.isNil(state.appStore.session_id),
        resourcesLoaded: state.businessResourcesStore.resourcesLoaded,
    };
}

const mapDispatchToState = {
    login,
    logout,
};

export default connect(
    mapStateToProps,
    mapDispatchToState
)(OpenId);
