import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { Input } from 'reactstrap';
import { phone as phoneIcon } from 'react-icons-kit/fa/phone';
import { envelope } from 'react-icons-kit/fa/envelope';
import {
    isCompanyNameValid,
    isNameValid,
    isValidEmail,
    isValidPhone,
} from '../../../utils/CommonFunctions.js';
import { Col, Row } from 'react-bootstrap';
import '../../../css/ContactUs.css';
import ContactUsAttachment from './ContactUsAttachment';
import SaveLoading from '../../components/SaveLoading.js';
import PhoneInput from 'react-phone-input-2';
export default class ContactUsDefault extends Component {

    state = {
        fNameInvalidMsg: '',
        lNameInvalidMsg: '',
        emailInvalidMsg: '',
        phoneInvalidMsg: '',
        cNameInvalidMsg: '',
    };
    setFields = fields => {
        this.setState({ ...fields });
    };

    render() {
        const {
            message,
            attachments,
            isSendingMessage,
            subject,
            first_name,
            last_name,
            email,
            name,
            phone,
        } = this.props;

        return (
            <div className="contactUsContainer cardContainer">
                <div className="contactUsForm">
                    <Row>
                        <div className="logoContainer">
                            <img
                                style={{ width: 120, height: 35 }}
                                srcSet="/img/sales/logo@2x-dark.png "
                                src="/img/sales/logo@2x-dark.png"
                                alt="Minute7"
                            />
                        </div>
                    </Row>
                    {/* <Row className='contactUsRowAlightCenter'>
                        <div className="contactUsHeaderText">
                            Connect with us
                        </div>
                    </Row> */}
                    <Row className="contactUsRowAlightCenter">
                        <div className="contactUsSubHeader ">
                            We will be happy to answer your questions.
                        </div>
                    </Row>
                    <Row className="contactUsRowAlightCenter">
                        <div className="contactUsSubHeader2 ">
                            Fill out the form and we will get back to you.
                        </div>
                    </Row>
                    <Row style={{ marginBottom: '15px', marginTop: '15px' }}>
                        <Col sm={6} style={{ marginBottom: '5px' }}>
                            <a href="tel:+18587369366">
                                <div className="contactUsIconContainer">
                                    <Icon className="contactUsIcon" size={22} icon={phoneIcon} />
                                    (858) 736-9366
                                </div>
                            </a>
                        </Col>
                        <Col sm={6} style={{ marginBottom: '5px' }}>
                            <a href="mailto:support@minute7.com?Subject=Minute7%20Support">
                                <div className="contactUsIconContainer">
                                    <Icon className="contactUsIcon" size={22} icon={envelope} />
                                    support@minute7.com
                                </div>
                            </a>
                        </Col>
                    </Row>
                    <Row className="contactUsRow">
                        <Col sm={6} style={{ marginBottom: '5px' }}>
                            <div className="contactUsLabelAndInput">
                                <Row>
                                    <div className="contactUsLabel largeBodyText greyText">
                                        First Name
                                    </div>
                                    <div className="required">*</div>
                                </Row>

                                <div>
                                    <Input
                                        className={
                                            this.state.fNameInvalidMsg === ''
                                                ? ''
                                                : 'ausInvalidInput'
                                        }
                                        name="first_name"
                                        value={first_name}
                                        onChange={this.props.handleFirstNameOnChange}
                                        onBlur={
                                            first_name.length !== 0
                                                ? () =>
                                                      isNameValid(first_name)
                                                          ? this.setFields({ fNameInvalidMsg: '' })
                                                          : this.setFields({
                                                                fNameInvalidMsg:
                                                                    'First name contains only  1 to 50 alphabets.',
                                                            })
                                                : ''
                                        }
                                    />
                                    <div className="ftInputError">
                                        {!this.state.fNameInvalidMsg === '' &&
                                        !first_name === '' ? (
                                            <label />
                                        ) : (
                                            <label className="error-message">
                                                {this.state.fNameInvalidMsg}
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} style={{ marginBottom: '5px' }}>
                            <div className="contactUsLabelAndInput">
                                <Row>
                                    <div className="contactUsLabel largeBodyText greyText">
                                        Last Name
                                    </div>
                                    <div className="required">*</div>
                                </Row>
                                <div>
                                    <Input
                                        className={
                                            this.state.lNameInvalidMsg === ''
                                                ? ''
                                                : 'ausInvalidInput'
                                        }
                                        name="last_name"
                                        value={last_name}
                                        onChange={this.props.handleLastNameOnChange}
                                        onBlur={
                                            last_name.length !== 0
                                                ? () =>
                                                      isNameValid(last_name)
                                                          ? this.setFields({ lNameInvalidMsg: '' })
                                                          : this.setFields({
                                                                lNameInvalidMsg:
                                                                    'Last name contains only  1 to 50 alphabets.',
                                                            })
                                                : ''
                                        }
                                    />
                                    <div className="ftInputError">
                                        {!this.state.lNameInvalidMsg === '' && !last_name === '' ? (
                                            <label />
                                        ) : (
                                            <label className="error-message">
                                                {this.state.lNameInvalidMsg}
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="contactUsRow">
                        <Col sm={12} style={{ marginBottom: '5px' }}>
                            <div className="contactUsLabelAndInput">
                                <Row>
                                    <div className="contactUsLabel largeBodyText greyText">
                                        Email
                                    </div>
                                    <div className="required">*</div>
                                </Row>

                                <div>
                                    <Input
                                        className={
                                            this.state.emailInvalidMsg === ''
                                                ? ''
                                                : 'ausInvalidInput'
                                        }
                                        name="email"
                                        value={email}
                                        onChange={this.props.handleEmailOnChange}
                                        onBlur={
                                            email.length !== 0
                                                ? () =>
                                                      isValidEmail(email)
                                                          ? this.setFields({ emailInvalidMsg: '' })
                                                          : this.setFields({
                                                                emailInvalidMsg:
                                                                    'Email must be between to 4 to 40 characters (e.g. example@minute7.com)',
                                                            })
                                                : ''
                                        }
                                    />
                                    <div className="ftInputError">
                                        {!this.state.emailInvalidMsg === '' && !email === '' ? (
                                            <label />
                                        ) : (
                                            <label className="error-message">
                                                {this.state.emailInvalidMsg}
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="contactUsRow">
                        <Col sm={12} style={{ marginBottom: '5px' }}>
                            <div className="contactUsLabelAndInput">
                                <Row>
                                    <div className="contactUsLabel largeBodyText greyText">
                                        Company Name
                                    </div>
                                    <div className="required">*</div>
                                </Row>
                                <div>
                                    <Input
                                        className={
                                            this.state.cNameInvalidMsg !== '' && name !== ''
                                                ? 'ausInvalidInput'
                                                : ''
                                        }
                                        name="company_name"
                                        value={name}
                                        onChange={this.props.handleNameOnChange}
                                        onBlur={
                                            name.length !== 0
                                                ? () =>
                                                      isCompanyNameValid(name)
                                                          ? this.setFields({ cNameInvalidMsg: '' })
                                                          : this.setFields({
                                                                cNameInvalidMsg:
                                                                    "Only Alphanumeric and some Special  Symbols (' - & . , _) allowed between 3 to 100 characters",
                                                            })
                                                : ''
                                        }
                                    />
                                    <div className="ftInputError">
                                        {this.state.cNameInvalidMsg !== '' || name === '' ? (
                                            <label className="error-message">
                                                {this.state.cNameInvalidMsg}
                                            </label>
                                        ) : (
                                            <label />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="contactUsRow">
                        <Col sm={12} style={{ marginBottom: '5px' }}>
                            <div className="contactUsLabelAndInput">
                                <Row>
                                    <div className="contactUsLabel largeBodyText greyText">
                                        {' '}
                                        Phone Number
                                    </div>
                                    <div className="required">*</div>
                                </Row>

                                <div>
                                    <PhoneInput
                                        className={
                                            this.state.phoneInvalidMsg === ''
                                                ? ''
                                                : 'ausInvalidInput'
                                        }
                                        name="phone_number"
                                        placeholder="###-###-####"
                                        containerClass="phone-input"
                                        country={'us'}
                                        value={phone}
                                        onChange={this.props.handlePhoneOnChange}
                                        onBlur={
                                            phone.length !== 0
                                                ? () =>
                                                      isValidPhone(phone)
                                                          ? this.setFields({ phoneInvalidMsg: '' })
                                                          : this.setFields({
                                                                phoneInvalidMsg:
                                                                    'Enter a valid phone number (e.g. 012-345-6789)',
                                                            })
                                                : ''
                                        }
                                    />
                                    <div className="ftInputError">
                                        {!this.state.phoneInvalidMsg === '' && !phone === '' ? (
                                            <label />
                                        ) : (
                                            <label className="error-message">
                                                {this.state.phoneInvalidMsg}
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Input
                            placeholder="Message..."
                            className="contactUsMessage"
                            value={message}
                            onChange={this.props.handleMessageChanged}
                            type="textarea"
                        />
                    </Row>
                    <Row>
                        <ContactUsAttachment
                            attachments={attachments}
                            handleFilesUploaded={this.props.handleFilesUploaded}
                            handleRemoveAttachmentPressed={this.props.handleRemoveAttachmentPressed}
                        />
                    </Row>
                    <Row>
                        <div className="contactUsLabelAndInput">
                            {isSendingMessage ? (
                                <div className="lButton">
                                    <SaveLoading />
                                </div>
                            ) : (
                                <button
                                    className="contactUsSendButton primaryButton"
                                    onClick={() => this.props.sendMessage()}
                                >
                                    Send Message
                                </button>
                            )}
                        </div>
                    </Row>
                </div>
            </div>
        );
    }
}
