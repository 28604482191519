import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    updateExistingPayrollItem,
    createNewPayrollItem,
    getAllPayrollItemsForTable,
    activatePayrollItem,
    deactivatePayrollItem,
} from '../requests/PayrollItemRequests';
import * as uiActions from '../actions/Actions';
import * as payrollItemActions from '../actions/PayrollItemAction';
import _ from 'lodash';
import { payrollItemFields } from '../config/Fields';
import * as resourceActions from '../actions/BusinessResourcesActions';


const formDataForItem = payrollItem => {
    const data = {};
    _.forEach(_.pick(payrollItem, payrollItemFields), (value, key) => {
        switch (key) {
            case 'account_id': {
                data['PayrollItem[account_id]'] = value;
                break;
            }
            case 'id': {
                data['PayrollItem[id]'] = value;
                break;
            }
            case 'wage_type': {
                data['PayrollItem[wage_type]'] = value;
                break;
            }
            default: {
                data[`PayrollItem[${key}]`] = value;
            }
        }
    });
    return data;
};

export function* updatePayrollItem({ payrollItem, callback }) {
    try {
        const data = formDataForItem(payrollItem);
        const updatedPayrollItem = yield call(updateExistingPayrollItem, data);
        yield put(resourceActions.requestBusinessResources());
        callback(updatedPayrollItem);
    } catch (error) {
        console.log(error);
        callback(false);
        yield put(payrollItemActions.updatePayrollItemError(error));
        yield put(
            uiActions.showToast('Couldn’t update this payroll item. Try again later.', 'negative')
        );
    }
}

export function* addPayrollItem({ payrollItem, callback }) {
    try {
        const data = formDataForItem(payrollItem);
        const newPayrollItem = yield call(createNewPayrollItem, data);
        yield put(payrollItemActions.addPayrollItemSuccess(newPayrollItem));
        yield put(resourceActions.requestBusinessResources());
        callback(newPayrollItem);
    } catch (error) {
        console.log(error);
        callback(false);
        yield put(payrollItemActions.addPayrollItemError(error));
        yield put(uiActions.showToast('Couldn’t create new payroll item', 'negative'));
    }
}

export function* addPayrollItemList() {
    try {
        const updatedPayrollItems = yield call(getAllPayrollItemsForTable);
        yield put(payrollItemActions.setAllPayrollItems(updatedPayrollItems));
        yield put(uiActions.showToast('New Payroll Items are added', 'positive'));
    } catch (error) {
        console.log(error);
        yield put(payrollItemActions.addPayrollItemListError(error));
        yield put(
            uiActions.showToast('Couldn’t get new payroll items, please refresh page', 'negative')
        );
    }
}

export function* unhidePayrollItem({ payrollItemId }) {
    try {
        yield call(activatePayrollItem, payrollItemId);
        yield put(resourceActions.requestBusinessResources());
        yield put(uiActions.showToast('payrol item has been activated', 'positive'));
    } catch (error) {
        yield put(payrollItemActions.unhidePayrollItem(error));
        yield put(
            uiActions.showToast('Couldn’t activate this payroll item. Try again later.', 'negative')
        );
    }
}

export function* hidePayrollItem({ payrollItemId }) {
    try {
        yield call(deactivatePayrollItem, payrollItemId);
        yield put(resourceActions.requestBusinessResources());
        yield put(uiActions.showToast('payroll item has been deactivated', 'positive'));
    } catch (error) {
        yield put(payrollItemActions.hidePayrollItem(error));
        yield put(
            uiActions.showToast(
                'Couldn’t deactivate this payroll item. Try again later.',
                'negative'
            )
        );
    }
}

export default function* root() {
    yield all([
        takeLatest(['UPDATE_PAYROLL_ITEM'], updatePayrollItem),
        takeLatest(['ADD_PAYROLL_ITEM'], addPayrollItem),
        takeLatest(['ADD_PAYROLL_ITEM_LIST'], addPayrollItemList),
        takeLatest(['ACTIVATE_PAYROLL_ITEM'], unhidePayrollItem),
        takeLatest(['DEACTIVATE_PAYROLL_ITEM'], hidePayrollItem),
    ]);
}
