import React, { useMemo } from 'react';
import Icon from 'react-icons-kit';
import { plus } from 'react-icons-kit/ikons/plus';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import { refresh } from 'react-icons-kit/ionicons/refresh';
import { getAllCustomersForTable } from '../../../requests/CustomerRequests';
import Modal from 'react-modal';
import MinuteTable from '../../components/MinuteTable';
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit';
import '../../../css/Timesheets.css';
import Select from '../../components/Select.js';
import { caretDown } from 'react-icons-kit/fa/caretDown';
import { caretRight } from 'react-icons-kit/fa/caretRight';

try {
    Modal.setAppElement('#root');
} catch {}

const CustomersResourcesList = ({
    title,
    onClick,
    history,
    createNewLabel,
    createNewPath,
    activateCustomer,
    deactivateCustomer,
    showToast,
}) => {
    const columns = useMemo(
        () => [
            {
                accessor: 'name',
                Header: 'Company Name',
                Cell: ({ row }) => (
                    // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                    // to build the toggle for expanding a row
                    <div
                        {...row.getToggleRowExpandedProps({
                            style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 3}rem`,
                            },
                        })}
                    >
                        {row.canExpand && (
                            <div>
                                <Icon
                                    className="icon"
                                    style={{ paddingRight: '1px', color: '#008fd7' }}
                                    icon={
                                        row.isExpanded && row.original.subRows.length > 0
                                            ? caretDown
                                            : caretRight
                                    }
                                    size={18}
                                />
                                {row.original.company_name}
                            </div>
                        )}

                        {!row.canExpand && (
                            <div>
                                <Icon
                                    className="icon"
                                    style={{ paddingRight: '1px', color: 'white' }}
                                    icon={caretRight}
                                    size={18}
                                />
                                {row.original.company_name}
                            </div>
                        )}
                    </div>
                ),
            },
            {
                accessor: 'null',
                Header: '',
            },
            {
                accessor: 'null1',
                Header: '',
            },
            {
                Header: 'Status',
                id: 'status',
                accessor: () => 'Status',
                Cell: tableProps => (
                    <td className="tsStatusCell">
                        {tableProps.row.original.hidden === false && (
                            <span
                                className="tsStatus tsStatusApproved"
                                onClick={() => onToggleApproved(tableProps.row.original)}
                            >
                                {isUpdatingStatus ? 'Updating...' : 'Active'}
                            </span>
                        )}
                        {tableProps.row.original.hidden === true && (
                            <span
                                className="tsStatus tsStatusPending"
                                onClick={() => onToggleApproved(tableProps.row.original)}
                            >
                                {isUpdatingStatus ? 'Updating...' : 'Inactive'}
                            </span>
                        )}
                    </td>
                ),
            },
            {
                Header: 'Actions',
                id: 'actions',
                accessor: () => 'Actions',

                Cell: tableProps => (
                    <Icon
                        onClick={() => onClick(tableProps.row.original)}
                        icon={ic_mode_edit}
                        className="tableIcon asAccountUserIcon"
                        size={22}
                        data-tip="Edit Permissions"
                    />
                ),
            },
        ],
        []
    );

    const statusFilters = [{ name: 'All Customers', id: 'all' }, { name: 'ACTIVE', id: 'active' }];
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = React.useState(0);
    const [filter, setFilter] = React.useState('active');
    const [resetTable, setResetTable] = React.useState(false);
    const [isUpdatingStatus, setIsUpdatingStatus] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);
    const sortIdRef = React.useRef(0);
    const [refreshExapnad, setRefreshExapnad] = React.useState(false);

    const fetchData = React.useCallback(({ sortBy, pageSize, pageIndex, filter }) => {
        const fetchId = ++fetchIdRef.current;
        setLoading(true);
        setRefreshTable(false);
        setResetTable(false);
        setTimeout(() => {
            if (fetchId === fetchIdRef.current) {
                let paramsObject = {
                    current_page: pageIndex + 1,
                    per_page: pageSize,
                    filter: filter,
                };
                Promise.all([getAllCustomersForTable(paramsObject)]).then(response => {
                    let json = response[0];
                    let customerList = json.customerList;

                    if (sortBy.length > 0) {
                        const sortId = ++sortIdRef.current;
                        if (sortId === sortIdRef.current) {
                            let sorted = customerList;
                            sorted.sort((a, b) => {
                                for (let i = 0; i < sortBy.length; ++i) {
                                    if (a[sortBy[i].id] > b[sortBy[i].id])
                                        return sortBy[i].desc ? -1 : 1;
                                    if (a[sortBy[i].id] < b[sortBy[i].id])
                                        return sortBy[i].desc ? 1 : -1;
                                }
                                return 0;
                            });
                            setData(customerList);
                            setRefreshExapnad(true);
                            setPageCount(Math.ceil(json.meta.total / pageSize));
                            setLoading(false);
                        }
                    } else {
                        setData(customerList);
                        setRefreshExapnad(true);
                        setPageCount(Math.ceil(json.meta.total / pageSize));
                        setLoading(false);
                    }
                });
            }
        }, 1000);
    }, []);

    function handleFilterChanged(value) {
        setFilter(value);
        setResetTable(true);
    }
    function closeModal() {
        setIsModalOpen(false);
    }

    function onToggleApproved(customer) {
        if (customer.hidden === false) {
            setSelectedCustomerId(customer.id);
            setIsModalOpen(true);
        } else {
            if (customer.parent_hidden === true) {
                showToast('To activite this customer please activate its parent first.', 'warning');
            } else {
                setIsUpdatingStatus(true);
                activateCustomer(customer.id);
                setIsUpdatingStatus(false);
                setRefreshTable(true);
            }
        }
    }

    function inactiveUser() {
        setIsUpdatingStatus(true);
        deactivateCustomer(selectedCustomerId);
        setIsUpdatingStatus(false);
        setRefreshTable(true);
        closeModal();
    }

    return (
        <div>
            <h3 className="cardHeading">
                {title}
                <div className="bodyHeader filters">
                    <div className="" style={{ fontWeight: 'lighter' }}>
                        <Select
                            toggleStyle="button"
                            className="multiselectFilter"
                            options={statusFilters}
                            value={filter}
                            defaultValue="active"
                            onChange={handleFilterChanged}
                        />
                    </div>

                    <div className="">
                        <button
                            className="secondaryButton"
                            style={{ height: '35px' }}
                            onClick={() => history.push(createNewPath)}
                        >
                            <Icon className="icon" icon={plus} size={20} />
                            {createNewLabel}
                        </button>
                    </div>
                    <div className="headerActions">
                        <button
                            className="secondaryButton"
                            style={{ height: '35px' }}
                            onClick={() => setRefreshTable(true)}
                        >
                            <Icon className="icon" icon={refresh} size={20} />
                        </button>
                    </div>
                </div>
            </h3>
            <Modal
                closeTimeoutMS={400}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Inactive user"
                className="modalContainer resourceModal"
            >
                <div className="modalHeader">
                    <h2>Deactivate Customer</h2>
                    <div className="modalOptions">
                        <Icon
                            className="modalIcon"
                            size={32}
                            icon={closeIcon}
                            onClick={closeModal}
                        />
                    </div>
                </div>
                <div className="modalContent helpModalContent">
                    <div className="sectionAnswer">
                        Are you sure you want to deactivate this customer?
                    </div>
                    <div className="sectionButton">
                        <button className="destructiveButton" onClick={() => inactiveUser()}>
                            Deactivate
                        </button>
                    </div>
                </div>
            </Modal>
            <div className="cardContainer asSection">
                <MinuteTable
                    columns={columns}
                    data={data}
                    fetchData={fetchData}
                    loading={loading}
                    pageCount={pageCount}
                    filter={filter}
                    refresh={refreshTable}
                    resetTable={resetTable}
                    openToggle={refreshExapnad}
                />
            </div>
        </div>
    );
};

export default CustomersResourcesList;
