import React from 'react';

const Minute7Security = () => (
    <div>
        <div className="sectionQuestion">
            How do I change my security settings for Minute7 in QuickBooks?
        </div>
        <div className="sectionAnswer">
            To change your security settings for the sync process after you have gone through the
            initial setup process, open your company file in QuickBooks. In the main menubar at the
            top, select "Edit", and then "Preferences...". The QuickBooks preference window will
            open up. In the left-hand column, choose "Integrated Applications" and select the
            "Company Preferences" tab. In the list of applications on that tab, select "Minute7" and
            click the "Properties..." button. This will open up a window detailing the settings
            QuickBooks has stored for Minute7. From there, you can change the Access Rights allowed
            to Minute7.
        </div>
    </div>
);

export default Minute7Security;
