import React from 'react';

const SyncWithoutExporting = () => (
    <div>
        <div className="sectionQuestion">
            I've updated my employees, customers, or other data in QuickBooks, but I'm not ready to
            export time and expense entries yet. How can I sync without exporting time and expense
            entries?
        </div>
        <div className="sectionAnswer">
            By default, when you sync Minute7 with QuickBooks, Minute7 will update all of your
            Customer, Employee, Service Item, Vendor, and Class lists directly from QuickBooks, and
            it will also export the time and expense entries you created in Minute7 to QuickBooks.
            <br /> <br />
            <b>If you want to update your data lists but not export time and expense entries</b>,
            you can do so as an Account Manager from the Account Management screen: just click on
            the link under "QuickBooks Sync" that says
            <b>"Don't export time and expense entries"</b>. The next time you sync Minute7 with
            QuickBooks, time and expense entries will not be exported, but your Employee, Customer,
            and other data lists will be updated.
            <br /> <br />
            Note that each time you sync, <b>your account settings are reset</b> so that time and
            expense entries will be exported with the following sync unless you specifically choose
            to not export the entries by clicking "Don't export time and expense entries."
        </div>
    </div>
);

export default SyncWithoutExporting;
