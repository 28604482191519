import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroupAddon, InputGroup } from 'reactstrap';
import Icon from 'react-icons-kit';
import { search } from 'react-icons-kit/fa/search';
import { circle_delete as circleDelete } from 'react-icons-kit/ikons/circle_delete';

export default class SearchField extends PureComponent {
    static propTypes = {
        onSearchPressed: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.string,
    };

    static defaultProps = {
        placeholder: 'Search',
        value: '',
    };

    constructor(props) {
        super(props);
        this.state = {
            text: props.value,
        };
    }

    componentWillReceiveProps = props => {
        if (props.value !== this.props.value && props.value !== this.state.text) {
            this.setState({ text: props.value });
        }
    };

    handleTextChanged = event => {
        this.setState({ text: event.target.value });
    };

    handleClearPressed = () => {
        this.setState({ text: '' });
        this.props.onSearchPressed('');
    };

    handleSearchPressed = () => {
        this.props.onSearchPressed(this.state.text);
    };

    handleKeyDown = event => {
        if (event.key === 'Enter') {
            this.props.onSearchPressed(this.state.text);
        }

        if (event.key === 'Escape') {
            this.handleClearPressed();
        }
    };

    render() {
        const { placeholder } = this.props;
        const { text } = this.state;
        const hasEnteredText = text.trim().length > 0;

        return (
            <InputGroup className="searchFieldContainer">
                <Input
                    className="searchField"
                    placeholder={placeholder}
                    value={text}
                    onChange={this.handleTextChanged}
                    onKeyDown={this.handleKeyDown}
                />
                {hasEnteredText && (
                    <InputGroupAddon addonType="append">
                        <Icon
                            className="inputAddonPrependIcon clearIcon"
                            size={15}
                            icon={circleDelete}
                            onClick={this.handleClearPressed}
                            data-tip="Clear Search"
                        />
                    </InputGroupAddon>
                )}
                <InputGroupAddon addonType="append">
                    <Icon
                        className="inputAddonAppendIcon"
                        size={15}
                        icon={search}
                        onClick={this.handleSearchPressed}
                        data-tip="Search"
                    />
                </InputGroupAddon>
            </InputGroup>
        );
    }
}
