import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Icon from 'react-icons-kit';
import { filePdfO } from 'react-icons-kit/fa/filePdfO';
import { fileExcelO } from 'react-icons-kit/fa/fileExcelO';
import { fileTextO } from 'react-icons-kit/fa/fileTextO';
import { fileZipO } from 'react-icons-kit/fa/fileZipO';
import {
    reportTimeFields,
    reportExpenseFields,
    reportTimeFieldsNonQB,
    reportTimeFieldsNonPayRollManager,
    reportTimeFieldsNonBillRatelManager,
    reportExpenseFieldsNonQB,
    segmentationTimeFields,
    segmentationExpenseFields,
    segmentationTimeFieldsNonPayrollManager,
    reportTimeFieldsNonPayRollAndBillRollManager,
} from '../../config/Fields.js';
import '../../css/Reports.css';
import { objectKeysToCommaSeparatedString } from '../../utils/CommonFunctions.js';
import Filters from './components/Filters.js';
import Segmentation from './components/Segmentation.js';
import { showToast } from '../../actions/Actions.js';
import {
    requestSegmentedTime,
    requestSegmentedExpenses,
    cancelReportRequests,
} from '../../actions/ReportActions';
import {
    getTimePdf,
    getTimeExcel,
    getTimeCsv,
    getExpenseExcel,
    getExpenseCsv,
    getExpensePdf,
    getExpenseAttachments,
} from '../../requests/ReportRequests.js';
import ReportFields from './components/ReportFields.js';
import * as accountSelectors from '../../selectors/AccountSelectors';
import Select from '../components/Select';
import { reportFilterFileds } from '../../config/Fields.js';
import { addReportFilter , refreshReportFilters, deleteReportFilters, updateReportFilters} from '../../actions/ReportFilterActions.js';
import { event } from 'react-ga';

class Reports extends Component {
    constructor(props) {
        super(props);

        this.expenseEntriesStore = [];
        this.timeEntriesStore = [];
        this.state = {
            loading: true,
            ...this.defaultState('timesheets'),
        };
    }

    componentDidMount() {
        this.resetState();
    }

    componentWillUnmount() {
        this.props.cancelReportRequests();
    }

    resetState = (newView = false) => {
        const currentView = newView ? newView : this.state.currentView;
        const callback = currentView === 'timesheets' ? this.segmentTime : this.segmentExpenses;
        this.refresUserhReportFilters();
        this.setState(this.defaultState(newView), callback);
    };

    defaultState = (newView = false) => {
        const now = moment();
        const startDate = moment(now)
            .startOf('day')
            .subtract(1, 'month')
            .format('Y-MM-DD');
        const endDate = now.format('Y-MM-DD');

        const currentView = newView ? newView : this.state.currentView;
        let timeFields = this.setTimeFields();
        const expenseFields =
            this.props.accountStore.qb_type === 'None'
                ? reportExpenseFieldsNonQB
                : reportExpenseFields;
        return {
            restrictByDate: true,
            startDate,
            endDate,
            searchText: '',
            filteredList: [],
            currentView,
            searchCustomerIds: [],
            searchEmployeeIds: [],
            searchVendorIds: [],
            searchInventoryItemIds: [],
            searchQbClassIds: [],
            searchQbAccountIds: [],
            searchPayrollItemIds: [],
            searchExpenseGroupingIds: [],
            exportTimeFields: { ...timeFields },
            exportExpenseFields: { ...expenseFields },
            approvedFilter: 'all',
            billableFilter: 'all',
            taxableFilter: 'all',
            exportedFilter: 'all',
            lockedFilter: 'all',
            expenseTypeFilter: 'all',
            attachmentFilter: 'all',

            firstSegmentation: currentView === 'timesheets' ? 'employee_id' : 'vendor_id',
            secondSegmentation: 'no_segment',
            thirdSegmentation: 'no_segment',
            fourthSegmentation: 'no_segment',
            segmentedTime: [],
            segmentedExpenses: [],
            isModalOpen: false,
            isDeleteModalOpen: false,
            filterSelected: false,
            reportName: '',
            reportFIlterNameInvalidMsg: '',
            reprotFiltersList: [],
            selectedReportFilter:{},
            selectedReportFilterName:"",
        };
    };

    setTimeFields = () => {
        let timeFields = [];
        if (this.props.accountStore.qb_type === 'None' && !this.props.canManagePayroll && this.props.canManageItemBillrate) {
            timeFields = reportTimeFieldsNonPayRollManager;
        }
        else if (this.props.accountStore.qb_type === 'None' && this.props.canManagePayroll && !this.props.canManageItemBillrate) {
            timeFields = reportTimeFieldsNonBillRatelManager;
        }
        else if (this.props.accountStore.qb_type === 'None' && this.props.canManagePayroll && this.props.canManageItemBillrate) {
            timeFields = reportTimeFieldsNonQB;
        }
        else if (this.props.accountStore.qb_type === 'None' && !this.props.canManagePayroll && !this.props.canManageItemBillrate) {
            timeFields = reportTimeFieldsNonPayRollAndBillRollManager;
        }
        else {
            timeFields = reportTimeFields;
        }
        return timeFields;
    }

    setSegmentFields = () => {
        let segment = segmentationTimeFields;
        if (this.props.accountStore.qb_type === 'None' && !this.props.canManagePayroll) {
            segment = segmentationTimeFieldsNonPayrollManager;
        }
        return segment;
    }

    closeModal = () => {
        this.setState({
            reportFIlterNameInvalidMsg: '',
            reportName: '',
            isModalOpen: false,
            isUpdateModalOpen: false
        });
    }
    openModal = () => {
        this.setState({ isModalOpen: true });
    }

    closeUpdateFilterModal = () => {
        this.setState({
            reportFIlterNameInvalidMsg: '',
            reportName: '',
            isUpdateModalOpen: false
        });
    }
    openUpdateFilterModal = () => {
        this.setState({ isUpdateModalOpen: true, reportName: this.state.selectedReportFilterName });
    }

    closeDeleteFilterModal = () => {
        this.setState({
            isDeleteModalOpen: false
        });
    }
    openDeleteFilterModal = () => {
        this.setState({ isDeleteModalOpen: true });
    }

    refresUserhReportFilters = () =>{
        this.setState({ reportLoading: true });
        this.props.refreshReportFilters( {}, newReportFilter => {
            this.setState({ reportLoading: false });
            if( this.state.currentView === 'timesheets' ){
            this.setState({reprotFiltersList: this.props.reportFilterStore.reportFilters.ReportFilters.filter(x=>x.report_type === "timesheets")})
            }
            else{
                this.setState({reprotFiltersList: this.props.reportFilterStore.reportFilters.ReportFilters.filter(x=>x.report_type == "expenses")})
            }
            });
    }
    segmentTime = () => {
        this.setState({ loading: true });
        this.props.requestSegmentedTime({
            params: this.constructParamsObject(),
            onSuccess: segmentedTime => {
                this.setState({ segmentedTime, loading: false });
            },
            onError: () => {
                this.setState({ loading: false });
            },
        });
    };

    segmentExpenses = () => {
        this.setState({ loading: true });
        this.props.requestSegmentedExpenses({
            params: this.constructParamsObject(),
            onSuccess: segmentedExpenses => {
                this.setState({ segmentedExpenses, loading: false });
            },
            onError: () => {
                this.setState({ loading: false });
            },
        });
    };

    constructParamsObject = () => {
        const { session_id: sessionId, session_name: sessionName } = this.props.appStore;
        const params = {
            export_time_fields: objectKeysToCommaSeparatedString(this.state.exportTimeFields),
            export_expense_fields: objectKeysToCommaSeparatedString(this.state.exportExpenseFields),
            report_vendor_id: this.state.searchVendorIds.join(','),
            report_employee_id: this.state.searchEmployeeIds.join(','),
            report_customer_id: this.state.searchCustomerIds.join(','),
            report_qb_class_id: this.state.searchQbClassIds.join(','),
            report_qb_account_id: this.state.searchQbAccountIds.join(','),
            report_payroll_item_id: this.state.searchPayrollItemIds.join(','),
            report_inventory_item_id: this.state.searchInventoryItemIds.join(','),
            report_expense_grouping_id: this.state.searchExpenseGroupingIds.join(','),
            has_attachment: this.state.attachmentFilter.replace(/\s/g, '+'),
            billable: this.state.billableFilter.replace(/\s/g, '+'),
            taxable: this.state.taxableFilter.replace(/\s/g, '+'),
            exported: this.state.exportedFilter.replace(/\s/g, '+'),
            locked: this.state.lockedFilter.replace(/\s/g, '+'),
            expenseTypeFilter: this.state.expenseTypeFilter.replace(/\s/g, '+'),
            approved: this.state.approvedFilter.replace(/\s/g, '+'),
            callback: window.location.href,
        };
        if (this.state.restrictByDate) {
            params.filter_by_date = true;
            params.start_date = moment(this.state.startDate, 'Y-MM-DD').format('Y-MM-DD');
            params.end_date = moment(this.state.endDate, 'Y-MM-DD').format('Y-MM-DD');
        }
        params.segments = [
            this.state.firstSegmentation,
            this.state.secondSegmentation,
            this.state.thirdSegmentation,
            this.state.fourthSegmentation,
        ];

        if (sessionId !== null && sessionName !== null) {
            params[sessionName] = sessionId;
        }
        return params;
    };

    excelExport = () => {
        const data = this.constructParamsObject();
        this.state.currentView === 'timesheets' ? getTimeExcel(data) : getExpenseExcel(data);
    };

    pdfExport = () => {
        const data = this.constructParamsObject();
        this.state.currentView === 'timesheets' ? getTimePdf(data) : getExpensePdf(data);
    };

    csvExport = () => {
        const data = this.constructParamsObject();
        this.state.currentView === 'timesheets' ? getTimeCsv(data) : getExpenseCsv(data);
    };

    downloadAttachments = () => {
        const data = this.constructParamsObject();
        getExpenseAttachments(data);
    };

    toggleExportField = key => {
        if (this.state.currentView === 'timesheets') {
            let fields = this.setTimeFields();
            let tempExportFields = this.state.exportTimeFields;
            if (tempExportFields[key]) {
                tempExportFields[key] = false;
            } else {
                tempExportFields[key] = fields[key];
            }
            this.setState({ exportTimeFields: tempExportFields });
        } else if (this.state.currentView === 'expenses') {
            let fields = this.props.accountStore.qb_type === 'None'
                ? reportExpenseFieldsNonQB
                : reportExpenseFields;
            let tempExportFields = this.state.exportExpenseFields;
            if (tempExportFields[key]) {
                tempExportFields[key] = false;
            } else {
                tempExportFields[key] = fields[key];
            }
            this.setState({ exportExpenseFields: tempExportFields });
        }
    };

    handleReportTypeChanged = reportType => {
        this.resetState(reportType);
    };

    handleFirstSegmentationChanged = value => {
        this.setState({ firstSegmentation: value }, this.updateSegmentedDate);
    };

    handleSecondSegmentationChanged = value => {
        this.setState({ secondSegmentation: value }, this.updateSegmentedDate);
    };

    handleThirdSegmentationChanged = value => {
        this.setState({ thirdSegmentation: value }, this.updateSegmentedDate);
    };

    handleFourthSegmentationChanged = value => {
        this.setState({ fourthSegmentation: value }, this.updateSegmentedDate);
    };

    handleReportNameChnaged = value => {
        this.setState({ reportName: value.target.value})
    }

    handleReportFilterChanged = value =>{
        let reportFilter = this.state.reprotFiltersList.filter(x=>x.id === value)[0];
        this.setState({
            attachmentFilter: reportFilter.attachment,
            expenseTypeFilter: reportFilter.expense_type,
            lockedFilter: reportFilter.locked,
            taxableFilter: reportFilter.taxable,
            billableFilter: reportFilter.billable,
            exportedFilter: reportFilter.synced,
            approvedFilter: reportFilter.approved,
            searchCustomerIds: reportFilter.customers!==''?reportFilter.customers.split(","):[],
            searchEmployeeIds: reportFilter.employees!==''?reportFilter.employees.split(","):[],
            searchVendorIds: reportFilter.vendors!==''?reportFilter.vendors.split(","):[],
            searchInventoryItemIds: reportFilter.inventory!==''?reportFilter.inventory.split(","):(reportFilter.service!==''?reportFilter.service.split(",") :[]),
            searchQbClassIds: reportFilter.class_list!==''?reportFilter.class_list.split(","):[],
            searchQbAccountIds:  reportFilter.accounts!==''?reportFilter.accounts.split(","):[],
            searchPayrollItemIds: reportFilter.payroll_items!==''?reportFilter.payroll_items.split(","):[],
            searchExpenseGroupingIds: reportFilter.bill!==''?reportFilter.bill.split(","):[],
            selectedReportFilter: value,
            selectedReportFilterName: reportFilter.name,
            filterSelected: true 
        });
        let fieldsKeys = Object.keys(JSON.parse(reportFilter.fields ))
        let timeFields = this.setTimeFields();
        const expenseFields =
            this.props.accountStore.qb_type === 'None'
                ? reportExpenseFieldsNonQB
                : reportExpenseFields;
        if (this.state.currentView === 'timesheets') {
            let tempExportFields = { ...timeFields}
            Object.keys( timeFields).forEach(element => {
                if(fieldsKeys.includes(element) === false){
                    tempExportFields[element] = false; 
                }
            });
            this.setState({ exportTimeFields: tempExportFields });
        }
        else{
            let tempExportFields ={...expenseFields} ;
            Object.keys(expenseFields).forEach(element => {
                if(fieldsKeys.includes(element) === false){
                    tempExportFields[element] = false; 
                }
            });
            this.setState({ exportExpenseFields: tempExportFields });
        }

    }
    handleFiltersChanged = updates => {
        const allowedKeys = [
            'searchEmployeeIds',
            'searchVendorIds',
            'searchCustomerIds',
            'searchQbAccountIds',
            'searchInventoryItemIds',
            'searchQbClassIds',
            'searchPayrollItemIds',
            'searchExpenseGroupingIds',
            'approvedFilter',
            'billableFilter',
            'taxableFilter',
            'exportedFilter',
            'lockedFilter',
            'expenseTypeFilter',
            'attachmentFilter',
            'restrictByDate',
            'startDate',
            'endDate',
        ];
        this.setState({ ..._.pick(updates, allowedKeys) });
    };

    updateSegmentedDate = () => {
        this.state.currentView === 'timesheets' ? this.segmentTime() : this.segmentExpenses();
    };

    renderSelectOrDeselect = () => {
        return (
            <button
                className="tertiaryButton"
                onClick={() => this.resetState()}>
                Reset Filters
            </button>
        );
    };

    handleCsvTotalDataRequest = () => {
        const { currentView } = this.state;
        const { name } = this.props.accountStore;
        const data = this.constructParamsForTotalDataRequest();
        const csv_data = {
            data: data['data'],
            header: data['headers'],
            filename: name + '_' + currentView + '.csv',
        };
        return csv_data;
    };

    csv_data = (segments, index, data, currentView) => {
        let result = [];
        data.forEach(cur => {
            let row_data = {};
            row_data[segments[index]] = cur.name;
            row_data[currentView] = cur.sum;
            result.push(row_data);

            if (cur.rows === undefined) {
                return result;
            } else {
                const row_detail = this.csv_data(segments, index + 1, cur.rows, currentView);
                row_detail.forEach(curr1 => {
                    curr1[segments[index]] = cur.name;
                    result.push(curr1);
                });
            }
        });
        return result;
    };

    constructParamsForTotalDataRequest = () => {
        const {
            firstSegmentation,
            secondSegmentation,
            thirdSegmentation,
            fourthSegmentation,
            currentView,
            segmentedTime,
            segmentedExpenses,
        } = this.state;
        const currentSegmentationList =
            currentView === 'timesheets' ? segmentationTimeFields : segmentationExpenseFields;
        const segmentedData = currentView === 'timesheets' ? segmentedTime : segmentedExpenses;
        const { session_id, session_name } = this.props.appStore;

        let data = {};
        const segments = [
            firstSegmentation,
            secondSegmentation,
            thirdSegmentation,
            fourthSegmentation,
        ];

        let headers = segments
            .filter(entityName => entityName !== 'no_segment')
            .map(
                entityName =>
                    currentSegmentationList.filter(value => value.id === entityName)[0].name
            );

        data['headers'] = JSON.stringify(headers);

        data['data'] = JSON.stringify(
            this.csv_data(
                headers,
                0,
                segmentedData,
                currentView === 'timesheets' ? 'Total Hours' : 'Total Amount'
            )
        );

        if (session_id !== null && session_name !== null) {
            data[session_name] = session_id;
        }
        return data;
    };

    saveReportFilters = event => {
        if (
            this.state.reportName.length === 0 || this.state.reportName === '') {
            this.setState({
                reportFIlterNameInvalidMsg: 'Please provide a name for your Report Filter',
            })
        }
        else {
            let service = "";
            let item = "";
            let fields = [];
            if (this.state.currentView === 'timesheets') {
                service = this.state.searchInventoryItemIds.join(',')
                fields = { ...this.state.exportTimeFields};
            }
            else {
                item = this.state.searchInventoryItemIds.join(',')
                fields = { ...this.state.exportExpenseFields};
            }
            Object.keys(fields).forEach(element => {
                if(fields[element] === false){
                   delete fields[element]; 
                }
            });
            let ReportFilter = {};
            
            let export_time_fields= objectKeysToCommaSeparatedString(this.state.exportTimeFields);
            ReportFilter["user_id"]= this.props.accountUserStore.id;
            ReportFilter["name"]= this.state.reportName;
            ReportFilter["report_type"]= this.state.currentView;
            ReportFilter["account_id"]= this.props.account.id;
            ReportFilter["vendors"]= this.state.searchVendorIds.join(',');
            ReportFilter["employees"]= this.state.searchEmployeeIds.join(',');
            ReportFilter["customers"]= this.state.searchCustomerIds.join(',');
            ReportFilter["class_list"]= this.state.searchQbClassIds.join(',');
            ReportFilter["accounts"] = this.state.searchQbAccountIds.join(',');
            ReportFilter["payroll_items"]= this.state.searchPayrollItemIds.join(',');
            ReportFilter["service"]= service;
            ReportFilter["inventory"]= item;
            ReportFilter["bill"]= this.state.searchExpenseGroupingIds.join(',');
            ReportFilter["billable"]= this.state.billableFilter.replace(/\s/g, '+');
            ReportFilter["taxable"]= this.state.taxableFilter.replace(/\s/g, '+');
            ReportFilter["synced"]= this.state.exportedFilter.replace(/\s/g, '+');
            ReportFilter["locked"]= this.state.lockedFilter.replace(/\s/g, '+');
            ReportFilter["approved"]= this.state.approvedFilter.replace(/\s/g, '+');
            ReportFilter["attachment"]= this.state.attachmentFilter.replace(/\s/g, '+');
            ReportFilter["expense_type"]= this.state.expenseTypeFilter.replace(/\s/g, '+');
            ReportFilter["fields"]=JSON.stringify(fields);

            this.props.addReportFilter(ReportFilter, newReportFilter => {
                if (newReportFilter === false) {
                    this.props.showToast(
                        `report filter not saved`,
                        'negative'
                    );
                    this.setState({
                        isModalOpen: false,
                        reportFIlterNameInvalidMsg: '',
                        reportName: '',
                    });
                    return;
                } else {
                    this.setState({
                        selectedReportFilter: newReportFilter.id,
                        selectedReportFilterName: newReportFilter.name,
                        filterSelected: true,
                    });
                    this.props.showToast(
                        `New Report Filter: "${newReportFilter.name}" created`,
                        'positive'
                    );
                    this.refresUserhReportFilters();
                    this.closeModal();
                }
                
                
            });
        }
    }

    onUpdate =() => {
        if (
            this.state.reportName.length === 0 || this.state.reportName === '') {
            this.setState({
                reportFIlterNameInvalidMsg: 'Please provide a name for your Report Filter',
            })
        }
        else {
            let service = "";
            let item = "";
            let fields = { ...this.state.exportTimeFields};
            if (this.state.currentView === 'timesheets') {
                service = this.state.searchInventoryItemIds.join(',')
            }
            else {
                item = this.state.searchInventoryItemIds.join(',')
            }
            Object.keys(fields).forEach(element => {
                if(fields[element] === false){
                   delete fields[element]; 
                }
            });
            let ReportFilter = {};
            
            let export_time_fields= objectKeysToCommaSeparatedString(this.state.exportTimeFields);
            ReportFilter["user_id"]= this.props.accountUserStore.id;
            ReportFilter["name"]= this.state.reportName;
            ReportFilter["report_type"]= this.state.currentView;
            ReportFilter["account_id"]= this.props.account.id;
            ReportFilter["vendors"]= this.state.searchVendorIds.join(',');
            ReportFilter["employees"]= this.state.searchEmployeeIds.join(',');
            ReportFilter["customers"]= this.state.searchCustomerIds.join(',');
            ReportFilter["class_list"]= this.state.searchQbClassIds.join(',');
            ReportFilter["accounts"] = this.state.searchQbAccountIds.join(',');
            ReportFilter["payroll_items"]= this.state.searchPayrollItemIds.join(',');
            ReportFilter["service"]= service;
            ReportFilter["inventory"]= item;
            ReportFilter["bill"]= this.state.searchExpenseGroupingIds.join(',');
            ReportFilter["billable"]= this.state.billableFilter.replace(/\s/g, '+');
            ReportFilter["taxable"]= this.state.taxableFilter.replace(/\s/g, '+');
            ReportFilter["synced"]= this.state.exportedFilter.replace(/\s/g, '+');
            ReportFilter["locked"]= this.state.lockedFilter.replace(/\s/g, '+');
            ReportFilter["approved"]= this.state.approvedFilter.replace(/\s/g, '+');
            ReportFilter["attachment"]= this.state.attachmentFilter.replace(/\s/g, '+');
            ReportFilter["expense_type"]= this.state.expenseTypeFilter.replace(/\s/g, '+');
            ReportFilter["fields"]=JSON.stringify(fields);
            this.props.updateReportFilters(this.state.selectedReportFilter, ReportFilter, updatedReportFilter => {
                if (updatedReportFilter === false) {
                    this.props.showToast(
                        `report filter not updated`,
                        'negative'
                    );
                    this.setState({
                        isUpdateModalOpen: false,
                        reportFIlterNameInvalidMsg: '',
                        reportName: '',
                    });
                    return;
                } else {
                    this.setState({
                        selectedReportFilterName: updatedReportFilter.ReportFilter.name,
                    })
                    this.props.showToast(
                        `New Report Filter: "${updatedReportFilter.ReportFilter.name}" updated`,
                        'positive'
                    );
                    this.refresUserhReportFilters();
                    this.closeUpdateFilterModal();
                }
                
                
            });
        }
    }

    onDelete = () => {
        this.props.deleteReportFilters(this.state.selectedReportFilter, newReportFilter => {
            if (newReportFilter === false) {
                this.props.showToast(
                    `report filter not deleted`,
                    'negative'
                );
                this.setState({
                    isModalOpen: false,
                });
                return;
            } else {
                this.props.showToast(
                    `Report Filter: "${this.state.selectedReportFilterName}" deleted`,
                    'positive'
                );
                this.setState({
                    selectedReportFilter:{}, selectedReportFilterName:"", filterSelected:false
                });
                this.resetState();
                this.refresUserhReportFilters();
                this.closeDeleteFilterModal();
            }
            
            
        });
    }

    render() {
        const {
            currentView,
            exportExpenseFields,
            exportTimeFields,
            searchEmployeeIds,
            searchVendorIds,
            searchCustomerIds,
            searchQbAccountIds,
            searchInventoryItemIds,
            searchQbClassIds,
            searchPayrollItemIds,
            searchExpenseGroupingIds,
            approvedFilter,
            billableFilter,
            taxableFilter,
            exportedFilter,
            lockedFilter,
            expenseTypeFilter,
            restrictByDate,
            startDate,
            endDate,
            attachmentFilter,
            isModalOpen,
            reportName,
            reportFIlterNameInvalidMsg,
            reprotFiltersList,
            selectedReportFilter,
            filterSelected,
            isDeleteModalOpen,
            isUpdateModalOpen
        } = this.state;

        const { preferredDistanceUnits, prefersPoundSign, qbType, canManagePayroll, canManageItemBillrate } = this.props;

        return (
            <div className="applicationWrapper">
                <div className="secondaryNav">
                    <button
                        id="reportsTimesheetViewToggle"
                        className={classNames({
                            secondaryNavLink: true,
                            secondaryNavActiveLink: currentView === 'timesheets',
                        })}
                        onClick={this.handleReportTypeChanged.bind(this, 'timesheets')}
                    >
                        Timesheets
                    </button>
                    <button
                        id="reportsExpenseViewToggle"
                        className={classNames({
                            secondaryNavLink: true,
                            secondaryNavActiveLink: currentView === 'expenses',
                        })}
                        onClick={this.handleReportTypeChanged.bind(this, 'expenses')}
                    >
                        Expenses
                    </button>
                </div>
                <div className="applicationBody">

                    <h1 className="cardHeading rHeading">
                        Export Timesheet Reports
                    </h1>
                    <div className="cardContainer">
                        <div className="rTopContainer">
                            <div className="stackedLabelAndDropdown fieldGroup rContent">

                                <h3 className="cardHeading">
                                    Select Report Filters
                                </h3>

                                <Select
                                    className="dropdownContainer"
                                    withSearch
                                    options={reprotFiltersList}
                                    value={selectedReportFilter}
                                    buttonsList={
                                        []}
                                    onChange={this.handleReportFilterChanged}
                                />
                            </div>

                            <div className="rExportButtonContainer">
                                <button
                                    hidden={currentView === 'timesheets'}
                                    id="reportsAttachmentExportButton"
                                    className="secondaryButton rExportButton"
                                    onClick={this.downloadAttachments}
                                    data-tip="Download all attachments"
                                >
                                    <div className="rExportButtonIcon">
                                        <Icon
                                            className="rExportButtonIcon"
                                            icon={fileZipO}
                                            size={20}
                                        />
                                    </div>
                                    <div className="rExportButtonTitle">Attachments</div>
                                </button>
                                <button
                                    id="reportsExcelExportButton"
                                    className="secondaryButton rExportButton"
                                    onClick={this.excelExport}
                                    data-tip={
                                        'Export ' +
                                        (this.state.currentView === 'timesheets'
                                            ? 'Timesheets'
                                            : 'Expenses') +
                                        ' in an Excel File'
                                    }
                                >
                                    <div className="rExportButtonIcon">
                                        <Icon
                                            className="rExportButtonIcon"
                                            icon={fileExcelO}
                                            size={20}
                                        />
                                    </div>
                                    <div className="rExportButtonTitle">Export Excel</div>
                                </button>
                                <button
                                    id="reportsCSVExportButton"
                                    className="secondaryButton rExportButton"
                                    onClick={this.csvExport}
                                    data-tip={
                                        'Export ' +
                                        (this.state.currentView === 'timesheets'
                                            ? 'Timesheets'
                                            : 'Expenses') +
                                        ' in a CSV File'
                                    }
                                >
                                    <div className="rExportButtonIcon">
                                        <Icon
                                            className="rExportButtonIcon"
                                            icon={fileTextO}
                                            size={20}
                                        />
                                    </div>
                                    <div className="rExportButtonTitle">Export CSV</div>
                                </button>
                                <button
                                    id="reportsPDFExportButton"
                                    className="secondaryButton rExportButton"
                                    onClick={this.pdfExport}
                                    data-tip={
                                        'Export ' +
                                        (currentView === 'timesheets'
                                            ? 'Timesheets'
                                            : 'Expenses') +
                                        ' in a PDF File'
                                    }
                                >
                                    <div className="rExportButtonIcon">
                                        <Icon
                                            className="rExportButtonIcon"
                                            icon={filePdfO}
                                            size={20}
                                        />
                                    </div>
                                    <div className="rExportButtonTitle">Export PDF</div>
                                </button>

                            </div>

                        </div>
                        <div className="rPDFWarning">
                            <strong>Note:&nbsp; </strong> PDF has restricted fields
                        </div>
                    </div>

                    <div className="cardContainer">
                        <div className="rExportFieldsWrapper rContent">
                            <h3 className="cardHeading ">
                                Excel and CSV Export Fields
                                <div className="rSelectAllText rSubHeading">{this.renderSelectOrDeselect()}</div>
                            </h3>
                            <div className="cardContent">
                                <div className="rExportFieldsContainer">
                                    <div className="rExportFieldsTableWrapper">
                                        <table className="rExportFieldsTable">
                                            <tbody>
                                                <ReportFields
                                                    toggleExportField={this.toggleExportField}
                                                    currentView={currentView}
                                                    exportTimeFields={exportTimeFields}
                                                    exportExpenseFields={exportExpenseFields}
                                                    preferredDistanceUnits={preferredDistanceUnits}
                                                    qbType={qbType}
                                                    canManagePayroll={canManagePayroll}
                                                    canManageItemBillrate={canManageItemBillrate}
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rFiltersWrapper rContent">
                            <h3 className="cardHeading">Filters</h3>
                            <Filters
                                onChange={this.handleFiltersChanged}
                                onReset={() => this.resetState()}
                                onSubmit={this.updateSegmentedDate}
                                currentView={currentView}
                                searchEmployeeIds={searchEmployeeIds}
                                searchVendorIds={searchVendorIds}
                                searchCustomerIds={searchCustomerIds}
                                searchQbAccountIds={searchQbAccountIds}
                                searchInventoryItemIds={searchInventoryItemIds}
                                searchQbClassIds={searchQbClassIds}
                                searchPayrollItemIds={searchPayrollItemIds}
                                searchExpenseGroupingIds={searchExpenseGroupingIds}
                                approvedFilter={approvedFilter}
                                billableFilter={billableFilter}
                                taxableFilter={taxableFilter}
                                exportedFilter={exportedFilter}
                                expenseTypeFilter={expenseTypeFilter}
                                restrictByDate={restrictByDate}
                                startDate={startDate}
                                endDate={endDate}
                                attachmentFilter={attachmentFilter}
                                qbType={qbType}
                                lockedFilter={lockedFilter}
                                canManagePayroll={canManagePayroll}
                                canManageItemBillrate={canManageItemBillrate}
                                isModalOpen={isModalOpen}
                                closeModal={this.closeModal}
                                openModal={this.openModal}
                                reportName={reportName}
                                handleReportNameChnaged={this.handleReportNameChnaged}
                                validateReportFilterName={this.validateReportFilterName}
                                saveReportFilters={this.saveReportFilters}
                                reportFIlterNameInvalidMsg={reportFIlterNameInvalidMsg}
                                filterSelected={filterSelected}
                                isDeleteModalOpen={isDeleteModalOpen}
                                closeDeleteModal={this.closeDeleteFilterModal}
                                openDeleteFilterModal={this.openDeleteFilterModal}
                                onDelete={this.onDelete}
                                isUpdateModalOpen={isUpdateModalOpen}
                                closeUpdateModal={this.closeUpdateFilterModal}
                                openUpdateFilterModal={this.openUpdateFilterModal}
                                onUpdate={this.onUpdate}
                            />
                        </div>
                    </div>
                    <h1 className="cardHeading rHeading">
                        Report View
                    </h1>
                    <div className="rReportWrapper cardContainer">
                        <Segmentation
                            firstSegmentation={this.state.firstSegmentation}
                            handleFirstSegmentationChanged={this.handleFirstSegmentationChanged}
                            secondSegmentation={this.state.secondSegmentation}
                            handleSecondSegmentationChanged={this.handleSecondSegmentationChanged}
                            thirdSegmentation={this.state.thirdSegmentation}
                            handleThirdSegmentationChanged={this.handleThirdSegmentationChanged}
                            fourthSegmentation={this.state.fourthSegmentation}
                            handleFourthSegmentationChanged={this.handleFourthSegmentationChanged}
                            loading={this.state.loading}
                            segmentedData={
                                currentView === 'timesheets'
                                    ? this.state.segmentedTime
                                    : this.state.segmentedExpenses
                            }
                            currentView={currentView}
                            currentSegmentationList={
                                currentView === 'timesheets'
                                    ? this.setSegmentFields()
                                    : segmentationExpenseFields
                            }
                            handleCsvTotalDataRequest={this.handleCsvTotalDataRequest}
                            prefersPoundSign={prefersPoundSign}
                        />
                    </div>
                    <ReactTooltip delayShow={500} type="info" multiline={true} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const canViewActiveEmployees = accountSelectors.selectCanViewActiveEmployees(state);
    const canViewActiveVendors = accountSelectors.selectCanViewActiveVendors(state);

    return {
        canManagePayroll: state.accountUserStore.manage_payroll_payrates === 'Yes',
        canManageItemBillrate: state.accountUserStore.manage_item_billrates === 'Yes',
        businessResourcesStore: state.businessResourcesStore,
        accountStore: state.accountStore,
        reportFilterStore: state.reportFiltersStore,
        accountUserStore: state.accountUserStore,
        canViewEmployees: state.employeeStore.canViewEmployees,
        canViewActiveEmployees,
        canViewVendors: state.vendorStore.canViewVendors,
        canViewActiveVendors,
        canEditEmployees: state.employeeStore.canEditEmployees,
        canEditVendors: state.vendorStore.canEditVendors,
        preferredDistanceUnits: _.get(state.accountUserStore, 'preferred_distance_units', 'miles'),
        appStore: state.appStore,
        prefersPoundSign: state.accountStore.prefer_pound_sign,
        qbType: state.accountStore.qb_type,
        account: state.accountStore,
    };
}

const mapDispatchToProps = {
    showToast,
    requestSegmentedTime,
    requestSegmentedExpenses,
    cancelReportRequests,
    refreshReportFilters,
    addReportFilter,
    deleteReportFilters,
    updateReportFilters
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Reports);
