import {
    figureCanViewExpenses,
    figureCanViewTimesheets,
    figureCanViewActivityLog,
} from './PermissionUtils.js';
import { NO_EMPLOYEE_ASSOCIATION_STRING } from '../config/Constants.js';

// Permission check functions have the following signature:
// function canDoSomething(currentUser, account): PermissionResult
//
// type PermissionResult = {
//      isAllowed: bool,
//      errorWhenNotAllowed?: string,
//      redirectWhenNotAllowed?: string,
// }
//
export const canManageAccount = currentUser => ({
    isAllowed: currentUser.manage_account === 'Yes',
    errorWhenNotAllowed: 'Your account doesn’t have permission to manage accounts.',
});

export const canManageUsers = currentUser => ({
    isAllowed: canManageAccount(currentUser).isAllowed || currentUser.manage_users === 'Yes',
    errorWhenNotAllowed: 'Your account doesn’t have permission to manage accounts.',
});

export const canViewReports = currentUser => ({
    isAllowed: currentUser.view_reports === 'Yes',
    errorWhenNotAllowed: 'Your account doesn’t have permission to view reports.',
});

export const canExportTime = currentUser => ({
    isAllowed: currentUser.export_time === 'Yes',
    errorWhenNotAllowed: 'Your account doesn’t have permission to sync with QuickBooks.',
});

export const canViewTimesheets = currentUser => ({
    isAllowed: figureCanViewTimesheets(currentUser),
    errorWhenNotAllowed: NO_EMPLOYEE_ASSOCIATION_STRING,
    redirectWhenNotAllowed: '/help',
});

export const canViewExpenses = (currentUser, account) => ({
    isAllowed: figureCanViewExpenses(account, currentUser),
    errorWhenNotAllowed:
        'Your account isn’t set up to use expenses. Contact your account admin to associate you with a QuickBooks vendor to begin using Minute7 expense tracking.',
    redirectWhenNotAllowed: '/help',
});

export const canViewActivityLog = currentUser => ({
    isAllowed: figureCanViewActivityLog(currentUser),
    errorWhenNotAllowed: 'Your account doesn’t have permissions to view the activity log.',
    redirectWhenNotAllowed: '/help',
});
