import React from 'react';

const ExportWaitTime = () => (
    <div>
        <div className="sectionQuestion">
            How do I change the number of hours Minute7 waits after a time entry is created before
            exporting it?
        </div>
        <div className="sectionAnswer">
            By default, when you sync with QuickBooks, Minute7 will not export time entries that
            have been modified (or created) in the past 8 hours. We recommend keeping this setting,
            so that time entries are not edited after being synchronized to QuickBooks. If you wish
            to change this setting, you can do so on your{' '}
            <a href="/account_settings">Account Settings</a> page.
        </div>
    </div>
);

export default ExportWaitTime;
