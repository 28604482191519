import React from 'react';

const MissingPayrollInfo = () => (
    <div>
        <div className="sectionQuestion">
            QuickBooks Web Connector gives me the error 'QuickBooks is having trouble finding
            payroll information for one of your employees who has "Use time data to create
            paychecks" enabled'.
        </div>
        <div className="sectionAnswer">
            If you have received this error message, QuickBooks is having trouble adding the time
            entries from Minute7 because your employees are set to "Use time data for paychecks",
            but some necessary data is missing. If you use time data to create paychecks for
            employees in QuickBooks, you must make sure to that QuickBooks knows to allow Minute7
            access to payroll data. The following steps will set the appropriate permissions in
            QuickBooks:
            <br />
            <ol>
                <li>
                    In the top menubar of QuickBooks, go to "Edit", then "Preferences...". The
                    QuickBooks preference window will open up.
                </li>
                <li>
                    In the left-hand column, choose "Integrated Applications" and select the
                    "Company Preferences" tab.
                </li>
                <li>
                    In the list of applications on that tab, select "Minute7" and click the
                    "Properties..." button. This will open up a window detailing the settings
                    QuickBooks has stored for Minute7.
                </li>
                <li>
                    Check the box that says "Allow this application to access Social Security
                    Numbers, customer credit card information, and other personal data" (see Figure
                    1). Minute7 will never store sensitive data like social security numbers or
                    customer credit card numbers, but QuickBooks requires that this access be in
                    place if you have configured QuickBooks to manage payroll for your employees.
                </li>
                <li>Close and reopen QuickBooks and the Web Connector (if it is open).</li>
            </ol>
            <b>Note:</b> For each employee with "Use time data to create paychecks" enabled, make
            sure you have an Earnings- Item Name and Rate entered (for example, you might have an
            Earnings Item "Salary" with the rate "25.00").
        </div>
    </div>
);

export default MissingPayrollInfo;
