import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { isCompanyNameValid } from '../../../utils/CommonFunctions.js';
import { Row, Col } from 'react-bootstrap';
import { customerFields } from '../../../config/Fields.js';
import { showToast } from '../../../actions/Actions.js';
import 'react-phone-input-2/lib/style.css';
import Select from '../../components/Select.js';
import Spinner from '../../components/Spinner.js';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';
import { addCustomer } from '../../../actions/CustomerActions';
import { selectCustomer } from '../../../actions/Actions.js';
import ToggleOptionsSecondV from '../../components/ToggleOptionsSecondV';
import ToggleSettingListV2 from '../../components/ToggleSettingListV2.js';
import Icon from 'react-icons-kit';
import { informationCircled as info } from 'react-icons-kit/ionicons/informationCircled';
import ReactTooltip from 'react-tooltip';

class NewCustomerForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState({ selectedView: 'customer',firstSearchFilter: '',
        secondSearchFilter: '',
        firstSearchFilterSecondList: '',
        secondSearchFilterSecondList: '', });
    }

    componentWillReceiveProps(props) {}

    defaultState = data => {
        const { items,accountId, serviceItems,inventoryItems } = this.props;

        return {
            inventory_items_bill_rates: new Map(),
            service_items_bill_rates: new Map(),
            cNameInvalidMsg: '',
            parent_id: '',
            parent_customer: '',
            name: '',
            company_name: '',
            account_id: accountId,
            spinner_loader: false,
            item_ids: Object.keys(items).map(key => items[key].id),
            items: items,
            item_type: "Service",
            serviceItems: serviceItems,
            inventoryItems: inventoryItems,
        };
    };

    constructCustomer = () => {
        const data = {};
        customerFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else if (field === 'name') {
                    const { customers } = this.props;
                    const parentCustomer =
                        this.state.parent_id !== ''
                            ? customers.find(e => e.id === `${this.state.parent_id}`)
                            : false;

                    data[field] = parentCustomer
                        ? `${parentCustomer.name}:${this.state.company_name}`
                        : this.state.company_name;
                } else if (field === 'item_bill_rates') {
                    const { item_bill_rates } = this.state;
                    data[field] = JSON.stringify(Object.fromEntries(item_bill_rates));
                }
                else if (field === 'service_items_bill_rates') {
                    const { service_items_bill_rates } = this.state;
                    data[field] = JSON.stringify(Object.fromEntries(service_items_bill_rates));
                }
                else if (field === 'inventory_items_bill_rates') {
                    const { inventory_items_bill_rates } = this.state;
                    data[field] = JSON.stringify(Object.fromEntries(inventory_items_bill_rates));
                }
                else if (field.includes('item_ids')) {
                    data[field] = this.state[field].join(',');
                }
                 else {
                    data[field] = this.state[field];
                }
            }
        });
        return data;
    };

    checkRequiredFields = () => {
        if (this.state.company_name === '') {
            this.props.showToast('Please fill out Customer Name', 'warning');
            return false;
        }
        return true;
    };

    saveCustomer = event => {
        event.preventDefault();
        if (this.state.cNameInvalidMsg !== '') {
            this.props.showToast('Please Fix the form error before submit', 'warning');
        } else {
            if (!this.checkRequiredFields()) {
                return;
            }
            this.setState({ spinner_loader: true });
            const data = this.constructCustomer();
            this.props.addCustomer(data, newCustomer => {
                if (newCustomer === false) {
                    this.setState({ spinner_loader: false });
                    return;
                } else {
                    this.props.showToast(`New Customer ${newCustomer.name} created`, 'positive');
                    this.setState({
                        spinner_loader: false,
                        parent_id: '',
                        parent_customer: '',
                        name: '',
                        company_name: '',
                        inventory_items_bill_rates: new Map(),
                        service_items_bill_rates: new Map(),
                        item_ids: [],
                        firstSearchFilter: '',
                        secondSearchFilter: '',
                        firstSearchFilterSecondList: '',
                        secondSearchFilterSecondList: '',
                    });
                }
            });
        }
    };

    handleParentCustomerOnChange = newParentCustomer => {
        this.setState({ parent_id: newParentCustomer });
    };

    setFields = fields => {
        this.setState({ ...fields });
    };

    handleOnChanges = {
        handleComapnyNameOnChange: newName => {
            this.setState({ company_name: newName.target.value });
        },
    };

    render() {
        const { parent_id, spinner_loader, company_name,   firstSearchFilter,
            secondSearchFilter, inventory_items_bill_rates, service_items_bill_rates, items, item_ids, item_type, serviceItems, inventoryItems } = this.state;
        const { customers, canManageItemBillrate, show_items_association_for_customers } = this.props;
        return (
            <div className="applicationWrapper">
                <div className="application">
                    <div className="ftContainer cardContainer">
                        <form className="ftForm" onSubmit={this.saveCustomer}>
                            <Row className="ftRowAlightCenter">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Create New Customer
                                </div>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                Company Name
                                            </div>
                                            <div className="required">*</div>
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.cNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="company_name"
                                                value={company_name}
                                                onChange={
                                                    this.handleOnChanges.handleComapnyNameOnChange
                                                }
                                                onBlur={
                                                    company_name.length !== 0
                                                        ? () =>
                                                              isCompanyNameValid(company_name)
                                                                  ? this.setFields({
                                                                        cNameInvalidMsg: '',
                                                                    })
                                                                  : this.setFields({
                                                                        cNameInvalidMsg:
                                                                            'Customer name contains only  1 to 50 alphabets.',
                                                                    })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.cNameInvalidMsg === '' &&
                                                !company_name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.cNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                Parent Customer
                                            </div>
                                        </Row>
                                        <div>
                                            <Select
                                                options={customers}
                                                withSearch
                                                labelWhenNothingSelected="No Parent Customer Selected"
                                                value={parent_id}
                                                onChange={this.handleParentCustomerOnChange}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {canManageItemBillrate && show_items_association_for_customers && (
                            <ToggleSettingListV2
                                        title={[
                                            <span style={{ fontWeight: 'bold' }}>
                                                Select Item Type{' '}
                                            </span>,
                                            
                                        ]}
                                        value={item_type}
                                        options={[
                                            { label: 'Service Item', value: 'Service' },
                                            { label: 'Inventory Item', value: 'Inventory' },
                                        ]}
                                        valueForOption={option => option.value}
                                        labelForOption={option => option.label}
                                        onChange={value => {
                                            this.setState({
                                                item_type: value,
                                            });
                                           
                                        }}
                                    />                                
                            )}
                            {canManageItemBillrate && show_items_association_for_customers && (
                                <Row className="ftrow">
                                <div className="ftLabel largeBodyText greyText"  
                                            style={{ fontWeight: 'bold' , paddingTop: '2%', paddingBottom:'2%' }}>
                                    {' '}
                                    Set bill rates for {item_type} Items{' '}
                                    <ReactTooltip
                                        delayShow={500}
                                        className="tooltip-override"
                                        data-for="ItemBillRate"
                                        type="info"
                                        multiline={true}
                                        data-html={true}
                                    />
                                    <Icon
                                            style={{ paddingLeft: '4px' , marginTop: '5px'}}
                                            className="blueIcon fEnableDateRangeText"
                                            size={16}
                                            data-tip= {`By default, all ${item_type} items are selected. You can deselect any items if you prefer not to associate them with the customer.`}
                                            icon={info}
                                            id="ItemBillRate"
                                        />
                                </div>
                                </Row>
                            )}
                          
                            {item_type === 'Service' && canManageItemBillrate  && show_items_association_for_customers && (<ToggleOptionsSecondV
                                listOptions={serviceItems}
                                firstSearchFilter={firstSearchFilter}
                                secondSearchFilter={secondSearchFilter}
                                valueForListOptionMap={service_items_bill_rates}
                                listValue={item_ids}
                                options={['Yes', 'No']}
                                onListChange={values => {
                                    this.setState({ item_ids: values });
                                }}
                                onValueChange={values => {
                                    this.setState({ service_items_bill_rates: values });
                                }}
                                />)}
                              {item_type === 'Inventory' && canManageItemBillrate && show_items_association_for_customers && (<ToggleOptionsSecondV
                                listOptions={inventoryItems}
                                firstSearchFilter={firstSearchFilter}
                                secondSearchFilter={secondSearchFilter}
                                valueForListOptionMap={inventory_items_bill_rates}
                                listValue={item_ids}
                                options={['Yes', 'No']}
                                onListChange={values => {
                                    this.setState({ item_ids: values });
                                }}
                                onValueChange={values => {
                                    this.setState({  inventory_items_bill_rates: values });
                                }}
                                />)}
                            <Row>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button type="submit" className="primaryButton ftButton">
                                            Save
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button
                                            className="secondaryButton ftButton"
                                            onClick={() =>
                                                this.props.history.push(
                                                    '/resource_settings/customers'
                                                )
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Spinner loading={spinner_loader} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function getTwoLevelCustomerList(customers) {
    let updatedList = [];
    customers.map(customer => {
        if (customer.name.split(':').length < 4) {
            updatedList.push(customer);
        }
    });
    return updatedList;
}

function mapStateToProps(state) {
    let items = state.businessResourcesStore.inventoryItemsById
    return {
        canManageItemBillrate: state.accountUserStore.manage_item_billrates === 'Yes',
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        isSaving: state.accountUserStore.isSaving,
        customers: getTwoLevelCustomerList(state.customerStore.customers),
        items: state.businessResourcesStore.inventoryItemsById,
        serviceItems: state.businessResourcesStore.serviceTypeItems,
        inventoryItems: state.businessResourcesStore.inventoryTypeItems,
        show_items_association_for_customers: state.accountStore.show_items_association_for_customers,

    };
}

const mapDispatchToProps = {
    showToast,
    addCustomer,
    selectCustomer,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewCustomerForm);
