import { useEffect } from 'react';
import { withRouter } from 'react-router';

import scrollTo from '../../utils/scrollTo';

const ScrollToTop = ({ children, location: { pathname } }) => {
    useEffect(
        () => {
            if (window.location.hash === '') {
                scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'instant',
                });
            }
        },
        [pathname]
    );

    return children;
};

export default withRouter(ScrollToTop);
