import _ from 'lodash';
import moment from 'moment';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as api from '../requests/TimesheetsRequests';
import * as timesheetsActions from '../actions/TimesheetsActions';
import * as timerActions from '../actions/TimerActions';
import * as resourceActions from '../actions/BusinessResourcesActions';
import * as uiActions from '../actions/Actions.js';
import * as resourceSelectors from '../selectors/BusinessResourcesSelectors';
import * as accountSelectors from '../selectors/AccountSelectors';
import { getMinAndMaxDateOfEntries, startOfWeek } from '../utils/DateUtils';
import { filterStateMatchesEntry, newFilterStateMatchingEntries } from '../utils/CommonFunctions';
import {
    figureEmployeeId,
    formDataForEntry,
    constructParamsObject,
    referenceDateForEntry,
    isZeroDuration,
    isEmptyDuration,
} from '../utils/TimeEntryUtils.js';
import { extractEmbeddedEntities } from '../utils/HelperFunctions';

const selectFetchParams = (state, queryParams) => {
    const params = constructParamsObject(
        state.timesheetsStore,
        state.accountUserStore,
        queryParams
    );

    return params;
};

const selectFilterState = ({ timesheetsStore: store }) => ({
    activeFilters: store.activeFilters,
    searchText: store.searchText,
    searchEmployeeIds: store.searchEmployeeIds,
    searchCustomerIds: store.searchCustomerIds,
    searchInventoryItemIds: store.searchInventoryItemIds,
    searchQbClassIds: store.searchQbClassIds,
    searchPayrollItemIds: store.searchPayrollItemIds,
    approvedFilter: store.approvedFilter,
    exportedFilter: store.exportedFilter,
    billableFilter: store.billableFilter,
    taxableFilter: store.taxableFilter,
    startDate: store.startDate,
    endDate: store.endDate,
});

const selectDefaultsForNewTimeEntries = state => {
    const { timesheetsStore, accountStore, accountUserStore: currentUser } = state;
    const { inventoryItemId, customerId, payrollItemId, employeeId } = timesheetsStore;

    const canEditEmployees = accountSelectors.selectCanEditEmployees(state);
    const inventoryItems = resourceSelectors.selectAllowedInventoryItems(state);
    const customers = resourceSelectors.selectAllowedCustomers(state);

    const defaults = {};

    const defaultEmployeeId = figureEmployeeId(currentUser, canEditEmployees);

    const isBillableByDefault = accountStore.billable_by_default === 'Yes';
    defaults.billable = isBillableByDefault ? 'Yes' : 'No';

    const isTaxableByDefault = accountStore.taxable_by_default === 'Yes';
    defaults.taxable = isTaxableByDefault ? 'Yes' : 'No';

    if (inventoryItemId === '0' && accountStore.qb_type !== 'None') {
        defaults.inventoryItemId = _.get(inventoryItems, '[0].id', '0');
    }

    if (customerId === '0') {
        defaults.customerId = _.get(customers, '[0].id', '0');
    }

    // Set to default_payroll_item_id for employee if present
    if (payrollItemId === '0') {
        const employee = canEditEmployees.find(employee => employee.id === defaultEmployeeId);
        if (
            employee &&
            employee.use_time_for_paychecks === 'UseTimeData' &&
            'default_payroll_item_id' in employee
        ) {
            defaults.payrollItemId = employee.default_payroll_item_id;
        }
    }

    if (employeeId === '0' && defaultEmployeeId) {
        const type = defaultEmployeeId.includes('_v') ? 'Vendor' : 'Employee';
        defaults.employeeId = defaultEmployeeId;
        defaults.type = type;
    }

    return defaults;
};

const selectModalState = ({ timerStore, timesheetsStore }) => ({
    isTimerOpen: timerStore.isTimerOpen,
    isNewEntryModalOpen: timesheetsStore.isNewEntryModalOpen,
    isEditEntryModalOpen: timesheetsStore.isEditEntryModalOpen,
});

const selectWeekOffset = ({ accountUserStore }) => {
    return _.defaultTo(_.get(accountUserStore, 'week_start_offset', 0), 0);
};

export function* getTimesheets({ queryParams }) {
    try {
        const params = yield select(selectFetchParams, queryParams);

        const { entries: timesheets, meta } = yield call(api.getTimesheets, params);

        // extract embedded entities and call the batched receive action
        // for the new resources
        const entityToStateKey = {
            Customer: 'customers',
            InventoryItem: 'inventoryItems',
            QbClass: 'qbClasses',
        };

        const extractedEntities = extractEmbeddedEntities(
            Object.keys(entityToStateKey),
            timesheets
        );

        const newResources = {};

        for (let entityName in extractedEntities) {
            const entities = extractedEntities[entityName];
            const stateKey = entityToStateKey[entityName];

            if (entities.length > 0) {
                newResources[stateKey] = entities;
            }
        }

        if (Object.keys(newResources).length > 0) {
            yield put(resourceActions.receiveBusinessResources(newResources));
        }

        yield put(timesheetsActions.receiveTimesheets(timesheets, meta.total));
        yield put(timesheetsActions.setSelectedEntryIds([]));

        if ('entry_ids' in params) {
            const minAndMaxDate = getMinAndMaxDateOfEntries(timesheets);

            if ('min' in minAndMaxDate) {
                yield put(timesheetsActions.setStartDate(minAndMaxDate.min));
            }

            if ('max' in minAndMaxDate) {
                yield put(timesheetsActions.setEndDate(minAndMaxDate.max));
            }
        }
    } catch (err) {
        yield put(timesheetsActions.requestTimesheetsError(err));
        yield put(timesheetsActions.setSelectedEntryIds([]));
    }
}

export function* setEditStartOfWeek({ payload }) {
    try {
        const weekOffset = yield select(selectWeekOffset);
        const date = referenceDateForEntry(payload);
        const start = moment(startOfWeek(date, weekOffset));
        yield put(timesheetsActions.setEditStartOfWeek(start.format('Y-MM-DD')));
    } catch (err) {
        console.error(err);
    }
}

export function* setStartOfWeek() {
    try {
        const weekOffset = yield select(selectWeekOffset);
        const date = new Date();
        const start = moment(startOfWeek(date, weekOffset));
        yield put(timesheetsActions.setStartOfWeek(start.format('Y-MM-DD')));
    } catch (err) {
        console.error(err);
    }
}

export function* deleteTimeEntries({ ids }) {
    try {
        const requests = ids.map(id => call(api.deleteTimeEntry, { id }));
        yield all(requests);
        yield put(timesheetsActions.deleteTimeEntriesSuccess({ ids }));
        yield put(timesheetsActions.selectTimeEntryHash(null));
        yield put(timesheetsActions.requestTimesheets({ showLoading: false }));
    } catch (err) {
        yield put(timesheetsActions.deleteTimeEntriesError(err));
        console.log(err);
    }
}

export function* setTimeEntriesBillable({ ids, billable }) {
    try {
        const params = {
            ids: ids.join(','),
            billable_status: billable,
        };

        yield call(api.setBillable, params);
        yield put(timesheetsActions.setTimeEntriesBillableSuccess(ids));
        yield put(timesheetsActions.requestTimesheets({ showLoading: false }));
    } catch (err) {
        yield put(timesheetsActions.setTimeEntriesBillableError(err));
        console.log(err);
    }
}

export function* setTimeEntriesTaxable({ ids, taxable }) {
    try {
        const params = {
            ids: ids.join(','),
            taxable_status: taxable,
        };

        yield call(api.setTaxable, params);
        yield put(timesheetsActions.setTimeEntriesTaxableSuccess(ids));
        yield put(timesheetsActions.requestTimesheets({ showLoading: false }));
    } catch (err) {
        yield put(timesheetsActions.setTimeEntriesTaxableError(err));
        console.log(err);
    }
}

export function* setTimeEntriesApproved({ ids, approved }) {
    try {
        const params = {
            ids: ids.join(','),
            approved_status: approved,
        };

        yield call(api.setApproved, params);
        yield put(timesheetsActions.setTimeEntriesApprovedSuccess(ids));
        yield put(timesheetsActions.requestTimesheets({ showLoading: false }));
    } catch (err) {
        yield put(timesheetsActions.setTimeEntriesApprovedError(err));
        console.log(err);
    }
}

export function* setTimeEntriesLocked({ ids }) {
    try {
        const params = {
            ids: ids.join(','),
            locked_status: "Yes",
        };

        yield call(api.setLocked, params);
        yield put(timesheetsActions.setTimeEntriesLockedSuccess(ids));
        yield put(timesheetsActions.requestTimesheets({ showLoading: false }));
    } catch (err) {
        yield put(timesheetsActions.setTimeEntriesLockedError(err));
        console.log(err);
    }
}

function requiredUpdatesForEntry(entry, { mode }) {
    const updates = {
        add: [],
        update: [],
        remove: [],
        removedEntryIds: [],
    };

    const { entryType } = entry;
    const data = formDataForEntry(entry);

    if (entryType === 'day') {
        const { duration, id } = entry;

        const hasEmptyDuration = isEmptyDuration(duration);
        const hasId = mode === 'edit' && typeof id !== 'undefined';

        // we don't need to add entries with empty duration
        if (hasEmptyDuration && !hasId) {
            return updates;
        }

        // existing entries that have been changed to empty duration will be deleted
        if (hasEmptyDuration && hasId) {
            updates.remove.push(call(api.deleteTimeEntry, { id }));
            updates.removedEntryIds = [...updates.removedEntryIds, id];
            return updates;
        }

        if (mode === 'add' || mode === 'duplicate') {
            if ( mode === 'duplicate' && typeof entry.duplicate_time_entry_id !== 'undefined') {
                data['TimeEntry[duplicate_time_entry_id]'] = entry.duplicate_time_entry_id;
            }
            updates.add.push(call(api.addTimeEntry, data));
        }

        if (mode === 'edit') {
            updates.update.push(call(api.editTimeEntry, data));
        }
    } else {
        _.forEach(entry.dates, (date, day) => {
            const singleEntryData = {};
            const duration = entry.durations[day];
            const id = entry.grouped_time_ids[day];
            const hasId = mode === 'edit' && (typeof id !== 'undefined' && id.length !== 0);

            const hasEmptyDuration = isEmptyDuration(duration);
            const hasZeroDuration = isZeroDuration(duration);

            // we don't need to add zero-duration entries
            if (hasEmptyDuration && !hasId) {
                return;
            }

            // existing entries that have been changed to zero duration will be deleted
            if (hasEmptyDuration && hasId) {
                id.forEach(entryId => {
                    updates.remove.push(call(api.deleteTimeEntry, { id: entryId }));
                });
                updates.removedEntryIds = [...updates.removedEntryIds, ...id];
                return;
            }

            singleEntryData['TimeEntry[duration]'] = hasZeroDuration ? '0' : duration;
            singleEntryData['TimeEntry[date]'] = date;

            // There are three cases to consider, depending on how
            // many grouped ids we have for each day:
            //
            // 1) single id - use the id from the array and edit/add the entry
            // 2) multiple ids - delete the existing entries for this day
            //    and replace them with a new one, but only if the overall
            //    duration value has changed for this day. Otherwise, do a regular update
            // 3) no id - edit/add the entry without setting an id

            if (hasId && id.length === 1) {
                singleEntryData['TimeEntry[id]'] = id[0];
                const payload = { ...data, ...singleEntryData };
                
                if (mode === 'edit') {
                    updates.update.push(call(api.editTimeEntry, payload));
                }
                return;
            } else if (hasId && id.length > 1) {
                if (
                    entry.originalDurations &&
                    parseFloat(entry.originalDurations[day]) === parseFloat(entry.durations[day])
                ) {
                    // Duration hasn't changed, so there's no need to replace these entries
                    id.forEach(entryId => {
                        const payload = {
                            ...data,
                            ...singleEntryData,
                            'TimeEntry[id]': entryId,
                            'TimeEntry[duration]': entry.entriesInGroupById[entryId].duration,
                        };
                        
                        if (mode === 'edit') {
                            updates.update.push(call(api.editTimeEntry, payload));
                        }
                    });
                    return;
                }

                // Duration has changed. The old entries will be deleted and a new
                // entry with the updated duration will take their place
                id.forEach(entryId => {
                    updates.remove.push(call(api.deleteTimeEntry, { id: entryId }));
                });
                updates.removedEntryIds = [...updates.removedEntryIds, ...id];

                // add/edit replacement entry
                const payload = { ...data, ...singleEntryData };
                if (mode === 'add' || mode === 'duplicate') {
                    updates.add.push(call(api.addTimeEntry, payload));
                }
                if (mode === 'edit') {
                    updates.update.push(call(api.editTimeEntry, payload));
                }
                return;
            } else {
                if ( mode === 'duplicate' && id.length > 1) {
                    data['TimeEntry[duplicate_time_entry_id]'] = id[id.length-1];
                }
                else if( mode === 'duplicate' && id.length === 1){
                    data['TimeEntry[duplicate_time_entry_id]'] = id[0];
                }
                const payload = { ...data, ...singleEntryData };
                updates.add.push(call(api.addTimeEntry, payload));
                return;
            }
        });
    }

    return updates;
}

export function* addTimeEntry({ entry }) {
    try {
        yield put(timesheetsActions.setIsSaving(true));

        const updates = requiredUpdatesForEntry(entry, { mode: 'add' });
        const newEntries = yield all(updates.add);

        yield put(timesheetsActions.addTimeEntrySuccess({ newEntries }));

        yield put(timesheetsActions.selectTimeEntryHash(null));
        yield put(timesheetsActions.setIsSaving(false));

        const modalState = yield select(selectModalState);
        if (modalState.isTimerOpen) {
            // assume the add call is coming from within the timer
            yield put(timerActions.resetTimer());
            yield put(timerActions.closeTimer());
        } else if (modalState.isNewEntryModalOpen) {
            yield put(timesheetsActions.setIsNewEntryModalOpen(false));
            yield put(timesheetsActions.resetTimesheetForm());
        } else if (modalState.isEditEntryModalOpen) {
            yield put(timesheetsActions.setIsEditEntryModalOpen(false));
            yield put(timesheetsActions.resetTimesheetForm());
        } else {
            yield put(timesheetsActions.resetTimesheetForm());
        }
        yield put(timesheetsActions.requestTimesheets({ showLoading: false }));
    } catch (err) {
        yield put(timesheetsActions.setIsSaving(false));
        yield put(timesheetsActions.addTimeEntryError(err));
        console.log(err);
    }
}

export function* updateTimeEntry({ entry }) {
    try {
        yield put(timesheetsActions.setIsSaving(true));

        const updates = requiredUpdatesForEntry(entry, { mode: 'edit' });
        const newEntries = yield all(updates.add);
        const updatedEntries = yield all(updates.update);
        yield all(updates.remove);

        yield put(
            timesheetsActions.updateTimeEntrySuccess({
                newEntries,
                updatedEntries,
                removedEntryIds: updates.removedEntryIds,
            })
        );
        yield put(timesheetsActions.selectTimeEntryHash(null));
        yield put(timesheetsActions.setIsSaving(false));

        const modalState = yield select(selectModalState);
        if (modalState.isTimerOpen) {
            // assume the add call is coming from within the timer
            yield put(timerActions.resetTimer());
            yield put(timerActions.closeTimer());
        } else if (modalState.isNewEntryModalOpen) {
            yield put(timesheetsActions.setIsNewEntryModalOpen(false));
            yield put(timesheetsActions.resetTimesheetForm());
        } else if (modalState.isEditEntryModalOpen) {
            yield put(timesheetsActions.setIsEditEntryModalOpen(false));
            yield put(timesheetsActions.resetTimesheetForm());
        } else {
            yield put(timesheetsActions.resetTimesheetForm());
        }
        yield put(timesheetsActions.requestTimesheets({ showLoading: false }));
    } catch (err) {
        yield put(timesheetsActions.setIsSaving(false));
        yield put(timesheetsActions.updateTimeEntryError(err));
        console.log(err);
    }
}

export function* duplicateTimeEntry({ entry }) {
    try {
        yield put(timesheetsActions.setIsSaving(true));

        const updates = requiredUpdatesForEntry(entry, { mode: 'duplicate' });
        const newEntries = yield all(updates.add);

        yield put(
            timesheetsActions.duplicateTimeEntrySuccess({
                newEntries,
            })
        );
        yield put(timesheetsActions.selectTimeEntryHash(null));
        yield put(timesheetsActions.setIsSaving(false));

        const modalState = yield select(selectModalState);
        if (modalState.isTimerOpen) {
            // assume the add call is coming from within the timer
            yield put(timerActions.resetTimer());
            yield put(timerActions.closeTimer());
        } else if (modalState.isNewEntryModalOpen) {
            yield put(timesheetsActions.setIsNewEntryModalOpen(false));
            yield put(timesheetsActions.resetTimesheetForm());
        } else if (modalState.isEditEntryModalOpen) {
            yield put(timesheetsActions.setIsEditEntryModalOpen(false));
            yield put(timesheetsActions.resetTimesheetForm());
        } else {
            yield put(timesheetsActions.resetTimesheetForm());
        }

        yield put(timesheetsActions.requestTimesheets({ showLoading: false }));
    } catch (err) {
        yield put(timesheetsActions.setIsSaving(false));
        yield put(timesheetsActions.duplicateTimeEntryError(err));
        console.log(err);
    }
}

export function* showSuccessAlertForNewEntry() {
    yield put(uiActions.showToast('Saved', 'positive'));
}

export function* showSuccessAlertForUpdatedEntry() {
    yield put(uiActions.showToast('Saved', 'positive'));
}

export function* showSuccessAlertForDuplicatedEntry() {
    yield put(uiActions.showToast('Saved', 'positive'));
}

export function* showErrorAlertForEntry() {
    yield put(uiActions.showToast('Error saving entry', 'negative'));
}

export function* setDefaultsForNewTimeEntries() {
    const defaults = yield select(selectDefaultsForNewTimeEntries);
    if ('employeeId' in defaults && defaults.employeeId !== '0') {
        yield put(resourceActions.requestPayrollItemsForEmployee(defaults.employeeId));
    }
    yield put(timesheetsActions.setDefaultsForNewTimeEntries(defaults));
}

export function* getPayrollItemsForEmployee({ payload: employeeId }) {
    const shouldShowPayrollItems = yield select(accountSelectors.selectShouldShowPayrollItems);
    if (!shouldShowPayrollItems) {
        return;
    }

    yield put(resourceActions.requestPayrollItemsForEmployee(employeeId));
}

export function* setDefaultPayrollItemForEmployee({ payload: employeeId }) {
    const employee = yield select(accountSelectors.selectEmployeeById, employeeId);

    if (employee.use_time_for_paychecks !== 'UseTimeData') {
        return;
    }

    const defaultPayrollItemId = employee ? employee.default_payroll_item_id : '0';

    const newEmployeeId = yield select(({ timesheetsStore }) => timesheetsStore.employeeId);
    const editEmployeeId = yield select(({ timesheetsStore }) => timesheetsStore.editEmployeeId);
    const timerEmployeeId = yield select(({ timerStore }) => timerStore.employeeId);

    if (newEmployeeId === employeeId) {
        yield put(timesheetsActions.setPayrollItemId(defaultPayrollItemId));
    }

    if (editEmployeeId === employeeId) {
        yield put(timesheetsActions.setEditPayrollItemId(defaultPayrollItemId));
    }

    if (timerEmployeeId === employeeId) {
        yield put(timerActions.setPayrollItemId(defaultPayrollItemId));
    }
}

export function* getPayrollItemsForEditedEntry({ payload: { employee_id: employeeId } }) {
    const shouldShowPayrollItems = yield select(accountSelectors.selectShouldShowPayrollItems);
    if (!shouldShowPayrollItems) {
        return;
    }

    yield put(resourceActions.requestPayrollItemsForEmployee(employeeId));
}

export function* ensureFiltersMatchNewAndUpdatedEntries({ newEntries, updatedEntries }) {
    // ensure new entries match current filter
    const filterState = yield select(selectFilterState);
    const entries = [...(newEntries || []), ...(updatedEntries || [])];
    if (!entries.every(entry => filterStateMatchesEntry(filterState, entry))) {
        const newFilterState = newFilterStateMatchingEntries(filterState, newEntries);
        yield put(timesheetsActions.setFilterState(newFilterState));
    }
}

export default function* root() {
    yield all([
        takeLatest(['REQUEST_TIMESHEETS'], getTimesheets),
        takeLatest(['SET_EDIT_TIMESHEET_STORE'], setEditStartOfWeek),
        takeLatest(['DELETE_TIME_ENTRIES'], deleteTimeEntries),
        takeLatest(['SET_TIME_ENTRIES_BILLABLE'], setTimeEntriesBillable),
        takeLatest(['SET_TIME_ENTRIES_TAXABLE'], setTimeEntriesTaxable),
        takeLatest(['SET_TIME_ENTRIES_APPROVED'], setTimeEntriesApproved),
        takeLatest(['SET_TIME_ENTRIES_LOCKED'], setTimeEntriesLocked),
        takeLatest(['ADD_TIME_ENTRY'], addTimeEntry),
        takeLatest(['UPDATE_TIME_ENTRY'], updateTimeEntry),
        takeLatest(['DUPLICATE_TIME_ENTRY'], duplicateTimeEntry),
        takeLatest(['ADD_TIME_ENTRY_SUCCESS'], showSuccessAlertForNewEntry),
        takeLatest(['UPDATE_TIME_ENTRY_SUCCESS'], showSuccessAlertForUpdatedEntry),
        takeLatest(['DUPLICATE_TIME_ENTRY_SUCCESS'], showSuccessAlertForDuplicatedEntry),
        takeLatest(
            ['ADD_TIME_ENTRY_ERROR', 'UPDATE_TIME_ENTRY_ERROR', 'DUPLICATE_TIME_ENTRY_ERROR'],
            showErrorAlertForEntry
        ),
        takeLatest(['RESOURCES_LOADED'], setDefaultsForNewTimeEntries),
        takeLatest(
            [
                'SET_TIMESHEETS_EMPLOYEE_ID',
                'SET_TIMESHEETS_EDIT_EMPLOYEE_ID',
                'SET_TIMER_EMPLOYEE_ID',
            ],
            getPayrollItemsForEmployee
        ),
        takeLatest(
            [
                'SET_TIMESHEETS_EMPLOYEE_ID',
                'SET_TIMESHEETS_EDIT_EMPLOYEE_ID',
                'SET_TIMER_EMPLOYEE_ID',
            ],
            setDefaultPayrollItemForEmployee
        ),
        takeLatest(['SET_EDIT_TIMESHEET_STORE'], getPayrollItemsForEditedEntry),
        takeLatest(
            ['ADD_TIME_ENTRY_SUCCESS', 'UPDATE_TIME_ENTRY_SUCCESS', 'DUPLICATE_TIME_ENTRY_SUCCESS'],
            ensureFiltersMatchNewAndUpdatedEntries
        ),
    ]);
}
