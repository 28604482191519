import _ from 'lodash';
import { accountUserFields } from '../config/Fields';
import { KILOMETERS } from '../config/Constants';
import { kilometersToMiles } from './CommonFunctions';

/**
 * Returns an AccountUser object for api calls
 * @param {Object} user holds all accountUser settings
 */
export const formDataForUser = user => {
    const data = {};
    _.forEach(_.pick(user, accountUserFields), (value, key) => {
        switch (key) {
            case 'employee_id': {
                if (String(value).includes('_v')) {
                    data['AccountUser[employee_id]'] = value.replace('_v', '');
                    data['AccountUser[type]'] = 'Vendor';
                } else {
                    data['AccountUser[employee_id]'] = value;
                    data['AccountUser[type]'] = 'Employee';
                }
                break;
            }
            case 'password': {
                if (value.toString().replace(/\s+/, '').length > 0) {
                    data['AccountUser[password]'] = value;
                }
                break;
            }
            case 'reimbursement_rate': {
                data['AccountUser[reimbursement_rate]'] = value;
                if (!_.isNil(value) && user.preferred_distance_units === KILOMETERS) {
                    // convert back to rate per mile
                    data['AccountUser[reimbursement_rate]'] =
                        parseFloat(value) * kilometersToMiles(1);
                }
                break;
            }
            case 'type': {
                break;
            }
            default: {
                data[`AccountUser[${key}]`] = value;
            }
        }
    });
    return data;
};
