import React from 'react';
import Icon from 'react-icons-kit';
import { ic_done_all as done } from 'react-icons-kit/md/ic_done_all';

const EmailSentSuccess = () => (
    <div className="infoPage">
        <Icon size={72} icon={done} className="doneIcon" />
        <h1>Email Sent!</h1>
        <p>
            Thanks for your submission! Go to <a href="/">Home</a>.
        </p>
    </div>
);

export default EmailSentSuccess;
