import React from 'react';
import Icon from 'react-icons-kit';
import { trash } from 'react-icons-kit/fa/trash';

const RemoveUser = () => (
    <div>
        <div className="sectionQuestion">How Do I remove a user from my account</div>
        <div className="sectionAnswer">
            If you want to remove a user form your account, you can take the following steps:
            <ol>
                <li>The Account Manager will need to log into your account at www.minute7.com</li>
                <li>Once logged in, click on the Account tab at the top of the page</li>
                <li>Scroll down to "Users" section</li>
                <li>
                    Click on the &nbsp;
                    <Icon icon={trash} size={28} className="blueIcon" />
                    &nbsp; next to the account user you want to delete
                </li>
                <li>Click the "Delete" button on the dialogue that appears</li>
            </ol>
        </div>
    </div>
);

export default RemoveUser;
