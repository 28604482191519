import _ from 'lodash';
import React, { useState, useRef, useMemo, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import classNames from 'classnames';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import Icon from 'react-icons-kit';
import { caretDown } from 'react-icons-kit/fa/caretDown';
import { search } from 'react-icons-kit/fa/search';
import { circle_delete as circleDelete } from 'react-icons-kit/ikons/circle_delete';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';
import {
    AutoSizer,
    List,
    ArrowKeyStepper,
    CellMeasurerCache,
    CellMeasurer,
} from 'react-virtualized';
import useDismissableByClickOutside from '../../hooks/useDismissableByClickOutside';
import { bugsnagClient } from '../../bugsnag';

import styles from '../../css/Select.module.css';

const ROW_HEIGHT = 36;
const MAX_ROWS_BEFORE_SCROLL = 7;

const defaultRowRenderer = ({
    key,
    style,
    classes,
    onClick,
    canSelectMultiple,
    isSelected,
    label,
}) => {
    return (
        <div key={key} style={style} className={classes} onClick={onClick}>
            {canSelectMultiple && (
                <Icon
                    className={styles.itemIcon}
                    size={18}
                    icon={isSelected ? checkboxChecked : checkboxUnchecked}
                />
            )}
            <div className={styles.listItem}>{label}</div>
        </div>
    );
};

const defaultFooterRenderer = () => null;

const Select = ({
    onClear,
    onChange,
    className,
    value,
    withSearch,
    buttonsList,
    canSelectMultiple,
    showSelectedInToggle,
    toggleLabel,
    clearButtonLabel,
    withClearButton,
    searchPlaceholder,
    labelWhenNothingSelected,
    labelForOption,
    valueForOption,
    insetForOption,
    labelForOptionSearch,
    options,
    defaultValue,
    toggleStyle,
    withClearOption,
    clearOptionLabel,
    clearOptionValue,
    rowRenderer,
    footerRenderer,
}) => {
    const [id] = useState(uuid.v4());
    const containerRef = useRef(null);
    const searchBarRef = useRef(null);
    const dropdownContainerRef = useRef(null);
    const listRef = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useDismissableByClickOutside(containerRef);

    const filteredList = useMemo(
        () => {
            const clearOption = { id: clearOptionValue, name: clearOptionLabel };

            if (!withSearch) {
                return withClearOption ? [clearOption, ...options] : options;
            }

            const searchRE = new RegExp(_.escapeRegExp(searchText), 'i');
            const filtered = options.filter(option => {
                if (typeof option === 'undefined') {
                    bugsnagClient.notify(new Error('Undefined option passed to Select.'), {
                        metaData: {
                            props: {
                                labelWhenNothingSelected,
                                options,
                                value,
                            },
                        },
                    });
                }

                return searchRE.test(labelForOptionSearch(option));
            });
            return withClearOption ? [clearOption, ...filtered] : filtered;
        },
        [options, searchText, withSearch, withClearOption, clearOptionLabel]
    );

    const cellSizeCache = useMemo(
        () => {
            return new CellMeasurerCache({
                fixedWidth: true,
                minHeight: ROW_HEIGHT,
                defaultHeight: ROW_HEIGHT,
            });
        },
        [filteredList]
    );

    let currentlySelected = _.isArray(value) ? value : [value];
    currentlySelected = currentlySelected.filter(v => !_.isNil(v));
    const hasSelectedItems = currentlySelected.length > 0;
    const hasDefaultValue = typeof defaultValue !== 'undefined';
    const hasSelectedDefaultValue =
        hasDefaultValue && _.isEqual(currentlySelected, _.flatten([defaultValue]));
    const hasSelectedClearOption =
        withClearOption && _.isEqual(currentlySelected, [clearOptionValue]);

    const currentlySelectedLabel = useMemo(
        () => {
            const labels = [];
            options.forEach(option => {
                if (_.includes(currentlySelected, valueForOption(option))) {
                    labels.push(labelForOption(option));
                }
            });
            return labels.length ? labels.join(', ') : labelWhenNothingSelected;
        },
        [value, options]
    );

    const selectedItemRowIndices = useMemo(
        () => {
            const indices = [];
            filteredList.forEach((option, i) => {
                if (_.includes(currentlySelected, valueForOption(option))) {
                    indices.push(i);
                }
            });
            return indices;
        },
        [value, filteredList]
    );

    const focusedRowDefault =
        hasSelectedItems &&
            selectedItemRowIndices.length > 0 &&
            !(hasSelectedDefaultValue || hasSelectedClearOption)
            ? selectedItemRowIndices[0]
            : 0;

    const [focusedRow, setFocusedRow] = useState(focusedRowDefault);

    const rowCount = filteredList.length;
    const shouldAutoManageHeight = rowCount > MAX_ROWS_BEFORE_SCROLL;
    const containerHeight = shouldAutoManageHeight
        ? `${MAX_ROWS_BEFORE_SCROLL * ROW_HEIGHT}px`
        : 'auto';

    const select = val => {
        const selected = value;

        if (!canSelectMultiple) {
            onChange(val);
            setIsOpen(false);
            return;
        }

        if (_.includes(selected, val)) {
            onChange(_.without(selected, val));
        } else {
            onChange([...selected, val]);
        }
    };

    const scrollToCurrentValue = () => {
        setFocusedRow(focusedRowDefault);
        if (listRef.current) {
            listRef.current.scrollToRow(focusedRowDefault);
        }
    };

    const searchList = event => {
        setSearchText(event.target.value);
        setFocusedRow(0);
    };

    const openList = () => {
        setIsOpen(true);
        scrollToCurrentValue();
    };

    const handleClear = event => {
        event.stopPropagation();

        if (typeof onClear === 'function') {
            onClear();
        } else if (defaultValue) {
            select(defaultValue);
        }
    };

    const handleClearOptionPressed = event => {
        event.stopPropagation();
        if (typeof onClear === 'function') {
            return onClear();
        }

        if (canSelectMultiple) {
            onChange([]);
        } else {
            onChange(clearOptionValue);
        }
        setIsOpen(false);
    };

    const handleScrollToChange = ({ scrollToColumn, scrollToRow }) => {
        setFocusedRow(scrollToRow);
    };

    const handleKeydown = useCallback(
        event => {
            switch (event.key) {
                case 'Enter': {
                    event.preventDefault();
                    event.stopPropagation();
                    if (filteredList.length > 0) {
                        const val = valueForOption(filteredList[focusedRow]);
                        select(val);
                    }
                    break;
                }
                case 'Escape': {
                    setIsOpen(false);
                    break;
                }
                case 'a': {
                    if (event.ctrlKey) {
                        event.preventDefault();
                        event.stopPropagation();
                        if (typeof onClear === 'function') {
                            onClear();
                        }
                    }
                    break;
                }
                case 'Tab': {
                    if (filteredList.length > 0) {
                        const val = valueForOption(filteredList[focusedRow]);
                        select(val);
                    }
                    if (event.shiftKey) {
                        // go to the previous dropdown
                        event.preventDefault();
                        const dropdowns = document.querySelectorAll('[id^=select]');
                        const index = [].findIndex.call(dropdowns, el => el.id === `select-${id}`);
                        if (index !== -1) {
                            dropdowns[index - 1 < 0 ? 0 : index - 1].focus();
                        }
                    }
                    // setIsOpen(false);
                    break;
                }
                default:
                    break;
            }
        },
        [focusedRow, filteredList, value]
    );

    const renderToggle = () => {
        let label;
        if (showSelectedInToggle) {
            label =
                hasSelectedItems || hasSelectedDefaultValue
                    ? currentlySelectedLabel
                    : labelWhenNothingSelected;
        } else {
            label = toggleLabel || labelWhenNothingSelected;
        }

        const canBeCleared = typeof onClear === 'function' || typeof defaultValue !== 'undefined';
        const shouldShowClearButton = canBeCleared && hasSelectedItems && !hasSelectedDefaultValue;

        if (toggleStyle === 'button') {
            return (
                <button
                    className={classNames({
                        [styles.buttonToggle]: true,
                        secondaryButton: true,
                        [styles.hasSelectedOptions]:
                            hasSelectedItems && !hasSelectedDefaultValue && !hasSelectedClearOption,
                    })}
                    onClick={openList}
                >
                    <div className={styles.currentValue}>{label}</div>
                    <div className={styles.toggleIcons}>
                        {shouldShowClearButton && (
                            <Icon
                                className={classNames(styles.clearIcon)}
                                size={18}
                                icon={closeIcon}
                                onClick={handleClear}
                                data-tip="Clear Selected"
                            />
                        )}
                        {!shouldShowClearButton && (
                            <Icon className={styles.caretIcon} size={15} icon={caretDown} />
                        )}
                    </div>
                </button>
            );
        }

        // assumes input style as the default
        return (
            <div
                className={classNames({
                    'form-control': true,
                    [styles.inputToggle]: true,
                    [styles.hasSelectedOptions]:
                        hasSelectedItems && !hasSelectedDefaultValue && !hasSelectedClearOption,
                })}
                onClick={openList}
            >
                <div className={styles.currentValue}>{label}</div>
                <div className={styles.toggleIcons}>
                    {shouldShowClearButton && (
                        <Icon
                            className={classNames(styles.clearIcon)}
                            size={15}
                            icon={circleDelete}
                            onClick={handleClear}
                            data-tip="Clear Selected"
                        />
                    )}
                    <Icon className={styles.caretIcon} size={15} icon={caretDown} />
                </div>
            </div>
        );
    };

    const renderRow = ({ index, key, style, scrollToRow, parent }) => {
        const label = labelForOption(filteredList[index]);
        const val = valueForOption(filteredList[index]);
        const inset = insetForOption(filteredList[index]);
        const isClearOption = withClearOption && val === clearOptionValue;
        const isSelected = isClearOption ? !hasSelectedItems : _.includes(currentlySelected, val);
        const classes = classNames({
            [styles.listEntry]: true,
            [styles.focusedEntry]: index === scrollToRow,
            [styles.selectedEntry]: !canSelectMultiple && isSelected,
            [styles[`entryInset${inset}`]]: inset ? true : false,
        });

        const onClick = isClearOption ? handleClearOptionPressed : () => select(val);
        const row = rowRenderer({
            key,
            style,
            classes,
            onClick,
            label,
            val,
            isClearOption,
            isSelected,
            canSelectMultiple,
            setIsOpen,
        });
        return (
            <CellMeasurer
                key={key}
                rowIndex={index}
                columnIndex={0}
                parent={parent}
                cache={cellSizeCache}
            >
                {row}
            </CellMeasurer>
        );
    };


    const renderNoRows = () => {
        return <span className={styles.noMatches}>No Results</span>;
    };

    useEffect(
        () => {
            if (isOpen) {
                if (searchBarRef.current) {
                    searchBarRef.current.focus();
                    searchBarRef.current.select();
                } else if (dropdownContainerRef.current) {
                    dropdownContainerRef.current.focus();
                }
            }
        },
        [isOpen]
    );

    useEffect(
        () => {
            if (isOpen) {
                document.addEventListener('keydown', handleKeydown, false);
            }

            return () => {
                document.removeEventListener('keydown', handleKeydown, false);
            };
        },
        [isOpen, handleKeydown]
    );

    return (
        <div
            id={`select-${id}`}
            tabIndex="0"
            onFocus={event => {
                if (!isOpen && event.target.id === `select-${id}`) {
                    openList();
                }
            }}
            className={classNames(className, styles.container)}
            ref={containerRef}
        >
            {renderToggle()}
            <ArrowKeyStepper
                onScrollToChange={handleScrollToChange}
                isControlled={true}
                mode="cells"
                columnCount={1}
                rowCount={rowCount}
                scrollToRow={focusedRow}
            >
                {({ onSectionRendered, scrollToRow }) => (
                    <div
                        hidden={!isOpen}
                        className={styles.dropdownContainer}
                        ref={dropdownContainerRef}
                        tabIndex="-1"
                    >
                        {withSearch && (
                            <InputGroup className={styles.searchBar}>
                                <input
                                    value={searchText}
                                    ref={searchBarRef}
                                    onChange={searchList}
                                    className={styles.searchInput}
                                    placeholder={searchPlaceholder}
                                />
                                <InputGroupAddon addonType="append">
                                    <Icon
                                        className="inputAddonAppendIcon"
                                        size={15}
                                        icon={search}
                                    />
                                </InputGroupAddon>
                            </InputGroup>
                        )}
                        <div style={{ height: containerHeight }}>
                            <AutoSizer disableHeight={!shouldAutoManageHeight}>
                                {({ height, width }) => (
                                    <List
                                        height={
                                            shouldAutoManageHeight
                                                ? height
                                                : Math.max(ROW_HEIGHT, rowCount * ROW_HEIGHT)
                                        }
                                        rowCount={rowCount}
                                        deferredMeasurementCache={cellSizeCache}
                                        rowHeight={cellSizeCache.rowHeight}
                                        rowRenderer={({ index, key, style, parent }) => {
                                            return renderRow({
                                                index,
                                                key,
                                                style,
                                                scrollToRow,
                                                parent,
                                            });
                                        }}
                                        width={width}
                                        noRowsRenderer={renderNoRows}
                                        onRowsRendered={({ startIndex, stopIndex }) => {
                                            onSectionRendered({
                                                rowStartIndex: startIndex,
                                                rowStopIndex: stopIndex,
                                            });
                                        }}
                                        scrollToIndex={scrollToRow}
                                        value={value}
                                        searchText={searchText}
                                        className={styles.listContainer}
                                        ref={listRef}
                                    />
                                )}
                            </AutoSizer>
                        </div>
                        {buttonsList.length > 0 && (
                            buttonsList.map(key => (
                                key.withButton && (
                                    <button className={classNames(styles.DropDownButton, 'secondaryButton')} onClick={() => key.route_path()} >
                                        {key.buttonLablel} </button>)
                            ))
                        )}

                        {withClearButton && (
                            <div className={styles.clearButtonContainer}>
                                <button
                                    onClick={handleClear}
                                    className={classNames(styles.clearButton, 'secondaryButton')}
                                >
                                    {clearButtonLabel}
                                </button>
                            </div>
                        )}
                        {footerRenderer({ setIsOpen })}
                    </div>
                )}
            </ArrowKeyStepper>
        </div>
    );
};

Select.propTypes = {
    toggleStyle: PropTypes.oneOf(['input', 'button']),
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    withSearch: PropTypes.bool,
    buttonsList: PropTypes.array,
    withClearButton: PropTypes.bool,
    showSelectedInToggle: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    clearButtonLabel: PropTypes.string,
    canSelectMultiple: PropTypes.bool,
    labelWhenNothingSelected: PropTypes.string,
    className: PropTypes.string,
    labelForOption: PropTypes.func,
    valueForOption: PropTypes.func,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    withClearOption: PropTypes.bool,
    clearOptionLabel: PropTypes.string,
    clearOptionValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rowRenderer: PropTypes.func,
    footerRenderer: PropTypes.func,
};

Select.defaultProps = {
    toggleStyle: 'input',
    withSearch: false,
    buttonsList: [],
    withClearButton: false,
    canSelectMultiple: false,
    showSelectedInToggle: true,
    clearButtonLabel: 'Restore Defaults',
    clearOptionValue: '0',
    withClearOption: false,
    clearOptionLabel: 'Not Selected',
    searchPlaceholder: 'Type to Search',
    className: '',
    value: [],
    labelWhenNothingSelected: 'Select',
    labelForOption: option => option.name || option.description,
    // should typically be the same as labelForOption, but could
    // contain more strings you can search over
    labelForOptionSearch: option => option.name || option.description,
    valueForOption: option => option.id,
    insetForOption: option => (_.isObject(option) && option.inset ? option.inset : 0),
    rowRenderer: defaultRowRenderer,
    footerRenderer: defaultFooterRenderer,
};

export default memo(Select);
