import React from 'react';

const NoExpenses = () => (
    <div>
        <div className="sectionQuestion">What if I don't want to track expenses?</div>
        <div className="sectionAnswer">
            You can turn off expense tracking by going to your account settings page, and setting{' '}
            <b>"Enable expense tracking for Vendors"</b> to <b>"No"</b>.
        </div>
    </div>
);

export default NoExpenses;
