import React from 'react';

const SettingUpPayroll = () => (
    <div>
        <div className="sectionQuestion">
            How do I set up an Employee to use time data to create paychecks in QuickBooks?
        </div>
        <div className="sectionAnswer">
            In order to assign payroll items to time entries created for an Employee in QuickBooks,
            the Employee must be set to "Use time data to create paychecks". You can set up an
            Employee to "Use time data to create paychecks" by doing the following:
            <ol>
                <li>In QuickBooks, open up the "Edit Employee" window for your employee.</li>
                <li>
                    In the drop-down select at the top of the "Edit Employee" window, choose
                    "Payroll and Compensation Info."
                </li>
                <li>
                    In the Payroll Info tab, about half-way down, you will see a checkbox that says
                    <b>"Use time data to create paychecks"</b>.
                    <br /> <br />
                    To enable payroll items for this Employee, make sure this box is checked, and
                    make sure you have an Earnings- Item Name and Rate entered (for example, you
                    might have an Earnings Item "Salary" with the rate "25.00"). The Item Names
                    entered here are the payroll items that will be available to assign to time
                    entries in Minute7. To turn off payroll items for this Employee, uncheck the
                    box.
                </li>
                <li>Click "OK" to close and save your changes.</li>
            </ol>
        </div>
    </div>
);

export default SettingUpPayroll;
