import React from 'react';

const CompanyLogo = () => (
    <div className="helpModal">
        <div className="sectionQuestion">
            My company logo now looks different or strange. What happened?
        </div>
        <div className="sectionAnswer">
            As part of the redesign we have a new darker header, which may cause some company logos
            to look different than they did before. If you have a lighter version of your logo, you
            may want to try uploading that into Minute7 to see if it meets your needs.
        </div>
    </div>
);

export default CompanyLogo;
