import React from 'react';
import className from 'classnames';
import styles from '../../css/Home/MobileHome.module.css';
import Icon from 'react-icons-kit';
import SecondaryHeaderBar from './SecondaryHeaderBar.js';
import { reorder as list } from 'react-icons-kit/fa/reorder';
import $ from 'jquery';

/**
 * Inherits scroll functionality from SecondaryHeaderBar
 */
export default class MobileHeaderBar extends SecondaryHeaderBar {
    constructor(props) {
        super(props);
        this.dropdown = null;
    }

    componentDidMount() {
        super.componentDidMount();
        document.addEventListener('mousedown', this.handleClick, false);
        this.removeMobileViewPort();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        document.removeEventListener('mousedown', this.handleClick, false);
        this.addMobileViewPort();
    }

    /**
     * Any component using the mobile header bar has been optimized for a mobile browser. Remove the static viewport
     */
    removeMobileViewPort = () => {
        $('meta[name=viewport]').remove();
        $('head').append(
            '<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>'
        );
    };

    addMobileViewPort = () => {
        $('meta[name=viewport]').remove();
        $('head').append(
            '<meta name="viewport" content="width=1280, initial-scale=.1, shrink-to-fit=no"/>'
        );
    };

    handleClick = clickedItem => {
        if (this.dropdown === null) {
            return;
        }

        if (
            this.dropdown.contains(clickedItem.target) ||
            clickedItem.target.nodeName === 'path' ||
            clickedItem.target.nodeName === 'svg'
        ) {
            return;
        }

        this.setState({ isListOpen: false });
    };

    render() {
        return (
            <div>
                <div className={className(className, styles.headerBar)}>
                    <div className={className(className, styles.m7LogoContainer)}>
                        <img
                            srcSet="/img/sales/logo@2x.png 2x, /img/sales/logo.png 1x"
                            src="/img/sales/logo.png"
                            alt="Minute7"
                        />
                    </div>
                    <div className={className(className, styles.iconContainer)}>
                        <Icon
                            onClick={() =>
                                this.setState({
                                    isListOpen: !this.state.isListOpen,
                                })
                            }
                            className={className(className, styles.icon)}
                            icon={list}
                            size={36}
                        />
                    </div>
                </div>
                <div
                    ref={dropdown => (this.dropdown = dropdown)}
                    className={className(className, styles.dropdown)}
                    hidden={!this.state.isListOpen}
                >
                    <div className={className(className, styles.mobileLinkContainer)}>
                        <div
                            className={className(
                                className,
                                this.state.activeSection === 'home'
                                    ? [styles.mobileLink, styles.activeMobileLink]
                                    : styles.mobileLink
                            )}
                            onClick={() => this.scrollTo('home')}
                        >
                            Home
                        </div>
                        <div
                            className={className(
                                className,
                                this.state.activeSection === 'features'
                                    ? [styles.mobileLink, styles.activeMobileLink]
                                    : styles.mobileLink
                            )}
                            onClick={() => this.scrollTo('features')}
                        >
                            Features
                        </div>
                        <div
                            className={className(
                                className,
                                this.state.activeSection === 'about'
                                    ? [styles.mobileLink, styles.activeMobileLink]
                                    : styles.mobileLink
                            )}
                            onClick={() => this.scrollTo('about')}
                        >
                            About
                        </div>
                        <div
                            className={className(
                                className,
                                this.state.activeSection === 'testimonials'
                                    ? [styles.mobileLink, styles.activeMobileLink]
                                    : styles.mobileLink
                            )}
                            onClick={() => this.scrollTo('testimonials')}
                        >
                            Testimonies
                        </div>
                        <div
                            className={className(
                                className,
                                this.state.activeSection === 'pricing'
                                    ? [styles.mobileLink, styles.activeMobileLink]
                                    : styles.mobileLink
                            )}
                            onClick={() => this.scrollTo('pricing')}
                        >
                            Pricing
                        </div>
                        <div
                            className={className(
                                className,
                                this.props.history.location.pathname === '/contact'
                                    ? [styles.mobileLink, styles.activeMobileLink]
                                    : styles.mobileLink
                            )}
                            onClick={() => this.redirect('/contact')}
                        >
                            Contact Us
                        </div>
                        <div
                            className={className(
                                className,
                                this.props.history.location.pathname === '/quick_tour'
                                    ? [styles.mobileLink, styles.activeMobileLink]
                                    : styles.mobileLink
                            )}
                            onClick={() => this.redirect('/quick_tour')}
                        >
                            Quick Tour
                        </div>
                        <div
                            className={className(
                                className,
                                this.props.history.location.pathname === '/support'
                                    ? [styles.mobileLink, styles.activeMobileLink]
                                    : styles.mobileLink
                            )}
                            onClick={() => this.redirect('/support')}
                        >
                            Support
                        </div>
                        <div
                            className={className(
                                className,
                                this.props.history.location.pathname === '/login'
                                    ? [styles.mobileLink, styles.activeMobileLink]
                                    : styles.mobileLink
                            )}
                            onClick={() => this.redirect('/login')}
                        >
                            Sign In
                        </div>
                        <button
                            className="mobileFreeTrial primaryButton"
                            color="success"
                            style={{ marginLeft: '9px' }}
                            onClick={() => this.redirect('/free_trial')}
                        >
                            Free Trial
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
