import { getData, postRequestWithFormData, createQueryString } from '../utils/HelperFunctions.js';
import { openPopUpWindow } from '../utils/CommonFunctions.js';

export const getAccount = (paramsObject = {}) => {
    return getData(paramsObject, 'account/view').then(res => res);
};

export const updateAccountSettings = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'account/update', paramsObject).then(res => res);
};

export const chargeAccount = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'account/charge', paramsObject).then(res => res);
};

export const removeCompanyLogo = (paramsObject = {}) => {
    return getData(paramsObject, 'account/remove_company_logo').then(res => res);
};

export const createAccount = data => {
    return postRequestWithFormData(data, 'account/create', {}).then(res => res);
};

export const toggleSyncing = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'account/toggle_syncing', paramsObject).then(res => res);
};

export const toggleAutoSyncing = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'account/toggle_auto_syncing', paramsObject).then(
        res => res
    );
};

export const reactivateAccount = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'account/reactivate', paramsObject, {'Content-Type': 'multipart/form-data'}).then(
        res => res.Account
    );
};

export const deactivateAccount = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'account/deactivate', paramsObject, {'Content-Type': 'multipart/form-data'}).then(
        res => res.Account
    );
};

export const getPaymentHistory = paramsObject => {
    return getData(paramsObject, 'billingtransaction/view').then(res => res);
};

export const getMemo = paramsObject => {
    let url = createQueryString(paramsObject, 'billingtransaction/memo');
    // Fixes dual-screen position centering
    openPopUpWindow('Minute7 Memo', 735, 500, url);
};

export const impersonateUser = userId => {
    return getData({}, `/users/masquerade/${userId}`).then(res => res);
};

export const exitImpersonateUser = () => {
    return getData({}, '/users/masquerade/back').then(res => res);
};

export const verifyPromoCode = data => {
    return getData(data, 'promo_code/verify').then(response => response);
};
