import React, { Component } from 'react';
import $ from 'jquery';
import scrollTo from '../../../../../utils/scrollTo';

export default class WebConnector extends Component {
    componentDidMount() {
        let anchor = this.props.history.location.state
            ? this.props.history.location.state.scrollTo
            : 'webConnector';
        if ($(`#${anchor}`).length) {
            let scrollY = $(`#${anchor}`).offset().top;

            scrollTo({
                top: scrollY,
                left: 0,
                behavior: 'instant',
            });
        }
    }

    render() {
        return (
            <div className="cardContainer helpPageContainer">
                <div className="helpPage">
                    <div className="sectionQuestion" id="webConnector">
                        Syncing Minute7 with QuickBooks using the QuickBooks Web Connector
                    </div>
                    <div className="sectionAnswer">
                        <div>
                            <div>
                                QuickBooks 2006+ for Windows will allow you to synchronize
                                information with Minute7 using the QuickBooks Web Connector, a free
                                program from Intuit that allows QuickBooks to interface with web
                                applications like Minute7. If you already have the Web Connector
                                installed, you can skip to the Web Connector Minute7 setup
                                instructions or if you've already done that, you can skip to the Web
                                Connector usage instructions.
                            </div>
                            <br />
                            <div>
                                If you have a Mac, you'll need at least OS X 10.4 running PC
                                emulation software like Bootcamp or Parallels and of course
                                QuickBooks 2006+ (for Windows)
                            </div>
                            <br />
                            <div>
                                You only need to install and configure QuickBooks Web Connector
                                once!
                            </div>
                        </div>
                    </div>
                    <div className="sectionQuestion" id="installWebConnector">
                        Instructions for installing QuickBooks Web Connector
                    </div>
                    <br />
                    <div className="sectionAnswer">
                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    className="qwcImage"
                                    src="/help_images/web_connector/download_qwc.png"
                                    alt="Download and install QuickBooks Web Connector"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                <div>
                                    1. Download and install QuickBooks Web Connector From Intuit
                                </div>
                                <br />
                                <div className="helpNote">
                                    <a
                                        href="https://developer.intuit.com/docs/0200_quickbooks_desktop/0100_essentials/quickbooks_web_connector"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        alt="Open a new tab to Intuit's QuickBooks Web Connector"
                                    >
                                        Open a new tab to Intuit's QuickBooks Web Connector
                                    </a>
                                    , then click on the Download and install the QuickBooks Web
                                    Connector link. A window similar to the one pictured will
                                    appear.
                                    <br /> <br />
                                    <b>Download</b> the QuickBooks Web Connector installer
                                    application to a location on your computer (your desktop, for
                                    example) and <b>run</b> the installer by double clicking on it.
                                </div>
                            </div>
                        </div>
                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    className="qwcImage"
                                    src="/help_images/web_connector/install_qwc.png"
                                    alt="Install The QuickBooks Web Connector"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                <div>2. Install The QuickBooks Web Connector</div>
                                <br />
                                <div className="helpNote">
                                    Allow the QuickBooks Web Connector installer to complete by
                                    agreeing to its license and proceeding through its installation
                                    options.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sectionQuestion">
                        Instructions for setting up QuickBooks Web Connector to work with Minute7
                    </div>
                    <br />
                    <div className="sectionAnswer">
                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    className="qwcImage"
                                    src="/help_images/web_connector/minute7_download_qwc.jpg"
                                    alt="Download your QWC file"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                <div>1. Download Your QWC File</div>
                                <div className="helpNote">
                                    <ol>
                                        <li>
                                            In Minute7, while using an account with export
                                            permission, click on the Sync tab at the top of the
                                            screen
                                        </li>
                                        <li>Click the link labeled "Web Connector File".</li>
                                        <li>
                                            Name and Save the file to your Desktop so it's easy to
                                            find. You can delete it later.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    className="qwcImage"
                                    src="/help_images/web_connector/qwc.png"
                                    alt="Run QuickBooks Web Connector"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                <div>2. Run QuickBooks Web Connector</div>
                                <div className="helpNote">
                                    Either double-click the QWC file you just downloaded, or open
                                    QuickBooks Web Connector by:
                                    <br />
                                    <ol>
                                        <li>Going to the Start Menu</li>
                                        <li>Selecting All Programs</li>
                                        <li>Selecting QuickBooks</li>
                                        <li>And then selecting QuickBooks Web Connector</li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    className="qwcImage"
                                    src="/help_images/web_connector/locate_and_add_your_QWC.png"
                                    alt="Add Minute7 as a QuickBooks Web Connector Application"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                <div>3. Add Minute7 as a QuickBooks Web Connector Application</div>
                                <div className="helpNote">
                                    <ol>
                                        <li>
                                            In QuickBooks Web Connector, select the "Add an
                                            Application" button in the lower right corner of the
                                            window
                                        </li>
                                        <li>
                                            Select the saved QWC file from your Desktop (or where
                                            ever you saved it) and click okay
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    className="qwcImage"
                                    src="/help_images/web_connector/minute7_cert.png"
                                    alt="Allow QuickBooks Web Connector to work with Minute7"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                4. Allow QuickBooks Web Connector to work with Minute7
                                <div className="helpNote">
                                    A screen will appear with information about Minute7, including
                                    its support URL, security certificate information, etc. Make
                                    sure QuickBooks is running and open to the correct company file,
                                    then click OK to allow QuickBooks Web Connector to interact with
                                    Minute7.
                                </div>
                            </div>
                        </div>

                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    src="/help_images/web_connector/minute7_permissions.png"
                                    className="qwcImage"
                                    alt="Set permissions for Minute7 within QuickBooks"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                5. Set permissions for Minute7 within QuickBooks
                                <div className="helpNote">
                                    <ol>
                                        <li>
                                            In QuickBooks, a window will open up asking you to grant
                                            access to your QuickBooks file to Minute7. If you only
                                            want to run the sync process when QuickBooks is open,
                                            choose the radio button "Yes, whenever this QuickBooks
                                            company file is open"; if you intend to run the sync
                                            process even if QuickBooks itself is not open, you
                                            should select "Yes, always; allow access even if
                                            QuickBooks is not running".
                                        </li>

                                        <li>
                                            If you use time data to create paychecks for employees
                                            in QuickBooks, check "Allow this application to access
                                            Social Security Numbers, customer credit card
                                            information, and other personal data". Otherwise, it's
                                            not necessary to allow Minute7 to access sensitive data.
                                            Minute7 will never store sensitive data like social
                                            security numbers, customer credit card numbers, etc but
                                            QuickBooks requires that this access be in place if you
                                            have configured QuickBooks to manage payroll for your
                                            employees (otherwise, Minute7 can't communicate time
                                            entry data to QuickBooks).
                                        </li>

                                        <li>
                                            Click "Continue" to save your preferences, and "Done" in
                                            the summary window that pops up to confirm your
                                            settings.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    src="/help_images/web_connector/minute7_qbd_sync.png"
                                    className="qwcImage"
                                    alt="Synchronize Minute7 with QuickBooks"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                6. Add your Minute7 password, select Minute7, then click "Update
                                Selected" to synchronize with QuickBooks
                                <div className="helpNote">
                                    <ol>
                                        <li>
                                            Back in the Web Connector, under "Password", enter the
                                            password you use to log in to Minute7 with the username
                                            that downloaded the Web Connect file (likely your
                                            manager account password).
                                        </li>

                                        <li>
                                            Check the box next to the Minute7 application name, and
                                            click "Update Selected" to run the update. Allow the
                                            syncing process to run through.
                                        </li>
                                        <li>
                                            The next time you are ready to export your time entries
                                            and sync Minute7 with your QuickBooks file, simply open
                                            the Web Connector, check off Minute7, and click "Update
                                            Selected"— and you're set!
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sectionQuestion" id="minute7Syncing">
                        Instructions for Syncing Minute7 using QuickBooks Web Connector
                    </div>
                    <br />
                    <div className="sectionAnswer">
                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    src="/help_images/web_connector/minute7_sync_step_1.png"
                                    className="qwcImage"
                                    alt="Synchronize Minute7 with QuickBooks"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                <div>
                                    Select Minute7, then click Update Selected to synchronize with
                                    QuickBooks
                                </div>
                                <div className="helpNote">
                                    <ol>
                                        <li>
                                            Check the box next to the Minute7 application name, and
                                            click "Update Selected" to run the update. Allow the
                                            syncing process to run through.
                                        </li>
                                        <li>
                                            Entries marked as disapproved will not be exported for
                                            inclusion in your QuickBooks file.
                                        </li>
                                        <li>
                                            By default, Minute7 will not export time entries that
                                            have been modified (or created) in the past 8 hours. We
                                            recommend keeping this setting, so that time entries are
                                            not edited after being synchronized to QuickBooks. If
                                            you wish to change this setting, you can do so on your
                                            Account Settings page.
                                        </li>
                                        <li>
                                            If changes are made to a time entry after it has been
                                            synced to QuickBooks, these changes will not propagate
                                            from Minute7 to QuickBooks on subsequent syncs. Intuit
                                            has only added support for updating time entries that
                                            have already been synced to QuickBooks in very recent
                                            versions of QuickBooks, and Minute7 targets
                                            compatibility with a broader range of QuickBooks
                                            versions than can support updating time entries after
                                            they have been synced.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="helpRow">
                            <div className="helpRowColumnOne">
                                <img
                                    src="/help_images/web_connector/minute7_sync_step_2.png"
                                    className="qwcImage"
                                    alt="Setup Minute7 to sync automatically"
                                />
                            </div>
                            <div className="helpRowColumnTwo">
                                <b>Optional:</b> Setup Minute7 to sync automatically
                                <br /> <br />
                                By default, Minute7 will only sync with your QuickBooks file when
                                you tell it to in the Web Connector. However, you can set Minute7 to
                                automatically sync at any time interval you choose in the QuickBooks
                                Web Connector.
                                <br /> <br />
                                <ol>
                                    <li>
                                        When the Web Connector is open, check the "Auto-Run" box
                                        that appears beside the Minute7 application description.
                                    </li>
                                    <li>
                                        Then, enter the number of minutes you want the Web Connector
                                        to wait in between updates. The default in QuickBooks Web
                                        Connector is 60 minutes, but we recommend automatically
                                        syncing at most every two days. An ideal automatic sync
                                        interval for many companies is one week, while some
                                        companies prefer to sync automatically only once per month.
                                    </li>
                                    <li>
                                        For your reference, one week is 10,080 minutes, and 30 days
                                        is 43,200 minutes.
                                    </li>
                                    <li>
                                        <b>
                                            Note: If you set the Minute7 sync process to Auto-Run,
                                            both the Web Connector and QuickBooks must be open for
                                            the sync to complete.
                                        </b>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="sectionQuestion" id="qwcVersion">
                        What version of QuickBooks works with the web connector?
                    </div>
                    <div className="sectionAnswer">
                        The web connector works with pretty much all versions of QuickBooks Desktop.
                        Here is a &nbsp;
                        <a
                            href="https://developer.intuit.com/docs/0200_quickbooks_desktop/0400_tools/web_connector"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            current list from Intuit
                        </a>
                        .
                    </div>
                </div>
            </div>
        );
    }
}
