export const setAccountStore = payload => ({
    type: 'SET_ACCOUNT_STORE',
    payload,
});

export const toggleSyncing = () => ({
    type: 'ACCOUNT_TOGGLE_SYNCING',
});

export const toggleSyncingSuccess = isEnabled => ({
    type: 'ACCOUNT_TOGGLE_SYNCING_SUCCESS',
    isEnabled,
});

export const toggleSyncingError = error => ({
    type: 'ACCOUNT_TOGGLE_SYNCING_ERROR',
    error,
});

export const toggleAutoSyncing = () => ({
    type: 'ACCOUNT_TOGGLE_AUTO_SYNCING',
});

export const toggleAutoSyncingSuccess = isEnabled => ({
    type: 'ACCOUNT_TOGGLE_AUTO_SYNCING_SUCCESS',
    isEnabled,
});

export const toggleAutoSyncingError = error => ({
    type: 'ACCOUNT_TOGGLE_AUTO_SYNCING_ERROR',
    error,
});

export const reactivateAccount = () => ({
    type: 'REACTIVATE_ACCOUNT',
});

export const reactivateAccountSuccess = account => ({
    type: 'REACTIVATE_ACCOUNT_SUCCESS',
    account,
});

export const reactivateAccountError = error => ({
    type: 'REACTIVATE_ACCOUNT_ERROR',
    error,
});

export const deactivateAccount = () => ({
    type: 'DEACTIVATE_ACCOUNT',
});

export const deactivateAccountSuccess = account => ({
    type: 'DEACTIVATE_ACCOUNT_SUCCESS',
    account,
});

export const deactivateAccountError = error => ({
    type: 'DEACTIVATE_ACCOUNT_ERROR',
    error,
});

export const impersonateUser = userId => ({
    type: 'IMPERSONATE_USER',
    userId,
});

export const exitImpersonateUser = () => ({
    type: 'EXIT_IMPERSONATE_USER',
});

export const impersonateStoreClear = () => ({
    type: 'IMPERSONATE_STORE_CLEAR',
});
