import React, { Component } from 'react';
import DefaultHome from './components/DefaultHome.js';
import MobileHome from './components/MobileHome.js';
import { isMobile } from 'react-device-detect';
import testimonies from './components/Testimonies.js';
import '../../css/Home/animate.min.css';
import '../../css/Home/font-awesome.min.css';
import '../../css/Home/responsive.css';
import '../../css/Home/style.css';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonyPage: 0,
        };
    }

    changeTestimonyInterval = () => {
        if (this.state.testimonyPage !== 2) {
            this.setState({ testimonyPage: this.state.testimonyPage + 1 });
        } else {
            this.setState({ testimonyPage: 0 });
        }
    };

    testimonyChangeOnClick = newTestimonyPage => {
        clearInterval(this.testimonyInterval);
        this.setState({ testimonyPage: newTestimonyPage });
        this.testimonyInterval = setInterval(() => this.changeTestimonyInterval(), 5000);
    };

    componentDidMount() {
        this.testimonyInterval = setInterval(() => this.changeTestimonyInterval(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.testimonyInterval);
    }

    render() {
        if (!isMobile) {
            return (
                <DefaultHome
                    history={this.props.history}
                    testimonies={testimonies}
                    testimonyPage={this.state.testimonyPage}
                    testimonyChangeOnClick={this.testimonyChangeOnClick}
                />
            );
        } else {
            return (
                <MobileHome
                    history={this.props.history}
                    testimonies={testimonies}
                    testimonyPage={this.state.testimonyPage}
                    testimonyChangeOnClick={this.testimonyChangeOnClick}
                />
            );
        }
    }
}
