import _ from 'lodash';
import { createSelector } from 'reselect';

const nameSort = e => (e.last_name || e.first_name || e.name).toLowerCase();
const fullNameSort = e => (e.name).toLowerCase();

export const selectUsers = ({ accountStore }) => accountStore.accountUsers;

export const selectUsersSorted = createSelector(
    [selectUsers],
    users => _.sortBy(users, nameSort)
);

export const selectUsersIds = createSelector(
    [selectUsers],
    users => users.map(user=> user.employee_id)
);

export const selectWeekOffset = ({ accountUserStore }) => accountUserStore.week_start_offset;
export const selectCanEditEmployees = ({ employeeStore }) => employeeStore.canEditEmployees;
export const selectCanViewEmployees = ({ employeeStore }) => employeeStore.canViewEmployees;
export const selectCanViewVendors = ({ vendorStore }) => vendorStore.canViewVendors;
export const selectCanEditVendors = ({ vendorStore }) => vendorStore.canEditVendors;
export const selectQbType = ({ accountStore }) => accountStore.qb_type;
export const selectAccount = ({ accountStore }) => accountStore;
export const selectAccountUsers = ({ accountStore }) => accountStore.accountUsers;
export const selectCurrentUser = ({ accountUserStore }) => accountUserStore;
export const selectPreventEditForSyncedEntries = ({ accountStore }) =>
    accountStore.prevent_edit_for_synced_entries === 'Yes';
export const prevent_edit_for_locked_entries = ({ accountStore }) =>
    accountStore.prevent_edit_for_locked_entries === 'Yes';
export const selectShouldShowPayrollItems = ({ accountStore }) =>
    accountStore.show_payroll === 'Yes';
export const selectShouldShowQbClasses = ({ accountStore }) =>
    accountStore.show_qb_classes === 'Yes';

export const selectCanViewEmployeesSorted = createSelector(
    [selectCanViewEmployees],
    employees => {
        const sortedByLastName = _.sortBy(employees, nameSort);
        const [ofTypeVendor, ofTypeEmployee] = _.partition(sortedByLastName, e =>
            e.id.includes('_v')
        );
        return ofTypeEmployee.concat(ofTypeVendor);
    }
);

const isEmployeeActive = employee => employee.hidden === 'No' && !employee.name.match(/^\*/);

export const selectCanViewActiveEmployees = createSelector(
    [selectCanViewEmployeesSorted],
    employees => employees.filter(isEmployeeActive)
);

export const selectCanViewEmployeesForReports = createSelector(
    [selectCanViewEmployeesSorted],
    employees => employees.filter(employee => employee.hidden === 'No')
);

export const selectCanEditEmployeesSorted = createSelector(
    [selectCanEditEmployees],
    employees => {
        const sortedByLastName = _.sortBy(employees, nameSort);
        const [ofTypeVendor, ofTypeEmployee] = _.partition(sortedByLastName, e =>
            e.id.includes('_v')
        );
        return ofTypeEmployee.concat(ofTypeVendor);
    }
);

export const selectEmployeeIdForCurrentlyEditedEntry = ({ timesheetsStore }) => {
    if (
        timesheetsStore.selectedTimeEntry !== null ||
        timesheetsStore.selectedTimeEntryHash !== null
    ) {
        return timesheetsStore.editEmployeeId;
    }

    return '0';
};

// Note: inactive employees will have an asterisk (*) in front of their
// names, so we'll filter them out here
export const selectCanEditActiveEmployees = createSelector(
    [selectCanEditEmployeesSorted],
    employees => employees.filter(e => e.hidden === 'No' && !e.name.match(/^\*/))
);

export const selectCanEditActiveEmployeesNoAssosiation = createSelector(
    [selectCanEditEmployeesSorted, selectUsersIds],
    (employees, userIds )=> _.sortBy(employees.filter(e => e.hidden === 'No' && !e.name.match(/^\*/) && !userIds.includes(e.id)), fullNameSort)
);

export const selectCanEditActiveEmployeesForEditing = createSelector(
    [selectCanEditEmployeesSorted, selectEmployeeIdForCurrentlyEditedEntry],
    (canEditEmployees, employeeIdForCurrentlyEditedEntry) => {
        return canEditEmployees
            .filter(e => e.hidden === 'No')
            .filter(e => employeeIdForCurrentlyEditedEntry === e.id || !e.name.match(/^\*/));
    }
);

export const selectCanViewEmployeesById = createSelector(
    [selectCanViewActiveEmployees],
    canViewEmployees =>
        canViewEmployees.reduce((prev, current) => {
            prev[current.id] = current;
            return prev;
        }, {})
);

export const selectCanEditEmployeesById = createSelector(
    [selectCanEditActiveEmployees],
    canEditEmployees =>
        canEditEmployees.reduce((prev, current) => {
            prev[current.id] = current;
            return prev;
        }, {})
);

const selectEmployeeId = (state, employeeId) => employeeId;

export const selectEmployeeById = createSelector(
    [selectCanEditEmployeesById, selectEmployeeId],
    (canEditEmployeesById, employeeId) => {
        return canEditEmployeesById[employeeId];
    }
);

export const selectDefaultPayrollItemIdForEmployee = createSelector(
    [selectCanEditEmployeesById, selectEmployeeId],
    (canEditEmployeesById, employeeId) => {
        const employee = canEditEmployeesById[employeeId];
        return employee ? employee.default_payroll_item_id : '0';
    }
);

export const selectCanViewActiveVendors = createSelector(
    [selectCanViewVendors],
    vendors => vendors.filter(v => v.hidden === 'No')
);

export const selectCanEditActiveVendors = createSelector(
    [selectCanEditVendors],
    vendors => vendors.filter(v => v.hidden === 'No')
);

export const selectCanViewVendorsById = createSelector(
    [selectCanViewActiveVendors],
    canViewVendors =>
        canViewVendors.reduce((prev, current) => {
            prev[current.id] = current;
            return prev;
        }, {})
);
