import { getData, createQueryString } from '../utils/HelperFunctions.js';
import GoogleAnalytics from '../utils/GoogleAnalytics.js';

export const getActivityLog = (paramsObject = {}) => {
    return getData(paramsObject, 'activitylog/view').then(res =>
        res.ActivityLogs.map(({ AuditRecord }) => AuditRecord)
    );
};

export const getActivityLogTotal = (paramsObject = {}) => {
    return getData(paramsObject, 'activitylog/total').then(res => res.total);
};

export const exportActivityLogExcel = (paramsObject = {}) => {
    GoogleAnalytics.activityLog('xlsx');
    const url = createQueryString(paramsObject, 'activitylog/xlsx');
    window.location = url;
};

export const exportActivityLogCSV = (paramsObject = {}) => {
    GoogleAnalytics.activityLog('csv');
    const url = createQueryString(paramsObject, 'activitylog/csv');
    window.location = url;
};
