import React from 'react';
import styles from '../../css/IENotSupportedView.module.css';
const IENotSupportedView = () => {
    return (
        <div className={styles.container}>
            <div className={styles.ieNotSupportedContainer}>
                <h2 className={styles.heading}>
                    Internet Explorer is no longer Supported by Microsoft
                </h2>
                <div className={styles.infoText}>
                    <a href="https://techcommunity.microsoft.com/t5/microsoft-365-blog/microsoft-365-apps-say-farewell-to-internet-explorer-11-and/ba-p/1591666">
                        Microsoft no longer supports Internet Explorer.
                    </a>
                    <p className={styles.links}>
                        Please use Microsoft's new browser -
                        <a href="https://www.microsoft.com/en-us/edge"> Edge</a> or
                        <a href="https://www.google.com/chrome/"> Chrome</a>,
                        <a href="https://www.mozilla.org/en-US/firefox/new/"> Firefox</a>,
                        <a href="https://support.apple.com/downloads/safari"> Safari</a> to access
                        Minute7
                    </p>
                </div>
            </div>
            <div className={styles.footer}>
                <a href="https://minute7.com" className={styles.headerLink}>
                    Go to Minute7.com
                </a>
            </div>
        </div>
    );
};

export default IENotSupportedView;
