import React from 'react';

const WhatAreExpenses = () => (
    <div>
        <div className="sectionQuestion">What are expenses? How do they work in QuickBooks?</div>
        <div className="sectionAnswer">
            In QuickBooks, expenses incurred for a Customer or Job are recorded as bills that are
            payable to Vendors. Expenses are associated with a Vendor as well as a Customer:Job, and
            can be used for billing and reimbursement.
            <br /> <br />
            You can enter expenses directly into QuickBooks by going to "Vendors" in the top menubar
            of your QuickBooks window and selecting "Enter Bills". Expenses appear as line items in
            the Enter Bill screen that appears. Once an expense is entered and saved, it appears
            with the bill under the "Transactions" tab in the Vendor Center.
            <br /> <br />
            Expenses must be associated with Vendors in QuickBooks, and can also be tied to
            Employees for tracking reimbursable Employee expenses if you correctly associate the
            Employee with a Vendor.
        </div>
    </div>
);

export default WhatAreExpenses;
