import React, { Component } from 'react';

import {CardElement} from '@stripe/react-stripe-js';
import SaveLoading from '../components/SaveLoading.js';
class StripeForm extends Component {
    handleSubmit = async (event) => {
        // Block native form submission.
        event.preventDefault();

        const { stripe, elements, onSubmit, setIsBillingInfoSaving } = this.props;

        setIsBillingInfoSaving(true);

        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        onSubmit(error, paymentMethod)
    };


    render() {
      const { stripe, isSavingBillingInfo } = this.props;

      return (
        <div className="modalContent">
          <form onSubmit={this.handleSubmit}>
            <div style={{padding: '10px', border: "1px solid rgba(38, 56, 48, 0.1)", borderRadius: '3px'}}>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: '#fce883',
                      },
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
            </div>
            <div className="modalActions">
              {!isSavingBillingInfo ? (
                <button className="primaryButton" type="submit" disabled={!stripe}>
                    Update Info
                </button>
              ) : (
                <div className="biLoadingIcon">
                    <SaveLoading />
                </div>
              )}
            </div>
          </form>
        </div>
      );
    }
}

export default StripeForm;
