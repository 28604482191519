import _ from 'lodash';
import { createSelector } from 'reselect';
import {
    getEntityWithId,
    figureUnknownEntities,
    searchEntries,
    sumEntriesColumnByWeekday,
    groupByWeek,
} from '../utils/CommonFunctions';
import { sortByVendorAndDate } from '../utils/ExpenseEntryUtils';
import { getMinAndMaxDateOfEntries } from '../utils/DateUtils';
import { canUserEditExpenseEntry, canUserApproveExpenseEntry } from '../utils/PermissionUtils';
import { EXPENSE_FILTERS, allowedAccountTypes, paymentAccountTypes } from '../config/Fields';
import {
    selectWeekOffset,
    selectQbType,
    selectAccount,
    selectAccountUsers,
    selectCurrentUser,
    selectPreventEditForSyncedEntries,
    prevent_edit_for_locked_entries,
    selectCanViewVendorsById,
} from './AccountSelectors';
import {
    selectAllowedQbAccounts,
    selectAllowedQbAccountsForEditing,
} from './BusinessResourcesSelectors';
export const selectBusinessResources = ({ businessResourcesStore }) => businessResourcesStore;
export const selectAllEntries = ({ expensesStore }) => expensesStore.entries;
export const selectSearchKeyword = ({ expensesStore }) => expensesStore.searchText;
export const selectStartDate = ({ expensesStore }) => expensesStore.startDate;
export const selectEndDate = ({ expensesStore }) => expensesStore.endDate;

export const filterByKeyword = (entries, keyword) => {
    return searchEntries(keyword, entries);
};

export const getSortedWeekIds = entriesByWeek => {
    const timestamps = Object.keys(entriesByWeek);
    timestamps.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
    return timestamps;
};

export const calculateWeeklyTotals = entriesByWeek => {
    return _.mapValues(entriesByWeek, entries => sumEntriesColumnByWeekday(entries, 'amount'));
};

export const calculateWeeklyGrandTotal = weeklyTotals => {
    const grandTotal = {
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0,
        Sun: 0,
        sum: 0,
    };

    _.forEach(weeklyTotals, totals => {
        _.forOwn(totals, (value, key) => {
            grandTotal[key] += value;
        });
    });

    return grandTotal;
};
const rmSpaceFromAccType = accountType =>
    accountType ? _.startCase(accountType).replace(/ /g, '') : '';
const bySpecificAccountType = account =>
    allowedAccountTypes.includes(rmSpaceFromAccType(account.account_type));
const bySpecificPaymentAccountType = account =>
    paymentAccountTypes.includes(rmSpaceFromAccType(account.account_type));

export const selectAllowedQbPaymentAccountsForExpenses = createSelector(
    [selectAllowedQbAccounts],
    allowedQbAccounts => allowedQbAccounts.filter(bySpecificPaymentAccountType)
);

export const selectAllowedQbAccountsForExpenses = createSelector(
    [selectAllowedQbAccounts],
    allowedQbAccounts => allowedQbAccounts.filter(bySpecificAccountType)
);

export const selectAllowedQbAccountsForExpensesEditing = createSelector(
    [selectAllowedQbAccountsForEditing],
    allowedQbAccountsForEditing => allowedQbAccountsForEditing.filter(bySpecificAccountType)
);

export const selectAllDenormalizedEntries = createSelector(
    [
        selectAllEntries,
        selectBusinessResources,
        selectCanViewVendorsById,
        selectQbType,
        selectAccountUsers,
        selectCurrentUser,
        selectPreventEditForSyncedEntries,
        prevent_edit_for_locked_entries,
    ],
    (
        entries,
        resources,
        canViewVendorsById,
        qbType,
        accountUsers,
        currentUser,
        preventEditForSyncedEntries,
        prevent_edit_for_locked_entries,
    ) => {
        return entries.map(entry => {
            if (_.isNil(_.get(entry, 'Customer.id'))) {
                entry.Customer = resources.customersById[entry.customer_id];
            }

            if (_.isNil(_.get(entry, 'QbAccount.id'))) {
                entry.QbAccount = resources.qbAccountsById[entry.qb_account_id];
            }

            if (_.isNil(_.get(entry, 'InventoryItem.id'))) {
                entry.InventoryItem = resources.inventoryItemsById[entry.inventory_item_id];
            }

            if (_.isNil(_.get(entry, 'QbClass.id'))) {
                entry.QbClass = resources.qbClassesById[entry.qb_class_id];
            }

            if (_.isNil(_.get(entry, 'ExpenseGrouping.id'))) {
                entry.ExpenseGrouping = getEntityWithId(
                    'ExpenseGrouping',
                    entry.expense_grouping_id,
                    resources
                );
            }

            if (_.isNil(_.get(entry, 'AccountUser.id'))) {
                entry.AccountUser = (accountUsers || []).find(
                    ({ id }) => id === entry.account_user_id
                );
            }

            if (_.isNil(_.get(entry, 'Vendor.id'))) {
                entry.Vendor = canViewVendorsById[entry.vendor_id];
            }

            entry.unknown_entities = figureUnknownEntities(
                {
                    vendor: entry.Vendor,
                    customer: entry.Customer,
                    qbAccount: entry.QbAccount,
                    inventoryItem: entry.InventoryItem,
                    qbClass: entry.QbClass,
                },
                qbType
            );

            entry.can_be_edited_by_current_user = canUserEditExpenseEntry({
                user: currentUser,
                entry,
                preventEditForSyncedEntries,
                prevent_edit_for_locked_entries,
                qbType,
            });

            entry.can_be_approved_by_current_user = canUserApproveExpenseEntry({
                user: currentUser,
                entry,
                preventEditForSyncedEntries,
            });

            return entry;
        });
    }
);

export const selectAllowedExpensesFilters = createSelector(
    [selectCurrentUser, selectAccount],
    (currentUser, account) => {
        const canViewOthersExpenses = currentUser.view_others_expenses === 'Yes';
        const shouldShowQbClasses = account.show_qb_classes === 'Yes';
        const shouldShowBillable = account.show_billable === 'Yes';
        const shouldShowTaxable = account.show_taxable === 'Yes';
        const noIntegration = account.qb_type === 'None';
        return EXPENSE_FILTERS.filter(({ filter: { id } }) => {
            if (id === 'employee' && !canViewOthersExpenses) {
                return false;
            }

            if ((id === 'qbClass' && !shouldShowQbClasses) || (id === 'qbClass' && noIntegration)) {
                return false;
            }

            if (id === 'billable' && !shouldShowBillable) {
                return false;
            }

            if (id === 'taxable' && !shouldShowTaxable) {
                return false;
            }

            if (id === 'exported' && noIntegration) {
                return false;
            }

            if (id === 'locked' && !noIntegration) {
                return false;
            }

            return true;
        });
    }
);

export const selectFilteredEntries = createSelector(
    [selectAllDenormalizedEntries, selectSearchKeyword],
    filterByKeyword
);

export const selectGroupedByWeek = createSelector(
    [selectFilteredEntries, selectWeekOffset],
    groupByWeek
);

export const selectGroupedByWeekAndVendor = createSelector(
    [selectGroupedByWeek, selectCanViewVendorsById],
    (entriesByWeek, canViewVendorsById) => {
        return _.mapValues(entriesByWeek, entries =>
            sortByVendorAndDate(entries, canViewVendorsById)
        );
    }
);

export const selectSortedWeekIds = createSelector(
    [selectGroupedByWeek],
    getSortedWeekIds
);

export const selectWeeklyTotals = createSelector(
    [selectGroupedByWeek],
    calculateWeeklyTotals
);

export const selectWeeklyGrandTotal = createSelector(
    [selectWeeklyTotals],
    calculateWeeklyGrandTotal
);

export const selectEntriesAvailableForEdit = createSelector(
    [selectGroupedByWeek],
    entriesByWeek => {
        const entriesAvailableForEdit = [];
        _.forOwn(entriesByWeek, entries => {
            _.forEach(entries, entry => {
                const canEdit = entry.can_be_edited_by_current_user;
                const canApprove = entry.can_be_approved_by_current_user;

                if (canEdit || canApprove) {
                    entriesAvailableForEdit.push(entry);
                }
            });
        });
        return entriesAvailableForEdit;
    }
);

export const selectEntriesDateRange = createSelector(
    [selectAllEntries],
    getMinAndMaxDateOfEntries
);

export const selectDateFilterOptions = createSelector(
    [selectStartDate, selectEndDate],
    (startDate, endDate) => {
        return {
            startDate: _.isNil(startDate) ? startDate : new Date(startDate),
            endDate: _.isNil(endDate) ? endDate : new Date(endDate),
        };
    }
);
