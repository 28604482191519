import _ from 'lodash';
import moment from 'moment';
import { constructEditExpenseStore } from '../utils/ExpenseEntryUtils.js';

export const INITIAL_STATE = {
    selectedExpenseEntry: {},
    duplicate: false,

    loading: false,
    startDate: null,
    endDate: null,

    searchText: '',
    searchVendorIds: null, // null so we can set initial
    searchCustomerIds: [],
    searchQbAccountIds: [],
    searchInventoryItemIds: [],
    searchQbClassIds: [],
    searchExpenseGroupingIds: [],
    approvedFilter: 'all',
    billableFilter: 'all',
    exportedFilter: 'all',
    expenseTypeFilter: 'all',
    attachmentFilter: 'all',
    lockedFilter: 'all',
    isSaving: false,
    isNewEntryModalOpen: false,
    isEditEntryModalOpen: false,
    selectedEntryIds: [],
    entries: [],
    currentPage: 0,
    totalExpenseEntries: 0,
    entriesAvailableForEdit: [],
    activeFilters: [],
    updatingStatusEntryIds: [],
    updatingBillableEntryIds: [],
    mode: 'add',

    isNewEntryCollapsed: false,

    customerId: '0',
    vendorId: '0',
    expenseGroupingId: '0',
    qbClassId: '0',
    expenseableId: '0',
    date: moment().format(),
    id: '',
    description: '',
    miles: 0,
    reimbursementRate: 0,
    amount: '',
    attachments: [],
    deletedAttachments: [],
    billable: 'No',
    exported: 'No',
    paymentAccountId: 0,

    // For use with duplicate/edit expense entry modals
    editCustomerId: '0',
    editVendorId: '0',
    editExpenseGroupingId: '0',
    editQbClassId: '0',
    editExpenseableId: '0',
    editDate: moment().format(),
    editId: '',
    editDescription: '',
    editReimbursementRate: 0,
    editMiles: 0,
    editAmount: '',
    editAttachments: [],
    editDeletedAttachments: [],
    editBillable: 'No',
    editExported: 'No',
};

export const expensesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        /**
         * Expense Entry Fields
         */
        case 'SET_EXPENSES_START_DATE':
            return {
                ...state,
                startDate: action.payload,
            };
        case 'SET_EXPENSES_END_DATE':
            return {
                ...state,
                endDate: action.payload,
            };
        case 'SET_EXPENSES_CUSTOMER_ID':
            return {
                ...state,
                customerId: action.payload,
            };
        case 'SET_EXPENSES_VENDOR_ID':
            return {
                ...state,
                vendorId: action.payload,
            };
        case 'SET_EXPENSEABLE_ID':
            return {
                ...state,
                expenseableId: action.payload,
            };
        case 'SET_EXPENSES_QB_CLASS_ID':
            return {
                ...state,
                qbClassId: action.payload,
            };
        case 'SET_EXPENSES_EXPENSE_GROUPING_ID':
            return {
                ...state,
                expenseGroupingId: action.payload,
            };
        case 'SET_EXPENSES_PAYMENT_ACCOUNT_ID':
            return {
                ...state,
                paymentAccountId: action.payload,
            };
        case 'SET_EXPENSES_DATE':
            return {
                ...state,
                date: action.payload,
            };
        case 'SET_EXPENSES_AMOUNT':
            return {
                ...state,
                amount: action.payload,
            };
        case 'SET_EXPENSES_ATTACHMENTS':
            return {
                ...state,
                attachments: action.payload,
            };
        case 'SET_EXPENSES_DESCRIPTION':
            return {
                ...state,
                description: action.payload,
            };
        case 'SET_EXPENSES_REIMBURSEMENT_RATE':
            return {
                ...state,
                reimbursementRate: action.payload,
            };
        case 'SET_EXPENSES_MILES':
            return {
                ...state,
                miles: action.payload,
            };
        case 'SET_EXPENSES_BILLABLE':
            return {
                ...state,
                billable: action.payload,
            };
        /**
         * Expense Filters
         */
        case 'SET_EXPENSES_SEARCH_TEXT':
            return {
                ...state,
                searchText: action.payload,
            };
        case 'SET_EXPENSES_SEARCH_VENDOR_IDS':
            return {
                ...state,
                searchVendorIds: action.payload,
            };
        case 'SET_EXPENSES_SEARCH_CUSTOMER_IDS':
            return {
                ...state,
                searchCustomerIds: action.payload,
            };
        case 'SET_EXPENSES_SEARCH_QB_CLASS_IDS':
            return {
                ...state,
                searchQbClassIds: action.payload,
            };
        case 'SET_EXPENSES_SEARCH_QB_ACCOUNT_IDS':
            return {
                ...state,
                searchQbAccountIds: action.payload,
            };
        case 'SET_EXPENSES_SEARCH_INVENTORY_ITEM_IDS':
            return {
                ...state,
                searchInventoryItemIds: action.payload,
            };
        case 'SET_EXPENSES_SEARCH_EXPENSE_GROUPING_IDS':
            return {
                ...state,
                searchExpenseGroupingIds: action.payload,
            };
        case 'SET_EXPENSES_APPROVED_FILTER':
            return {
                ...state,
                approvedFilter: action.payload,
            };
        case 'SET_EXPENSES_BILLABLE_FILTER':
            return {
                ...state,
                billableFilter: action.payload,
            };
        case 'SET_EXPENSES_LOCKED_FILTER':
            return {
                ...state,
                lockedFilter: action.payload,
            };
        case 'SET_EXPENSES_EXPORTED_FILTER':
            return {
                ...state,
                exportedFilter: action.payload,
            };
        case 'SET_EXPENSE_TYPE_FILTER':
            return {
                ...state,
                expenseTypeFilter: action.payload,
            };
        case 'SET_EXPENSES_ATTACHMENT_FILTER':
            return {
                ...state,
                attachmentFilter: action.payload,
            };
        case 'SET_EXPENSES_DELETED_ATTACHMENTS':
            return {
                ...state,
                deletedAttachments: action.payload,
            };
        case 'SET_EXPENSES_CURRENT_PAGE':
            return {
                ...state,
                currentPage: action.payload,
            };
        case 'SET_EXPENSES_TOTAL_EXPENSE_ENTRIES':
            return {
                ...state,
                totalExpenseEntries: action.payload,
            };
        case 'SET_EXPENSES_ACTIVE_FILTERS':
            return {
                ...state,
                activeFilters: action.payload,
            };
        case 'SET_EXPENSES_FILTER_STATE': {
            const allowedKeys = [
                'startDate',
                'endDate',
                'searchText',
                'searchVendorIds',
                'searchCustomerIds',
                'searchInventoryItemIds',
                'searchQbClassIds',
                'searchQbAccountIds',
                'searchExpenseGroupingIds',
                'approvedFilter',
                'billableFilter',
                'exportedFilter',
                'expenseTypeFilter',
                'attachmentFilter',
                'currentPage',
                'activeFilters',
            ];

            return {
                ...state,
                ..._.pick(action.payload, allowedKeys),
            };
        }
        /**
         * Edit/Duplicate expense entry actions
         */
        case 'SET_EDIT_EXPENSE_STORE':
            return {
                ...state,
                ...constructEditExpenseStore(action.payload, state.duplicate),
            };
        case 'SET_EXPENSES_EDIT_CUSTOMER_ID':
            return {
                ...state,
                editCustomerId: action.payload,
            };
        case 'SET_EXPENSES_EDIT_VENDOR_ID':
            return {
                ...state,
                editVendorId: action.payload,
            };
        case 'SET_EXPENSE_EDIT_EXPENSEABLE_ID':
            return {
                ...state,
                editExpenseableId: action.payload,
            };
        case 'SET_EXPENSES_EDIT_QB_CLASS_ID':
            return {
                ...state,
                editQbClassId: action.payload,
            };
        case 'SET_EXPENSES_EDIT_EXPENSE_GROUPING_ID':
            return {
                ...state,
                editExpenseGroupingId: action.payload,
            };
        case 'SET_EXPENSES_EDIT_DATE':
            return {
                ...state,
                editDate: action.payload,
            };
        case 'SET_EXPENSES_EDIT_AMOUNT':
            return {
                ...state,
                editAmount: action.payload,
            };
        case 'SET_EXPENSES_EDIT_ATTACHMENTS':
            return {
                ...state,
                editAttachments: action.payload,
            };
        case 'SET_EXPENSES_EDIT_DELETED_ATTACHMENTS':
            return {
                ...state,
                editDeletedAttachments: action.payload,
            };
        case 'SET_EXPENSES_EDIT_DESCRIPTION':
            return {
                ...state,
                editDescription: action.payload,
            };
        case 'SET_EXPENSES_EDIT_REIMBURSEMENT_RATE':
            return {
                ...state,
                editReimbursementRate: action.payload,
            };
        case 'SET_EXPENSES_EDIT_MILES':
            return {
                ...state,
                editMiles: action.payload,
            };
        case 'SET_EXPENSES_EDIT_BILLABLE':
            return {
                ...state,
                editBillable: action.payload,
            };
        /**
         * Misc
         */
        case 'SET_EXPENSES_IS_SAVING':
            return {
                ...state,
                isSaving: action.payload,
            };
        case 'SET_EXPENSES_IS_NEW_ENTRY_MODAL_OPEN':
            return {
                ...state,
                isNewEntryModalOpen: action.payload,
                mode: 'add',
            };
        case 'SET_EXPENSES_IS_EDIT_ENTRY_MODAL_OPEN':
            return {
                ...state,
                isEditEntryModalOpen: action.payload,
                mode: action.payload ? 'edit' : 'add',
            };
        case 'SET_EXPENSES_IS_NEW_ENTRY_COLLAPSED':
            return {
                ...state,
                isNewEntryCollapsed: action.payload,
            };
        case 'SET_EXPENSES_SELECTED_ENTRY_IDS':
            return {
                ...state,
                selectedEntryIds: action.payload,
            };
        case 'SET_EXPENSES_LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        case 'RESET_EXPENSES_FORM':
            return {
                ...state,
                description: '',
                amount: '',
                miles: '',
                attachments: [],
                deletedAttachments: [],
            };
        case 'SELECT_EXPENSE_ENTRY':
            return {
                ...state,
                selectedExpenseEntry: action.payload.selectedExpenseEntry,
                duplicate: action.payload.duplicate,
            };
        case 'REQUEST_EXPENSES': {
            return {
                ...state,
                loading: action.showLoading,
            };
        }
        case 'RECEIVE_EXPENSES': {
            return {
                ...state,
                entries: action.expenses,
                totalExpenseEntries: action.total,
                loading: false,
            };
        }
        case 'REQUEST_EXPENSES_ERROR': {
            return {
                ...state,
                loading: false,
            };
        }
        case 'SET_EXPENSE_ENTRIES_BILLABLE': {
            return {
                ...state,
                updatingBillableEntryIds: [...state.updatingBillableEntryIds, ...action.ids],
            };
        }
        case 'SET_EXPENSE_ENTRIES_BILLABLE_SUCCESS': {
            const newIds = state.updatingBillableEntryIds.filter(id => !action.ids.includes(id));
            return {
                ...state,
                updatingBillableEntryIds: newIds,
            };
        }
        case 'SET_EXPENSE_ENTRIES_BILLABLE_ERROR': {
            return {
                ...state,
                updatingBillableEntryIds: [],
            };
        }
        case 'SET_EXPENSE_ENTRIES_APPROVED': {
            return {
                ...state,
                updatingStatusEntryIds: [...state.updatingStatusEntryIds, ...action.ids],
            };
        }
        case 'SET_EXPENSE_ENTRIES_APPROVED_SUCCESS': {
            const newIds = state.updatingStatusEntryIds.filter(id => !action.ids.includes(id));
            return {
                ...state,
                updatingStatusEntryIds: newIds,
            };
        }
        case 'SET_EXPENSE_ENTRIES_APPROVED_ERROR': {
            return {
                ...state,
                updatingStatusEntryIds: [],
            };
        }

        case 'SET_EXPENSE_ENTRIES_LOCKED': {
            return {
                ...state,
                updatingStatusEntryIds: [...state.updatingStatusEntryIds, ...action.ids],
            };
        }
        case 'SET_EXPENSE_ENTRIES_LOCKED_SUCCESS': {
            const newIds = state.updatingStatusEntryIds.filter(id => !action.ids.includes(id));
            return {
                ...state,
                updatingStatusEntryIds: newIds,
            };
        }
        case 'SET_EXPENSE_ENTRIES_LOCKED_ERROR': {
            return {
                ...state,
                updatingStatusEntryIds: [],
            };
        }
        case 'SET_DEFAULTS_FOR_NEW_EXPENSE_ENTRIES': {
            const allowedKeys = [
                'customerId',
                'qbAccountId',
                'inventoryItemId',
                'vendorId',
                'billable',
                'reimbursementRate',
            ];

            return {
                ...state,
                ..._.pick(action.defaults, allowedKeys),
            };
        }
        case 'LOGOUT':
            return INITIAL_STATE;
        case 'IMPERSONATE_STORE_CLEAR':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default expensesReducer;
