import _ from 'lodash';
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import Icon from 'react-icons-kit';
import { ic_warning } from 'react-icons-kit/md/ic_warning';
import { ic_delete } from 'react-icons-kit/md/ic_delete';
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit';
import { ic_sync } from 'react-icons-kit/md/ic_sync';
import { ic_sync_problem } from 'react-icons-kit/md/ic_sync_problem';
import { ic_security as permissionsIcon } from 'react-icons-kit/md/ic_security';

import TrialBanner from './components/TrialBanner.js';
import {
    figureVendorsNotAssociatedWithAnAccountUser,
    figureEmployeesNotAssociatedWithAnAccountUser,
    maskCCNumber,
} from '../../utils/CommonFunctions.js';
import ConfirmationModal from '../components/ConfirmationModal.js';
import { deletePermissionsGroup } from '../../actions/BusinessResourcesActions.js';
import NoEntriesFound from '../components/NoEntriesFound.js';
import { showToast, selectEmployee, selectVendor } from '../../actions/Actions.js';
import { setPermissionsGroups } from '../../actions/BusinessResourcesActions.js';
import { toggleSyncing, toggleAutoSyncing } from '../../actions/AccountActions.js';
import { deleteUser } from '../../actions/AccountUserActions';
import * as accountSelectors from '../../selectors/AccountSelectors';
import EmployeeList from './components/EmployeeList.js';

import '../../css/CommonTheme.css';
import '../../css/AccountSettings.css';
const UserRow = ({
    id,
    firstName,
    lastName,
    email,
    isManager,
    onEdit,
    onEditPermissions,
    onDelete,
    currentUserId,
    checkBoxColor,
}) => (
    <div className="asAccountUserContainer">
        <div className="asAccountUserNameAndEmail">
            <div className="asAccountUserName">{[firstName, lastName].join(' ')}</div>
            <div className="asAccountUserEmail">{isManager ? 'Manager' : email}</div>
        </div>
        <div className="asAccountUserOptions">
                <Icon
                onClick={() => onEdit(id)}
                icon={ic_mode_edit}
                className="tableIcon asAccountUserIcon"
                size={22}
                style={{
                    color: checkBoxColor(
                        isManager && !(currentUserId===id)
                    ),
                }}
                data-tip="Edit Account User"
            />
            
            {!isManager &&(
                <Icon
                    onClick={() => onEditPermissions(id)}
                    icon={permissionsIcon}
                    className="tableIcon asAccountUserIcon"
                    size={22}
                    style={{
                        color: checkBoxColor(
                            currentUserId===id
                        ),
                    }}
                    data-tip="Edit Permissions"
                />
            )}
            {!isManager && (
                <Icon
                    onClick={() => onDelete(id)}
                    icon={ic_delete}
                    className="tableIcon asAccountUserIcon"
                    size={22}
                    data-tip="Delete Account User"
                />
            )}
        </div>
    </div>
);

class AccountSettings extends Component {
    constructor(props) {
        super(props);
        this.props.selectEmployee('');
        this.state = {
            isConfirmationModalOpen: false,
            confirmationModalOnConfirm: null,
            confirmationModalTitle: '',

            isEmployeesExpanded: false,
            isVendorsExpanded: false,
            selectedResource: 0,
        };
    }

    

    deletePermissionsGroup = permissionsGroupId => {
        const paramsObject = {
            permission_group_id: permissionsGroupId,
        };
        this.props.deletePermissionsGroup(paramsObject);
    };

    handleSelectEmployeePressed = employeeId => {
        this.props.selectEmployee(employeeId);
        this.props.history.push('/create_account_user');
    };

    handleSelectVendorPressed = vendorId => {
        this.props.selectVendor(vendorId);
        this.props.history.push('/create_account_user');
    };

    handleSelectAccountUserPress = (accountUserId, route) => {
        this.props.history.push(`${route}/${accountUserId}`);
    };

    handleSelectPermissionsGroupPress = id => {
        this.props.history.push(`/permissions_group/${id}`);
    };

    checkBoxColor(value) {
        if (value) {
            return 'gray';
        } else {
            return '#008fd7';
        }
    }

    handleEditUser = id => {
        let selected_user_account_manager = this.props.users.find(u => u.id === id).manage_account === 'Yes';
        let current_user_account_manager = this.props.currentUser.id === id;
        if(selected_user_account_manager && !current_user_account_manager){
            return;
        }
        this.props.history.push(`/edit_account_user/${id}`);
    };

    handleEditUserPermissions = id => {
        if(this.props.currentUser.id === id){
            return;
        }
        this.props.history.push(`/account_user_permissions/${id}`);
    };

    handleDeleteUser = id => {
        this.setState({
            isConfirmationModalOpen: true,
            confirmationModalTitle: 'Delete Account User?',
            confirmationModalOnConfirm: () => {
                this.props.deleteUser(id);
                this.setState({
                    isConfirmationModalOpen: false,
                });
            },
        });
    };

    renderAccountUsersNotInPermissionsGroup = () => {
        const [managers, nonManagers] = _.partition(
            this.props.users,
            u => u.manage_account === 'Yes'
        );
        const managersSorted = _.sortBy(managers, item => item.first_name.toLowerCase());
        const nonManagersSorted = _.sortBy(nonManagers, item => item.first_name.toLowerCase());

        return [...managersSorted, ...nonManagersSorted]
            .filter(user => user.permission_group_id === '0' || user.manage_account === 'Yes')
            .map(user => (
                <UserRow
                    key={user.id}
                    id={user.id}
                    isManager={user.manage_account === 'Yes'}
                    firstName={user.first_name}
                    lastName={user.last_name}
                    email={user.email}
                    onEdit={this.handleEditUser}
                    onEditPermissions={this.handleEditUserPermissions}
                    onDelete={this.handleDeleteUser}
                    currentUserId={this.props.currentUser.id}
                    checkBoxColor={this.checkBoxColor}
                />
            ));
    };

    renderAccountUserInPermissionsGroup = groupId => {
        return this.props.users
            .filter(user => user.permission_group_id === groupId)
            .map(user => (
                <UserRow
                    key={user.id}
                    id={user.id}
                    isManager={user.manage_account === 'Yes'}
                    firstName={user.first_name}
                    lastName={user.last_name}
                    email={user.email}
                    onEdit={this.handleEditUser}
                    onEditPermissions={this.handleEditUserPermissions}
                    onDelete={this.handleDeleteUser}
                    currentUserId={this.props.currentUser.id}
                    checkBoxColor={this.checkBoxColor}
                />
            ));
    };

    renderPermissionsGroups = () => {
        if (this.props.permissionsGroups.length === 0) {
            return <NoEntriesFound key="noEntries" type="Permission Groups" />;
        }

        return this.props.permissionsGroups.map(group => (
            <div key={group.id}>
                <div className="asPermissionsGroup">
                    <div className="asPermissionsGroupName">{group.name}</div>
                    <div className="asPermissionsGroupOptions">
                        <Icon
                            onClick={() => this.handleSelectPermissionsGroupPress(group.id)}
                            icon={ic_mode_edit}
                            className="tableIcon asAccountUserIcon"
                            size={22}
                            data-tip="Edit Permissions Group"
                        />
                        <Icon
                            onClick={() => {
                                this.setState({
                                    isConfirmationModalOpen: true,
                                    confirmationModalTitle: 'Delete Permissions Group?',
                                    confirmationModalOnConfirm: () => {
                                        this.deletePermissionsGroup(group.id);
                                        this.setState({
                                            isConfirmationModalOpen: false,
                                        });
                                    },
                                });
                            }}
                            icon={ic_delete}
                            className="tableIcon asAccountUserIcon"
                            size={22}
                            data-tip="Delete Permissions Group"
                        />
                    </div>
                </div>
                <div className="asPermissionsGroupAccountUser">
                    {this.renderAccountUserInPermissionsGroup(group.id)}
                </div>
            </div>
        ));
    };

    render() {
        const { pathname } = this.props.history.location;

        const {
            accountStore,
            toggleBillingInfoVisibility,
            canEditEmployees,
            canEditVendors,
            canManageAccount,
            canManageUsers,
            canExportTime,
            isSyncEnabled,
            isAutoSyncEnabled,
            users,
            no_integration,
        } = this.props;

        const ccFields = ['cc_number'];
        const billingAddressFields = ['address1', 'first_name', 'last_name', 'city'];
        const isMissingCCInfo = ccFields.some(field => accountStore[field] === '');
        const isMissingAddressInfo = billingAddressFields.some(field => accountStore[field] === '');
        const isMissingBillingInfo = isMissingCCInfo || isMissingAddressInfo;

        const employeesWithoutAccountUsers = figureEmployeesNotAssociatedWithAnAccountUser(
            canEditEmployees,
            users
        ).filter(e => e.hidden === 'No' && !e.name.match(/^\*/));

        const vendorsWithoutAccountUsers = figureVendorsNotAssociatedWithAnAccountUser(
            canEditVendors,
            users
        ).filter(e => e.hidden === 'No');

        return (
            <div className="applicationWrapper">
                <div className="secondaryNav">
                    {(canManageAccount || canManageUsers) && (
                        <button
                            className={classNames({
                                secondaryNavLink: true,
                                secondaryNavActiveLink: pathname === '/account_settings',
                            })}
                            onClick={() => this.props.history.push('account_settings')}
                        >
                            Account Settings
                        </button>
                    )}
                    {canManageAccount && (
                        <button
                            className={classNames({
                                secondaryNavLink: true,
                                secondaryNavActiveLink: pathname === '/company_settings',
                            })}
                            onClick={() => this.props.history.push('company_settings')}
                        >
                            Company Settings
                        </button>
                    )}
                    {(canManageAccount || canManageUsers) && accountStore.qb_type === 'None' && (
                        <button
                            className={classNames({
                                secondaryNavLink: true,
                                secondaryNavActiveLink: pathname === '/resource_settings',
                            })}
                            onClick={() => this.props.history.push('resource_settings')}
                        >
                            Resource Settings
                        </button>
                    )}
                </div>
                <div className="applicationBody">
                    <ConfirmationModal
                        isOpen={this.state.isConfirmationModalOpen}
                        onConfirm={this.state.confirmationModalOnConfirm}
                        onRequestClose={() => this.setState({ isConfirmationModalOpen: false })}
                        title={this.state.confirmationModalTitle}
                    />

                    {canManageAccount && isMissingBillingInfo && (
                        <TrialBanner
                            accountStore={accountStore}
                            toggleBillingInfoVisibility={toggleBillingInfoVisibility}
                        />
                    )}

                    <div className="bodyHeader">
                        <h2>{accountStore.name}</h2>
                    </div>

                    {canManageAccount && (
                        <div>
                            <h3 className="cardHeading">
                                Billing Information
                                <button
                                    className="tertiaryButton"
                                    onClick={() => this.props.toggleBillingInfoVisibility()}
                                >
                                    Edit
                                </button>
                            </h3>

                            <div className="cardContainer asSection">
                                {isMissingCCInfo && isMissingAddressInfo && (
                                    <div className="asBillingInfoContainer">
                                        <Icon
                                            className="asWarningIcon"
                                            icon={ic_warning}
                                            size={20}
                                        />
                                        <div className="bodyText">
                                            No billing information on file.
                                            <button
                                                className="tertiaryButton"
                                                onClick={this.props.toggleBillingInfoVisibility}
                                            >
                                                &nbsp;Enter Billing Info
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {(!isMissingCCInfo || !isMissingAddressInfo) && (
                                    <div>
                                        {isMissingCCInfo ? (
                                            <div className="asBillingInfoContainer">
                                                <Icon
                                                    className="asWarningIcon"
                                                    icon={ic_warning}
                                                    size={20}
                                                />
                                                <div className="bodyText">
                                                    No credit card on file
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="asBillingInfoRow">
                                                    <div className="asBillingInfoLabel">
                                                        Paid Through
                                                    </div>
                                                    <div>
                                                        {accountStore.paid_through &&
                                                            moment(
                                                                accountStore.paid_through
                                                            ).format('M/D/Y')}
                                                    </div>
                                                </div>
                                                <div className="asBillingInfoRow">
                                                    <div className="asBillingInfoLabel">
                                                        Monthly Charges
                                                    </div>
                                                    <div>
                                                        ${8 * accountStore.accountUsers.length} /
                                                        month ({accountStore.accountUsers.length}{' '}
                                                        users)
                                                    </div>
                                                    &nbsp;
                                                    <button
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                'payment_history'
                                                            )
                                                        }
                                                        className="tertiaryButton"
                                                    >
                                                        Payment History
                                                    </button>
                                                </div>
                                                <div className="asBillingInfoRow">
                                                    <div className="asBillingInfoLabel">
                                                        Card Type
                                                    </div>
                                                    <div>{accountStore.cc_type}</div>
                                                </div>
                                                <div className="asBillingInfoRow">
                                                    <div className="asBillingInfoLabel">
                                                        Card Number
                                                    </div>
                                                    <div>
                                                        {maskCCNumber(
                                                            this.props.accountStore.cc_number
                                                        )}{' '}
                                                        &nbsp;(Expires{' '}
                                                        {accountStore.expiration_month}/
                                                        {accountStore.expiration_year})
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {isMissingAddressInfo ? (
                                            <div className="asBillingInfoContainer">
                                                <Icon
                                                    className="asWarningIcon"
                                                    icon={ic_warning}
                                                    size={20}
                                                />
                                                <div className="bodyText">
                                                    No billing address on file
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="asBillingInfoRow lastRow">
                                                <div className="asBillingInfoLabel">
                                                    Billing Address
                                                </div>
                                                <div>
                                                    {accountStore.address1 +
                                                        ', ' +
                                                        accountStore.city +
                                                        ' ' +
                                                        accountStore.zip}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    {(canManageAccount || canExportTime) && !no_integration && (
                        <div>
                            <h3 className="cardHeading">
                                Sync Preferences
                                <button
                                    className="tertiaryButton"
                                    onClick={() => this.props.history.push('/sync')}
                                >
                                    Sync Minute7 with QuickBooks
                                </button>
                            </h3>

                            <div className="cardContainer cardContent asSection">
                                <p className="bodyText">
                                    You can control whether you want time and expense data to be
                                    sent to QuickBooks during the sync process. <br />
                                    This allows you to update all the other data in Minute7 without
                                    sending time and expense data to QuickBooks.
                                </p>
                                <div className="asSyncWarningContainer">
                                    {!isSyncEnabled ? (
                                        <Icon
                                            icon={ic_sync_problem}
                                            size={50}
                                            className="asSyncWarning"
                                        />
                                    ) : (
                                        <Icon
                                            icon={ic_sync}
                                            size={50}
                                            color="#28A745"
                                            className="asSync"
                                        />
                                    )}
                                    <div>
                                        <div>
                                            {!isSyncEnabled ? (
                                                <b>
                                                    Time and expense entries will NOT be synced to
                                                    QuickBooks.
                                                </b>
                                            ) : (
                                                <b>
                                                    Time and expense entries will be synced to
                                                    QuickBooks.
                                                </b>
                                            )}
                                            <button
                                                className="tertiaryButton"
                                                onClick={() => this.props.toggleSyncing()}
                                            >
                                                &nbsp;
                                                {!isSyncEnabled
                                                    ? 'Turn On Syncing'
                                                    : 'Turn Off Syncing'}
                                            </button>
                                        </div>
                                        <div className="bodyText">
                                            Employees, customers, and other data in Minute7 will be
                                            updated.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {accountStore.quickbooks_type === 'Online' && (
                                <div className="cardContainer cardContent asSection">
                                    <p className="bodyText">
                                        You can choose to automatically sync your data with
                                        QuickBooks every weekend. <br />
                                    </p>
                                    <div className="asSyncWarningContainer">
                                        {isAutoSyncEnabled ? (
                                            <Icon
                                                icon={ic_sync}
                                                size={50}
                                                color="#28A745"
                                                className="asSync"
                                            />
                                        ) : (
                                            <Icon
                                                icon={ic_sync_problem}
                                                size={50}
                                                className="asSyncWarning"
                                            />
                                        )}
                                        <div>
                                            <div>
                                                {isAutoSyncEnabled ? (
                                                    <b>
                                                        An auto sync will send all your entries to
                                                        to Quickboooks on Saturday.
                                                    </b>
                                                ) : (
                                                    <b>Automatic sync will not run.</b>
                                                )}

                                                <button
                                                    disabled={!isSyncEnabled}
                                                    className="tertiaryButton"
                                                    onClick={() => this.props.toggleAutoSyncing()}
                                                >
                                                    &nbsp;
                                                    {isAutoSyncEnabled
                                                        ? 'Turn Off Auto Syncing'
                                                        : 'Turn On Auto Syncing'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    <h3 className="cardHeading">Users &amp; Groups</h3>

                    <p className="bodyText">
                        Create user accounts so employees can track time, and create permissions
                        groups if you have many employees that will need the same permissions.
                    </p>

                    <h3 className="cardHeading">
                        Users
                        <div className="headingActions">
                            <button
                                className="tertiaryButton"
                                onClick={() => this.props.history.push('/create_account_user')}
                            >
                                Add a User
                            </button>
                        </div>
                    </h3>
                    <div className="cardContainer asSection">
                        {this.renderAccountUsersNotInPermissionsGroup()}
                    </div>
                    <h3 className="cardHeading">
                        Permissions Groups
                        <div className="headingActions">
                            <button
                                className="tertiaryButton"
                                onClick={() => this.props.history.push('/permissions_group')}
                            >
                                Add a Permission Group
                            </button>
                        </div>
                    </h3>

                    <div className="cardContainer asSection">{this.renderPermissionsGroups()}</div>

                    <EmployeeList
                        title="Employees without a Minute7 User Account"
                        list={employeesWithoutAccountUsers}
                        onClick={this.handleSelectEmployeePressed}
                        addActionTip="Create a Minute7 account for this employee"
                    />

                    <EmployeeList
                        title="Vendors without a Minute7 User Account"
                        list={vendorsWithoutAccountUsers}
                        onClick={this.handleSelectVendorPressed}
                        addActionTip="Create a Minute7 account for this vendor"
                    />

                    <ReactTooltip delayShow={500} type="info" multiline={true} />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { match } = ownProps;
    let currentUser;
    if (match && match.params && match.params.id) {
        currentUser = users.find(u => u.id === match.params.id);
    } else {
        currentUser = state.accountUserStore;
    }
    const users = accountSelectors.selectUsersSorted(state);
    const employees = accountSelectors.selectCanEditEmployeesSorted(state);
    const no_integration = state.accountStore.qb_type === 'None' ? true : false;
    return {
        currentUser,
        users,
        businessResourcesStore: state.businessResourcesStore,
        permissionsGroups: state.businessResourcesStore.permissionsGroups,
        accountStore: state.accountStore,
        isSyncEnabled: state.accountStore.sync_entries === 'Yes',
        isAutoSyncEnabled: state.accountStore.auto_sync === 'Yes',
        canEditVendors: state.vendorStore.canEditVendors,
        canEditEmployees: employees,
        canManageAccount: state.accountUserStore.manage_account === 'Yes',
        canManageUsers: state.accountUserStore.manage_users === 'Yes',
        canExportTime: state.accountUserStore.export_time === 'Yes',
        no_integration,
    };
}

const mapDispatchToProps = {
    showToast,
    selectEmployee,
    selectVendor,
    setPermissionsGroups,
    deleteUser,
    toggleSyncing,
    toggleAutoSyncing,
    deletePermissionsGroup,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountSettings);
