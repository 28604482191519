import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Input } from 'reactstrap';
import Icon from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { showToast } from '../../actions/Actions.js';
import Select from '../components/Select';
import DateTimePicker from '../components/DateTimePicker.js';
import { timeRoundingIntervals } from '../../config/Fields.js';
import {
    durationToString,
    constructNewTimeEntry,
    elapsedTimeWorkingFromChunks,
    startTimeFromChunks,
} from '../../utils/TimerUtils.js';
import { durationStringToDuration } from '../../utils/TimeEntryUtils';
import { requestTimesheets } from '../../actions/TimesheetsActions.js';

import * as timerActions from '../../actions/TimerActions.js';
import * as timesheetsActions from '../../actions/TimesheetsActions';
import * as businessResourcesSelectors from '../../selectors/BusinessResourcesSelectors';
import DescriptionInput from './DescriptionInput.js';
import '../../css/Timer.css';

// Our tests don't like this line
try {
    Modal.setAppElement('#root');
} catch {}

const TICK_INTERVAL = 300;
const TIME_FORMAT = 'HH:mm';

class Timer extends Component {
    static propTypes = {
        showToast: PropTypes.func.isRequired,
        isTimerOpen: PropTypes.bool.isRequired,
        history: PropTypes.object,
    };

    static getDerivedStateFromProps(props, state) {
        const startTimeFromProps = startTimeFromChunks(props.timeChunks);

        if (startTimeFromProps !== state.startTime && state.startTimeIsValid) {
            return {
                startTime: startTimeFromProps,
                startTimeIsValid: true,
            };
        }
        return null;
    }

    state = {
        elapsedTimeWorking: 0,
        startTime: moment().format('HH:mm'),
        startTimeIsValid: true,
    };

    timerTickerInterval = null;

    componentDidUpdate() {
        if (this.props.isTimerStarted && this.timerTickerInterval === null) {
            this.timerTickerInterval = setInterval(this.timerTicker, TICK_INTERVAL);
        }
    }

    componentWillUnmount() {
        if (this.timerTickerInterval) {
            clearInterval(this.timerTickerInterval);
        }
    }

    handleStartTimerPressed = () => {
        this.props.startTimer();
        this.timerTickerInterval = setInterval(this.timerTicker, TICK_INTERVAL);
    };

    handlePauseOrResumePressed = () => {
        if (this.props.isTimerRunning) {
            this.props.pauseTimer();
        } else {
            this.props.startTimer();
        }
    };

    timerTicker = () => {
        if (!this.props.isTimerStarted) {
            return;
        }

        this.updateTimeDisplayState();
    };

    updateTimeDisplayState = () => {
        const { timeChunks } = this.props;

        const elapsedTimeWorking = elapsedTimeWorkingFromChunks(timeChunks);

        this.setState({ elapsedTimeWorking });
    };

    saveTimeEntry = () => {
        if (this.props.isTimerStarted) {
            clearInterval(this.timerTickerInterval);
            this.timerTickerInterval = null;
            const entry = constructNewTimeEntry(this.props);
            if (entry.id) {
                this.props.updateTimeEntry(entry);
            } else {
                this.props.addTimeEntry(entry);
            }
        } else {
            this.props.showToast('No time to save', 'warning');
        }
    };

    handleResetTimer = () => {
        this.setState({ elapsedTimeWorking: 0 });
        this.props.resetTimer();
    };

    handleEmployeeSelected = id => {
        this.props.setEmployeeId(id);
    };

    handleCustomerSelected = id => {
        this.props.setCustomerId(id);
        if(this.props.no_integration){
            this.props.setInventoryItemId(0);
        }
    };

    handleInventoryItemSelected = id => {
        this.props.setInventoryItemId(id);
    };

    handleQbClassSelected = id => {
        this.props.setQbClassId(id);
    };

    handlePayrollItemSelected = id => {
        this.props.setPayrollItemId(id);
    };

    handleDateChanged = date => {
        this.props.setDate(date);
    };

    handleDescriptionChanged = event => {
        this.props.setDescription(event.target.value);
    };

    handleBillableToggled = () => {
        const newBillableValue = this.props.billable === 'Yes' ? 'No' : 'Yes';
        this.props.setBillable(newBillableValue);
    };

    handleTaxableToggled = () => {
        const newTaxableValue = this.props.taxable === 'Yes' ? 'No' : 'Yes';
        this.props.setTaxable(newTaxableValue);
    };

    handleRoundingIntervalChange = value => {
        this.props.setRoundingInterval(timeRoundingIntervals[value]);
    };

    handleStartTimeChanged = event => {
        const value = event.target.value;

        this.setState({ startTime: value });

        const time = moment(value.trim(), TIME_FORMAT);
        if (time.isValid()) {
            this.props.setStartTime(time.format(TIME_FORMAT));
            this.setState({ startTimeIsValid: true });
        } else {
            this.setState({ startTimeIsValid: false });
        }
    };

    handleHoursOffDutyChanged = event => {
        this.props.setHoursOffDuty(event.target.value);
    };

    render() {
        const {
            isTimerOpen,
            accountUserStore,
            accountStore,
            payrollItems,
            canEditEmployees,
            employeeId,
            qbClassId,
            inventoryItemId,
            payrollItemId,
            customerId,
            description,
            isTimerRunning,
            isTimerStarted,
            roundingInterval,
            billable,
            taxable,
            timeEntryId,
            closeTimer,
            allowedCustomers,
            allowedInventoryItems,
            allowedQbClasses,
            hoursOffDuty,
            hoursOffDutyAdjustment,
            timeChunks,
            no_integration,
            filterdInventoryItems,
        } = this.props;

        const { startTime } = this.state;
        const elapsedTimeWorking = elapsedTimeWorkingFromChunks(timeChunks);
        const durationOffDutyAdjustment = moment.duration(hoursOffDutyAdjustment, 'hours');
        const durationOffDuty = durationStringToDuration(hoursOffDuty).subtract(
            durationOffDutyAdjustment
        );

        const hoursWorked = durationToString(
            elapsedTimeWorking - durationOffDuty.asMilliseconds(),
            {
                includeSeconds: true,
            }
        );

        const date = new Date(this.props.date);

        const canEditOthersTime = accountUserStore.edit_others_time !== 'No';
        const shouldShowQbClasses = accountStore.show_qb_classes === 'Yes';
        let shouldShowPayroll =
            payrollItems.length > 0 &&
            accountStore.show_payroll === 'Yes' &&
            accountUserStore.edit_payroll === 'Yes';
        const hasTimeToLog = elapsedTimeWorking > 0;
        const isClockInOnly = accountUserStore.clock_in_only === 'Yes';
        const shouldShowBillable = accountStore.show_billable === 'Yes';
        const shouldShowTaxable = accountStore.show_taxable === 'Yes';

        return (
            <Modal
                closeTimeoutMS={400}
                isOpen={isTimerOpen}
                onRequestClose={closeTimer}
                contentLabel="Timer"
                className="modalContainer tsTimeEntryModal"
                shouldCloseOnOverlayClick={true}
            >
                <div className="modalHeader">
                    <h2>Timer</h2>
                    <div className="modalOptions">
                        <Icon
                            className="modalIcon"
                            size={24}
                            icon={closeIcon}
                            onClick={closeTimer}
                        />
                    </div>
                </div>
                <div className="modalContent">
                    <div>
                        {/** Hours Worked, Hours Off Duty, Start Time */}
                        <div className="tTimerDetails">
                            <div className="tRow tHoursWorked">
                                <label>Hours Worked</label>
                                <span
                                    className={classNames({
                                        value: true,
                                        inactive: !isTimerStarted || !isTimerRunning,
                                    })}
                                >
                                    {hoursWorked}
                                </span>
                            </div>
                            {hasTimeToLog && isTimerRunning && (
                                <div className="timerActions">
                                    <button
                                        className="secondaryButton"
                                        onClick={this.handleResetTimer}
                                    >
                                        Reset Timer
                                    </button>
                                    <button
                                        className="secondaryButton"
                                        onClick={this.handlePauseOrResumePressed}
                                    >
                                        Pause Timer
                                    </button>
                                </div>
                            )}
                            {hasTimeToLog && !isTimerRunning && (
                                <div className="timerActions">
                                    <button
                                        className="secondaryButton"
                                        onClick={this.handleResetTimer}
                                    >
                                        Reset Timer
                                    </button>
                                    <button
                                        className="secondaryButton"
                                        onClick={this.handlePauseOrResumePressed}
                                    >
                                        Resume Timer
                                    </button>
                                </div>
                            )}
                            {!hasTimeToLog && (
                                <div className="timerActions">
                                    <button
                                        disabled
                                        className="secondaryButton"
                                        onClick={this.handleResetTimer}
                                    >
                                        Reset Timer
                                    </button>
                                    <button
                                        className="primaryButton"
                                        onClick={this.handleStartTimerPressed}
                                    >
                                        Start Timer
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className="entryContainer">
                            <div className="tsEntryCustomerDetailsContainer">
                                <h3 className="formGroupHeading">Customer &amp; Job Details</h3>
                                <div className="fields">
                                    {canEditOthersTime && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Employee</label>
                                            <div className="dropdownContainer">
                                                <Select
                                                    withSearch
                                                    options={canEditEmployees}
                                                    labelWhenNothingSelected="No Employee Selected"
                                                    value={employeeId}
                                                    onChange={this.handleEmployeeSelected}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    <div className="stackedLabelAndDropdown fieldGroup">
                                        <label className="dropdownLabel">Customer &amp; Job</label>
                                        <div className="dropdownContainer">
                                            <Select
                                                withSearch
                                                options={allowedCustomers}
                                                labelWhenNothingSelected="No Customer Selected"
                                                value={customerId}
                                                onChange={this.handleCustomerSelected}
                                            />
                                        </div>
                                    </div>
                                    {no_integration === false && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Service</label>
                                            <div className="dropdownContainer">
                                                <Select
                                                    withSearch
                                                    options={allowedInventoryItems}
                                                    labelWhenNothingSelected="No Service Selected"
                                                    value={inventoryItemId}
                                                    onChange={this.handleInventoryItemSelected}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {no_integration === true && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Item</label>
                                            <div className="dropdownContainer">
                                                <Select
                                                    withSearch
                                                    options={filterdInventoryItems}
                                                    labelWhenNothingSelected="No Item Selected"
                                                    value={inventoryItemId}
                                                    onChange={this.handleInventoryItemSelected}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    
                                    {no_integration === false && shouldShowQbClasses && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Class</label>
                                            <div className="dropdownContainer">
                                                <Select
                                                    withSearch
                                                    withClearOption
                                                    clearOptionLabel="No Class Selected"
                                                    options={allowedQbClasses}
                                                    labelWhenNothingSelected="No Class Selected"
                                                    value={qbClassId}
                                                    onChange={this.handleQbClassSelected}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {shouldShowPayroll && (
                                        <div className="stackedLabelAndDropdown fieldGroup">
                                            <label className="dropdownLabel">Payroll Item</label>
                                            <div className="dropdownContainer">
                                                <Select
                                                    withClearOption
                                                    clearOptionLabel="No Payroll Item"
                                                    labelWhenNothingSelected="No Payroll Item Selected"
                                                    options={payrollItems}
                                                    onChange={this.handlePayrollItemSelected}
                                                    value={payrollItemId}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="tsEntryCustomerDetailsContainer">
                                <h3 className="formGroupHeading noMarginRight">Time</h3>
                                <div className="fieldGroupRow">
                                    <div className="stackedLabelAndDropdown fieldGroup">
                                        <label className="dropdownLabel">Date</label>
                                        <DateTimePicker
                                            toggleStyle="input"
                                            value={date}
                                            onChange={this.handleDateChanged}
                                        />
                                    </div>
                                    <div className="stackedLabelAndDropdown fieldGroup">
                                        <label className="inputLabel">Start Time</label>
                                        <Input
                                            type="time"
                                            value={startTime}
                                            onChange={this.handleStartTimeChanged}
                                        />
                                    </div>
                                </div>

                                <div className="fieldGroupRow">
                                    {!isClockInOnly && (
                                        <div className="stackedLabelAndInput fieldGroup">
                                            <label className="inputLabel">Rounding Interval</label>
                                            <Select
                                                options={Object.keys(timeRoundingIntervals)}
                                                labelForOption={option => option}
                                                valueForOption={option => option}
                                                value={
                                                    roundingInterval === '1'
                                                        ? `${roundingInterval} minute`
                                                        : `${roundingInterval} minutes`
                                                }
                                                onChange={this.handleRoundingIntervalChange}
                                            />
                                        </div>
                                    )}
                                    <div className="stackedLabelAndInput fieldGroup">
                                        <label className="inputLabel">Hours Off Duty</label>
                                        <Input
                                            value={hoursOffDuty === '0:00' ? '' : hoursOffDuty}
                                            placeholder="0:00"
                                            onChange={this.handleHoursOffDutyChanged}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className="inputLabel">Description</label>
                                    <DescriptionInput
                                        value={description ? description : ''}
                                        onChange={this.handleDescriptionChanged}
                                    />
                                </div>

                                <div className="timerBillableButtonContainer">
                                    {shouldShowBillable && (
                                        <div
                                            className="toggleBillableContainer noselect"
                                            onClick={this.handleBillableToggled}
                                        >
                                            <Icon
                                                className="toggleBillableIcon"
                                                size={18}
                                                icon={
                                                    billable === 'Yes'
                                                        ? checkboxChecked
                                                        : checkboxUnchecked
                                                }
                                            />
                                            Billable
                                        </div>
                                    )}
                                    {shouldShowTaxable && billable === 'Yes' && (
                                        <div
                                            className="toggleTaxableContainer noselect"
                                            onClick={this.handleTaxableToggled}
                                        >
                                            <Icon
                                                className="toggleTaxableIcon"
                                                size={18}
                                                icon={
                                                    taxable === 'Yes'
                                                        ? checkboxChecked
                                                        : checkboxUnchecked
                                                }
                                            />
                                            Taxable
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="modalActions">
                            <button className="secondaryButton" onClick={closeTimer}>
                                Cancel
                            </button>
                            <button
                                disabled={!hasTimeToLog}
                                className="primaryButton"
                                onClick={this.saveTimeEntry}
                            >
                                {timeEntryId ? 'Update Time Entry' : 'Add Time Entry'}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    const allowedInventoryItems = businessResourcesSelectors.selectAllowedInventoryItems(state);
    const allowedCustomers = businessResourcesSelectors.selectAllowedCustomers(state);
    const allowedQbClasses = businessResourcesSelectors.selectAllowedQbClasses(state);

    const eId = state.timerStore.employeeId;
    const employees = state.employeeStore.canEditEmployees;
    const employee = employees.find(({ id }) => id === eId);

    let payrollItems = [];
    if (employee && employee.use_time_for_paychecks === 'UseTimeData') {
        payrollItems = state.businessResourcesStore.payrollItemsByEmployeeId[eId] || [];
    } else if (employee && state.accountStore.qb_type === 'None') {
        payrollItems = state.businessResourcesStore.payrollItemsByEmployeeId[eId] || [];
    }
    const mode = 'mode' in state ? state.mode : state.timesheetsStore.mode;
    const isDuplicate =
        'isDuplicate' in state ? state.isDuplicate : !!state.timesheetsStore.duplicate;
    const isEditOrDuplicate = mode === 'edit' || mode === 'duplicate' || isDuplicate;
    const billableToUse =
        state.accountStore.show_billable === 'No'
            ? state.accountStore.billable_by_default
            : state.timerStore.billable;

    const taxableToUse =
        state.accountStore.show_taxable === 'No' && state.accountStore.billable_by_default === 'Yes'
            ? state.accountStore.taxable_by_default
            : state.timerStore.taxable;
    const no_integration = state.accountStore.qb_type === 'None';

    let filterdInventoryItems = [];
    if(no_integration) { 
        if(!state.accountStore.show_items_association_for_customers){
            filterdInventoryItems = state.businessResourcesStore.serviceTypeItems;
        }
        else{
           let cId = state.timerStore.customerId;
           let customer = state.businessResourcesStore.customersById[cId];
           if(customer && customer.item_ids){
               let ids= customer.item_ids.split(",");
               filterdInventoryItems = state.businessResourcesStore.serviceTypeItems.filter(obj => ids.includes(obj.id));
           }
       }
    }
    return {
        accountStore: state.accountStore,
        accountUserStore: state.accountUserStore,
        canEditEmployees: employees,
        ...state.timerStore,
        allowedInventoryItems,
        allowedCustomers,
        allowedQbClasses,
        payrollItems,
        billable: isEditOrDuplicate ? state.timesheetsStore.editBillable : billableToUse,
        taxable: isEditOrDuplicate ? state.timesheetsStore.editTaxable : taxableToUse,
        no_integration,
        inventoryItemId:  state.timerStore.inventoryItemId,
        filterdInventoryItems: filterdInventoryItems,
        default_rate: state.default_rate,
        
    };
}

const mapDispatchToProps = {
    showToast,
    requestTimesheets,
    ...timerActions,
    addTimeEntry: timesheetsActions.addTimeEntry,
    updateTimeEntry: timesheetsActions.updateTimeEntry,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Timer);
