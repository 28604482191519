import React, { Component } from 'react';
import { getPaymentHistory, getMemo } from '../../../requests/AccountRequests.js';
import { showToast } from '../../../actions/Actions.js';
import DetailsLoading from '../../components/DetailsLoading.js';
import NoEntriesFound from '../../components/NoEntriesFound.js';
import { connect } from 'react-redux';
import className from 'classnames';
import uuid from 'uuid';
import Icon from 'react-icons-kit';
import { fileO } from 'react-icons-kit/fa/fileO';
import style from '../../../css/PaymentHistory.module.css';

class PaymentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentHistory: null,
            loading: false,
        };
    }

    componentDidMount() {
        this.getPaymentHistory();
    }

    getPaymentHistory = () => {
        const paramsObject = {
            account_id: this.props.accountStore.id,
        };
        this.setState({ loading: true });
        getPaymentHistory(paramsObject)
            .then(res => {
                this.setState({
                    paymentHistory: res.BillingTransactions,
                    loading: false,
                });
            })
            .catch(() => {
                this.props.showToast('Couldn’t load payment history', 'negative');
                this.setState({ loading: false });
            });
    };

    renderPaymentHistory = () => {
        if (this.state.paymentHistory === null) {
            return null;
        } else if (this.state.paymentHistory.length > 0) {
            let renderedPaymentHistory = [];
            this.state.paymentHistory.forEach((transaction, index) => {
                transaction = transaction.BillingTransaction;
                const paramsObject = {
                    billing_transaction_id: transaction.id,
                };
                const dateToShow = transaction.created.split(' ')[0];
                renderedPaymentHistory.push(
                    <tr
                        key={uuid.v4()}
                        className={index % 2 === 0 ? '' : className(className, style.oddRow)}
                    >
                        <td>
                            <Icon
                                className={className(className, style.memoIcon)}
                                icon={fileO}
                                size={15}
                                onClick={() => getMemo(paramsObject)}
                            />{' '}
                            {dateToShow}
                        </td>
                        <td>{transaction.type}</td>
                        <td>{transaction.description}</td>
                        <td>{transaction.amount}</td>
                    </tr>
                );
            });
            return (
                <table className="dataTable">
                    <tbody>
                        <tr className={className(className, style.paymentTableHeaderRow)}>
                            <th className={className(className, style.dateCell)}>Date</th>
                            <th className={className(className, style.typeCell)}>Type</th>
                            <th className={className(className, style.descriptionCell)}>
                                Description
                            </th>
                            <th className={className(className, style.amountCell)}>Amount</th>
                        </tr>
                        {renderedPaymentHistory}
                    </tbody>
                </table>
            );
        } else {
            return <NoEntriesFound key="noEntries" type="Payment History" />;
        }
    };

    render() {
        return (
            <div className="applicationBody">
                <div className="bodyHeader">
                    <h2>Payment History for {this.props.accountStore.name}</h2>
                    <button
                        onClick={() => this.props.history.push('account_settings')}
                        className="tertiaryButton"
                    >
                        Account Settings
                    </button>
                </div>
                {this.state.loading ? (
                    <DetailsLoading description="Loading Payment History" />
                ) : (
                    <div className="cardContainer">
                        <div className={className(className, style.paymentHistoryTableContainer)}>
                            {this.renderPaymentHistory()}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        accountStore: state.accountStore,
    };
}

const mapDispatchToProps = {
    showToast,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentHistory);
