import React from 'react';
import PropTypes from 'prop-types';
import '../../css/CommonTheme.css';

const NoEntriesFound = ({ type }) => {
    return (
        <div className="noEntriesFoundContainer">
            <div className="noEntriesFoundHeader">No {type} Found</div>
        </div>
    );
};

NoEntriesFound.propTypes = {
    type: PropTypes.string.isRequired,
};

export default NoEntriesFound;
