import * as ReactDOM from 'react-dom';
import * as React from 'react';
import ToastManager from './ToastManager';

const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
const PORTAL_ID = 'toast-portal';

class ToastPortal {
    constructor() {
        if (!isBrowser) {
            return;
        }

        let portalElement;
        const existingPortalElement = document.getElementById(PORTAL_ID);

        if (existingPortalElement) {
            portalElement = existingPortalElement;
        } else {
            const el = document.createElement('div');
            el.id = PORTAL_ID;
            el.className = 'ToastPortal';
            if (document.body != null) {
                document.body.appendChild(el);
            }
            portalElement = el;
        }

        ReactDOM.render(<ToastManager notify={this.bindNotify} />, portalElement);
    }

    bindNotify = fn => {
        this.createNotification = fn;
    };

    notify = (message, options = {}) => {
        if (this.createNotification) {
            this.createNotification(message, options);
        }
    };
}

export default ToastPortal;
