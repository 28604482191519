import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';
import moment from 'moment';
import Select from '../../components/Select';
import DateTimePicker from '../../components/DateTimePicker.js';
import TwoButtonSwitch from '../../components/TwoButtonSwitch.js';
import SaveLoading from '../../components/SaveLoading.js';
import WeekEntryInput from './WeekEntryInput.js';
import DescriptionInput from '../../components/DescriptionInput.js';
import DurationInput from './DurationInput.js';
import { calculateDurationFromStartAndEndTimes } from '../../../utils/DateUtils.js';
import { durationStringToDuration } from '../../../utils/TimeEntryUtils';
import { MIDNIGHT } from '../../../config/Constants.js';
import '../../../css/Timesheets.css';
import { durationToString } from '../../../utils/TimerUtils';

export default class NewTimeEntryForm extends Component {
    static propTypes = {
        mode: PropTypes.oneOf(['add', 'edit', 'duplicate']),
        shouldShowBillable: PropTypes.bool.isRequired,
        shouldShowTaxable: PropTypes.bool.isRequired,
        shouldShowQbClasses: PropTypes.bool.isRequired,
        shouldShowPayrollItems: PropTypes.bool.isRequired,
        canEditOthersTime: PropTypes.bool.isRequired,
        qbClasses: PropTypes.any.isRequired,
        payrollItems: PropTypes.any.isRequired,
        employees: PropTypes.any.isRequired,
        customers: PropTypes.any.isRequired,
        inventoryItems: PropTypes.any.isRequired,
        onSavePressed: PropTypes.func.isRequired,
        onCancelPressed: PropTypes.func,
        entryType: PropTypes.oneOf(['day', 'week']),
        qbClassId: PropTypes.string,
        employeeId: PropTypes.string,
        inventoryItemId: PropTypes.string,
        customerId: PropTypes.string,
        payrollItemId: PropTypes.string,
        durations: PropTypes.object,
        hoursOffDuty: PropTypes.string,
        description: PropTypes.string,
        duration: PropTypes.string,
        billable: PropTypes.string,
        taxable: PropTypes.string,
        date: PropTypes.string,
        isSaving: PropTypes.bool,
        no_integration: PropTypes.bool,
        filterdInventoryItems: PropTypes.array,
    };

    static defaultProps = {
        isSaving: false,
        mode: 'add',
        entryType: 'day',
        description: '',
    };

    isDurationFocused = false;

    componentWillMount() {
        document.addEventListener('keydown', this.handleEnterKeyPressed, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEnterKeyPressed, false);
    }

    handleEnterKeyPressed = event => {
        if (event.code === 'Enter' && this.isDurationFocused) {
            this.props.onSavePressed();
        }
    };

    onDurationFocus = () => {
        this.isDurationFocused = true;
    };

    onDurationBlur = () => {
        this.isDurationFocused = false;
    };

    get shouldShowQbClasses() {
        return this.props.qbClasses.length > 0 && this.props.shouldShowQbClasses;
    }

    get shouldShowPayroll() {
        return this.props.payrollItems.length > 0;
    }

    get shouldShowInventory() {
        return this.props.no_integration === false;
    }

    handleEntryTypeChanged = entryType => {
        this.props.onEntryFieldUpdated('entryType', entryType);
    };

    handleTimeFieldChanged = (date, { target }) => {
        const tempDurations = { ...this.props.durations };
        tempDurations[moment(date).format('ddd')] = target.value.replace(/[^0-9:.]/g, '');

        this.props.onEntryFieldUpdated('durations', tempDurations);
    };

    handleDescriptionChanged = event => {
        this.props.onEntryFieldUpdated('description', event.target.value);
    };

    // Reset start/end times
    handleDurationChanged = ({ target }) => {
        this.props.onEntryFieldUpdated('duration', target.value.replace(/[^0-9:.]/g, ''));
        this.props.onEntryFieldUpdated('startTime', MIDNIGHT);
        this.props.onEntryFieldUpdated('endTime', MIDNIGHT);
        this.props.onEntryFieldUpdated('hoursOffDuty', '0:00');
    };

    handleDateChanged = newDate => {
        this.props.onEntryFieldUpdated('date', newDate);
    };

    handleBillableToggled = () => {
        const billable = !(this.props.billable === 'Yes');
        this.props.onEntryFieldUpdated('billable', billable ? 'Yes' : 'No');
    };

    handleTaxableToggled = () => {
        const taxable = !(this.props.taxable === 'Yes');
        this.props.onEntryFieldUpdated('taxable', taxable ? 'Yes' : 'No');
    };

    handleEmployeeSelected = id => {
        this.props.onEntryFieldUpdated('employeeId', id);
    };

    handleCustomerSelected = id => {
        this.props.onEntryFieldUpdated('customerId', id);
        if(this.props.no_integration){
            this.props.onEntryFieldUpdated('inventoryItemId', "0");
        }
    };

    handleInventoryItemSelected = id => {
        this.props.onEntryFieldUpdated('inventoryItemId', id);
    };

    handlePayrollItemSelected = id => {
        this.props.onEntryFieldUpdated('payrollItemId', id);
    };

    handleQbClassSelected = id => {
        this.props.onEntryFieldUpdated('qbClassId', id);
    };

    updateHoursOffProps = hoursOffDuty => {
        this.props.onEntryFieldUpdated('hoursOffDuty', hoursOffDuty);
    };

    updateStartTimeProps = startTime => {
        this.props.onEntryFieldUpdated('startTime', startTime);
    };

    updateEndTimeProps = endTime => {
        this.props.onEntryFieldUpdated('endTime', endTime);
    };

    calculateDuration = calculationCriteria => {
        const startTime = calculationCriteria.start;
        const endTime = calculationCriteria.end;
        const offHours = calculationCriteria.hoursOffDuty;
        this.props.onEntryFieldUpdated('startTime', startTime);
        this.props.onEntryFieldUpdated('endTime', endTime);
        this.props.onEntryFieldUpdated('hoursOffDuty', offHours);
        const durationFromStartEndTime = calculateDurationFromStartAndEndTimes(startTime, endTime);
        let durationValue = 0;
        if (this.isBlank(durationFromStartEndTime)) durationValue = this.props.duration;
        else durationValue = durationFromStartEndTime;
        const durationAsMilli = durationStringToDuration(durationValue).asMilliseconds();
        const hoursOffAsMilli = durationStringToDuration(offHours).asMilliseconds();
        durationValue = durationAsMilli - hoursOffAsMilli;
        this.props.onEntryFieldUpdated('duration', durationToString(durationValue));
        this.setState({ isMileageCalculatorOpen: false });
    };

    isBlank(hours) {
        return hours === '' || hours === ' ' || hours === '0:00';
    }

    handleStartTimeChanged = value => {
        this.props.onEntryFieldUpdated('startTime', value);
        // Calculate new duration
        this.props.onEntryFieldUpdated(
            'duration',
            calculateDurationFromStartAndEndTimes(value, this.props.endTime)
        );
    };

    handleEndTimeChanged = value => {
        this.props.onEntryFieldUpdated('endTime', value);
        // Calculate new duration
        this.props.onEntryFieldUpdated(
            'duration',
            calculateDurationFromStartAndEndTimes(this.props.startTime, value)
        );
    };

    handleStartOfWeekChanged = ([startDate, endDate]) => {
        this.props.onStartOfWeekChange(startDate);
    };

    render() {
        const {
            qbClasses,
            employees,
            customers,
            inventoryItems,
            payrollItems,
            shouldShowBillable,
            shouldShowTaxable,
            canEditOthersTime,
            mode,
            entryType,
            qbClassId,
            employeeId,
            inventoryItemId,
            customerId,
            payrollItemId,
            durations,
            hoursOffDuty,
            billable,
            taxable,
            duration,
            description,
            date,
            isSaving,
            onSavePressed,
            onCancelPressed,
            onPrevWeek,
            onNextWeek,
            startOfWeek,
            startTime,
            endTime,
            hideEntryTypeToggle,
            no_integration,
            filterdInventoryItems,
        } = this.props;

        const startOfWeekMoment = moment(startOfWeek);
        const startTimeMoment = moment(startTime, 'HH:mm:ss');
        const endTimeMoment = moment(endTime, 'HH:mm:ss');

        const formattedWeekdays = _.range(0, 7).map(index => {
            const date = startOfWeekMoment.toDate();
            const day = startOfWeekMoment.format('ddd');
            const formattedDate = startOfWeekMoment.format('dd M/D');
            startOfWeekMoment.add(1, 'd');

            return {
                day,
                date,
                formattedDate,
            };
        });

        return (
            <div className="entryWrapper">
                <div className="entryContainer">
                    <div className="tsEntryCustomerDetailsContainer">
                        <h3 className="formGroupHeading">Customer &amp; Job Details</h3>

                        <div className="fields">
                            {canEditOthersTime && (
                                <div className="stackedLabelAndDropdown fieldGroup">
                                    <label className="dropdownLabel">Employee</label>
                                    <Select
                                        className="dropdownContainer"
                                        withSearch
                                        options={employees}
                                        value={employeeId}
                                        onChange={this.handleEmployeeSelected}
                                    />
                                </div>
                            )}

                            <div className="stackedLabelAndDropdown fieldGroup">
                                <label className="dropdownLabel">Customer &amp; Job</label>
                                <Select
                                    className="dropdownContainer"
                                    options={customers}
                                    withSearch
                                    labelWhenNothingSelected="No Customer Selected"
                                    value={customerId}
                                    onChange={this.handleCustomerSelected}
                                />
                            </div>

                            {no_integration === true && (
                                <div className="stackedLabelAndDropdown fieldGroup">
                                    <label className="dropdownLabel">Item</label>
                                    <Select
                                        className="dropdownContainer"
                                        withSearch
                                        options={filterdInventoryItems}
                                        labelWhenNothingSelected="No Item Selected"
                                        value={inventoryItemId}
                                        onChange={this.handleInventoryItemSelected}
                                    />
                                </div>
                            )}

                            {no_integration === false && (
                                <div className="stackedLabelAndDropdown fieldGroup">
                                    <label className="dropdownLabel">Service</label>
                                    <Select
                                        className="dropdownContainer"
                                        withSearch
                                        options={inventoryItems}
                                        labelWhenNothingSelected="No Service Selected"
                                        value={inventoryItemId}
                                        onChange={this.handleInventoryItemSelected}
                                    />
                                </div>
                            )}

                            {this.shouldShowQbClasses && (
                                <div className="stackedLabelAndDropdown fieldGroup">
                                    <label className="dropdownLabel">Class</label>
                                    <Select
                                        className="dropdownContainer"
                                        withSearch
                                        options={qbClasses}
                                        labelWhenNothingSelected="No Class Selected"
                                        value={qbClassId}
                                        onChange={this.handleQbClassSelected}
                                        withClearOption
                                        clearOptionLabel="No Class Selected"
                                    />
                                </div>
                            )}

                            {this.shouldShowPayroll && (
                                <div className="stackedLabelAndDropdown fieldGroup">
                                    <label className="dropdownLabel">Payroll Item</label>
                                    <Select
                                        className="dropdownContainer"
                                        withClearOption
                                        clearOptionLabel="No Payroll Item"
                                        labelWhenNothingSelected="No Payroll Item Selected"
                                        options={payrollItems}
                                        onChange={this.handlePayrollItemSelected}
                                        value={payrollItemId}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="tsEntryTimeContainer">
                        <h3 className="formGroupHeading">
                            Time
                            {!hideEntryTypeToggle && (
                                <TwoButtonSwitch
                                    firstButtonText="Day Entry"
                                    firstButtonValue="day"
                                    secondButtonText="Week Entry"
                                    secondButtonValue="week"
                                    onChange={this.handleEntryTypeChanged}
                                    value={entryType}
                                />
                            )}
                            {entryType === 'week' && (
                                <div className="headingActions">
                                    <DateTimePicker
                                        mode="week"
                                        toggleStyle="labelAndIcon"
                                        toggleLabel="Pick Week"
                                        todayButtonLabel="This Week"
                                        className="tsWeekPicker"
                                        value={startOfWeek}
                                        onChange={this.handleStartOfWeekChanged}
                                    />
                                </div>
                            )}
                        </h3>

                        {entryType === 'day' && (
                            <div className="fieldGroupRow">
                                <div className="stackedLabelAndInput fieldGroup">
                                    <label className="inputLabel">Duration</label>
                                    <DurationInput
                                        onDurationChanged={this.handleDurationChanged}
                                        onDurationBlur={this.onDurationBlur}
                                        onDurationFocus={this.onDurationFocus}
                                        onStartTimeChanged={this.handleStartTimeChanged}
                                        startTime={startTimeMoment}
                                        onEndTimeChanged={this.handleEndTimeChanged}
                                        endTime={endTimeMoment}
                                        duration={duration}
                                        withStartAndEndTime
                                        hoursOffDuty={hoursOffDuty}
                                        updateHoursOffProps={this.updateHoursOffProps}
                                        calculateDuration={this.calculateDuration}
                                        updateStartTimeProps={this.updateStartTimeProps}
                                        updateEndTimeProps={this.updateEndTimeProps}
                                    />
                                </div>

                                <div className="stackedLabelAndDropdown fieldGroup">
                                    <label className="dropdownLabel">Date</label>
                                    <DateTimePicker
                                        toggleStyle="input"
                                        className="tsDateTimePicker"
                                        value={date}
                                        onChange={this.handleDateChanged}
                                    />
                                </div>
                            </div>
                        )}

                        {entryType === 'week' && (
                            <WeekEntryInput
                                formattedWeekdays={formattedWeekdays}
                                onDurationBlur={this.onDurationBlur}
                                onDurationFocus={this.onDurationFocus}
                                handleTimeFieldChanged={this.handleTimeFieldChanged}
                                durations={durations}
                                handlePreviousWeekPressed={onPrevWeek}
                                handleNextWeekPressed={onNextWeek}
                            />
                        )}

                        <div className="stackedLabelAndInput fieldGroup fullWidthField">
                            <label className="inputLabel">Description</label>
                            <DescriptionInput
                                value={description ? description : ''}
                                onChange={this.handleDescriptionChanged}
                            />
                        </div>

                        {shouldShowBillable && (
                            <div className="tsBillableButtonContainer">
                                <div
                                    className="toggleBillableContainer noselect"
                                    onClick={this.handleBillableToggled}
                                >
                                    <Icon
                                        className="toggleBillableIcon"
                                        size={18}
                                        icon={
                                            billable === 'Yes' ? checkboxChecked : checkboxUnchecked
                                        }
                                    />
                                    Billable
                                </div>
                            </div>
                        )}
                        {shouldShowTaxable && billable === 'Yes' && (
                            <div className="tsBillableButtonContainer">
                                <div
                                    className="toggleTaxableContainer noselect"
                                    onClick={this.handleTaxableToggled}
                                >
                                    <Icon
                                        className="toggleTaxableIcon"
                                        size={18}
                                        icon={
                                            taxable === 'Yes' ? checkboxChecked : checkboxUnchecked
                                        }
                                    />
                                    Taxable
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isSaving && (
                    <div className="entryActions">
                        <SaveLoading />
                    </div>
                )}
                {!isSaving && (
                    <div className="entryActions">
                        {onCancelPressed && (
                            <button className="secondaryButton" onClick={onCancelPressed}>
                                Cancel
                            </button>
                        )}
                        <button className="primaryButton" onClick={onSavePressed}>
                            {mode === 'add' || mode === 'duplicate'
                                ? 'Add Timesheet'
                                : 'Update Timesheet'}
                        </button>
                    </div>
                )}
            </div>
        );
    }
}
