import React from 'react';

const Minute7WithOnlineEdition = () => (
    <div>
        <div className="sectionQuestion">
            Can I use Minute7 if my company uses QuickBooks Online Edition?
        </div>
        <div className="sectionAnswer">
            <b>Absolutely!</b> Minute7 syncs seamlessly with QuickBooks Online Edition. You can
            choose to have your Minute7 account sync with QuickBooks Online Edition when you sign up
            or sign in and navigate to the Sync Tab (located at the top of the page)
        </div>
        <div className="sectionButton">
            <button
                className="secondaryButton sectionFont"
                onClick={() => (window.location.href = '/free_trial')}
            >
                Start Your Free Trial
            </button>
        </div>
    </div>
);

export default Minute7WithOnlineEdition;
