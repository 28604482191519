export const INITIAL_STATE = {
    isSaving: false,
    id: null,
    account_id: null,
    permission_group_id: null,
    employee_id: null,
    type: null,
    expense_vendor_id: null,
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    phone: '',
    manage_account: null,
    manage_users: null,
    manage_api: null,
    export_time: null,
    view_reports: null,
    clock_in_only: null,
    edit_own_time: null,
    view_others_time: null,
    edit_others_time: null,
    approve_others_time: null,
    filter_view_employee_ids: null,
    filter_edit_employee_ids: null,
    filter_approve_employee_ids: null,
    edit_own_expenses: null,
    edit_others_expenses: null,
    view_others_expenses: null,
    approve_others_expenses: null,
    filter_view_expense_vendor_ids: null,
    filter_edit_expense_vendor_ids: null,
    filter_approve_expense_vendor_ids: null,
    edit_payroll: null,
    filter_customer_list: null,
    filter_customer_ids: null,
    filter_inventory_item_list: null,
    filter_inventory_item_ids: null,
    filter_qb_account_list: null,
    filter_qb_account_ids: null,
    filter_qb_class_list: null,
    filter_qb_class_ids: null,
    week_start_offset: null,
    show_most_recent: null,
    show_description_text_field: null,
    enable_improved_selects: null,
    description_text_field_rows: null,
    show_tips: null,
    timesheet_grouping: 'week',
    preferred_distance_units: 'miles',
    reimbursement_rate: null,
    time_prepend_name: null,
    expenses_prepend_name: null,
    time_entry_reminder_frequency: null,
    time_entry_reminder_minimum_hours: null,
    time_entry_reminder_sent: null,
    export_reminder_interval: null,
    export_reminder_sent: null,
    show_mobile_timer: null,
    show_start_end_times: null,
    created: null,
    modified: null,
    manage_account_override_group: null,
    manage_users_override_group: null,
    manage_api_override_group: null,
    export_time_override_group: null,
    view_reports_override_group: null,
    edit_own_time_override_group: null,
    view_others_time_override_group: null,
    edit_others_time_override_group: null,
    approve_others_time_override_group: null,
    filter_view_employee_ids_override_group: null,
    filter_edit_employee_ids_override_group: null,
    filter_approve_employee_ids_override_group: null,
    edit_own_expenses_override_group: null,
    edit_others_expenses_override_group: null,
    view_others_expenses_override_group: null,
    approve_others_expenses_override_group: null,
    filter_view_expense_vendor_ids_override_group: null,
    filter_edit_expense_vendor_ids_override_group: null,
    filter_approve_expense_vendor_ids_override_group: null,
    edit_payroll_override_group: null,
    filter_customer_list_override_group: null,
    filter_customer_ids_override_group: null,
    filter_inventory_item_list_override_group: null,
    filter_inventory_item_ids_override_group: null,
    filter_qb_account_list_override_group: null,
    filter_qb_account_ids_override_group: null,
    filter_qb_class_list_override_group: null,
    filter_qb_class_ids_override_group: null,
    manage_payroll_payrates_override_group: null,
    manage_item_billrates_override_group: null,
    manage_expenses_override_group: null,
    restrictions_override_group: null,
    manage_time_override_group: null,
    impersonated: false,
    is_admin: false
};

export const accountUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_ACCOUNT_USER_STORE':
            return {
                ...state,
                ...action.payload,
            };
        case 'UPDATE_USER':
        case 'ADD_USER': {
            return {
                ...state,
                isSaving: true,
            };
        }
        case 'UPDATE_USER_SUCCESS': {
            // only update if the user we updated is the current user
            if (action.user.id === state.id) {
                return {
                    ...state,
                    ...action.user,
                    isSaving: false,
                };
            }
            return {
                ...state,
                isSaving: false,
            };
        }
        case 'ADD_USER_SUCCESS': {
            return {
                ...state,
                isSaving: false,
            };
        }
        case 'UPDATE_USER_ERROR':
        case 'ADD_USER_ERROR': {
            return {
                ...state,
                isSaving: false,
            };
        }
        case 'LOGOUT':
            return INITIAL_STATE;
        case 'IMPERSONATE_STORE_CLEAR':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default accountUserReducer;
