import React from 'react';

const BillingStatus = () => (
    <div>
        <div className="sectionQuestion">
            The billing status of my expenses is not always showing up in QuickBooks. Why not?
        </div>
        <div className="sectionAnswer">
            Although Minute7 allows users to set the billing status of all expenses, QuickBooks only
            accepts and displays billing information for accounts that are reimbursable. QuickBooks
            regards any accounts that have the type Expense, Other Expense, or Other Current Asset
            as reimbursable. If you mark billing status information for expenses associated with
            other types of accounts, the billing status will still be recorded and displayed in
            Minute7, but it will not be reflected in QuickBooks.
        </div>
    </div>
);

export default BillingStatus;
