import React, { Component } from 'react';
import AccountUserSettings from '../../account_user_settings/AccountUserSettings.js';

class EditAccountUser extends Component {
    render() {
        return <AccountUserSettings {...this.props} fromAccountSettings={true} />;
    }
}

export default EditAccountUser;
