import React, { Component } from 'react';
import className from 'classnames';
import styles from '../../../css/Home/MobileHome.module.css';
import Icon from 'react-icons-kit';
import { apple } from 'react-icons-kit/fa/apple';
import { android } from 'react-icons-kit/fa/android';
import { desktop } from 'react-icons-kit/fa/desktop';
import { clockO } from 'react-icons-kit/fa/clockO';
import { creditCard } from 'react-icons-kit/fa/creditCard';
import { lock } from 'react-icons-kit/fa/lock';
import { mobile } from 'react-icons-kit/fa/mobile';
import { paperclip } from 'react-icons-kit/fa/paperclip';
import { usd } from 'react-icons-kit/fa/usd';
import { calculator } from 'react-icons-kit/fa/calculator';
import { barChart } from 'react-icons-kit/fa/barChart';
import { phone } from 'react-icons-kit/fa/phone';
import { envelope } from 'react-icons-kit/fa/envelope';

import { CONSTANTS } from '../../../config/Constants.js';
import HomeCircleIcon from './HomeCircleIcon.js';
import { Button } from 'reactstrap';

export default class MobileHome extends Component {
    renderTestimonies = () => {
        const testimonies = this.props.testimonies;
        const testimonyPage = this.props.testimonyPage;
        return [testimonies[testimonyPage * 2], testimonies[testimonyPage * 2 + 1]];
    };

    handleScheduleDemo = () => {
        // Add LinkedIn tracking before changing the window location
        window.lintrk('track', { conversion_id: 14782602 });

        // Redirect to the specified URL after tracking
        window.location.href = CONSTANTS.bookDemo;
    };

    render() {
        return (
            <div>
                <div className={className(className, styles.mobileHomeContainer)}>
                    <section className={className(className, styles.banner)} id="home">
                        <div className={className(className, styles.introduction)}>
                            <div className="banner-img wow fadeInRight animated">
                                <img
                                    className={className(className, styles.imgResponsiveMobile)}
                                    srcSet="/img/sales/cross-platform@2x.png 2x, /img/sales/cross-platform.png 1x"
                                    src="/img/sales/cross-platform.png"
                                    alt=""
                                />
                            </div>
                            <h2 className={className(className, styles.introH2)}>
                                Introducing Minute7
                            </h2>
                            <h1 className={className(className, styles.introQb)}>
                                QuickBooks time and expense tracking in the cloud
                            </h1>
                            <h5 className={className(className, styles.whiteText)}>
                                Minute7 syncs with QuickBooks Desktop and QuickBooks Online and
                                enables employees to enter time and expenses from their PCs, Macs,
                                iPhone and Android devices.
                            </h5>
                            <div className="note">*No Credit Card Required</div>
                            <button
                                className="primaryButton"
                                onClick={() => this.props.history.push('/free_trial')}
                            >
                                30 DAY FREE TRIAL
                            </button>
                            <Button
                                className={className(className, styles.mobileScheduleDemoButton)}
                                outline
                                color="primary"
                                onClick={this.handleScheduleDemo}
                            >
                                SCHEDULE A DEMO
                            </Button>
                        </div>
                    </section>
                    <section className="features text-center" id="features">
                        <h4>Minute7</h4>
                        <h2>Features &amp; Benefits</h2>
                        <img className="img-responsive" src="/img/sales/daag.png" alt="" />
                        <div className={className(className, styles.featureContainer)}>
                            <div className="wow zoomIn animated" aria-hidden="true">
                                <HomeCircleIcon icon={clockO} size={32} />
                                <h3>Timesheets</h3>
                                <p>
                                    Employees and contractors can access their web-based timesheet
                                    online from any web browser, day or night.
                                </p>
                            </div>
                            <div className="wow zoomIn animated">
                                <HomeCircleIcon icon={creditCard} size={32} />
                                <h3>Expense Tracking</h3>
                                <p>
                                    Time is money, and money is money too — so track your employee
                                    and contractor expenses with Minute7. Then, import them
                                    seamlessly to QuickBooks for invoicing or reimbursement.
                                </p>
                            </div>
                            <div className="wow zoomIn animated">
                                <HomeCircleIcon icon={lock} size={34} />
                                <h3>Secure Data</h3>
                                <p>
                                    We're partners with Amazon Web Services®, the leading cloud
                                    hosting provider and you bet we back it up.
                                </p>
                            </div>
                            <div className="wow zoomIn animated">
                                <HomeCircleIcon icon={barChart} size={40} />
                                <h3>Reporting</h3>
                                <p>
                                    Quickly and effectively view summaries and reports of all the
                                    time your employees and vendors have entered. Even export the
                                    raw data to Excel.
                                </p>
                            </div>
                            <div className="wow zoomIn animated">
                                <HomeCircleIcon icon={mobile} size={40} />
                                <h3>Mobile</h3>
                                <p>
                                    Employees and contractors can access their timesheets online and
                                    on mobile devices. Enter time in the field, on the road or at
                                    the meeting.
                                </p>
                            </div>
                            <div className="zoomIn animated">
                                <HomeCircleIcon icon={calculator} size={32} />
                                <h3>Mileage Calculator</h3>
                                <p>
                                    Need traveling reimbursement? Let us help in calculating the
                                    cost. We pull the most recent reimbursment rate from the IRS.
                                </p>
                            </div>
                            <div className="wow zoomIn animated">
                                <HomeCircleIcon icon={paperclip} size={32} />
                                <h3>Attach Receipts</h3>
                                <p>
                                    Add attachments to expense entries. Take a picture with your
                                    phone and add the receipt directly to your expense entry.
                                </p>
                            </div>
                            <div className="wow zoomIn animated">
                                <HomeCircleIcon icon={usd} size={32} />
                                <h3>Simple Pricing</h3>
                                <p>
                                    $8/user/month. There are no signup, setup, software, hardware,
                                    download or update costs. You can sign up for a free trial and
                                    cancel at any time with no obligations. There is no effect on
                                    your QuickBooks data if you cancel.
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="details text-center" id="about">
                        <h4>Cross-platform</h4>
                        <h2>Beautiful on every device</h2>
                        <div>
                            <img src="/img/sales/daag.png" alt="" />
                        </div>
                        <img
                            className={className(className, styles.imgResponsiveMobile)}
                            srcSet="/img/sales/cross-platform@2x.png 2x, /img/sales/cross-platform.png 1x"
                            src="/img/sales/cross-platform.png"
                            alt="Cross Platform"
                        />
                        <div className={className(className, styles.designed)}>
                            We designed Minute7 so your whole team can use it on any device they
                            have available.
                        </div>
                        <div className={className(className, styles.platforms)}>
                            <ul>
                                <li>
                                    <div className="deviceAndLabelContainer">
                                        <Icon className="deviceIcon" size={25} icon={desktop} />
                                        <img
                                            className="appstoreButton"
                                            src="./popular_browsers.png"
                                            alt="Major Browsers"
                                        />
                                    </div>
                                </li>
                                <li>
                                    <div className="deviceAndLabelContainer">
                                        <Icon className="deviceIcon" size={25} icon={apple} />
                                        <a
                                            href="https://itunes.apple.com/us/app/minute7/id812540141?ls=1&mt=8"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className="appstoreButton"
                                                src="./appstore_btn.png"
                                                alt="Apple"
                                            />
                                        </a>
                                    </div>
                                </li>
                                <li>
                                    <div className="deviceAndLabelContainer">
                                        <Icon className="deviceIcon" size={25} icon={android} />
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.minute7.mobile"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                className="appstoreButton"
                                                src="./google_play_store_btn.png"
                                                alt="Android"
                                            />
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className={className(className, styles.entrepreneursLoveUsSection)}>
                        <div className={className(className, styles.featureDetails)}>
                            <h4>For Teams of All Sizes</h4>
                            <h2>Entrepreneurs love us</h2>
                            <img className="img-responsive" src="/img/sales/daag.png" alt="" />
                            <div className={className(className, styles.magContainer)}>
                                <img
                                    className={className(className, styles.magazine)}
                                    src="/img/sales/magazine_cover.png"
                                    alt="phone"
                                />
                            </div>
                            <div className={className(className, styles.entreDescription)}>
                                Whether your team is 1 or 100, setting up Minute7 couldn't be
                                easier. This is part of why we've been featured by Entrepreneur
                                magazine as a top tool to help business owners efficient ways to
                                track time.
                            </div>
                        </div>
                    </section>
                    <section
                        className={className(className, styles.testimonialsSection)}
                        id="testimonials"
                    >
                        <div className="pricing-slide-heading text-center">
                            <h4>Our customers love us</h4>
                            <h2 className={className(className, styles.whiteText)}>Testimonials</h2>
                            <img className="img=responsive" src="/img/sales/daag.png" alt="" />
                        </div>
                        <div className="the-pricing-slider">
                            <div
                                id="carousel-example-generic"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <ol className="carousel-indicators">
                                    <li
                                        data-target="#carousel-example-generic"
                                        data-slide-to="0"
                                        className={this.props.testimonyPage === 0 ? 'active' : ''}
                                        onClick={() => this.props.testimonyChangeOnClick(0)}
                                    />
                                    <li
                                        data-target="#carousel-example-generic"
                                        data-slide-to="1"
                                        className={this.props.testimonyPage === 1 ? 'active' : ''}
                                        onClick={() => this.props.testimonyChangeOnClick(1)}
                                    />
                                    <li
                                        data-target="#carousel-example-generic"
                                        data-slide-to="2"
                                        className={this.props.testimonyPage === 2 ? 'active' : ''}
                                        onClick={() => this.props.testimonyChangeOnClick(2)}
                                    />
                                </ol>
                                <div className="carousel-inner" role="listbox">
                                    <div className={className(className, styles.testimonies)}>
                                        {this.renderTestimonies()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className={className(className, styles.freeTrialBanner)}>
                        <div className={className(className, styles.likeWhatYouSee)}>
                            Like What you see?
                        </div>
                        <div className={className(className, styles.tryUs)}>
                            Try us out free for 30 days
                        </div>
                        <button
                            className="primaryButton"
                            onClick={() => this.props.history.push('/free_trial')}
                        >
                            30 DAY FREE TRIAL
                        </button>
                    </div>
                    <section id="pricing">
                        <div className={className(className, styles.pricingContainer)}>
                            <h4>The Best Value</h4>
                            <h2>Pricing</h2>
                            <img className="img-responsive" src="/img/sales/daag.png" alt="" />
                            <br />
                            <div className={className(className, styles.pricingTable)}>
                                <div className="wow zoomIn animated">
                                    <div className="single-table free">
                                        <div className="table-heading">
                                            <h3>PER USER</h3>
                                        </div>
                                        <div className="table-price">
                                            <h5>$8</h5>
                                            <p>per month</p>
                                        </div>
                                        <div className="table-description">
                                            <p>
                                                That's it.
                                                <br />
                                                <br />
                                                No hidden signup, setup, software, hardware,
                                                download, or update costs.
                                                <a href="/free_trial">Try us free for 30 days</a>
                                            </p>
                                            <a
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.props.history.push('/free_trial');
                                                }}
                                                className="btn btn-primary"
                                                href="/free_trial"
                                            >
                                                START YOUR TRIAL
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className={className(className, styles.contactUsContainer)}>
                        <h4>Get in touch</h4>
                        <h2>Contact us</h2>
                        <img className="img-responsive" src="/img/sales/daag.png" alt="" />
                        <div className={className(className, styles.contactIcons)}>
                            <div className={className(className, styles.contactIconContainer)}>
                                <Icon
                                    size={32}
                                    icon={phone}
                                    className={className(className, styles.contactUsIcon)}
                                />
                                <p>
                                    <a href="tel:1-858-736-9366">(858) 736-9366</a>
                                </p>
                            </div>
                            <div className={className(className, styles.contactIconContainer)}>
                                <Icon
                                    size={32}
                                    icon={envelope}
                                    className={className(className, styles.contactUsIcon)}
                                />
                                <p>
                                    <a href="mailto:support@minute7.com">support@minute7.com</a>
                                </p>
                            </div>
                        </div>
                        <div className={className(className, styles.socialIconsRow)}>
                            <a
                                href="https://www.facebook.com/Minute7"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className={className(className, styles.socialIcon)}
                                    src="./facebook_icon.png"
                                    alt="Facebook"
                                />
                            </a>
                            <a
                                href="https://twitter.com/Minute7Inc"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className={className(className, styles.socialIcon)}
                                    src="./twitter_icon.png"
                                    alt="Twitter"
                                />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/minute7/?viewAsMember=true"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    className={className(className, styles.socialIcon)}
                                    src="./linkedin_icon.png"
                                    alt="Linked In"
                                />
                            </a>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
