import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import {
    showAlert,
    showToast,
    selectEmployee,
    selectVendor,
    selectCustomer,
    selectPayrollItem,
    selectInventoryItem,
    selectAccount,
} from '../../../actions/Actions.js';
import { setAccountStore } from '../../../actions/AccountActions.js';
import {
    setCanViewEmployees,
    setCanEditEmployees,
    setAllEmployees,
    hideEmployee,
    unhideEmployee,
} from '../../../actions/EmployeeActions.js';
import { setAllVendors, hideVendor, unhideVendor } from '../../../actions/VendorActions.js';
import { setAllCustomers, hideCustomer, unhideCustomer } from '../../../actions/CustomerActions';
import {
    setAllPayrollItems,
    hidePayrollItem,
    unhidePayrollItem,
} from '../../../actions/PayrollItemAction.js';

import { setAllItems, hideItem, unhideItem } from '../../../actions/ItemAction.js';
import { setAllAccounts, hideAccount, unhideAccount } from '../../../actions/AccountsActions.js';
import { receiveCustomers } from '../../../actions/BusinessResourcesActions.js';
import '../../../css/CompanySettings.css';
import * as resourceSelectors from '../../../selectors/BusinessResourcesSelectors';
import EmployeeResourcesList from '../../employees/components/EmployeeResourcesList.js';
import VendorResourcesList from '../../vendors/components/VendorResourcesList.js';
import CustomersResourcesList from '../../customers/components/CustomersResourcesList.js';
import PayrollItemResourcesList from '../../payroll_item/components/PayrollItemResourceList.js';
import ItemResourcesList from '../../inventory_item/components/ItemResourceList.js';
import AccountResourcesList from '../../accounts/components/AccountResourceList.js';
import { requestBusinessResources } from '../../../actions/BusinessResourcesActions';
import * as accountSelectors from '../../../selectors/AccountSelectors';

class ResourceSettings extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState();
    }

    defaultState = () => {
        const { accountStore, match } = this.props;
        this.props.requestBusinessResources();
        return {
            name: accountStore.name,
            file: null,
            isUploadModalOpen: false,
            uploadModalOnConfirm: null,
            selectedResource: match.params.path ? match.params.path : 'employees',
            render: false,
        };
    };

    componentDidMount() {
        this.setState({ render: true });
    }

    handleEditEmployeePressed = (employeeId, currentEmployee_id) => {
        if((currentEmployee_id  !== employeeId.id)  || this.props.currentUser.manage_account === "Yes"){
            this.props.history.push('/edit_employee');
            this.props.selectEmployee(employeeId);
        }
    };

    handleEditVendorPressed = (vendorId, currentVendorId) => {
        if(currentVendorId  !== vendorId.id){
            this.props.selectVendor(vendorId);
            this.props.history.push('/edit_vendor');
        }
    };

    handleEditCustomerPressed = customerId => {
        this.props.selectCustomer(customerId);
        this.props.history.push('/edit_customer');
    };

    handleEditPayrollItemPressed = payrollItemId => {
        this.props.selectPayrollItem(payrollItemId);
        this.props.history.push('/edit_payroll_item');
    };

    handleEditItemPressed = itemId => {
        this.props.selectInventoryItem(itemId);
        this.props.history.push('/edit_item');
    };

    handleEditAccountPressed = accountId => {
        this.props.selectAccount(accountId);
        this.props.history.push('/edit_account');
    };

    handleSubMenuClick = value => {
        this.setState({ selectedResource: value });
        this.props.history.push(`/resource_settings/${value}`);
    };

    render() {
        const { pathname } = this.props.history.location;
        const { accountStore, history, payrollItems, canManageAccount, showToast, canManagePayroll,canManageItemBillrate  } = this.props;
        const { selectedResource } = this.state;
        if (this.state.render) {
            return (
                <div className="applicationWrapper">
                    <div className="secondaryNav">
                        <button
                            className={classNames({
                                secondaryNavLink: true,
                                secondaryNavActiveLink: pathname === '/account_settings',
                            })}
                            onClick={() => this.props.history.push('/account_settings')}
                        >
                            Account Settings
                        </button>
                        {canManageAccount && (
                            <button
                                className={classNames({
                                    secondaryNavLink: true,
                                    secondaryNavActiveLink: pathname === '/company_settings',
                                })}
                                onClick={() => this.props.history.push('/company_settings')}
                            >
                                Company Settings
                            </button>
                        )}
                        {accountStore.qb_type === 'None' && (
                            <button
                                className={classNames({
                                    secondaryNavLink: true,
                                    secondaryNavActiveLink: pathname === '/resource_settings',
                                })}
                                onClick={() => this.props.history.push('resource_settings')}
                            >
                                Resource Settings
                            </button>
                        )}
                    </div>

                    <div className="w-50 center resourceNav">
                        <nav class="nav nav-pills flex-column flex-sm-row">
                            <button
                                className={`flex-sm-fill text-sm-center ${
                                    selectedResource === 'employees'
                                        ? 'onClickedRowActionButton'
                                        : 'rowActionButton'
                                }`}
                                //    onClick={() => this.setState({ selectedResource: 'employees' })}
                                onClick={() => this.handleSubMenuClick('employees')}
                            >
                                Employees
                            </button>
                            <button
                                className={`flex-sm-fill text-sm-center ${
                                    selectedResource === 1
                                        ? 'onClickedRowActionButton'
                                        : 'rowActionButton'
                                }`}
                                //    onClick={() => this.setState({ selectedResource: 'employees' })}
                                onClick={() => this.handleSubMenuClick('vendors')}
                            >
                                Vendors
                            </button>
                            <button
                                className={`flex-sm-fill text-sm-center ${
                                    selectedResource === 2
                                        ? 'onClickedRowActionButton'
                                        : 'rowActionButton'
                                }`}
                                //    onClick={() => this.setState({ selectedResource: 'employees' })}
                                onClick={() => this.handleSubMenuClick('customers')}
                            >
                                Customers
                            </button>
                            {canManageItemBillrate && (<button
                                className={`flex-sm-fill text-sm-center ${
                                    selectedResource === 3
                                        ? 'onClickedRowActionButton'
                                        : 'rowActionButton'
                                }`}
                                onClick={() => this.handleSubMenuClick('items')}
                            >
                                Items
                            </button>)}
                            {canManagePayroll && (
                                <button
                                className={`flex-sm-fill text-sm-center ${
                                    selectedResource === 4
                                        ? 'onClickedRowActionButton'
                                        : 'rowActionButton'
                                }`}
                                onClick={() => this.handleSubMenuClick('payroll_items')}
                            >
                                Payroll Items
                            </button>
                            )}
                       
                            <button
                                className={`flex-sm-fill text-sm-center ${
                                    selectedResource === 5
                                        ? 'onClickedRowActionButton'
                                        : 'rowActionButton'
                                }`}
                                onClick={() => this.handleSubMenuClick('accounts')}
                            >
                                Accounts
                            </button>
                        </nav>
                    </div>
                    <div className="bodyHeader applicationBody">
                        <h2>Company: {accountStore.name}</h2>
                    </div>
                    {selectedResource === 'employees' && (
                        <div className="applicationBody">
                            <EmployeeResourcesList
                                title="Employees"
                                history={history}
                                payrollItems={payrollItems}
                                onClick={this.handleEditEmployeePressed}
                                createNewLabel="Create New Employee"
                                createNewPath="/create_new_employee"
                                addActionTip="Edit"
                                activateEmployee={this.props.unhideEmployee}
                                deactivateEmployee={this.props.hideEmployee}
                                currentUserId={this.props.currentUser.employee_id?this.props.currentUser.employee_id:false}
                                manageAccount={this.props.currentUser.manage_account === "Yes"}
                            />
                        </div>
                    )}
                    {selectedResource === 'vendors' && (
                        <div className="applicationBody">
                            <div className="bodyHeader" />
                            <VendorResourcesList
                                title="Vendors"
                                history={history}
                                onClick={this.handleEditVendorPressed}
                                createNewLabel="Create New Vendor"
                                createNewPath="/create_new_vendor"
                                addActionTip="Edit"
                                activateVendor={this.props.unhideVendor}
                                deactivateVendor={this.props.hideVendor}
                                currentUserId={this.props.currentUser.expense_vendor_id?this.props.currentUser.expense_vendor_id:false}
                            />
                        </div>
                    )}
                    {selectedResource === 'customers' && (
                        <div className="applicationBody">
                            <div className="bodyHeader" />
                            <CustomersResourcesList
                                title="Customers"
                                history={history}
                                onClick={this.handleEditCustomerPressed}
                                createNewLabel="Create New Customer"
                                createNewPath="/create_new_customer"
                                addActionTip="Edit"
                                activateCustomer={this.props.unhideCustomer}
                                deactivateCustomer={this.props.hideCustomer}
                                showToast={showToast}
                            />
                        </div>
                    )}
                    {selectedResource === 'items' && (
                        <div className="applicationBody">
                            <div className="bodyHeader" />
                            <ItemResourcesList
                                title="Items"
                                history={history}
                                onClick={this.handleEditItemPressed}
                                createNewLabel="Create New  Item"
                                createNewPath="/create_new_item"
                                addActionTip="Edit"
                                activateItem={this.props.unhideItem}
                                deactivateItem={this.props.hideItem}
                                showToast={showToast}
                            />
                        </div>
                    )}
                    {selectedResource === 'payroll_items' && (
                        <div className="applicationBody">
                            <div className="bodyHeader" />
                            <PayrollItemResourcesList
                                title="Payroll Items"
                                history={history}
                                onClick={this.handleEditPayrollItemPressed}
                                createNewLabel="Create New Payroll Item"
                                createNewPath="/create_new_payroll_item"
                                addActionTip="Edit"
                                activatePayrollItem={this.props.unhidePayrollItem}
                                deactivatePayrollItem={this.props.hidePayrollItem}
                                showToast={showToast}
                            />
                        </div>
                    )}
                    {selectedResource === 'accounts' && (
                        <div className="applicationBody">
                            <div className="bodyHeader" />
                            <AccountResourcesList
                                title="Accounts"
                                history={history}
                                onClick={this.handleEditAccountPressed}
                                createNewLabel="Create New Account"
                                createNewPath="/choose_new_account_type"
                                addActionTip="Edit"
                                activateAccount={this.props.unhideAccount}
                                deactivateAccount={this.props.hideAccount}
                                showToast={showToast}
                            />
                        </div>
                    )}
                </div>
            );
        }
    }
}

function mapStateToProps(state, ownProps) {
    const { match } = ownProps;
    let currentUser;
    if (match && match.params && match.params.id) {
        currentUser = users.find(u => u.id === match.params.id);
    } else {
        currentUser = state.accountUserStore;
    }
    const users = accountSelectors.selectUsersSorted(state);
    return {
        currentUser,
        accountStore: state.accountStore,
        canManagePayroll: state.accountUserStore.manage_payroll_payrates === 'Yes',
        canManageItemBillrate: state.accountUserStore.manage_item_billrates === 'Yes',

        //  employees: state.employeeStore.allEmployees,
        inventoryItems: resourceSelectors.selectAllowedInventoryItems(state),
        payrollItems: state.businessResourcesStore.payrollItems,
        canManageAccount: state.accountUserStore.manage_account === 'Yes',
    };
}

const mapDispatchToProps = {
    showAlert,
    showToast,
    setAccountStore,
    selectEmployee,
    selectVendor,
    setCanViewEmployees,
    setCanEditEmployees,
    setAllEmployees,
    setAllVendors,
    receiveCustomers,
    hideEmployee,
    unhideEmployee,
    hideVendor,
    unhideVendor,
    selectCustomer,
    setAllCustomers,
    hideCustomer,
    unhideCustomer,
    selectPayrollItem,
    setAllPayrollItems,
    hidePayrollItem,
    unhidePayrollItem,
    selectInventoryItem,
    setAllItems,
    hideItem,
    unhideItem,
    selectAccount,
    setAllAccounts,
    hideAccount,
    unhideAccount,
    requestBusinessResources,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceSettings);
