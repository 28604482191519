import React from 'react';
import ToggleSetting from '../../components/ToggleSetting';

const Permissions = ({
    permissionsGroupId,
    manageUsers,
    manageUsersOverrideGroup,
    customers,
    inventoryItems,
    allowedQbAccounts,
    allowedQbPaymentAccounts,
    qbClasses,
    employees,
    vendors,
    exportTime,
    exportTimeOverrideGroup,
    viewReports,
    viewReportsOverrideGroup,
    clockInOnly,
    editPayroll,
    editPayrollOverrideGroup,
    managePayrollPayratesOverrideGroup,
    manageItemBillratesOverrideGroup,
    manageTimeOverrideGroup,
    viewOthersTime,
    filterViewEmployeeIds,
    editOthersTime,
    filterEditEmployeeIds,
    approveOthersTime,
    filterApproveEmployeeIds,
    manageExpensesOverrideGroup,
    viewOthersExpenses,
    filterViewExpenseVendorIds,
    editOthersExpenses,
    filterEditExpenseVendorIds,
    approveOthersExpenses,
    filterApproveExpenseVendorIds,
    filterCustomerList,
    filterCustomerIds,
    filterCustomerIdsOverrideGroup,
    filterInventoryItemList,
    filterInventoryItemIds,
    filterInventoryItemIdsOverrideGroup,
    filterQbAccountList,
    filterQbAccountIds,
    filterQbAccountIdsOverrideGroup,
    filterQbClassList,
    filterQbClassIds,
    filterQbClassIdsOverrideGroup,
    onChange,
    showClockInOnlySetting,
    manageApi,
    managePayrollPayrates,
    manageItemBillrates,
    manageApiOverrideGroup,
    expenseEntityType,
    no_integration,
    
}) => (
    <>
        <div>
            <h3 className="cardHeading">Account Permissions</h3>
            <div className="cardContainer settingsSection">
               {permissionsGroupId=== '0' && (
                <ToggleSetting
                title="Manage Users"
                subtitle="Create and edit other users, perform all actions except edit account details/billing information"
                value={manageUsers}
                options={['Yes', 'No']}
                onChange={value =>
                    onChange({
                        manage_users: value,
                    })
                }
                permissionsGroupId={permissionsGroupId}
                overrideValue={manageUsersOverrideGroup}
                overrideOnChange={newValue =>
                    onChange({
                        manage_users_override_group: newValue,
                    })
                }
            />
               ) } 
            {permissionsGroupId!== '0' && (
                <ToggleSetting
                title="Manage Users"
                subtitle="Create and edit other users, perform all actions except edit account details/billing information"
                value={manageUsers}
                options={['Yes', 'No']}
                onChange={value =>
                    onChange({
                        permission_group_manage_users: value,
                    })
                }
                permissionsGroupId={permissionsGroupId}
                overrideValue={manageUsersOverrideGroup}
                overrideOnChange={newValue =>
                    onChange({
                        manage_users_override_group: newValue,
                    })
                }
            />
               ) }
                {!no_integration && (
                    <ToggleSetting
                        title="Export/Sync Time and Expenses"
                        subtitle="Export time and expense entries for all users, sync QuickBooks with Minute7"
                        value={exportTime}
                        options={['Yes', 'No']}
                        onChange={value =>
                            onChange({
                                export_time: value,
                            })
                        }
                        permissionsGroupId={permissionsGroupId}
                        overrideValue={exportTimeOverrideGroup}
                        overrideOnChange={newValue =>
                            onChange({
                                export_time_override_group: newValue,
                            })
                        }
                    />
                )}

                <ToggleSetting
                    title="Create and View Time and Expense Reports"
                    subtitle="Create and view reports showing time and expense entries for users who this user can view time entries for"
                    value={viewReports}
                    options={['Yes', 'No']}
                    onChange={value =>
                        onChange({
                            view_reports: value,
                        })
                    }
                    permissionsGroupId={permissionsGroupId}
                    overrideValue={viewReportsOverrideGroup}
                    overrideOnChange={newValue =>
                        onChange({
                            view_reports_override_group: newValue,
                        })
                    }
                />
                {showClockInOnlySetting && (
                    <ToggleSetting
                        title="Clock In/Out Only"
                        subtitle="Enter time entries using timer only, not entering in duration. Also this user won't be able to edit/duplicate entries."
                        value={clockInOnly}
                        options={['Yes', 'No']}
                        onChange={value =>
                            onChange({
                                clock_in_only: value,
                            })
                        }
                    />
                )}
                <ToggleSetting
                    title="Edit Payroll"
                    subtitle="Allow this user to assign payroll items to time entries"
                    value={editPayroll}
                    options={['Yes', 'No']}
                    onChange={value =>
                        onChange({
                            edit_payroll: value,
                        })
                    }
                    permissionsGroupId={permissionsGroupId}
                    overrideValue={editPayrollOverrideGroup}
                    overrideOnChange={newValue =>
                        onChange({
                            edit_payroll_override_group: newValue,
                        })
                    }
                />
                {no_integration  && (
                    <ToggleSetting
                        title="Manage Payroll and Pay Rates"
                        subtitle="Allow this user to create payrolls and pay rates for employee and view them in reports."
                        value={managePayrollPayrates}
                        options={['Yes', 'No']}
                        onChange={value =>
                            onChange({
                                manage_payroll_payrates: value,
                            })
                        }
                        permissionsGroupId={permissionsGroupId}
                        overrideValue={managePayrollPayratesOverrideGroup}
                        overrideOnChange={newValue =>
                            onChange({
                                manage_payroll_payrates_override_group: newValue,
                            })
                        }
                    />
                )}

                {no_integration  && (
                    <ToggleSetting
                        title="Manage Items and Bill Rates"
                        subtitle="Allow this user to create Items and assosate bill rates for Comapny and view them in reports."
                        value={manageItemBillrates}
                        options={['Yes', 'No']}
                        onChange={value =>
                            onChange({
                                manage_item_billrates: value,
                            })
                        }
                        permissionsGroupId={permissionsGroupId}
                        overrideValue={manageItemBillratesOverrideGroup}
                        overrideOnChange={newValue =>
                            onChange({
                                manage_item_billrates_override_group: newValue,
                            })
                        }
                    />
                )}
            </div>
        </div>

        {manageUsers === 'Yes' && (
            <div>
                <h3 className="cardHeading">Time and Expense Permissions</h3>
                <div className="cardContainer cardContent settingsSection">
                    If a user has their Manage Users permission set to “Yes”, they will have the
                    ability to view all Vendors and Employees. As a result, Time Permissions and
                    Expense Permissions will not be applied to the user. If you want to apply Time
                    Permissions and Expense Permissions to a user, set the Manage Users permission
                    to “No”.
                </div>
            </div>
        )}

        {manageUsers === 'No' && (
            <>
                <div>
                    <h3 className="cardHeading">Time Permissions</h3>
                    <div className="cardContainer settingsSection">
                        {permissionsGroupId !== '0' && (
                            <ToggleSetting
                                title="Override Group"
                                value={manageTimeOverrideGroup}
                                options={['Yes', 'No']}
                                onChange={value =>
                                    onChange({
                                        manage_time_override_group: value,
                                    })
                                }
                                subtitle="Override this user’s group-level permissions for managing time"
                            />
                        )}
                        <ToggleSetting
                            title="View time entries for"
                            value={viewOthersTime}
                            options={[
                                { label: 'Only him or herself', value: 'No' },
                                { label: 'All employees', value: 'Yes' },
                                { label: 'Certain employees', value: 'Filtered' },
                            ]}
                            labelForOption={o => o.label}
                            valueForOption={o => o.value}
                            onChange={value =>
                                onChange({
                                    view_others_time: value,
                                })
                            }
                            firstListOptions={viewOthersTime === 'Filtered' ? employees : null}
                            listValue={filterViewEmployeeIds}
                            onListChange={values => {
                                onChange({ filter_view_employee_ids: values });
                            }}
                            overrideValue={
                                permissionsGroupId === '0' ? 'Yes' : manageTimeOverrideGroup
                            }
                        />
                        <ToggleSetting
                            title="Edit time entries for"
                            value={editOthersTime}
                            options={[
                                { label: 'Only him or herself', value: 'No' },
                                { label: 'All employees', value: 'Yes' },
                                { label: 'Certain employees', value: 'Filtered' },
                            ]}
                            labelForOption={o => o.label}
                            valueForOption={o => o.value}
                            onChange={value =>
                                onChange({
                                    edit_others_time: value,
                                })
                            }
                            firstListOptions={editOthersTime === 'Filtered' ? employees : null}
                            listValue={filterEditEmployeeIds}
                            onListChange={values => {
                                onChange({ filter_edit_employee_ids: values });
                            }}
                            overrideValue={
                                permissionsGroupId === '0' ? 'Yes' : manageTimeOverrideGroup
                            }
                        />
                        <ToggleSetting
                            title="Approve time entries for"
                            value={approveOthersTime}
                            options={[
                                { label: 'No one', value: 'No' },
                                { label: 'Only him or herself', value: 'Self' },
                                { label: 'All employees', value: 'Yes' },
                                { label: 'Certain employees', value: 'Filtered' },
                            ]}
                            labelForOption={o => o.label}
                            valueForOption={o => o.value}
                            onChange={value =>
                                onChange({
                                    approve_others_time: value,
                                })
                            }
                            firstListOptions={approveOthersTime === 'Filtered' ? employees : null}
                            listValue={filterApproveEmployeeIds}
                            onListChange={values => {
                                onChange({ filter_approve_employee_ids: values });
                            }}
                            overrideValue={
                                permissionsGroupId === '0' ? 'Yes' : manageTimeOverrideGroup
                            }
                        />
                    </div>
                </div>

                <div>
                    <h3 className="cardHeading">Expense Permissions</h3>
                    <div className="cardContainer settingsSection">
                        {permissionsGroupId !== '0' && (
                            <ToggleSetting
                                title="Override Group"
                                value={manageExpensesOverrideGroup}
                                options={['Yes', 'No']}
                                onChange={value =>
                                    onChange({
                                        manage_expenses_override_group: value,
                                    })
                                }
                                subtitle="Override this user’s group-level permissions for managing expenses"
                            />
                        )}
                        <ToggleSetting
                            title="View expense entries for"
                            value={viewOthersExpenses}
                            options={[
                                { label: 'Only him or herself', value: 'No' },
                                { label: `All ${expenseEntityType}`, value: 'Yes' },
                                { label: `Certain ${expenseEntityType}`, value: 'Filtered' },
                            ]}
                            labelForOption={o => o.label}
                            valueForOption={o => o.value}
                            onChange={value =>
                                onChange({
                                    view_others_expenses: value,
                                })
                            }
                            firstListOptions={viewOthersExpenses === 'Filtered' ? vendors : null}
                            listValue={filterViewExpenseVendorIds}
                            onListChange={values => {
                                onChange({ filter_view_expense_vendor_ids: values });
                            }}
                            overrideValue={
                                permissionsGroupId === '0' ? 'Yes' : manageExpensesOverrideGroup
                            }
                        />
                        <ToggleSetting
                            title="Edit expense entries for"
                            value={editOthersExpenses}
                            options={[
                                { label: 'Only him or herself', value: 'No' },
                                { label: `All ${expenseEntityType}`, value: 'Yes' },
                                { label: `Certain ${expenseEntityType}`, value: 'Filtered' },
                            ]}
                            labelForOption={o => o.label}
                            valueForOption={o => o.value}
                            onChange={value =>
                                onChange({
                                    edit_others_expenses: value,
                                })
                            }
                            firstListOptions={editOthersExpenses === 'Filtered' ? vendors : null}
                            listValue={filterEditExpenseVendorIds}
                            onListChange={values => {
                                onChange({ filter_edit_expense_vendor_ids: values });
                            }}
                            overrideValue={
                                permissionsGroupId === '0' ? 'Yes' : manageExpensesOverrideGroup
                            }
                        />
                        <ToggleSetting
                            title="Approve expense entries for"
                            value={approveOthersExpenses}
                            options={[
                                { label: 'No one', value: 'No' },
                                { label: 'Only him or herself', value: 'Self' },
                                { label: `All ${expenseEntityType}`, value: 'Yes' },
                                { label: `Certain ${expenseEntityType}`, value: 'Filtered' },
                            ]}
                            labelForOption={o => o.label}
                            valueForOption={o => o.value}
                            onChange={value =>
                                onChange({
                                    approve_others_expenses: value,
                                })
                            }
                            firstListOptions={approveOthersExpenses === 'Filtered' ? vendors : null}
                            listValue={filterApproveExpenseVendorIds}
                            onListChange={values => {
                                onChange({
                                    filter_approve_expense_vendor_ids: values,
                                });
                            }}
                            overrideValue={
                                permissionsGroupId === '0' ? 'Yes' : manageExpensesOverrideGroup
                            }
                        />
                    </div>
                </div>
            </>
        )}

        <div>
            <h3 className="cardHeading">Restrictions</h3>
            <div className="cardContainer settingsSection">
                <ToggleSetting
                    title="Limit to certain customers"
                    value={filterCustomerList}
                    options={['Yes', 'No']}
                    onChange={value =>
                        onChange({
                            filter_customer_list: value,
                        })
                    }
                    firstListOptions={filterCustomerList === 'Yes' ? customers : null}
                    listValue={filterCustomerIds}
                    onListChange={values => onChange({ filter_customer_ids: values })}
                    permissionsGroupId={permissionsGroupId}
                    overrideValue={filterCustomerIdsOverrideGroup}
                    overrideOnChange={newValue =>
                        onChange({
                            filter_customer_ids_override_group: newValue,
                        })
                    }
                />
                <ToggleSetting
                    title="Limit to certain service items"
                    value={filterInventoryItemList}
                    options={['Yes', 'No']}
                    onChange={value =>
                        onChange({
                            filter_inventory_item_list: value,
                        })
                    }
                    firstListOptions={filterInventoryItemList === 'Yes' ? inventoryItems : null}
                    listValue={filterInventoryItemIds}
                    onListChange={values => onChange({ filter_inventory_item_ids: values })}
                    permissionsGroupId={permissionsGroupId}
                    overrideValue={filterInventoryItemIdsOverrideGroup}
                    overrideOnChange={newValue =>
                        onChange({
                            filter_inventory_item_ids_override_group: newValue,
                        })
                    }
                />
                <ToggleSetting
                    title="Limit to certain Expense/Payment Accounts"
                    value={filterQbAccountList}
                    options={['Yes', 'No']}
                    onChange={value =>
                        onChange({
                            filter_qb_account_list: value,
                        })
                    }
                    firstListOptions={filterQbAccountList === 'Yes' ? allowedQbAccounts : null}
                    secondListOptions={
                        filterQbAccountList === 'Yes' ? allowedQbPaymentAccounts : null
                    }
                    firstLabel="Expense Account"
                    secondLabel="Payment Account"
                    listValue={filterQbAccountIds}
                    onListChange={values => onChange({ filter_qb_account_ids: values })}
                    permissionsGroupId={permissionsGroupId}
                    overrideValue={filterQbAccountIdsOverrideGroup}
                    overrideOnChange={newValue =>
                        onChange({
                            filter_qb_account_ids_override_group: newValue,
                        })
                    }
                />
                {!no_integration && (
                    <ToggleSetting
                        title="Limit to certain QuickBooks classes"
                        value={filterQbClassList}
                        options={['Yes', 'No']}
                        onChange={value =>
                            onChange({
                                filter_qb_class_list: value,
                            })
                        }
                        firstListOptions={filterQbClassList === 'Yes' ? qbClasses : null}
                        listValue={filterQbClassIds}
                        onListChange={values => onChange({ filter_qb_class_ids: values })}
                        permissionsGroupId={permissionsGroupId}
                        overrideValue={filterQbClassIdsOverrideGroup}
                        overrideOnChange={newValue =>
                            onChange({
                                filter_qb_class_ids_override_group: newValue,
                            })
                        }
                    />
                )}
                {viewReports === 'Yes' && (
                    <ToggleSetting
                        title="Allow communication with API"
                        value={manageApi}
                        options={['Yes', 'No']}
                        onChange={value =>
                            onChange({
                                manage_api: value,
                            })
                        }
                        permissionsGroupId={permissionsGroupId}
                        overrideValue={manageApiOverrideGroup}
                        overrideOnChange={newValue =>
                            onChange({
                                manage_api_override_group: newValue,
                            })
                        }
                    />
                )}
            </div>
        </div>
    </>
);

Permissions.defaultProps = {
    permissionsGroupId: '0',
    // when editing permissions groups, the "clock in only" setting is not available
    // this props allows you to turn it off
    showClockInOnlySetting: true,
};

export default Permissions;
