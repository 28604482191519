import React from 'react';

const ShowMostRecent = () => (
    <div>
        <div className="sectionQuestion">
            What does "Show the most recent time entries and time entry form on the bottom/on the
            top" mean?
        </div>
        <div className="sectionAnswer">
            Users can choose how they would like time entries displayed:
            <ol>
                <li>
                    On the top: the most recent time and entry form appear at the top of the page,
                    with older entries at the bottom of the page
                </li>
                <li>
                    On the bottom: the most recent time entries appear at the bottom of the page,
                    with older entries at the top of the page
                </li>
            </ol>
        </div>
    </div>
);

export default ShowMostRecent;
