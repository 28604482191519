import React from 'react';

const UnknownPayrollInfo = () => (
    <div>
        <div className="sectionQuestion">
            QuickBooks Web Connector gives me the error 'QuickBooks is having trouble determining
            the payroll status for one of your employees'.
        </div>
        <div className="sectionAnswer">
            If you haven't used QuickBooks to enter time for an employee before, QuickBooks may not
            know what payroll settings to use for that employee, so{' '}
            <b>QuickBooks won't allow Minute7 to sync</b> time entries until you explicitly tell
            QuickBooks whether the employee uses time entry data to generate payroll checks.
            Usually, this problem occurs with new employees in QuickBooks, since QuickBooks likes to
            have the payroll preferences for an employee explicitly set before allowing any action
            to be recorded for that employee.
            <br /> <br />
            <b>You can fix this problem in QuickBooks</b> by doing the following:
            <br />
            <ol>
                <li>
                    In QuickBooks, open up the <b>"Edit Employee"</b> window for your employee.
                </li>
                <li>
                    In the drop-down select at the top of the "Edit Employee" window, choose{' '}
                    <b>"Payroll and Compensation Info."</b>
                </li>
                <li>
                    In the Payroll Info tab, about half-way down, you will see a checkbox that says
                    <b>"Use time data to create paychecks"</b>
                    <br />
                    <b>
                        If this is a feature of QuickBooks that you use, make sure this box is
                        checked
                    </b>
                    , and make sure you have an Earnings- Item Name and Rate entered (for example,
                    you might have an Earnings Item "Salary" with the rate "25.00").
                    <br />
                    <b>
                        If this is not a feature of QuickBooks that you use, first check the box,
                        and then uncheck it
                    </b>{' '}
                    so that QuickBooks registers that a choice has been made.
                    <br />
                    (If you are unsure whether you use this feature, or have never heard of it,
                    chances are you don't use it, so check and then uncheck the box to let
                    QuickBooks sync with Minute7.)
                    <br />
                </li>
                <li>
                    Click <b>"OK"</b> to close and save your changes.
                </li>
            </ol>
            These steps should set QuickBook's awareness of your payroll settings, and you should
            then be able to sync and manage payroll settings correctly.
        </div>
    </div>
);

export default UnknownPayrollInfo;
