import React from 'react';
import Icon from 'react-icons-kit';
import { ic_info } from 'react-icons-kit/md/ic_info';
import moment from 'moment';

const TrialBanner = ({ toggleBillingInfoVisibility, accountStore }) => {
    let trialSplit = accountStore.created_at
        ? accountStore.created_at.split(' ')[0]
        : '2000-01-01';
    let isTestAccount = accountStore.test_account === 'Yes';

    let reactivatedCount = parseInt(accountStore.reactivated_count, 10);
    let trialStart = moment(trialSplit, 'YYYY-MM-DD');
    let trialLength = parseInt(accountStore.trial_length, 10);
    let trialEnd = trialStart.clone();
    trialEnd.add(trialLength, 'days');
    let today = new moment();

    let firstPaidAt = accountStore.first_paid_at
        ? moment(accountStore.first_paid_at.split(' ')[0], 'YYYY-MM-DD')
        : undefined

    let paidThroughSplit = accountStore.paid_through
        ? accountStore.paid_through.split(' ')[0]
        : '2000-01-01';
    let paidThrough = moment(paidThroughSplit, 'YYYY-MM-DD');

    if (reactivatedCount > 0 || isTestAccount) {
        return null;
    } else if (today.diff(trialEnd, 'seconds') < 0) {
        return (
            <div className="asWarningBanner">
                <Icon className="asWarningBannerIcon" size={32} icon={ic_info} />
                <b>Your trial ends {trialEnd.fromNow()}.</b>
                &nbsp;
                <button className="tertiaryButton" onClick={toggleBillingInfoVisibility}>
                    Enter Billing Info
                </button>
            </div>
        );
    } else if (!firstPaidAt) {
        return (
            <div className="asWarningBanner">
                <Icon className="asWarningBannerIcon" size={32} icon={ic_info} />
                <b>Your trial has ended.</b>
                &nbsp;
                <button className="tertiaryButton" onClick={toggleBillingInfoVisibility}>
                    Enter Billing Info
                </button>
            </div>
        );
    } else if (!(today.diff(paidThrough, 'seconds') < 0)) {
        return (
            <div className="asWarningBanner">
                <Icon className="asWarningBannerIcon" size={32} icon={ic_info} />
                <b>Your credit card has expired. Please update.</b>
                &nbsp;
                <button className="tertiaryButton" onClick={toggleBillingInfoVisibility}>
                    Enter Billing Info
                </button>
            </div>
        )
    } else {
        return null;
    }
};

export default TrialBanner;
