import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import { tick as tickIcon } from 'react-icons-kit/ikons/tick';
import { warning as warningIcon } from 'react-icons-kit/ikons/warning';
import { exclamation as negativeIcon } from 'react-icons-kit/fa/exclamation';

import styles from './Toast.module.css';

const PositiveIcon = () => (
    <div className={styles.positiveIconContainer}>
        <Icon icon={tickIcon} size={18} className={styles.positiveIcon} />
    </div>
);

const WarningIcon = () => (
    <div className={styles.warningIconContainer}>
        <Icon icon={warningIcon} size={14} className={styles.warningIcon} />
    </div>
);

const NegativeIcon = () => (
    <div className={styles.negativeIconContainer}>
        <Icon icon={negativeIcon} size={14} className={styles.negativeIcon} />
    </div>
);

const Message = ({ id, title, kind, withIcon, onClose }) => {
    const iconsForKind = {
        positive: PositiveIcon,
        warning: WarningIcon,
        negative: NegativeIcon,
    };

    const Icon = iconsForKind[kind] || (() => null);

    return (
        <div
            id={`toast-${id}`}
            className={classNames({
                [styles.messageContainer]: true,
                [styles[`messageContainer--${kind}`]]: true,
                [styles[`messageContainer--withIcon`]]: withIcon,
            })}
        >
            {withIcon && <Icon />}

            {typeof title === 'string' ? <div className={styles.messageText}>{title}</div> : title}

            {onClose && <Close onClose={onClose} />}
        </div>
    );
};

Message.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    kind: PropTypes.oneOf(['positive', 'warning', 'negative', 'neutral']),
    onClose: PropTypes.func,
    withIcon: PropTypes.bool,
};

Message.defaultProps = {
    withIcon: true,
};

const Close = ({ onClose }) => (
    <div className={styles.closeButton} onClick={onClose} aria-label="Close">
        <Icon className={styles.closeIcon} icon={closeIcon} size={18} />
    </div>
);

export default Message;
