import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from 'react-icons-kit';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';
import * as resourceSelectors from '../../../selectors/BusinessResourcesSelectors';
import * as accountSelectors from '../../../selectors/AccountSelectors';
import * as expenseSelectors from '../../../selectors/ExpensesSelectors';
import Modal from 'react-modal';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import { Row, Col } from 'react-bootstrap';
import { Input } from 'reactstrap';

import {
    billableFilter as billableFilterOptions,
    taxableFilter as taxableFilterOptions,
    approvedFilter as approvedFilterOptions,
    exportedFilter as exportedFilterOptions,
    expenseTypeFilter as expenseTypeOptions,
    attachmentFilter as attachmentFilterOptions,
    lockedFilter as lockedFilterOptions,
} from '../../../config/Fields.js';
import DateTimePicker from '../../components/DateTimePicker.js';
import Select from '../../components/Select.js';

import '../../../css/Filters.css';

class Filters extends Component {

    render() {
        const {
            inventoryItemsOfTypeInventory,
            shouldShowQbClasses,
            shouldShowPayrollItems,
            canViewEmployees,
            canViewVendors,
            allowedCustomers,
            allowedQbAccounts,
            allowedInventoryItems,
            allowedQbClasses,
            payrollItems,
            expenseGroupings,
            canSeeEmployeeFilter,
            canSeeVendorFilter,
            currentView,
            searchEmployeeIds,
            searchVendorIds,
            searchCustomerIds,
            searchQbAccountIds,
            searchInventoryItemIds,
            searchQbClassIds,
            searchPayrollItemIds,
            searchExpenseGroupingIds,
            approvedFilter,
            billableFilter,
            taxableFilter,
            exportedFilter,
            lockedFilter,
            expenseTypeFilter,
            restrictByDate,
            startDate,
            endDate,
            attachmentFilter,
            onReset,
            onChange,
            onSubmit,
            qbType,
            canManagePayroll,
            canManageItemBillrate,
            closeModal,
            isModalOpen,
            openModal,
            reportName,
            handleReportNameChnaged,
            validateReportFilterName,
            saveReportFilters,
            reportFIlterNameInvalidMsg,
            filterSelected,
            onDelete,
            isDeleteModalOpen,
            closeDeleteModal,
            openDeleteFilterModal,
            onUpdate,
            isUpdateModalOpen,
            closeUpdateModal,
            openUpdateFilterModal
        } = this.props;

        return (
            <div className="cardContent">
                <div className="fFilterContainer">
                    <div className="fItemFilterContainer">
                        {currentView === 'timesheets' && canSeeEmployeeFilter && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Employee</div>
                                <Select
                                    withSearch
                                    canSelectMultiple
                                    className="dropdownContainer"
                                    options={canViewEmployees}
                                    labelWhenNothingSelected="All Employees"
                                    value={searchEmployeeIds}
                                    onChange={ids =>
                                        onChange({
                                            searchEmployeeIds: ids,
                                        })
                                    }
                                    onClear={() =>
                                        onChange({
                                            searchEmployeeIds: [],
                                        })
                                    }
                                />
                            </div>
                        )}
                        {currentView === 'expenses' && canSeeVendorFilter && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Vendor</div>
                                <Select
                                    withSearch
                                    canSelectMultiple
                                    className="dropdownContainer"
                                    options={canViewVendors}
                                    labelWhenNothingSelected="All Vendors"
                                    value={searchVendorIds}
                                    onChange={ids =>
                                        onChange({
                                            searchVendorIds: ids,
                                        })
                                    }
                                    onClear={() =>
                                        onChange({
                                            searchVendorIds: [],
                                        })
                                    }
                                />
                            </div>
                        )}
                        <div className="labelAndDropdown">
                            <div className="dropdownLabel">Customer &amp; Job</div>
                            <Select
                                withSearch
                                canSelectMultiple
                                className="dropdownContainer"
                                options={allowedCustomers}
                                labelWhenNothingSelected="All Customers"
                                value={searchCustomerIds}
                                onChange={ids =>
                                    onChange({
                                        searchCustomerIds: ids,
                                    })
                                }
                                onClear={() =>
                                    onChange({
                                        searchCustomerIds: [],
                                    })
                                }
                            />
                        </div>
                        {currentView === 'expenses' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Account</div>
                                <Select
                                    withSearch
                                    canSelectMultiple
                                    className="dropdownContainer"
                                    options={allowedQbAccounts}
                                    labelWhenNothingSelected="All Accounts"
                                    value={searchQbAccountIds}
                                    onChange={ids =>
                                        onChange({
                                            searchQbAccountIds: ids,
                                        })
                                    }
                                    onClear={() =>
                                        onChange({
                                            searchQbAccountIds: [],
                                        })
                                    }
                                />
                            </div>
                        )}
                        {currentView === 'expenses' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Item</div>
                                <Select
                                    withSearch
                                    canSelectMultiple
                                    className="dropdownContainer"
                                    options={inventoryItemsOfTypeInventory}
                                    labelWhenNothingSelected="All Items"
                                    value={searchInventoryItemIds}
                                    onChange={ids =>
                                        onChange({
                                            searchInventoryItemIds: ids,
                                        })
                                    }
                                    onClear={() =>
                                        onChange({
                                            searchInventoryItemIds: [],
                                        })
                                    }
                                />
                            </div>
                        )}
                        {currentView === 'timesheets' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Service</div>
                                <Select
                                    withSearch
                                    canSelectMultiple
                                    className="dropdownContainer"
                                    options={allowedInventoryItems}
                                    labelWhenNothingSelected="All Services"
                                    value={searchInventoryItemIds}
                                    onChange={ids =>
                                        onChange({
                                            searchInventoryItemIds: ids,
                                        })
                                    }
                                    onClear={() =>
                                        onChange({
                                            searchInventoryItemIds: [],
                                        })
                                    }
                                />
                            </div>
                        )}
                        {shouldShowQbClasses && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Class</div>
                                <Select
                                    withSearch
                                    canSelectMultiple
                                    className="dropdownContainer"
                                    options={allowedQbClasses}
                                    labelWhenNothingSelected="All Classes"
                                    value={searchQbClassIds}
                                    onChange={ids =>
                                        onChange({
                                            searchQbClassIds: ids,
                                        })
                                    }
                                    onClear={() =>
                                        onChange({
                                            searchQbClassIds: [],
                                        })
                                    }
                                />
                            </div>
                        )}
                        {shouldShowPayrollItems && currentView === 'timesheets' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Payroll Item</div>
                                <Select
                                    withSearch
                                    canSelectMultiple
                                    className="dropdownContainer"
                                    options={payrollItems}
                                    labelWhenNothingSelected="All Payroll Items"
                                    value={searchPayrollItemIds}
                                    onChange={ids =>
                                        onChange({
                                            searchPayrollItemIds: ids,
                                        })
                                    }
                                    onClear={() =>
                                        onChange({
                                            searchPayrollItemIds: [],
                                        })
                                    }
                                />
                            </div>
                        )}
                        {currentView === 'expenses' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Bill</div>
                                <Select
                                    withSearch
                                    canSelectMultiple
                                    className="dropdownContainer"
                                    options={expenseGroupings}
                                    labelWhenNothingSelected="All Bills"
                                    value={searchExpenseGroupingIds}
                                    onChange={ids => {
                                        onChange({
                                            searchExpenseGroupingIds: ids,
                                        });
                                    }}
                                    onClear={() =>
                                        onChange({
                                            searchExpenseGroupingIds: [],
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className="fApprovedBillableAndSyncFilterContainer">
                        <div className="labelAndDropdown">
                            <div className="dropdownLabel">Approved</div>
                            <Select
                                options={approvedFilterOptions}
                                value={approvedFilter}
                                className="dropdownContainer"
                                defaultValue="all"
                                onChange={value =>
                                    onChange({
                                        approvedFilter: value,
                                    })
                                }
                            />
                        </div>
                        <div className="labelAndDropdown">
                            <div className="dropdownLabel">Billable</div>
                            <Select
                                className="dropdownContainer"
                                options={billableFilterOptions}
                                value={billableFilter}
                                defaultValue="all"
                                onChange={value =>
                                    onChange({
                                        billableFilter: value,
                                    })
                                }
                            />
                        </div>
                        {currentView === 'timesheets' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Taxable</div>
                                <Select
                                    className="dropdownContainer"
                                    options={taxableFilterOptions}
                                    value={taxableFilter}
                                    defaultValue="all"
                                    onChange={value =>
                                        onChange({
                                            taxableFilter: value,
                                        })
                                    }
                                />
                            </div>
                        )}
                        {qbType !== 'None' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Synced</div>
                                <Select
                                    className="dropdownContainer"
                                    options={exportedFilterOptions}
                                    value={exportedFilter}
                                    defaultValue="all"
                                    onChange={value =>
                                        onChange({
                                            exportedFilter: value,
                                        })
                                    }
                                />
                            </div>
                        )}
                        {qbType === 'None' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Locked</div>
                                <Select
                                    className="dropdownContainer"
                                    options={lockedFilterOptions}
                                    value={lockedFilter}
                                    defaultValue="all"
                                    onChange={value =>
                                        onChange({
                                            lockedFilter: value,
                                        })
                                    }
                                />
                            </div>
                        )}
                        {currentView === 'expenses' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Expense Type</div>
                                <Select
                                    className="dropdownContainer"
                                    options={expenseTypeOptions}
                                    value={expenseTypeFilter}
                                    defaultValue="all"
                                    onChange={value =>
                                        onChange({
                                            expenseTypeFilter: value,
                                        })
                                    }
                                />
                            </div>
                        )}
                        {currentView === 'expenses' && (
                            <div className="labelAndDropdown">
                                <div className="dropdownLabel">Attachment</div>
                                <Select
                                    className="dropdownContainer"
                                    options={attachmentFilterOptions}
                                    value={attachmentFilter}
                                    defaultValue="all"
                                    onChange={value =>
                                        onChange({
                                            attachmentFilter: value,
                                        })
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className="fDateFilterContainer">
                        <div className="fFilterByDateCheckboxContainer">
                            <div className="fFilterByDateCheckbox">
                                <Icon
                                    className="blueIcon"
                                    size={18}
                                    icon={restrictByDate ? checkboxChecked : checkboxUnchecked}
                                    onClick={() =>
                                        onChange({
                                            restrictByDate: !restrictByDate,
                                        })
                                    }
                                />
                            </div>
                            <div className="fEnableDateRangeText">Enable Date Range</div>
                        </div>
                        <div className="labelAndDropdown">
                            <div className="dropdownLabel">From</div>
                            <DateTimePicker
                                toggleStyle="input"
                                value={startDate}
                                onChange={newStartDate =>
                                    onChange({
                                        startDate: newStartDate,
                                    })
                                }
                            />
                        </div>
                        <div className="labelAndDropdown">
                            <div className="dropdownLabel">To</div>
                            <DateTimePicker
                                toggleStyle="input"
                                value={endDate}
                                onChange={newEndDate => onChange({ endDate: newEndDate })}
                            />
                        </div>
                    </div>
                </div>
                <div className="fButtonContainer">
                    { filterSelected && (
                        
                            <button className="destructiveSecondaryButton" onClick={openDeleteFilterModal}>
                                Delete Filter
                            </button> )}

                    { filterSelected && (
                        
                        <button className="primaryButton" onClick={openUpdateFilterModal}>
                        Update Filter
                        </button> )}

                    {!filterSelected && (
                            <button className="primaryButton" onClick={openModal}>
                                Save Filter
                            </button>
                    )}
                            <button className="primaryButton" onClick={onSubmit}>
                                Run Report
                            </button>
                
                </div>
                <Modal
                    closeTimeoutMS={400}
                    isOpen={isModalOpen}
                    onRequestClose={closeModal }
                    contentLabel="Inactive user"
                    className="modalContainer resourceModal"
                >
                    <div className="modalHeader">
                        <h2>Save Report Filter</h2>
                        <div className="modalOptions">
                            <Icon
                                className="modalIcon"
                                size={32}
                                icon={closeIcon}
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                    <div className="modalContent helpModalContent">
                        <div className="sectionAnswer">
                            Enter your Report Filter name
                        </div>
                        <Row className="">
                            <Col sm={12} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                            {' '}
                                            Report Name{' '}
                                        </div>

                                        <div className="required">*</div>

                                    </Row>

                                    <div>
                                        <Input
                                            className=""
                                            name="reportName"
                                            value={reportName}
                                            onChange={handleReportNameChnaged}
                                          
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="ftInputError">

                            <label className="error-message">
                                {reportFIlterNameInvalidMsg}
                            </label>

                        </div>
                        <div className="sectionButton">
                            <button className="primaryButton" onClick={saveReportFilters}>
                                Save
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal
                    closeTimeoutMS={400}
                    isOpen={isUpdateModalOpen}
                    onRequestClose={closeUpdateModal }
                    contentLabel="Inactive user"
                    className="modalContainer resourceModal"
                >
                    <div className="modalHeader">
                        <h2>Update Report Filter</h2>
                        <div className="modalOptions">
                            <Icon
                                className="modalIcon"
                                size={32}
                                icon={closeIcon}
                                onClick={closeUpdateModal}
                            />
                        </div>
                    </div>
                    <div className="modalContent helpModalContent">
                        <div className="sectionAnswer">
                            Enter your Report Filter name
                        </div>
                        <Row className="">
                            <Col sm={12} style={{ marginBottom: '5px' }}>
                                <div className="ftLabelAndInput">
                                    <Row>
                                        <div className="ftLabel largeBodyText greyText">
                                            {' '}
                                            Report Name{' '}
                                        </div>

                                        <div className="required">*</div>

                                    </Row>

                                    <div>
                                        <Input
                                            className=""
                                            name="reportName"
                                            value={reportName}
                                            onChange={handleReportNameChnaged}
                                          
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="ftInputError">

                            <label className="error-message">
                                {reportFIlterNameInvalidMsg}
                            </label>

                        </div>
                        <div className="sectionButton updateModal">
                            <button className="primaryButton" onClick={() => onUpdate()}>
                                Update
                            </button>

                            <button className="primaryButton" onClick={saveReportFilters}>
                                Save as New
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal
                closeTimeoutMS={400}
                isOpen={isDeleteModalOpen}
                onRequestClose={closeDeleteModal}
                contentLabel="Inactive user"
                className="modalContainer resourceModal"
            >
                <div className="modalHeader">
                    <h2>Delete Report Filter</h2>
                    <div className="modalOptions">
                        <Icon
                            className="modalIcon"
                            size={32}
                            icon={closeIcon}
                            onClick={closeDeleteModal}
                        />
                    </div>
                </div>
                <div className="modalContent helpModalContent">
                    <div className="sectionAnswer">
                        Are you sure you want to delete this report filter?
                    </div>
                   
                    <div className="sectionButton">
                        <button className="destructiveButton" onClick={() => onDelete()}>
                            Delete
                        </button>
                    </div>
                </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const allowedCustomers = resourceSelectors.selectAllowedCustomers(state);
    const allowedQbAccounts = expenseSelectors.selectAllowedQbAccountsForExpenses(state);
    const allowedInventoryItems = resourceSelectors.selectAllowedInventoryItems(state);
    const allowedQbClasses = resourceSelectors.selectAllowedQbClasses(state);
    const inventoryItemsOfTypeInventory = resourceSelectors.selectAllowedInventoryItemsOfTypeInventory(state);
    var shouldShowPayrollItems = accountSelectors.selectShouldShowPayrollItems(state)
    const canSeeEmployeeFilter =
        state.accountUserStore.manage_users === 'Yes' ||
        state.accountUserStore.manage_account === 'Yes' ||
        state.accountUserStore.view_others_time !== 'No';

    const canSeeVendorFilter =
        state.accountUserStore.manage_users === 'Yes' ||
        state.accountUserStore.manage_account === 'Yes' ||
        state.accountUserStore.view_others_expenses !== 'No';
    if (state.qbType === 'None') {
        shouldShowPayrollItems = accountSelectors.selectShouldShowPayrollItems(state) && state.canManagePayroll;
    }


    return {
        inventoryItemsOfTypeInventory,
        allowedCustomers,
        allowedQbAccounts,
        allowedInventoryItems,
        allowedQbClasses,
        payrollItems: state.businessResourcesStore.payrollItems,
        expenseGroupings: state.businessResourcesStore.expenseGroupings,
        canSeeEmployeeFilter,
        canSeeVendorFilter,
        canViewEmployees: accountSelectors.selectCanViewEmployeesForReports(state),
        canViewVendors: accountSelectors.selectCanViewActiveVendors(state),
        shouldShowQbClasses: accountSelectors.selectShouldShowQbClasses(state),
        shouldShowPayrollItems: shouldShowPayrollItems,
    };
};

export default connect(mapStateToProps)(Filters);
