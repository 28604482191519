import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import NewTimeEntryForm from './NewTimeEntryForm';
import { firstLetterToUpperCase } from '../../../utils/CommonFunctions';

import * as timesheetsActions from '../../../actions/TimesheetsActions';

class NewTimeEntryContainer extends Component {
    static propTypes = {
        isSaving: PropTypes.bool,
        isDuplicate: PropTypes.bool,
        onSavePressed: PropTypes.func.isRequired,
        onCancelPressed: PropTypes.func,
        FormComponent: PropTypes.any,
        isCollapsed: PropTypes.bool,
        onCollapsePressed: PropTypes.func,
        history: PropTypes.any,
    };

    static defaultProps = {
        isDuplicate: false,
        FormComponent: NewTimeEntryForm,
        isCollapsed: false,
        isSaving: false,
        filterdInventoryItems: [],
    };

    handleEntryFieldUpdated = (field, value) => {
        if (
            this.props.mode === 'edit' ||
            this.props.mode === 'duplicate' ||
            this.props.isDuplicate
        ) {
            this.props['setEdit' + firstLetterToUpperCase(field)](value);
        } else {
            this.props['set' + firstLetterToUpperCase(field)](value);
        }
    };

    handleSaveEntryPressed = () => {
        const {
            id,
            mode,
            entryType,
            approved,
            qbClassId,
            employeeId,
            inventoryItemId,
            customerId,
            payrollItemId,
            durations,
            billable,
            taxable,
            duration,
            description,
            date,
            groupedTimeIds,
            startTime,
            hoursOffDuty,
            endTime,
            isDuplicate,
            editOriginalDurations,
            editEntriesInGroupById,
            startOfWeek,
            no_integration,
        } = this.props;

        const isEditMode = mode === 'edit';

        const startOfWeekMoment = moment(startOfWeek);

        const datesForWeeklyEntry = _.range(0, 7).reduce((all, i) => {
            const date = moment(startOfWeekMoment).add(i, 'd');
            const day = date.format('ddd');
            all[day] = date.format('Y-MM-DD');
            return all;
        }, {});

        const entry = {
            entryType,
            approved,
            billable,
            taxable,
            type: employeeId.includes('_v') ? 'Vendor' : 'Employee',
            employee_id: employeeId,
            qb_class_id: qbClassId,
            inventory_item_id: inventoryItemId,
            customer_id: customerId,
            payroll_item_id: payrollItemId,
            grouped_time_ids: groupedTimeIds,
            durations,
            originalDurations: isEditMode ? editOriginalDurations : null,
            entriesInGroupById: isEditMode ? editEntriesInGroupById : null,
            duration,
            description,
            date,
            dates: entryType === 'week' ? datesForWeeklyEntry : null,
            start_time: startTime,
            hours_off_duty: hoursOffDuty,
            end_time: endTime,
            default_rate: inventoryItemId !== '0' && no_integration ? this.props.filterdInventoryItems.find(item => item.id === inventoryItemId).default_rate : 0.0,
        };

        if (isEditMode && typeof id !== 'undefined') {
            entry.id = id;
        }
        if (isDuplicate && typeof id !== 'undefined') {
            entry.duplicate_time_entry_id = id;
        }


        this.props.onSavePressed(entry, isEditMode, isDuplicate);
    };

    handlePrevWeekPressed = () => {
        const { startOfWeek } = this.props;
        const startOfWeekMoment = moment(startOfWeek);
        startOfWeekMoment.add(-1, 'w');
        this.handleStartOfWeekChanged(startOfWeekMoment);
    };

    handleNextWeekPressed = () => {
        const { startOfWeek } = this.props;
        const startOfWeekMoment = moment(startOfWeek);
        startOfWeekMoment.add(1, 'w');
        this.handleStartOfWeekChanged(startOfWeekMoment);
    };

    handleStartOfWeekChanged = date => {
        const startOfWeekMoment = moment(date);
        if (
            this.props.mode === 'edit' ||
            this.props.mode === 'duplicate' ||
            this.props.isDuplicate
        ) {
            this.props.setEditStartOfWeek(startOfWeekMoment.format('YYYY-MM-DD'));
        } else {
            this.props.setStartOfWeek(startOfWeekMoment.format('YYYY-MM-DD'));
        }
    };

    render() {
        const {
            FormComponent,
            isSaving,
            onCancelPressed,
            canEditEmployees,
            entryType,
            qbClassId,
            employeeId,
            payrollItemId,
            durations,
            description,
            duration,
            billable,
            taxable,
            date,
            dates,
            startOfWeek,
            mode,
            resetTimesheetForm,
            hideEntryTypeToggle,
            isCollapsed,
            onCollapsePressed,
            hoursOffDuty,
            startTime,
            endTime,
            shouldShowBillable,
            shouldShowTaxable,
            shouldShowVendors,
            shouldShowPayrollItems,
            shouldShowQbClasses,
            canEditOthersTime,
            qbClasses,
            payrollItems,
            no_integration,
            customers,
            inventoryItems,
            // setter actions for new entry draft
            setCustomerId,
            setEmployeeId,
            setInventoryItemId,
            setQbClassId,
            setPayrollItemId,
            setDate,
            setDuration,
            setDurations,
            setDescription,
            setType,
            setBillable,
            setTaxable,
            setStartOfWeek,
            setHoursOffDuty,
            setStartTime,
            setEndTime,
            setEntryType,
            // setter actions for editing entries
            setEditCustomerId,
            setEditEmployeeId,
            setEditInventoryItemId,
            setEditQbClassId,
            setEditPayrollItemId,
            setEditDate,
            setEditDuration,
            setEditDurations,
            setEditDescription,
            setEditType,
            setEditBillable,
            setEditStartOfWeek,
            setEditHoursOffDuty,
            setEditStartTime,
            setEditEndTime,
            history,
            filterdInventoryItems,
        } = this.props;
        let { inventoryItemId, customerId } = this.props;

        if (inventoryItemId === '0' && no_integration === false) {
            inventoryItemId = _.get(inventoryItems, '[0].id', '0');
        }

        if (customerId === '0') {
            customerId = _.get(customers, '[0].id', '0');
        }

        return (
            <FormComponent
                hideEntryTypeToggle={hideEntryTypeToggle}
                onPrevWeek={this.handlePrevWeekPressed}
                onNextWeek={this.handleNextWeekPressed}
                onStartOfWeekChange={this.handleStartOfWeekChanged}
                onEntryFieldUpdated={this.handleEntryFieldUpdated}
                onSavePressed={this.handleSaveEntryPressed}
                onCancelPressed={onCancelPressed}
                onResetPressed={resetTimesheetForm}
                onCollapsePressed={onCollapsePressed}
                isSaving={isSaving}
                isCollapsed={isCollapsed}
                shouldShowBillable={shouldShowBillable}
                shouldShowTaxable={shouldShowTaxable}
                shouldShowVendors={shouldShowVendors}
                shouldShowQbClasses={shouldShowQbClasses}
                shouldShowPayrollItems={shouldShowPayrollItems}
                canEditOthersTime={canEditOthersTime}
                qbClasses={qbClasses}
                payrollItems={payrollItems}
                no_integration={no_integration}
                employees={canEditEmployees}
                customers={customers}
                inventoryItems={inventoryItems}
                entryType={entryType}
                qbClassId={qbClassId}
                employeeId={employeeId}
                inventoryItemId={inventoryItemId}
                customerId={customerId}
                payrollItemId={payrollItemId}
                startOfWeek={startOfWeek}
                durations={durations}
                description={description}
                duration={duration}
                hoursOffDuty={hoursOffDuty}
                startTime={startTime}
                endTime={endTime}
                billable={billable}
                taxable={taxable}
                date={date}
                dates={dates}
                mode={mode}
                setCustomerId={setCustomerId}
                setEmployeeId={setEmployeeId}
                setInventoryItemId={setInventoryItemId}
                setQbClassId={setQbClassId}
                setPayrollItemId={setPayrollItemId}
                setDate={setDate}
                setDuration={setDuration}
                setDurations={setDurations}
                setDescription={setDescription}
                setType={setType}
                setBillable={setBillable}
                setTaxable={setTaxable}
                setStartOfWeek={setStartOfWeek}
                setStartTime={setStartTime}
                setHoursOffDuty={setHoursOffDuty}
                setEndTime={setEndTime}
                setEntryType={setEntryType}
                setEditCustomerId={setEditCustomerId}
                setEditEmployeeId={setEditEmployeeId}
                setEditInventoryItemId={setEditInventoryItemId}
                setEditQbClassId={setEditQbClassId}
                setEditPayrollItemId={setEditPayrollItemId}
                setEditDate={setEditDate}
                setEditDuration={setEditDuration}
                setEditDurations={setEditDurations}
                setEditDescription={setEditDescription}
                setEditType={setEditType}
                setEditBillable={setEditBillable}
                setEditStartOfWeek={setEditStartOfWeek}
                setEditStartTime={setEditStartTime}
                setEditHoursOffDuty={setEditHoursOffDuty}
                setEditEndTime={setEditEndTime}
                history={history}
                filterdInventoryItems={filterdInventoryItems}
            />
        );
    }
}

const mapStateToProps = (
    { timesheetsStore, accountStore, accountUserStore, businessResourcesStore },
    ownProps
) => {
    const {
        // fields for new entry draft
        entryType,
        employeeId,
        qbClassId,
        inventoryItemId,
        customerId,
        payrollItemId,
        groupedTimeIds,
        exported,
        type,
        startOfWeek,
        durations,
        description,
        duration,
        hoursOffDuty,
        startTime,
        endTime,
        billable,
        taxable,
        date,
        dates,
        // fields for editing an entry
        editId,
        editEntryType,
        editEmployeeId,
        editQbClassId,
        editInventoryItemId,
        editCustomerId,
        editPayrollItemId,
        editGroupedTimeIds,
        editExported,
        editType,
        editStartOfWeek,
        editDurations,
        editDescription,
        editDuration,
        editHoursOffDuty,
        editStartTime,
        editEndTime,
        editBillable,
        editTaxable,
        editApproved,
        editDate,
        editDates,
        editOriginalDurations,
        editEntriesInGroupById,
    } = timesheetsStore;

    const billableToUse =
        accountStore.show_billable === 'No' ? accountStore.billable_by_default : billable;

    const taxableToUse =
        accountStore.show_taxable === 'No' && accountStore.billable_by_default === 'Yes'
            ? accountStore.taxable_by_default
            : taxable;
    const mode = 'mode' in ownProps ? ownProps.mode : timesheetsStore.mode;
    const isDuplicate = timesheetsStore.duplicate;

    const isEditOrDuplicate = mode === 'edit' || mode === 'duplicate' || isDuplicate;

    const eId = isEditOrDuplicate ? editEmployeeId : employeeId;
    const employees = ownProps.canEditEmployees;
    const employee = employees.find(({ id }) => id === eId);
    const no_integration = accountStore.qb_type === 'None' ? true : false;
    let payrollItems = [];
    if (employee && employee.use_time_for_paychecks === 'UseTimeData') {
        payrollItems = businessResourcesStore.payrollItemsByEmployeeId[eId] || [];
    } else if (employee && accountStore.qb_type === 'None') {
        payrollItems = businessResourcesStore.payrollItemsByEmployeeId[eId] || [];
    }
    let filterdInventoryItems = [];

    if(no_integration) {
        if(!accountStore.show_items_association_for_customers){
            filterdInventoryItems = businessResourcesStore.serviceTypeItems;
        }
        else{
        let cId =  isEditOrDuplicate ? editCustomerId : customerId;
        let customer = businessResourcesStore.customersById[cId];
        if(customer && customer.item_ids){
            let ids= customer.item_ids.split(",");
            filterdInventoryItems = businessResourcesStore.serviceTypeItems.filter(obj => ids.includes(obj.id));
        }
        }
    }

    return {
        id: isEditOrDuplicate ? editId : undefined,
        entryType: isEditOrDuplicate ? editEntryType : entryType,
        employeeId: eId,
        qbClassId: isEditOrDuplicate ? editQbClassId : qbClassId,
        inventoryItemId: isEditOrDuplicate ? editInventoryItemId : inventoryItemId,
        customerId: isEditOrDuplicate ? editCustomerId : customerId,
        payrollItemId: isEditOrDuplicate ? editPayrollItemId : payrollItemId,
        groupedTimeIds: isEditOrDuplicate ? editGroupedTimeIds : groupedTimeIds,
        exported: isEditOrDuplicate ? editExported : exported,
        type: isEditOrDuplicate ? editType : type,
        startOfWeek: isEditOrDuplicate ? editStartOfWeek : startOfWeek,
        durations: isEditOrDuplicate ? editDurations : durations,
        duration: isEditOrDuplicate ? editDuration : duration,
        hoursOffDuty: isEditOrDuplicate ? editHoursOffDuty : hoursOffDuty,
        description: isEditOrDuplicate ? editDescription : description,
        startTime: isEditOrDuplicate ? editStartTime : startTime,
        endTime: isEditOrDuplicate ? editEndTime : endTime,
        billable: isEditOrDuplicate ? editBillable : billableToUse,
        taxable: isEditOrDuplicate ? editTaxable : taxableToUse,
        approved: isEditOrDuplicate ? editApproved : undefined,
        date: isEditOrDuplicate ? editDate : date,
        dates: isEditOrDuplicate ? editDates : dates,
        editOriginalDurations: isEditOrDuplicate ? editOriginalDurations : undefined,
        editEntriesInGroupById: isEditOrDuplicate ? editEntriesInGroupById : undefined,
        isDuplicate,
        mode,
        payrollItems,
        no_integration,
        filterdInventoryItems: filterdInventoryItems,
    };
};

const {
    // setter actions for new entry draft
    setCustomerId,
    setEmployeeId,
    setInventoryItemId,
    setQbClassId,
    setPayrollItemId,
    setDate,
    setDuration,
    setDurations,
    setDescription,
    setType,
    setBillable,
    setTaxable,
    setStartOfWeek,
    setStartTime,
    setHoursOffDuty,
    setEndTime,
    setEntryType,
    // setter actions for editing entries
    setEditCustomerId,
    setEditEmployeeId,
    setEditInventoryItemId,
    setEditQbClassId,
    setEditPayrollItemId,
    setEditDate,
    setEditDuration,
    setEditDurations,
    setEditDescription,
    setEditType,
    setEditBillable,
    setEditTaxable,
    setEditStartOfWeek,
    setEditStartTime,
    setEditHoursOffDuty,
    setEditEndTime,
    // other
    resetTimesheetForm,
} = timesheetsActions;

const mapDispatchToProps = {
    // setter actions for new entry draft
    setCustomerId,
    setEmployeeId,
    setInventoryItemId,
    setQbClassId,
    setPayrollItemId,
    setDate,
    setDuration,
    setDurations,
    setDescription,
    setType,
    setBillable,
    setTaxable,
    setStartOfWeek,
    setHoursOffDuty,
    setStartTime,
    setEndTime,
    setEntryType,
    // setter actions for editing entries
    setEditCustomerId,
    setEditEmployeeId,
    setEditInventoryItemId,
    setEditQbClassId,
    setEditPayrollItemId,
    setEditDate,
    setEditDuration,
    setEditDurations,
    setEditDescription,
    setEditType,
    setEditBillable,
    setEditTaxable,
    setEditStartOfWeek,
    setEditHoursOffDuty,
    setEditStartTime,
    setEditEndTime,
    // other
    resetTimesheetForm,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewTimeEntryContainer);
