import React from 'react';

const GroupingEntries = () => (
    <div className="helpModal">
        <div className="sectionQuestion">
            How do I control whether my time entries are grouped by week or shown individually?
        </div>
        <div className="sectionAnswer">
            <ol>
                <li>Go to the TimeSheets tab in Minute7</li>
                <li>
                    Scroll down to the bottom of the page and the right hand side click the “More”
                    button.
                </li>
                <li>
                    Under “Grouping Options” select “Show Individual Entries” or “Group by Week”.
                </li>
            </ol>
        </div>
    </div>
);

export default GroupingEntries;
