import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';

export const getAllResources = (paramsObject = {}) => {
    return getData(paramsObject, 'resources/view').then(({ resources }) => {
        return {
            customers: resources.Customers.map(({ Customer }) => Customer),
            qbAccounts: resources.QbAccounts.map(({ QbAccount }) => QbAccount),
            qbClasses: resources.QbClasses.map(({ QbClass }) => QbClass),
            expenseGroupings: resources.ExpenseGroupings.map(
                ({ ExpenseGrouping }) => ExpenseGrouping
            ),
            payrollItems: resources.PayrollItems.map(({ PayrollItem }) => PayrollItem),
            allActivePayrollItems: resources.AllActivePayrollItems.map(({ PayrollItem }) => PayrollItem),
            permissionsGroups: resources.PermissionsGroups.map(
                ({ PermissionsGroup }) => PermissionsGroup
            ),
            inventoryItems: resources.InventoryItems.map(({ InventoryItem }) => InventoryItem),
            serviceTypeItems: resources.ServiceTypeItems.map(({ InventoryItem }) => InventoryItem),
            inventoryTypeItems: resources.InventoryTypeItems.map(({ InventoryItem }) => InventoryItem),
            canEditEmployees: resources.CanEditEmployees.map(({ Employee }) => Employee),
            canViewEmployees: resources.CanViewEmployees.map(({ Employee }) => Employee),
            canEditVendors: resources.CanEditVendors.map(({ Vendor }) => Vendor),
            canViewVendors: resources.CanViewVendors.map(({ Vendor }) => Vendor),
        };
    });
};

export const getCustomers = (paramsObject = {}) => {
    return getData(paramsObject, 'customers/view').then(res =>
        res.Customers.map(({ Customer }) => Customer)
    );
};

export const getQbAccounts = (paramsObject = {}) => {
    return getData(paramsObject, 'qbaccounts/view').then(res =>
        res.QbAccounts.map(({ QbAccount }) => QbAccount)
    );
};

export const getQbClasses = (paramsObject = {}) => {
    return getData(paramsObject, 'qbclasses/view').then(res =>
        res.QbClasses.map(({ QbClass }) => QbClass)
    );
};

// We have to restructure this response - our API sends an incorrectly structured object
export const getExpenseGroupings = (paramsObject = {}) => {
    return getData(paramsObject, 'expensegroupings/view').then(res => {
        const entries = res.ExpenseGroupings;
        const tempObject = [];
        for (let key in entries) {
            let objectToAdd =
                'ExpenseGrouping' in entries[key] ? entries[key].ExpenseGrouping : entries[key];
            tempObject.push(objectToAdd);
        }
        return tempObject;
    });
};

export const addExpenseGrouping = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'expensegroupings/add', paramsObject).then(
        res => res.ExpenseGrouping
    );
};

export const editExpenseGrouping = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'expensegroupings/edit', paramsObject).then(
        res => res.ExpenseGrouping
    );
};

export const getMileageReimbursementRate = (paramsObject = {}) => {
    return getData(paramsObject, 'mileagereimbursementrate/view').then(
        res => res.MileageReimbursementRate
    );
};

export const getInventoryItems = (paramsObject = {}) => {
    return getData(paramsObject, 'inventoryitems/view').then(res =>
        res.InventoryItems.map(({ InventoryItem }) => InventoryItem)
    );
};

export const getPayrollItems = (paramsObject = {}) => {
    return getData(paramsObject, 'payrollitems/view').then(res =>
        res.PayrollItems.map(({ PayrollItem }) => PayrollItem)
    );
};

export const getPermissionsGroups = (paramsObject = {}) => {
    return getData(paramsObject, 'permissionsgroups/view').then(res =>
        res.PermissionsGroups.map(({ PermissionsGroup }) => PermissionsGroup)
    );
};

export const savePermissionsGroups = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'permissionsgroups/save', paramsObject).then(res =>
        res.PermissionsGroups.map(({ PermissionsGroup }) => PermissionsGroup)
    );
};

export const deletePermissionsGroups = (paramsObject = {}) => {
    return getData(paramsObject, 'permissionsgroups/delete');
};
