import { all, call, put, takeLatest } from 'redux-saga/effects';
import { sendWelcomeEmail } from '../requests/AccountUserRequests';
import {
    updateExistingCustomer,
    createNewCustomer,
    getAllCustomersForTable,
    activateCustomer,
    deactivateCustomer,
} from '../requests/CustomerRequests';
import * as uiActions from '../actions/Actions';
import * as customerActions from '../actions/CustomerActions';
import _ from 'lodash';
import { customerFields } from '../config/Fields';
import * as resourceActions from '../actions/BusinessResourcesActions';

/**
 * Returns an Employee object for api calls
 * @param {Object} user holds all accountUser settings
 */
const formDataForCustomer = customer => {
    const data = {};
    _.forEach(_.pick(customer, customerFields), (value, key) => {
        switch (key) {
            case 'customer_id': {
                if (String(value).includes('_c')) {
                    data['Customer[customer_id]'] = value.replace('_c', '');
                    data['Customer[type]'] = 'Customer';
                }
                break;
            }
            case 'password': {
                if (value.toString().replace(/\s+/, '').length > 0) {
                    data['Customer[password]'] = value;
                }
                break;
            }
            case 'type': {
                break;
            }
            default: {
                data[`Customer[${key}]`] = value;
            }
        }
    });
    return data;
};

export function* updateCustomer({ customer, callback }) {
    try {
        const data = formDataForCustomer(customer);
        const updatedCustomer = yield call(updateExistingCustomer, data);
        yield put(resourceActions.requestBusinessResources());
        callback(updatedCustomer);
    } catch (error) {
        console.log(error);
        callback(false);
        yield put(customerActions.updateCustomerError(error));
        yield put(
            uiActions.showToast('Couldn’t update this customer. Try again later.', 'negative')
        );
    }
}

export function* addCustomer({ customer, callback }) {
    try {
        const data = formDataForCustomer(customer);
        const newCustomer = yield call(createNewCustomer, data);
        yield put(customerActions.addCustomerSuccess(newCustomer));
        yield put(resourceActions.requestBusinessResources());
        yield call(sendWelcomeEmail, newCustomer);
        callback(newCustomer);
    } catch (error) {
        console.log(error);
        callback(false);
        yield put(customerActions.addCustomerError(error));
        yield put(uiActions.showToast('Couldn’t create new customer', 'negative'));
    }
}

export function* addCustomerList() {
    try {
        const updatedCustomers = yield call(getAllCustomersForTable);
        yield put(customerActions.setAllCustomers(updatedCustomers));
        yield put(uiActions.showToast('New Customers are added', 'positive'));
    } catch (error) {
        console.log(error);
        yield put(customerActions.addCustomerListError(error));
        yield put(
            uiActions.showToast('Couldn’t get new customers, please refresh page', 'negative')
        );
    }
}

export function* unhideCustomer({ customerId }) {
    try {
        yield call(activateCustomer, customerId);
        yield put(resourceActions.requestBusinessResources());
        yield put(uiActions.showToast('Customer has been activated', 'positive'));
    } catch (error) {
        yield put(customerActions.unhideCustomer(error));
        yield put(
            uiActions.showToast('Couldn’t activate this customer. Try again later.', 'negative')
        );
    }
}

export function* hideCustomer({ customerId }) {
    try {
        yield call(deactivateCustomer, customerId);
        yield put(resourceActions.requestBusinessResources());
        yield put(uiActions.showToast('Customer has been deactivated', 'positive'));
    } catch (error) {
        yield put(customerActions.hideCustomer(error));
        yield put(
            uiActions.showToast('Couldn’t deactivate this customer. Try again later.', 'negative')
        );
    }
}

export default function* root() {
    yield all([
        takeLatest(['UPDATE_CUSTOMER'], updateCustomer),
        takeLatest(['ADD_CUSTOMER'], addCustomer),
        takeLatest(['ADD_CUSTOMER_LIST'], addCustomerList),
        takeLatest(['ACTIVATE_CUSTOMER'], unhideCustomer),
        takeLatest(['DEACTIVATE_CUSTOMER'], hideCustomer),
    ]);
}
