/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

const TrackingReimbursibleExpenses = () => (
    <div>
        <div className="sectionQuestion">
            Expense tracking is much easier with Minute 7 as it allows users to track both
            reimbursable, and credit card expenses.
        </div>
        <div className="sectionQuestion">
            <b>Reimbursable Expenses:</b>
        </div>
        <div className="sectionAnswer">
            As the name implies, reimbursable expenses are those expenses which are going to be
            reimbursed to the employee or vendor. This workflow will generate a bill in QuickBooks
            after syncing the expense from Minute 7 to QuickBooks.
        </div>
        <div className="sectionQuestion">
            <b>Example</b>
        </div>
        <div className="sectionAnswer">
            A vendor purchased $500 in office supplies for your company with his own money, and now
            you want your company to reimburse the $500 to the vendor, they would have to enter a
            reimbursable expense entry in Minute7 and (if applicable) attach a receipt in Minute 7
            to get paid as shown below:
            <img
                src="help_images/expense_entries/reimbursible_expense_entry.png"
                className="w-100 m-2"
            />
            The reimbursable expense entry will then be approved by the manager and synced to
            QuickBooks, where it will have the type “Bill” and status “Open” as shown in the
            screenshot below:
            <img
                src="help_images/expense_entries/desktop_reimbursible_expense_entry.png"
                className="w-100 m-2"
            />
        </div>
        <div className="sectionQuestion">
            Difference Between Credit Card and Reimbursible Expense Entries:
        </div>
        <div className="sectionAnswer">
            You can see the difference in QuickBooks Desktop by noticing the different status and
            type of two expense entries when synced with QuickBooks below:
            <img
                src="help_images/expense_entries/credit_card_expense_entry_desktop.png"
                className="w-100 m-2"
            />
        </div>
    </div>
);

export default TrackingReimbursibleExpenses;
