import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from 'react-icons-kit';
import { arrow_left as chevronLeft } from 'react-icons-kit/ikons/arrow_left';
import { arrow_right as chevronRight } from 'react-icons-kit/ikons/arrow_right';
import { ic_date_range as calendarIcon } from 'react-icons-kit/md/ic_date_range';

import DateTimeRangePicker from './DateTimeRangePicker.js';

import '../../css/DateFilter.css';

const formatDate = date => {
    return moment(date).format('MMM D, Y');
};

export default class DateFilter extends PureComponent {
    static propTypes = {
        onPrevWeek: PropTypes.func.isRequired,
        onNextWeek: PropTypes.func.isRequired,
        onThisWeekPress: PropTypes.func.isRequired,
        startDate: PropTypes.instanceOf(Date),
        endDate: PropTypes.instanceOf(Date),
    };

    static defaultProps = {
        date: new Date(),
    };

    handleDateRangePressed = () => {
        if (this.datePickerRef) {
            this.datePickerRef.open();
        }
    };

    render() {
        const {
            startDate,
            endDate,
            onPrevWeek,
            onNextWeek,
            onRangeChange,
            onThisWeekPress,
        } = this.props;

        return (
            <div className="dateFilter">
                <Icon
                    className="prevButton secondaryButton"
                    size={22}
                    icon={chevronLeft}
                    onClick={onPrevWeek}
                />
                <div className="dateRange secondaryButton" onClick={this.handleDateRangePressed}>
                    <DateTimeRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        ref={c => (this.datePickerRef = c)}
                        onRangeChange={onRangeChange}
                        onThisWeekPress={onThisWeekPress}
                        todayButtonText="This Week"
                    />
                    {formatDate(startDate)} – {formatDate(endDate)}
                    <Icon className="icon" size={22} icon={calendarIcon} />
                </div>
                <Icon
                    className="nextButton secondaryButton"
                    size={22}
                    icon={chevronRight}
                    onClick={onNextWeek}
                />
            </div>
        );
    }
}
