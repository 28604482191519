import React, { useRef } from 'react';
import Icon from 'react-icons-kit';
import { renderFileName } from '../../../utils/CommonFunctions.js';
import { ic_delete as deleteIcon } from 'react-icons-kit/md/ic_delete';
import { ic_attach_file as attachmentIcon } from 'react-icons-kit/md/ic_attach_file';
import { filePdfO as pdfIcon } from 'react-icons-kit/fa/filePdfO';
import '../../../css/ContactUsAttachment.css';

function ContactUsAttachment(props) {
    const attachmentsInputRef = useRef(null);
    const { attachments } = props;

    const renderCorrectThumbnail = (src, fileType) => {
        if (fileType.includes('pdf')) {
            return <Icon icon={pdfIcon} size={40} />;
        } else {
            return (
                <span className="thumbnailContainer">
                    <img className="thumbnail" src={src} title="attachment" alt="Attachment" />
                </span>
            );
        }
    };

    const handleAttachmentUploaded = () => {
        const { files } = attachmentsInputRef.current;
        props.handleFilesUploaded(files);
    };

    return (
        <div style={{ marginBottom: '10px', marginTop: '15px' , width:'100%'}}>
            <div>
                {attachments.length > 0 && (
                    <div className="attachmentsContainer">
                        {attachments.map(attachment => (
                            <div className="attachmentContainer" key={attachment.id}>
                                {renderCorrectThumbnail(attachment.path, attachment.type)}

                                <div className="filename">
                                    {renderFileName(attachment.name, attachment.type)}
                                </div>

                                <button
                                    className="destructiveSecondaryButton deleteAttachmentButton"
                                    onClick={() =>
                                        props.handleRemoveAttachmentPressed(attachment.id)
                                    }
                                >
                                    <Icon icon={deleteIcon} size={20} />
                                </button>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div>
                <button
                    className="secondaryButton addAttachmentButton"
                    onClick={() => attachmentsInputRef.current.click()}
                >
                    <Icon icon={attachmentIcon} size={20} />
                    Add Attachment
                </button>

                <input
                    hidden
                    ref={attachmentsInputRef}
                    multiple
                    type="file"
                    onChange={handleAttachmentUploaded}
                />
            </div>
        </div>
    );
}

export default ContactUsAttachment;
