import React from 'react';
import { Link } from 'react-router-dom';

const DeactivateAccount = () => (
    <div>
        <div className="sectionQuestion">What if I want to deactivate my Minute7 account?</div>
        <div className="sectionAnswer">
            You can deactivate your Minute7 account at any time, with no cancellation fees or hard
            feelings. But please let us know if there's anything we can do to keep your business.
        </div>
        <div className="sectionButton">
            <Link className="destructiveButton" to="/deactivate_account">
                Deactivate Account
            </Link>
        </div>
    </div>
);

export default DeactivateAccount;
