import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';
import GoogleAnalytics from '../utils/GoogleAnalytics.js';

const unwrapEntry = ({ ExpenseEntry, Customer, QbClass, Vendor, Expenseable }) => ({
    ...ExpenseEntry,
    Customer,
    QbClass,
    Expenseable,
    Vendor,
});

export const getExpenses = (paramsObject = {}) => {
    return getData(paramsObject, 'expenses/view').then(({ ExpenseEntries, meta }) => {
        const entries = ExpenseEntries.map(unwrapEntry);

        return {
            entries,
            meta,
        };
    });
};

export const addExpenseEntry = (data, paramsObject = {}) => {
    GoogleAnalytics.expenseEntry('Add');
    return postRequestWithFormData(data, 'expenses/add', paramsObject).then(unwrapEntry);
};

export const editExpenseEntry = (data, paramsObject = {}) => {
    GoogleAnalytics.expenseEntry('Edit');
    return postRequestWithFormData(data, 'expenses/edit', paramsObject).then(unwrapEntry);
};

export const deleteExpenseEntry = (paramsObject = {}) => {
    GoogleAnalytics.expenseEntry('Delete');
    return postRequestWithFormData({}, 'expenses/delete', paramsObject, {
        'Content-Type': 'multipart/form-data',
    });
};

export const setApproved = (data, paramsObject = {}) => {
    let action = data['approved_status'] === 'Yes' ? 'Approved' : 'Disapproved';
    GoogleAnalytics.expenseEntry(action);
    return postRequestWithFormData(data, 'expenses/set_approved', paramsObject).then(
        res => res.ExpenseEntry
    );
};

export const setLocked = (data, paramsObject = {}) => {
    let action = 'Locked';
    GoogleAnalytics.expenseEntry(action);
    return postRequestWithFormData(data, 'expenses/set_locked', paramsObject).then(
        res => res.ExpenseEntry
    );
};

export const setBillable = (data, paramsObject = {}) => {
    let action = data['billable_status'] === 'Yes' ? 'Marked Billable' : 'Marked Unbillable';
    GoogleAnalytics.expenseEntry(action);
    return postRequestWithFormData(data, 'expenses/set_billable', paramsObject).then(
        res => res.ExpenseEntry
    );
};
