import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { isNameValid } from '../../../utils/CommonFunctions.js';
import { Row, Col } from 'react-bootstrap';
import { payrollItemFields } from '../../../config/Fields.js';
import { showToast } from '../../../actions/Actions.js';
import 'react-phone-input-2/lib/style.css';
import Select from '../../components/Select.js';
import Spinner from '../../components/Spinner.js';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';
import { updatePayrollItem } from '../../../actions/PayrollItemAction.js';
import { selectPayrollItem } from '../../../actions/Actions.js';

const payrollItemTypes = [
    { name: 'Regular Pay', id: 'RegularPay' },
    { name: 'Overtime Pay', id: 'OverTimePay' },
    { name: 'Leave Pay', id: 'LeavePay' },
];
class EditPayrollItemForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState({ selectedView: 'payroll_item' });
    }

    componentWillReceiveProps(props) {}

    defaultState = data => {
        const { accountId, payrollItem } = this.props;

        return {
            pNameInvalidMsg: '',
            id: payrollItem.id || '',
            name: payrollItem.name || '',
            wage_type: payrollItem.wage_type || '',
            account_id: accountId,
            spinner_loader: false,
            hidden: payrollItem.hidden || false,
        };
    };

    constructPayrollItem = () => {
        const data = {};
        payrollItemFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else {
                    data[field] = this.state[field];
                }
            }
        });
        return data;
    };

    checkRequiredFields = () => {
        const { name, wage_type } = this.state;

        if (name === '' || wage_type === '') {
            this.props.showToast('Please fill out name, item type ', 'warning');
            return false;
        }
        return true;
    };

    savePayrollItem = event => {
        event.preventDefault();
        if (this.state.pNameInvalidMsg !== '') {
            this.props.showToast('Please Fix the form error before submit', 'warning');
        } else {
            if (!this.checkRequiredFields()) {
                return;
            }
            this.setState({ spinner_loader: true });
            const data = this.constructPayrollItem();
            this.props.updatePayrollItem(data, newPayrollItem => {
                if (newPayrollItem === false) {
                    this.setState({ spinner_loader: false });
                    return;
                } else {
                    this.props.showToast(
                        `New Payroll Item ${newPayrollItem.name} created`,
                        'positive'
                    );
                    this.props.history.push('/resource_settings/payroll_items');
                }
            });
        }
    };

    handleItemTypeOnChange = newItemType => {
        this.setState({ wage_type: newItemType });
    };

    setFields = fields => {
        this.setState({ ...fields });
    };

    handleOnChanges = {
        handlePayrollItemName: newName => {
            this.setState({ name: newName.target.value });
        },
    };

    render() {
        const { spinner_loader, name, wage_type, hidden } = this.state;
        return (
            <div className="applicationWrapper">
                <div className="application">
                    <div className="ftContainer cardContainer">
                        <form className="ftForm" onSubmit={this.savePayrollItem}>
                            <Row className="ftRowAlightCenter">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Edit Payroll Item
                                </div>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                Name
                                            </div>
                                            <div className="required">*</div>
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.pNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="name"
                                                value={name}
                                                onChange={
                                                    this.handleOnChanges.handlePayrollItemName
                                                }
                                                onBlur={
                                                    name.length !== 0
                                                        ? () =>
                                                              isNameValid(name)
                                                                  ? this.setFields({
                                                                        cNameInvalidMsg: '',
                                                                    })
                                                                  : this.setFields({
                                                                        cNameInvalidMsg:
                                                                            'Payroll Item name contains only  1 to 50 alphabets.',
                                                                    })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.pNameInvalidMsg === '' &&
                                                !name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.pNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                Pay Type
                                            </div>
                                            <div className="required">*</div>
                                        </Row>
                                        <div>
                                            <Select
                                                options={payrollItemTypes}
                                                withSearch
                                                labelWhenNothingSelected="No Pay type Selected"
                                                value={wage_type}
                                                onChange={this.handleItemTypeOnChange}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '15px', marginTop: '15px' }}>
                                    <div className="ftLabelAndInput">
                                        <div>
                                            <input
                                                name="hidden_checkbox"
                                                type="checkbox"
                                                className="checkbox"
                                                checked={hidden}
                                                onChange={() =>
                                                    this.setFields({
                                                        hidden: !hidden,
                                                    })
                                                }
                                            />
                                            <label htmlFor="hidden_checkbox">
                                                Mark Payroll Item as inactive
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button type="submit" className="primaryButton ftButton">
                                            Save
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button
                                            className="secondaryButton ftButton"
                                            onClick={() =>
                                                this.props.history.push(
                                                    '/resource_settings/payroll_items'
                                                )
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Spinner loading={spinner_loader} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        appStore: { selectedPayrollItemId },
    } = state;

    const payrollItem = selectedPayrollItemId;
    return {
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        isSaving: state.accountUserStore.isSaving,
        payrollItem,
    };
}

const mapDispatchToProps = {
    showToast,
    updatePayrollItem,
    selectPayrollItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditPayrollItemForm);
