import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createMigrate, persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import createSagaMiddleware from 'redux-saga';
import localforage from 'localforage';
import allReducers from '../stores';
import rootSaga from '../sagas';
import migrations from './migrations';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

localforage.config({
    name: 'Minute7',
    version: 1.0,
    size: 4900000, // 5MB - Safari has the lowest limit of 5MB before asking
    storeName: 'M7',
    description: 'Minute7 Storage',
});

const appStoreBlacklistFilter = createBlacklistFilter('appStore', [
    'password',
    'globalAlertMessage',
    'globalAlertType',
    'isGlobalAlertVisible',
]);

const timesheetsStoreBlacklistFilter = createBlacklistFilter('timesheetsStore', [
    'loading',
    'isSaving',
    'updatingStatusEntryIds',
    'updatingBillableEntryIds',
]);

const expensesStoreBlacklistFilter = createBlacklistFilter('expensesStore', [
    'loading',
    'isSaving',
    'updatingStatusEntryIds',
    'updatingBillableEntryIds',
]);

// Important: changing the version number will no longer reset the entire
// store, forcing users to log in again. When you change the version number,
// please write a corresponding migration in ./migrations.js.
export const PERSIST_CONFIG = {
    key: 'M7',
    version: 10,
    storage: localforage,
    transforms: [
        appStoreBlacklistFilter,
        timesheetsStoreBlacklistFilter,
        expensesStoreBlacklistFilter,
    ],
    migrate: createMigrate(migrations, { debug: false }),
    stateReconciler: autoMergeLevel2,
    throttle: 1000,
};

export const reducers = combineReducers({
    ...allReducers,
});

const persistedReducer = persistReducer(PERSIST_CONFIG, reducers);

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger');
    middleware.push(createLogger({ collapsed: true }));
}

export const appStore = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

if (module.hot) {
    module.hot.accept('../stores', () => {
        appStore.replaceReducer(require('../stores/index').default);
    });
}

export const persistor = persistStore(appStore);
