import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';

export const getAllAccounts = async (paramsObject = {}) => {
    const { QbAccounts, meta } = await getData(paramsObject, 'v1/qbaccounts');
    let accountsList = QbAccounts.map(item => {
        return item.QbAccount;
    });
    return {
        accountsList,
        meta,
    };
};

export const getAllAccountsForTable = async (paramsObject = {}) => {
    const { QbAccounts, meta } = await getData(paramsObject, 'v1/qbaccounts/paginated');
    let accountsList = QbAccounts.map(item => {
        return item.QbAccount;
    });
    return {
        accountsList,
        meta,
    };
};

export const createNewAccount = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(data, 'v1/quickbooks_accounts', paramsObject);
    return res.QbAccount;
};

export const updateExistingAccount = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        data,
        'v1/quickbooks_accounts/' + data['QuickbooksAccount[id]'],
        paramsObject
    );
    return res.QbAccount;
};

export const activateAccount = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/quickbooks_accounts/' + id + '/activate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.QbAccount;
};

export const deactivateAccount = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/quickbooks_accounts/' + id + '/deactivate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.QbAccount;
};
