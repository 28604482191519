/*
import { getInitialState, ensureStateConformsToInitialStateShape } from '../utils/MigrationUtils';

Depending on what you've changed about the state shape, there a few
recommended approaches:

1.  If you've only added new properties or updated an existing property:

const migrations = {
    ...,
    1: (state) => {
        return {
            ...state,
            storeYouveChanged: {
                ...state.storeYouveChanged,
                newPropYouveAddedOrUpdated: someDefaultValue,
            }
        }
    }
}

2.  If you've added or removed properties, but haven't updated the type of a
    property (e.g. changed a prop from an array to an object):

const migrations = {
    ...,
    1: (state) => {
        return ensureStateConformsToInitialStateShape(state);
    }
}

This function will keep the current values of any properties that exist in both
the new initial state and the old persisted state. It will add new properties
that only exist in the new initial state and will delete any properties that
no longer exist in the initial state.

3. If you've added new properties, deleted others and updated some, use a
    combination of both of the approaches above:

const migrations = {
    ...,
    1: (state) => {
        const tempState = ensureStateConformsToInitialStateShape(state);
        return {
            ...tempState,
            storeYouveChanged: {
                ...tempState.storeYouveChanged,
                propYouveUpdated: newValue,
            }
        }
    }
}

As a last resort, you can blow away the old store and reset everything to
the initial state by just passing in the `getInitialState` function to the migration.
This, however, results in bad user experience, because the user is forced to log out
of the app, so try to keep the use of this approach to a minimum.

*/

const migrations = {
    10: state => {
        return {
            ...state,
            expensesStore: {
                ...state.expensesStore,
                paymentAccountId: '0',
            },
            accountStore: {
                ...state.accountStore,
                expense_transaction_type: null,
            },
        };
    },
};

export default migrations;
