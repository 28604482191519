import React from 'react';

const QBSyncBeforeUsing = () => (
    <div>
        <div className="sectionQuestion">
            I've synced Minute7 with QuickBooks, but Minute7 still tells me I need to sync before I
            can start using it. What should I do?
        </div>
        <div className="sectionAnswer">
            If you have synced Minute7 with QuickBooks, and QuickBooks Web Connector confirms the
            completion of the sync operation, try logging out of Minute7, then logging back in to
            refresh your account.
            <br /> <br />
            If the issue persists. Please <a href="/contact_us">contact support</a>
        </div>
    </div>
);

export default QBSyncBeforeUsing;
