import _ from 'lodash';
import moment from 'moment';
import { toParamsObject, expenseFields } from '../config/Fields.js';
import { isDateInRange, isValidDate } from './DateUtils.js';
import { KILOMETERS, NO_VENDOR_ASSOCIATION_STRING } from '../config/Constants.js';
import { milesToKilometers } from './CommonFunctions.js';

/**
 * Validates data of an expense entry
 * VendorId required
 * Date required
 * @todo add test case
 * @param {*} entry
 * @param {*} showAlert
 */
export const isDataValid = (
    entry,
    expenseTransactionType,
    selectedExpenseType,
    showAlert = null,
    showToast = null
) => {
    const canShowAlert = typeof showAlert === 'function';
    const canShowToast = typeof showToast === 'function';

    if (entry.expenseable_id === '0') {
        canShowAlert && showToast('Please select Account/Item from dropdown!', 'negative');
        return false;
    }

    for (let key in entry) {
        const isValueEmpty = entry[key] === null || entry[key] === '';
        // Checking vendor
        if (key === 'vendor_id' && isValueEmpty) {
            if (parseInt(entry[key]) <= 0) {
                canShowAlert && showAlert(NO_VENDOR_ASSOCIATION_STRING, 'negative');
                return false;
            } else if (isValueEmpty) {
                canShowToast && showToast('Please select a vendor', 'warning');
                return false;
            }
        }

        if (
            (expenseTransactionType === 'purchase' ||
                (expenseTransactionType === 'bill_and_purchase' &&
                    selectedExpenseType === 'purchase')) &&
            entry['payment_account_id'] === 0
        ) {
            canShowToast && showToast('Must select Payment Account for this entry', 'warning');
            return false;
        }

        // Checking date
        if (key === 'date' && isValueEmpty && !isValidDate) {
            canShowToast &&
                showToast('Please provide a valid date in format: YYYY-MM-DD', 'warning');
            return false;
        }

        if (key === 'date' && !isDateInRange(entry[key])) {
            canShowToast &&
                showToast('Date should be in range of 5 years', 'warning');
            return false;
        }
    }
    return true;
};

/**
 * Given an accountUser and vendors, figure the appropriate vendorId to select
 * @param {*} accountUserStore
 * @param {*} vendors
 * @todo test case
 */
export const figureVendorId = (accountUserStore, vendors) => {
    if (accountUserStore.expense_vendor_id !== '0') {
        return accountUserStore.expense_vendor_id;
    } else {
        let canViewOtherVendors =
            accountUserStore.view_others_expenses === 'Yes' ||
            accountUserStore.manage_users === 'Yes' ||
            accountUserStore.manage_account === 'Yes';
        if (canViewOtherVendors && vendors.length > 0) {
            return vendors[0].id;
        } else {
            return '0';
        }
    }
};

/**
 * Returns the default, initial activeFilters
 * @todo test case
 * @param {Boolean} canViewOthersTime whether the current AccountUser can view others' expenses
 */
export const getDefaultFiltersForCurrentUser = canViewOthersExpenses => {
    const activeFilters = ['date', 'search', 'customer', 'approved'];
    if (canViewOthersExpenses) {
        activeFilters.push('vendor');
    }
    return activeFilters;
};

/**
 * @todo add test case
 * @param {object} state object holding all data of expense entry
 * @param {object} accountUser
 */
export const constructParamsObject = (state, accountUser, urlVariables = null) => {
    const {
        activeFilters,
        searchVendorIds,
        searchCustomerIds,
        searchQbAccountIds,
        searchQbClassIds,
        searchExpenseGroupingIds,
        approvedFilter,
        lockedFilter,
        exportedFilter,
        expenseTypeFilter,
        billableFilter,
        attachmentFilter,
        currentPage,
    } = state;

    const startDate = new Date(state.startDate);
    const endDate = new Date(state.endDate);

    let paramsObject = {
        current_page: currentPage,
    };

    // Vendor filter
    if (activeFilters.includes('vendor') && searchVendorIds.length > 0) {
        paramsObject.expense_vendor_id = searchVendorIds.join(',');
    } else if (
        accountUser.view_others_expenses !== 'No' ||
        accountUser.manage_account === 'Yes' ||
        accountUser.manage_users === 'Yes'
    ) {
        paramsObject.expense_vendor_id = 'All';
    } else {
        paramsObject.expense_vendor_id = accountUser.expense_vendor_id;

        // AccountUser not associated with any vendor, set to -1;
        if (paramsObject.expense_vendor_id === '0') {
            paramsObject.expense_vendor_id = -1;
        }
    }

    if (state.activeFilters.includes('date')) {
        paramsObject.filter_by_date = true;
        paramsObject.search_start_date = moment(startDate).format('Y-MM-DD');
        paramsObject.search_end_date = moment(endDate).format('Y-MM-DD');
    }

    if (activeFilters.includes('customer') && searchCustomerIds.length > 0) {
        paramsObject.customer_ids = searchCustomerIds.join(',');
    }

    if (activeFilters.includes('qbAccount') && searchQbAccountIds.length > 0) {
        paramsObject.qb_account_ids = searchQbAccountIds.join(',');
    }

    if (activeFilters.includes('qbClass') && searchQbClassIds.length > 0) {
        paramsObject.qb_class_ids = searchQbClassIds.join(',');
    }

    if (activeFilters.includes('bill') && searchExpenseGroupingIds.length > 0) {
        paramsObject.expense_grouping_ids = searchExpenseGroupingIds.join(',');
    }

    if (activeFilters.includes('billable') && toParamsObject[billableFilter] !== '') {
        paramsObject.billable = toParamsObject[billableFilter];
    }

    if (activeFilters.includes('approved') && toParamsObject[approvedFilter] !== '') {
        paramsObject.approved = toParamsObject[approvedFilter];
    }

    if (activeFilters.includes('locked') && toParamsObject[lockedFilter] !== '') {
        paramsObject.locked = toParamsObject[lockedFilter];
    }

    if (activeFilters.includes('exported') && toParamsObject[exportedFilter] !== '') {
        paramsObject.exported = toParamsObject[exportedFilter];
    }

    if (activeFilters.includes('expenseType') && toParamsObject[expenseTypeFilter] !== '') {
        paramsObject.expenseType = toParamsObject[expenseTypeFilter];
    }

    if (activeFilters.includes('attachment') && toParamsObject[attachmentFilter] !== '') {
        paramsObject.has_attachment = toParamsObject[attachmentFilter];
    }

    if (typeof urlVariables === 'object') {
        for (let key in urlVariables) {
            if (key === 'entry_ids') {
                // Set filter by date to false to ensure we pull entry ids
                paramsObject['filter_by_date'] = 0;
                paramsObject['expense_vendor_id'] = 'All';
                paramsObject[key] = urlVariables[key];
            } else {
                paramsObject[key] = urlVariables[key];
            }
        }
    }

    return paramsObject;
};

/**
 * Figure which vendor to select by default
 *  - If associated with a vendor - select that vendor
 *  - Else use all (empty object)
 * @param {*} accountUser accountUser fields
 * @return {object}
 * @todo test case
 */
export const figureDefaultSelectedVendors = accountUser => {
    if (parseInt(accountUser.expense_vendor_id) !== 0) {
        return [accountUser.expense_vendor_id];
    }
    return [];
};

/* Sorts an array of entries by vendor name alphabetically */
export const sortByVendorAndDate = entries => {
    const nameSort = entry => (entry.Vendor ? entry.Vendor.name.toLowerCase() : '');
    const dateSort = entry => -moment(entry.date).valueOf();
    return _.sortBy(entries, [dateSort, nameSort]);
};

export const mapValuesToEntry = props => {
    if (props.mode === 'edit' || props.isDuplicate) {
        return {
            amount: props.editAmount,
            approved: props.editApproved,
            billable: props.editBillable,
            customerId: props.editCustomerId,
            date: props.editDate,
            description: props.editDescription,
            expenseGroupingId: props.editExpenseGroupingId,
            exported: props.editExported,
            id: props.editId,
            miles: props.editMiles,
            qbAccountId: props.editQbAccountId,
            inventoryItemId: props.inventoryItemId,
            qbClassId: props.editQbClassId,
            reimbursementRate: props.editReimbursementRate,
            vendorId: props.editVendorId,
            attachments: props.editAttachments,
            deletedAttachments: props.editDeletedAttachments,
        };
    } else {
        return props;
    }
};

export const constructEditExpenseStore = (entry, duplicate) => {
    return {
        editAmount: entry.amount,
        editApproved: entry.approved,
        editBillable: entry.billable,
        editCustomerId: entry.customer_id,
        editDate: entry.date,
        editDescription: entry.description,
        editExpenseGroupingId: entry.expense_grouping_id,
        editExported: entry.exported,
        editId: entry.id,
        editMiles: entry.miles,
        inventoryItemId: entry.inventory_item_id,
        editExpenseableId: entry.expenseable_id,
        editQbClassId: entry.qb_class_id,
        editReimbursementRate: entry.reimbursement_rate,
        editVendorId: entry.vendor_id,
        editAttachments: entry.attachments && !duplicate ? entry.attachments : [],
    };
};

export const formDataForEntry = entry => {
    const data = {};
    _.forEach(_.pick(entry, expenseFields), (value, key) => {
        switch (key) {
            case 'date': {
                if (entry[key] instanceof Date) {
                    data[`ExpenseEntry[${key}]`] = moment(entry.date).format('Y-MM-DD');
                } else if (typeof entry.date === 'string') {
                    data[`ExpenseEntry[${key}]`] = entry[key].split('T')[0];
                }
                break;
            }
            case 'attachments': {
                data['new_attachments'] = entry.attachments
                    .filter(attachment => !attachment.alreadyAdded)
                    .map(attachment => ({
                        name: attachment.name,
                        path: attachment.url,
                        size: attachment.size,
                        type: attachment.type,
                    }));
                break;
            }
            case 'deletedAttachments': {
                if (!entry.deletedAttachments) {
                    break;
                }

                data['deleted_attachments'] = entry.deletedAttachments.map(attachment => ({
                    url: attachment.url,
                    id: attachment.id,
                }));
                break;
            }
            case 'payment_account_id': {
                data[`ExpenseGrouping[${key}]`] = entry[key];
                break;
            }
            default: {
                data[`ExpenseEntry[${key}]`] = entry[key];
            }
        }
    });

    return data;
};

export const createReimbursementDescription = ({
    amount,
    description,
    miles,
    reimbursementRate,
    preferredDistanceUnits,
}) => {
    const unitSingular = preferredDistanceUnits === KILOMETERS ? 'kilometer' : 'mile';
    const unitPlural = preferredDistanceUnits === KILOMETERS ? 'kilometers' : 'miles';

    const descriptionLines = (description || '').split(')');
    const distance =
        preferredDistanceUnits === KILOMETERS ? milesToKilometers(miles).toFixed(2) : miles;
    const reimbursementRateInDisplayUnits = convertAndFormatRate(
        reimbursementRate,
        preferredDistanceUnits
    );

    const newDescription = `(${distance} ${parseFloat(miles) === 1 ? unitSingular : unitPlural
        } at ${reimbursementRateInDisplayUnits}/${unitSingular} = $${amount}`;

    if (descriptionLines[0].includes('$') && descriptionLines[0].includes(unitSingular)) {
        descriptionLines[0] = newDescription;
    } else {
        descriptionLines.unshift(newDescription);
    }
    return descriptionLines.join(') ');
};

export const calculateReimbursementCost = (miles, reimbursementRate, preferredDistanceUnit) => {
    let cost;
    if (preferredDistanceUnit === KILOMETERS) {
        cost = miles * milesToKilometers(1) * reimbursementRate * milesToKilometers(1);
    } else cost = miles * reimbursementRate;

    return cost.toFixed(2);
};

export const convertAndFormatRate = (rate, displayUnits) => {
    if (rate) {
        const convertedValue =
            displayUnits === KILOMETERS ? milesToKilometers(parseFloat(rate)) : parseFloat(rate);
        return convertedValue.toFixed(4);
    } else return rate;
};
