export const setAllCustomers = payload => ({
    type: 'SET_ALL_CUSTOMERS',
    payload,
});

export const addCustomer = (customer, callback) => ({
    type: 'ADD_CUSTOMER',
    customer,
    callback,
});

export const addCustomerList = () => ({
    type: 'ADD_CUSTOMER_LIST',
});

export const addCustomerListError = () => ({
    type: 'ADD_CUSTOMER_LIST_ERROR',
});

export const addCustomerSuccess = customer => ({
    type: 'ADD_CUSTOMER_SUCCESS',
    customer,
});

export const addCustomerError = error => ({
    type: 'ADD_CUSTOMER_ERROR',
    error,
});

export const updateCustomer = (customer, callback) => ({
    type: 'UPDATE_CUSTOMER',
    customer,
    callback,
});

export const updateCustomerSuccess = customer => ({
    type: 'UPDATE_CUSTOMER_SUCCESS',
    customer,
});

export const updateCustomerError = error => ({
    type: 'UPDATE_CUSTOMER_ERROR',
    error,
});

export const hideCustomer = customerId => ({
    type: 'DEACTIVATE_CUSTOMER',
    customerId,
});

export const unhideCustomer = customerId => ({
    type: 'ACTIVATE_CUSTOMER',
    customerId,
});
