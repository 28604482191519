import { all, call, takeEvery, takeLatest, put } from 'redux-saga/effects';
import toaster from '../views/components/toast';
import * as uiActions from '../actions/Actions';
import history from '../history';

const redirectToLogin = () => {
    history.push('/login');
};

export function* showToast({ message, kind }) {
    yield call(toaster.notify, message, { kind });
}

export function* handleUnauthorizedRequest() {
    yield call(redirectToLogin);
    yield put(uiActions.logout());
    yield put(
        uiActions.showAlert('You’ve been logged out, because your session had expired.', 'negative')
    );
}

export default function* root() {
    yield all([
        takeEvery(['SHOW_TOAST'], showToast),
        takeLatest(['API_REQUEST_UNAUTHORIZED_ERROR'], handleUnauthorizedRequest),
    ]);
}
