import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import moment from 'moment';
import Icon from 'react-icons-kit';
import { fileExcelO } from 'react-icons-kit/fa/fileExcelO';
import { fileTextO } from 'react-icons-kit/fa/fileTextO';

import {
    getActivityLog,
    getActivityLogTotal,
    exportActivityLogExcel,
    exportActivityLogCSV,
} from '../../requests/ActivityLogRequest.js';
import DetailsLoading from '../components/DetailsLoading.js';
import NoEntriesFound from '../components/NoEntriesFound.js';
import Select from '../components/Select';
import SearchField from '../components/SearchField.js';
import Pagination from '../components/Pagination';
import { byAction, byObjectType, yearFilter, months } from '../../config/Fields.js';
import {
    figureAccountUserDisplayNameWithId,
    firstLetterToUpperCase,
    addSpaces,
    constructAccountUserList,
    replaceQuickbooks,
} from '../../utils/CommonFunctions.js';
import { firstAndLastDayOfYear, firstAndLastDayOfMonth } from '../../utils/DateUtils.js';
import { showToast, setActivityLog } from '../../actions/Actions.js';

import '../../css/CommonTheme.css';
import '../../css/ActivityLog.css';

// Defined in API
const perPage = 25;

class ActivityLog extends Component {
    constructor(props) {
        super(props);

        const { canManageAccount, canManageUsers, accountUserStore } = props;

        this.state = {
            filterAccountUserId: canManageAccount || canManageUsers ? null : accountUserStore.id,
            filterAction: null,
            filterObjectType: null,
            filterYear: null,
            filterMonth: null,
            filterText: '',
            activityLog: [],
            currentPage: 1,
            totalActivityLog: 0,
            exportFilename: '',
        };
    }

    componentDidMount() {
        this.getActivityLog();
    }

    resetState = () => {
        const { canManageAccount, canManageUsers, accountUserStore } = this.props;

        this.setState(
            {
                filterAccountUserId:
                    canManageAccount || canManageUsers ? null : accountUserStore.id,
                filterAction: null,
                filterObjectType: null,
                filterYear: null,
                filterMonth: null,
                currentPage: 1,
                filterText: '',
                activityLog: [],
                totalActivityLog: 0,
            },
            this.getActivityLog
        );
    };

    exportActivityLogExcel = () => {
        const paramsObject = this.constructParamsObject();
        exportActivityLogExcel(paramsObject);
    };

    exportActivityLogCSV = () => {
        const paramsObject = this.constructParamsObject();
        exportActivityLogCSV(paramsObject);
    };

    constructParamsObject = () => {
        const { session_id: sessionId, session_name: sessionName } = this.props.appStore;

        let restrictByDate =
            this.state.filterYear === null || this.state.filterYear === 'All Years' ? false : true;
        const firstAndLastDayOfFilter =
            restrictByDate &&
            this.state.filterMonth !== null &&
            this.state.filterMonth !== 'All Months'
                ? firstAndLastDayOfMonth(this.state.filterYear, this.state.filterMonth)
                : firstAndLastDayOfYear(this.state.filterYear);
        let paramsObject = {
            search_text: this.state.filterText,
            restrict_by_date: restrictByDate,
            start_date: restrictByDate ? firstAndLastDayOfFilter.first : '',
            end_date: restrictByDate ? firstAndLastDayOfFilter.last : '',
            object_type:
                this.state.filterObjectType !== null &&
                this.state.filterObjectType !== 'All Objects'
                    ? this.state.filterObjectType
                          .replace('QuickBooks', 'Quickbooks')
                          .replace(/ /g, '')
                    : null,
            action_type:
                this.state.filterAction !== null && this.state.filterObjectType !== 'All Actions'
                    ? this.state.filterAction.toLowerCase()
                    : null,
            account_user_id: this.state.filterAccountUserId,
            current_page: this.state.currentPage,
            filename: this.state.exportFilename,
        };

        if (sessionId !== null && sessionName !== null) {
            paramsObject[sessionName] = sessionId;
        }

        return _.pickBy(paramsObject, val => !_.isNil(val));
    };

    getActivityLog = () => {
        const paramsObject = this.constructParamsObject();
        this.setState({ loading: true });
        let promises = [getActivityLog(paramsObject), getActivityLogTotal(paramsObject)];

        return Promise.all(promises)
            .then(res => {
                this.props.setActivityLog(res[0]);
                this.setState({
                    activityLog: res[0],
                    totalActivityLog: parseInt(res[1], 10),
                    loading: false,
                });
            })
            .catch(() => {
                this.props.showToast(
                    'Couldn’t load activity log. Try reloading the page.',
                    'warning'
                );
                this.setState({ loading: false });
            });
    };

    handleExportFilenameChanged = newValue => {
        this.setState({ exportFilename: newValue.target.value });
    };

    handleSearchPressed = filterText => {
        this.setState({ filterText }, this.getActivityLog);
    };

    handleNextPagePressed = () => {
        if (this.state.currentPage * perPage <= this.state.totalActivityLog) {
            this.setState(
                {
                    currentPage: this.state.currentPage + 1,
                },
                this.getActivityLog
            );
        }
    };

    handlePreviousPagePressed = () => {
        if (this.state.currentPage >= 1) {
            this.setState(
                {
                    currentPage: this.state.currentPage - 1,
                },
                this.getActivityLog
            );
        }
    };

    renderAccountUserName = index => {
        if (
            'accountUsers' in this.props.accountStore &&
            this.props.accountStore.accountUsers.length > 0
        ) {
            return figureAccountUserDisplayNameWithId(
                this.state.activityLog[index].account_user_id,
                this.props.accountStore.accountUsers
            );
        } else {
            return (
                this.props.accountUserStore.first_name + ' ' + this.props.accountUserStore.last_name
            );
        }
    };

    renderActivityLog = () => {
        let activityLog = [];
        for (let i = 0; i < this.state.activityLog.length; i++) {
            let dateToDisplay = moment(this.state.activityLog[i].created);
            dateToDisplay = dateToDisplay.format('YYYY/MM/DD h:mm:ss a');
            activityLog.push(
                <tr key={this.state.activityLog[i].id} className="detailsRow">
                    <td>{this.renderAccountUserName(i)}</td>
                    <td>
                        {this.state.activityLog[i].message.split('\n').map(str => (
                            <tr>{replaceQuickbooks(str)}</tr>
                        ))}
                    </td>
                    <td>{firstLetterToUpperCase(this.state.activityLog[i].action)}</td>
                    <td>
                        {addSpaces(
                            this.state.activityLog[i].object_type.replace('Soap', '')
                        ).replace('Quickbooks', 'QuickBooks')}
                    </td>
                    <td>{dateToDisplay}</td>
                </tr>
            );
        }

        return (
            <table className="alActivityLogTable dataTable">
                <thead>
                    <tr className="detailsHeaderRow">
                        <th className="alAccountUserCell">Account User</th>
                        <th className="alMessageCell">Message</th>
                        <th className="alActionCell">Action</th>
                        <th className="alObjectTypeCell">Object Type</th>
                        <th className="alDateCell">Date</th>
                    </tr>
                </thead>
                <tbody>{activityLog}</tbody>
            </table>
        );
    };

    render() {
        const { totalActivityLog: totalEntries, currentPage } = this.state;

        return (
            <div className="applicationBody">
                <div className="bodyHeader">
                    <h2>{this.props.accountStore.name + ' Activity Log'}</h2>
                </div>

                <div className="alExportAndPagination">
                    <div className="alExportOptions">
                        <Input
                            className="activityLogExportOption"
                            value={this.state.exportFilename}
                            onChange={this.handleExportFilenameChanged}
                            placeholder="Export Filename"
                        />
                        <button
                            className="secondaryButton activityLogExportOption"
                            onClick={this.exportActivityLogCSV}
                        >
                            <Icon className="rExportButtonIcon" icon={fileTextO} size={20} />
                            Export CSV
                        </button>
                        <button
                            className="secondaryButton activityLogExportOption"
                            onClick={this.exportActivityLogExcel}
                        >
                            <Icon className="rExportButtonIcon" icon={fileExcelO} size={20} />
                            Export Excel
                        </button>
                    </div>
                </div>

                <div className="filters activeFilters">
                    <SearchField placeholder="Search" onSearchPressed={this.handleSearchPressed} />

                    <Select
                        toggleStyle="button"
                        withSearch
                        className="multiselectFilter"
                        options={constructAccountUserList(
                            this.props.accountStore.accountUsers,
                            this.props.accountUserStore
                        )}
                        labelWhenNothingSelected="User"
                        value={this.state.filterAccountUserId}
                        onClear={() =>
                            this.setState({ filterAccountUserId: null }, this.getActivityLog)
                        }
                        onChange={id =>
                            this.setState({ filterAccountUserId: id }, this.getActivityLog)
                        }
                    />

                    <Select
                        toggleStyle="button"
                        className="multiselectFilter"
                        options={byAction}
                        labelForOption={option => option}
                        valueForOption={option => option}
                        onChange={value =>
                            this.setState({ filterAction: value }, this.getActivityLog)
                        }
                        onClear={() => this.setState({ filterAction: null }, this.getActivityLog)}
                        value={this.state.filterAction}
                        labelWhenNothingSelected="Action"
                    />

                    <Select
                        toggleStyle="button"
                        className="multiselectFilter"
                        options={byObjectType}
                        labelForOption={option => option}
                        valueForOption={option => option}
                        onChange={value =>
                            this.setState({ filterObjectType: value }, this.getActivityLog)
                        }
                        onClear={() =>
                            this.setState({ filterObjectType: null }, this.getActivityLog)
                        }
                        value={this.state.filterObjectType}
                        labelWhenNothingSelected="Object Type"
                    />

                    <Select
                        toggleStyle="button"
                        className="multiselectFilter"
                        options={yearFilter}
                        labelForOption={option => option}
                        valueForOption={option => option}
                        onChange={value =>
                            this.setState({ filterYear: value }, this.getActivityLog)
                        }
                        onClear={() => this.setState({ filterYear: null }, this.getActivityLog)}
                        value={this.state.filterYear}
                        labelWhenNothingSelected="Year"
                    />

                    {this.state.filterYear !== null &&
                        (this.state.filterYear !== 'All Years' && (
                            <Select
                                toggleStyle="button"
                                className="multiselectFilter"
                                options={months}
                                labelForOption={option => option}
                                valueForOption={option => option}
                                onChange={value =>
                                    this.setState({ filterMonth: value }, this.getActivityLog)
                                }
                                onClear={() =>
                                    this.setState({ filterMonth: null }, this.getActivityLog)
                                }
                                value={this.state.filterMonth}
                                labelWhenNothingSelected="Month"
                            />
                        ))}

                    <button className="alResetFilter secondaryButton" onClick={this.resetState}>
                        Reset Filter
                    </button>
                </div>

                {this.state.loading ? (
                    <div className="cardContainer">
                        <DetailsLoading description="Loading Activity Log" />
                    </div>
                ) : this.state.activityLog.length > 0 ? (
                    <div>
                        <div className="cardContainer">{this.renderActivityLog()}</div>
                        <Pagination
                            total={totalEntries}
                            perPage={perPage}
                            currentPage={currentPage - 1}
                            onPreviousPressed={this.handlePreviousPagePressed}
                            onNextPressed={this.handleNextPagePressed}
                        />
                    </div>
                ) : (
                    <div className="cardContainer">
                        <NoEntriesFound type="Activity" />
                    </div>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        accountStore: state.accountStore,
        accountUserStore: state.accountUserStore,
        appStore: state.appStore,
        canManageAccount: state.accountUserStore.manage_account === 'Yes',
        canManageUsers: state.accountUserStore.manage_users === 'Yes',
    };
}

const mapDispatchToProps = {
    showToast,
    setActivityLog,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityLog);
