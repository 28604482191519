/**
 * @todo test cases
 */
export const openTimer = (payload = {}) => ({
    type: 'OPEN_TIMER',
    payload,
});

export const closeTimer = () => ({
    type: 'CLOSE_TIMER',
});

export const setBillable = payload => ({
    type: 'SET_TIMER_BILLABLE',
    payload,
});

export const setTaxable = payload => ({
    type: 'SET_TIMER_TAXABLE',
    payload,
});

export const setApproved = payload => ({
    type: 'SET_TIMER_APPROVED',
    payload,
});

export const setCustomerId = payload => ({
    type: 'SET_TIMER_CUSTOMER_ID',
    payload,
});

export const setEmployeeId = payload => ({
    type: 'SET_TIMER_EMPLOYEE_ID',
    payload,
});

export const setPayrollItemId = payload => ({
    type: 'SET_TIMER_PAYROLL_ITEM_ID',
    payload,
});

export const setInventoryItemId = payload => ({
    type: 'SET_TIMER_INVENTORY_ITEM_ID',
    payload,
});

export const setQbClassId = payload => ({
    type: 'SET_TIMER_QB_CLASS_ID',
    payload,
});

export const setDate = payload => ({
    type: 'SET_TIMER_DATE',
    payload,
});

export const setStartTime = payload => ({
    type: 'SET_TIMER_START_TIME',
    payload,
});

export const setHoursOffDuty = payload => ({
    type: 'SET_TIMER_HOURS_OFF_DUTY',
    payload,
});

export const setDescription = payload => ({
    type: 'SET_TIMER_DESCRIPTION',
    payload,
});

export const setType = payload => ({
    type: 'SET_TIMER_TYPE',
    payload,
});

export const setRoundingInterval = payload => ({
    type: 'SET_TIMER_ROUNDING_INTERVAL',
    payload,
});

export const resetTimer = () => ({
    type: 'TIMER_RESET',
});

export const startTimer = () => ({
    type: 'START_TIMER',
});

export const pauseTimer = () => ({
    type: 'PAUSE_TIMER',
});

export const continueTimer = payload => ({
    type: 'CONTINUE_TIMER',
    payload,
});

export const setDefaultsForNewTimerEntries = defaults => ({
    type: 'SET_DEFAULTS_FOR_NEW_TIMER_ENTRIES',
    defaults,
});
