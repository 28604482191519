import React from 'react';

const WhoCanEnterExpenses = () => (
    <div>
        <div className="sectionQuestion">Who can enter expenses?</div>
        <div className="sectionAnswer">
            In Minute7, any user with the "Manage Users" permission or the "View and Edit All Time"
            permission can track expenses for any Vendor. Users who cannot manage users or edit all
            time can only add expenses if their user account has been associated with a specific{' '}
            <b>Associated Vendor for expense tracking in QuickBooks</b>. To associate a Minute7 user
            with a Vendor for expense tracking, go to the <b>user permissions</b> page for the user
            in question, and select the correct Vendor from the{' '}
            <b>"Associated Vendor for expense tracking in QuickBooks"</b> drop-down list.
        </div>
    </div>
);

export default WhoCanEnterExpenses;
