import React, { useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, Input } from 'reactstrap';
import Icon from 'react-icons-kit';
import { clock as clockIcon } from 'react-icons-kit/icomoon/clock';
import StartAndEndTimeCalculator from './StartAndEndTimeCalculator.js';
import useDismissableByClickOutside from '../../../hooks/useDismissableByClickOutside';

const DurationInput = ({
    onDurationChanged,
    onDurationFocus,
    onDurationBlur,
    withStartAndEndTime,
    duration,
    startTime,
    endTime,
    hoursOffDuty,
    calculateDuration,
    updateHoursOffProps,
    updateStartTimeProps,
    updateEndTimeProps,
}) => {
    const containerRef = useRef(null);
    const [isCalculatorOpen, setCalculatorOpen] = useDismissableByClickOutside(containerRef);

    useEffect(
        () => {
            if (isCalculatorOpen) {
                document.addEventListener('keyup', handleKeyup, false);
            }

            return () => {
                document.removeEventListener('keyup', handleKeyup, false);
            };
        },
        [isCalculatorOpen]
    );

    const handleKeyup = event => {
        if (event.key === 'Tab') {
            if (containerRef.current && containerRef.current.contains(document.activeElement)) {
                // focus is still within the dropdown, do nothing
                return;
            }

            setCalculatorOpen(false);
        }
    };

    const calculateDurationValue = calculationCriteria => {
        calculateDuration(calculationCriteria);
        setCalculatorOpen(false);
    };

    return (
        <div>
            <InputGroup>
                <Input
                    className="durationInput"
                    onFocus={onDurationFocus}
                    onBlur={onDurationBlur}
                    placeholder="0:00"
                    onChange={onDurationChanged}
                    value={duration}
                    autoComplete="off"
                />
                {withStartAndEndTime && (
                    <InputGroupAddon addonType="append">
                        <Icon
                            className="inputAddonAppendIcon"
                            size={15}
                            icon={clockIcon}
                            onClick={() => setCalculatorOpen(true)}
                            data-tip="Open Start/End Time Calculator"
                        />
                    </InputGroupAddon>
                )}
            </InputGroup>
            <StartAndEndTimeCalculator
                ref={containerRef}
                startTime={startTime}
                endTime={endTime}
                isOpen={isCalculatorOpen}
                offHours={hoursOffDuty}
                duration={duration}
                calculateDuration={calculateDurationValue}
                updateHoursOffProps={updateHoursOffProps}
                updateStartTimeProps={updateStartTimeProps}
                updateEndTimeProps={updateEndTimeProps}
            />
        </div>
    );
};

DurationInput.propTypes = {
    onDurationChanged: PropTypes.func.isRequired,
    onDurationFocus: PropTypes.func.isRequired,
    onDurationBlur: PropTypes.func.isRequired,
    onHoursOffDutyChanged: PropTypes.func.isRequired,
    withStartAndEndTime: PropTypes.bool,
    duration: PropTypes.string.isRequired,
    startTime: PropTypes.object.isRequired,
    endTime: PropTypes.object.isRequired,
    hoursOffDuty: PropTypes.string,
};

DurationInput.defaultProps = {
    withStartAndEndTime: true,
    duration: '',
};

export default memo(DurationInput);
