import React, { Component } from 'react';
import { qbConnect, isOauthValid, qbDisconnect, onlineSync } from '../../requests/SyncRequests.js';
import DetailsLoading from '../components/DetailsLoading.js';
import Icon from 'react-icons-kit';
import { ic_sync } from 'react-icons-kit/md/ic_sync';
import { caretDown } from 'react-icons-kit/fa/caretDown';
import { caretRight } from 'react-icons-kit/fa/caretRight';
import '../../css/Sync.css';
import { connect } from 'react-redux';
import { showAlert, showToast } from '../../actions/Actions.js';
import { setAccountStore, toggleSyncing } from '../../actions/AccountActions.js';
import { requestBusinessResources } from '../../actions/BusinessResourcesActions';

class OnlineSync extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOauthValid: false,
            loading: true,
            disconnecting: false,
            isSyncPreferencesHidden: true,
            isAdministrativeOptionsHidden: true,
            isSyncing: false,
        };
    }

    componentDidMount() {
        this.isOauthValid();
    }

    qbConnect = params => {
        qbConnect(params);
    };

    sync = () => {
        this.setState({ isSyncing: true });
        onlineSync({ user_id: this.props.accountUserStore.id })
            .then(res => {
                if ('error_message' in res) {
                    throw new Error(res.error_message);
                }

                this.props.requestBusinessResources();
                this.props.showAlert(
                    () => (
                        <div key="globalAlertMessage">
                            {res.message.header}
                            <br />
                            {res.message.detail}
                        </div>
                    ),
                    'positive'
                );
                this.setState({ isSyncing: false });
            })
            .catch(error => {
                this.props.showAlert(error.toString(), 'negative');
                this.setState({ isSyncing: false });
            });
    };

    disconnect = () => {
        this.setState({ disconnecting: true });
        qbDisconnect({ user_id: this.props.accountUserStore.id })
            .then(res => {
                this.props.showToast('Disconnected from QuickBooks', 'positive');
                this.setState({
                    isOauthValid: false,
                    disconnecting: false,
                });
            })
            .catch(error => {
                this.props.showToast(error.message, 'negative');
                this.setState({
                    loading: false,
                    disconnecting: false,
                });
            });
    };

    isOauthValid = () => {
        return isOauthValid({ user_id: this.props.accountUserStore.id }).then(res => {
            this.setState({
                isOauthValid: res.result,
                loading: false,
                disconnecting: false,
            });
        });
    };

    render() {
        const { accountUserStore } = this.props;

        if (this.state.isOauthValid && !this.state.loading && !this.state.disconnecting) {
            return (
                <div className="applicationBody">
                    <div className="bodyHeader syncContainer">
                        <h2>Sync Minute7 with QuickBooks Online</h2>
                    </div>

                    <div className="settingsSection">
                        {!this.state.isSyncing ? (
                            <div
                                className="secondaryButton qbOnlineSyncContainer"
                                data-tip="Sync With QuickBooks Online"
                                onClick={this.sync}
                            >
                                <Icon className="qbOnlineSyncIcon" size={44} icon={ic_sync} />
                                <span>Click here to sync with QuickBooks Online</span>
                            </div>
                        ) : (
                            <div
                                className="qbOnlineSyncContainer"
                                data-tip="Minute7 is currently syncing with QuickBooks"
                            >
                                <Icon className="qbOnlineSyncingIcon" size={44} icon={ic_sync} />
                                <div className="qbOnlineSyncingLabel">
                                    <strong>
                                        Please wait while Minute7 syncs with QuickBooks.
                                    </strong>{' '}
                                    <span>
                                        This process may take several minutes depending on the
                                        number of customers, service items, and other entities in
                                        your QuickBooks account.
                                    </span>
                                </div>
                            </div>
                        )}

                        <div className="qbOnlineNextSteps">
                            <b>Next Step:</b> After syncing, click on your
                            <button
                                className="tertiaryButton"
                                onClick={() => this.props.history.push('/account_settings')}
                            >
                                &nbsp;Settings&nbsp;
                            </button>
                            tab and invite your employees and vendors to use Minute7.
                        </div>

                        <div className="qbOnlineMoreInfoContainer">
                            <button
                                className="tertiaryButton"
                                onClick={() =>
                                    this.setState({
                                        isSyncPreferencesHidden: !this.state
                                            .isSyncPreferencesHidden,
                                    })
                                }
                            >
                                <Icon
                                    icon={
                                        this.state.isSyncPreferencesHidden ? caretRight : caretDown
                                    }
                                    size={18}
                                    className="icon"
                                />
                                Sync Preferences
                            </button>

                            {!this.state.isSyncPreferencesHidden && (
                                <div className="qbOnlineMoreInfo">
                                    <div>
                                        You can control whether you want time and expense data to be
                                        sent to QuickBooks during the sync process. <br />
                                        This allows you to update all the other data in Minute7
                                        without sending time and expense data to QuickBooks.
                                    </div>

                                    <br />

                                    <div>
                                        {this.props.accountStore.sync_entries === 'No' ? (
                                            <div>
                                                Time and expense entries will not be synced to
                                                QuickBooks.
                                                <button
                                                    className="tertiaryButton"
                                                    onClick={this.props.toggleSyncing}
                                                >
                                                    &nbsp;Turn on syncing.
                                                </button>
                                                <br />
                                                Employees, customers, and other data in Minute7 will
                                                be updated.
                                                <br />
                                                <b>Note:</b> After the next sync, Minute7
                                                automatically returns to syncing time and expenses
                                                with QuickBooks.
                                            </div>
                                        ) : (
                                            <div>
                                                Time and expense entries will be synced to
                                                QuickBooks.
                                                <button
                                                    className="tertiaryButton"
                                                    onClick={this.props.toggleSyncing}
                                                >
                                                    &nbsp;Turn off syncing.
                                                </button>
                                                <br />
                                                Employees, customers, and other data in Minute7 will
                                                be updated.
                                                <button
                                                    className="tertiaryButton"
                                                    onClick={() =>
                                                        this.props.history.push('/company_settings')
                                                    }
                                                >
                                                    &nbsp;Edit sync delay.
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <button
                                className="tertiaryButton"
                                onClick={() =>
                                    this.setState({
                                        isAdministrativeOptionsHidden: !this.state
                                            .isAdministrativeOptionsHidden,
                                    })
                                }
                            >
                                <Icon
                                    icon={
                                        this.state.isAdministrativeOptionsHidden
                                            ? caretRight
                                            : caretDown
                                    }
                                    size={18}
                                    className="icon"
                                />
                                Administrative Options
                            </button>

                            {!this.state.isAdministrativeOptionsHidden && (
                                <div className="qbOnlineMoreInfo">
                                    If you would like to switch to QuickBooks Desktop Edition,
                                    please
                                    <button
                                        className="tertiaryButton"
                                        onClick={() => this.props.history.push('contact_us')}
                                    >
                                        &nbsp;<b>contact support.</b>
                                    </button>
                                    <br />
                                    If you would like to disconnect your Minute7 account from
                                    QuickBooks Online,
                                    <button className="tertiaryButton" onClick={this.disconnect}>
                                        &nbsp;<b>click here.</b>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        } else if (!this.state.loading && !this.state.disconnecting) {
            return (
                <div className="applicationBody">
                    <script
                        type="text/javascript"
                        src="https://appcenter.intuit.com/Content/IA/intuit.ipp.anywhere.js"
                    />
                    <div className="bodyHeader">
                        <h2>Connect Minute7 to QuickBooks Online account</h2>
                    </div>
                    <div className="qbConnectContainer">
                        <h2 className="qbConnectHeading">Get Started</h2>
                        <div className="qbConnectExplanation">
                            Click the button below and authorize Minute7 to connect to QuickBooks
                            Online
                        </div>
                        <img
                            style={{
                                cursor: 'pointer',
                            }}
                            src="/qb_connect.png"
                            alt="Connect To QuickBooks Online"
                            onClick={() => this.qbConnect({ user_id: accountUserStore.id })}
                        />
                    </div>
                </div>
            );
        } else if (this.state.disconnecting) {
            return (
                <div className="applicationBody syncLoadingContainer">
                    <DetailsLoading standalone description="Disconnecting From QuickBooks Online" />
                </div>
            );
        } else {
            return (
                <div className="applicationBody syncLoadingContainer">
                    <DetailsLoading standalone description="Checking QuickBooks Connection" />
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        accountStore: state.accountStore,
        accountUserStore: state.accountUserStore,
    };
}

const mapDispatchToProps = {
    showAlert,
    showToast,
    setAccountStore,
    requestBusinessResources,
    toggleSyncing,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OnlineSync);
