import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Icon from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import { CSVLink } from 'react-csv';
import '../../../css/ConfirmationModal.css';

try {
    Modal.setAppElement('#root');
} catch {}

export default class DownloadModal extends Component {
    render() {
        const { isOpen, onRequestClose, data, filename, onConfirm, title, message } = this.props;

        return (
            <Modal
                closeTimeoutMS={400}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel="Download Modal"
                shouldCloseOnOverlayClick={true}
                className="modalContainer"
            >
                <div>
                    <div className="modalHeader modalHeaderWithoutTitle">
                        <div className="modalOptions">
                            <Icon
                                className="modalIcon"
                                size={24}
                                icon={closeIcon}
                                onClick={onRequestClose}
                            />
                        </div>
                    </div>
                    <div className="modalContent">
                        <div className="confirmationTitle">{title}</div>
                        {message && <div className="confirmationMessage">{message}</div>}
                        <div className="modalActions">
                            <button className="secondaryButton m-1" onClick={onRequestClose}>
                                Cancel
                            </button>
                            <CSVLink
                                onClick={onConfirm}
                                className="primaryButton m-1"
                                data={data}
                                filename={filename}
                            >
                                Download CSV
                            </CSVLink>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

DownloadModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    DownloadActionLabel: PropTypes.string,
    downloadActionIsDestructive: PropTypes.bool,
    data: PropTypes.any,
    filename: PropTypes.string,
};

DownloadModal.defaultProps = {
    DownloadActionLabel: 'Delete',
    downloadActionIsDestructive: true,
};
