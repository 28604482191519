export const PATTERNS = {
    name: /^(?=.{1,50}$)[a-zA-Z]+(?:[-.'\s][a-zA-Z]+)*$/,
    zip: /^[a-zA-Z0-9-]*$/,
    address: /^([a-zA-z0-9/\\''(),-\s]{2,100})$/,
    email: /^(?!\d+@)\w+([-+.']\w+)*@(?!\d+\.)\w+([-.]\w+)*\.\w+([-.]\w+)*$/i,
    stateName: /^(?=.{1,50}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/,
    cityName: /^(?=.{1,50}$)[a-zA-Z]+(?:[-\s][a-zA-Z]+)*$/,
    phoneNumber: /^[+]?[\d]?[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
    date: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/,
    encodeURIChars: /[!'()*~]/g,
    ccNumber: /^\d{4}([ -]?)\d{4}\1\d{4}\1\d{4}$/g,
    ccSecurity: /^[0-9]{3-4}$/g,
    durationFormat: /(^((\d?){4})?(:[0-5]?|:[0-5]?(\d)?|[.](\d?){2})?$)/,
    companyName: /^\w([-&.,'\w]|\s[-&.,'\w]){3,100}$/,
    nameFillter: /(?=.{1,50}$)[a-zA-Z]+(?:[-.'\s][a-zA-Z]+)*/g,
    companyNameFillter: /\w([-&.,'\w]|\s[-&.,'\w]){3,100}/g,
    stateNameFillter: /(?=.{1,50}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*/g,
    cityNameFillter: /(?=.{1,50}$)[a-zA-Z]+(?:[-\s][a-zA-Z]+)*/g,
    zipFillter: /[a-zA-Z0-9-]*/g,
    addressFillter: /([a-zA-z0-9/\\''(),-\s]{2,100})/g,
    emailFillter: /(?!\d+@)\w+([-+.']\w+)*@(?!\d+\.)\w+([-.]\w+)*\.\w+([-.]\w+)*/g,
    doubleSpace: /\s{2,}/,
    singleSpace: /\s{1,}/,
    startEndSpace: /(^\s+)|(\s+$)/,
};
