import React from 'react';

const HowDoPermissionsWork = () => (
    <div className="card helpPageContainer">
        <div className="helpPage">
            <div className="sectionQuestion">How do permissions work?</div>
            <div className="sectionAnswer">
                You can control each user's access by setting permissions. Minute7 allows many
                levels of access, so that you can finely tune who can see what within your company.
                <br /> <br />
                By default, when you create a new employee user account by clicking on her name on
                the Account page or by starting from a blank user account, the new user account will
                only be able to view and edit her own time and expenses. You can optionally give new
                and existing users permission to manage your account, manage others' time, and
                manage others' expenses by toggling the permissions levels that Minute7 offers.
                <br /> <br />
                <b>Manage the Minute7 Account</b>
                <ul>
                    <li>
                        <b>Manage Account</b> <br />
                        The highest level of access, limited to the account creator (manager). Only
                        the manager can edit company account settings, change billing information,
                        or sync with QuickBooks.
                    </li>
                    <li>
                        <b>Manage Users</b> <br />
                        Allows the creation and management of user accounts, settings and
                        permissions. A user with the Manage Users permission can also view, edit,
                        and approve everyone's time and expense entries and manage permissions.
                    </li>
                    <li>
                        <b>Export Time and Expenses</b> <br />
                        Permits the user to sync company data with QuickBooks.
                    </li>
                    <li>
                        <b>Create and View Reports</b> <br />
                        Permits the user to create and view reports in Minute7 showing all users'
                        time and expense entries.
                    </li>
                    <li>
                        <b>Edit Payroll</b>
                        Permits the user to assign payroll items to time entries for QuickBooks
                        employees who use time data to create paychecks. If a user has permission to
                        Edit Payroll, she can add and alter payroll items for all employees and
                        vendors which she has permission to edit. Read more about using payroll in
                        Minute7.
                    </li>
                </ul>
                <br />
                <b>Managing Time Entries</b>
                <ul>
                    <li>
                        <b>View Time Entries for Others</b> <br />A user can view time entries for{' '}
                        <b>only him or herself</b> (the default when you create a new user),{' '}
                        <b>all employees and vendors</b>, or <b>certain employees and vendors</b>.
                        <br />
                        If a user is set to view time for certain employees and vendors, a list of
                        the current active QuickBooks employees and vendors will appear. Check the
                        box next to "view" for each employee and vendor that the Minute7 user in
                        question should be able to see time entries for.
                    </li>
                    <li>
                        <b>Edit Time Entries for Others</b> <br />A user can edit time entries for{' '}
                        <b>only him or herself</b> (the default when you create a new user),{' '}
                        <b>all employees and vendors</b>, <b>or certain employees and vendors</b>.
                        If a user is set to edit time for certain employees and vendors, a list of
                        the current active QuickBooks employees and vendors will appear.{' '}
                        <b>Check the box next to "edit"</b> for each employee and vendor that the
                        Minute7 user in question should be able to edit time entries for.
                    </li>
                    <li>
                        <b>Approve Time Entries for Others</b> <br />A user can approve time entries
                        for <b>no one</b> (the default when you create a new user),{' '}
                        <b>only him or herself</b>, <b>all employees and vendors</b>, or{' '}
                        <b>certain employees and vendors</b>. If a user is set to approve time for
                        certain employees and vendors, a list of the current active QuickBooks
                        employees and vendors will appear. <b>Check the box next to "approve"</b>{' '}
                        for each employee and vendor that the Minute7 user in question should be
                        able to approve time entries for.
                    </li>
                </ul>
                <br />
                <b>Managing Expense Entries</b>
                <ul>
                    <li>
                        <b>View Expense Entries for Others</b> <br />A user can view expense entries
                        for <b>only him or herself</b> (the default when you create a new user),{' '}
                        <b>all vendors</b>, or <b>certain vendors</b>. If a user is set to view
                        expenses for certain vendors, a list of the current active QuickBooks
                        vendors will appear. <b>Check the box next to "view"</b> for each vendor
                        that the Minute7 user in question should be able to see expense entries for.
                    </li>
                    <li>
                        <b>Edit Expense Entries For Others</b> <br />A user can edit expense entries
                        for <b>only him or herself</b> (the default when you create a new user),{' '}
                        <b>all vendors</b>, or <b>certain vendors</b>. If a user is set to edit
                        expenses for certain vendors, a list of the current active QuickBooks
                        vendors will appear. <b>Check the box next to "edit"</b> for each vendor
                        that the Minute7 user in question should be able to edit expense entries
                        for.
                    </li>
                    <li>
                        <b>Approve Expense Entries for Others</b> <br />A user can approve expense
                        entries for <b>no one</b> (the default when you create a new user),{' '}
                        <b>only him or herself</b>, <b>all vendors</b>, or <b>certain vendors</b>.
                        If a user is set to approve expenses for certain vendors, a list of the
                        current active QuickBooks vendors will appear.{' '}
                        <b>Check the box next to "approve"</b> for each vendor that the Minute7 user
                        in question should be able to approve expense entries for.
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

export default HowDoPermissionsWork;
