import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';

export const getAccountUser = (paramsObject = {}) => {
    return getData(paramsObject, 'accountuser/view').then(res => res.AccountUser);
};

export const passwordReset = (paramsObject = {}) => {
    return getData(paramsObject, 'accountuser/password_reset').then(res => res);
};

export const sendMessage = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'accountuser/send_message', paramsObject).then(res => res);
};

export const deleteAccountUser = (paramsObject = {}) => {
    return getData(paramsObject, 'accountuser/delete').then(res => res);
};

export const updateAccountUserSettings = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'accountuser/update', paramsObject).then(
        res => res.AccountUser
    );
};

export const createAccountUser = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'accountuser/create', paramsObject).then(
        res => res.AccountUser
    );
};

export const sendWelcomeEmail = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'accountuser/send_welcome_email', paramsObject).then(
        res => res
    );
};

export const sendResetUserEmail = (paramsObject = {}) => {
    return getData(paramsObject, 'accountuser/reset_email').then(res => res);
};
