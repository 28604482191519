import { useRef, useEffect } from 'react';

export function useTimeout(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(
        () => {
            savedCallback.current = callback;
        },
        [callback]
    );

    // Set up the interval.
    useEffect(
        () => {
            function tick() {
                if (savedCallback.current) {
                    savedCallback.current();
                }
            }
            if (delay !== null) {
                let id = setTimeout(tick, delay);
                return () => clearTimeout(id);
            }
        },
        [delay]
    );
}
