import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Iframe from 'react-iframe';
import { getQWC } from '../../requests/SyncRequests.js';
import '../../css/Sync.css';

const SetupInstructions = ({ history, accountUserStore }) => (
    <div>
        <h3 className="cardHeading">Get Started With Minute7 Time and Expense Tracking</h3>
        <div className="cardContainer cardContent settingsSection">
            <div className="syncDesktopList">
                <div className="syncDesktopListItem">
                    <div className="syncDesktopListNumberContainer">1</div>
                    <div>
                        <a
                            className="tertiaryButton"
                            href="https://intuit.box.com/shared/static/l2tztaei6qozxv8znbb0vpas01johpjj.zip"
                        >
                            Download the QuickBooks Web Connector
                        </a>
                        <div className="note">
                            <a
                                href="https://developer.intuit.com/docs/0200_quickbooks_desktop/0100_essentials/quickbooks_web_connector"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Intuit’s QuickBooks Web Connector
                            </a>{' '}
                            allows Minute7 to sync data with QuickBooks.
                        </div>
                    </div>
                </div>
                <div className="syncDesktopListItem">
                    <div className="syncDesktopListNumberContainer">2</div>
                    <div>
                        <button className="tertiaryButton" onClick={() => getQWC({ user_id: accountUserStore.id })}>
                            Install your Minute7 Web Connector File
                        </button>
                        <div className="note">
                            When you open your Minute7 Web Connector File, the QuickBooks Web
                            Connector will walk you through the process of allowing Minute7
                            permission to sync with QuickBooks.
                        </div>
                    </div>
                </div>
                <div className="syncDesktopListItem">
                    <div className="syncDesktopListNumberContainer">3</div>
                    <div>
                        <button
                            className="tertiaryButton"
                            onClick={() => history.push('/account_settings')}
                        >
                            Start using QuickBooks Web Connector
                        </button>
                        <div className="note">
                            Open QuickBooks and the QuickBooks Web Connector, then click "Update
                            Selected" to sync Minute7 with QuickBooks. After syncing, go to your
                            Account to add employees and vendors.
                        </div>
                    </div>
                </div>
            </div>

            <p>
                Have 3 minutes? Watch this short tutorial video that walks you through the setup
                process.
            </p>

            <div className="syncIframeContainer">
                <Iframe
                    url="https://www.youtube.com/embed/n95s6_Ioezk"
                    display="initial"
                    position="relative"
                    className="syncIframe"
                    allowFullScreen
                />
            </div>
        </div>
    </div>
);

const SyncInstructions = () => (
    <div>
        <h3 className="cardHeading">Initiating Syncing</h3>

        <div className="cardContainer cardContent settingsSection">
            <p>You’ve already completed the one-time setup process, you’re ready to sync&hellip;</p>

            <ol className="numberedList">
                <li>
                    Open <strong>QuickBooks Web Connector</strong> (Start > Exchange Data with Web
                    Services)
                </li>
                <li>
                    <strong>Check the box</strong> next to <strong>Minute7</strong>
                </li>
                <li>
                    Click the <strong>"Update Selected"</strong> button
                </li>
            </ol>

            <p>
                If you want to sync Minute7 with QuickBooks on a new computer, or if you need to
                reinstall the QuickBooks Web Connector, you can follow the steps below for the
                one-time setup process.
            </p>
        </div>
    </div>
);

const DesktopSync = ({ history, lastSync, accountUserStore}) => {
    const diff = moment().diff(lastSync, 'months');
    const hasNotSynced = isNaN(diff) || diff >= 24;

    return (
        <div className="applicationBody">
            <div className="bodyHeader">
                <h2>Sync Minute7 With QuickBooks Desktop</h2>
            </div>
            {!hasNotSynced && <SyncInstructions history={history} />}
            <SetupInstructions history={history} accountUserStore={accountUserStore}/>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        lastSync: state.accountStore.last_sync,
        accountStore: state.accountStore,
        accountUserStore: state.accountUserStore,
    };
}

export default connect(
    mapStateToProps,
    {}
)(DesktopSync);
