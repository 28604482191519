import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';

export const getCanViewVendors = () => {
    return getData({}, 'vendors/view_can_view').then(res =>
        res.Vendors.map(({ Vendor }) => Vendor)
    );
};

export const getCanEditVendors = () => {
    return getData({}, 'vendors/view_can_edit').then(res =>
        res.Vendors.map(({ Vendor }) => Vendor)
    );
};


export const getAllVendors  = (paramsObject = {}) => {
    return getData(paramsObject, 'v1/vendors')
.then(({ Vendors, meta }) => {
    let vendorsList = Vendors.map(item =>{   return item.Vendor  });
    return {
        vendorsList,
        meta,
    };
});
};

export const createNewVendor = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'v1/vendors/', paramsObject).then(res => res.Vendor);
};

export const updateExistingVendor = (data, paramsObject = {}) => {
    return postRequestWithFormData(
        data,
        'v1/vendors/' + data['Vendor[account_id]'],
        paramsObject
    ).then(res => res.Vendor);
};

export const activateVendor = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/vendors/' + id + '/activate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.Employee;
};

export const deactivateVendor = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/vendors/' + id + '/deactivate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.Employee;
};