import React from 'react';

const EditSyncedEntries = () => (
    <div>
        <div className="sectionQuestion">
            Can I edit time entries that have already been synced?
        </div>
        <div className="sectionAnswer">
            Minute7 does not alter time entries in QuickBooks that already exist or have previously
            been synced. This means you can be sure that the data you saw and approved when you
            synced will not be changed by a subsequent sync. You can tell if time entries in your
            searches and timesheets have already been synced with QuickBooks by looking for the
            "Synced" icon that appears in the right-hand column on time entry options. This is the
            "Synced" label: <span class="tsStatus tsStatusSynced">Synced</span>
            <br /> <br />
            If an entry has that icon beside it, changing or deleting the entry will not affect your
            company's QuickBooks file.
            <br /> <br />
            Note that if you want to be able to prevent particular time entries from being synced so
            that you can edit them at a later date, you can edit your Account Settings to adjust the
            number of hours that Minute7 waits after a time entry has been added or edited before
            syncing the time entry with QuickBooks. Alternatively, you can flag particular entries
            as 'Disapproved' (click the flag icon next to the entry in your timesheet, and the entry
            will turn red). Disapproved entries are never synced. When you're ready to sync the
            entry, just Approve the entry and it will sync normally.
        </div>
    </div>
);

export default EditSyncedEntries;
