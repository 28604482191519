import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import Icon from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import className from 'classnames';
import moment from 'moment';
import style from '../../css/DeleteEntryModal.module.css';

// Our tests don't like this line
try {
    Modal.setAppElement('#root');
} catch {}

export default class DeleteEntryModal extends PureComponent {
    renderTimeEntryDetails = () => {
        const entry = this.props.entry;
        const startOfTheWeek = entry.start_of_the_week

        if ('durations' in entry) {
            return (
                <div>
                    <div className={className(className, style.weekContainer)}>
                        {Object.keys(entry.durations).map((key, index) => {
                            if (entry.durations[key] !== '0:00') {
                                return (
                                    <div key={key} className={className(className, style.dayLabel)}>
                                        {moment(startOfTheWeek).add(index, 'days').format('ddd D')}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>

                    <div className={className(className, style.weekContainer)}>
                        {Object.keys(entry.durations).map(key => {
                            if (entry.durations[key] !== '0:00') {
                                return (
                                    <div key={key} className={className(className, style.day)}>
                                        {entry.durations[key] || "0:00"}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Duration:</div>
                        <div className={className(className, style.input)}>{entry.duration}</div>
                    </div>
                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Date:</div>
                        <div className={className(className, style.input)}>{entry.date}</div>
                    </div>
                </div>
            );
        }
    };

    createEntryDescription = () => {
        const { entry } = this.props;

        if (!entry) {
            return null;
        }

        if (this.props.objectName === 'TimeEntry') {
            const employee = entry.Employee;
            const employeeName = employee ? employee.name : '';

            return (
                <div className={className(className, style.deleteEntryModal)}>
                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Employee:</div>
                        <div className={className(className, style.input)}>{employeeName}</div>
                    </div>

                    {this.renderTimeEntryDetails()}

                    <div className={className(className, style.descriptionContainer)}>
                        <div className={className(className, style.descriptionLabel)}>
                            Description:
                        </div>
                        <div className={className(className, style.description)}>
                            {entry.description}
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.objectName === 'ExpenseEntry') {
            const vendor = entry.Vendor;
            const vendorName = vendor ? vendor.name : '';
            return (
                <div className={className(className, style.deleteEntryModal)}>
                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Vendor:</div>
                        <div className={className(className, style.input)}>{vendorName}</div>
                    </div>

                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Date:</div>
                        <div className={className(className, style.input)}>{entry.date}</div>
                    </div>

                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Amount:</div>
                        <div className={className(className, style.input)}>{entry.amount}</div>
                    </div>

                    <div className={className(className, style.descriptionContainer)}>
                        <div className={className(className, style.descriptionLabel)}>
                            Description:
                        </div>
                        <div className={className(className, style.description)}>
                            {entry.description}
                        </div>
                    </div>
                </div>
            );
        }
    };

    render() {
        const { requestClose } = this.props;

        return (
            <Modal
                closeTimeoutMS={400}
                isOpen={this.props.isDeleteEntryModalOpen}
                onRequestClose={this.props.requestClose}
                contentLabel="Delete Entry"
                className="modalContainer deleteModal"
                shouldCloseOnOverlayClick={true}
            >
                <div className="modalHeader">
                    <h2>Delete Entry?</h2>
                    <div className="modalOptions">
                        <Icon
                            className="modalIcon"
                            size={24}
                            icon={closeIcon}
                            onClick={requestClose}
                        />
                    </div>
                </div>
                <div className="modalContent">
                    {this.createEntryDescription()}

                    <div className="modalActions">
                        <button className="secondaryButton" onClick={this.props.requestClose}>
                            Cancel
                        </button>
                        <button
                            className="destructiveButton"
                            onClick={() => {
                                this.props.deleteEntry();
                                this.props.requestClose();
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}
