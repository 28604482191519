export const setAllItems = payload => ({
    type: 'SET_ALL_ITEM',
    payload,
});

export const addItem = (item, callback) => ({
    type: 'ADD_ITEM',
    item,
    callback,
});

export const addItemList = () => ({
    type: 'ADD_ITEM_LIST',
});

export const addItemListError = () => ({
    type: 'ADD_ITEM_LIST_ERROR',
});

export const addItemSuccess = item => ({
    type: 'ADD_ITEM_SUCCESS',
    item,
});

export const addItemError = error => ({
    type: 'ADD_ITEM_ERROR',
    error,
});

export const updateItem = (item, callback) => ({
    type: 'UPDATE_ITEM',
    item,
    callback,
});

export const updateItemSuccess = item => ({
    type: 'UPDATE_ITEM_SUCCESS',
    item,
});

export const updateItemError = error => ({
    type: 'UPDATE_ITEM_ERROR',
    error,
});

export const hideItem = itemId => ({
    type: 'DEACTIVATE_ITEM',
    itemId,
});

export const unhideItem = itemId => ({
    type: 'ACTIVATE_ITEM',
    itemId,
});
