import _ from 'lodash';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { isValidEmail } from '../../utils/CommonFunctions.js';
import scrollTo from '../../utils/scrollTo';
import { showAlert, showToast, login } from '../../actions/Actions.js';
import DefaultLogin from './components/DefaultLogin.js';
import MobileLogin from './components/MobileLogin.js';
import MaintenancePlaceholder from './components/MaintenancePlaceholder.js'

import '../../css/login/Login.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            redirectToReferrer: false,
        };
    }

    componentDidMount() {
        // permission check error will be saved in the location state
        // the code below will display them
        const { state = {} } = this.props.location;
        const { error } = state;
        if (error) {
            this.props.showAlert(error, 'negative');
        }

        document.addEventListener('keydown', this.handleEnterDown, false);

        scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEnterDown, false);
    }

    componentWillReceiveProps(props) {
        // resources have now loaded, proceed with login
        if (props.isLoggedIn && props.resourcesLoaded && !this.props.resourcesLoaded) {
            this.setState({
                redirectToReferrer: true,
            });
        }
    }

    handleEnterDown = event => {
        if (event.key === 'Enter') {
            this.login();
        }
    };

    login = () => {
        if (isValidEmail(this.state.email)) {
            this.props.login({ email: this.state.email, password: this.state.password });
        } else {
            this.props.showToast('Please enter a valid email and password', 'warning');
        }
    };

    passwordOnChange = newPassword => {
        this.setState({
            password: newPassword.target.value,
            invalidLogin: false,
        });
    };

    emailOnChange = newEmail => {
        this.setState({
            email: newEmail.target.value,
            invalidLogin: false,
        });
    };

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        const { redirectToReferrer } = this.state;
        const { isLoggedIn, isLoggingIn, resourcesLoaded } = this.props;

        const showLoading = isLoggingIn || (isLoggedIn && !resourcesLoaded);

        if (window.location.search === "?bypass_server_maintenance=true") {
            return <MaintenancePlaceholder />
        }

        if (redirectToReferrer === true) {
            return <Redirect to={from || '/'} />;
        }

        if (isLoggedIn === true) {
            return <Redirect to={from || '/'} />;
        }

        if (isMobile) {
            return (
                <MobileLogin
                    emailOnChange={this.emailOnChange}
                    passwordOnChange={this.passwordOnChange}
                    login={this.login}
                    loading={showLoading}
                    history={this.props.history}
                />
            );
        } else {
            return (
                <DefaultLogin
                    emailOnChange={this.emailOnChange}
                    passwordOnChange={this.passwordOnChange}
                    login={this.login}
                    loading={showLoading}
                    history={this.props.history}
                />
            );
        }
    }
}

function mapStateToProps({ businessResourcesStore, appStore }) {
    return {
        isLoggedIn: !_.isNil(appStore.session_id),
        isLoggingIn: appStore.isLoggingIn,
        resourcesLoaded: businessResourcesStore.resourcesLoaded,
    };
}

const mapDispatchToProps = {
    showAlert,
    showToast,
    login,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
