import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { arrow_left as chevronLeft } from 'react-icons-kit/ikons/arrow_left';
import { arrow_right as chevronRight } from 'react-icons-kit/ikons/arrow_right';

import style from '../../css/Pagination.module.css';

const Pagination = ({ total, currentPage, perPage, onPreviousPressed, onNextPressed }) => {
    const totalPages = Math.max(Math.ceil(total / perPage), 1);

    let range;
    if (total === 0) {
        range = '0 – 0';
    } else {
        const lowerBound = currentPage * perPage + 1;
        let upperBound = (currentPage + 1) * perPage;
        if (upperBound > total) {
            upperBound = total;
        }
        range = lowerBound + ' – ' + upperBound;
    }

    return (
        <div className={style.pagingContainer}>
            <button
                className={`secondaryButton ${style.pagingButton}`}
                onClick={onPreviousPressed}
                disabled={total === 0 || currentPage - 1 < 0}
            >
                <Icon size={22} icon={chevronLeft} />
            </button>
            <div className={style.pageAndTotalResultsContainer}>
                <div>
                    {range} of {total} total
                </div>
            </div>
            <button
                className={`secondaryButton ${style.pagingButton}`}
                onClick={onNextPressed}
                disabled={total === 0 || currentPage + 1 >= totalPages}
            >
                <Icon size={22} icon={chevronRight} />
            </button>
        </div>
    );
};

Pagination.propTypes = {
    total: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    onPreviousPressed: PropTypes.func.isRequired,
    onNextPressed: PropTypes.func.isRequired,
};

export default React.memo(Pagination);
