import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import ReactQuoraPixel from 'react-quora-pixel';
import TagManager from 'react-gtm-module'
import { PersistGate } from 'redux-persist/integration/react';
import registerServiceWorker from './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ErrorBoundary } from './bugsnag';
import Router from './Router.js';
import { appStore, persistor } from './stores/store.js';
import { setSession } from './utils/HelperFunctions.js';
import { SiteLoading } from './views/components/SiteLoading.js';
require('dotenv').config();

ReactQuoraPixel.init('fe30c15943704d3ebd155ef709b001cc');
ReactQuoraPixel.track('ViewContent');

const tagManagerArgs = {
    gtmId: 'GTM-MH2SG9W',
};
TagManager.initialize(tagManagerArgs);

function loading() {
    return <SiteLoading />;
}

function hydrateSession() {
    const currentStore = appStore.getState().appStore;
    setSession(currentStore.session_id, currentStore.session_name);
}

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={appStore}>
            <PersistGate onBeforeLift={hydrateSession} persistor={persistor} loading={loading()}>
                <Router />
            </PersistGate>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);

registerServiceWorker();
