import React from 'react';

const AccountManagerPermissions = () => (
    <div>
        <div className="sectionQuestion">Can I change the permissions of the account manager?</div>
        <div className="sectionAnswer">
            No. The account manager cannot have his or her permissions altered, and will always have
            access to all aspects of the account.
        </div>
    </div>
);

export default AccountManagerPermissions;
