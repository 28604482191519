import _ from 'lodash';
import moment from 'moment';

import { PATTERNS } from '../config/Patterns.js';
import {
    searchableFields,
    billableFilter as BILLABLE_FILTER_OPTIONS,
    taxableFilter as TAXABLE_FILTER_OPTIONS,
    exportedFilter as EXPORTED_FILTER_OPTIONS,
    expenseTypeFilter as EXPENSE_TYPE_FILTER_OPTIONS,
    approvedFilter as APPROVED_FILTER_OPTIONS,
    attachmentFilter as ATTACHMENT_FILTER_OPTIONS,
} from '../config/Fields.js';
import { startOfWeek } from './DateUtils';

/**
 * Given a history object, parse all ?name=value variables from url into an object
 * structured like { name: value } or false if the request is malformed/missing
 * @param {object} history
 * @return {object | boolean} decodedVariables or false
 */

export const parseUrlVariables = history => {
    let request = null;
    if ('location' in history && 'search' in history.location) {
        request = decodeURIComponent(history.location.search).replace('?', '');
    } else {
        return false;
    }
    const decodedVariables = {};
    request = request.split('&');
    request.forEach(nameValuePair => {
        nameValuePair = nameValuePair.split('=');
        if (nameValuePair[0] !== '') {
            decodedVariables[nameValuePair[0]] = nameValuePair[1];
        }
    });
    return decodedVariables;
};

export const figureExpenseTransactionType = qbAccounts => {
    qbAccounts = qbAccounts.filter(
        account =>
            account.account_type !== 'Other Current Liability' &&
            account.account_type !== 'OtherCurrentLiability'
    );
    return qbAccounts;
};

export const createProperPageTitle = currentRoute => {
    // This allows for stuff like /help/web_connector to be displayed as Web Connector
    currentRoute = currentRoute.split('/');
    currentRoute = currentRoute[currentRoute.length - 1];
    currentRoute = currentRoute.replace('/', '');
    currentRoute = currentRoute.split('_');
    for (let i = 0; i < currentRoute.length; i++) {
        currentRoute[i] =
            currentRoute[i].charAt(0).toUpperCase() +
            currentRoute[i].substring(1, currentRoute[i].length);
    }
    return currentRoute.join(' ');
};

export const figureNameOrDescription = item => {
    if (!item) {
        return null;
    }

    if ('name' in item) {
        return item.name;
    } else if ('description' in item) {
        return item.description;
    }
};

export const figureAccountUserDisplayNameWithId = (id, accountUsers) => {
    const accountUser = accountUsers.find(user => user.id === id);

    if (accountUser) {
        return [accountUser.first_name, accountUser.last_name].join(' ');
    } else {
        return false;
    }
};

export const maskCCNumber = number => {
    if (!number || typeof number === 'undefined') {
        return '';
    }

    let maskedNumber = '';
    number = number.split('');
    for (let i = 0; i < number.length; i++) {
        if (i !== 0 && i % 4 === 0) {
            maskedNumber += ' ';
        }

        if (i < 12) {
            maskedNumber += '*';
        } else {
            maskedNumber += number[i];
        }
    }
    return maskedNumber;
};

export const maskSecurityCode = securityCode => {
    if (typeof securityCode === 'undefined') {
        return '';
    }

    if (securityCode.length < 3) {
        return '';
    } else {
        return '***';
    }
};

export const figureEmployeesNotAssociatedWithAnAccountUser = (employees, accountUsers) => {
    let employeesWithoutAssociation = [];
    for (let employeeIndex in employees) {
        let employee = employees[employeeIndex];
        let addFlag = true;
        for (let accountUserIndex in accountUsers) {
            let accountUser = accountUsers[accountUserIndex];
            if (employee.id.includes('_v')) {
                addFlag = false;
                break;
            }
            if (employee.id === accountUser.employee_id) {
                addFlag = false;
                break;
            }
        }
        if (addFlag) {
            employeesWithoutAssociation.push(employee);
        }
    }
    return employeesWithoutAssociation;
};

export const createSelectedListWithIds = (arrayOfIds, list) => {
    const selectedList = [];
    arrayOfIds.forEach(id => {
        const entry = list.find(e => id === e.id);
        if (entry) {
            selectedList.push(entry);
        }
    });
    return selectedList;
};

export const spliceSelectedListFromList = (arrayOfIds, list) => {
    return list.filter(({ id }) => !arrayOfIds.includes(id));
};

export const figureVendorsNotAssociatedWithAnAccountUser = (vendors, accountUsers) => {
    let vendorsWithoutAssociation = [];
    for (let vendorIndex in vendors) {
        let vendor = vendors[vendorIndex];
        let addFlag = true;
        for (let accountUserIndex in accountUsers) {
            let accountUser = accountUsers[accountUserIndex];
            if (vendor.id === accountUser.expense_vendor_id) {
                addFlag = false;
                break;
            }
        }
        if (addFlag) {
            vendorsWithoutAssociation.push(vendor);
        }
    }
    return vendorsWithoutAssociation;
};

export const constructAccountUserList = (users, currentAccountUser) => {
    const accountUserList = [];
    // User has access to other users
    if (users && users.length > 0) {
        for (let index in users) {
            let currentAccountUser = users[index];
            accountUserList.push({
                id: currentAccountUser.id,
                name: [currentAccountUser.first_name, currentAccountUser.last_name].join(' '),
            });
        }
    }
    // User only has access to self
    else {
        accountUserList.push({
            id: currentAccountUser.id,
            name: [currentAccountUser.first_name, currentAccountUser.last_name].join(' '),
        });
    }
    return accountUserList;
};

/**
 * Add spaces before capital letters
 * @param {string} string string to add spaces to
 */

export const addSpaces = string => {
    string = string.split('');
    let tempString = string;
    for (let i = string.length - 1; i > 0; i--) {
        if (string[i] === string[i].toUpperCase()) {
            tempString.splice(i, 0, ' ');
        }
    }
    return tempString.join('');
};

export const firstLetterToUpperCase = string => {
    string = string.split('');
    string[0] = string[0].toUpperCase();
    return string.join('');
};

export const replaceQuickbooks = string => {
    string = string.replace('Quickbooks', 'QuickBooks');
    string = string.replace('QuickBooksAccount', 'QuickBooks Account');
    return string;
};

export const paddingZero = str => {
    str = String(str);
    if (str.length === 1) {
        return '0' + str;
    } else {
        return str;
    }
};

export const renderFileName = (filename, fileType, maxLength = 30) => {
    let fileExtension = fileType.split('/');
    fileExtension = fileExtension[fileExtension.length - 1];
    filename = filename.split('.');
    let displayName = '';
    // Length - 1 to ensure we leave out file extension if present
    for (let i = 0; i < filename.length - 1; i++) {
        displayName += filename[i];
        if (displayName.length > maxLength) {
            displayName = displayName.slice(0, maxLength);
            displayName += '...' + fileExtension;
            return displayName;
        }
    }
    return displayName + '.' + fileExtension;
};

export const getAllLabel = objectName => {
    switch (objectName) {
        case 'Customer':
            return 'All Customers';
        case 'QbAccount':
            return 'All Accounts';
        case 'InventoryItem':
            return 'All Services';
        case 'Employee':
            return 'All Employees';
        case 'Vendor':
            return 'All Vendors';
        case 'QbClass':
            return 'All Classes';
        case 'ExpenseGrouping':
            return 'All Bills';
        case 'PayrollItem':
            return 'All Payroll Items';
        default:
            return 'No Options';
    }
};

export const properDisplayName = objectName => {
    switch (objectName) {
        case 'Customer':
            return 'Customer';
        case 'QbAccount':
            return 'Account';
        case 'InventoryItem':
            return 'Service';
        case 'Employee':
            return 'Employee';
        case 'Vendor':
            return 'Vendor';
        case 'QbClass':
            return 'Class';
        case 'ExpenseGrouping':
            return 'Bill';
        case 'PayrollItem':
            return 'Payroll Item';
        case 'AccountUser':
            return 'Account User';
        case 'PermissionsGroup':
            return 'Permissions Group';
        default:
            return 'Option';
    }
};

export const properDisplayNameFromIdName = idName => {
    switch (idName) {
        case 'customer_id':
            return 'Customer';
        case 'qb_account_id':
            return 'Account';
        case 'inventory_item_id':
            return 'Job';
        case 'employee_id':
            return 'Employee';
        case 'vendor_id':
            return 'Vendor';
        case 'qb_class_id':
            return 'Class';
        case 'expense_grouping_id':
            return 'Bill';
        case 'payroll_item_id':
            return 'Payroll Item';
        case 'account_user_id':
            return 'Account User';
        default:
            return 'No Option';
    }
};

export const isValidPhone = inputPhone => {
    if (inputPhone.length === 0 || !PATTERNS.phoneNumber.test(inputPhone)) {
        return false;
    }
    return true;
};

export const isValidEmail = inputEmail => {
    if (inputEmail.length > 50 || inputEmail.length < 4 || !PATTERNS.email.test(inputEmail)) {
        return false;
    }
    return true;
};

export const isPasswordsValid = (inputPassword, inputConfirmPassword) => {
    if (
        inputPassword.length < 6 ||
        inputConfirmPassword.length < 6 ||
        inputPassword !== inputConfirmPassword
    ) {
        return false;
    }
    return true;
};

export const isNameValid = name => {
    if (!PATTERNS.name.test(name) || PATTERNS.singleSpace.test(name)) {
        return false;
    }
    return true;
};

export const isCityValid = name => {
    if (!PATTERNS.cityName.test(name) || PATTERNS.doubleSpace.test(name)) {
        return false;
    }
    return true;
};

export const isStateValid = name => {
    if (!PATTERNS.stateName.test(name) || PATTERNS.doubleSpace.test(name)) {
        return false;
    }
    return true;
};

export const isCompanyNameValid = nameOfcompany => {
    if (
        nameOfcompany.length < 3 ||
        nameOfcompany.length > 100 ||
        !PATTERNS.companyName.test(nameOfcompany) ||
        PATTERNS.doubleSpace.test(nameOfcompany)
    ) {
        return false;
    }
    return true;
};

export const isEmployeeNameValid = nameOfEmployee => {
    if (
        nameOfEmployee.length < 3 ||
        nameOfEmployee.length > 100 ||
        PATTERNS.doubleSpace.test(nameOfEmployee)
    ) {
        return false;
    }
    return true;
};

export const isInventoryItemNameValid = nameOfEmployee => {
    if (
        nameOfEmployee.length < 3 ||
        nameOfEmployee.length > 100 ||
        PATTERNS.doubleSpace.test(nameOfEmployee)
    ) {
        return false;
    }
    return true;
};
export const zipCodeValid = zipCode => {
    if (zipCode.length < 5 || zipCode.length > 10 || !PATTERNS.zip.test(zipCode)) {
        return false;
    }
    return true;
};

export const isAddressValid = address => {
    if (!PATTERNS.address.test(address)) {
        return false;
    }
    return true;
};

export const companyNameError = val => {
    const wrrongEntry = val.replace(PATTERNS.companyNameFillter, '') + ' not allowed. ';
    if (val.length > 100 || val.length < 3) {
        return 'Invalid length. ';
    } else if (PATTERNS.doubleSpace.test(val)) {
        return 'Double space not allowed ';
    } else if (PATTERNS.startEndSpace.test(val)) {
        return 'Space at start and end not allowed. ';
    } else {
        return wrrongEntry;
    }
};

export const nameErrorMessage = val => {
    const wrrongEntry = val.replace(PATTERNS.nameFillter, '') + ' not allowed. ';
    if (val.length > 50 || val.length < 1) {
        return 'Invalid length. ';
    } else if (PATTERNS.singleSpace.test(val)) {
        return 'Space not allowed. ';
    } else {
        return wrrongEntry;
    }
};

export const stateErrorMessage = val => {
    const wrrongEntry = val.replace(PATTERNS.stateNameFillter, '') + ' not allowed. ';
    if (val.length > 50 || val.length < 1) {
        return 'Invalid length. ';
    } else if (PATTERNS.doubleSpace.test(val)) {
        return 'Double space not allowed. ';
    } else if (PATTERNS.startEndSpace.test(val)) {
        return 'Space at start and end not allowed. ';
    } else {
        return wrrongEntry;
    }
};

export const cityErrorMessage = val => {
    const wrrongEntry = val.replace(PATTERNS.cityNameFillter, '') + ' not allowed. ';
    if (val.length > 50 || val.length < 1) {
        return 'Invalid length. ';
    } else if (PATTERNS.doubleSpace.test(val)) {
        return 'Double space not allowed. ';
    } else if (PATTERNS.startEndSpace.test(val)) {
        return 'Space at start and end not allowed. ';
    } else {
        return wrrongEntry;
    }
};

export const emailErrorMessage = val => {
    debugger;
    const wrrongEntry = val.replace(PATTERNS.emailFillter, '') + ' is invalid email pattern. ';
    if (val.length < 4 || val.length > 50) {
        return 'Invalid length. ';
    } else if (PATTERNS.startEndSpace.test(val)) {
        return 'Space at start and end not allowed. ';
    } else {
        return wrrongEntry;
    }
};

export const zipErrorMessage = val => {
    const wrrongEntry = val.replace(PATTERNS.zipFillter, '') + ' not allowed. ';
    if (val.length < 5 || val.length > 10) {
        return 'Invalid length.  ';
    } else if (PATTERNS.singleSpace.test(val)) {
        return 'Space not allowed. ';
    } else {
        return wrrongEntry;
    }
};

export const addressErrorMessage = val => {
    const wrrongEntry = val.replace(PATTERNS.addressFillter, '') + ' not allowed. ';
    if (val.length < 2 || val.length > 100) {
        return 'Invalid length. ';
    } else {
        return wrrongEntry;
    }
};

export const sumWeeklyEntryDurations = entries => {
    const weekTotal = {
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0,
        Sun: 0,
        sum: 0,
    };

    // coalesced weekly time entries
    _.forOwn(entries, entry => {
        _.forOwn(entry.durations, (duration, day) => {
            const floatDuration = duration === '' ? 0 : parseFloat(duration);
            weekTotal[day] += floatDuration;
            weekTotal.sum += floatDuration;
        });
    });

    return weekTotal;
};

export const sumEntriesColumnByWeekday = (entries, column) => {
    const weekTotal = {
        Mon: 0,
        Tue: 0,
        Wed: 0,
        Thu: 0,
        Fri: 0,
        Sat: 0,
        Sun: 0,
        sum: 0,
    };

    if (!entries || entries.length === 0) {
        return weekTotal;
    }

    entries.forEach(entry => {
        const day = moment(entry.date).format('ddd');
        const amount = entry[column] === '' ? 0 : parseFloat(entry[column]);
        weekTotal[day] += amount;
        weekTotal.sum += amount;
    });

    return weekTotal;
};

export const trimDescription = (description, maxLength = 125) => {
    if (description === null) {
        return '';
    } else if (description.length > maxLength) {
        return description.substring(0, maxLength) + '...';
    } else {
        return description;
    }
};

export const getParentObjectName = objectName => {
    switch (objectName) {
        case 'Customer':
            return 'customers';
        case 'QbAccount':
            return 'qbAccounts';
        case 'InventoryItem':
            return 'inventoryItems';
        case 'Employee':
            return 'employees';
        case 'Vendor':
            return 'vendors';
        case 'QbClass':
            return 'qbClasses';
        case 'ExpenseGrouping':
            return 'expenseGroupings';
        case 'PayrollItem':
            return 'payrollItems';
        case 'AccountUser':
            return 'accountUsers';
        case 'PermissionsGroup':
            return 'permissionsGroups';
        default:
            return 'Option';
    }
};

/*
    Group entries by the timestamp for the start of the week they were created in
 */

export function groupByWeek(entries, weekOffset = 0) {
    return _.groupBy(entries, entry => {
        return moment(startOfWeek(entry.date, weekOffset)).valueOf();
    });
}

export const searchEntries = (searchText, entries) => {
    searchText = searchText.toLowerCase();

    return entries.filter(entry => {
        const matches = searchableFields.some(key => {
            const value = _.get(entry, key, null);

            return (
                value !== null &&
                value
                    .toString()
                    .toLowerCase()
                    .includes(searchText)
            );
        });

        return matches;
    });
};

export const searchTextFilterMatchesEntry = ({ searchText }, entry) => {
    return (
        _.isNil(searchText) || searchText === '' || searchEntries(searchText, [entry]).length === 1
    );
};

export const dateFilterMatchesEntry = ({ startDate, endDate }, entry) => {
    const entryDate = moment(entry.date, 'Y-MM-DD');
    return entryDate.isSameOrAfter(startDate, 'day') && entryDate.isSameOrBefore(endDate, 'day');
};

export const employeeFilterMatchesEntry = ({ searchEmployeeIds }, entry) => {
    return (
        searchEmployeeIds.length === 0 ||
        searchEmployeeIds.includes(
            entry.type === 'Vendor' ? `${entry.employee_id}_v` : entry.employee_id
        )
    );
};

export const vendorFilterMatchesEntry = ({ searchVendorIds }, entry) => {
    return searchVendorIds.length === 0 || searchVendorIds.includes(entry.vendor_id);
};

export const customerFilterMatchesEntry = ({ searchCustomerIds }, entry) => {
    return searchCustomerIds.length === 0 || searchCustomerIds.includes(entry.customer_id);
};

export const inventoryItemFilterMatchesEntry = ({ searchInventoryItemIds }, entry) => {
    return (
        searchInventoryItemIds.length === 0 ||
        searchInventoryItemIds.includes(entry.inventory_item_id)
    );
};

export const qbClassFilterMatchesEntry = ({ searchQbClassIds }, entry) => {
    return searchQbClassIds.length === 0 || searchQbClassIds.includes(entry.qb_class_id);
};

export const qbAccountFilterMatchesEntry = ({ searchQbAccountIds }, entry) => {
    return searchQbAccountIds.length === 0 || searchQbAccountIds.includes(entry.qb_account_id);
};

export const payrollItemFilterMatchesEntry = ({ searchPayrollItemIds }, entry) => {
    return (
        searchPayrollItemIds.length === 0 || searchPayrollItemIds.includes(entry.payroll_item_id)
    );
};

export const expenseGroupingFilterMatchesEntry = ({ searchExpenseGroupingIds }, entry) => {
    return (
        searchExpenseGroupingIds.length === 0 ||
        searchExpenseGroupingIds.includes(entry.expense_grouping_id)
    );
};

export const billableFilterMatchesEntry = ({ billableFilter }, entry) => {
    const mapping = {
        billable: 'Yes',
        not_billable: 'No',
    };
    return billableFilter === 'all' || entry.billable === mapping[billableFilter];
};

export const approvedFilterMatchesEntry = ({ approvedFilter }, entry) => {
    const mapping = {
        approved: 'Yes',
        pending: 'No',
    };
    return approvedFilter === 'all' || entry.approved === mapping[approvedFilter];
};

export const exportedFilterMatchesEntry = ({ exportedFilter }, entry) => {
    const mapping = {
        synced: 'Yes',
        unsynced: 'No',
    };
    return exportedFilter === 'all' || entry.exported === mapping[exportedFilter];
};

export const expenseTypeFilterMatchesEntry = ({ expenseTypeFilter }, entry) => {
    const mapping = {
        reimbursable: 'Yes',
        credit_card: 'No',
    };
    return expenseTypeFilter === 'all' || entry.expenseType === mapping[expenseTypeFilter];
};

export const attachmentFilterMatchesEntry = ({ attachmentFilter }, entry) => {
    const mapping = {
        with_attachment: 'Yes',
        without_attachment: 'No',
    };
    return attachmentFilter === 'all' || entry.has_attachment === mapping[attachmentFilter];
};

export const dateRangeEncompassingEntries = entries => {
    if (entries.length === 0) {
        return [moment().startOf('month'), moment().endOf('month')];
    }

    const moments = entries.map(e => moment(e.date));
    const earliest = moment.min(moments).clone();
    const latest = moment.max(moments).clone();
    return [earliest.startOf('month'), latest.endOf('month')];
};

const filterToTestMapping = {
    searchText: searchTextFilterMatchesEntry,
    date: dateFilterMatchesEntry,
    employee: employeeFilterMatchesEntry,
    vendor: vendorFilterMatchesEntry,
    customer: customerFilterMatchesEntry,
    inventoryItem: inventoryItemFilterMatchesEntry,
    qbClass: qbClassFilterMatchesEntry,
    qbAccount: qbAccountFilterMatchesEntry,
    payrollItem: payrollItemFilterMatchesEntry,
    billable: billableFilterMatchesEntry,
    approved: approvedFilterMatchesEntry,
    exported: exportedFilterMatchesEntry,
    expenseTypeFilter: expenseTypeFilterMatchesEntry,
    attachment: attachmentFilterMatchesEntry,
    bill: expenseGroupingFilterMatchesEntry,
};

export const filterStateMatchesEntry = (state, entry) => {
    const { activeFilters } = state;

    const tests = _.values(_.pick(filterToTestMapping, ['searchText', ...activeFilters]));
    return tests.every(test => test(state, entry));
};

export const newFilterStateMatchingEntries = (state, entries) => {
    const { activeFilters } = state;
    const tests = _.pick(filterToTestMapping, ['searchText', ...activeFilters]);
    const newFilterState = { ...state };

    _.forEach(tests, (test, filter) => {
        const success = entries.every(entry => test(state, entry));
        if (success) {
            return;
        }

        if (filter === 'searchText') {
            newFilterState.searchText = '';
        }

        if (filter === 'date') {
            const [startDate, endDate] = dateRangeEncompassingEntries(entries);
            newFilterState.startDate = startDate.toDate();
            newFilterState.endDate = endDate.toDate();
        }

        if (filter === 'employee') {
            const ids = entries.map(e =>
                e.type === 'Vendor' ? `${e.employee_id}_v` : e.employee_id
            );
            newFilterState.searchEmployeeIds = _.uniq([
                ...newFilterState.searchEmployeeIds,
                ...ids,
            ]);
        }

        if (filter === 'vendor') {
            const ids = entries.map(e => e.vendor_id);
            newFilterState.searchVendorIds = _.uniq([...newFilterState.searchVendorIds, ...ids]);
        }

        if (filter === 'customer') {
            const ids = entries.map(e => e.customer_id);
            newFilterState.searchCustomerIds = _.uniq([
                ...newFilterState.searchCustomerIds,
                ...ids,
            ]);
        }

        if (filter === 'inventoryItem') {
            const ids = entries.map(e => e.inventory_item_id);
            newFilterState.searchInventoryItemIds = _.uniq([
                ...newFilterState.searchInventoryItemIds,
                ...ids,
            ]);
        }

        if (filter === 'qbClass') {
            const ids = entries.map(e => e.qb_class_id);
            newFilterState.searchQbClassIds = _.uniq([...newFilterState.searchQbClassIds, ...ids]);
        }

        if (filter === 'qbAccount') {
            const ids = entries.map(e => e.qb_account_id);
            newFilterState.searchQbAccountIds = _.uniq([
                ...newFilterState.searchQbAccountIds,
                ...ids,
            ]);
        }

        if (filter === 'payrollItem') {
            const ids = entries.map(e => e.payroll_item_id);
            newFilterState.searchPayrollItemIds = _.uniq([
                ...newFilterState.searchPayrollItemIds,
                ...ids,
            ]);
        }

        if (filter === 'bill') {
            const ids = entries.map(e => e.expense_grouping_id);
            newFilterState.searchExpenseGroupingIds = _.uniq([
                ...newFilterState.searchExpenseGroupingIds,
                ...ids,
            ]);
        }

        if (['billable', 'approved', 'exported', 'attachment', 'expenseType'].includes(filter)) {
            // simpler filters like the above just get reset to all
            newFilterState[`${filter}Filter`] = 'all';
        }
    });

    return newFilterState;
};

export const filterEntries = (searchText, filterObject, entries) => {
    // Restrict filters by our search field value first
    let searchedList = searchEntries(searchText, entries);
    let tempFilteredList = [];
    for (let i = 0; i < searchedList.length; i++) {
        const entry = searchedList[i];
        let filterFlag = true;
        // check start and end date if restrictByDate
        if (filterObject.restrictByDate) {
            let startDateMs = filterObject.startDate.getTime();
            let endDateMs = filterObject.endDate.getTime();
            let currentEntryDateMs = entry.date.split('-');
            currentEntryDateMs = new Date(
                currentEntryDateMs[0],
                currentEntryDateMs[1] - 1,
                currentEntryDateMs[2]
            ).getTime();
            if (!(currentEntryDateMs >= startDateMs && currentEntryDateMs <= endDateMs)) {
                filterFlag = false;
            }
        }
        // Check all dropdown list filters
        for (let key in filterObject) {
            if (
                filterObject[key] instanceof Object &&
                Object.keys(filterObject[key]).length > 0 &&
                key
                    .toString()
                    .toLowerCase()
                    .includes('id')
            ) {
                let doesEntryHaveAnId = false;
                for (let id in filterObject[key]) {
                    if (id === entry[key]) {
                        doesEntryHaveAnId = true;
                    }
                }
                if (!doesEntryHaveAnId) {
                    filterFlag = false;
                    break;
                }
            }
        }
        // check billable, synced, approved, attachment dropdown lists
        if (filterObject.approvedFilter === APPROVED_FILTER_OPTIONS[1].id) {
            if (entry.approved === 'No') {
                filterFlag = false;
            }
        } else if (filterObject.approvedFilter === APPROVED_FILTER_OPTIONS[2].id) {
            if (entry.approved === 'Yes') {
                filterFlag = false;
            }
        }

        if (filterObject.billableFilter === BILLABLE_FILTER_OPTIONS[1].id) {
            if (entry.billable === 'No') {
                filterFlag = false;
            }
        } else if (filterObject.billableFilter === BILLABLE_FILTER_OPTIONS[2].id) {
            if (entry.billable === 'Yes') {
                filterFlag = false;
            }
        }

        if (filterObject.taxableFilter === TAXABLE_FILTER_OPTIONS[1].id) {
            if (entry.taxable === 'No') {
                filterFlag = false;
            }
        } else if (filterObject.taxableFilter === TAXABLE_FILTER_OPTIONS[2].id) {
            if (entry.taxable === 'Yes') {
                filterFlag = false;
            }
        }

        if (filterObject.exportedFilter === EXPORTED_FILTER_OPTIONS[1].id) {
            if (entry.exported === 'No') {
                filterFlag = false;
            }
        } else if (filterObject.exportedFilter === EXPORTED_FILTER_OPTIONS[2].id) {
            if (entry.exported === 'Yes') {
                filterFlag = false;
            }
        }

        if (filterObject.expenseTypeFilter === EXPENSE_TYPE_FILTER_OPTIONS[1].id) {
            if (entry.expenseType === 'No') {
                filterFlag = false;
            }
        } else if (filterObject.expenseTypeFilter === EXPENSE_TYPE_FILTER_OPTIONS[2].id) {
            if (entry.expenseType === 'Yes') {
                filterFlag = false;
            }
        }

        if ('attachmentFilter' in filterObject) {
            let areAttachmentsAvailable = 'attachments' in entry;
            // Only with attachments
            if (filterObject.attachmentFilter === ATTACHMENT_FILTER_OPTIONS[1].id) {
                if (!areAttachmentsAvailable || entry.attachments.length === 0) {
                    filterFlag = false;
                }
            }
            // Only without attachments
            else if (filterObject.attachmentFilter === ATTACHMENT_FILTER_OPTIONS[2].id) {
                if (areAttachmentsAvailable && entry.attachments.length > 0) {
                    filterFlag = false;
                }
            }
        }

        if (filterFlag) {
            tempFilteredList.push(searchedList[i]);
        }
    }
    return tempFilteredList;
};

export const figureNameOrDescriptionFromKeyWithId = (key, id, businessResourcesStore) => {
    switch (key) {
        case 'inventory_item_id':
            return figureNameOrDescriptionFromId('InventoryItem', id, businessResourcesStore);
        case 'payroll_item_id':
            return figureNameOrDescriptionFromId('PayrollItem', id, businessResourcesStore);
        case 'qb_class_id':
            return figureNameOrDescriptionFromId('QbClass', id, businessResourcesStore);
        case 'qb_account_id':
            return figureNameOrDescriptionFromId('QbAccount', id, businessResourcesStore);
        case 'customer_id':
            return figureNameOrDescriptionFromId('Customer', id, businessResourcesStore);
        case 'vendor_id':
            return figureNameOrDescriptionFromId('Vendor', id, businessResourcesStore);
        case 'expense_grouping_id':
            return figureNameOrDescriptionFromId('ExpenseGrouping', id, businessResourcesStore);
        case 'employee_id':
            let employee = figureNameOrDescriptionFromId('Employee', id, businessResourcesStore);
            return employee
                ? employee
                : figureNameOrDescriptionFromId('Employee', id + '_v', businessResourcesStore);
        default:
            return false;
    }
};

export const getEntityWithId = (objectName, id, businessResourcesStore) => {
    const objectParent = getParentObjectName(objectName);
    let entry;

    if (businessResourcesStore[`${objectParent}ById`]) {
        // support normalized state shapes like:
        // customers: [id1, id2, id3],
        // customersById: { id1: {...}, id2: {...}, ... }
        entry = businessResourcesStore[`${objectParent}ById`][id];
    } else {
        entry = businessResourcesStore[objectParent].find(entry => entry.id === id);
    }

    if (entry) {
        return entry;
    }

    return null;
};

export const figureNameOrDescriptionFromId = (objectName, id, businessResourcesStore) => {
    const objectParent = getParentObjectName(objectName);
    let entry;
    if (businessResourcesStore[`${objectParent}ById`]) {
        // support normalized state shapes like:
        // customers: [id1, id2, id3],
        // customersById: { id1: {...}, id2: {...}, ... }
        entry = businessResourcesStore[`${objectParent}ById`][id];
    } else {
        entry = businessResourcesStore[objectParent].find(entry => entry.id === id);
    }

    if (entry) {
        if ('name' in entry) {
            return entry.name;
        } else if ('description' in entry) {
            return entry.description;
        }

        return id;
    }

    return false;
};

export const doesEntryHaveAttributes = (entry, attributes) => {
    if (attributes instanceof Array) {
        attributes.forEach(attribute => {
            if (!entry[attribute] || entry[attribute] === '0' || entry[attribute] === null) {
                return false;
            }
        });
        return true;
    } else {
        return entry[attributes] && entry[attributes] !== '0' && entry[attributes] !== null;
    }
};

/**
 * Creates a file object from object in s3 bucket
 * @param {string} url - url to file stored on S3
 * @param {string} keyPrefix - prefix to find our key in
 */
export const makeCancelable = promise => {
    let hasCanceled_ = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
        );
    });
    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export const employeeWithId = (employees, id) => {
    return employees.find(employee => employee.id === id);
};

/**
 * From an object, return a comma separated list of the keys for all values not false
 * @param {Object} object
 */
export const objectKeysToCommaSeparatedString = obj => {
    return _.compact(_.map(obj, (v, k) => (v !== false ? k : null))).join(',');
};

export const isNumeric = str => {
    return /^\d+$/.test(str);
};

export const figureUnknownEntities = (entities, qbType) => {
    if (qbType !== 'Online') {
        return [];
    }

    let unknownEntities = [];
    for (let entityName in entities) {
        let entity = entities[entityName];
        if (entity && entity.id !== null && !isNumeric(entity.list_id)) {
            unknownEntities.push(entityName);
        }
    }
    return unknownEntities;
};

export const openPopUpWindow = (title, windowWidth, windowHeight, url) => {
    var width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screenX;
    var height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screenY;
    var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    var left = width / 2 - 800 / 2 + dualScreenLeft;
    var top = height / 2 - 700 / 2 + dualScreenTop;
    return window.open(
        url,
        title,
        `toolbar=0, scrollbars=no, width=${windowWidth}, height=${windowHeight}, top=${top}, left=${left}`
    );
};

export const updateEntryInArray = (arr, updated) =>
    arr.map(entry => {
        if (entry.id !== updated.id) {
            return entry;
        }
        return {
            ...entry,
            ...updated,
        };
    });

export const milesToKilometers = miles => {
    return miles / 0.62137;
};

export const kilometersToMiles = kilometers => {
    return kilometers / milesToKilometers(1);
};
