import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { persistor } from '../stores/store';
import { setSession } from '../utils/HelperFunctions.js';
import {
    getAccount,
    toggleSyncing as apiToggleSyncing,
    toggleAutoSyncing as apiToggleAutoSyncing,
    reactivateAccount as apiReactivateAccount,
    deactivateAccount as apiDeactivateAccount,
    impersonateUser as apiImpersonateUser,
    exitImpersonateUser as apiExitImpersonateUser,
} from '../requests/AccountRequests';
import { getAccountUser } from '../requests/AccountUserRequests';
import { logout, login } from '../requests/LoginRequests';
import * as uiActions from '../actions/Actions';
import * as resourceActions from '../actions/BusinessResourcesActions';
import * as accountActions from '../actions/AccountActions';
import * as accountUserActions from '../actions/AccountUserActions';
import history from '../history';

const selectUserEmail = ({ appStore: { email } }) => email;
const selectIsLoggedIn = ({ appStore: { session_id } }) => !_.isNil(session_id);
const selectAccountId = ({ accountStore: { id } }) => id;

export function* flushStore() {
    yield call(persistor.flush);
}

export function* setSessionFromLoginInfo({
    payload: {
        logininfo: { session },
    },
}) {
    yield call(setSession, session.session_id, session.session_name);
}

export function* setSessionFromAction({ payload: session }) {
    yield call(setSession, session.session_id, session.session_name);
}

export function* loginFromSession({ sessionId, sessionName, email }) {
    yield call(setSession, sessionId, sessionName);
    yield put(uiActions.refreshAccount());
    const action = yield take(['REFRESH_ACCOUNT_SUCCESS', 'REFRESH_ACCOUNT_ERROR']);
    if (action.type === 'REFRESH_ACCOUNT_SUCCESS') {
        yield put(uiActions.loginFromSessionSuccess());
    } else {
        yield put(uiActions.loginFromSessionError(action.error));
    }
}

export function* refreshAccount() {
    try {
        const email = yield select(selectUserEmail);
        const [accountUser, account] = yield all([
            call(getAccountUser, { email }),
            call(getAccount),
        ]);

        yield put(accountActions.setAccountStore(account));
        yield put(accountUserActions.setAccountUserStore(accountUser));

        yield put(resourceActions.requestBusinessResources());
        yield put(uiActions.refreshAccountSuccess());
    } catch (error) {
        yield put(uiActions.refreshAccountError(error));
        yield put(
            uiActions.showToast(
                'Couldn’t load data from our servers. Try reloading the page.',
                'negative'
            )
        );
    }
}

export function* refreshAccountUsers() {
    try {
        const email = yield select(selectUserEmail);
        const [accountUser, account] = yield all([
            call(getAccountUser, { email }),
            call(getAccount),
        ]);

        yield put(accountActions.setAccountStore(account));
        yield put(accountUserActions.setAccountUserStore(accountUser));

        yield put(uiActions.refreshAccountUsersSuccess());
    } catch (error) {
        yield put(uiActions.refreshAccountUsersError(error));
        yield put(
            uiActions.showToast(
                'Couldn’t load data from our servers. Try reloading the page.',
                'negative'
            )
        );
    }
}

export function* logoutFlow({ sessionParams }) {
    try {
        yield call(logout, sessionParams);
        yield put(uiActions.logoutSuccess());
    } catch (error) {
        yield put(uiActions.logoutError(error));
    }
}

export function* loginFlow({ email, password }) {
    var result = '';
    try {
        const isLoggedIn = yield select(selectIsLoggedIn);

        if (isLoggedIn) {
            yield put(uiActions.logout());
            yield take(['LOGOUT_SUCCESS', 'LOGOUT_ERROR']);
        }

        result = yield call(login, email, password);
        yield put(uiActions.loginSuccess({ ...result, email }));
        yield put(accountUserActions.setAccountUserStore(result.logininfo.AccountUser));
        yield put(accountActions.setAccountStore(result.logininfo.Account));
        yield put(resourceActions.requestBusinessResources());
    } catch (error) {
        if (error.response.status === 403) {
            yield put(
                uiActions.showAlert(
                    'Account Locked. Reset your password to unlock your account.',
                    'negative'
                )
            );
            history.push('/password_reset');
        } else {
            yield put(uiActions.showToast('Invalid email or password.', 'warning'));
        }
        yield put(uiActions.loginError(error));
    }
}

export function* toggleSyncing() {
    try {
        const id = yield select(selectAccountId);
        const params = {
            account_id: id,
        };
        const result = yield call(apiToggleSyncing, params);
        yield put(accountActions.toggleSyncingSuccess(result.sync_entries === 'Yes'));
    } catch (error) {
        yield put(accountActions.toggleSyncingError(error));
        yield put(uiActions.showToast('Couldn’t save sync settings', 'negative'));
    }
}

export function* toggleAutoSyncing() {
    try {
        const id = yield select(selectAccountId);
        const params = {
            account_id: id,
        };
        const result = yield call(apiToggleAutoSyncing, params);
        yield put(accountActions.toggleAutoSyncingSuccess(result.auto_sync === 'Yes'));
    } catch (error) {
        yield put(accountActions.toggleAutoSyncingError(error));
        yield put(uiActions.showToast('Couldn’t save sync settings', 'negative'));
    }
}

export function* reactivateAccount() {
    try {
        const result = yield call(apiReactivateAccount);
        yield put(accountActions.reactivateAccountSuccess(result));
        yield put(
            uiActions.showAlert(
                () => (
                    <>
                        Welcome back!
                        <br />
                        Next, make sure your{' '}
                        <Link to="/account_settings">billing information is up to date</Link> so you
                        can continue to use Minute7.
                    </>
                ),
                'positive'
            )
        );
    } catch (error) {
        yield put(accountActions.reactivateAccountError(error));
        yield put(uiActions.showToast('Couldn’t reactivate account', 'negative'));
    }
}

export function* deactivateAccount() {
    try {
        const result = yield call(apiDeactivateAccount);
        yield put(accountActions.deactivateAccountSuccess(result));
        yield put(
            uiActions.showAlert(
                () => (
                    <>
                        We’re sorry to see you go.
                        <br />
                        Please <Link to="/contact">tell us why you left</Link>, and come back soon!
                    </>
                ),
                'positive'
            )
        );
    } catch (error) {
        yield put(accountActions.deactivateAccountError(error));
        yield put(uiActions.showToast('Couldn’t deactivate account', 'negative'));
    }
}

export function* impersonateUser({ userId }) {
    try {
        yield call(apiImpersonateUser, userId);
        yield put(accountActions.impersonateStoreClear());
        yield (window.location.href = '/');
    } catch (error) {
        yield put(uiActions.showToast("Couldn't impersonate user", 'negative'));
    }
}

export function* exitImpersonateUser() {
    try {
        yield call(apiExitImpersonateUser);
        yield put(accountActions.impersonateStoreClear());
        yield window.location.reload();
    } catch (error) {
        yield put(uiActions.showToast("Couldn't impersonate user", 'negative'));
    }
}

export default function* root() {
    yield all([
        takeLatest(['LOGIN'], loginFlow),
        takeLatest(['LOGIN_SUCCESS'], setSessionFromLoginInfo),
        takeLatest(['LOGIN_FROM_SESSION'], loginFromSession),
        takeLatest(['REFRESH_ACCOUNT'], refreshAccount),
        takeLatest(
            ['REFRESH_ACCOUNT_USERS', 'UPDATE_PERMISSIONS_GROUP_SUCCESS'],
            refreshAccountUsers
        ),
        takeLatest(['SET_SESSION'], setSessionFromAction),
        takeLatest(['LOGOUT', 'RESOURCES_LOADED', 'IMPERSONATE_STORE_CLEAR'], flushStore),
        takeLatest(['LOGOUT'], logoutFlow),
        takeLatest(['ACCOUNT_TOGGLE_SYNCING'], toggleSyncing),
        takeLatest(['ACCOUNT_TOGGLE_AUTO_SYNCING'], toggleAutoSyncing),
        takeLatest(['REACTIVATE_ACCOUNT'], reactivateAccount),
        takeLatest(['DEACTIVATE_ACCOUNT'], deactivateAccount),
        takeLatest(['IMPERSONATE_USER'], impersonateUser),
        takeLatest(['EXIT_IMPERSONATE_USER'], exitImpersonateUser),
    ]);
}
