import { Component } from 'react';
import ReactGA from 'react-ga';

// initializing ReactGA causes tests to fail
try {
    ReactGA.initialize('UA-3517496-2');
} catch (error) {}

export default class GoogleAnalytics extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            ReactGA.pageview(this.props.location.pathname);
        }
    }

    static timeEntry = action => {
        ReactGA.event({
            category: 'Time Entry',
            action,
        });
    };

    static expenseEntry = action => {
        ReactGA.event({
            category: 'Expense Entry',
            action,
        });
    };

    static report = action => {
        ReactGA.event({
            category: 'Report',
            action,
        });
    };

    static login = () => {
        ReactGA.event({
            category: 'Login',
        });
    };

    static QBO = action => {
        ReactGA.event({
            category: 'QBO',
            action,
        });
    };

    static openId = action => {
        ReactGA.event({
            category: 'OpenID',
            action,
        });
    };

    static activityLog = action => {
        ReactGA.event({
            category: 'Activity Log',
            action,
        });
    };

    render() {
        return null;
    }
}
