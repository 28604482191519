import React from 'react';

const QuickBooksFile = () => (
    <div>
        <div className="sectionQuestion">What will Minute7 change in my QuickBooks file?</div>
        <div className="sectionAnswer">
            Minute7 gets information about your Customer, Employee, Service Item, Vendor, and Class
            lists directly from QuickBooks, and automatically makes your QuickBooks-entered customer
            and service type information available to your employees on the Minute7 secure web
            interface. However, when you sync Minute7 and QuickBooks, the only change Minute7 will
            make in your QuickBooks file is to add time entries you've created in Minute7 to your
            QuickBooks timesheets. Minute7 will not change any data stored in your QuickBooks file
            for Customers, Employees, Service Items, and Vendors, and also will not change any
            existing time entries. You can rest assured that your QuickBooks file and the integrity
            of your company data are safe with Minute7.
        </div>
    </div>
);

export default QuickBooksFile;
