import React from 'react';

const DefaultPayrollItem = () => (
    <div>
        <div className="sectionQuestion">
            How do I set the default payroll item that is used when an employee creates a time entry
            in Minute7?
        </div>
        <div className="sectionAnswer">
            From a Minute7 user's user preferences page, you can set default payroll items for
            QuickBooks employees who are set to use time data to create paychecks in QuickBooks
            <br /> <br />
            When you select a QuickBooks Employee or Vendor for the user you are editing, the
            available payroll options for the Employee or Vendor will appear below under the heading
            Payroll Settings. Note that your Minute7 account must have "Show Payroll" set to Yes,
            and you must have permission to "Edit Payroll" in order for the Payroll Settings to
            appear.
            <br /> <br />
            The available payroll settings will vary based on which QuickBooks Employee or Vendor is
            selected. Vendors cannot have payroll items assigned to time entries in QuickBooks, so
            no default payroll item can be set for Vendors. Similarly, employees who are not set to
            "Use time data for paychecks" in QuickBooks cannot have payroll items assigned to time
            entries.
            <br /> <br />
            For Employees who are set to "Use time data for paychecks" in QuickBooks, a default
            payroll item can be set. This is the payroll item that will be sent to QuickBooks with
            all time entries for the selected Employee unless a user with the Edit Payroll
            permission changes the selected payroll item for a specific time entry while entering
            time in the Minute7 timesheets.
        </div>
    </div>
);

export default DefaultPayrollItem;
