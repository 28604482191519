import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Input } from 'reactstrap';
import className from 'classnames';
import Icon from 'react-icons-kit';
import moment from 'moment';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import { sendMessage } from '../../requests/AccountUserRequests.js';
import style from '../../css/MessageModal.module.css';

// Our tests don't like this line
try {
    Modal.setAppElement('#root');
} catch {}

export default class MessageModal extends PureComponent {
    static propTypes = {
        fromEmail: PropTypes.string.isRequired,
        isMessageModalOpen: PropTypes.bool.isRequired,
        requestClose: PropTypes.func.isRequired,
        entry: PropTypes.object,
        objectName: PropTypes.oneOf(['TimeEntry', 'ExpenseEntry']),
        showToast: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            message: '',
            to: '',
        };
    }

    componentWillReceiveProps(props) {
        if (props.entry) {
            const idProp = 'uuid' in props.entry ? 'uuid' : 'id';
            if (this.props.entry && this.props.entry[idProp] === props.entry[idProp]) {
                return;
            }

            this.setState({
                to: _.get(props.entry, 'AccountUser.email', ''),
            });
        }
    }

    resetState = () => {
        this.setState({
            message: '',
            to: '',
        });
    };

    handleToChanged = value => {
        this.setState({ to: value.target.value });
    };

    handleMessageChanged = value => {
        this.setState({ message: value.target.value });
    };

    sendMessage() {
        const entryFields = [
            'id',
            'account_id',
            'account_user_id',
            'amount',
            'approved',
            'billable',
            'customer_id',
            'date',
            'dates',
            'description',
            'employee_id',
            'exported',
            'inventory_item_id',
            'payroll_item_id',
            'expense_grouping_id',
            'qb_class_id',
            'miles',
            'qb_account_id',
            'start_time',
            'end_time',
            'hours_off_duty',
            'duration',
            'durations',
            'grouped_time_ids',
            'vendor_id',
            'type',
        ];

        const entryData = _.pick(this.props.entry, entryFields);
        if (entryData.grouped_time_ids) {
            entryData.grouped_time_ids = _.flatten(Object.values(entryData.grouped_time_ids));
        }

        const data = {
            message: this.state.message,
            entry: JSON.stringify(entryData),
            to: this.state.to,
        };
        sendMessage(data)
            .then(() => {
                this.props.showToast('Message sent', 'positive');
                this.setState({ message: '' });
                this.props.requestClose();
            })
            .catch(() => this.props.showToast('Couldn’t send message', 'negative'));
    }

    renderTimeEntryDetails = () => {
        const entry = this.props.entry;
        // Week entry
        if ('durations' in entry) {
            const daysWithDuration = Object.keys(entry.durations).filter(
                key => entry.durations[key] !== '0:00' && entry.durations[key] !== ''
            );
            return (
                <div>
                    <div className={className(className, style.weekContainer)}>
                        {daysWithDuration.map(key => (
                            <div key={key} className={className(className, style.dayLabel)}>
                                {moment(entry.dates[key]).format('ddd D')}
                            </div>
                        ))}
                    </div>

                    <div className={className(className, style.weekContainer)}>
                        {daysWithDuration.map(key => (
                            <div key={key} className={className(className, style.day)}>
                                {entry.durations[key]}
                            </div>
                        ))}
                    </div>
                </div>
            );
            // Day Entry
        } else {
            return (
                <div>
                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Duration:</div>
                        <div className={className(className, style.input)}>{entry.duration}</div>
                    </div>
                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Date:</div>
                        <div className={className(className, style.input)}>{entry.date}</div>
                    </div>
                </div>
            );
        }
    };

    createEntryDescription = () => {
        const { entry } = this.props;

        if (!entry) {
            return null;
        }

        if (this.props.objectName === 'TimeEntry') {
            const employee = entry.Employee;
            const employeeName = employee ? employee.name : '';

            return (
                <div className={className(className, style.messageModal)}>
                    <div className={className(className, style.toFromContainer)}>
                        <div className={className(className, style.labelAndInput)}>
                            <div className={className(className, style.label)}>From:</div>
                            <div className={className(className, style.input)}>
                                {this.props.fromEmail}
                            </div>
                        </div>
                        <div className={className(className, style.labelAndInput)}>
                            <div className={className(className, style.label)}>To:</div>
                            <div className={className(className, style.input)}>
                                <Input value={this.state.to} onChange={this.handleToChanged} />
                            </div>
                        </div>
                    </div>

                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Employee:</div>
                        <div className={className(className, style.input)}>{employeeName}</div>
                    </div>

                    {this.renderTimeEntryDetails()}

                    <div className={className(className, style.descriptionContainer)}>
                        <div className={className(className, style.descriptionLabel)}>
                            Description:
                        </div>
                        <div className={className(className, style.description)}>
                            {entry.description}
                        </div>
                    </div>
                </div>
            );
        } else if (this.props.objectName === 'ExpenseEntry') {
            const vendor = entry.Vendor;
            const vendorName = vendor ? vendor.name : '';
            return (
                <div className={className(className, style.messageModal)}>
                    <div className={className(className, style.toFromContainer)}>
                        <div className={className(className, style.labelAndInput)}>
                            <div className={className(className, style.label)}>From:</div>
                            <div className={className(className, style.input)}>
                                {this.props.fromEmail}
                            </div>
                        </div>
                        <div className={className(className, style.labelAndInput)}>
                            <div className={className(className, style.label)}>To:</div>
                            <div className={className(className, style.input)}>
                                <Input value={this.state.to} onChange={this.handleToChanged} />
                            </div>
                        </div>
                    </div>

                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Vendor:</div>
                        <div className={className(className, style.input)}>{vendorName}</div>
                    </div>

                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Date:</div>
                        <div className={className(className, style.input)}>{entry.date}</div>
                    </div>

                    <div className={className(className, style.labelAndInput)}>
                        <div className={className(className, style.label)}>Amount:</div>
                        <div className={className(className, style.input)}>{entry.amount}</div>
                    </div>

                    <div className={className(className, style.descriptionContainer)}>
                        <div className={className(className, style.descriptionLabel)}>
                            Description:
                        </div>
                        <div className={className(className, style.description)}>
                            {entry.description}
                        </div>
                    </div>
                </div>
            );
        }
    };

    render() {
        return (
            <Modal
                closeTimeoutMS={400}
                isOpen={this.props.isMessageModalOpen}
                onRequestClose={this.props.requestClose}
                contentLabel="Send a Message"
                className="modalContainer messageModal"
                shouldCloseOnOverlayClick={true}
            >
                <div className="modalHeader">
                    <h2>Send a Message</h2>
                    <div className="modalOptions">
                        <Icon
                            className="modalIcon"
                            size={24}
                            icon={closeIcon}
                            onClick={this.props.requestClose}
                        />
                    </div>
                </div>
                <div className="modalContent">
                    {this.createEntryDescription()}
                    <Input
                        placeholder="Message"
                        type="textarea"
                        value={this.state.message}
                        onChange={this.handleMessageChanged}
                    />

                    <div className="modalActions">
                        <button className="secondaryButton" onClick={this.props.requestClose}>
                            Cancel
                        </button>
                        <button className="primaryButton" onClick={() => this.sendMessage()}>
                            Send Message
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}
