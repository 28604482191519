import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { isEmployeeNameValid, isValidEmail } from '../../../utils/CommonFunctions.js';
import { Row, Col } from 'react-bootstrap';
import { accountUserFields, employeeFields } from '../../../config/Fields';
import { showToast } from '../../../actions/Actions.js';
import { addEmployee } from '../../../actions/EmployeeActions';
import { selectEmployee } from '../../../actions/Actions.js';
import { addUser } from '../../../actions/AccountUserActions';
import * as accountSelectors from '../../../selectors/AccountSelectors';
import * as resourceSelectors from '../../../selectors/BusinessResourcesSelectors';
import * as expensesSelectors from '../../../selectors/ExpensesSelectors';
import 'react-phone-input-2/lib/style.css';
import Select from '../../components/Select.js';
import Icon from 'react-icons-kit';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';
import { informationCircled as info } from 'react-icons-kit/ionicons/informationCircled';
import { isValidPhoneNumber } from 'libphonenumber-js';
import ToggleSettingListV2 from '../../components/ToggleSettingListV2.js';
import ToggleOptionsSecondV from '../../components/ToggleOptionsSecondV';
import Spinner from '../../components/Spinner.js';
import ReactTooltip from 'react-tooltip';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';

class NewEmployeeForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState({
            selectedView: 'employee', make_employee_user: false, firstSearchFilter: '',
            secondSearchFilter: '',
            firstSearchFilterSecondList: '',
            secondSearchFilterSecondList: '',
        });
    }

    componentWillReceiveProps(props) {
        if (props.isSaving !== this.props.isSaving && !props.isSaving) {
            //this.props.history.goBack();
        }
    }

    defaultState = data => {
        const { payrollItems, accountId } = this.props;
        const payroll_item = [];
        return {
            payroll_bill_rates: new Map(),
            fNameInvalidMsg: '',
            mNameInvalidMsg: '',
            lNameInvalidMsg: '',
            emailInvalidMsg: '',
            userSettings: 'default',
            defaultUserCreation: true,
            account_id: accountId,
            make_employee_user: data.make_employee_user,
            currentView: data.selectedView,
            email: data.email ? data.email : '',
            name: data.name ? data.name : '',
            first_name: data.first_name ? data.first_name : '',
            middle_name: data.middle_name ? data.middle_name : '',
            last_name: data.last_name ? data.last_name : '',
            reference_number: data.reference_number ? data.reference_number : '',
            type: '',
            hidden: false,
            payroll_item_ids: payroll_item ? payroll_item : [],
            payroll_items: payrollItems,
            use_time_for_paychecks: 'UseTimeData',
            save_message: 'Save + Send Invite',
            labels: [
                { label: 'Default', value: 'default' },
                { label: 'Customized', value: 'custom' },
            ],
            employee_id: '0',
            expense_vendor_id: '0',
            phone: '',
            validation_msg: '',
            week_start_offset: 0,
            timesheet_grouping: 'week',
            time_entry_reminder_frequency: 'Never',
            time_entry_reminder_minimum_hours: 0,
            export_reminder_interval: 'Never',
            permission_group_id: '0',
            approve_others_expenses: 'No',
            approve_others_time: 'No',
            edit_others_expenses: 'No',
            edit_others_time: 'No',
            edit_own_expenses: 'No',
            edit_own_time: 'No',
            edit_payroll: 'No',
            export_time: 'No',
            manage_account: 'No',
            manage_users: 'No',
            manage_api: 'No',
            view_others_expenses: 'No',
            view_others_time: 'No',
            view_reports: 'No',
            clock_in_only: 'No',
            manage_payroll_payrates: 'No',
            manage_item_billrates: 'No',
            filter_view_employee_ids: [],
            filter_edit_employee_ids: [],
            filter_approve_employee_ids: [],
            filter_view_expense_vendor_ids: [],
            filter_edit_expense_vendor_ids: [],
            filter_approve_expense_vendor_ids: [],
            filter_customer_list: 'No',
            filter_customer_ids: [],
            filter_inventory_item_list: 'No',
            filter_inventory_item_ids: [],
            filter_qb_account_list: 'No',
            filter_qb_account_ids: [],
            filter_qb_class_list: 'No',
            filter_qb_class_ids: [],
            approve_others_expenses_override_group: 'No',
            approve_others_time_override_group: 'No',
            edit_others_expenses_override_group: 'No',
            edit_others_time_override_group: 'No',
            edit_own_expenses_override_group: 'No',
            edit_own_time_override_group: 'No',
            edit_payroll_override_group: 'No',
            manage_payroll_payrates_override_group: 'No',
            manage_item_billrates_override_group: 'No',
            export_time_override_group: 'No',
            filter_approve_employee_ids_override_group: 'No',
            filter_approve_expense_vendor_ids_override_group: 'No',
            filter_customer_ids_override_group: 'No',
            filter_customer_list_override_group: 'No',
            filter_edit_employee_ids_override_group: 'No',
            filter_edit_expense_vendor_ids_override_group: 'No',
            filter_inventory_item_ids_override_group: 'No',
            filter_inventory_item_list_override_group: 'No',
            filter_qb_account_ids_override_group: 'No',
            filter_qb_account_list_override_group: 'No',
            filter_qb_class_ids_override_group: 'No',
            filter_qb_class_list_override_group: 'No',
            filter_view_employee_ids_override_group: 'No',
            filter_view_expense_vendor_ids_override_group: 'No',
            manage_account_override_group: 'No',
            manage_expenses_override_group: 'No',
            manage_time_override_group: 'No',
            manage_users_override_group: 'No',
            manage_api_override_group: 'No',
            restrictions_override_group: 'No',
            view_others_expenses_override_group: 'No',
            view_others_time_override_group: 'No',
            view_reports_override_group: 'No',
            send_invite_sms: false,
            spinner_loader: false,
        };
    };

    constructEmployee = () => {
        const data = {};
        const dataFields = this.state.make_employee_user ? accountUserFields : employeeFields;
        dataFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else if (field === 'email') {
                    data[field] = this.state[field].trim().toLowerCase();
                } else if (field === 'phone') {
                    const { phone } = this.state;
                    data[field] = phone !== '' ? '+' + phone : phone;
                }
                else if (field === 'payroll_bill_rates') {
                    const { payroll_bill_rates } = this.state;
                    data[field] = JSON.stringify(Object.fromEntries(payroll_bill_rates));
                }
                 else {
                    data[field] = this.state[field];
                }
                if (field.includes('payroll_item_ids')) {
                    data[field] = this.state[field].join(',');
                }
            }
        });
        return data;
    };

    checkRequiredFields = () => {
        if (!isValidEmail(this.state.email.trim())) {
            this.props.showToast('Please enter a valid email address for this user', 'warning');
            return false;
        }
        if (this.state.last_name === '' || this.state.first_name === '') {
            this.props.showToast('Please fill out both First Name and Last Name', 'warning');
            return false;
        }
        if (this.state.make_employee_user) {
            if (!this.isPhoneNumberValid()) {
                this.props.showToast('Please enter a valid Phone Number', 'warning');
                return false;
            }
        }
        return true;
    };

    saveEmployee = event => {
        event.preventDefault();
        if (
            this.state.emailInvalidMsg !== '' ||
            this.state.fNameInvalidMsg !== '' ||
            this.state.mNameInvalidMsg !== '' ||
            this.state.lNameInvalidMsg !== ''
        ) {
            this.props.showToast('Please Fix the form error before submit', 'warning');
        } else {
            if (!this.checkRequiredFields()) {
                return;
            }
            this.setState({ spinner_loader: true });
            const employeeData = this.constructEmployee();
            this.props.addEmployee(employeeData, newEmployee => {
                if (newEmployee === false) {
                    this.setState({ spinner_loader: false });
                    return;
                }
                if (this.state.defaultUserCreation) {
                    this.setState({
                        employee_id: newEmployee.id.toString(),
                        make_employee_user: true,
                    });
                    const userData = this.constructEmployee();
                    if (this.state.userSettings === 'default') {
                        this.props.addUser(userData, newUser => {
                            if (newUser === false) {
                                this.setState({ spinner_loader: false });
                                return;
                            }
                            this.props.showToast(
                                `New Employee ${newUser.first_name} ${newUser.last_name
                                } and its user created`,
                                'positive'
                            );
                            this.setState({
                                spinner_loader: false,
                                employee_id: '0',
                                email: '',
                                name: '',
                                first_name: '',
                                middle_name: '',
                                last_name: '',
                                reference_number: '',
                                payroll_item_ids: []
                            });
                        });
                    } else {
                        this.props.selectEmployee(newEmployee.id.toString());
                        this.props.history.push('/create_account_user');
                        this.props.showToast(
                            `New Employee ${newEmployee.name} created`,
                            'positive'
                        );
                    }
                } else {
                    this.props.showToast(`New Employee ${newEmployee.name} created`, 'positive');
                    this.setState({
                        spinner_loader: false,
                        employee_id: '0',
                        email: '',
                        name: '',
                        first_name: '',
                        middle_name: '',
                        last_name: '',
                        reference_number: '',
                        payroll_item_ids: []
                    });
                }
            });
        }
    };

    handlePayrollItemOnChange = newPayrollItem => {
        this.setState({ payroll_item_id: newPayrollItem });
    };

    setFields = fields => {
        this.setState({ ...fields });
    };

    isPhoneNumberValid = () => {
        const { validation_msg, phone, send_invite_sms } = this.state;
        if (validation_msg !== '' && phone !== '') {
            return false;
        }
        if (send_invite_sms && (validation_msg !== '' || phone === '')) {
            return false;
        }

        return true;
    };

    handlePhoneInput = () => {
        isValidPhoneNumber('' + this.state.phone)
            ? this.setFields({
                validation_msg: '',
            })
            : this.setFields({
                validation_msg: 'Invalid Phone Number',
            });
    };
    handleDefaultUserCheckOnChange = defaultUserCreation => {
        this.setState({ defaultUserCreation: defaultUserCreation });
        if (defaultUserCreation && this.state.userSettings === 'default') {
            this.setState({ save_message: 'Save + Send Invite' });
        } else {
            this.setState({ save_message: 'Save' });
        }
    };

    handleOnChanges = {
        handleFirstNameOnChange: newName => {
            this.setState({ name: `${newName.target.value} ${this.state.last_name}` });
            this.setState({ first_name: newName.target.value });
        },
        handleMiddleNameOnChange: newName => {
            this.setState({ middle_name: newName.target.value });
        },
        handleLastNameOnChange: newName => {
            this.setState({ name: `${this.state.first_name} ${newName.target.value}` });
            this.setState({ last_name: newName.target.value });
        },
        handleEmailOnChange: newEmail => {
            this.setState({ email: newEmail.target.value });
        },
        handlePayrollItemOnChange: newPayrollItem => {
            this.setState({ payroll_item_ids: newPayrollItem });
        },
        handleReferenceNumberOnChange: newReferenceNumber => {
            this.setState({ reference_number: newReferenceNumber.target.value });
        },
    };

    render() {
        const {
            email,
            first_name,
            middle_name,
            last_name,
            payroll_items,
            payroll_item_ids,
            reference_number,
            defaultUserCreation,
            userSettings,
            save_message,
            spinner_loader,
            firstSearchFilter,
            secondSearchFilter,
            payroll_bill_rates,
        } = this.state;
        const { canManagePayRollItem } = this.props;
        return (
            <div className="applicationWrapper">
                <div className="application">
                    <div className="ftContainer cardContainer">
                        <form className="ftForm" onSubmit={this.saveEmployee}>
                            <Row className="ftrow ftRowAlightCenter">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Create New Employee
                                </div>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                First Name{' '}
                                            </div>
                                            <div className="required">*</div>
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.fNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="first_name"
                                                value={first_name}
                                                onChange={
                                                    this.handleOnChanges.handleFirstNameOnChange
                                                }
                                                onBlur={
                                                    first_name.length !== 0
                                                        ? () =>
                                                            isEmployeeNameValid(first_name)
                                                                ? this.setFields({
                                                                    fNameInvalidMsg: '',
                                                                })
                                                                : this.setFields({
                                                                    fNameInvalidMsg:
                                                                        'First name contains only  1 to 50 alphabets.',
                                                                })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.fNameInvalidMsg === '' &&
                                                    !first_name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.fNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                Last Name{' '}
                                            </div>
                                            <div className="required">*</div>
                                        </Row>
                                        <div>
                                            <Input
                                                className={
                                                    this.state.lNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="last_name"
                                                value={last_name}
                                                onChange={
                                                    this.handleOnChanges.handleLastNameOnChange
                                                }
                                                onBlur={
                                                    last_name.length !== 0
                                                        ? () =>
                                                            isEmployeeNameValid(last_name)
                                                                ? this.setFields({
                                                                    lNameInvalidMsg: '',
                                                                })
                                                                : this.setFields({
                                                                    lNameInvalidMsg:
                                                                        'Last name contains only  1 to 50 alphabets.',
                                                                })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.lNameInvalidMsg === '' &&
                                                    !last_name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.lNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                Middle Name{' '}
                                            </div>
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.mNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="middle_name"
                                                value={middle_name}
                                                onChange={
                                                    this.handleOnChanges.handleMiddleNameOnChange
                                                }
                                                onBlur={
                                                    middle_name.length !== 0
                                                        ? () =>
                                                            isEmployeeNameValid(middle_name)
                                                                ? this.setFields({
                                                                    mNameInvalidMsg: '',
                                                                })
                                                                : this.setFields({
                                                                    mNameInvalidMsg:
                                                                        'Middle name contains only  1 to 50 alphabets.',
                                                                })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.mNameInvalidMsg === '' &&
                                                    !middle_name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.mNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                Email{' '}
                                            </div>
                                            <div className="required">*</div>
                                        </Row>
                                        <div>
                                            <Input
                                                className={
                                                    this.state.emailInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="email"
                                                value={email}
                                                onChange={this.handleOnChanges.handleEmailOnChange}
                                                onBlur={
                                                    email.length !== 0
                                                        ? () =>
                                                            isValidEmail(email)
                                                                ? this.setFields({
                                                                    emailInvalidMsg: '',
                                                                })
                                                                : this.setFields({
                                                                    emailInvalidMsg:
                                                                        'Email must be between to 4 to 40 characters (e.g. example@minute7.com)',
                                                                })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.emailInvalidMsg === '' &&
                                                    !email === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.emailInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                {' '}
                                                Employee Number{' '}
                                            </div>
                                        </Row>
                                        <div>
                                            <Input
                                                name="reference_number"
                                                value={reference_number}
                                                onChange={
                                                    this.handleOnChanges
                                                        .handleReferenceNumberOnChange
                                                }
                                            />
                                        </div>
                                    </div>
                                </Col>
                                {/* </Row>
                                <Row className="ftrow"> */}
                                <Col sm={6} >
                                    <ReactTooltip
                                        delayShow={500}
                                        className="tooltip-override"
                                        data-for="UserCreation"
                                        type="info"
                                        multiline={true}
                                        data-html={true}
                                    />
                                    {/* <div className="fFilterCheckboxContainer"> */}
                                    <div className="fFilterCheckboxContainer" style={{ marginTop: '27px' }}>
                                        <div className="fFilterByDateCheckbox">
                                            <Icon
                                                className="blueIcon"
                                                size={18}
                                                icon={
                                                    defaultUserCreation
                                                        ? checkboxChecked
                                                        : checkboxUnchecked
                                                }
                                                onClick={() =>
                                                    this.handleDefaultUserCheckOnChange(
                                                        !defaultUserCreation
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            className="fEnableDateRangeText"
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            Add as a user
                                        </div>
                                        <Icon
                                            style={{ paddingLeft: '4px' }}
                                            className="blueIcon fEnableDateRangeText"
                                            size={16}
                                            data-tip="Selecting this option will auto create Minute 7 user account for this employee."
                                            icon={info}
                                            id="UserCreation"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {defaultUserCreation && (
                                <div className="ftLabelAndInput">
                                    <ReactTooltip
                                        delayShow={500}
                                        className="tooltip-override"
                                        data-for="permission"
                                        type="info"
                                        multiline={true}
                                        data-html={true}
                                    />
                                    <ToggleSettingListV2
                                        title={[
                                            <span style={{ fontWeight: 'bold' }}>
                                                Permission Settings{' '}
                                            </span>,
                                            <Icon
                                                className="blueIcon"
                                                size={16}
                                                data-tip="Default permission settings allow users to add and edit their timesheets. Choose customized settings for different permissions."
                                                icon={info}
                                                id="permission"
                                            />,
                                        ]}
                                        value={userSettings}
                                        options={[
                                            { label: 'Default', value: 'default' },
                                            { label: 'Customized', value: 'custom' },
                                        ]}
                                        valueForOption={option => option.value}
                                        labelForOption={option => option.label}
                                        onChange={value => {
                                            this.setState({
                                                userSettings: value,
                                            });
                                            if (value === 'default') {
                                                this.setState({
                                                    save_message: 'Save + Send Invite',
                                                });
                                            } else {
                                                this.setState({ save_message: 'Save' });
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {canManagePayRollItem && (
                            <Row className="ftrow">
                                <div className="ftLabel largeBodyText greyText"  
                                            style={{ fontWeight: 'bold' , paddingTop: '2%', paddingBottom:'2%' }}>
                                    {' '}
                                    Payroll Items{' '}
                                </div>
                            </Row>)}
                            {canManagePayRollItem && (
                                <ToggleOptionsSecondV
                                listOptions={payroll_items}
                                firstSearchFilter={firstSearchFilter}
                                secondSearchFilter={secondSearchFilter}
                                valueForListOptionMap={payroll_bill_rates}
                                listValue={payroll_item_ids}
                                options={['Yes', 'No']}
                                onListChange={values => {
                                    this.setState({ payroll_item_ids: values });
                                }}
                                onValueChange={values => {
                                    this.setState({ payroll_bill_rates: values });
                                }}
                                />
                            )}
                           
                            <Row>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '10px' }}>
                                    <div className="ftButtonContainer">
                                        <button type="submit" className="primaryButton ftButton">
                                            {save_message}
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '10px' }}>
                                    <div className="ftButtonContainer">
                                        <button
                                            className="secondaryButton ftButton"
                                            onClick={() =>
                                                this.props.history.push(
                                                    '/resource_settings/employees'
                                                )
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Spinner loading={spinner_loader} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const employees = accountSelectors.selectCanEditActiveEmployees(state);

    return {
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        isSaving: state.accountUserStore.isSaving,
        canManagePayRollItem: state.accountUserStore.manage_payroll_payrates === 'Yes',
        payrollItems: state.businessResourcesStore.allActivePayrollItems,
        expenseTransactionType: state.accountStore.expense_transaction_type,
        qbType: state.accountStore.qb_type,
        customers: resourceSelectors.selectAllCustomers(state),
        inventoryItems: resourceSelectors.selectAllInventoryItems(state),
        allowedQbAccounts: expensesSelectors.selectAllowedQbAccountsForExpenses(state),
        allowedQbPaymentAccounts: expensesSelectors.selectAllowedQbPaymentAccountsForExpenses(
            state
        ),
        employees,
    };
}

const mapDispatchToProps = {
    showToast,
    addEmployee,
    selectEmployee,
    addUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewEmployeeForm);
