import React from 'react';

const AllowPayrollItems = () => (
    <div>
        <div className="sectionQuestion">
            How do I allow my employees to edit payroll items for time entries?
        </div>
        <div className="sectionAnswer">
            Minute7 users with the permission to "Edit Payroll" can edit and assign payroll items
            for time entries for employees who are set to use time data to create paychecks in
            QuickBooks. To give a user permission to edit payroll items, go to the Edit User
            Permissions page for that user by clicking on the Edit User Permissions icon on your
            Account page. On the Permissions page for the user, you can set "Edit Payroll" to Yes or
            No.
        </div>
    </div>
);

export default AllowPayrollItems;
