import _ from 'lodash';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import * as timerActions from '../actions/TimerActions';
import * as resourceSelectors from '../selectors/BusinessResourcesSelectors';
import * as accountSelectors from '../selectors/AccountSelectors';
import { figureEmployeeId } from '../utils/TimeEntryUtils.js';

const selectDefaultsForNewTimerEntries = state => {
    const { timerStore, accountStore, accountUserStore: currentUser } = state;
    const {
        inventoryItemId,
        customerId,
        payrollItemId,
        employeeId,
        roundingInterval,
        billable,
        taxable,
    } = timerStore;

    const canEditEmployees = accountSelectors.selectCanEditEmployees(state);
    const inventoryItems = resourceSelectors.selectAllowedInventoryItems(state);
    const customers = resourceSelectors.selectAllowedCustomers(state);

    const defaults = {};

    const defaultEmployeeId = figureEmployeeId(currentUser, canEditEmployees);

    const isBillableByDefault = accountStore.billable_by_default === 'Yes';
    const isTaxableByDefault = accountStore.taxable_by_default === 'Yes';
    const isApprovedByDefault = accountStore.approved_by_default === 'Yes';

    defaults.approved = isApprovedByDefault ? 'Yes' : 'No';

    if (billable === null) {
        defaults.billable = isBillableByDefault ? 'Yes' : 'No';
    }

    if (taxable === null) {
        defaults.taxable = isTaxableByDefault ? 'Yes' : 'No';
    }

    if (inventoryItemId === '0') {
        defaults.inventoryItemId = _.get(inventoryItems, '[0].id', '0');
    }

    if (customerId === '0') {
        defaults.customerId = _.get(customers, '[0].id', '0');
    }

    // Set to default_payroll_item_id for employee if present
    if (payrollItemId === '0') {
        const employee = canEditEmployees.find(employee => employee.id === defaultEmployeeId);
        if (
            employee &&
            employee.use_time_for_paychecks === 'UseTimeData' &&
            'default_payroll_item_id' in employee
        ) {
            defaults.payrollItemId = employee.default_payroll_item_id;
        }
    }

    if (employeeId === '0' && defaultEmployeeId) {
        const type = defaultEmployeeId.includes('_v') ? 'Vendor' : 'Employee';
        defaults.employeeId = defaultEmployeeId;
        defaults.type = type;
    }

    if (roundingInterval === null) {
        defaults.roundingInterval = accountStore.default_rounding_interval;
    }

    return defaults;
};

export function* setDefaultsForNewTimerEntries() {
    const defaults = yield select(selectDefaultsForNewTimerEntries);
    yield put(timerActions.setDefaultsForNewTimerEntries(defaults));
}

export default function* root() {
    yield all([takeLatest(['RESOURCES_LOADED'], setDefaultsForNewTimerEntries)]);
}
