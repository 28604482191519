import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { ic_mode_edit } from 'react-icons-kit/md/ic_mode_edit';
import { ic_more_horiz as menuIcon } from 'react-icons-kit/md/ic_more_horiz';
import { stopwatch as timerIcon } from 'react-icons-kit/entypo/stopwatch';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { entryIncludesCurrentDate } from '../../../utils/TimeEntryUtils';
import ReactTooltip from 'react-tooltip';
import '../../../css/Timesheets.css';

/**
 * This component differs from our component/EntryOptions by accepting entries grouped by week
 */
export default class TimeEntryOptions extends PureComponent {
    static propTypes = {
        entry: PropTypes.object.isRequired,
        entryHash: PropTypes.string.isRequired,
        groupedIds: PropTypes.object,
        onEditEntry: PropTypes.func.isRequired,
        onDuplicateEntry: PropTypes.func.isRequired,
        onToggleApproved: PropTypes.func.isRequired,
        onToggleBillable: PropTypes.func.isRequired,
        onToggleTaxable: PropTypes.func.isRequired,
        onSendMessagePressed: PropTypes.func.isRequired,
        onDeletePressed: PropTypes.func.isRequired,
        onContinueTimer: PropTypes.func.isRequired,
        onStartNewTimer: PropTypes.func.isRequired,
        onOpenTimer: PropTypes.func.isRequired,
        canApprove: PropTypes.bool.isRequired,
        canEdit: PropTypes.bool.isRequired,
        shouldShowBillable: PropTypes.bool.isRequired,
        shouldShowTaxable: PropTypes.bool.isRequired,
        isUserClockInOnly: PropTypes.bool.isRequired,
        noIntegration: PropTypes.bool,
        canLockEntries: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false,
        };
    }

    handleToggleApprovePressed = () => {
        this.props.onToggleApproved(this.props.entry, this.props.noIntegration, true);
    };

    handleToggleLockPressed = () => {
        this.props.onToggleApproved(this.props.entry, this.props.noIntegration, false);
    };

    handleToggleBillablePressed = () => {
        this.props.onToggleBillable(this.props.entry);
    };

    handleToggleTaxablePressed = () => {
        this.props.onToggleTaxable(this.props.entry);
    };

    handleEditPressed = () => {
        this.props.onEditEntry(this.props.entry, this.props.entryHash);
    };

    handleDuplicatePressed = () => {
        this.props.onDuplicateEntry(this.props.entry, this.props.entryHash);
    };

    handleContinueTimerPressed = () => {
        this.props.onContinueTimer(this.props.entry);
    };

    handleStartTimerForTodayPressed = () => {
        this.props.onStartNewTimer(this.props.entry);
    };

    handleToggleDropdownPressed = () => {
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    };

    render() {
        const {
            entry,
            canApprove,
            canEdit,
            onOpenTimer,
            isContinuingTimerRow,
            shouldShowBillable,
            shouldShowTaxable,
            isUserClockInOnly,
            groupedByWeek,
            noIntegration,
            canLockEntries,
        } = this.props;

        const isApproved = entry.approved === 'Yes';
        const isLocked = entry.locked === 'Yes';
        const isBillable = entry.billable === 'Yes';
        const isTaxable = entry.taxable === 'Yes';
        const hasEntryForCurrentDate = entryIncludesCurrentDate(entry);
        const shouldDisplayTimerButton = groupedByWeek
            ? hasEntryForCurrentDate /* || isInCurrentWeek*/
            : hasEntryForCurrentDate;
        const { isDropdownOpen } = this.state;

        const timerButtonLabel = isContinuingTimerRow
            ? 'View Timer'
            : hasEntryForCurrentDate
            ? 'Continue Timer'
            : 'Start New Timer for Today';

        const timerButtonAction = isContinuingTimerRow
            ? onOpenTimer
            : hasEntryForCurrentDate
            ? this.handleContinueTimerPressed
            : this.handleStartTimerForTodayPressed;

        return (
            <div className="entryOptionContainer">
                <ReactTooltip delayShow={500} type="info" multiline={true} />

                {canEdit && !isUserClockInOnly && (
                    <button
                        className="rowActionButton rowActionButtonWithIcon"
                        onClick={this.handleEditPressed}
                    >
                        <Icon icon={ic_mode_edit} size={20} />
                        Edit
                    </button>
                )}

                {shouldDisplayTimerButton && canEdit && (
                    <button
                        className="rowActionButton rowActionButtonWithIcon"
                        onClick={timerButtonAction}
                        data-tip={timerButtonLabel}
                    >
                        <Icon icon={timerIcon} size={20} />
                    </button>
                )}

                <Dropdown isOpen={isDropdownOpen} toggle={this.handleToggleDropdownPressed}>
                    <DropdownToggle className="rowActionButton">
                        <Icon size={20} icon={menuIcon} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {hasEntryForCurrentDate && canEdit && (
                            <DropdownItem onClick={this.handleContinueTimerPressed}>
                                Continue Timer
                            </DropdownItem>
                        )}

                        {!hasEntryForCurrentDate && canEdit && !isLocked && (
                            <DropdownItem onClick={this.handleStartTimerForTodayPressed}>
                                Start New Timer for Today
                            </DropdownItem>
                        )}

                        {!isUserClockInOnly && (
                            <DropdownItem onClick={this.handleDuplicatePressed}>
                                Duplicate
                            </DropdownItem>
                        )}

                        {canEdit && canApprove && (
                            <DropdownItem onClick={this.handleToggleApprovePressed}>
                                {isApproved ? 'Mark as Pending' : 'Mark as Approved'}
                            </DropdownItem>
                        )}

                        {canEdit && shouldShowBillable && (
                            <DropdownItem onClick={this.handleToggleBillablePressed}>
                                {isBillable ? 'Mark as Not Billable' : 'Mark as Billable'}
                            </DropdownItem>
                        )}

                        {canEdit && shouldShowTaxable && isBillable && (
                            <DropdownItem onClick={this.handleToggleTaxablePressed}>
                                {isTaxable ? 'Mark as Not Taxable' : 'Mark as Taxable'}
                            </DropdownItem>
                        )}

                        {canEdit && noIntegration && isApproved && !isLocked && canLockEntries && (
                            <DropdownItem onClick={this.handleToggleLockPressed}>
                                {'Mark as Locked'}
                            </DropdownItem>
                        )}

                        <DropdownItem onClick={() => this.props.onSendMessagePressed(entry)}>
                            Send a Message
                        </DropdownItem>

                        {canEdit && <DropdownItem divider />}

                        {canEdit && (
                            <DropdownItem
                                onClick={() => this.props.onDeletePressed(entry)}
                                className="destructiveOption"
                            >
                                Delete
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}
