import React from 'react';
import uuid from 'uuid';

const testimonies = [
    <div className="zoomIn animated testimonyColumn" key={uuid.v4()}>
        <p>Minute7 integrates easily with QuickBooks, its great!</p>
        <div className="person">
            <img className="img-circle img-responsive" src="/img/sales/slider-img-1.jpg" alt="" />
            <h6>Gregory Boyce</h6>
            <h5>Boyce Legal, LLC</h5>
        </div>
    </div>,
    <div className="zoomIn animated testimonyColumn" key={uuid.v4()}>
        <p>I use Minute7 for expenses and reimbursements everyday.</p>
        <div className="person">
            <img className="img-circle img-responsive" src="/img/sales/slider-img-2.jpg" alt="" />
            <h6>Matt Riggs</h6>
            <h5>Riggs Financials</h5>
        </div>
    </div>,
    <div className="zoomIn animated testimonyColumn" key={uuid.v4()}>
        <p>Minute7 has made my payroll so simple. Thank you!</p>
        <div className="person">
            <img className="img-circle img-responsive" src="/img/sales/slider-img-3.jpg" alt="" />
            <h6>Kelly Melantin</h6>
            <h5>Utah Architects</h5>
        </div>
    </div>,
    <div className="zoomIn animated testimonyColumn" key={uuid.v4()}>
        <p>I've been using Minute7 for 5 years, would never consider anything else.</p>
        <div className="person">
            <img className="img-circle img-responsive" src="/img/sales/slider-img-4.jpg" alt="" />
            <h6>Ali Berry</h6>
            <h5>Menlo Landscaping</h5>
        </div>
    </div>,
    <div className="zoomIn animated testimonyColumn" key={uuid.v4()}>
        <p>No more paper or spreadsheets for me, I just use Minute7.</p>
        <div className="person">
            <img className="img-circle img-responsive" src="/img/sales/slider-img-5.jpg" alt="" />
            <h6>Haley Smith</h6>
            <h5>Cleaners4.me</h5>
        </div>
    </div>,
    <div className="zoomIn animated testimonyColumn" key={uuid.v4()}>
        <p>Minute7 is super easy to learn, I use it everyday!</p>
        <div className="person">
            <img className="img-circle img-responsive" src="/img/sales/slider-img-6.jpg" alt="" />
            <h6>Ryan Johnson</h6>
            <h5>Wriley Air Conditioning</h5>
        </div>
    </div>,
];

export default testimonies;
