import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../../css/TwoButtonSwitch.css';

const TwoButtonSwitch = ({
    firstButtonText,
    firstButtonValue,
    secondButtonText,
    secondButtonValue,
    onChange,
    value,
}) => {
    const handleFirstButtonClicked = () => onChange(firstButtonValue);
    const handleSecondButtonClicked = () => onChange(secondButtonValue);

    const firstButtonClassNames = classNames({
        tbsButtonContainer: true,
        tbsSelectedButton: firstButtonValue === value,
    });
    const secondButtonClassNames = classNames({
        tbsButtonContainer: true,
        tbsSelectedButton: secondButtonValue === value,
    });

    return (
        <div className="tbsContainer">
            <div className={firstButtonClassNames} onClick={handleFirstButtonClicked}>
                {firstButtonText}
            </div>
            <div className={secondButtonClassNames} onClick={handleSecondButtonClicked}>
                {secondButtonText}
            </div>
        </div>
    );
};

TwoButtonSwitch.propTypes = {
    firstButtonText: PropTypes.string.isRequired,
    firstButtonValue: PropTypes.any,
    secondButtonText: PropTypes.string.isRequired,
    secondButtonValue: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
};

export default TwoButtonSwitch;
