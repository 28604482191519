import React from 'react';

const VendorAssociation = () => (
    <div>
        <div className="sectionQuestion">
            Can I have users and time entries associated with vendors?
        </div>
        <div className="sectionAnswer">
            Yes — Minute7 fully supports time entries for vendors and user accounts tied to specific
            vendors in your QuickBooks company file. To fully enable vendor time-entry support (at
            no additional charge) simply go to your Account Settings page and choose <b>"Yes"</b>{' '}
            under the <b>Enable time entries for Vendors option.</b>
        </div>
    </div>
);

export default VendorAssociation;
