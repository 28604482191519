import _ from 'lodash';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import Icon from 'react-icons-kit';
import { ic_check_box as checkboxChecked } from 'react-icons-kit/md/ic_check_box';
import { ic_check_box_outline_blank as checkboxUnchecked } from 'react-icons-kit/md/ic_check_box_outline_blank';
import { ic_indeterminate_check_box as checkboxIndeterminate } from 'react-icons-kit/md/ic_indeterminate_check_box';
import { ic_sync_problem as syncIssue } from 'react-icons-kit/md/ic_sync_problem';
import MessageModal from '../../components/MessageModal.js';
import DeleteEntryModal from '../../components/DeleteEntryModal.js';
import TimeEntryOptions from './TimeEntryOptions.js';
import NoEntriesFound from './../../components/NoEntriesFound.js';
import { figureNameOrDescription } from '../../../utils/CommonFunctions.js';
import { idsForWeeklyEntry, isEmptyDuration } from '../../../utils/TimeEntryUtils.js';
import { showToast } from '../../../actions/Actions.js';
import {
    deleteTimeEntries,
    setTimeEntriesApproved,
    setTimeEntriesBillable,
    setTimeEntriesTaxable,
    setTimeEntriesLocked,
} from '../../../actions/TimesheetsActions';
import '../../../css/Timesheets.css';

const MAX_DESCRIPTION_LENGTH = 160;
const entityNameToDisplayName = {
    employee: 'Employee',
    customer: 'Customer',
    inventoryItem: 'Service',
    qbClass: 'Class',
    payrollItem: 'Payroll Item',
};

const constructUnknownEntitiesDataTip = unknownEntities => {
    const displayNames = unknownEntities.map(name => entityNameToDisplayName[name]);
    return 'Unknown ' + displayNames.join(', ');
};

const renderWeekdayTotals = (entry, startOfWeek, filterStartDate, filterEndDate) => {
    const startDate = moment(startOfWeek);

    return _.range(0, 7).map(index => {
        const isOutOfRange =
            startDate.isBefore(filterStartDate, 'day') || startDate.isAfter(filterEndDate, 'day');
        const day = startDate.format('ddd');
        const duration = entry.durations[day];
        startDate.add(1, 'd');

        if (isOutOfRange) {
            return (
                <td key={`weekday-${index}`} className="tsDetailsDayCell">
                    <span
                        data-tip="This date is outside of the currently selected date range"
                        className="outOfRange"
                    >
                        &mdash;
                    </span>
                </td>
            );
        }
        return (
            <td key={`weekday-${index}`} className="tsDetailsDayCell">
                {!isEmptyDuration(duration) ? (
                    <span className="nonEmptyTotal">{duration}</span>
                ) : (
                    <span className="emptyTotal">0:00</span>
                )}
            </td>
        );
    });
};

const TimesheetRow = ({
    entry,
    qbType,
    startOfWeek,
    shouldShowPayroll,
    onEditEntry,
    onDuplicateEntry,
    onDeleteEntry,
    onSendMessage,
    selectedEntryHash,
    selectedEntryIds,
    onEntrySelect,
    shouldShowBillable,
    shouldShowTaxable,
    shouldShowQbClasses,
    onContinueTimer,
    onStartNewTimer,
    onOpenTimer,
    onToggleBillable,
    onToggleTaxable,
    onToggleApproved,
    isContinuingTimerRow,
    isUserClockInOnly,
    updatingBillableEntryIds,
    updatingStatusEntryIds,
    groupedByWeek,
    filterStartDate,
    filterEndDate,
    noIntegration,
    canLockEntries,
}) => {
    const employee = entry.Employee;
    const customer = entry.Customer;
    const inventoryItem = entry.InventoryItem;
    const qbClass = entry.QbClass;
    const payrollItem = entry.PayrollItem;
    const unknownEntities = entry.unknown_entities;
    const isAbleToSync = entry.unknown_entities.length === 0;

    const employeeName = figureNameOrDescription(employee),
        customerName = figureNameOrDescription(customer),
        inventoryItemName = figureNameOrDescription(inventoryItem),
        qbClassName = figureNameOrDescription(qbClass),
        payrollItemName = figureNameOrDescription(payrollItem);

    const uuid = 'uuid' in entry ? entry.uuid : entry.id;
    const isBillable = entry.billable === 'Yes';
    const isTaxable = entry.taxable === 'Yes';
    const isSynced = entry.exported === 'Yes';
    const isLocked = entry.locked === 'Yes';
    const isApprovedForSyncing = entry.approved === 'Yes';
    let isUpdatingBillable = false;
    let isUpdatingStatus = false;
    if (groupedByWeek) {
        const ids = idsForWeeklyEntry(entry);
        isUpdatingBillable = updatingBillableEntryIds.some(id => ids.includes(id));
        isUpdatingStatus = updatingStatusEntryIds.some(id => ids.includes(id));
    } else {
        isUpdatingBillable = updatingBillableEntryIds.includes(entry.id);
        isUpdatingStatus = updatingStatusEntryIds.includes(entry.id);
    }
    const canApprove = entry.can_be_approved_by_current_user;
    const canEdit = entry.can_be_edited_by_current_user;
    const isSelected = selectedEntryIds.includes(uuid);
    const date = moment(entry.date).format('MMM D, YYYY');

    const shouldTruncate = entry.description
        ? entry.description.length > MAX_DESCRIPTION_LENGTH
        : false;
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
    const description =
        shouldTruncate && !isDescriptionExpanded
            ? _.truncate(entry.description, { length: MAX_DESCRIPTION_LENGTH, separator: ' ' })
            : entry.description;
    const approvedText = noIntegration ? 'Approved' : 'Approved for Sync';
    return (
        <tr
            className={classNames({
                detailsRow: true,
                editing: _.isEqual(entry.week_hash, selectedEntryHash),
                selected: isSelected,
            })}
        >
            <td className="tsSelectionCell">
                {(canEdit || canApprove) && isAbleToSync && (
                    <Icon
                        className="icon"
                        icon={isSelected ? checkboxChecked : checkboxUnchecked}
                        size={18}
                        onClick={onEntrySelect.bind(this, uuid)}
                    />
                )}

                {!isAbleToSync && (
                    <span sync-data-tip={constructUnknownEntitiesDataTip(unknownEntities)}>
                        <Icon className="syncIssueIcon" icon={syncIssue} size={30} />
                    </span>
                )}
            </td>
            <td className="tsEmployeeCell">{employeeName}</td>

            <td className="tsCustomerJobDescriptionCell">
                <strong className="tsCustomerName">{customerName}</strong>

                <span className="tsInventoryItemName">{inventoryItemName}</span>

                {
                    <span
                        className={classNames({
                            tsBillableTag: true,
                            tsEditableBillableTag: canEdit && shouldShowBillable,
                            isBillable,
                        })}
                        onClick={
                            canEdit && shouldShowBillable ? () => onToggleBillable(entry) : null
                        }
                    >
                        {isUpdatingBillable
                            ? 'Updating...'
                            : isBillable
                            ? 'Billable'
                            : 'Not Billable'}
                    </span>
                }

                {qbType === 'Online' && (
                    <span
                        className={classNames({
                            tsTaxableTag: true,
                            tsEditableTaxableTag: canEdit && shouldShowTaxable,
                            isTaxable,
                        })}
                        onClick={
                            canEdit && shouldShowTaxable && isBillable
                                ? () => onToggleTaxable(entry)
                                : null
                        }
                    >
                        {isUpdatingBillable ? 'Updating...' : isTaxable ? 'Taxable' : 'Not Taxable'}
                    </span>
                )}

                {shouldShowQbClasses && qbClassName && (
                    <div className="tsQbClassName">{qbClassName}</div>
                )}
                {shouldShowPayroll && payrollItemName && (
                    <div className="tsPayrollItemName">{payrollItemName}</div>
                )}

                {description && (
                    <p className="entryDescriptionContainer">
                        {description}{' '}
                        {shouldTruncate && (
                            <button
                                onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
                                className="tertiaryButton"
                            >
                                {isDescriptionExpanded ? 'Less' : 'More'}
                            </button>
                        )}
                    </p>
                )}
            </td>

            {groupedByWeek &&
                renderWeekdayTotals(entry, startOfWeek, filterStartDate, filterEndDate)}

            {!groupedByWeek && <td className="tsDateCell">{date}</td>}

            {!groupedByWeek && <td className="tsDurationCell">{entry.duration}</td>}

            <td className="tsStatusCell">
                {isSynced && (
                    <span className="tsStatus tsStatusSynced">
                        {isUpdatingStatus ? 'Updating...' : 'Synced'}
                    </span>
                )}
                {isLocked && noIntegration && (
                    <span className="tsStatus tsStatusApproved">
                        {isUpdatingStatus ? 'Updating...' : 'Locked'}
                    </span>
                )}
                {isApprovedForSyncing && !isSynced && canApprove && !isLocked && (
                    <span
                        className="tsStatus tsStatusApproved"
                        onClick={() => onToggleApproved(entry, noIntegration)}
                    >
                        {isUpdatingStatus ? 'Updating...' : approvedText}
                    </span>
                )}
                {!isApprovedForSyncing && !isSynced && canApprove && !isLocked && (
                    <span
                        className="tsStatus tsStatusPending"
                        onClick={() => onToggleApproved(entry, noIntegration)}
                    >
                        {isUpdatingStatus ? 'Updating...' : 'Pending Approval'}
                    </span>
                )}
            </td>

            <td className="actionsCell">
                <TimeEntryOptions
                    entry={entry}
                    entryHash={entry.week_hash}
                    groupedIds={entry.grouped_time_ids}
                    onEditEntry={onEditEntry}
                    onDuplicateEntry={onDuplicateEntry}
                    onSendMessagePressed={onSendMessage}
                    onDeletePressed={onDeleteEntry}
                    onToggleApproved={onToggleApproved}
                    onToggleBillable={onToggleBillable}
                    onToggleTaxable={onToggleTaxable}
                    onContinueTimer={onContinueTimer}
                    onStartNewTimer={onStartNewTimer}
                    onOpenTimer={onOpenTimer}
                    canApprove={canApprove}
                    canEdit={canEdit}
                    groupedByWeek={groupedByWeek}
                    isContinuingTimerRow={isContinuingTimerRow}
                    shouldShowBillable={shouldShowBillable}
                    shouldShowTaxable={shouldShowTaxable}
                    isUserClockInOnly={isUserClockInOnly}
                    noIntegration={noIntegration}
                    canLockEntries={canLockEntries}
                />
            </td>
        </tr>
    );
};

class TimesheetWeekDetails extends Component {
    static propTypes = {
        selectedEntryHash: PropTypes.string,
        selectedEntryIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        editTimeEntry: PropTypes.func.isRequired,
        duplicateTimeEntry: PropTypes.func.isRequired,
        deleteTimeEntries: PropTypes.func.isRequired,
        setTimeEntriesApproved: PropTypes.func.isRequired,
        setTimeEntriesLocked: PropTypes.func.isRequired,
        setTimeEntriesBillable: PropTypes.func.isRequired,
        onEntrySelect: PropTypes.func.isRequired,
        onSelectAllPress: PropTypes.func.isRequired,
        groupedByWeek: PropTypes.bool.isRequired,
        entries: PropTypes.array,
        entriesByWeek: PropTypes.object,
        weekIds: PropTypes.array,
        weeklyTotals: PropTypes.object,
        weeklyGrandTotal: PropTypes.object,
        startDate: PropTypes.objectOf(Date),
        endDate: PropTypes.objectOf(Date),
        shouldShowBillable: PropTypes.bool.isRequired,
        shouldShowQbClasses: PropTypes.bool.isRequired,
        shouldShowPayrollItems: PropTypes.bool.isRequired,
        updatingBillableEntryIds: PropTypes.array.isRequired,
        updatingStatusEntryIds: PropTypes.array.isRequired,
    };

    static defaultProps = {
        entriesByWeek: {},
        weeklyEntriesByWeek: {},
        weekIds: [],
        weeklyTotals: {},
        weeklyGrandTotal: {},
        updatingBillableEntryIds: [],
        updatingStatusEntryIds: [],
    };

    state = {
        isMessageModalOpen: false,
        isDeleteEntryModalOpen: false,
        selectedEntry: null,
    };

    handleMessageModalClosed = () => {
        this.setState({
            isMessageModalOpen: false,
        });
    };

    handleSendMessagePressed = entry => {
        this.setState({
            isMessageModalOpen: true,
            selectedEntry: entry,
        });
    };

    handleDeleteEntryModalClosed = () => {
        this.setState({
            isDeleteEntryModalOpen: false,
        });
    };

    handleDeletePressed = entry => {
        this.setState({
            isDeleteEntryModalOpen: true,
            selectedEntry: entry,
        });
    };

    handleDeleteConfirmed = () => {
        let ids = [this.state.selectedEntry.id];
        if ('grouped_time_ids' in this.state.selectedEntry) {
            ids = idsForWeeklyEntry(this.state.selectedEntry);
        }
        this.props.deleteTimeEntries(ids);
    };

    requestDeleteModalClose = () => {
        this.setState({
            isDeleteEntryModalOpen: false,
        });
    };

    renderWeekdayHeaders = (startOfWeek, totals) => {
        const startDate = moment(startOfWeek);
        return _.range(0, 7).map(index => {
            const day = startDate.format('ddd');
            startDate.add(1, 'd');
            const total = totals[day].toFixed(2);
            return (
                <th key={`weekday-${index}`} className="tsDetailsDayCell">
                    <span className="date">{day}</span>
                    <span className={total > 0 ? 'dayTotal' : 'emptyTotal'}>{total}</span>
                </th>
            );
        });
    };

    renderWeekdayDatesAndTotals = (startOfWeek, totals) => {
        const startDate = moment(startOfWeek);

        return _.range(0, 7).map(index => {
            const date = startDate.format('MMM D');
            const day = startDate.format('ddd');
            startDate.add(1, 'd');
            const total = totals[day].toFixed(2);
            return (
                <td key={`weekday-${index}`} className="tsDetailsDayCell">
                    <span className="date">{date}</span>
                    <span className={total > 0 ? 'dayTotal' : 'emptyTotal'}>{total}</span>
                </td>
            );
        });
    };

    handleToggleApprovePressed = (entry, noIntegration, isApprove) => {
        const approvedStatus = entry.approved === 'Yes' ? 'No' : 'Yes';

        let ids = [entry.id];
        if (entry.grouped_time_ids) {
            ids = idsForWeeklyEntry(entry);
        }

        if (noIntegration && entry.locked === 'Yes' && !isApprove) {
            return;
        } else if (
            noIntegration &&
            entry.locked === 'No' &&
            entry.approved === 'Yes' &&
            !isApprove
        ) {
            this.props.setTimeEntriesLocked(ids);
        } else {
            entry.approved = approvedStatus;
            this.props.setTimeEntriesApproved(ids, approvedStatus);
        }
    };

    handleBillablePressed = entry => {
        const billableStatus = entry.billable === 'Yes' ? 'No' : 'Yes';
        entry.billable = billableStatus;
        let ids = [entry.id];
        if (entry.grouped_time_ids) {
            ids = idsForWeeklyEntry(entry);
        }
        this.props.setTimeEntriesBillable(ids, billableStatus);
    };

    handleTaxablePressed = entry => {
        const taxableStatus = entry.taxable === 'Yes' ? 'No' : 'Yes';
        entry.taxable = taxableStatus;
        let ids = [entry.id];
        if (entry.grouped_time_ids) {
            ids = idsForWeeklyEntry(entry);
        }
        this.props.setTimeEntriesTaxable(ids, taxableStatus);
    };

    render() {
        const {
            entriesByWeek,
            weeklyEntriesByWeek,
            weekIds,
            editTimeEntry,
            duplicateTimeEntry,
            weeklyTotals,
            weeklyGrandTotal,
            selectedEntryHash,
            onSelectAllPress,
            onEntrySelect,
            selectedEntryIds,
            groupedByWeek,
            qbType,
            currentUser,
            entriesAvailableForEdit,
            groupingType,
            showToast,
            continueTimer,
            startNewTimer,
            openTimer,
            shouldShowBillable,
            shouldShowTaxable,
            shouldShowQbClasses,
            shouldShowPayrollItems,
            isUserClockInOnly,
            updatingBillableEntryIds,
            updatingStatusEntryIds,
            startDate,
            endDate,
            activeTimerEntryId,
            noIntegration,
            canLockEntries,
        } = this.props;

        const { isMessageModalOpen, isDeleteEntryModalOpen, selectedEntry } = this.state;

        const shouldShowPayroll = shouldShowPayrollItems && qbType !== 'Online';

        let totalEntries = 0;
        const weeks = weekIds.map(timestamp => {
            const startOfWeek = moment(parseInt(timestamp, 10));
            const entries = entriesByWeek[timestamp].map(entry => {
                return { ...entry, ...{ start_of_the_week: startOfWeek.format('MMM D, YYYY') } };
            });
            const weeklyEntries = weeklyEntriesByWeek[timestamp].map(weeklyEntry => {
                return {
                    ...weeklyEntry,
                    ...{ start_of_the_week: startOfWeek.format('MMM D, YYYY') },
                };
            });

            totalEntries += entriesByWeek[timestamp].length;

            return {
                id: timestamp,
                startOfWeek,
                label: startOfWeek.format('MMM D, YYYY'),
                entries: entries,
                weeklyEntries: weeklyEntries,
            };
        });

        let selectAllIcon = checkboxUnchecked;
        if (groupingType === 'week') {
            if (
                selectedEntryIds.length === entriesAvailableForEdit.length &&
                entriesAvailableForEdit.length > 0
            ) {
                selectAllIcon = checkboxChecked;
            } else if (selectedEntryIds.length > 0) {
                selectAllIcon = checkboxIndeterminate;
            }
        } else {
            if (selectedEntryIds.length === totalEntries && entriesAvailableForEdit.length > 0) {
                selectAllIcon = checkboxChecked;
            } else if (selectedEntryIds.length > 0) {
                selectAllIcon = checkboxIndeterminate;
            }
        }

        if (!_.isEmpty(entriesByWeek)) {
            return (
                <div>
                    <table className="dataTable">
                        <thead>
                            <tr>
                                <th className="tsSelectionCell">
                                    <Icon
                                        className="icon"
                                        icon={selectAllIcon}
                                        size={18}
                                        onClick={onSelectAllPress}
                                    />
                                </th>
                                <th className="tsEmployeeCell">Employee</th>
                                <th className="tsCustomerJobDescriptionCell">
                                    Customer &amp; Job Details
                                </th>
                                {groupedByWeek &&
                                    this.renderWeekdayHeaders(
                                        weeks[0].startOfWeek,
                                        weeklyGrandTotal
                                    )}
                                {!groupedByWeek && <th className="tsDateCell">Date</th>}
                                {!groupedByWeek && <th className="tsDurationCell">Duration</th>}
                                <th className="tsStatusCell">&nbsp;</th>
                                <th className="tsTotalHours">
                                    Total
                                    <br />
                                    {weeklyGrandTotal.sum.toFixed(2)} Hours
                                </th>
                            </tr>
                        </thead>
                        {_.map(weeks, week => {
                            return (
                                <tbody key={week.id}>
                                    <tr className="tsWeekHeader">
                                        <td />
                                        <td />
                                        <td>Week of {week.label}</td>
                                        {groupedByWeek &&
                                            this.renderWeekdayDatesAndTotals(
                                                week.startOfWeek,
                                                weeklyTotals[week.id]
                                            )}
                                        {!groupedByWeek && <td />}
                                        {!groupedByWeek && <td />}
                                        <td />
                                        <td />
                                    </tr>
                                    {_.map(
                                        groupedByWeek ? week.weeklyEntries : week.entries,
                                        entry => (
                                            <TimesheetRow
                                                key={'uuid' in entry ? entry.uuid : entry.id}
                                                entry={entry}
                                                qbType={qbType}
                                                startOfWeek={week.startOfWeek}
                                                shouldShowPayroll={shouldShowPayroll}
                                                shouldShowBillable={shouldShowBillable}
                                                shouldShowTaxable={shouldShowTaxable}
                                                shouldShowQbClasses={shouldShowQbClasses}
                                                onEditEntry={editTimeEntry}
                                                onDuplicateEntry={duplicateTimeEntry}
                                                onDeleteEntry={this.handleDeletePressed}
                                                onSendMessage={this.handleSendMessagePressed}
                                                onEntrySelect={onEntrySelect}
                                                onToggleBillable={this.handleBillablePressed}
                                                onToggleTaxable={this.handleTaxablePressed}
                                                onToggleApproved={this.handleToggleApprovePressed}
                                                selectedEntryHash={selectedEntryHash}
                                                selectedEntryIds={selectedEntryIds}
                                                onContinueTimer={continueTimer}
                                                onStartNewTimer={startNewTimer}
                                                onOpenTimer={openTimer}
                                                isContinuingTimerRow={
                                                    activeTimerEntryId &&
                                                    ('entriesInGroupById' in entry
                                                        ? Object.keys(
                                                              entry.entriesInGroupById
                                                          ).includes(activeTimerEntryId)
                                                        : activeTimerEntryId === entry.id)
                                                }
                                                isUserClockInOnly={isUserClockInOnly}
                                                updatingBillableEntryIds={updatingBillableEntryIds}
                                                updatingStatusEntryIds={updatingStatusEntryIds}
                                                groupedByWeek={groupedByWeek}
                                                filterStartDate={startDate}
                                                filterEndDate={endDate}
                                                noIntegration={noIntegration}
                                                canLockEntries={canLockEntries}
                                            />
                                        )
                                    )}
                                </tbody>
                            );
                        })}
                    </table>
                    <DeleteEntryModal
                        isDeleteEntryModalOpen={isDeleteEntryModalOpen}
                        entry={selectedEntry}
                        objectName="TimeEntry"
                        requestClose={this.handleDeleteEntryModalClosed}
                        deleteEntry={this.handleDeleteConfirmed}
                        showToast={showToast}
                    />

                    <MessageModal
                        fromEmail={currentUser.email}
                        isMessageModalOpen={isMessageModalOpen}
                        requestClose={this.handleMessageModalClosed}
                        entry={selectedEntry}
                        objectName="TimeEntry"
                        showToast={showToast}
                    />
                </div>
            );
        } else {
            return <NoEntriesFound type="Timesheets" />;
        }
    }
}

const mapStateToProps = ({ timerStore, accountStore, accountUserStore, timesheetsStore }) => {
    return {
        qbType: accountStore.qb_type,
        isUserClockInOnly: accountUserStore.clock_in_only === 'Yes',
        currentUser: accountUserStore,
        updatingBillableEntryIds: timesheetsStore.updatingBillableEntryIds,
        updatingStatusEntryIds: timesheetsStore.updatingStatusEntryIds,
        activeTimerEntryId: timerStore.timeEntryId,
        canLockEntries:
            accountUserStore.manage_account === 'Yes' || accountUserStore.manage_users === 'Yes',
    };
};

const mapDispatchToProps = {
    showToast,
    deleteTimeEntries,
    setTimeEntriesBillable,
    setTimeEntriesTaxable,
    setTimeEntriesApproved,
    setTimeEntriesLocked,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TimesheetWeekDetails);
