import React from 'react';
import PropTypes from 'prop-types';

const FloatingSaveButton = ({ buttonText, hasBeenEdited, onSave }) => {
    return (
        <div hidden={!hasBeenEdited} className="floatingSave">
            <div className="floatingSaveContent">
                <div className="floatingButtonText">Edits have been made</div>
                <button className="floatingSaveButton primaryButton" onClick={() => onSave()}>
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

FloatingSaveButton.propTypes = {
    hasBeenEdited: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default FloatingSaveButton;
