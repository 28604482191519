import React from 'react';
import QuickBooksVersion from './pages/getting_started/QuickBooksVersions.js';
import Minute7Cost from './pages/getting_started/Minute7Cost.js';
import DeactivateAccount from './pages/getting_started/DeactivateAccount.js';
import SignUp from './pages/getting_started/SignUp.js';
import Minute7Secure from './pages/getting_started/Minute7Secure.js';
import DataBackup from './pages/getting_started/DataBackup.js';
import BeforeYouBegin from './pages/getting_started/BeforeYouBegin.js';
import UpdateBilling from './pages/account_admin/UpdateBilling.js';
import ResetPassword from './pages/account_admin/ResetPassword.js';
import RemoveUser from './pages/account_admin/RemoveUser.js';
import EditSyncedEntry from './pages/account_admin/EditSyncedEntry.js';
import Minute7WithOnlineEdition from './pages/syncing_with_qbo/Minute7WithOnlineEdition.js';
import QBOVersion from './pages/syncing_with_qbo/QBOVersion.js';
import ConnectionNotFound from './pages/syncing_with_qbo/ConnectionNotFound.js';
import CouldNotAddError from './pages/syncing_with_qbo/CouldNotAddError.js';
import QuickBooksFile from './pages/other_qb_questions/QuickBooksFile.js';
import QBSyncBeforeUsing from './pages/other_qb_questions/QBSyncBeforeUsing.js';
import SyncWithoutExporting from './pages/other_qb_questions/SyncWithoutExporting.js';
import Minute7Security from './pages/other_qb_questions/Minute7Security.js';
import EditSyncedEntries from './pages/other_qb_questions/EditSyncedEntries.js';
import ExportWaitTime from './pages/other_qb_questions/ExportWaitTime.js';
import UnknownPayrollInfo from './pages/common_qwc_errors/UnknownPayrollInfo.js';
import MissingPayrollInfo from './pages/common_qwc_errors/MissingPayrollInfo.js';
import EnableTimeTracking from './pages/common_qwc_errors/EnableTimeTracking.js';
import QBWC1085 from './pages/common_qwc_errors/QBWC1085.js';
import NotAssociatedEmployee from './pages/people_and_permissions/NotAssociatedEmployee.js';
import LimitCustomerJobs from './pages/people_and_permissions/LimitCustomerJobs.js';
import LimitServiceItems from './pages/people_and_permissions/LimitServiceItems.js';
import AccountManagerPermissions from './pages/people_and_permissions/AccountManagerPermissions.js';
import CannotSeeAllEmployees from './pages/people_and_permissions/CannotSeeAllEmployees.js';
import VendorAssociation from './pages/people_and_permissions/VendorAssociation.js';
import WhatIsAssociatedVendor from './pages/people_and_permissions/WhatIsAssociatedVendor.js';
import DoNotSeeEmployeesOrCustomers from './pages/working_with_timesheets/DoNotSeeEmployeesOrCustomers.js';
import ApprovedAndDisapproved from './pages/working_with_timesheets/ApprovedAndDisapproved.js';
import BillableAndUnbillableTime from './pages/working_with_timesheets/BillableAndUnbillableTime.js';
import QbClassesTime from './pages/working_with_timesheets/QbClassesTime.js';
import WhatAreExpenses from './pages/working_with_expense_tracking/WhatAreExpenses.js';
import WhoCanEnterExpenses from './pages/working_with_expense_tracking/WhoCanEnterExpenses.js';
import AssociateAnEmployee from './pages/working_with_expense_tracking/AssociateAnEmployee.js';
import NoExpenses from './pages/working_with_expense_tracking/NoExpenses.js';
import BillingStatus from './pages/working_with_expense_tracking/BillingStatus.js';
import TrackingReimbursibleExpenses from './pages/working_with_expense_tracking/TrackingReimbursibleExpenses.js';
import TrackingCreditCardExpenses from './pages/working_with_expense_tracking/TrackingCreditCardExpenses.js';
import PayrollWithTime from './pages/minute7_and_payroll/PayrollWithTime.js';
import AllowPayrollItems from './pages/minute7_and_payroll/AllowPayrollItems.js';
import DefaultPayrollItem from './pages/minute7_and_payroll/DefaultPayrollItem.js';
import SettingUpPayroll from './pages/setting_up_payroll/SettingUpPayroll.js';
import AddPayroll from './pages/setting_up_payroll/AddPayroll.js';
import UploadCompanyLogo from './pages/customizing_minute7/UploadCompanyLogo.js';
import ShowMostRecent from './pages/customizing_minute7/ShowMostRecent.js';
import TimeEntriesToInvoices from './pages/quickbooks_with_minute7/TimeEntriesToInvoices.js';
import MobileMinute7 from './pages/mobile_minute7/MobileMinute7.js';
import WhatIsMobileMinute7 from './pages/mobile_minute7/WhatIsMobileMinute7.js';
import WhichMobileDevices from './pages/mobile_minute7/WhichMobileDevices.js';
import SearchTab from './pages/faq_about_new_site/SearchTab';
import CompanyLogo from './pages/faq_about_new_site/CompanyLogo';
import ColorScheme from './pages/faq_about_new_site/ColorScheme';
import GroupingEntries from './pages/faq_about_new_site/GroupingEntries';
import RestartingTimers from './pages/faq_about_new_site/RestartingTimers';

/**
 * @param {boolean} isExpandedKey state value of whether the list is expanded or not
 * @param {string} label Display list label
 * @param {array} links array of objects [
 *  @param {string} title Display value for list item
 *  @param {JSX.Element} content to be displayed in modal
 *  @param {boolean} onClickOverride for help sections too big for a modal (scrolling in modals is a bad experience)
 * ]
 */
export const HelpLinks = {
    gettingStarted: {
        hash: 'getting-started',
        isExpandedKey: 'isGettingStartedOpen',
        label: 'Getting Started',
        links: [
            {
                title: 'Which versions of QuickBooks does Minute7 work with?',
                content: <QuickBooksVersion />,
                modalTitle: 'QuickBooks Version',
            },
            {
                title: 'How much does Minute7 cost?',
                content: <Minute7Cost />,
                modalTitle: 'Minute7 Pricing',
            },
            {
                title: 'What will I need during the sign up process?',
                content: <SignUp />,
                modalTitle: 'Signing Up',
            },
            {
                title: 'What if I want to deactivate my Minute7 account?',
                content: <DeactivateAccount />,
                modalTitle: 'Deactivate Account',
            },
            {
                title: 'Is my information secure and confidential?',
                content: <Minute7Secure />,
                modalTitle: 'Minute7 Security and Confidentiality',
            },
            {
                title: 'Is my data backed up?',
                content: <DataBackup />,
                modalTitle: 'Data Back Up',
            },
            {
                title: 'What do I need to do before my employees begin entering time?',
                content: <BeforeYouBegin />,
                modalTitle: 'Before You Begin',
            },
            {
                title:
                    'User guide: How to sync Minute7 with QuickBooks Desktop for the first time.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/11rUSu3PH5uJ_Hlc36ehAKElfQFWPF_z_/view?usp=sharing'
                    ),
            },
            {
                title: 'User guide: How to sync Minute7 with QuickBooks Online for the first time.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/18cHmIymq7kKKtM2vEDPsYjC37KST6Jil/view?usp=sharing'
                    ),
            },
        ],
    },
    accountAdministration: {
        isExpandedKey: 'isAccountAdministrationOpen',
        label: 'Account Administration',
        hash: 'account-administration',
        links: [
            {
                title: 'How do I update the Billing Information for our account?',
                content: <UpdateBilling />,
                modalTitle: 'Update Billing Information',
            },
            {
                title: 'I am having trouble getting into my account. How can I reset my password?',
                content: <ResetPassword />,
                modalTitle: 'Reset Password',
            },
            {
                title: 'How do I remove a user from our account',
                content: <RemoveUser />,
                modalTitle: 'Remove User',
            },
            {
                title: 'How can I delete or edit entries that have been synced with QuickBooks',
                content: <EditSyncedEntry />,
                modalTitle: 'Edit Synced Entries',
            },
            {
                title: 'User guide: How to reset your password in Minute7.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1g8yhegS_HwnWxJ7k3y3SknS1kui7OLam/view?usp=sharing'
                    ),
            },
            {
                title:
                    'User guide: How to change the manager of your Minute7 account, using QuickBooks Desktop.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1_FT4MwRKGDCuPin8BCG9U1XpEzNVYcWn/view?usp=sharing'
                    ),
            },
            {
                title:
                    'User guide: How to change the manager of your Minute7 account, using QuickBooks Online.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/184ouXUK_Oi7PzUq9b35YWIxGWnt7QfHI/view?usp=sharing'
                    ),
            },
            {
                title: 'User guide: How to change your email and password in Minute7.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1UwMN9z4Pgyrg0ptQ8EqcPbEPdEbMsvoC/view?usp=sharing'
                    ),
            },
        ],
    },
    syncingWithQuickBooksDesktop: {
        isExpandedKey: 'isSyncingWithQBDOpen',
        label: 'Syncing With QuickBooks Desktop',
        hash: 'syncing-with-qbd',
        links: [
            {
                title: 'What is QuickBooks Web Connector?',
                onClickOverride: history =>
                    history.push('/help/web_connector#webConnector', { scrollTo: 'webConnector' }),
            },
            {
                title: 'How do I set up QuickBooks Web Connector to sync with Minute7?',
                onClickOverride: history =>
                    history.push('/help/web_connector#installWebConnector', {
                        scrollTo: 'installWebConnector',
                    }),
            },
            {
                title: 'How do I get my QuickBooks company information into Minute7?',
                onClickOverride: history =>
                    history.push('/help/web_connector#minute7Syncing', {
                        scrollTo: 'minute7Syncing',
                    }),
            },
            {
                title: 'How do I get my timesheets and time entries into QuickBooks?',
                onClickOverride: history =>
                    history.push('/help/web_connector#minute7Syncing', {
                        scrollTo: 'minute7Syncing',
                    }),
            },
            {
                title: 'Can I automatically sync Minute7 and QuickBooks at specific intervals?',
                onClickOverride: history =>
                    history.push('/help/web_connector#minute7Syncing', {
                        scrollTo: 'minute7Syncing',
                    }),
            },
            {
                title: 'What version of QuickBooks works with the web connector?',
                onClickOverride: history =>
                    history.push('/help/web_connector#qwcVersion', { scrollTo: 'qwcVersion' }),
            },
            {
                title:
                    'User guide: How to sync Minute7 with QuickBooks Desktop for the first time.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1AJl8wPX3c0JLz6EqujW6Q90gdcUviqLm/view?usp=sharing'
                    ),
            },
        ],
    },
    syncingWithQuickBooksOnline: {
        isExpandedKey: 'isSyncingWithQBOOpen',
        label: 'Syncing With QuickBooks Online',
        hash: 'syncing-with-qbo',
        links: [
            {
                title: 'Can I use Minute7 if my company uses QuickBooks Online Edition?',
                content: <Minute7WithOnlineEdition />,
                modalTitle: 'Minute7 With QuickBooks Online',
            },
            {
                title: 'What versions of QuickBooks Online Edition will work with Minute7?',
                content: <QBOVersion />,
                modalTitle: 'Minute7 With QuickBooks Online',
            },
            {
                title: 'How do I set up my Minute7 account to sync with QuickBooks Online Edition?',
                onClickOverride: history => history.push('/help/set_up_qbo'),
            },
            {
                title:
                    'When I sync, I get the error message, "The record of Minute7 connection in QuickBooks Online couldn\'t not be found..."',
                content: <ConnectionNotFound />,
                modalTitle: 'QuickBooks Online Connection Error',
            },
            {
                title:
                    'When I sync, I get the error message, "QuickBooks Online could not add time and expense entries because your QuickBooks Online Account does not..."',
                content: <CouldNotAddError />,
                modalTitle: 'QuickBooks Online Time And Expense Tracking Enabled',
            },
            {
                title:
                    'User guide: How to sync Minute7 with QuickBooks Online for the first time."',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/159KfMW4G6XfAO6j1c4yIiw3v64nAoa0J/view?usp=sharing'
                    ),
            },
        ],
    },
    otherQuickBooksQuestions: {
        isExpandedKey: 'isSyncingWithQBQuestions',
        label: 'Other QuickBooks Sync Questions',
        hash: 'syncing-with-qb-questions',
        links: [
            {
                title: 'What will Minute7 change in my QuickBooks file?',
                content: <QuickBooksFile />,
                modalTitle: 'Minute7 and your QuickBooks File',
            },
            {
                title:
                    "I've synced with Minute7 with QuickBooks, but Minute7 still tells me I need to sync before I can start using it.",
                content: <QBSyncBeforeUsing />,
                modalTitle: 'Sync Before Using Minute7',
            },
            {
                title:
                    "I've updated my employees, customers, etc in QuickBooks, but I'm not ready to export time and expense entries yet.",
                content: <SyncWithoutExporting />,
                modalTitle: 'Sync Without Exporting',
            },
            {
                title: 'How do I change my security settings for Minute7 in QuickBooks?',
                content: <Minute7Security />,
                modalTitle: 'Minute7 Security In QuickBooks',
            },
            {
                title: 'Can I edit entries that have already been synced?',
                content: <EditSyncedEntries />,
                modalTitle: 'Edit Synced Entries',
            },
            {
                title:
                    'How do I change the number of hours Minute7 waits after an entry is created before exporting it?',
                content: <ExportWaitTime />,
                modalTitle: 'Change Number of Hours Before Exporting',
            },
        ],
    },
    commonQuickBooksWebConnectorSyncErrors: {
        isExpandedKey: 'isCommonWebConnectorOpen',
        label: 'Common QuickBooks Web Connector Sync Errors',
        hash: 'common-web-connector-errors',
        links: [
            {
                title:
                    'Error: QuickBooks is having trouble determining the payroll status for on of your employees',
                content: <UnknownPayrollInfo />,
                modalTitle: 'Payroll Status For One of Your Employees',
            },
            {
                title:
                    'Error: QuickBooks is having trouble finding payroll information for on of your employees who has "Use time data to create paychecks" enabled',
                content: <MissingPayrollInfo />,
                modalTitle: 'Use Time Data to Create Paychecks',
            },
            {
                title:
                    'Error: QuickBooks could not add time and expense entries because your QuickBooks company file does not have time tracking enabled',
                content: <EnableTimeTracking />,
                modalTitle: 'Enable Time Tracking',
            },
            {
                title: 'What should I do if I get a QuickBooks Web Connector error "QBWC1085"',
                content: <QBWC1085 />,
                modalTitle: 'Error: QWC1085',
            },
        ],
    },
    peopleAndPermissions: {
        isExpandedKey: 'isPeopleAndPermissionsOpen',
        label: 'People and Permissions',
        hash: 'people-and-permissions',
        links: [
            {
                title: 'How do permissions work?',
                onClickOverride: history => history.push('/help/how_do_permissions_work'),
            },
            {
                title: "What happens if I don't associate a user with an employee?",
                content: <NotAssociatedEmployee />,
                modalTitle: 'Employee Association',
            },
            {
                title: 'Can I limit which "Customer: Jobs" a user can see?',
                content: <LimitCustomerJobs />,
                modalTitle: 'Limiting Customer: Jobs',
            },
            {
                title: 'Can I limit which of my "Service Items" a user can see?',
                content: <LimitServiceItems />,
                modalTitle: 'Limiting Service Items',
            },
            {
                title: 'Can I change the permissions of the account manager?',
                content: <AccountManagerPermissions />,
                modalTitle: 'Account Manager Permissions',
            },
            {
                title: "Why can't I see all of my employees in the drop-down list",
                content: <CannotSeeAllEmployees />,
                modalTitle: 'Hidden Employees',
            },
            {
                title: 'Can I have users and time entries associated with vendors?',
                content: <VendorAssociation />,
                modalTitle: 'Vendor Association',
            },
            {
                title: 'What is an"Associated Vendor for expense tracking in QuickBooks?"',
                content: <WhatIsAssociatedVendor />,
                modalTitle: 'Associated Vendor for Expense Tracking',
            },
            {
                title: 'User guide: How to Set Up Restrictions for Users.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1ltBjR5PirxWmq_n9V0zIHTmfCJ7L1OBo/view?usp=sharing'
                    ),
            },
        ],
    },
    workingWithTimesheets: {
        isExpandedKey: 'isWorkingWithTimesheetsOpen',
        label: 'Working With Timesheets',
        hash: 'working-with-timesheets',
        links: [
            {
                title: 'What do I need to do before my employees begin entering time?',
                content: <BeforeYouBegin />,
                modalTitle: 'Before You Begin Entering Time',
            },
            {
                title: "I don't see any of my employees or customers",
                content: <DoNotSeeEmployeesOrCustomers />,
                modalTitle: "I don't see any of my employees or customers",
            },
            {
                title: 'What are "approved" and "disapproved" time entries?',
                content: <ApprovedAndDisapproved />,
                modalTitle: 'Approved and Disapproved Time Entries',
            },
            {
                title: 'How can I enter billable and non billable time',
                content: <BillableAndUnbillableTime />,
                modalTitle: 'Billable and Non billable Time Entries',
            },
            {
                title: 'How can I assign QuickBooks classes to my time entries',
                content: <QbClassesTime />,
                modalTitle: 'QuickBook Classes and Time Entries',
            },
            {
                title: 'User guide: How to enter and track time with Minute7.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1wDPdgY_3JQpsyIDraqdykXWGRkXBaiQX/view?usp=sharing'
                    ),
            },
            {
                title:
                    'User guide: How to enter a 1099 into QuickBooks Online and track time in Minute7.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/171C8yZwR4AaHVRZel0L87DuvE1J3g9K2/view?usp=sharing'
                    ),
            },
            {
                title:
                    'User guide: How to enter a 1099 into QuickBooks Desktop and track time in Minute7.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1dAocDQ79yHgEWAHptq0nd2_jMatyqXm4/view?usp=sharing'
                    ),
            },
        ],
    },
    workingWithExpenseTracking: {
        isExpandedKey: 'isWorkingWithExpensesOpen',
        label: 'Working With Expense Tracking',
        hash: 'working-with-expenses',
        links: [
            {
                title: 'What are expenses? How do they work in QuickBooks?',
                content: <WhatAreExpenses />,
                modalTitle: 'Expense Tracking',
            },
            {
                title: 'Who can enter expenses?',
                content: <WhoCanEnterExpenses />,
                modalTitle: 'Who Can Enter Expenses',
            },
            {
                title:
                    'Why would I associate an Employee with an "Associated Vendor for expense tracking in QuickBooks"',
                content: <AssociateAnEmployee />,
                modalTitle: 'Associate An Employee With a Vendor',
            },
            {
                title: "What if I don't want to track expenses?",
                content: <NoExpenses />,
                modalTitle: 'Turn Off Expense Tracking',
            },
            {
                title:
                    'The billing status of my expenses is not always showing up in QuickBooks. Why Not?',
                content: <BillingStatus />,
                modalTitle: 'Expense Billing Status',
            },
            {
                title: 'User guide: How to enter and track expenses in Minute7.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1uP8qcrrezpzqx8BINpk2OiXFxBz-PeJk/view?usp=sharing'
                    ),
            },
            {
                title: 'How to track your Reimbursable Expenses with Minute 7?',
                content: <TrackingReimbursibleExpenses />,
                modalTitle: 'Reimbursible Expenses',
            },
            {
                title: 'How to track your Credit Card Expenses with Minute 7?',
                content: <TrackingCreditCardExpenses />,
                modalTitle: 'Credit Card Expenses',
            },
        ],
    },
    minute7AndPayroll: {
        isExpandedKey: 'isPayrollOpen',
        label: 'Minute7 and Payroll',
        hash: 'payroll',
        links: [
            {
                title: 'Can Minute7 associate QuickBooks payroll items with time entries',
                content: <PayrollWithTime />,
                modalTitle: 'Payroll Items With Time Entries',
            },
            {
                title: 'How do I allow my employees to edit payroll items for time entries',
                content: <AllowPayrollItems />,
                modalTitle: 'Allow Payroll Item Association',
            },
            {
                title:
                    'How do I set up the default payroll item that is used when an employee creates a time entry in Minute7',
                content: <DefaultPayrollItem />,
                modalTitle: 'Default Payroll Item',
            },
            {
                title:
                    'User guide: How to add Payroll items to Minute7 and make a Default Payroll for a User',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1Vu5Xvx6wIk0e7zvO43LJPVMuh0HnELiV/view?usp=sharing'
                    ),
            },
        ],
    },
    settingUpPayrollItemsInQuickBooks: {
        isExpandedKey: 'isSettingUpPayrollItemsOpen',
        label: 'Setting Up Payroll Items In QuickBooks',
        hash: 'setting-up-payroll-items',
        links: [
            {
                title:
                    'How do I set up an Employee to use time data to create paychecks in QuickBooks',
                content: <SettingUpPayroll />,
                modalTitle: 'Setting Up Payroll',
            },
            {
                title:
                    'When I select an Employee for a Minute7 user, I see a note that says "QuickBooks does not know the payroll status of this employee." What does this mean?',
                content: <UnknownPayrollInfo />,
                modalTitle: 'Unknown Payroll Status',
            },
            {
                title: 'How do I add payroll items and rates for an Employee in QuickBooks',
                content: <AddPayroll />,
                modalTitle: 'Add Rate For Payroll Item',
            },
            {
                title: 'User guide: How to add a payroll item into QuickBooks Desktop and Minute7',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1kbQHdJ-MtpCexQBNp1Q1SqBvivP2S56i/view?usp=sharing'
                    ),
            },
        ],
    },
    mobileMinute7: {
        isExpandedKey: 'isMobileMinute7Open',
        label: 'Mobile Minute7',
        hash: 'mobile-minute7',
        links: [
            {
                title: 'Can I use Minute7 on my mobile phone?',
                content: <MobileMinute7 />,
                modalTitle: 'Mobile Minute7',
            },
            {
                title: 'What is Mobile Minute7? How do I use it?',
                content: <WhatIsMobileMinute7 />,
                modalTitle: 'Mobile Minute7',
            },
            {
                title: 'Which mobile phones support Mobile Minute7? Will it work on my phone?',
                content: <WhichMobileDevices />,
                modalTitle: 'Mobile Devices',
            },
        ],
    },
    customizingMinute7ForYourCompany: {
        isExpandedKey: 'isCustomizingMinute7Open',
        label: 'Customizing Minute7',
        hash: 'customizing-minute7',
        links: [
            {
                title: 'Uploading your company logo',
                content: <UploadCompanyLogo />,
                modalTitle: 'Uploading Company Logo',
            },
            {
                title: 'What does "Show most recent time entry on bottom/on top" mean?',
                content: <ShowMostRecent />,
                modalTitle: 'Show most recent time entry on bottom/on top',
            },
            {
                title: 'User guide: How to assign classes in Minute7',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1TrDJyh-jbCy9mWBEgoXzgjPlbXgH7YVo/view?usp=sharing'
                    ),
            },
            {
                title: 'User guide: How to batch process time and expense entries in Minute7.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1SnXg_fzgOxY22N1IiZa6Cod74N2hniaC/view?usp=sharing'
                    ),
            },
            {
                title: 'User guide: How to mark an entry as billable or non billable in Minute7.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1xR0c77RHcCgIwrAhFC4prOg3-_1shimN/view?usp=sharing'
                    ),
            },
        ],
    },
    usingQuickBooksWithMinute7: {
        isExpandedKey: 'isUsingQuickBooksWithMinute7Open',
        label: 'Using QuickBooks With Minute7',
        hash: 'using-quickbooks-with-minute7',
        links: [
            {
                title: 'How do I transfer time entries to invoices?',
                content: <TimeEntriesToInvoices />,
                modalTitle: 'Time Entries to Invoices',
            },
            {
                title:
                    'User guide: How to enter a service item into QuickBooks Desktop and Minute7',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1E0RLlvrEJFPRJEyMUkGm_xGs7BZW2tWW/view?usp=sharing'
                    ),
            },
            {
                title:
                    'User guide: How to Associate a Minute7 User With a QuickBooks Employee or Vendor.',
                onClickOverride: () =>
                    window.open(
                        'https://drive.google.com/file/d/1mdCtjb4cHeyL8HZlpye3nfa21zW7ecOW/view?usp=sharing'
                    ),
            },
        ],
    },
    faqAboutNewSite: {
        isExpandedKey: 'isFAQAboutNewSiteOpen',
        label: 'FAQ About New Site',
        hash: 'faq-new-site',
        links: [
            {
                title: 'What happened to the Search tab?',
                content: <SearchTab />,
                modalTitle: 'Search Tab',
            },
            {
                title:
                    'How do I control whether my time entries are grouped by week or shown individually?',
                content: <GroupingEntries />,
                modalTitle: 'Grouping Time Entries',
            },
            {
                title: 'How do I restart a timer in the new site?',
                content: <RestartingTimers />,
                modalTitle: 'Restarting Timers',
            },
            {
                title: 'My company logo now looks different or strange.  What happened?',
                content: <CompanyLogo />,
                modalTitle: 'Company Logo',
            },
            {
                title: 'Why is the color scheme different?',
                content: <ColorScheme />,
                modalTitle: 'Color Scheme',
            },
        ],
    },
};
