import React from 'react';

const SignUp = () => (
    <div>
        <div className="sectionQuestion">What will I need during the sign up process?</div>
        <div className="sectionAnswer">
            All you need during the sign up process is your name, email address and a password. You
            can update your user account and company account at any time. Don't worry — we would
            never sell, rent or otherwise distribute your personal information or send you
            unsolicited email
        </div>
        <div className="sectionButton">
            <button
                className="secondaryButton sectionFont"
                onClick={() => (window.location.href = '/free_trial')}
            >
                Start Your Free Trial
            </button>
        </div>
    </div>
);

export default SignUp;
