export const INITIAL_STATE = {
    canViewEmployees: [],
    canEditEmployees: [],
    allEmployees: [],
};

export const employeeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'RECEIVE_BUSINESS_RESOURCES': {
            if ('canViewEmployees' in action.payload || 'canEditEmployees' in action.payload || 'allEmployees' in action.payload) {
                const newState = { ...state };
                ['canViewEmployees', 'canEditEmployees', 'allEmployees'].forEach(k => {
                    if (k in action.payload) {
                        newState[k] = action.payload[k];
                    }
                });
                return newState;
            } else {
                return state;
            }
        }
        case 'SET_CAN_VIEW_EMPLOYEES':
            return {
                ...state,
                canViewEmployees: action.payload,
            };
        case 'SET_CAN_EDIT_EMPLOYEES':
            return {
                ...state,
                canEditEmployees: action.payload,
            };
        case 'SET_ALL_EMPLOYEES':
            return {
                ...state,
                allEmployees: action.payload,
            };
        case 'ADD_EMPLOYEE':
            return {
                ...state,
                isSaving: true,
            };
        case 'ADD_EMPLOYEE_SUCCESS':
            return {
                ...state,
                isSaving: false,
            };
        case 'ADD_EMPLOYEE_ERROR':
            return {
                ...state,
                isSaving: false,
            };
        case 'LOGOUT':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default employeeReducer;
