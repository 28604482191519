import _ from 'lodash';
import React, { Component } from 'react';
import { sendMessage } from '../../requests/ContactUsRequests.js';
import { isNameValid, isValidEmail, isValidPhone } from '../../utils/CommonFunctions.js';
import scrollTo from '../../utils/scrollTo';
import { connect } from 'react-redux';
import { showToast } from '../../actions/Actions.js';
import '../../css/ContactUs.css';
import { emailType, supportedUploadFileTypes } from '../../config/Fields.js';
import { isMobile } from 'react-device-detect';
import MobileFreeTrial from '../free_trial/components/MobileFreeTrial';
import ContactUsDefault from './components/ContactUsDefault';
import ContactUsMobile from "./components/ContactUsMobile";

const supportedUploadFileTypesString = 'png, jpeg, jpg, gif, and pdf';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: _.get(this, 'props.accountUserStore.first_name', ''),
            last_name: _.get(this, 'props.accountUserStore.last_name', ''),
            name: _.get(this, 'props.accountStore.name', ''),
            email: _.get(this, 'props.accountUserStore.email', ''),
            phone: _.get(this, 'props.accountUserStore.phone', ''),
            message: '',
            attachments: [],
            isSendingMessage: false,
            subject: emailType.support,
        };
    }

    componentDidMount() {
        scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    /**
     * Cleaner passing as a prop
     */
    handleOnChanges = {
        handleFirstNameOnChange: newName => {
            this.setState({ first_name: newName.target.value });
        },
        handleLastNameOnChange: newName => {
            this.setState({ last_name: newName.target.value });
        },
        handleEmailOnChange: newEmail => {
            this.setState({ email: newEmail.target.value });
        },
        handlePhoneOnChange: newPhone => {
            this.setState({ phone: newPhone });
        },
        handleNameOnChange: newName => {
            this.setState({ name: newName.target.value });
        },
        handleMessageChanged: newMessage => {
            this.setState({ message: newMessage.target.value });
        },
    };

    checkRequiredFields = () => {
        const { email, first_name, last_name, phone, message } = this.state;

        if (
            !isValidEmail(email) ||
            !isNameValid(first_name) ||
            !isNameValid(last_name) ||
            !isValidPhone(phone)
        ) {
            this.props.showToast('Make sure all fields are filled out correctly', 'warning');
            return false;
        } else if (message === '') {
            this.props.showToast('Please enter message', 'warning');
            return false;
        }
        return true;
    };

    constructParamsObject = () => {
        let data = {};
        data['account_user'] = this.state;
        data['account'] = this.props.accountStore;
        data['message'] = this.state.message;
        data['new_attachments'] = this.state.attachments;
        data['subject'] = this.state.subject;
        return data;
    };

    sendMessage = () => {
        const { isLoggedIn } = this.props;
        if (!this.checkRequiredFields()) {
            return;
        }
        const paramsObject = this.constructParamsObject();
        this.setState({ isSendingMessage: true });
        sendMessage(paramsObject)
            .then(res => {
                this.setState({ message: '', attachments: [], isSendingMessage: false });
                if (!isLoggedIn) {
                    this.setState({
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        name: '',
                        subject: emailType.support,
                    });
                }
                this.props.history.push('/email_sent_success');
            })
            .catch(() => {
                this.props.showToast('Couldn’t send email', 'warning');
            });
    };

    handleFilesUploaded = files => {
        if (this.state.attachments.length + Object.keys(files).length > 3) {
            this.props.showToast('You can’t add more than 3 attachments', 'negative');
            return;
        }

        const fileOnLoad = (fileProperties, file) => {
            if (supportedUploadFileTypes.includes(fileProperties.type)) {
                const id = this.getUniqueAttachmentKey();

                const attachment = {
                    id,
                    path: file.target.result,
                    name: fileProperties.name,
                    type: fileProperties.type,
                    size: fileProperties.size,
                };

                this.setState(prevState => ({
                    attachments: [...prevState.attachments, attachment],
                }));
            } else {
                this.props.showToast(
                    `Files of ${
                        fileProperties.type ? `type ${fileProperties.type}` : 'this type'
                    } aren’t supported. Supported file types are ${supportedUploadFileTypesString}`,
                    'negative'
                );
            }
        };

        for (let key in files) {
            if (!isNaN(key)) {
                const reader = new FileReader();
                reader.readAsDataURL(files[key]);
                reader.onload = fileOnLoad.bind(this, files[key]);
            }
        }
    };

    getUniqueAttachmentKey = () => {
        let key = Math.random() * 1000;
        while (key in Object.keys(this.state.attachments)) {
            key = Math.random() * 1000;
        }
        return key;
    };

    handleRemoveAttachmentPressed = id => {
        const attachments = this.state.attachments.filter(attachment => attachment.id !== id);
        this.setState({ attachments });
    };

    render() {
        if (isMobile) {
            return (
                <ContactUsMobile
                    {...this.state}
                    {...this.handleOnChanges}
                    history={this.props.history}
                    sendMessage={this.sendMessage}
                    handleFilesUploaded={this.handleFilesUploaded}
                    handleRemoveAttachmentPressed={this.handleRemoveAttachmentPressed}
                />
            );
        } else {
            return (
                <ContactUsDefault
                    {...this.state}
                    {...this.handleOnChanges}
                    history={this.props.history}
                    sendMessage={this.sendMessage}
                    handleFilesUploaded={this.handleFilesUploaded}
                    handleRemoveAttachmentPressed={this.handleRemoveAttachmentPressed}
                />
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        accountStore: state.accountStore,
        accountUserStore: state.accountUserStore,
        isLoggedIn: !_.isNil(state.appStore.session_id),
    };
}

export default connect(
    mapStateToProps,
    { showToast }
)(ContactUs);
