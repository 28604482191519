import React, { Component } from 'react';
import '../../css/FooterBar.css';

const VIDEO_URL = 'https://www.youtube.com/channel/UC_v5ATGGZJvx3mVe7TMdsXg/videos';

export default class FooterBar extends Component {
    redirect = to => {
        this.props.history.push(to);
    };

    openVideosTab = () => {
        window.open(VIDEO_URL, '_blank');
    };

currentYear = () => {
    return new Date().getFullYear()
}

    render() {
        return (
            <div className="fbTableContainer">
                <div className="fbSeparator" />
                <table>
                    <tbody>
                        <tr className="fbLinkRow">
                            <td onClick={() => this.redirect('/')}>Home</td>
                            <td onClick={() => this.redirect('/help')}>Help &amp; Support</td>
                            <td onClick={() => this.redirect('/contact')}>Contact Us</td>
                            <td onClick={() => this.redirect('/terms')}>Terms of Service</td>
                            <td onClick={() => this.redirect('/privacy')}>Privacy Policy</td>
                            <td onClick={this.openVideosTab}>Video Tutorials</td>
                        </tr>
                        <tr>
                            <td className="fbMinute7Details" colSpan="6">
                                <div>
                                    &copy; {this.currentYear()} Minute7 LLC | All rights reserved
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
