import React from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { deactivateAccount } from '../../actions/AccountActions';
import styles from '../../css/DeactivateAccount.module.css';

const DeactivateAccount = ({ deactivateAccount }) => {
    return (
        <div className="application">
            <DocumentTitle title="Deactivate Your Account" />

            <div className="applicationBody">
                <div className={classNames('cardContainer', 'cardContent', styles.container)}>
                    <h2 className="heading">Deactivate Your Account</h2>
                    <div className={styles.details}>
                        <p>
                            If you would like to deactivate your account, you may do so by pressing
                            the button below. If, however, there is anything we can help you with
                            that might change your mind, please{' '}
                            <Link to="/contact">contact us</Link>, and we will be happy to work with
                            you to resolve any issues.
                        </p>
                        <p>
                            Once you deactivate your account, you will no longer have access to any
                            of the billing or time entry features of Minute7, but all your data and
                            settings will be waiting for you if you decide to reactivate your
                            account. For your future reference, you may reactivate your account at
                            any time by{' '}
                            <a href="https://frontend.minute7.com/login">
                                signing into your account
                            </a>{' '}
                            and following the prompts.
                        </p>
                    </div>

                    <button className="destructiveButton" onClick={deactivateAccount}>
                        Deactivate Account
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    deactivateAccount,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeactivateAccount);
