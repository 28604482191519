import _ from 'lodash';
import React from 'react';

const IMAGES_BASE_URL = process.env.REACT_APP_S3_BUCKET_URL;

const CompanyLogo = props => {
    let logo = props.logo;

    if (!_.isNil(logo) && logo !== '') {
        logo = { src: `${IMAGES_BASE_URL}/${logo}` };
    } else {
        logo = {
            src: '/minute7-logo.png',
            srcSet: '/minute7-logo.png 1x, /minute7-logo@2x.png 2x, /minute7-logo@3x.png 3x',
        };
    }

    return (
        <div className="hbLogoContainer">
            <img alt="Company Logo" {...logo} className="hbLogo" />
        </div>
    );
};

export default CompanyLogo;
