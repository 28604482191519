import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';

export const getAllCustomers = async (paramsObject = {}) => {
    const { Customers, meta } = await getData(paramsObject, 'v1/customers');
    let customerList = Customers.map(item => {
        return item.Customer;
    });
    return {
        customerList,
        meta,
    };
};

export const getAllCustomersForTable = async (paramsObject = {}) => {
    const { Customers, meta } = await getData(paramsObject, 'v1/customers/paginated');
    let customerList = Customers.map(item => {
        return item.Customer;
    });
    return {
        customerList,
        meta,
    };
};

export const createNewCustomer = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(data, 'v1/customers/', paramsObject);
    return res.Customer;
};

export const updateExistingCustomer = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        data,
        'v1/customers/' + data['Customer[id]'],
        paramsObject
    );
    return res.Customer;
};

export const createNewCustomerThroughCsv = async data => {
    try {
        const res = await postRequestWithFormData(
            data,
            'v1/customers/create_customer_through_csv',
            {
                'Content-Type': 'multipart/form-data',
            }
        );
        return res.Customers;
    } catch (err) {
        alert(JSON.stringify(err.message));
    }
};

export const activateCustomer = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/customers/' + id + '/activate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.Employee;
};

export const deactivateCustomer = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/customers/' + id + '/deactivate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.Employee;
};
