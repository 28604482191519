const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

const scrollTo = ({ left, top, behavior }) => {
    if (isSmoothScrollSupported) {
        window.scrollTo({
            top,
            left,
            behavior,
        });
    } else {
        window.scrollTo(left, top);
    }
};

export default scrollTo;
