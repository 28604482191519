import React from 'react';

const TimeEntriesToInvoices = () => (
    <div>
        <div className="sectionQuestion">How do I transfer time entries to invoices?</div>
        <div className="sectionAnswer">
            After syncing Minute7 with QuickBooks, transferring the time entries you have created
            using Minute7 onto invoices for clients can be accomplished in a number of ways. One of
            the simplest is to use the QuickBooks Time/Costs console as follows:
            <ol>
                <li>
                    Go to Customers > Create Invoices in the main menubar to open the main Invoicing
                    screen.
                </li>
                <li>Select the Customer you want to invoice</li>
                <li>At the bottom of the Invoice window, select "Add Time/Costs".</li>
                <li>
                    A window will pop up with the time and cost entries for the customer. Select the
                    Time tab
                </li>
                <li>
                    Click "Select All" to approve all time entries for billing, or check and uncheck
                    entries if you only want to charge the customer for some of them.
                </li>
                <li>
                    Click "OK". The checked time entries will all appear in the Invoice line item
                    listing.
                </li>
                <li>Continue invoicing as you would normally.</li>
            </ol>
            Do you have a method of invoicing that you find easier? We're always interested in
            hearing about how you use Minute7— so let us know what process works best for you!
        </div>
    </div>
);

export default TimeEntriesToInvoices;
