/*
 * Expense Entry Fields
 */
export const setStartDate = payload => ({
    type: 'SET_EXPENSES_START_DATE',
    payload,
});

export const setEndDate = payload => ({
    type: 'SET_EXPENSES_END_DATE',
    payload,
});

export const setCustomerId = payload => ({
    type: 'SET_EXPENSES_CUSTOMER_ID',
    payload,
});

export const setVendorId = payload => ({
    type: 'SET_EXPENSES_VENDOR_ID',
    payload,
});

export const setExpenseableId = payload => ({
    type: 'SET_EXPENSEABLE_ID',
    payload,
});

export const setQbClassId = payload => ({
    type: 'SET_EXPENSES_QB_CLASS_ID',
    payload,
});

export const setExpenseGroupingId = payload => ({
    type: 'SET_EXPENSES_EXPENSE_GROUPING_ID',
    payload,
});

export const setPaymentAccountId = payload => ({
    type: 'SET_EXPENSES_PAYMENT_ACCOUNT_ID',
    payload,
});

export const setDate = payload => ({
    type: 'SET_EXPENSES_DATE',
    payload,
});

export const setAmount = payload => ({
    type: 'SET_EXPENSES_AMOUNT',
    payload,
});

export const setAttachments = payload => ({
    type: 'SET_EXPENSES_ATTACHMENTS',
    payload,
});

export const setDeletedAttachments = payload => ({
    type: 'SET_DELETED_ATTACHMENTS',
    payload,
});

export const setDescription = payload => ({
    type: 'SET_EXPENSES_DESCRIPTION',
    payload,
});

export const setReimbursementRate = payload => ({
    type: 'SET_EXPENSES_REIMBURSEMENT_RATE',
    payload,
});

export const setMiles = payload => ({
    type: 'SET_EXPENSES_MILES',
    payload,
});

export const setBillable = payload => ({
    type: 'SET_EXPENSES_BILLABLE',
    payload,
});

/**
 * Expense Filters
 */
export const setSearchText = payload => ({
    type: 'SET_EXPENSES_SEARCH_TEXT',
    payload,
});

export const setSearchVendorIds = payload => ({
    type: 'SET_EXPENSES_SEARCH_VENDOR_IDS',
    payload,
});

export const setSearchCustomerIds = payload => ({
    type: 'SET_EXPENSES_SEARCH_CUSTOMER_IDS',
    payload,
});

export const setSearchQbClassIds = payload => ({
    type: 'SET_EXPENSES_SEARCH_QB_CLASS_IDS',
    payload,
});

export const setSearchQbAccountIds = payload => ({
    type: 'SET_EXPENSES_SEARCH_QB_ACCOUNT_IDS',
    payload,
});

export const setSearchInventoryItemIds = payload => ({
    type: 'SET_EXPENSES_SEARCH_INVENTORY_ITEM_IDS',
    payload,
});

export const setSearchExpenseGroupingIds = payload => ({
    type: 'SET_EXPENSES_SEARCH_EXPENSE_GROUPING_IDS',
    payload,
});

export const setApprovedFilter = payload => ({
    type: 'SET_EXPENSES_APPROVED_FILTER',
    payload,
});
export const setLockedFilter = payload => ({
    type: 'SET_EXPENSES_LOCKED_FILTER',
    payload,
});
export const setBillableFilter = payload => ({
    type: 'SET_EXPENSES_BILLABLE_FILTER',
    payload,
});

export const setExportedFilter = payload => ({
    type: 'SET_EXPENSES_EXPORTED_FILTER',
    payload,
});

export const setExpenseTypeFilter = payload => ({
    type: 'SET_EXPENSE_TYPE_FILTER',
    payload,
});

export const setAttachmentFilter = payload => ({
    type: 'SET_EXPENSES_ATTACHMENT_FILTER',
    payload,
});

export const setCurrentPage = payload => ({
    type: 'SET_EXPENSES_CURRENT_PAGE',
    payload,
});

export const setTotalExpenseEntries = payload => ({
    type: 'SET_EXPENSES_TOTAL_EXPENSE_ENTRIES',
    payload,
});

export const setActiveFilters = payload => ({
    type: 'SET_EXPENSES_ACTIVE_FILTERS',
    payload,
});

export const setFilterState = payload => ({
    type: 'SET_EXPENSES_FILTER_STATE',
    payload,
});

/**
 * Edit/Duplicate expense entry actions
 */
export const setEditExpenseStore = payload => ({
    type: 'SET_EDIT_EXPENSE_STORE',
    payload,
});

export const setEditCustomerId = payload => ({
    type: 'SET_EXPENSES_EDIT_CUSTOMER_ID',
    payload,
});

export const setEditVendorId = payload => ({
    type: 'SET_EXPENSES_EDIT_VENDOR_ID',
    payload,
});

export const setEditExpenseableId = payload => ({
    type: 'SET_EXPENSE_EDIT_EXPENSEABLE_ID',
    payload,
});

export const setEditQbClassId = payload => ({
    type: 'SET_EXPENSES_EDIT_QB_CLASS_ID',
    payload,
});

export const setEditExpenseGroupingId = payload => ({
    type: 'SET_EXPENSES_EDIT_EXPENSE_GROUPING_ID',
    payload,
});

export const setEditPaymentAccountId = payload => ({
    type: 'SET_EXPENSES_EDIT_PAYMENT_ACCOUNT_ID',
    payload,
});

export const setEditDate = payload => ({
    type: 'SET_EXPENSES_EDIT_DATE',
    payload,
});

export const setEditAmount = payload => ({
    type: 'SET_EXPENSES_EDIT_AMOUNT',
    payload,
});

export const setEditAttachments = payload => ({
    type: 'SET_EXPENSES_EDIT_ATTACHMENTS',
    payload,
});

export const setEditDeletedAttachments = payload => ({
    type: 'SET_EXPENSES_EDIT_DELETED_ATTACHMENTS',
    payload,
});

export const setEditDescription = payload => ({
    type: 'SET_EXPENSES_EDIT_DESCRIPTION',
    payload,
});

export const setEditReimbursementRate = payload => ({
    type: 'SET_EXPENSES_EDIT_REIMBURSEMENT_RATE',
    payload,
});

export const setEditMiles = payload => ({
    type: 'SET_EXPENSES_EDIT_MILES',
    payload,
});

export const setEditBillable = payload => ({
    type: 'SET_EXPENSES_EDIT_BILLABLE',
    payload,
});

/*
 * Misc
 */
export const setIsSaving = payload => ({
    type: 'SET_EXPENSES_IS_SAVING',
    payload,
});

export const setIsNewEntryModalOpen = payload => ({
    type: 'SET_EXPENSES_IS_NEW_ENTRY_MODAL_OPEN',
    payload,
});

export const setIsEditEntryModalOpen = payload => ({
    type: 'SET_EXPENSES_IS_EDIT_ENTRY_MODAL_OPEN',
    payload,
});

export const setIsNewEntryCollapsed = payload => ({
    type: 'SET_EXPENSES_IS_NEW_ENTRY_COLLAPSED',
    payload,
});

export const setSelectedEntryIds = payload => ({
    type: 'SET_EXPENSES_SELECTED_ENTRY_IDS',
    payload,
});

export const setEntriesAvailableForEdit = payload => ({
    type: 'SET_EXPENSES_ENTRIES_AVAILABLE_FOR_EDIT',
    payload,
});

export const setLoading = payload => ({
    type: 'SET_EXPENSES_LOADING',
    payload,
});

export const resetExpenseForm = () => ({
    type: 'RESET_EXPENSES_FORM',
});

export const selectExpenseEntry = (expenseEntry, { duplicate }) => ({
    type: 'SELECT_EXPENSE_ENTRY',
    payload: {
        selectedExpenseEntry: expenseEntry,
        duplicate,
    },
});

export const setDefaultsForNewExpenseEntries = defaults => ({
    type: 'SET_DEFAULTS_FOR_NEW_EXPENSE_ENTRIES',
    defaults,
});

/*
 * Get Expenses
 */

export const requestExpenses = ({ showLoading, queryParams }) => ({
    type: 'REQUEST_EXPENSES',
    showLoading,
    queryParams,
});

export const receiveExpenses = (expenses, total) => ({
    type: 'RECEIVE_EXPENSES',
    expenses,
    total,
});

export const requestExpensesError = error => ({
    type: 'REQUEST_EXPENSES_ERROR',
    error,
});

/* Delete expense entries */
export const deleteExpenseEntries = ids => ({
    type: 'DELETE_EXPENSE_ENTRIES',
    ids,
});

export const deleteExpenseEntriesError = error => ({
    type: 'DELETE_EXPENSE_ENTRIES_ERROR',
    error,
});

export const deleteExpenseEntriesSuccess = ids => ({
    type: 'DELETE_EXPENSE_ENTRIES_SUCCESS',
    ids,
});

/* Mark expense entries as billable */
export const setExpenseEntriesBillable = (ids, billable) => ({
    type: 'SET_EXPENSE_ENTRIES_BILLABLE',
    ids,
    billable,
});

export const setExpenseEntriesBillableError = error => ({
    type: 'SET_EXPENSE_ENTRIES_BILLABLE_ERROR',
    error,
});

export const setExpenseEntriesBillableSuccess = ids => ({
    type: 'SET_EXPENSE_ENTRIES_BILLABLE_SUCCESS',
    ids,
});

/* Mark expense entries as approved/pending */
export const setExpenseEntriesApproved = (ids, approved) => ({
    type: 'SET_EXPENSE_ENTRIES_APPROVED',
    ids,
    approved,
});

export const setExpenseEntriesApprovedError = error => ({
    type: 'SET_EXPENSE_ENTRIES_APPROVED_ERROR',
    error,
});

export const setExpenseEntriesApprovedSuccess = ids => ({
    type: 'SET_EXPENSE_ENTRIES_APPROVED_SUCCESS',
    ids,
});

/* Mark expense entries as approved/pending */
export const setExpenseEntriesLocked = (ids) => ({
    type: 'SET_EXPENSE_ENTRIES_LOCKED',
    ids,
});

export const setExpenseEntriesLockedError = error => ({
    type: 'SET_EXPENSE_ENTRIES_LOCKED_ERROR',
    error,
});

export const setExpenseEntriesLockedSuccess = ids => ({
    type: 'SET_EXPENSE_ENTRIES_LOCKED_SUCCESS',
    ids,
});

/* Add time entry */
export const addExpenseEntry = entry => ({
    type: 'ADD_EXPENSE_ENTRY',
    entry,
});

export const addExpenseEntrySuccess = ({ newEntries }) => ({
    type: 'ADD_EXPENSE_ENTRY_SUCCESS',
    newEntries,
});

export const addExpenseEntryError = error => ({
    type: 'ADD_EXPENSE_ENTRY_ERROR',
    error,
});

/* Update expense entry */
export const updateExpenseEntry = entry => ({
    type: 'UPDATE_EXPENSE_ENTRY',
    entry,
});

export const updateExpenseEntrySuccess = ({ updatedEntries }) => ({
    type: 'UPDATE_EXPENSE_ENTRY_SUCCESS',
    updatedEntries,
});

export const updateExpenseEntryError = error => ({
    type: 'UPDATE_EXPENSE_ENTRY_ERROR',
    error,
});

/* Duplicate expense entry */
export const duplicateExpenseEntry = entry => ({
    type: 'DUPLICATE_EXPENSE_ENTRY',
    entry,
});

export const duplicateExpenseEntrySuccess = ({ newEntries }) => ({
    type: 'DUPLICATE_EXPENSE_ENTRY_SUCCESS',
    newEntries,
});

export const duplicateExpenseEntryError = error => ({
    type: 'DUPLICATE_EXPENSE_ENTRY_ERROR',
    error,
});
