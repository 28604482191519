import { postRequestWithFormData } from '../utils/HelperFunctions.js';

export const sendMessage = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'contactus/send_message', paramsObject).then(res => res);
};

export const sendFeedback = (data, paramsObject = {}) => {
    return postRequestWithFormData(data, 'contactus/frontend_feedback', paramsObject).then(
        res => res
    );
};
