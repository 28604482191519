import _ from 'lodash';
import moment from 'moment';
import { constructEditTimesheetStore } from '../utils/TimeEntryUtils.js';
import { MIDNIGHT } from '../config/Constants.js';

export const INITIAL_STATE = {
    selectedTimeEntry: null,
    selectedTimeEntryHash: null,
    duplicate: false,

    groupingType: null, // 'week' or 'individual_entries'
    startDate: null,
    endDate: null,

    searchText: '',
    searchEmployeeIds: null, // null so we can set initial
    searchCustomerIds: [],
    searchInventoryItemIds: [],
    searchQbClassIds: [],
    searchPayrollItemIds: [],
    approvedFilter: 'all',
    lockedFilter: 'all',
    billableFilter: 'all',
    taxableFilter: 'all',
    exportedFilter: 'all',
    isSaving: false,
    isNewEntryModalOpen: false,
    isEditEntryModalOpen: false,
    selectedEntryIds: [],
    entries: [],
    currentPage: 0,
    totalTimeEntries: 0,
    activeFilters: [],
    loading: false,
    updatingStatusEntryIds: [],
    updatingBillableEntryIds: [],
    mode: 'add', // add, edit

    isNewEntryCollapsed: false,

    customerId: '0',
    employeeId: '0',
    inventoryItemId: '0',
    payrollItemId: '0',
    qbClassId: '0',
    date: moment().format(),
    type: 'Employee',
    entryType: 'day',
    id: '',
    description: '',
    duration: '',
    hoursOffDuty: '',
    startTime: MIDNIGHT,
    endTime: MIDNIGHT,
    billable: 'No',
    taxable: 'No',
    exported: 'No',
    durations: {
        Mon: '',
        Tue: '',
        Wed: '',
        Thu: '',
        Fri: '',
        Sat: '',
        Sun: '',
    },
    groupedTimeIds: {
        Mon: [],
        Tue: [],
        Wed: [],
        Thu: [],
        Fri: [],
        Sat: [],
        Sun: [],
    },
    startOfWeek: null,

    // For use with duplicate/edit time entry modals
    editCustomerId: '0',
    editEmployeeId: '0',
    editInventoryItemId: '0',
    editPayrollItemId: '0',
    editQbClassId: '0',
    editDate: moment().format(),
    editType: 'Employee',
    editEntryType: 'day',
    editId: '',
    editDescription: '',
    editDuration: '',
    editHoursOffDuty: '',
    editStartTime: MIDNIGHT,
    editEndTime: MIDNIGHT,
    editBillable: 'No',
    editExported: 'No',
    editTaxable: 'No',
    editDurations: {
        Mon: '',
        Tue: '',
        Wed: '',
        Thu: '',
        Fri: '',
        Sat: '',
        Sun: '',
    },
    editOriginalDurations: {
        Mon: '',
        Tue: '',
        Wed: '',
        Thu: '',
        Fri: '',
        Sat: '',
        Sun: '',
    },
    editEntriesInGroupById: {},
    editGroupedTimeIds: {
        Mon: [],
        Tue: [],
        Wed: [],
        Thu: [],
        Fri: [],
        Sat: [],
        Sun: [],
    },
    editStartOfWeek: null,
};

export const timesheetsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        /**
         * Time Entry Fields
         */
        case 'SET_TIMESHEETS_CUSTOMER_ID':
            return {
                ...state,
                customerId: action.payload,
            };
        case 'SET_TIMESHEETS_EMPLOYEE_ID':
            const type = action.payload.includes('_v') ? 'Vendor' : 'Employee';
            return {
                ...state,
                employeeId: action.payload,
                type,
            };
        case 'SET_TIMESHEETS_INVENTORY_ITEM_ID':
            return {
                ...state,
                inventoryItemId: action.payload,
            };
        case 'SET_TIMESHEETS_QB_CLASS_ID':
            return {
                ...state,
                qbClassId: action.payload,
            };
        case 'SET_TIMESHEETS_PAYROLL_ITEM_ID':
            return {
                ...state,
                payrollItemId: action.payload,
            };
        case 'SET_TIMESHEETS_DATE':
            return {
                ...state,
                date: action.payload,
            };
        case 'SET_TIMESHEETS_DURATION':
            return {
                ...state,
                duration: action.payload,
            };
        case 'SET_TIMESHEETS_DURATIONS':
            return {
                ...state,
                durations: action.payload,
            };
        case 'SET_TIMESHEETS_DESCRIPTION':
            return {
                ...state,
                description: action.payload,
            };
        case 'SET_TIMESHEETS_GROUPED_TIME_IDS':
            return {
                ...state,
                groupedTimeIds: action.payload,
            };
        case 'SET_TIMESHEETS_TYPE':
            return {
                ...state,
                type: action.payload,
            };
        case 'SET_TIMESHEETS_ENTRY_TYPE': {
            return {
                ...state,
                entryType: action.payload,
            };
        }
        case 'SET_TIMESHEETS_BILLABLE':
            return {
                ...state,
                billable: action.payload,
            };
        case 'SET_TIMESHEETS_TAXABLE':
            return {
                ...state,
                taxable: action.payload,
            };
        case 'SET_TIMESHEETS_START_OF_WEEK':
            return {
                ...state,
                startOfWeek: action.payload,
            };
        case 'SET_TIMESHEETS_HOURS_OFF_DUTY':
            return {
                ...state,
                hoursOffDuty: action.payload,
            };
        case 'SET_TIMESHEETS_START_TIME':
            return {
                ...state,
                startTime: action.payload,
            };
        case 'SET_TIMESHEETS_END_TIME':
            return {
                ...state,
                endTime: action.payload,
            };
        /**
         * Edit/Duplicate time entry
         */
        case 'SET_EDIT_TIMESHEET_STORE':
            return {
                ...state,
                ...constructEditTimesheetStore(action.payload),
            };
        case 'SET_TIMESHEETS_EDIT_CUSTOMER_ID':
            return {
                ...state,
                editCustomerId: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_EMPLOYEE_ID':
            const editType = action.payload.includes('_v') ? 'Vendor' : 'Employee';
            return {
                ...state,
                editEmployeeId: action.payload,
                editType,
            };
        case 'SET_TIMESHEETS_EDIT_INVENTORY_ITEM_ID':
            return {
                ...state,
                editInventoryItemId: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_QB_CLASS_ID':
            return {
                ...state,
                editQbClassId: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_PAYROLL_ITEM_ID':
            return {
                ...state,
                editPayrollItemId: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_DATE':
            return {
                ...state,
                editDate: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_DURATION':
            return {
                ...state,
                editDuration: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_DURATIONS':
            return {
                ...state,
                editDurations: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_DESCRIPTION':
            return {
                ...state,
                editDescription: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_GROUPED_TIME_IDS':
            return {
                ...state,
                editGroupedTimeIds: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_TYPE':
            return {
                ...state,
                editType: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_ENTRY_TYPE':
            return {
                ...state,
                editEntryType: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_BILLABLE':
            return {
                ...state,
                editBillable: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_TAXABLE':
            return {
                ...state,
                editTaxable: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_START_OF_WEEK':
            return {
                ...state,
                editStartOfWeek: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_HOURS_OFF_DUTY':
            return {
                ...state,
                editHoursOffDuty: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_START_TIME':
            return {
                ...state,
                editStartTime: action.payload,
            };
        case 'SET_TIMESHEETS_EDIT_END_TIME':
            return {
                ...state,
                editEndTime: action.payload,
            };
        /**
         * Timesheet Filters
         */
        case 'SET_TIMESHEETS_START_DATE':
            return {
                ...state,
                startDate: action.payload,
            };
        case 'SET_TIMESHEETS_END_DATE':
            return {
                ...state,
                endDate: action.payload,
            };
        case 'SET_TIMESHEETS_SEARCH_TEXT':
            return {
                ...state,
                searchText: action.payload,
            };
        case 'SET_TIMESHEETS_SEARCH_EMPLOYEE_IDS':
            return {
                ...state,
                searchEmployeeIds: action.payload,
            };
        case 'SET_TIMESHEETS_SEARCH_CUSTOMER_IDS':
            return {
                ...state,
                searchCustomerIds: action.payload,
            };
        case 'SET_TIMESHEETS_SEARCH_INVENTORY_ITEM_IDS':
            return {
                ...state,
                searchInventoryItemIds: action.payload,
            };
        case 'SET_TIMESHEETS_SEARCH_QB_CLASS_IDS':
            return {
                ...state,
                searchQbClassIds: action.payload,
            };
        case 'SET_TIMESHEETS_SEARCH_PAYROLL_ITEM_IDS':
            return {
                ...state,
                searchPayrollItemIds: action.payload,
            };
        case 'SET_TIMESHEETS_APPROVED_FILTER':
            return {
                ...state,
                approvedFilter: action.payload,
            };
        case 'SET_TIMESHEETS_LOCKED_FILTER':
            return {
                ...state,
                lockedFilter: action.payload,
            };
        case 'SET_TIMESHEETS_BILLABLE_FILTER':
            return {
                ...state,
                billableFilter: action.payload,
            };
        case 'SET_TIMESHEETS_TAXABLE_FILTER':
            return {
                ...state,
                taxableFilter: action.payload,
            };
        case 'SET_TIMESHEETS_EXPORTED_FILTER':
            return {
                ...state,
                exportedFilter: action.payload,
            };
        case 'SET_TIMESHEETS_CURRENT_PAGE':
            return {
                ...state,
                currentPage: action.payload,
            };
        case 'SET_TIMESHEETS_TOTAL_TIME_ENTRIES':
            return {
                ...state,
                totalTimeEntries: action.payload,
            };
        case 'SET_TIMESHEETS_ACTIVE_FILTERS':
            return {
                ...state,
                activeFilters: action.payload,
            };

        case 'SET_TIMESHEETS_FILTER_STATE': {
            const allowedKeys = [
                'startDate',
                'endDate',
                'searchText',
                'searchEmployeeIds',
                'searchCustomerIds',
                'searchInventoryItemIds',
                'searchQbClassIds',
                'searchPayrollItemIds',
                'approvedFilter',
                'billableFilter',
                'taxableFilter',
                'exportedFilter',
                'currentPage',
                'activeFilters',
            ];

            return {
                ...state,
                ..._.pick(action.payload, allowedKeys),
            };
        }
        /**
         * Misc
         */
        case 'SET_TIMESHEETS_IS_SAVING':
            return {
                ...state,
                isSaving: action.payload,
            };
        case 'SET_TIMESHEETS_IS_NEW_ENTRY_MODAL_OPEN':
            return {
                ...state,
                isNewEntryModalOpen: action.payload,
                mode: 'add',
            };
        case 'SET_TIMESHEETS_IS_EDIT_ENTRY_MODAL_OPEN':
            return {
                ...state,
                isEditEntryModalOpen: action.payload,
                mode: action.payload ? 'edit' : 'add',
            };
        case 'SET_TIMESHEETS_IS_NEW_ENTRY_COLLAPSED':
            return {
                ...state,
                isNewEntryCollapsed: action.payload,
            };
        case 'SET_TIMESHEETS_SELECTED_ENTRY_IDS':
            return {
                ...state,
                selectedEntryIds: action.payload,
            };
        case 'SET_TIMESHEETS_LOADING':
            return {
                ...state,
                loading: action.payload,
            };
        case 'RESET_TIMESHEETS_FORM':
            return {
                ...state,
                description: '',
                editDescription: '',
                duration: '',
                editDuration: '',
                hoursOffDuty: '',
                editHoursOffDuty: '',
                startTime: MIDNIGHT,
                editStartTime: MIDNIGHT,
                endTime: MIDNIGHT,
                editEndTime: MIDNIGHT,
                durations: {
                    Mon: '',
                    Tue: '',
                    Wed: '',
                    Thu: '',
                    Fri: '',
                    Sat: '',
                    Sun: '',
                },
                editDurations: {
                    Mon: '',
                    Tue: '',
                    Wed: '',
                    Thu: '',
                    Fri: '',
                    Sat: '',
                    Sun: '',
                },
                editOriginalDurations: {
                    Mon: '',
                    Tue: '',
                    Wed: '',
                    Thu: '',
                    Fri: '',
                    Sat: '',
                    Sun: '',
                },
                editEntriesInGroupById: {},
                groupedTimeIds: {
                    Mon: [],
                    Tue: [],
                    Wed: [],
                    Thu: [],
                    Fri: [],
                    Sat: [],
                    Sun: [],
                },
                editGroupedTimeIds: {
                    Mon: [],
                    Tue: [],
                    Wed: [],
                    Thu: [],
                    Fri: [],
                    Sat: [],
                    Sun: [],
                },
            };
        case 'SELECT_TIME_ENTRY':
            return {
                ...state,
                selectedTimeEntry: action.payload.selectedTimeEntry,
                duplicate: action.payload.duplicate,
            };
        case 'SELECT_TIME_ENTRY_HASH':
            return {
                ...state,
                selectedTimeEntryHash: action.payload.selectedTimeEntryHash,
                duplicate: action.payload.duplicate,
            };
        case 'SET_TIMESHEETS_GROUPING_TYPE':
            return {
                ...state,
                groupingType: action.payload,
            };
        case 'REQUEST_TIMESHEETS': {
            return {
                ...state,
                loading: action.showLoading,
            };
        }
        case 'RECEIVE_TIMESHEETS': {
            return {
                ...state,
                entries: action.timesheets,
                totalTimeEntries: action.total,
                loading: false,
            };
        }
        case 'REQUEST_TIMESHEETS_ERROR': {
            return {
                ...state,
                loading: false,
            };
        }
        case 'SET_TIME_ENTRIES_BILLABLE': {
            return {
                ...state,
                updatingBillableEntryIds: [...state.updatingBillableEntryIds, ...action.ids],
            };
        }
        case 'SET_TIME_ENTRIES_BILLABLE_SUCCESS': {
            const newIds = state.updatingBillableEntryIds.filter(id => !action.ids.includes(id));
            return {
                ...state,
                updatingBillableEntryIds: newIds,
            };
        }
        case 'SET_TIME_ENTRIES_BILLABLE_ERROR': {
            return {
                ...state,
                updatingBillableEntryIds: [],
            };
        }
        case 'SET_TIME_ENTRIES_APPROVED': {
            return {
                ...state,
                updatingStatusEntryIds: [...state.updatingStatusEntryIds, ...action.ids],
            };
        }
        case 'SET_TIME_ENTRIES_APPROVED_SUCCESS': {
            const newIds = state.updatingStatusEntryIds.filter(id => !action.ids.includes(id));
            return {
                ...state,
                updatingStatusEntryIds: newIds,
            };
        }
        case 'SET_TIME_ENTRIES_APPROVED_ERROR': {
            return {
                ...state,
                updatingStatusEntryIds: [],
            };
        }
        case 'SET_DEFAULTS_FOR_NEW_TIME_ENTRIES': {
            const allowedKeys = [
                'customerId',
                'inventoryItemId',
                'payrollItemId',
                'employeeId',
                'type',
                'billable',
                'taxable',
            ];

            return {
                ...state,
                ..._.pick(action.defaults, allowedKeys),
            };
        }
        case 'LOGOUT':
            return INITIAL_STATE;
        case 'IMPERSONATE_STORE_CLEAR':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default timesheetsReducer;
