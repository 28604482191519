import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { createReportFilter, refreshReportFilter, deleteReportFilter, updateReportFilter} from '../requests/ReportFilterRequest.js';
import * as uiActions from '../actions/Actions.js';
import * as reportFilterActions from '../actions/ReportFilterActions.js';
import _ from 'lodash';
import { reportFilterFileds } from '../config/Fields';

/**
 * Returns an Employee object for api calls
 * @param {Object} user holds all accountUser settings
 */

const formDataForReportFilter = reportFilter => {
    const data = {};
    _.forEach(_.pick(reportFilter, reportFilterFileds ), (value, key) => {
            {
                data[`ReportFilter[${key}]`] = value;
            }
        }
    );
    return data;
};

export function* addReportFilter({ reportFilter, callback }) {
    try {
        const data = formDataForReportFilter(reportFilter);
        const newReportFilter = yield call(createReportFilter, data);

        yield put(reportFilterActions.addReportFiltersSucess(newReportFilter));
        callback(newReportFilter);
        // const action = yield take(['REFRESH_REPORT_FILTER_SUCCESS']);
        // console.log(action);
        // if (action.type === 'REFRESH_REPORT_FILTER_SUCCESS') {
        //     callback(newReportFilter); // Invoke the callback function
        // } 
    } catch (error) {
        if (callback) {
            callback(false);
        }
        //yield put(reportFilterActions.addReportFilter(error));
        yield put(uiActions.showToast(error.message, 'negative'));
    }
}


export function* deleteReportFilters({ id, callback }) {
    try {
        const respone = yield call(deleteReportFilter, id);
        yield put(reportFilterActions.addReportFiltersSucess(respone));
        callback(respone);
    } catch (error) {
        if (callback) {
            callback(false);
        }
        yield put(uiActions.showToast(error.message, 'negative'));
    }
}

export function* updateReportFilters({ id, reportFilter, callback }) {
    try {
        const data = formDataForReportFilter(reportFilter);
        const respone = yield call(updateReportFilter, id, data);
        yield put(reportFilterActions.addReportFiltersSucess(respone));
        callback(respone);
    } catch (error) {
        if (callback) {
            callback(false);
        }
        yield put(uiActions.showToast(error.message, 'negative'));
    }
}

export function* refreshReportFilters({params, callback}) {
    try {
        const reports = yield call(refreshReportFilter);
        yield put(reportFilterActions.setReportFilter(reports));
        yield put(reportFilterActions.refreshReportFiltersSuccess())
        callback(true);
    } catch (error) {
        callback(true);
        yield put(
            uiActions.showToast(
                'Couldn’t load data from our servers. Try reloading the page.',
                'negative'
            )
        );
    }
}


export default function* root() {
    yield all([
        takeLatest(['ADD_REPORT_FILTER'], addReportFilter),
        takeLatest(['REFRESH_REPORT_FILTERS'], refreshReportFilters),
        takeLatest(['DELETE_REPORT_FILTERS'], deleteReportFilters),
        takeLatest(['UPDATE_REPORT_FILTERS'], updateReportFilters),
    ]);
}
