import _ from 'lodash';
import moment from 'moment';

export const INITIAL_STATE = {
    timeEntryId: null, // If not null, continuing timer from an already saved entry
    // an array of { start: timestamp, end: timestamp } chunks for the current timing session
    timeChunks: [],

    customerId: '0',
    employeeId: '0',
    description: '',
    date: moment().format(),
    inventoryItemId: '0',
    qbClassId: '0',
    payrollItemId: '0',
    billable: null,
    taxable: null,
    approved: 'No',
    type: 'Employee',
    roundingInterval: null,
    hoursOffDuty: '',
    // When timer entries are saved, hours off duty are already taken out of the
    // final duration. When a timer for such an entry is resumed, the existing value
    // of hoursOffDuty will be saved as an adjustment and then subtracted from any newly
    // entered hoursOffDuty. This way, if the user wants to add additional time to be
    // counted as "off duty", we wouldn't be applying previously specified hoursOffDuty twice.
    // E.g. say you have an entry for 2.0 hours, with 0.25 hours off duty. You continue
    // the timer for the entry and it starts counting up for 2.0 hours. You let the timer run
    // for another hour, so hours worked is now 3.0 hours. You realize you've spent 0.5 hours
    // on break, and want to record that. You bump hours off duty to 0.75. The final duration
    // now becomes 2.5 hours (3.0 - (0.75 total off duty - 0.25 adjustment)).
    hoursOffDutyAdjustment: 0,

    isTimerRunning: false,
    isTimerStarted: false,
    isTimerOpen: false,
};

export const timerReducer = (state = INITIAL_STATE, action) => {
    const now = new Date();

    switch (action.type) {
        case 'SET_TIMER_BILLABLE':
            return {
                ...state,
                billable: action.payload,
            };
        case 'SET_TIMER_TAXABLE':
            return {
                ...state,
                taxable: action.payload,
            };
        case 'SET_TIMER_APPROVED':
            return {
                ...state,
                approved: action.payload,
            };
        case 'SET_TIMER_CUSTOMER_ID':
            return {
                ...state,
                customerId: action.payload,
            };
        case 'SET_TIMER_EMPLOYEE_ID':
            const type = action.payload.includes('_v') ? 'Vendor' : 'Employee';
            return {
                ...state,
                employeeId: action.payload,
                type,
            };
        case 'SET_TIMER_PAYROLL_ITEM_ID':
            return {
                ...state,
                payrollItemId: action.payload,
            };
        case 'SET_TIMER_INVENTORY_ITEM_ID':
            return {
                ...state,
                inventoryItemId: action.payload,
            };
        case 'SET_TIMER_QB_CLASS_ID':
            return {
                ...state,
                qbClassId: action.payload,
            };
        case 'SET_TIMER_DATE':
            return {
                ...state,
                date: action.payload,
            };
        case 'SET_TIMER_START_TIME': {
            // start time will shift the start of the first time chunk
            let timeChunks = state.timeChunks;
            if (timeChunks[0]) {
                const end = moment(timeChunks[0].end);
                // makes sure new start date is initially on the same day as the end date
                const start = end.clone();

                const newStart = moment(action.payload, 'HH:mm');
                if (!newStart.isValid()) {
                    console.warn('Tried to update timer start time with an invalid date.');
                    return state;
                }

                start.hours(newStart.hours());
                start.minutes(newStart.minutes());

                if (start.isAfter(end, 'minute')) {
                    start.subtract(1, 'd');
                }
                const updatedChunk = { start: start.valueOf(), end: timeChunks[0].end };
                timeChunks = [updatedChunk, ..._.drop(timeChunks)];
            }

            return {
                ...state,
                timeChunks,
            };
        }
        case 'SET_TIMER_END_TIME':
            return {
                ...state,
                endTime: action.payload,
            };
        case 'SET_TIMER_HOURS_OFF_DUTY':
            return {
                ...state,
                hoursOffDuty: action.payload,
            };
        case 'SET_TIMER_DESCRIPTION':
            return {
                ...state,
                description: action.payload,
            };
        case 'SET_TIMER_TYPE':
            return {
                ...state,
                type: action.payload,
            };
        case 'SET_TIMER_ROUNDING_INTERVAL':
            return {
                ...state,
                roundingInterval: action.payload,
            };
        case 'TIMER_RESET':
            return {
                ...state,
                isTimerRunning: false,
                isTimerStarted: false,
                timeEntryId: null,
                timeChunks: [{ start: +now, end: +now }],
                hoursOffDuty: '',
                description: '',
            };
        case 'OPEN_TIMER': {
            const allowedKeys = [
                'customerId',
                'date',
                'description',
                'employeeId',
                'billable',
                'payrollItemId',
                'inventoryItemId',
                'qbClassId',
                'hoursOffDuty',
                'hoursOffDutyAdjustment',
            ];
            return {
                ...state,
                isTimerOpen: true,
                timeChunks: state.isTimerStarted ? state.timeChunks : [{ start: +now, end: +now }],
                date: state.isTimerStarted ? state.date : moment().format(),
                ..._.pick(action.payload, allowedKeys),
            };
        }
        case 'CLOSE_TIMER':
            return {
                ...state,
                isTimerOpen: false,
            };
        case 'START_TIMER':
            return {
                ...state,
                timeChunks: [...state.timeChunks, { start: moment().valueOf(), end: null }],
                isTimerRunning: true,
                isTimerStarted: true,
            };
        case 'PAUSE_TIMER':
            const lastTimeChunk = state.timeChunks[state.timeChunks.length - 1];
            const updatedChunk = { ...lastTimeChunk, end: moment().valueOf() };

            return {
                ...state,
                timeChunks: [..._.dropRight(state.timeChunks), updatedChunk],
                isTimerRunning: false,
            };
        case 'CONTINUE_TIMER': {
            return {
                ...state,
                ...action.payload,
                timeChunks: [
                    ...action.payload.timeChunks,
                    { start: moment().valueOf(), end: null },
                ],
                isTimerStarted: true,
                isTimerRunning: true,
                isTimerOpen: true,
            };
        }
        case 'SET_DEFAULTS_FOR_NEW_TIMER_ENTRIES': {
            const allowedKeys = [
                'customerId',
                'inventoryItemId',
                'payrollItemId',
                'employeeId',
                'type',
                'billable',
                'taxable',
                'approved',
                'roundingInterval',
            ];

            return {
                ...state,
                ..._.pick(action.defaults, allowedKeys),
            };
        }
        case 'LOGOUT':
            return INITIAL_STATE;
        case 'IMPERSONATE_STORE_CLEAR':
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default timerReducer;
