import React from 'react';

const AssociateAnEmployee = () => (
    <div>
        <div className="sectionQuestion">
            Why associate an Employee with an "Associated Vendor for expense tracking in
            QuickBooks"?
        </div>
        <div className="sectionAnswer">
            In QuickBooks, there is currently no method of associating reimbursable expenses
            directly with Employees. However, Vendors can be directly associated with expenses, so
            one common way to track Employee expenses in QuickBooks is to{' '}
            <b>create a Vendor with the same name as your Employee</b> (often with "-vendor" or a
            similar note at the end of the name). This allows you to add and track expenses using
            the "Enter Bills" interface in QuickBooks.
            <br />
            If you want to allow Employees to enter expenses using Minute7, you must associate the
            Minute7 user for your Employee with the corresponding Vendor. If you already have a
            corresponding Vendor set up, just go to the <b>user permissions page</b> for the Minute7
            user in question, and select the correct Vendor from the{' '}
            <b>"Associated Vendor for expense tracking in QuickBooks"</b> drop-down list.
            <br />
            If you do not currently have a Vendor set up for your Employee in QuickBooks, but would
            like to start tracking expenses for the Employee, first set up a Vendor for your
            Employee with the following steps:
            <ol>
                <li>
                    Open your company file in QuickBooks, and go to the <b>Vendor Center</b> by
                    clicking on the "Vendor Center" tab in the Icon Bar at the top of the screen.
                </li>
                <li>
                    Click on the <b>"New Vendor"</b> icon in the upper left corner of the Vendor
                    Center screen.
                </li>
                <li>
                    In the window that opens up, enter all the relevant information for your
                    Employee, including the name of your Employee as the <b>"Vendor Name"</b>. You
                    might want to <b>add "-vendor"</b> or a similar identifier to the end of the
                    name.
                </li>
                <li>
                    Click <b>"OK"</b> to save the new vendor.
                </li>
                <li>
                    <b>Sync</b> your company file with Minute7 using the QuickBooks Web Connector to
                    make sure your Minute7 vendor list is up to date.
                </li>
            </ol>
            Once the corresponding Vendor has been set up for your Employee, just go to the{' '}
            <b>user permissions page</b> for the Minute7 user in question, and select the correct
            Vendor from the <b>"Associated Vendor for expense tracking in QuickBooks"</b> drop-down
            list. Your Employee is now ready to start tracking expenses!
        </div>
    </div>
);

export default AssociateAnEmployee;
