import React from 'react';

const DataBackup = () => (
    <div>
        <div className="sectionQuestion">Is my data backed up?</div>
        <div className="sectionAnswer">
            Yes. All data is backed up nightly, so that we are prepared for a full recovery if
            something goes wrong. All of our servers are hosted on Amazon Web Services, a leading
            hosting provider with secure data centers and a 99.9% up-time guarantee.
        </div>
    </div>
);

export default DataBackup;
