import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import classNames from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Icon from 'react-icons-kit';
import { ic_help_outline as helpIcon } from 'react-icons-kit/md/ic_help_outline';
import { caretDown } from 'react-icons-kit/fa/caretDown';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import SecondaryHeaderBar from '../components/SecondaryHeaderBar.js';
import MobileHeaderBar from '../components/MobileHeaderBar';
import { logout, hideAlert } from '../../actions/Actions.js';
import { exitImpersonateUser } from '../../actions/AccountActions';
import CompanyLogo from './CompanyLogo.js';
import { createProperPageTitle } from '../../utils/CommonFunctions.js';
import {
    figureCanViewExpenses,
    figureCanViewTimesheets,
    figureCanViewActivityLog,
} from '../../utils/PermissionUtils.js';
import '../../css/HeaderBar.css';

/**
 * These pathnames render their own header bar
 */
const pathnamesWithMobileViews = ['/home', '/free_trial', '/login', '/contact'];

const pathnamesWithAlternateHeaderBar = [
    '/login',
    '/home',
    '/contact',
    '/password_reset',
    '/free_trial',
    '/support',
];

/**
 * These pathnames are all pages "inside" account_settings (settings in headerBar)
 */
const accountSettingsUrls = [
    '/account_user_permissions',
    '/account_settings',
    '/company_settings',
    '/payment_history',
    '/create_account_user',
    '/edit_account_user',
    '/permissions_group',
];

class HeaderBar extends Component {
    static propTypes = {
        history: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: createProperPageTitle(this.props.history.location.pathname),
            isUserDropdownOpen: false,
        };
    }

    logout = () => {
        const { sessionName, sessionId } = this.props;
        this.props.logout({ [sessionName]: sessionId });
        this.props.history.push('/login');
    };

    redirect = to => {
        // Hide global alert if we are ever redirecting
        this.props.hideAlert();
        this.props.history.push(to);
    };

    handleToggleUserDropdownPressed = () => {
        this.setState({ isUserDropdownOpen: !this.state.isUserDropdownOpen });
    };

    render() {
        const {
            history,
            accountUserStore,
            canViewReports,
            canExportTime,
            canManageAccount,
            canManageUsers,
            canViewExpenses,
            canViewTimesheets,
            canViewActivityLog,
            isLoggedIn,
            isLoggingIn,
            isImpersonated,
            accountStore,
        } = this.props;
        const { isUserDropdownOpen } = this.state;
        const { pathname } = history.location;
        const isHome = pathname.includes('home');

        const userName = [accountUserStore.first_name, accountUserStore.last_name].join(' ');

        if (!pathnamesWithAlternateHeaderBar.includes(pathname) && isLoggedIn && !isLoggingIn) {
            return (
                <div className="hbContainer">
                    <div className="hbWrapper">
                        <DocumentTitle title={createProperPageTitle(pathname)} />
                        <CompanyLogo logo={this.props.logo} id={accountUserStore.account_id} />
                        <div className="hbPrimaryLinks">
                            {/* Primary Links */}
                            {canViewTimesheets && (
                                <div
                                    className={classNames({
                                        hbPageLinkContainer: true,
                                        hbActivePageLink: pathname === '/timesheets',
                                    })}
                                    onClick={() => this.redirect('/timesheets')}
                                >
                                    Timesheets
                                </div>
                            )}
                            {canViewExpenses && (
                                <div
                                    className={classNames({
                                        hbPageLinkContainer: true,
                                        hbActivePageLink: pathname === '/expenses',
                                    })}
                                    onClick={() => this.redirect('/expenses')}
                                >
                                    Expenses
                                </div>
                            )}
                            {canViewReports && (
                                <div
                                    className={classNames({
                                        hbPageLinkContainer: true,
                                        hbActivePageLink: pathname === '/reports',
                                    })}
                                    onClick={() => this.redirect('/reports')}
                                >
                                    Reports
                                </div>
                            )}
                            {canExportTime && accountStore.qb_type !== 'None' && (
                                <div
                                    className={classNames({
                                        hbPageLinkContainer: true,
                                        hbActivePageLink: pathname === '/sync',
                                    })}
                                    onClick={() => this.redirect('/sync')}
                                >
                                    Sync
                                </div>
                            )}
                            {(canManageAccount || canManageUsers) && (
                                <div
                                    className={classNames({
                                        hbPageLinkContainer: true,
                                        hbActivePageLink: accountSettingsUrls.includes(pathname),
                                    })}
                                    onClick={() => this.redirect('/account_settings')}
                                >
                                    Settings
                                </div>
                            )}
                            {canViewActivityLog && (
                                <div
                                    className={classNames({
                                        hbPageLinkContainer: true,
                                        hbActivePageLink: pathname === '/activity_log',
                                    })}
                                    onClick={() => this.redirect('/activity_log')}
                                >
                                    Activity Log
                                </div>
                            )}
                        </div>
                        <div className="hbSecondaryLinks">
                            {/* Secondary Links */}
                            <div
                                className={classNames({
                                    hbPageLinkContainer: true,
                                    hbActivePageLink: pathname.includes('/help'),
                                })}
                            >
                                <div
                                    className={classNames({
                                        hbSecondaryLink: true,
                                        hbActivePageLink: pathname.includes('/help'),
                                    })}
                                    onClick={() => this.redirect('/help')}
                                >
                                    <Icon icon={helpIcon} size={24} />
                                </div>
                            </div>
                            <div
                                className={classNames({
                                    hbPageLinkContainer: true,
                                    hbActivePageLink: pathname === '/account_user_settings',
                                })}
                            >
                                <Dropdown
                                    isOpen={isUserDropdownOpen}
                                    toggle={this.handleToggleUserDropdownPressed}
                                >
                                    <DropdownToggle className="hbSecondaryLink">
                                        {userName}
                                        <Icon className="dropdownIcon" icon={caretDown} size={16} />
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem
                                            onClick={() => this.redirect('/account_user_settings')}
                                        >
                                            My Settings
                                        </DropdownItem>
                                        {!isImpersonated && (
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItem
                                                    className="destructiveOption"
                                                    onClick={this.logout}
                                                >
                                                    Sign Out
                                                </DropdownItem>
                                            </>
                                        )}
                                        {isImpersonated && (
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItem
                                                    className="destructiveOption"
                                                    onClick={this.props.exitImpersonateUser}
                                                >
                                                    Exit Impersonation
                                                </DropdownItem>
                                            </>
                                        )}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (pathnamesWithMobileViews.includes(this.props.history.location.pathname) && isMobile) {
            return <MobileHeaderBar history={this.props.history} fromHome={isHome} />;
        }

        return <SecondaryHeaderBar history={this.props.history} fromHome={isHome} />;
    }
}

function mapStateToProps(state) {
    const canViewReports = state.accountUserStore.view_reports === 'Yes';
    const canExportTime = state.accountUserStore.export_time === 'Yes';
    const canManageAccount = state.accountUserStore.manage_account === 'Yes';
    const canManageUsers = state.accountUserStore.manage_users === 'Yes';
    const canViewExpenses = figureCanViewExpenses(state.accountStore, state.accountUserStore);
    const canViewTimesheets = figureCanViewTimesheets(state.accountUserStore);
    const canViewActivityLog = figureCanViewActivityLog(state.accountUserStore);
    const isImpersonated = state.accountUserStore.impersonated === true

    return {
        accountUserStore: state.accountUserStore,
        accountStore: state.accountStore,
        sessionName: state.appStore.session_name,
        sessionId: state.appStore.session_id,
        isLoggedIn: !_.isNil(state.appStore.session_id),
        isLoggingIn: state.appStore.isLoggingIn,
        isImpersonated,
        logo: state.accountStore.logo,
        canViewReports,
        canExportTime,
        canManageAccount,
        canManageUsers,
        canViewExpenses,
        canViewTimesheets,
        canViewActivityLog,
    };
}

const mapDispatchToProps = {
    logout,
    hideAlert,
    exitImpersonateUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderBar);
