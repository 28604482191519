import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { sendWelcomeEmail } from '../requests/AccountUserRequests';
import {
    updateExistingEmployee,
    createNewEmployee,
    getAllEmployees,
    activateEmployee,
    deactivateEmployee,
    getCanEditEmployees,
} from '../requests/EmployeeRequests';
import * as uiActions from '../actions/Actions';
import * as employeeActions from '../actions/EmployeeActions';
import * as businessResouceActions from '../actions/BusinessResourcesActions';
import _ from 'lodash';
import { employeeFields } from '../config/Fields';

/**
 * Returns an Employee object for api calls
 * @param {Object} user holds all accountUser settings
 */
const formDataForEmployee = employee => {
    const data = {};
    _.forEach(_.pick(employee, employeeFields), (value, key) => {
        switch (key) {
            case 'employee_id': {
                if (String(value).includes('_v')) {
                    data['Employee[employee_id]'] = value.replace('_v', '');
                    data['Employee[type]'] = 'Vendor';
                } else {
                    data['Employee[employee_id]'] = value;
                    data['Employee[type]'] = 'Employee';
                }
                break;
            }
            case 'password': {
                if (value.toString().replace(/\s+/, '').length > 0) {
                    data['Employee[password]'] = value;
                }
                break;
            }
            case 'type': {
                break;
            }
            default: {
                data[`Employee[${key}]`] = value;
            }
        }
    });
    return data;
};

export function* updateEmployee({ employee, callback }) {
    try {
        const data = formDataForEmployee(employee);
        const updatedEmployee = yield call(updateExistingEmployee, data);
        const updatedEmployees = yield call(getAllEmployees);

        yield put(employeeActions.updateEmployeeSuccess(updatedEmployee));
        yield put(employeeActions.setAllEmployees(updatedEmployees));
        yield put(businessResouceActions.requestBusinessResources());
        yield put(uiActions.refreshAccountUsers());
        yield put(employeeActions.refreshEmployees());
        const action = yield take(['REFRESH_EMPLOYEES_SUCCESS']);
        console.log(action);
        if (action.type === 'REFRESH_EMPLOYEES_SUCCESS') {
            if (callback) {
                callback(updatedEmployee); // Invoke the callback function
            }
        }
    } catch (error) {
        if (callback) {
            callback(false);
        }
        yield put(employeeActions.updateEmployeeError(error));
        yield put(uiActions.showToast(error.message, 'negative'));
    }
}

export function* addEmployee({ employee, callback }) {
    try {
        const data = formDataForEmployee(employee);
        const newEmployee = yield call(createNewEmployee, data);
        const updatedEmployees = yield call(getAllEmployees);

        yield put(employeeActions.addEmployeeSuccess(newEmployee));
        yield put(employeeActions.setAllEmployees(updatedEmployees));
        yield put(businessResouceActions.requestBusinessResources());
        yield call(sendWelcomeEmail, newEmployee);
        yield put(uiActions.refreshAccountUsers());
        yield put(employeeActions.refreshEmployees());
        const action = yield take(['REFRESH_EMPLOYEES_SUCCESS']);
        console.log(action);
        if (action.type === 'REFRESH_EMPLOYEES_SUCCESS') {
            callback(newEmployee); // Invoke the callback function
        } 
    } catch (error) {
        if (callback) {
            callback(false);
        }
        yield put(employeeActions.addEmployeeError(error));
        yield put(uiActions.showToast(error.message, 'negative'));
    }
}

export function* unhideEmployee({ employeeId }) {
    try {
        yield call(activateEmployee, employeeId);
        const updatedEmployees = yield call(getAllEmployees);

        yield put(employeeActions.setAllEmployees(updatedEmployees));
        yield put(businessResouceActions.requestBusinessResources());
        yield put(uiActions.showToast('Employee has been activated', 'positive'));
    } catch (error) {
        yield put(employeeActions.unhideEmployeeError(error));
        yield put(
            uiActions.showToast('Couldn’t activate this employee. Try again later.', 'negative')
        );
    }
}

export function* refreshEmployees() {
    try {
        const updatedEmployees = yield call(getCanEditEmployees);
        yield put(employeeActions.setCanEditEmployees(updatedEmployees));
        yield put(employeeActions.refreshEmployeesSuccess());
    } catch (error) {
        yield put(
            uiActions.showToast(
                'Couldn’t load data from our servers. Try reloading the page.',
                'negative'
            )
        );
    }
}

export function* hideEmployee({ employeeId }) {
    try {
        yield call(deactivateEmployee, employeeId);
        const updatedEmployees = yield call(getAllEmployees);

        yield put(employeeActions.setAllEmployees(updatedEmployees));
        yield put(businessResouceActions.requestBusinessResources());
        yield put(uiActions.showToast('Employee has been deactivated', 'positive'));
    } catch (error) {
        yield put(employeeActions.hideEmployeeError(error));
        yield put(
            uiActions.showToast('Couldn’t deactivate this employee. Try again later.', 'negative')
        );
    }
}

export default function* root() {
    yield all([
        takeLatest(['UPDATE_EMPLOYEE'], updateEmployee),
        takeLatest(['ADD_EMPLOYEE'], addEmployee),
        takeLatest(['DEACTIVATE_EMPLOYEE'], hideEmployee),
        takeLatest(['ACTIVATE_EMPLOYEE'], unhideEmployee),
        takeLatest(['REFRESH_EMPLOYEES'], refreshEmployees),
    ]);
}
