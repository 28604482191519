import { getData, postRequestWithFormData } from '../utils/HelperFunctions.js';

export const getCanViewEmployees = async () => {
    const res = await getData({}, 'employees/view_can_view');
    return res.Employees.map(({ Employee }) => Employee);
};

export const getCanEditEmployees = async () => {
    const res = await getData({}, 'employees/view_can_edit');
    return res.Employees.map(({ Employee }) => Employee);
};

export const getAllEmployees = async (paramsObject = {}) => {
    const { Employees, meta } = await getData(paramsObject, 'v1/employees');
    let employessList = [];
    employessList = Employees.map(item => {return item.Employee});
    return {
        employessList,
        meta,
    };
};

export const activateEmployee = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/employees/' + id + '/activate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.Employee;
};

export const deactivateEmployee = async (id, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        {},
        'v1/employees/' + id + '/deactivate',
        paramsObject,
        { 'Content-Type': 'multipart/form-data' }
    );
    return res.Employee;
};

export const createNewEmployee = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(data, 'v1/employees', paramsObject);
    return res.Employee;
};

export const updateExistingEmployee = async (data, paramsObject = {}) => {
    const res = await postRequestWithFormData(
        data,
        'v1/employees/' + data['Employee[account_id]'],
        paramsObject
    );
    return res.Employee;
};
