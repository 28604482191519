import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import Select from '../../components/Select';
import Permissions from './Permissions';
import FloatingSaveButton from '../../components/FloatingButton.js';
import { accountUserFields } from '../../../config/Fields.js';
import { showToast } from '../../../actions/Actions.js';
import { updateUser } from '../../../actions/AccountUserActions';
import { setPermissionsGroups } from '../../../actions/BusinessResourcesActions.js';
import {
    parseAccountUserPermissions,
    parsePermissionsGroupPermissions,
} from '../../../utils/PermissionUtils.js';
import * as expensesSelectors from '../../../selectors/ExpensesSelectors';
import * as resourceSelectors from '../../../selectors/BusinessResourcesSelectors';
import * as accountSelectors from '../../../selectors/AccountSelectors';
import Icon from 'react-icons-kit';
import { ic_error_outline as errorIcon } from 'react-icons-kit/md/ic_error_outline';

class AccountUserPermissions extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        if (!_.isEmpty(props.user)) {
            this.state = this.stateForUser(props.user);
        }
       
    }

    stateForUser = user => {
        const { accountStore, no_integration } = this.props;

        return {
            ...parseAccountUserPermissions({
                accountId: accountStore.id,
                accountUser: user,
            }),
            hasBeenEdited: false,
            no_integration: no_integration,
            permission_group_manage_users: this.setSelectedPermissionsGroupSelected(user),
        };
    };

    componentWillReceiveProps(props) {
        if (
            !_.isEmpty(this.props.user) &&
            props.user !== this.props.user &&
            props.user &&
            !props.isSaving
        ) {
            this.setState(this.stateForUser(props.user));
        }

        if (props.isSaving !== this.props.isSaving && !props.isSaving) {
            this.props.history.goBack();
        }
    }

    handlePermissionsGroupSelected = id => {
        if (id === '0') {
            this.setState({
                ...this.stateForUser(this.props.user),
                hasBeenEdited: true,
                permission_group_id: '0',
            });
            return;
        }
        const { permissionsGroups } = this.props;

        const selectedPermissionsGroup = permissionsGroups.find(group => group.id === id);

        this.setState({
            hasBeenEdited: true,
            ...parsePermissionsGroupPermissions({
                permissionsGroup: selectedPermissionsGroup,
            }),
        });
    };

    setSelectedPermissionsGroupSelected = user => { 
        let  permission_group_manage_users = 'No'
        if (user.permission_group_id !== '0') {
            if(user.manage_users_override_group === 'Yes'){
                permission_group_manage_users= user.manage_users_override;

            }else{
                let { permissionsGroups } = this.props;
                let selectedPermissionsGroup = permissionsGroups.find(group => group.id === user.permission_group_id);
                permission_group_manage_users= selectedPermissionsGroup.manage_users;
            }

        }
        return permission_group_manage_users;
    };

    constructAccountUserPermissions = () => {
        const data = {};
        accountUserFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = _.compact(this.state[field]).join(',');
                } else {
                    data[field] = this.state[field];
                }
            }
        });
        if(data.manage_users_override_group){
            data.manage_users_override = this.state.permission_group_manage_users;
        }

        // the *_list_override_group fields need to match the *_ids_override_group fields
        data.filter_customer_list_override_group = this.state.filter_customer_ids_override_group;
        data.filter_inventory_item_list_override_group = this.state.filter_inventory_item_ids_override_group;
        data.filter_qb_account_list_override_group = this.state.filter_qb_account_ids_override_group;
        data.filter_qb_class_list_override_group = this.state.filter_qb_class_ids_override_group;

        // restrictions_override_group should be automatically turned to 'Yes'
        // if at least one of the restriction fields has been overridden
        const restrictionsOverrideFields = [
            this.state.filter_customer_ids_override_group,
            this.state.filter_inventory_item_ids_override_group,
            this.state.filter_qb_account_ids_override_group,
            this.state.filter_qb_class_ids_override_group,
        ];

        if (restrictionsOverrideFields.some(value => value === 'Yes')) {
            data.restrictions_override_group = 'Yes';
        } else {
            data.restrictions_override_group = 'No';
        }

        const timePermissionsOverrideFields = [
            'view_others_time_override_group',
            'edit_others_time_override_group',
            'approve_others_time_override_group',
            'filter_view_employee_ids_override_group',
            'filter_edit_employee_ids_override_group',
            'filter_approve_employee_ids_override_group',
        ];

        // if manage_time_override_group is set to 'Yes', all time permissions
        // are considered overridden
        timePermissionsOverrideFields.forEach(
            field => (data[field] = this.state.manage_time_override_group)
        );

        const expensePermissionsOverrideFields = [
            'view_others_expenses_override_group',
            'edit_others_expenses_override_group',
            'approve_others_expenses_override_group',
            'filter_view_expense_vendor_ids_override_group',
            'filter_edit_expense_vendor_ids_override_group',
            'filter_approve_expense_vendor_ids_override_group',
        ];

        // if manage_time_override_group is set to 'Yes', all time permissions
        // are considered overridden
        expensePermissionsOverrideFields.forEach(
            field => (data[field] = this.state.manage_expenses_override_group)
        );

        // empty out filters if view/edit/approve permissions aren't set to 'Filtered'
        const fieldToFilterMapping = {
            view_others_time: 'filter_view_employee_ids',
            edit_others_time: 'filter_edit_employee_ids',
            approve_others_time: 'filter_approve_employee_ids',
            view_others_expenses: 'filter_view_vendor_ids',
            edit_others_expenses: 'filter_edit_vendor_ids',
            approve_others_expenses: 'filter_approve_vendor_ids',
        };

        _.map(fieldToFilterMapping, (filter, field) => {
            if (this.state[field] !== 'Filtered') {
                data[filter] = '';
            }
        });

        return data;
    };

    validate = () => {
        const {
            permission_group_id: permissionsGroupId,
            filter_view_employee_ids: filterViewEmployeeIds,
            filter_edit_employee_ids: filterEditEmployeeIds,
            filter_approve_employee_ids: filterApproveEmployeeIds,
            filter_view_expense_vendor_ids: filterViewExpenseVendorIds,
            filter_edit_expense_vendor_ids: filterEditExpenseVendorIds,
            filter_approve_expense_vendor_ids: filterApproveExpenseVendorIds,
            filter_customer_ids: filterCustomerIds,
            filter_inventory_item_ids: filterInventoryItemIds,
            filter_qb_account_ids: filterQbAccountIds,
            filter_qb_class_ids: filterQbClassIds,
            manage_users: manageUsers,
            view_others_time: viewOthersTime,
            edit_others_time: editOthersTime,
            approve_others_time: approveOthersTime,
            view_others_expenses: viewOthersExpenses,
            edit_others_expenses: editOthersExpenses,
            approve_others_expenses: approveOthersExpenses,
            filter_customer_list: filterCustomerList,
            filter_customer_ids_override_group: filterCustomerIdsOverrideGroup,
            filter_inventory_item_list: filterInventoryItemList,
            filter_inventory_item_ids_override_group: filterInventoryItemIdsOverrideGroup,
            filter_qb_account_list: filterQbAccountList,
            filter_qb_account_ids_override_group: filterQbAccountIdsOverrideGroup,
            filter_qb_class_list: filterQbClassList,
            filter_qb_class_ids_override_group: filterQbClassIdsOverrideGroup,
            manage_time_override_group: manageTimeOverrideGroup,
            manage_expenses_override_group: manageExpensesOverrideGroup,
        } = this.state;

        const { showToast } = this.props;

        const inGroup = permissionsGroupId !== '0';

        // the conditions below always assume that if the user is in a
        // permissions group and has not chosen to override the permissions group,
        // the permissions group setting will be valid

        // managers have permissions over all time entries and expenses, so
        // we can skip over time/expense permission validations for them
        if (manageUsers === 'No') {
            if (
                (!inGroup || (inGroup && manageTimeOverrideGroup === 'Yes')) &&
                viewOthersTime === 'Filtered' &&
                filterViewEmployeeIds.length === 0
            ) {
                showToast(
                    'Please select at least one employee this user can view time entries for.',
                    'warning'
                );
                return false;
            }

            if (
                (!inGroup || (inGroup && manageTimeOverrideGroup === 'Yes')) &&
                editOthersTime === 'Filtered' &&
                filterEditEmployeeIds.length === 0
            ) {
                showToast(
                    'Please select at least one employee this user can edit time entries for.',
                    'warning'
                );
                return false;
            }

            if (
                (!inGroup || (inGroup && manageTimeOverrideGroup === 'Yes')) &&
                approveOthersTime === 'Filtered' &&
                filterApproveEmployeeIds.length === 0
            ) {
                showToast(
                    'Please select at least one employee this user can approve time entries for.',
                    'warning'
                );
                return false;
            }

            if (
                (!inGroup || (inGroup && manageExpensesOverrideGroup === 'Yes')) &&
                viewOthersExpenses === 'Filtered' &&
                filterViewExpenseVendorIds.length === 0
            ) {
                showToast(
                    'Please select at least one employee this user can view expense entries for.',
                    'warning'
                );
                return false;
            }

            if (
                (!inGroup || (inGroup && manageExpensesOverrideGroup === 'Yes')) &&
                editOthersExpenses === 'Filtered' &&
                filterEditExpenseVendorIds.length === 0
            ) {
                showToast(
                    'Please select at least one employee this user can edit expense entries for.',
                    'warning'
                );
                return false;
            }

            if (
                (!inGroup || (inGroup && manageExpensesOverrideGroup === 'Yes')) &&
                approveOthersExpenses === 'Filtered' &&
                filterApproveExpenseVendorIds.length === 0
            ) {
                showToast(
                    'Please select at least one employee this user can approve expense entries for.',
                    'warning'
                );
                return false;
            }
        }

        if (
            (!inGroup || (inGroup && filterCustomerIdsOverrideGroup === 'Yes')) &&
            filterCustomerList === 'Yes' &&
            filterCustomerIds.length === 0
        ) {
            showToast(
                'Please select at least one customer this user should be restricted to.',
                'warning'
            );
            return false;
        }

        if (
            (!inGroup || (inGroup && filterInventoryItemIdsOverrideGroup === 'Yes')) &&
            filterInventoryItemList === 'Yes' &&
            filterInventoryItemIds.length === 0
        ) {
            showToast(
                'Please select at least one service this user should be restricted to.',
                'warning'
            );
            return false;
        }

        if (
            (!inGroup || (inGroup && filterQbAccountIdsOverrideGroup === 'Yes')) &&
            filterQbAccountList === 'Yes' &&
            filterQbAccountIds.length === 0
        ) {
            showToast(
                'Please select at least one expense entry account this user should be restricted to.',
                'warning'
            );
            return false;
        }

        if (
            (!inGroup || (inGroup && filterQbClassIdsOverrideGroup === 'Yes')) &&
            filterQbClassList === 'Yes' &&
            filterQbClassIds.length === 0
        ) {
            showToast(
                'Please select at least one QuickBooks class this user should be restricted to.',
                'warning'
            );
            return false;
        }

        return true;
    };

    updateAccountUserPermissions = () => {
        const isValid = this.validate();
        if (!isValid) {
            return;
        }

        const data = this.constructAccountUserPermissions();
        this.props.updateUser({ ...this.props.user, ...data });
    };

    setFields = fields => {
        this.setState({ ...fields, hasBeenEdited: true });
    };

    render() {
        const {
            permissionsGroups,
            customers,
            inventoryItems,
            qbClasses,
            allowedQbAccounts,
            allowedQbPaymentAccounts,
            employees,
            vendors,
            user,
            no_integration,
        } = this.props;

        const {
            hasBeenEdited,
            employee_id: employeeId,
            expense_vendor_id: expenseVendorId,
            permission_group_id: permissionsGroupId,
        } = this.state;

        const fullName = [user.first_name, user.last_name].join(' ');

        const permissionsProps = {
            permissionsGroupId: this.state.permission_group_id,
            manageUsers: this.state.permission_group_id !=='0'?this.state.permission_group_manage_users:this.state.manage_users,
            manageUsersOverrideGroup: this.state.manage_users_override_group,
            manageApi: this.state.manage_api,
            manageApiOverrideGroup: this.state.manage_api_override_group,
            customers,
            inventoryItems,
            allowedQbAccounts,
            allowedQbPaymentAccounts,
            qbClasses,
            employees,
            vendors,
            exportTime: this.state.export_time,
            exportTimeOverrideGroup: this.state.export_time_override_group,
            viewReports: this.state.view_reports,
            viewReportsOverrideGroup: this.state.view_reports_override_group,
            clockInOnly: this.state.clock_in_only,
            editPayroll: this.state.edit_payroll,
            editPayrollOverrideGroup: this.state.edit_payroll_override_group,
            managePayrollPayratesOverrideGroup: this.state.manage_payroll_payrates_override_group,
            manageItemBillratesOverrideGroup: this.state.manage_item_billrates_override_group,
            manageTimeOverrideGroup: this.state.manage_time_override_group,
            viewOthersTime: this.state.view_others_time,
            filterViewEmployeeIds: this.state.filter_view_employee_ids,
            editOthersTime: this.state.edit_others_time,
            filterEditEmployeeIds: this.state.filter_edit_employee_ids,
            approveOthersTime: this.state.approve_others_time,
            filterApproveEmployeeIds: this.state.filter_approve_employee_ids,
            manageExpensesOverrideGroup: this.state.manage_expenses_override_group,
            viewOthersExpenses: this.state.view_others_expenses,
            filterViewExpenseVendorIds: this.state.filter_view_expense_vendor_ids,
            editOthersExpenses: this.state.edit_others_expenses,
            filterEditExpenseVendorIds: this.state.filter_edit_expense_vendor_ids,
            approveOthersExpenses: this.state.approve_others_expenses,
            filterApproveExpenseVendorIds: this.state.filter_approve_expense_vendor_ids,
            filterCustomerList: this.state.filter_customer_list,
            filterCustomerIds: this.state.filter_customer_ids,
            filterCustomerIdsOverrideGroup: this.state.filter_customer_ids_override_group,
            filterInventoryItemList: this.state.filter_inventory_item_list,
            filterInventoryItemIds: this.state.filter_inventory_item_ids,
            filterInventoryItemIdsOverrideGroup: this.state
                .filter_inventory_item_ids_override_group,
            managePayrollPayrates: this.state.manage_payroll_payrates,
            manageItemBillrates: this.state.manage_item_billrates,
            filterQbAccountList: this.state.filter_qb_account_list,
            filterQbAccountIds: this.state.filter_qb_account_ids,
            filterQbAccountIdsOverrideGroup: this.state.filter_qb_account_ids_override_group,
            filterQbClassList: this.state.filter_qb_class_list,
            filterQbClassIds: this.state.filter_qb_class_ids,
            filterQbClassIdsOverrideGroup: this.state.filter_qb_class_ids_override_group,
            expenseEntityType: 'Vendors',
            onChange: this.setFields,
            no_integration: this.props.no_integration
        };

        if (_.isEmpty(user)) {
            return (
                <div className="infoPage">
                    <Icon size={72} icon={errorIcon} className="errorIcon" />

                    <h1>We can’t find the Account User Permission you're looking for.</h1>
                    <p>You can find a list of all Permissoin Groups on the Settings tab.</p>
                </div>
            );
        }
        return (
            <div className="applicationBody">
                <DocumentTitle title={`Permissions for ${fullName}`} />

                <FloatingSaveButton
                    buttonText={'Save'}
                    hasBeenEdited={hasBeenEdited}
                    onSave={this.updateAccountUserPermissions}
                />
                <div className="bodyHeader">
                    <h2>Permissions for {fullName}</h2>
                    <div className="headerActions">
                        <button
                            className="tertiaryButton"
                            onClick={() => this.props.history.push('/account_settings')}
                        >
                            Back to Account Settings
                        </button>
                    </div>
                </div>
                {!no_integration && (
                <div>
                    <h3 className="cardHeading">QuickBooks Settings</h3>
                    <div className="cardContainer settingsSection">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                QuickBooks Employee or Vendor for Time Tracking
                            </div>
                            <div className="settingsInput">
                                <Select
                                    options={employees}
                                    value={employeeId}
                                    onChange={id =>
                                        this.setFields({
                                            employee_id: id,
                                        })
                                    }
                                    withClearOption
                                    clearOptionLabel="No Employee Selected"
                                    labelWhenNothingSelected="No Employee Selected"
                                />
                            </div>
                            <div className="settingsTip">
                                Simplifies time tracking by automatically selecting this employee
                                for time entries
                            </div>
                        </div>
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">
                                QuickBooks Vendor for Expense Tracking
                            </div>
                            <div className="settingsInput">
                                <Select
                                    options={vendors}
                                    value={expenseVendorId}
                                    onChange={id =>
                                        this.setFields({
                                            expense_vendor_id: id,
                                        })
                                    }
                                    withClearOption
                                    clearOptionLabel="No Vendor Selected"
                                    labelWhenNothingSelected="No Vendor Selected"
                                />
                            </div>
                            <div className="settingsTip">
                                Required for mobile expense tracking. For web-based expense
                                tracking, required if permissions for this account limit it to
                                editing only its own expenses
                            </div>
                        </div>
                    </div>
                </div>)}
                <div>
                    <h3 className="cardHeading">Group</h3>
                    <div className="cardContainer settingsSection">
                        <div className="settingsLabelAndInputContainer">
                            <div className="settingsInputLabel">Permissions Group</div>
                            <div className="settingsInput">
                                <Select
                                    options={permissionsGroups}
                                    value={permissionsGroupId}
                                    onChange={this.handlePermissionsGroupSelected}
                                    withClearOption
                                    clearOptionLabel="No Permissions Group"
                                    labelWhenNothingSelected="No Permissions Group"
                                />
                            </div>
                            <div className="settingsTip">
                                You can add a new permissions group to manage permissions for many
                                users at once
                            </div>
                        </div>
                    </div>
                </div>

                <Permissions {...permissionsProps} />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const users = state.accountStore.accountUsers;
    const { match } = ownProps;

    let user;

    if (match && match.params && match.params.id) {
        user = users.find(u => u.id === match.params.id) || {};
    } else {
        user = state.accountUserStore;
    }
    const no_integration = state.accountStore.qb_type === 'None' ? true : false;
    return {
        accountStore: state.accountStore,
        currentUser: state.accountUserStore,
        appStore: state.appStore,
        customers: resourceSelectors.selectAllCustomers(state),
        inventoryItems: resourceSelectors.selectAllInventoryItems(state),
        qbClasses: resourceSelectors.selectAllQbClasses(state),
        allowedQbAccounts: expensesSelectors.selectAllowedQbAccountsForExpenses(state),
        allowedQbPaymentAccounts: expensesSelectors.selectAllowedQbPaymentAccountsForExpenses(
            state
        ),
        employees: accountSelectors.selectCanEditActiveEmployees(state),
        vendors: accountSelectors.selectCanEditActiveVendors(state),
        user,
        isSaving: state.accountUserStore.isSaving,
        permissionsGroups: state.businessResourcesStore.permissionsGroups,
        no_integration,
    };
}

const mapDispatchToProps = {
    showToast,
    setPermissionsGroups,
    updateUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountUserPermissions);
