import React, { Component } from 'react';
import Icon from 'react-icons-kit';
import { close as closeIcon } from 'react-icons-kit/ikons/close';
import { tick as tickIcon } from 'react-icons-kit/ikons/tick';
import { warning as warningIcon } from 'react-icons-kit/ikons/warning';
import { exclamation as negativeIcon } from 'react-icons-kit/fa/exclamation';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { hideAlert } from '../../actions/Actions.js';

import styles from '../../css/GlobalAlert.module.css';

const PositiveIcon = () => (
    <div className={styles.positiveIconContainer}>
        <Icon icon={tickIcon} size={18} className={styles.positiveIcon} />
    </div>
);

const WarningIcon = () => (
    <div className={styles.warningIconContainer}>
        <Icon icon={warningIcon} size={14} className={styles.warningIcon} />
    </div>
);

const NegativeIcon = () => (
    <div className={styles.negativeIconContainer}>
        <Icon icon={negativeIcon} size={14} className={styles.negativeIcon} />
    </div>
);

const alertProps = {
    positive: {
        title: 'Success',
        icon: PositiveIcon,
    },
    warning: {
        title: 'Warning',
        icon: WarningIcon,
    },
    negative: {
        title: 'Error',
        icon: NegativeIcon,
    },
    neutral: {
        title: null,
        icon: () => null,
    },
};

class GlobalAlert extends Component {
    render() {
        const { isGlobalAlertVisible, globalAlertType, hideAlert, globalAlertMessage } = this.props;

        const alertInformation = alertProps[globalAlertType] || alertProps.positive;

        const MessageIcon = alertInformation.icon;

        return (
            <div className={styles.wrapper} hidden={!isGlobalAlertVisible}>
                <div
                    className={classNames([
                        styles.container,
                        styles[`container--${globalAlertType}`],
                    ])}
                    onClick={hideAlert}
                >
                    <div className={styles.content}>
                        <MessageIcon />
                        <div className={styles.titleAndMessageContainer}>
                            {alertInformation.title && (
                                <div className={styles.title}>{alertInformation.title}</div>
                            )}
                            {typeof globalAlertMessage === 'function' ? (
                                <div>{globalAlertMessage()}</div>
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: globalAlertMessage }} />
                            )}
                        </div>
                        <div className={styles.closeIconContainer}>
                            <Icon className={styles.closeIcon} icon={closeIcon} size={24} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        globalAlertMessage: state.appStore.globalAlertMessage,
        globalAlertType: state.appStore.globalAlertType,
        isGlobalAlertVisible: state.appStore.isGlobalAlertVisible,
    };
}

export default connect(
    mapStateToProps,
    { hideAlert }
)(GlobalAlert);
