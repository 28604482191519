import React, { useRef, useEffect } from 'react';
import { Input } from 'reactstrap';

const MAX_HEIGHT = 250;

const autoGrow = event => {
    const element = event.target;
    if (
        element.style.height === '' ||
        parseInt(element.style.height.replace('px', '')) < MAX_HEIGHT
    ) {
        element.style.height = '5px';
        element.style.height = element.scrollHeight + 4 + 'px';
    } else {
        element.style.height = MAX_HEIGHT + 'px';
    }
};

const DescriptionInput = ({ className, value = '', onChange = () => null }) => {
    const inputRef = useRef(null);

    useEffect(
        () => {
            if (inputRef.current) {
                autoGrow({ target: inputRef.current });
            }
        },
        [value]
    );

    return (
        <Input
            style={{ height: '36px' }}
            onFocus={autoGrow}
            className={className}
            value={value}
            onChange={onChange}
            type="textarea"
            innerRef={inputRef}
        />
    );
};

export default DescriptionInput;
