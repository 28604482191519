import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';
import { isInventoryItemNameValid } from '../../../utils/CommonFunctions.js';
import { Row, Col } from 'react-bootstrap';
import { accountFields } from '../../../config/Fields.js';
import { showToast } from '../../../actions/Actions.js';
import 'react-phone-input-2/lib/style.css';
import Select from '../../components/Select.js';
import Spinner from '../../components/Spinner.js';
import '../../../css/CommonTheme.css';
import '../../../css/AccountSettings.css';
import { addAccount } from '../../../actions/AccountsActions.js';
import { selectAccount } from '../../../actions/Actions.js';

const paymentAccountTypes = [
    { name: 'Credit Card', id: 'Credit Card' },
    { name: 'Bank', id: 'Bank' },
];

class PaymentAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = this.defaultState({ selectedView: 'accounts' });
    }

    componentWillReceiveProps(props) {}

    defaultState = data => {
        const { accountId } = this.props;

        return {
            iNameInvalidMsg: '',
            name: '',
            account_type: '',
            account_id: accountId,
            spinner_loader: false,
        };
    };

    constructAccount = () => {
        const data = {};
        accountFields.forEach(field => {
            if (field in this.state) {
                if (
                    field.includes('filter') &&
                    field.includes('ids') &&
                    !field.includes('override')
                ) {
                    data[field] = this.state[field].join(',');
                } else {
                    data[field] = this.state[field];
                }
            }
        });
        return data;
    };

    checkRequiredFields = () => {
        const { name, account_type } = this.state;
        if (name === '' || account_type === '') {
            this.props.showToast('Please fill out name and account type', 'warning');
            return false;
        }
        return true;
    };

    handleOnChanges = {
        handleAccountName: newName => {
            this.setState({ name: newName.target.value });
        },
    };
    saveAccount = event => {
        event.preventDefault();
        if (this.state.iNameInvalidMsg !== '') {
            this.props.showToast('Please Fix the form error before submit', 'warning');
        } else {
            if (!this.checkRequiredFields()) {
                return;
            }
            this.setState({ spinner_loader: true });
            const data = this.constructAccount();
            this.props.addAccount(data, newAccount => {
                if (newAccount === false) {
                    this.setState({ spinner_loader: false });
                    return;
                } else {
                    this.props.showToast(`New Acount ${newAccount.name} created`, 'positive');
                    this.setState({
                        spinner_loader: false,
                        name: '',
                        account_type: '',
                    });
                }
            });
        }
    };

    handleAccountTypeOnChange = newAccountType => {
        this.setState({ account_type: newAccountType });
    };

    setFields = fields => {
        this.setState({ ...fields });
    };

    handleOnChanges = {
        handleAccountName: newName => {
            this.setState({ name: newName.target.value });
        },
    };

    render() {
        const { spinner_loader, name, account_type } = this.state;
        return (
            <div className="applicationWrapper">
                <div className="application">
                    <div className="ftContainer cardContainer">
                        <form className="ftForm" onSubmit={this.saveAccount}>
                            <Row className="ftRowAlightCenter">
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    Create New Account
                                </div>
                            </Row>
                            <Row className="ftrow">
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                Payment Account Name
                                            </div>
                                            <div className="required">*</div>
                                        </Row>

                                        <div>
                                            <Input
                                                className={
                                                    this.state.iNameInvalidMsg === ''
                                                        ? ''
                                                        : 'ausInvalidInput'
                                                }
                                                name="name"
                                                value={name}
                                                onChange={this.handleOnChanges.handleAccountName}
                                                onBlur={
                                                    name.length !== 0
                                                        ? () =>
                                                              isInventoryItemNameValid(name)
                                                                  ? this.setFields({
                                                                        iNameInvalidMsg: '',
                                                                    })
                                                                  : this.setFields({
                                                                        iNameInvalidMsg:
                                                                            ' Account name contains only  1 to 50 alphabets.',
                                                                    })
                                                        : ''
                                                }
                                            />
                                            <div className="ftInputError">
                                                {!this.state.iNameInvalidMsg === '' &&
                                                !name === '' ? (
                                                    <label />
                                                ) : (
                                                    <label className="error-message">
                                                        {this.state.iNameInvalidMsg}
                                                    </label>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px' }}>
                                    <div className="ftLabelAndInput">
                                        <Row>
                                            <div className="ftLabel largeBodyText greyText">
                                                Paymen Account Type
                                            </div>
                                            <div className="required">*</div>
                                        </Row>
                                        <div>
                                            <Select
                                                options={paymentAccountTypes}
                                                withSearch
                                                labelWhenNothingSelected="No Account type Selected"
                                                value={account_type}
                                                onChange={this.handleAccountTypeOnChange}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button type="submit" className="primaryButton ftButton">
                                            Save
                                        </button>
                                    </div>
                                </Col>
                                <Col sm={6} style={{ marginBottom: '5px', marginTop: '5px' }}>
                                    <div className="ftButtonContainer">
                                        <button
                                            className="secondaryButton ftButton"
                                            onClick={() =>
                                                this.props.history.push('/choose_new_account_type')
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            <Spinner loading={spinner_loader} />
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        accountId: state.accountStore.id,
        accountUserStore: state.accountUserStore,
        isSaving: state.accountUserStore.isSaving,
    };
}

const mapDispatchToProps = {
    showToast,
    addAccount,
    selectAccount,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentAccountForm);
