import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

export const bugsnagClient = bugsnag({
    appVersion: process.env.REACT_APP_VERSION,
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    notifyReleaseStages: ['production'],
});
bugsnagClient.use(bugsnagReact, React);

export const ErrorBoundary = bugsnagClient.getPlugin('react');
